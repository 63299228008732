import React, { useEffect, useState } from "react";

const SearchTableIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <g clipPath="url(#clip0_1289_1740)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0604 10.5536C19.0604 15.2777 15.2308 19.1073 10.5068 19.1073C5.78271 19.1073 1.95312 15.2777 1.95312 10.5536C1.95312 5.82959 5.78271 2 10.5068 2C15.2308 2 19.0604 5.82959 19.0604 10.5536ZM17.5706 18.3948C15.7001 20.0809 13.2233 21.1073 10.5068 21.1073C4.67814 21.1073 -0.046875 16.3822 -0.046875 10.5536C-0.046875 4.72502 4.67814 0 10.5068 0C16.3354 0 21.0604 4.72502 21.0604 10.5536C21.0604 12.945 20.265 15.1505 18.9244 16.9202L23.6164 21.6122C24.0069 22.0027 24.0069 22.6359 23.6164 23.0264C23.2259 23.4169 22.5927 23.4169 22.2022 23.0264L17.5706 18.3948Z"
          fill="#404040"
        />
      </g>
      <defs>
        <clipPath id="clip0_1289_1740">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SearchTableIcon;
