import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RegionSelectLoc } from "../../location/RegionSelectLoc";
import { SubregionSelectLoc } from "../../location/SubregionSelectLoc";
import { updateSubregion } from "../../../redux/actions/locs";
import log from "../../../services/log";

export default function SettingsORG() {
  const regiondata = useSelector((state) => state.locs.regiondata);
  const selectedregion = useSelector((state) => state.locs.region);
  const selectedsubregion = useSelector((state) => state.locs.subregion);

  const dispatch = useDispatch();

  useEffect(() => {
    const data = [];
    const subregions = [];

    if (regiondata) {
    }

    // regiondata && regiondata.forEach((v) => {
    //     if (v._source['region-code'] == region &&
    //         subregions.includes(v._source['sub-region-code']) == false) {
    //
    //         data.push({
    //             'sub-region': v._source['sub-region'],
    //             'sub-region-code': v._source['sub-region-code'],
    //             'disable': false
    //         });
    //         subregions.push(v._source['sub-region-code'])
    //     }
    // });

    // log.info("subregions", data);
    dispatch(updateSubregion(data));
  }, [regiondata]);

  const regionCallback = (val) => {
    // log.info("region", val);
    // setRegion(val);
  };

  const subregionCallback = (val) => {
    // log.info("subregion", val);
    // setSubregion(val)
  };

  return <Grid container></Grid>;
}
