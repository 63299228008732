import React, { useState, useEffect } from "react";
import { Grid, Box, Typographyn } from "@mui/material";
import FoundationTwoToneIcon from "@mui/icons-material/FoundationTwoTone";

import { Title } from "../common/Title";
import { tableParam } from "../common/ConstantsAndValues";
import log from "../../services/log";

import { useDispatch, useSelector } from "react-redux";
import { listOrgDepartments, selectDepartment } from "../../redux/actions/orgs";
import { buildOrgGroupDepartmentQuery } from "../dbcq/departmentdb";

export default function DepartmentList(props) {
  const title = "Departments";
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const selectorg = useSelector((state) => state?.orgs?.selectorg);
  const selectgroup = useSelector((state) => state?.orgs?.selectorggroup);
  const departments = useSelector((state) => state?.orgs?.departments);
  const selectdepartment = useSelector(
    (state) => state?.orgs?.selectdepartment
  );
  const address = useSelector((state) => state?.addresses?.selectaddress);
  const auth = useSelector((state) => state.auth);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectorg && selectgroup && address) {
      const resetpage = { ...pagedata, page: 0 };
      const jsonData = buildOrgGroupDepartmentQuery(
        resetpage,
        searchcontext,
        selectorg,
        selectgroup,
        address
      );
      log.info("query: ", jsonData);
      dispatch(listOrgDepartments(jsonData)).then((r) => {
        log.info("received departments: ", r);
        if (r.results) {
          setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
        }
      });
    }
  }, [selectorg, selectgroup, address]);

  function getFilteredList(alist, val) {
    return alist.filter((item) => item.org.includes(val));
  }

  const handleClick = (row) => {
    // log.info("rsm department", row);
    dispatch(selectDepartment(row));
  };

  // log.info("departmentlist", departments);

  return (
    <>
      <Box className="" pt={1} ml={2} mr={0}>
        <div onClick={() => setIsActive(!isActive)} className="cursor-pointer">
          <Title>
            <FoundationTwoToneIcon /> {title}
          </Title>
        </div>
      </Box>
      {isActive && (
        <Box
          className="list border-dbt border-dbb"
          pt={0}
          ml={2}
          mr={0}
          mb={1}
          height={tableParam.minHeight}
          width="90%"
        >
          <ul className="list-group list-group-flush">
            {departments
              ? departments.map((row) => {
                  return (
                    <li
                      className={` list-group-item cursor-pointer ${
                        row.id === selectdepartment?.id
                          ? "text-color-inv"
                          : "text-color"
                      }`}
                      key={row.id}
                      value={row}
                      onClick={() => handleClick(row)}
                    >
                      {row.departmentName}
                    </li>
                  );
                })
              : null}
          </ul>
        </Box>
      )}
    </>
  );
}
