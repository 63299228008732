import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

export function Title(props) {
  return (
    <Typography
      component="h2"
      variant="h6"
      gutterBottom
      className="text-color-primary"
    >
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

export function TitleProper(props) {
  let valu = props.children;
  valu = valu[0].toUpperCase() + valu.substr(1, valu.length);
  return (
    <Typography
      component="h2"
      variant="h6"
      gutterBottom
      className="text-color-primary"
    >
      {valu}
    </Typography>
  );
}

TitleProper.propTypes = {
  children: PropTypes.node,
};

export function Proper(props) {
  let valu = props.children;
  valu = valu[0].toUpperCase() + valu.substr(1, valu.length);
  return <Typography>{valu}</Typography>;
}

Proper.propTypes = {
  children: PropTypes.node,
};
