import React, { useEffect, useState } from "react";

export const NotificationBellIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;

  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
    >
      <g clipPath="url(#clip0_60_9143)">
        <mask id="path-1-inside-1_60_9143" fill="white">
          <path d="M20.8171 8.95488C20.8171 6.78917 19.9024 4.71216 18.2742 3.18077C16.646 1.64939 14.4377 0.789063 12.1351 0.789063C9.8325 0.789062 7.62421 1.64939 5.99602 3.18077C4.36783 4.71216 3.45313 6.78917 3.45312 8.95488H5.44158C5.44158 7.28519 6.14679 5.68388 7.40207 4.50323C8.65735 3.32258 10.3599 2.6593 12.1351 2.6593C13.9103 2.6593 15.6129 3.32258 16.8681 4.50323C18.1234 5.68388 18.8286 7.28519 18.8286 8.95488H20.8171Z" />
        </mask>
        <path
          d="M20.8171 8.95488V10.9549H22.8171V8.95488H20.8171ZM18.2742 3.18077L19.6444 1.72392L18.2742 3.18077ZM12.1351 0.789063V-1.21094V0.789063ZM5.99602 3.18077L4.62578 1.72392L5.99602 3.18077ZM3.45312 8.95488L1.45312 8.95488L1.45312 10.9549L3.45312 10.9549L3.45312 8.95488ZM5.44158 8.95488L5.44158 10.9549H7.44158L7.44158 8.95488H5.44158ZM7.40207 4.50323L6.03183 3.04637L7.40207 4.50323ZM12.1351 2.6593V0.6593V2.6593ZM18.8286 8.95488H16.8286V10.9549H18.8286V8.95488ZM22.8171 8.95488C22.8171 6.2178 21.6599 3.61959 19.6444 1.72392L16.904 4.63763C18.1448 5.80474 18.8171 7.36053 18.8171 8.95488H22.8171ZM19.6444 1.72392C17.6331 -0.167845 14.9299 -1.21094 12.1351 -1.21094V2.78906C13.9455 2.78906 15.6589 3.46662 16.904 4.63763L19.6444 1.72392ZM12.1351 -1.21094C9.34028 -1.21094 6.63712 -0.167846 4.62578 1.72392L7.36626 4.63763C8.6113 3.46662 10.3247 2.78906 12.1351 2.78906V-1.21094ZM4.62578 1.72392C2.61028 3.61959 1.45313 6.2178 1.45312 8.95488L5.45312 8.95488C5.45313 7.36053 6.12538 5.80474 7.36626 4.63763L4.62578 1.72392ZM3.45312 10.9549H5.44158L5.44158 6.95488L3.45313 6.95488L3.45312 10.9549ZM7.44158 8.95488C7.44158 7.85655 7.90434 6.77646 8.77231 5.96009L6.03183 3.04637C4.38924 4.59131 3.44158 6.71382 3.44158 8.95488H7.44158ZM8.77231 5.96009C9.64444 5.13981 10.8521 4.6593 12.1351 4.6593L12.1351 0.6593C9.86765 0.6593 7.67026 1.50535 6.03183 3.04637L8.77231 5.96009ZM12.1351 4.6593C13.4181 4.6593 14.6258 5.13981 15.4979 5.96009L18.2384 3.04638C16.6 1.50535 14.4026 0.6593 12.1351 0.6593L12.1351 4.6593ZM15.4979 5.96009C16.3659 6.77646 16.8286 7.85655 16.8286 8.95488H20.8286C20.8286 6.71382 19.881 4.59131 18.2384 3.04638L15.4979 5.96009ZM18.8286 10.9549H20.8171V6.95488H18.8286V10.9549Z"
          mask="url(#path-1-inside-1_60_9143)"
        />
        <path d="M3.45312 20.0703L3.45312 8.57031L5.45312 8.57031L5.45312 20.0703L3.45312 20.0703Z" />
        <path d="M18.8125 20.0781L18.8125 8.57812L20.8125 8.57812L20.8125 20.0781L18.8125 20.0781Z" />
        <rect y="18.0781" width="24" height="2" rx="1" />
        <rect x="9.46875" y="22.5" width="5.2834" height="2" rx="1" />
      </g>
      <defs>
        <clipPath id="clip0_60_9143">
          <rect
            width={width}
            height={height}
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
