import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTable } from "../styles/DiagnosticsStyles";

import { CTestsTableHeader } from "./CTestsTableHeader";
import { CTestsTableRows, CTestsTableRowsBlank } from "./CTestsTableRows";

const styles = stylesTable;

export const CTestsItemsTable = ({ diags, tableRowsCount }) => (
  <View style={styles.tableContainer}>
    <CTestsTableHeader />
    <CTestsTableRows items={diags} />
    {tableRowsCount !== 0 && (
      <CTestsTableRowsBlank rowsCount={tableRowsCount - diags.length} />
    )}
  </View>
);
