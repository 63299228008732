import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    "& label.Mui-focused": {
      color: "rgb(118, 118, 118)",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#2d8daa",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#2d8daa",
      },

      "&:hover fieldset": {
        borderColor: "#2d8daa",
      },

      "&.Mui-focused fieldset": {
        borderColor: "#2d8daa !important",
      },
    },
    width: "450px",
  },
  label: {
    color: "red",
  },
  errorMsg: {
    color: "red",
    fontSize: "10px",
    float: "left",
    width: "450px",
    marginBottom: "0px !important",
  },
});
export default useStyles;
