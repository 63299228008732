import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CustomizedAxisTick } from "./graph";

export default class MetaBarChart extends React.Component {
  state = {
    data: this.props.data,
    chatType: this.props.chartType,
    testType: this.props.testType,
    title: this.props.title,
    xlable: this.props.xlable,
    ylable: this.props.ylable,
  };

  color = ["#82ca9d", "#FFCC80", "#8884d8", "#82ca9d", "orange", "pink"];

  getBar = () => {
    const { stacked } = this.props;
    const keysArr = Object.keys(this.state.data[0]).slice(1);
    const barArr = [];
    keysArr.forEach((item, index) => {
      barArr.push(
        <Bar
          dataKey={item}
          stackId={stacked ? "a" : null}
          fill={this.color[index]}
        />
      );
    });
    return barArr;
  };

  modifyFormatter = (value, name, props) => {
    console.log(value, name, props, "props");
    const nameJSX = (
      <span>
        <span
          style={{
            display: "inline-block",
            marginRight: "5px",
            borderRadius: "10px",
            width: "10px",
            height: "10px",
            backgroundColor: props.color,
          }}
        ></span>
        {name} : {value}
      </span>
    );
    let result = [nameJSX].reverse();
    // console.log(result, "result");
    return result;
  };

  handleSort = (item1, item2) => {
    // console.log(item1, item2);
    return 1;
  };

  render() {
    const {
      data,
      title,
      refAreaLeft,
      refAreaRight,
      xlable,
      ylable,
      ResponsiveContainer,
    } = this.state;

    return (
      <BarChart
        width={600}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <CartesianGrid
          strokeDasharray="3 3"
          horizontal={false}
          vertical={false}
        />
        <XAxis dataKey={Object.keys(data[0])[0]} tick={<CustomizedAxisTick />}>
          <Label value={xlable} offset={20} position="left" />
        </XAxis>
        <YAxis label={{ value: ylable, angle: -90, position: "insideLeft" }} />
        <Tooltip
          formatter={this.modifyFormatter}
          itemSorter={this.handleSort}
        />
        <Legend verticalAlign="top" height={36} />
        {this.getBar()}
      </BarChart>
    );
  }
}
