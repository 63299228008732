import FileLoaderService from "./localFileLoaders/fileLoaderService";
// DICOMFileLoadingListener

export let loadedImage;

export function loadImageFile(imageId) {
  // Create a new Promise
  const promise = new Promise((resolve, reject) => {
    if (loadedImage) {
      // const image = createImageObject(loadedImage);
      resolve(loadedImage);
    } else {
      reject(new Error("no imagedata"));
    }
  });
  return { promise };
}

const processFile = async (file) => {
  try {
    console.log("fileloader", file);
    const fileLoaderService = new FileLoaderService(file);
    const imageId = fileLoaderService.addFile(file);
    const image = await fileLoaderService.loadFile(file, imageId);
    const dataset = await fileLoaderService.getDataset(image, imageId);
    const studies = await fileLoaderService.getStudies(dataset, imageId);
    loadedImage = image;

    return studies;
  } catch (error) {
    console.log(
      error.name,
      ":Error when trying to load and process local files:",
      error.message
    );
  }
};

export default async function filesToStudies(files) {
  const processFilesPromises = files.map(processFile);
  const studies = await Promise.all(processFilesPromises);

  return FileLoaderService.groupSeries(studies.flat());
}
