import React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  TextField,
} from "@mui/material";
import useStyles from "./useStyles";

function Password(props) {
  const {
    id,
    name,
    label,
    values,
    showPassword,
    handleChange,
    handleClickShowPassword,

    errors,
    errorText,
    help,
  } = props;
  const clases = useStyles();
  return (
    <>
      <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor={props.id}>
          <span className="text-color-primary">{label}</span>
        </InputLabel>

        <Input
          placeholder={label}
          style={{
            width: "100%",
            paddingBottom: "0px",
            marginTop: "0px",
            marginLeft: "auto",
            marginRight: "auto",
            background: "white",
            "&:hover": { backgroundColor: "white" },
            "&:focus": { outline: "none" },
            //borderBottom: "1px solid #2d8daa !important",
          }}
          error={errors ? true : false}
          className={errors ? "" : "bg-white"}
          id={props.id}
          name={name}
          type={showPassword ? "text" : "password"}
          defaultValue={props.values}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {errors && !values && (
          <span className={clases.errorMsg}>Field is mandatory</span>
        )}
        {errors && errorText && values && (
          <span className={clases.errorMsg}>{errorText}</span>
        )}
        {help && <span className="text-color-primary">{help}</span>}
      </FormControl>
    </>
  );
}

export default Password;
