import { Box } from "@mui/material";
import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocByplaceAutocomp } from "../../redux/actions/locs";

import { AddressInputPart } from "./AddressInputPartORG"; //?
import log from "../../services/log";
export const NewAddressOrg = (props) => {
  const { cancel, title } = props;

  const newaddress = useSelector((state) => state.locs.newaddress);
  const country = useSelector((state) => state.locs.country);

  const dispatch = useDispatch();

  useEffect(() => {
    // log.info("neworgaddress", newaddress);
    if (newaddress) {
      const jsonData = {};
      jsonData["placeId"] = newaddress?.value?.place_id;
      jsonData["country"] = country?._source["alpha-2"];
      dispatch(getLocByplaceAutocomp(jsonData));
    }
  }, [newaddress]);

  const handleCancel = () => {
    cancel();
  };

  const handleSave = () => {};

  return (
    <div>
      <AddressInputPart
        cancel={handleCancel}
        handleAddAddrSave={handleSave}
        title={title}
      />
    </div>
  );
};
