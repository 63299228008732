//-----------------TEMPORARY DATA DEPENDENCY------------//

function createData(date, product, value) {
  return { date, product, value };
}

//-----------------TEMPORARY DATA-----------------------//

export const data = {
  items: [
    createData("2020-05-12T23:50:21.817Z", "calcium", 9.0),
    createData("2020-07-12T23:50:21.817Z", "calcium", 9.0),
    createData("2020-09-12T23:50:21.817Z", "calcium", 9.2),
    createData("2020-11-12T23:50:21.817Z", "calcium", 9.2),
    createData("2021-05-12T23:50:21.817Z", "calcium", 8.7),
    createData("2021-07-12T23:50:21.817Z", "calcium", 9.6),
    createData("2021-09-12T23:50:21.817Z", "calcium", 9.3),
    createData("2021-11-12T23:50:21.817Z", "calcium", 9.8),
    createData("2022-03-12T23:50:21.817Z", "calcium", 9.7),
    createData("2022-05-12T23:50:21.817Z", "calcium", 9.7),
  ],
  columns: [
    ["min", 8.2, 8.2, 8.2, 8.2, 8.2, 8.2, 8.2, 8.2, 8.2, 8.2],
    ["max", 10.2, 10.2, 10.2, 10.2, 10.2, 10.2, 10.2, 10.2, 10.2, 10.2],
    ["calcium", 9.0, 9.0, 9.2, 9.2, 8.7, 9.6, 9.3, 9.8, 9.7, 9.7],
  ],
  xlabel: "Time",
  ylabel: "mg/dL",
  targetY: 0,
  targetX: 0,
  min: 8.2,
  max: 10.2,
  type: "line",
};

export const datac = {
  items: [
    createData("2020-05-12T23:50:21.817Z", "calcium", 9.0),
    createData("2020-07-12T23:50:21.817Z", "calcium", 9.0),
    createData("2020-09-12T23:50:21.817Z", "calcium", 9.2),
    createData("2020-11-12T23:50:21.817Z", "calcium", 9.2),
    createData("2021-05-12T23:50:21.817Z", "calcium", 8.7),
    createData("2021-07-12T23:50:21.817Z", "calcium", 9.6),
    createData("2021-09-12T23:50:21.817Z", "calcium", 9.3),
    createData("2021-11-12T23:50:21.817Z", "calcium", 9.8),
    createData("2022-03-12T23:50:21.817Z", "calcium", 9.7),
    createData("2022-05-12T23:50:21.817Z", "calcium", 9.7),

    createData("2020-05-12T23:50:21.817Z", "albumin", 9.0),
    createData("2020-07-12T23:50:21.817Z", "albumin", 9.1),
    createData("2020-09-12T23:50:21.817Z", "albumin", 9.2),
    createData("2020-11-12T23:50:21.817Z", "albumin", 9.3),
    createData("2021-05-12T23:50:21.817Z", "albumin", 8.1),
    createData("2021-07-12T23:50:21.817Z", "albumin", 8.2),
    createData("2021-09-12T23:50:21.817Z", "albumin", 8.3),
    createData("2021-11-12T23:50:21.817Z", "albumin", 9.8),
    createData("2022-03-12T23:50:21.817Z", "albumin", 9.7),
    createData("2022-05-12T23:50:21.817Z", "albumin", 9.0),

    createData("2020-05-12T23:50:21.817Z", "chloride", 9.0),
    createData("2020-07-12T23:50:21.817Z", "chloride", 9.1),
    createData("2020-09-12T23:50:21.817Z", "chloride", 9.2),
    createData("2020-11-12T23:50:21.817Z", "chloride", 9.3),

    createData("2021-05-12T23:50:21.817Z", "creatinine", 8.1),
    createData("2021-07-12T23:50:21.817Z", "creatinine", 8.2),
    createData("2021-09-12T23:50:21.817Z", "creatinine", 8.5),
    createData("2021-11-12T23:50:21.817Z", "creatinine", 9.5),
    createData("2022-03-12T23:50:21.817Z", "creatinine", 9.5),
    createData("2022-05-12T23:50:21.817Z", "creatinine", 9.0),

    createData("2020-05-12T23:50:21.817Z", "glucose", 9.6),
    createData("2020-07-12T23:50:21.817Z", "glucose", 9.0),
    createData("2020-09-12T23:50:21.817Z", "glucose", 9.2),
    createData("2020-11-12T23:50:21.817Z", "glucose", 9.2),
    createData("2021-05-12T23:50:21.817Z", "glucose", 8.7),
    createData("2021-07-12T23:50:21.817Z", "glucose", 9.6),
    createData("2021-09-12T23:50:21.817Z", "glucose", 9.3),
    createData("2021-11-12T23:50:21.817Z", "glucose", 9.6),
    createData("2022-03-12T23:50:21.817Z", "glucose", 9.6),
    createData("2022-05-12T23:50:21.817Z", "glucose", 9.0),

    createData("2020-05-12T23:50:21.817Z", "phosporus", 9.1),
    createData("2020-07-12T23:50:21.817Z", "phosporus", 9.0),
    createData("2020-09-12T23:50:21.817Z", "phosporus", 9.2),
    createData("2020-11-12T23:50:21.817Z", "phosporus", 9.2),
    createData("2021-05-12T23:50:21.817Z", "phosporus", 8.7),
    createData("2021-07-12T23:50:21.817Z", "phosporus", 9.6),
    createData("2021-09-12T23:50:21.817Z", "phosporus", 9.3),
    createData("2021-11-12T23:50:21.817Z", "phosporus", 9.8),
    createData("2022-03-12T23:50:21.817Z", "phosporus", 9.0),
    createData("2022-05-12T23:50:21.817Z", "phosporus", 9.7),

    createData("2020-05-12T23:50:21.817Z", "potassium", 8.0),
    createData("2020-07-12T23:50:21.817Z", "potassium", 8.0),
    createData("2020-09-12T23:50:21.817Z", "potassium", 8.2),
    createData("2020-11-12T23:50:21.817Z", "potassium", 8.2),
    createData("2021-05-12T23:50:21.817Z", "potassium", 8.7),
    createData("2021-07-12T23:50:21.817Z", "potassium", 8.6),
    createData("2021-09-12T23:50:21.817Z", "potassium", 8.3),
    createData("2021-11-12T23:50:21.817Z", "potassium", 8.8),
    createData("2022-03-12T23:50:21.817Z", "potassium", 8.7),
    createData("2022-05-12T23:50:21.817Z", "potassium", 8.7),

    createData("2020-05-12T23:50:21.817Z", "nitrogen", 9.0),
    createData("2020-07-12T23:50:21.817Z", "nitrogen", 9.0),
    createData("2020-09-12T23:50:21.817Z", "nitrogen", 9.2),
    createData("2020-11-12T23:50:21.817Z", "nitrogen", 9.2),
    createData("2021-05-12T23:50:21.817Z", "nitrogen", 9.7),
    createData("2021-07-12T23:50:21.817Z", "nitrogen", 9.6),
    createData("2021-09-12T23:50:21.817Z", "nitrogen", 9.3),
    createData("2021-11-12T23:50:21.817Z", "nitrogen", 9.8),
    createData("2022-03-12T23:50:21.817Z", "nitrogen", 9.1),
    createData("2022-05-12T23:50:21.817Z", "nitrogen", 9.1),
  ],
  columns: [
    ["min", 8.2, 8.2, 8.2, 8.2, 8.2, 8.2, 8.2, 8.2, 8.2, 8.2],
    ["max", 10.2, 10.2, 10.2, 10.2, 10.2, 10.2, 10.2, 10.2, 10.2, 10.2],
    ["calcium", 9.0, 9.0, 9.2, 9.2, 8.7, 9.6, 9.3, 9.8, 9.7, 9.7],
  ],
  //xlabel: 'Date',
  //ylabel: "mg/dL",
  //color: "#FF0000",
  targetY: 0,
  targetX: 0,
  min: 8.2,
  max: 10.2,
  type: "line",
  name: "Test",
  //desc:"An albumin blood test checks levels of albumin in your blood. Low albumin levels might indicate a problem with your liver, kidneys or other health conditions. High albumin levels are typically the result of dehydration or severe dehydration. The test is very quick and doesn’t carry any serious risks"
};

export default { data, datac };

//data = [
//    type: 'line',
//    name: "Test",
//    color: "red",
//  items: [ { "date": "2019-07-15", "marketvalue": 99, "value": 10 }, {  89, 11 ....} ,{ 67, 12 ....}]
//  ]
{
  /*

        createData("2020-05-12T23:50:21.817",9.0),   
        createData("2020-07-12T23:50:21.817" , 9.0),
        createData("2020-09-12T23:50:21.817", 9.2),
        createData("2020-11-12T23:50:21.817",9.2),
        createData("2021-05-12T23:50:21.817", 8.7),
        createData("2021-07-12T23:50:21.817", 9.6),
        createData("2021-09-12T23:50:21.817", 9.3),
        createData("2021-11-12T23:50:21.817", 9.8),
        createData("2022-03-12T23:50:21.817", 9.7),
        createData("2022-05-12T23:50:21.817", 9.7)

*/
}
//-----------------end TEMPORARY DATA-----------------------//
