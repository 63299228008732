import MoreVertTwoToneIcon from "@mui/icons-material/MoreVertTwoTone";
import SaveAltTwoToneIcon from "@mui/icons-material/SaveAltTwoTone";
import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import KeyTwoToneIcon from "@mui/icons-material/KeyTwoTone";
import CancelPresentationTwoToneIcon from "@mui/icons-material/CancelPresentationTwoTone";
import { Grid } from "@material-ui/core";
import { useState } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import log from "../../services/log";
import { tableParam } from "./ConstantsAndValues";
import { MyArrowButtons } from "../CustomComponents/MyButtons";
export default function ThreedotMenu(props) {
  const { setAction } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [openflag, setOpen] = useState(false);

  const handleCreateClick = () => {
    // log.info("threedot", "create");
    setAction("create");
    setOpen(false);
  };

  const handleAddClick = () => {
    // log.info("threedot", "add");
    setAction("add");
    setOpen(false);
  };

  const handleSaveClick = (event) => {
    // log.info("threedot", "save");
    setAction("save");
    setOpen(false);
  };

  const handleLoadClick = (event) => {
    // log.info("threedot", "load");
    setAction("load");
    setOpen(false);
  };

  const handleRetrieveClick = (event) => {
    // log.info("threedot", "retrieve");
    setAction("retrieve");
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  return (
    <Grid container sx={{ width: "100%" }} spacing={2}>
      <Box sx={{ display: "flex", alignItems: "left", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleOpen}
            // onMouseEnter={handleOpen}
            // onMouseLeave={handleClose}
            size="small"
            aria-controls={openflag == true ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openflag == true ? "true" : undefined}
            sx={{
              ml: 2,
              boxShadow: "0 !important",
              outline: "none",
              "&:focus": {
                border: "0 !important",
                outline: "none",
                boxShadow: "none",
                backgroundColor: "transparent",
              },
            }}
          >
            <MoreVertTwoToneIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openflag}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              paddingLeft: 2,
              paddingRight: 2,
              minWidth: tableParam.minWidth,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={handleClose}
            style={{
              padding: "4px 4px 4px 24px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span className="TitleMenu">My wallet</span>
            <span className="text-color-primary ">
              <MyArrowButtons
                onClick={handleClose}
                btnKey={"e"}
                btnLabel={"Close"}
                enState={false}
              />
            </span>
          </MenuItem>
          <Divider />
          <Box>
            <MenuItem
              width={"fit-content"}
              key={"create"}
              onClick={handleCreateClick}
            >
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              <ListItemText className="text-color">
                Create New Account
              </ListItemText>
            </MenuItem>
          </Box>
          <Box>
            <MenuItem key={"add"} onClick={handleAddClick}>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              <ListItemText className="text-color">Add Account</ListItemText>
            </MenuItem>
          </Box>
          <Box>
            <MenuItem width={"100%"} key={"load"} onClick={handleLoadClick}>
              <ListItemIcon>
                <UploadTwoToneIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText className="text-color">Load Wallet</ListItemText>
            </MenuItem>
          </Box>
          <Box>
            <MenuItem width={"100%"} key={"save"} onClick={handleSaveClick}>
              <ListItemIcon>
                <SaveAltTwoToneIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText className="text-color">Save Wallet</ListItemText>
            </MenuItem>
          </Box>
          <Box>
            <MenuItem
              width={"100%"}
              key={"retrieve"}
              onClick={handleRetrieveClick}
            >
              <ListItemIcon>
                <KeyTwoToneIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText className="text-color">Retrieve Key</ListItemText>
            </MenuItem>
          </Box>
          <Box>
            <MenuItem width={"100%"} key={"cancel"} onClick={handleClose}>
              <ListItemIcon>
                <CancelPresentationTwoToneIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText className="text-color">Cancel</ListItemText>
            </MenuItem>
          </Box>
        </Menu>
      </Box>
    </Grid>
  );
}
