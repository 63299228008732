import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { getDrugs, selectDrugList } from "../../../redux/drugs/drugs.actions";
import { drugsquery } from "../../utils/drugsqueries";
import { labsMetaQueries } from "../../utils/labsqueries";
import { getLabsList, selectLabsRow } from "../../../redux/labs/labs.actions";
import { RadiologyTestsPanel4ORGRow } from "./RadiologyTestsPanel4ORGRow";
import { RadiologyTestsPanel4ORGHead } from "./RadiologyTestsPanel4ORGHead";
import {
  pageOptions,
  tableParam,
  colWidthSign,
  SpanAnchor,
  SearchBoxStandard,
  StyledTableCell,
} from "../../common/ConstantsAndValues";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import { MyButtons } from "../../CustomComponents/MyButtons";
import log from "../../../services/log";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export function RadiologyTestsPanel4ORG(props) {
  const { searchText, selectRows, setActionState } = props;

  const rtlist = null;
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (rtlist) {
      setRows(rtlist);
    }
  }, [rtlist]);

  const buildQuery = (searchtext, paged) => {
    // log.info("rtlist serarch query", searchtext);
    const searchquery = searchtext
      ? searchtext.name
        ? labsMetaQueries("name", searchtext.name, paged)
        : labsMetaQueries("name", query.name, paged)
      : labsMetaQueries("name", query.name, paged);
    return searchquery;
  };

  useEffect(() => {
    // log.info("drug serarch query", searchText);
    const resetpage = { ...pagedata, page: 0 };
    const searchquery = buildQuery(searchText, resetpage);

    log.info("ctlist serarch query", searchquery);
    // dispatch(getLabsList(searchquery)).then((r) => {
    //     log.info("ctlist", r)
    //     if (r.total.value && r.total.value > 0) {
    //         setPage({... resetpage,
    //             count: r.total.value,
    //             pages: r.total.value/pagedata.limit,
    //         });
    //     }
    // });
  }, [searchText, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    const newlist = keyword
      ? rtlist.filter((v) =>
          v._source.name.toLowerCase().startsWith(keyword.toLowerCase())
        )
      : rtlist;
    setRows(newlist);
  };

  const handleChangePage = (event, value) => {
    // log.info("rtlist", value);
    // log.info("rtlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const searchquery = buildQuery(searchText, newpagedata);
    // log.info("ctlist nextpage", searchquery);
    // dispatch(getLabsList(searchquery)).then((r) => {
    //     log.info("ctlist", r)
    //     if (r.total.value && r.total.value > 0) {
    setPage(newpagedata);
    //     }
    // });
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (row, add) => {
    // log.info("Prescribe", row);
    // log.info("Prescribe", add);

    if (row && add === true) {
      // newlist.push(row);
      // setCheckedRows(true);
      // selectRows(row)
      dispatch(selectLabsRow(row));
    }

    if (row && add === false) {
      // const flist = newlist.filter((v) => v._source.Id !== row._source.Id)
      // setCheckedRows(false);
      // selectRows(null)
      dispatch(selectLabsRow(null));
    }
  };

  const handleGroupTests = () => {
    setActionState("moretests");
  };

  // log.info("rtlist", rows);

  return (
    <div>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0"></div>

          <div className="colfl0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Test Name"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div>
          <MyButtons
            onClick={handleGroupTests}
            btnKey={"info"}
            btnLabel={"Lab"}
            enState={false}
          />
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead>
              <RadiologyTestsPanel4ORGHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {rows &&
                rows.map((row, index) => (
                  <RadiologyTestsPanel4ORGRow
                    key={row._source.labId + index}
                    index={index}
                    row={row}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </div>
  );
}
