import { DrugsList } from "../../pharmacy/components/DrugsList";
import log from "../../../services/log";

export default function DrugsListOrg(props) {
  // log.info("drugslist org", drugtext);

  return (
    <div>
      <DrugsList />
    </div>
  );
}
