import * as React from "react";
import {
  StyledTableRowHead,
  StyledTableCellHead,
} from "../../common/ConstantsAndValues";

const patientTable = {
  name: {
    label: "Name",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  member: {
    label: "Member since",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.format("YYYY-MM-DD"),
  },
};

export function PatientListHead() {
  const table = patientTable;

  return (
    <React.Fragment>
      <StyledTableRowHead key={"policyHead"} style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead>{table.name.label}</StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.member.label}
        </StyledTableCellHead>
      </StyledTableRowHead>
    </React.Fragment>
  );
}
