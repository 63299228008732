import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import log from "../../../services/log";
import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import { Box } from "@mui/material";
import { tableParam } from "../../common/ConstantsAndValues";
import { MyIconButtons } from "../../CustomComponents/MyButtons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import { dateFormat } from "../../common/dateFormat";
import { setPharmacyGroup } from "../../../redux/drugs/drugs.actions";

export function ProfileGroupListRow(props) {
  const { row } = props;

  const profile = useSelector((state) => state?.user?.patientprofile);
  const pharmagroup = useSelector((state) => state?.drugs?.pharmagroup);
  const [open, setOpen] = useState(false);
  const [maplist, setMaplist] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // set row state on
    if (pharmagroup) {
      const selectrow = pharmagroup?.id === row.id;
      setSelectedRow(selectrow);
    }
  }, [pharmagroup, row]);

  useEffect(() => {
    if (row) {
      const listdata = [];
      listdata.push({ id: "org", label: "Organization", value: row.orgName });
      listdata.push({
        id: "update",
        label: "Last updated",
        value: dateFormat(row.updated_at),
      });
      listdata.push({ id: "status", label: "Status", value: row.status });
      setMaplist(listdata);
    }
  }, [profile]);

  const handleClick = () => {
    log.info("ct row", row);
    setOpen(!open);
  };

  const handleFilter = () => {};

  const handelRowlocal = (row) => {
    const selected = !selectedRow;
    log.info("new state ", selected);

    if (selected) {
      dispatch(setPharmacyGroup(row));
    } else {
      dispatch(setPharmacyGroup(null));
    }
    setSelectedRow(selected);
  };

  // log.info("ProfileGrouprow", row);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => handelRowlocal(row)}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
      >
        <TableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </TableCell>
        <TableCell component="th" scope="row">
          <b>{row.name}</b>
        </TableCell>
        <TableCell align="right">{dateFormat(row.created_at)}</TableCell>
      </TableRow>

      <TableRow className="text-color-gray bg-standard-offwhite">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">
                  Group information
                </div>
              </div>

              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {maplist &&
                      maplist?.map((m) => (
                        <TableRow key={m?.id}>
                          <TableCell>{m?.label}</TableCell>
                          <TableCell>{m?.value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
