import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import { CardActions } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";

import {
  Paper,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../common/ConstantsAndValues";
import { MyIconButtons } from "../../CustomComponents/MyButtons";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SystemUpdateAltTwoToneIcon from "@mui/icons-material/SystemUpdateAltTwoTone";

import ProfileInput from "../../forms/ProfileInput";
import { useNavigate } from "react-router-dom";
import { MetaBmiDial } from "../../charts/BmiDial";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>
          <MyIconButtons onClick={() => setOpen(!open)} enState={open} />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row.type}
        </StyledTableCell>
        <StyledTableCell align="right">{row.value}</StyledTableCell>
        <StyledTableCell align="right">
          updated on {row.createdAt}
        </StyledTableCell>
      </StyledTableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Historical data
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history &&
                    row.history.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.date}
                        </TableCell>
                        <TableCell>{historyRow.value}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function BmiCard(props) {
  const { data } = props;
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleUpdate = () => {
    console.log("update");
    navigate("/metauser/profile/update");
  };

  return (
    <Card sx={{ maxWidth: "auto" }} sx={{ flexGrow: 1 }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: "var(--bg-color)", fontSize: "12px" }}
            aria-label="recipe"
          >
            {props.avatar}
          </Avatar>
        }
        // action={
        //     <IconButton aria-label="settings">
        //         <MoreVertIcon onClick={handleUpdate} />
        //     </IconButton>
        // }
        title=<span className="text-color-primary text-md">
          {"" + props.title}
        </span>
        subheader={""}
      />
      <CardContent>
        <MetaBmiDial
          currentValueText={props.currentValueText}
          values={props.values}
          custmSegmentStops={props.segmentStops}
          segmentColors={props.segmentColors}
          innerLabels={props.innerLabels}
          outerLabels={props.outerLabels}
        />
      </CardContent>
    </Card>
  );
}
