import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import log from "../../../services/log";
//import UserSetting from "./UserSetting";

export default function SettingsUSR() {
  const dispatch = useDispatch();

  return <Grid container></Grid>;
}
