import { useState } from "react";
import { useSelector } from "react-redux";

import { RadiologyTestsList4ORG } from "../../laboratory/components/RadiologyTestsList4ORG";
import { RadiologyMoreTestList4ORG } from "../../laboratory/components/RadiologyMoreTestList4ORG";

import log from "../../../services/log";

export const RadiologyTestsListLAB = (props) => {
  const [actionState, setActionState] = useState("");

  // log.info("rtlist searchtext", searchtext);
  // log.info("rtlist searchcontext", searchcontext);

  const handleCancel = () => {
    setActionState("");
  };

  log.info("rtlist searchtext", actionState);

  return (
    <div>
      {actionState === "moretests" ? (
        <RadiologyMoreTestList4ORG handleBack={handleCancel} />
      ) : (
        <RadiologyTestsList4ORG setActionState={setActionState} />
      )}
    </div>
  );
};
