import React from "react";
import { useEffect, useState } from "react";
import { Grid, Box, Typography, Container } from "@mui/material";
import { MyHelpButtons } from "../CustomComponents/MyButtons";
import { Title } from "../common/Title";
import { validateEmail, validateIFSCode } from "../common/validateComponents";
import InputComponent from "../CustomComponents/InputComponent";
import { SelectOptions } from "../common/SelectOptions";
import {
  SearchBoxStandardBig,
  BankAccountTypes,
  IFSC_url,
  colChart,
} from "../common/ConstantsAndValues";

// interface Data {  //not used
//     BANK:     string,
//         BRANCH:   string,
//         ADDRESS:  string,
//         STATE:    string,
//         DISTRICT: string,
//         CITY:     string,
//         MICR:     string,
//         SWIFT:    string,
//         NEFT:     boolean,
//         IMPS:     boolean,
//         RTGS:     boolean,
//         UPI:      boolean,
// }

const defaultData = {
  IFSC: "",
  IFSCError: true,
  accNum: "",
  accNumError: true,
  accType: "",
  accTypeError: true,
  bank: "",
  branch: "",
  email: "",
  emailError: true,
  name: "",
  phone: "",
};
export default function BankAccountInputs(props) {
  const { handleAddSave, handleCancel, action } = props;

  const [values, setValues] = useState({});
  const [disable, setDisable] = useState(true);
  const [data, setData] = useState(defaultData);
  const [validBank, setValidBank] = useState(false);

  const fetchJson = (key) => {
    // fetch(IFSC_url + key)
    //     .then(response => {
    //         return response.json();
    //     })
    //     .then(data => {
    //         setData(data);
    //     })
    //     .catch((e: Error) => {
    //         console.log(e.message);
    //     })
    return null;
  };

  const handleFilter = (event) => {
    const keyword = event.target.value;
    const v = validateIFSCode(keyword);

    if (v) {
      fetchJson(keyword);

      setValues({
        ...values,
        bank: data && data.BANK,
        branch: data && data.BRANCH,
        IFSC: v && keyword.toUpperCase(),
        IFSCError: !v,
      });
    } else {
      setData(defaultData);
    }

    setValidBank(v);
  };

  const handleChangeAccNum = (event) => {
    const ev = event.target.value;
    const em = ev ? false : true;
    setValues({
      ...values,
      accNum: ev,
      accNumError: em,
      //error:              em ,
    });
    ev && setDisable(false);
  };

  const handleChangeAccType = (event) => {
    //abandoned
    const ev = event.target.value;
    const em = ev ? false : true;
    setValues({
      ...values,
      accType: ev,
      accTypeError: em,
      //error:              em ,
    });
    ev && setDisable(false);
  };
  const handleAccTypePage = (m) => {
    const ev = m.value;
    const em = ev ? false : true;
    setValues({
      ...values,
      accType: ev,
      accTypeError: em,
    });
    ev && setDisable(false);
    //console.log("myCover", ev);
  };

  const handleChangeName = (event) => {
    const ev = event.target.value;
    const em = ev ? false : true;
    setValues({
      ...values,
      name: ev,
      //nameError:          em ,
      //error:              em ,
    });
    ev && setDisable(false);
  };

  const handleChangePhone = (event) => {
    const ev = event.target.value;
    const em = ev ? false : true;
    setValues({
      ...values,
      phone: ev,
      //phoneError:         em ,
      //error:              em ,
    });
    ev && setDisable(false);
  };

  const handleChangeEmail = (event) => {
    const ev = event.target.value;
    const em = !validateEmail(ev);
    setValues({
      ...values,
      email: ev,
      emailError: em,
    });
    ev && setDisable(false);
  };

  const handleAddSaveHere = () => {
    handleAddSave(values);
    action("");
  };

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={5}>
        <Title>Add Bank Account</Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <SearchBoxStandardBig
            onChange={handleFilter}
            placeholder="IFS Code..."
            sx={{ marginRight: 1 }}
            className="text-color-primary text-color-2-border text-color-2-bg"
          />
        </div>
        <div className="border-db bg-standard-lightest">
          <div className="colfl text-color-gray">Bank: </div>
          <div className="colfr text-color-primary right">{data?.BANK} </div>
          <div className="colfl text-color-gray clear">Branch: </div>
          <div className="colfr text-color-primary right">{data?.BRANCH} </div>
          <div className="colfl text-color-gray clear">Address: </div>
          <div
            className="colfr text-color-primary right"
            style={{ width: "75%" }}
          >
            {data?.ADDRESS}
          </div>
          <div className="colfl text-color-gray clear">State: </div>
          <div className="colfr text-color-primary right">{data?.STATE} </div>
          <div className="colfl text-color-gray clear">District: </div>
          <div className="colfr text-color-primary right">{data?.DISTRICT}</div>
          <div className="colfl text-color-gray clear">City: </div>
          <div className="colfr text-color-primary right">{data?.CITY} </div>
          <div className="colfl text-color-gray clear">MICR: </div>
          <div className="colfr text-color-primary right">{data?.MICR} </div>
          <div className="colfl text-color-gray clear">SWIFT: </div>
          <div className="colfr text-color-primary right">{data?.SWIFT} </div>
          <div className="colfl text-color-gray clear">Facilities:</div>
          <div className="colfr text-color-primary right">
            {data?.IMPS ? "IMPS, " : ""}
            {data?.NEFT ? " NEFT, " : ""}
            {data?.RTGS ? " RTGS, " : ""}
            {data?.UPI ? " UPI" : ""}
          </div>
          <div className="clear" />
        </div>
      </Grid>
      {validBank && (
        <Grid item xs={7}>
          <InputComponent
            id="accNum"
            name="accNum"
            label="Account Number"
            defaultValue={""}
            variant="standard"
            handleChange={handleChangeAccNum}
          />
          &nbsp; &nbsp;
          <SelectOptions
            openPage={handleAccTypePage}
            data={BankAccountTypes}
            multi={false}
            title={"Account Type"}
            wide={colChart.width}
          />
          <InputComponent
            id="name"
            name="name"
            label="Account Holder Name"
            defaultValue={""}
            variant="standard"
            handleChange={handleChangeName}
          />
          <InputComponent
            id="phone"
            name="phone"
            label="Registered Phone Number"
            defaultValue={""}
            variant="standard"
            handleChange={handleChangePhone}
          />
          <InputComponent
            id="email"
            name="email"
            label="Registered eMail ID"
            defaultValue={""}
            variant="standard"
            handleChange={handleChangeEmail}
          />
          <div style={{ margin: "16px 0px" }}>
            <div className="colfr">
              <MyHelpButtons
                onClick={handleAddSaveHere}
                btnKey={"save"}
                btnLabel={"Update"}
                enState={disable}
                btnTooltip={"Update"}
              />
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
}
