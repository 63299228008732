import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { UploadJsonFile } from "../../docfiles/UploadJsonFile";

export default function UploadsORG() {
  return (
    <Grid container>
      <UploadJsonFile />
    </Grid>
  );
}
