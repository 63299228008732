import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import log from "../../services/log";
import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import { Box } from "@mui/material";
import { tableParam } from "../common/ConstantsAndValues";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import { CurrencyUser, CurrencyPolicy } from "../common/ConstantsAndValues";
import { MyIconButtons } from "../CustomComponents/MyButtons";

export function ApprovePolicyListRow(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [maplist, setMaplist] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (row) {
      const listdata = [];
      listdata.push({ label: "OPD Coverage", value: "None" });
      row.hiiCoverage
        ? listdata.push({
            label: "Hospitalizaiton Coverage",
            value: row.hiiCoverage.coverage,
          })
        : listdata.push({ label: "Hospitalizaiton Coverage", value: "" });
      row.dentalCoverage
        ? listdata.push({
            label: "Hospitalizaiton Coverage",
            value: row.dentalCoverage.coverage,
          })
        : listdata.push({ label: "Dental Coverage", value: "None" });
      row.visionCoverage
        ? listdata.push({
            label: "Hospitalizaiton Coverage",
            value: row.visionCoverage.coverage,
          })
        : listdata.push({ label: "Vision Coverage", value: "None" });
      // log.info("ct row", r);
      setMaplist(listdata);
    }
  }, []);

  const handleClick = () => {
    log.info("ct row", row);
    // if (row) {
    //     const jsonData = {};
    //     jsonData['metadata'] = {};
    //     jsonData.metadata['metalabId'] = row.labId;
    //     dispatch(getMetaLabMap(jsonData)).then((r) => {
    //         log.info("ct row", r);
    //         setMaplist(r)
    //     });
    // }
    setOpen(!open);
  };

  const handleFilter = () => {};

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </TableCell>
        <TableCell component="th" scope="row">
          <b>{row.policySubscriber}</b>
        </TableCell>
        <TableCell component="th" scope="row">
          <b>{row.policyName}</b>
        </TableCell>
        <TableCell align="right">
          <Typography variant={"body2"}>{row.providerName}</Typography>
        </TableCell>
        <TableCell align="right">
          {CurrencyPolicy}&nbsp;
          {row.hiiCoverage.coverage}
        </TableCell>
        <TableCell align="right">
          {CurrencyPolicy}&nbsp;
          {row.hiiCoverage.premium}
        </TableCell>
        <TableCell align="right">{row.policyStartDate}</TableCell>
        <TableCell align="right">{row.policyEndDate}</TableCell>
        <TableCell align="right">{row.status}</TableCell>
      </TableRow>

      <TableRow className="text-color-gray bg-standard-offwhite">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">Policy Detail</div>
              </div>

              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {maplist &&
                      maplist.map((m) => (
                        <TableRow key={maplist.id}>
                          <TableCell>{m.label}</TableCell>
                          <TableCell>{m.value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
