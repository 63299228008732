import useStyles from "../CustomComponents/useStyles";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import React, { useEffect, useState } from "react";
import { Box, MenuItem } from "@material-ui/core";
import log from "../../services/log";
import Select from "react-select";
import { LocMSStyles } from "../location/LocMSStyles";
import { setAppServiceType } from "../../redux/appointment/appointment.actions";

export default function InputServiceType(props) {
  const { disableContinue, form } = props;
  const dispatch = useDispatch();
  const [options, setOptions] = useState(null);
  const [selOption, setSelOption] = useState(null);

  useEffect(() => {
    let newoptions = [];
    newoptions.push({
      label: "Doctor consultation",
      value: { label: "Doctor consultation", role: "metamd", code: "metamd0" },
    });
    newoptions.push({
      label: "Laboratory testing",
      value: { label: "Laboratory tests", role: "metalab", code: "metalab0" },
    });
    newoptions.push({
      label: "Radiological imaging",
      value: {
        label: "Radiological images",
        role: "metalab",
        code: "metalab1",
      },
    });
    newoptions.push({
      label: "Hospital",
      value: { label: "Hospital", role: "metaorg", code: "metaorg1" },
    });
    newoptions.push({
      label: "Nursingh Home",
      value: { label: "Nursingh Home", role: "metaorg", code: "metaorg2" },
    });
    newoptions.push({
      label: "Pharmacy",
      value: { label: "Pharmacy", role: "metapharma", code: "pharma0" },
    });
    setOptions(newoptions);
  }, []);

  const handleSelect = (event) => {
    // log.info("servicetype", event);
    setSelOption(event);
    dispatch(setAppServiceType(event.value));
    form.setFieldValue("eventTitle", event.value.label);
    form.setFieldValue("eventType", event.value.role);
    disableContinue(false);
  };

  // log.info("servicetype", selOption);

  return (
    <FormControl
      fullWidth
      className="text-color-primary"
      variant="outlined"
      size="small"
    >
      &nbsp;
      <InputLabel
        sx={{ minWidth: 20 }}
        variant="standard"
        htmlFor="uncontrolled-native"
      ></InputLabel>
      <Box className="text-color-primary">
        <Select
          options={options}
          value={selOption}
          placeholder="Service Type"
          onChange={handleSelect}
          isSearchable={true}
          styles={LocMSStyles}
          menuPlacement="auto"
          menuPosition="fixed"
          isMulti={false}
        />
      </Box>
    </FormControl>
  );
}
