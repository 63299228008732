import log from "../../services/log";

export const AppCurrentLocation = () => {
  const successCallback = (location) => {
    log.info("current location", location);
  };

  const errorCallback = () => {
    log.info("app could not get current location");
  };
  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
};
