import {
  CommandsManager,
  ExtensionManager,
  ServicesManager,
  HotkeysManager,
  UINotificationService,
  UIModalService,
  UIDialogService,
  UIViewportDialogService,
  MeasurementService,
  DisplaySetService,
  ToolBarService,
  ViewportGridService,
  HangingProtocolService,
  SegmentationService,
  CineService,
  UserAuthenticationService,
  errorHandler,
  // utils,
  LoggerService,
  DicomMetadataStore,
} from "../core";

import { store } from "../../../redux/store";
import { getActiveContexts } from "./store/layout/selectors";

/**
 * @param {object|func} appConfigOrFunc - application configuration,
 * @param {object[]} defaultExtensions - array of extension objects
 */

export const appInit = async (
  appConfigOrFunc,
  defaultExtensions,
  defaultModes
) => {
  const appConfig = {
    ...(typeof appConfigOrFunc === "function"
      ? appConfigOrFunc({ servicesManager })
      : appConfigOrFunc),
  };

  const commandsManagerConfig = {
    getAppState: () => store.getState(),
    getActiveContexts: () => getActiveContexts(store.getState()),
  };

  const commandsManager = new CommandsManager(commandsManagerConfig);
  const servicesManager = new ServicesManager(commandsManager);
  const hotkeysManager = new HotkeysManager(commandsManager, servicesManager);
  const extensionManager = new ExtensionManager({
    commandsManager,
    servicesManager,
    hotkeysManager,
    appConfig,
  });

  servicesManager.registerServices([
    UINotificationService,
    UIModalService,
    UIDialogService,
    UIViewportDialogService,
    MeasurementService,
    DisplaySetService,
    ToolBarService,
    ViewportGridService,
    HangingProtocolService,
    SegmentationService,
    CineService,
    UserAuthenticationService,
    LoggerService,
    DicomMetadataStore,
  ]);

  errorHandler.getHTTPErrorHandler = () => {
    if (typeof appConfig.httpErrorHandler === "function") {
      return appConfig.httpErrorHandler;
    }
  };

  const requiredExtensions = [...defaultExtensions, ...appConfig.extensions];

  await extensionManager.registerExtensions(
    requiredExtensions,
    appConfig.servers.dicomWeb
  );

  for (let i = 0; i < defaultModes.length; i++) {
    const { modeFactory, id } = defaultModes[i];

    // If the appConfig contains configuration for this mode, use it.
    const modeConfig =
      appConfig.modeConfig && appConfig.modeConfig[i]
        ? appConfig.modeConfig[id]
        : {};

    const mode = modeFactory(modeConfig);

    appConfig.modes.push(mode);
  }

  return {
    appConfig,
    commandsManager,
    extensionManager,
    servicesManager,
    hotkeysManager,
  };
};
