import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";

import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PartyLedgerORGHead } from "./PartyLedgerORGHead";
import { PartyLedgerORGRow } from "./PartyLedgerORGRow";
import useStyles from "../CustomComponents/useStyles";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";

import log from "../../services/log";
import { getPolicySubsList } from "../../redux/policy/policy.actions";
import { getLedgerList } from "../../redux/payment/payment.actions";

export default function PartyLedgerORG(props) {
  const user = useSelector((state) => state.auth.user.user);
  const selectedPatient = useSelector((state) => state.user.selectedPatient);
  const orgspecialist = useSelector((state) => state.user.orgspecialist);
  const myledger = useSelector((state) => state.payment.ledgerlist);
  const searchcontext = useSelector((state) => state?.user?.searchcontext);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [bills, setBills] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  /**
   * three type filters
   *  - by org
   *  - by patient
   *  - by provider
   *
   * @param paged
   * @returns {{}}
   */

  const buildQuery = (paged) => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData["page"] = paged.page;
    jsonData["limit"] = paged.limit;
    jsonData["sortBy"] = "updated_at";
    jsonData.metadata["receiverId"] = user?.metadata?.accountId;

    if (searchcontext?.key == "patient") {
      jsonData.metadata["patientId"] = selectedPatient?.metadata?.patientId;
    }
    if (searchcontext?.key == "provider") {
      jsonData.metadata["providerId"] = orgspecialist?.metadata?.accountId;
    }

    return jsonData;
  };

  useEffect(() => {
    const newpagedata = { ...pagedata, page: 0 };
    const jsonQuery = buildQuery(newpagedata);
    dispatch(getLedgerList(jsonQuery)).then((r) => {
      // log.info("policylist", r)
      if (r.results) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  }, [searchcontext, dispatch]);

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const jsonQuery = buildQuery(newpagedata);
    dispatch(getLedgerList(jsonQuery)).then((r) => {
      // log.info("policylist", r)
      if (r.results) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };
  const handleRowClick = (e, selectrow) => {
    log.info("billlist", e);
    if (selectrow) {
      setCheckedRows(e);
      // dispatch(selectPatientMedcine(e));
    } else {
      setCheckedRows(null);
      // dispatch(selectPatientMedcine(null));
    }
  };

  // log.info("billlist", bills);

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead className="">
              <PartyLedgerORGHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {myledger &&
                myledger?.map((row) => (
                  <PartyLedgerORGRow
                    key={row.id}
                    row={row}
                    isRowSelected={checkedRows}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
