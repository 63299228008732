import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesLogo } from "../styles/CommonStyles";

const styles = stylesLogo;

export const PrescriptionDeclaration = ({ txtDeclare }) => (
  <View style={styles.declareContainer}>
    <Text style={styles.note}>{txtDeclare}</Text>
  </View>
);
