import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { setDeptAddress } from "../../redux/actions/addresses";
import { getLocByplaceAutocomp, locDocIndex } from "../../redux/actions/locs";

import InputComponent from "../CustomComponents/InputComponent";
import AddressAutocomplete from "../location/AddressAutoComplete";
import { locIndexQueries } from "../utils/locationmap";
import { Title } from "../common/Title";
import { addressInputSchema } from "../schema/addressInputSchema";
import { MyTooltipButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";

export function AddressInputPart(props) {
  const { cancel, title } = props;

  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const selectedregion = useSelector((state) => state.locs.region);
  const selectedsubregion = useSelector((state) => state.locs.subregion);
  const selectedcountry = useSelector((state) => state.locs.country);
  const selectedarea = useSelector((state) => state.locs.area);
  const address = useSelector((state) => state.locs.addressgeo);
  const selectgroup = useSelector((state) => state.orgs.selectorggroup);

  // const [selectAddress, setSelectAddress] = useState(null);

  const dispatch = useDispatch();
  const addressform = useFormik({
    initialValues: {
      orgId: selectgroup ? selectgroup.metadata.orgId : null,
      groupId: selectgroup ? selectgroup.metadata.accountId : null,
      userId: user ? user.metadata.accountId : "",
      email: user ? user.metadata.email : "",
      name: user ? user.name : "",
      street: "",
      city: "",
      lat: 0.0,
      lon: 0.0,
      formattedAddress: null,
      area: selectedarea ? selectedarea._source.name : null,
      areacode: selectedarea ? selectedarea._source["country-code"] : null,
      country: selectedcountry ? selectedcountry._source.name : null,
      countrycode: selectedcountry ? selectedcountry._source["alpha-2"] : null,
      subregion: selectedsubregion
        ? selectedsubregion._source["sub-region"]
        : null,
      subregioncode: selectedsubregion
        ? selectedsubregion._source["sub-region-code"]
        : null,
      region: selectedregion ? selectedregion._source.region : null,
      regioncode: selectedregion ? selectedregion._source["region-code"] : null,
      status: "active",
    },
    validationSchema: addressInputSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("medicineinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata.orgId = values.orgId;
      jsonData.metadata.groupId = values.groupId;
      jsonData.metadata.areacode = values.areacode;
      jsonData.metadata.countrycode = values.countrycode;
      jsonData.metadata.subregioncode = values.subregioncode;
      jsonData.metadata.regioncode = values.regioncode;
      jsonData.metadata.email = values.email;
      jsonData.metadata.userId = values.userId;
      jsonData.formatted_address = values.formattedAddress;
      jsonData.street = values.street;
      jsonData.location = {};
      jsonData.location.lat = values.lat;
      jsonData.location.lon = values.lon;
      jsonData.area = values.area;
      jsonData.country = values.country;
      jsonData.subregion = values.subregion;
      jsonData.region = values.region;
      jsonData.name = values.name;
      jsonData.region = values.region;
      jsonData.city = values.city;
      jsonData.status = values.status;
      // log.info("address to update", jsonData)
      dispatch(setDeptAddress(jsonData));
      cancel();
    },
    onReset: (values) => {},
  });

  useEffect(() => {
    log.info("newaddress", address);

    if (address) {
      address[0].address_components.forEach((a) => {
        if (a.types.includes("administrative_area_level_3")) {
          addressform.setFieldValue("city", a.long_name);
        }
      });
      addressform.setFieldValue("lat", address[0].geometry.location.lat);
      addressform.setFieldValue("lon", address[0].geometry.location.lng);
      addressform.setFieldValue(
        "formattedAddress",
        address[0].formatted_address
      );
    }
  }, [address]);

  const handleCancel = () => {
    cancel();
  };

  // log.info("address input", addressform.errors);

  return (
    <div>
      <div className="colfl0">
        <Title>{title}</Title>
      </div>
      <div className="colfr0">
        <MyTooltipButtons
          onClick={handleCancel}
          btnKey={"cancel"}
          btnLabel={"Cancel"}
          enState={false}
        />
      </div>

      <div className="clear" />

      <Box className="flex-grow-1">
        <form>
          <AddressAutocomplete title={"Locality address"} />
          &nbsp; &nbsp;
          <InputComponent
            id="street"
            name="street"
            label="Street (Building) address"
            value={addressform.values.street}
            errors={addressform.errors.street ? true : false}
            errorText={addressform.errors.street}
            defaultValue={addressform.values.street}
            variant="standard"
            disabled={false}
            handleChange={addressform.handleChange}
          />
        </form>
      </Box>
      <div className="clear" />
      <div className="colfr0">
        <MyTooltipButtons
          onClick={addressform.handleSubmit}
          btnKey={"add"}
          btnLabel={"Add"}
          enState={false}
        />
      </div>
      <div className="clear" />
    </div>
  );
}
