import React, { useState, useEffect } from "react";
import { Grid, Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";
import { Title } from "../../common/Title";
import log from "../../../services/log";
import { getOrgProfile } from "../../../redux/actions/orgs";
import { TextEditorView } from "../../editor/TextEditorView";

export default function InfoListORG() {
  const orgs = useSelector((state) => state?.orgs?.orgs);
  const user = useSelector((state) => state?.auth?.user?.user);
  const profile = useSelector((state) => state?.orgs?.orgprofile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (orgs) {
      dispatch(
        getOrgProfile({
          metadata: {
            email: orgs[0]?.metadata?.email,
          },
        })
      );
    }
  }, [orgs, dispatch]);

  function getFilterList(alist, val) {
    return alist.filter((item) => item.alias === val);
  }

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "justified",
  }));

  // log.info("org profile", profile);

  return (
    <Grid container sx={{ display: "flex", flexDirection: "column" }}>
      <h3 className="text-color-primary">{profile?.name}</h3>
      <Box>
        <Title>About</Title>
      </Box>
      <Box className="text-color-primary">
        <TextEditorView addToMsg={profile?.about} />
      </Box>
    </Grid>
  );
}
