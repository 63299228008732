export const ClinicalRadTestsData = {
  id: "5df3180a09ea16dc4b95f910",
  invoice_no: "201906-28",
  balance: "$2,283.74",
  name: "Mandira Smith",
  email: "susanafuentes@mantrix.com",
  phone: "+1 (872) 588-3809",
  address: "922 Campus Road, Drytown, Wisconsin, 1986",
  trans_date: "2019-09-12",
  due_date: "2019-10-12",
  age: 55,
  gender: "F",

  doctor: [
    {
      name: "Dr MK Smith ",
      designation: "MD Surgeon",
      address: "CA, USA",
      phone: "+11111",
      email: "bill@metalane.ai",
    },
  ],
  parent: [
    {
      company: "Metalane",
      address: "CA, USA",
      phone: "+11111",
      email: "bill@metalane.ai",
    },
  ],
  items: [
    {
      sno: 1,
      desc: "item description 1 item description 1 item description 1 item description 1 item description 1 item description 1",
      qty: 5,
      rate: 405.89,
      instruction:
        "before food before food before food before food before food before food before food",
    },
    {
      sno: 2,
      desc: "cillum quis sunt qui aute cillum quis sunt qui aute",
      qty: 5,
      rate: 373.11,
      instruction: "after food",
    },
    {
      sno: 3,
      desc: "ea commodo labore culpa irure",
      qty: 5,
      rate: 458.61,
      instruction: "sample for outstation",
    },
    {
      sno: 4,
      desc: "nisi consequat et adipisicing dolor nisi consequat et adipisicing dolor",
      qty: 10,
      rate: 725.24,
      instruction: "before food",
    },
    {
      sno: 5,
      desc: "proident cillum anim elit esse proident cillum anim elit esse proident cillum anim elit esse",
      qty: 4,
      rate: 141.02,
      instruction: "n/a",
    },
  ],
  invoice_declare: "E.&O.E.",
  invoice_sign: "for Metalane",
  doctor_declare: "",
  doctor_sign: "for Metalane",

  drugs: [
    {
      sno: 1,
      name: "4drug name1 drug name2 drug name3 drug name4 ",
      salt: "4salt1 salt1 salt1 salt1 ",
      dose: "4dos1 dos1 dos1 dose1",
      doseUnit: "15uuuuuuuuuuuuuuu",
      doseFrequency: "twice daily twice daily",
      doseRoute: "iv iv iv",
    },
    {
      sno: 2,
      name: "drug name11",
      salt: "salt2",
      dose: "dos2",
      doseUnit: "u",
      doseFrequency: "twice daily",
      doseRoute: "iv",
    },
    {
      sno: 3,
      name: "drug name3",
      salt: "salt3",
      dose: "dos3",
      doseUnit: "u",
      doseFrequency: "twice daily",
      doseRoute: "iv",
    },
  ],

  policy: [
    {
      id: "PP1",
      policy_no: "ITC001X20230125",
      policy_date: "",
      policy_start_date: "",
      policy_end_date: "",
      policy_holder: "P001",
      policy_holder_name: "R Ramkumar",
      provider: "ABC",
      plan: "Premium",
      members: [
        {
          id: 10,
          masterid: "PP1",
          memberid: "R001",
          name: "R RamKumar",
          relation: "self",
          age: "55",
          gender: "M",
          previous_ailment: "",
        },
        {
          id: 1,
          masterid: "PP1",
          memberid: "R002",
          name: "R K Charan",
          relation: "father",
          age: "76",
          gender: "M",
          previous_ailment: "",
        },
        {
          id: 2,
          masterid: "PP1",
          memberid: "R003",
          name: "MalatiDevi",
          relation: "mother",
          age: "70",
          gender: "F",
          previous_ailment: "",
        },
        {
          id: 3,
          masterid: "PP1",
          memberid: "R004",
          name: "Radha",
          relation: "wife",
          age: "50",
          gender: "F",
          previous_ailment: "",
        },
        {
          id: 4,
          masterid: "PP1",
          memberid: "R005",
          name: "Smita",
          relation: "aunt",
          age: "55",
          gender: "F",
          previous_ailment: "",
        },
      ],
      insurer: "CS001",
      instruction: "",
      status: "approved",
      currency: "USD",
      policy_terms: "",
      policy_tenure: "5years",
      cover: "5 lakhs",
      premium_amt: "31232.00",
      paid: false,
      paidDate: "",
      payType: "",
      payMethod: "",
      transactionNo: "",
      remarks: "5 members CI patient",
    },
  ],
};
