function createData(id, username, uimg, typ, status) {
  return { id, username, uimg, typ, status };
}
/*0 metauser  1 metamd   2 metaorg   3 metapharma 4 metaothers*/
export const MsgMetauserOnlineRows = [
  createData(0, "Mukesh Kumar", "4", 0, 1),
  createData(1, "Alice", "1", 0, 0),
  createData(2, "Dr Robert", "2", 1, 0),
  createData(3, "Dr Medi Aich", "3", 1, 1),
  createData(4, "Robin Hood Org", "1", 2, 0),
  createData(5, "Masum Hospital", "4", 2, 1),
  createData(6, "Dice Pharma", "1", 3, 0),
  createData(7, "Robertson Co", "2", 3, 1),
  createData(8, "M Watson", "3", 4, 0),
  createData(9, "Robson", "1", 4, 1),
  createData(10, "Mupesh Kar", "4", 0, 1),
  createData(11, "Alice", "1", 0, 0),
  createData(12, "Dr Robert", "2", 1, 0),
  createData(13, "Dr K Watson", "3", 1, 1),
  createData(14, "Robin", "1", 2, 1),
  createData(15, "Mukesh Kumar", "4", 2, 0),
  createData(16, "Alice", "1", 0, 1),
  createData(17, "Robert", "2", 0, 1),
  createData(18, "K Watson", "3", 0, 1),
  createData(19, "Robin", "1", 0, 1),
  createData(20, "Mukesh Kumar", "4", 0, 1),
  createData(21, "Alice", "1", 1, 0),
  createData(22, "Robert", "2", 0, 0),
  createData(23, "K Watson", "3", 0, 1),
  createData(24, "Robin", "1", 0, 0),
  createData(25, "Mukesh Kumar", "4", 0, 0),
  createData(26, "Alice", "1", 4, 1),
  createData(27, "Robert", "2", 4, 1),
  createData(28, "K Watson", "3", 4, 1),
  createData(29, "Robin", "1", 4, 1),
  createData(30, "Mukesh Kumar", "4", 4, 1),
];

export const MsgMetamdOnlineRows = [
  createData(5, "Mukesh Kumar", "4", 0),
  createData(6, "Alice", "1", 1),
  createData(7, "Robert", "2", 1),
  createData(8, "K Watson", "3", 1),
  createData(9, "Robin", "1", 1),
];
