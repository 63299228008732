import { Typography, Box, Grid, FormLabel, Divider } from "@mui/material";
import { GreenTickIcon } from "../../../customIcons/GreenTickIcon";

export const AppointmentBookedSection = ({ setCurrentStep }) => {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <GreenTickIcon size={0.4} />
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "18px",
            color: "#404040",
            fontFamily: "DM SANS",
            mt: 1.5,
          }}
        >
          Appointment booked!
        </Typography>
      </Box>
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "18px",
              color: "#404040",
              fontFamily: "DM SANS",
              mt: 4,
            }}
          >
            Selected profile
          </Typography>
          <Box className="review__card__green">
            <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
              Patient
            </FormLabel>
            <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
              Anita Menon
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "18px",
              color: "#404040",
              fontFamily: "DM SANS",
              mt: 4,
            }}
          >
            Appointment details
          </Typography>
          <Box className="review__card__green">
            <Grid container className="">
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Reason for visit
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  Health check-up
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Details
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  No details provided
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "18px",
              color: "#404040",
              fontFamily: "DM SANS",
              mt: 4,
            }}
          >
            Provider details
          </Typography>
          <Box className="review__card__green">
            <Grid container className="">
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Provider name
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  Dr. Aamir Khan
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Specialty
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  General medicine
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1, mt: 4 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Type of visit
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  In-person consult
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1, mt: 4 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Address
                </FormLabel>
                <Typography sx={{ fontWeight: "600" }}>
                  165, Tambakuwala, Dr Vigas Street, Charni Road, Mumbai
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                color: "#404040",
                fontFamily: "DM SANS",
                mt: 4,
              }}
            >
              Date & time of visit
            </Typography>
            <Typography
              sx={{
                fontWeight: "600",
                // fontSize: "18px",
                cursor: "pointer",
                color: "#2A8AA7",
                fontFamily: "DM SANS",
                mt: 4,
              }}
            >
              Start over
            </Typography>
          </Box>
          <Box className="review__card__green">
            <Grid container className="">
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Date
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  Thursday 13 October, 2023
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Time
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  12:00 pm - 1:00 pm
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 4, mb: 4 }} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "18px",
            color: "#404040",
            fontFamily: "DM SANS",
          }}
        >
          Want to schedule another appointment?
        </Typography>

        <button
          className="transparent_button"
          onClick={(e) => setCurrentStep(0)}
        >
          Schedule appointment
        </button>
      </Box>
    </>
  );
};
