import * as yup from "yup";

export const departmentSchema = yup.object({
  accountId: yup.string(),
  orgId: yup.string().required("organization is required"),
  groupId: yup.string().required("organization group is required"),
  locId: yup.string().required("location is required"),
  loincNumber: yup.string(),
  departmentName: yup.string().required("department is required"),
  departmentDescp: yup.string().required("department is required"),
  status: yup.string(),
});

export const departmentIndexSchema = yup.object({
  orgId: yup.string().required("organization is required"),
  groupId: yup.string().required("organization group is required"),
  locId: yup.string().required("location is required"),
  departmentId: yup.string(),
  providerId: yup.string().required("provider is required"),
  name: yup.string().required("provider is required"),
  role: yup.string().required("provider is required"),
  address: yup.string().required("address is required"),
  services: yup.string().required("services is required"),
  location: yup.object().required("location is required"),
});
