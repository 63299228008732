import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from '@material-ui/icons/Menu';

import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import ArrowCircleLeftTwoToneIcon from "@mui/icons-material/ArrowCircleLeftTwoTone";

import { styled } from "@mui/material/styles";

const styles = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    maxWidth: 360,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
};

const SearchBox = styled("input")({
  //not used till now
  type: "search",
  boxShadow: "none",
  height: "26px",
  width: "180px",
  outline: "none",
  fontSize: "14px",
  borderRadius: "10px",
  padding: "3px 5px",
  marginLeft: "15px",
});

function MessageInputBase(props) {
  const { classes } = props;

  return (
    <Paper className={classes.root} elevation={1}>
      <InputBase className={classes.input} placeholder="Search message" />
      <IconButton className={classes.iconButton} aria-label="Search">
        <SearchTwoToneIcon />
      </IconButton>
      <Divider className={classes.divider} />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="Directions"
      >
        <ArrowCircleLeftTwoToneIcon />
      </IconButton>
    </Paper>
  );
}

MessageInputBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MessageInputBase);
