import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
} from "@mui/material";

import { SearchBoxStandard } from "../../common/ConstantsAndValues";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import { MyButtons } from "../../CustomComponents/MyButtons";

import { ProfileGroupListHead } from "./ProfileGroupListHead";
import { ProfileGroupListRow } from "./ProfileGroupListRow";
import { getMDGroupList } from "../../../redux/user/user.actions";

import log from "../../../services/log";
import { buildPharmaGroupsQuery } from "../../dbcq/mdgroupdb";
import { setPharmacyGroup } from "../../../redux/drugs/drugs.actions";

export default function ProfileGroupListPHA(props) {
  const user = useSelector((state) => state?.auth?.user?.user);
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const userslist = useSelector((state) => state?.user?.pgrouplist);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [selectrows, setSelectrows] = useState(false);
  const [action, setAction] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setRows(userslist ?? []);
  }, [userslist]);

  useEffect(() => {
    const resetpage = { ...pagedata, page: 0 };
    const jsonData = buildPharmaGroupsQuery(resetpage, searchcontext, user);
    // log.info("ProfileGroup query ", jsonData);
    dispatch(getMDGroupList(jsonData)).then((r) => {
      log.info("ProfileGrouplist", r);
      if (r) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  }, [dispatch, searchcontext, user]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = userslist.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(userslist);
    }
  };

  const handleChangePage = (event, value) => {
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const jsonData = buildPharmaGroupsQuery(newpagedata, searchcontext, user);
    dispatch(getMDGroupList(jsonData)).then((r) => {
      log.info("ProfileGroup ", r);
      setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
    });
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleBack = () => {
    // log.info("policylist");
    setAction("");
  };

  const handleSelect = () => {
    log.info("ProfileGrouplist select");
    setAction("select");
  };

  // log.info("policylist", selectrows);

  return (
    <Grid container>
      <div className="clear"></div>
      <div
        width="100%"
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0"></div>

          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Group Name"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div className="colfr">
          {action === "select" && (
            <MyButtons
              onClick={handleBack}
              btnKey={"back"}
              btnLabel={"Back"}
              enState={false}
            />
          )}
        </div>
      </div>

      {action === "select" ? (
        <div>{/*<PolicyEnrol policy={selectrows}/>*/}</div>
      ) : (
        <div style={{ width: "100%" }}>
          <div>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <ProfileGroupListHead />
                </TableHead>

                <TableBody className="text-color-primary text text-md">
                  {rows &&
                    rows?.map((row) => (
                      <ProfileGroupListRow key={row?.id} row={row} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div style={{ width: "100%" }}>
            <TablePaginationStandard
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              pagedata={pagedata}
            />
          </div>
        </div>
      )}
    </Grid>
  );
}
