import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Box, Tooltip, Typography, InputLabel } from "@mui/material";

import useStyles from "./useStyles";

import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { SelectOptionsAuto } from "../common/SelectOptions";
import { Title } from "../common/Title";
import {
  colChart,
  SpecializationTypeOptions,
} from "../common/ConstantsAndValues";

import { addOrgDepartments } from "../../redux/actions/orgs";
import { getDepartments } from "../../redux/loinc/loinc.actions";
import { departmentsquery } from "../utils/elasticqueries";

import log from "../../services/log";

export default function InputSpecializationList(props) {
  const { openEventPage, cancelEventPage, data, handleChange, title } = props;
  const mdspecialities = useSelector((state) => state.loinc.mdspecialities);
  const orgroup = useSelector((state) => state.orgs.selectorggroup);
  const location = useSelector((state) => state.addresses.selectaddress);

  const auth = useSelector((state) => state.auth);

  const [selectedOptions, setSelectedOptions] = useState(null);
  const [options, setOptions] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getDepartments(departmentsquery()));
  }, []);

  useEffect(() => {
    // log.info("specializations", mdspecialities)
    if (mdspecialities) {
      let newoptions = [];
      mdspecialities.forEach((d) =>
        newoptions.push({ label: d._source["displayText"], value: d })
      );
      setOptions(newoptions);
    } else {
      setOptions([]);
    }
  }, [mdspecialities]);

  const handleSelect = (data) => {
    console.log("Selected Dept...to save", data);
    setSelectedOptions(data);
    handleChange(data);
  };

  const MSStyles = {
    control: (styles) => ({ ...styles }),
    container: (styles) => ({ ...styles, width: colChart.width }),
    option: (styles) => ({ ...styles }),
    // menu:      styles => ({ ...styles,  width: colChart.width }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: colChart.width,
    }),
  };

  // log.info("departmentlist", deptaddressform.errors);

  return (
    <Box width="100%">
      <Box className="colfl0" mb={1}>
        <Title>{title ? title : ""} </Title>
      </Box>

      <Box className="clear"> </Box>
      <Box className="text-color-primary">
        <SelectOptionsAuto
          openPage={handleSelect}
          data={options}
          multi={true}
          title={"department"}
          wide={0}
        />
      </Box>
    </Box>
  );
}

{
  /*
                <Select
                    options={options}
                    placeholder="Select Department"
                    defaultValue={selectedOptions}
                    onChange={handleSelect}
                    isSearchable={true}
                    styles={MSStyles}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    isMulti={true}
                />

*/
}
