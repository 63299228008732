import React, { useEffect, useState } from "react";

export const ArrowUpBlueIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#94C4D2" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <rect
        x="3.14844"
        y="11.1953"
        width="13.0016"
        height="2"
        rx="1"
        transform="rotate(-45 3.14844 11.1953)"
      />
      <rect
        x="20.1172"
        y="12.6094"
        width="13"
        height="2"
        rx="1"
        transform="rotate(-135 20.1172 12.6094)"
      />
      <rect
        x="11.1484"
        y="22"
        width="18"
        height="2"
        rx="0.999999"
        transform="rotate(-90 11.1484 22)"
      />
    </svg>
  );
};
