import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { NativeSelect } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import log from "../../services/log";
import { useEffect, useState } from "react";
import {
  getDepartments,
  getMDSpeciality,
} from "../../redux/loinc/loinc.actions";
import { departmentsquery } from "../utils/elasticqueries";

export function DepartmentSelect(props) {
  const departments = useSelector((state) => state.loinc.departments);
  const dispatch = useDispatch();
  const [department, setDepartment] = useState(null);

  const handleChange = (event) => {
    log.info("departmentselect", event.target.value);
    setDepartment(event.target.value);
  };

  useEffect(() => {
    dispatch(getDepartments(departmentsquery())).then((r) =>
      log.info("departmentselect", r)
    );
  }, []);

  return (
    <FormControl fullWidth className="text-color-primary" variant="outlined">
      <InputLabel
        sx={{ minWidth: 20 }}
        variant="standard"
        htmlFor="uncontrolled-native"
      >
        Departments
      </InputLabel>
      <NativeSelect
        disableUnderline
        key={"selregion"}
        variant="standard"
        multiple
        sx={{
          marginTop: "20px",
          height: 28,
          marginRight: 15,
          minWidth: "100%",
          color: "var(--color-highlight)",
          "& .MuiSvgIcon-root": {
            color: "var(--color-highlight)",
          },
        }}
        defaultValue={department}
        onChange={handleChange}
        inputProps={{
          name: "region",
          id: "uncontrolled-native",
        }}
      >
        {departments &&
          departments.map((g) => (
            <option
              key={g._source["deptId"]}
              value={g._source["deptName"]}
              className="text-color"
              p={2}
            >
              {g._source["deptName"]}
            </option>
          ))}
      </NativeSelect>
    </FormControl>
  );
}
