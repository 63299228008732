import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { colWidthSign, SearchBoxStandard } from "../common/ConstantsAndValues";
import { getLabsList, selectLabsRow } from "../../redux/labs/labs.actions";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";

import { QListRow } from "./QListUSRRow";
import { QListHead } from "./QListUSRHead";
import log from "../../services/log";
import { initQnslist } from "../../redux/qns/qns.actions";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export function QListUSR(props) {
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const qnslist = useSelector((state) => state?.qns?.qnslist);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const ctlist = useSelector((state) => state.labs.labslist);

  useEffect(() => {
    // log.info("drug serarch query", searchText);
    const resetpage = { ...pagedata, page: 0 };
    dispatch(initQnslist([]));
  }, [searchcontext, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    const newlist = keyword
      ? qnslist.filter((v) =>
          v._source.name.toLowerCase().startsWith(keyword.toLowerCase())
        )
      : qnslist;
    setRows(newlist);
  };

  const handleChangePage = (event, value) => {
    // log.info("ctlist", value);
    // log.info("ctlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  // log.info("ctlist", rows);

  return (
    <Grid container>
      <Grid item xs>
        <div>
          <div
            className="flex-grow-1"
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "36px",
              width: "100%",
            }}
          >
            <div>
              <div className="colfl0"></div>

              <div className="colfr0" width="100%">
                <SearchBoxStandard
                  onChange={handleFilter}
                  placeholder="Search"
                  sx={{ marginRight: 1 }}
                  className="text-color-primary text-color-2-border text-color-2-bg"
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "16px" }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="collapsible table">
                <TableHead>
                  <QListHead />
                </TableHead>

                <TableBody className="text-color-primary text text-md">
                  {rows &&
                    rows.map((row, index) => (
                      <QListRow key={index + "qusr1"} row={row} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div style={{ width: "100%" }}>
            <TablePaginationStandard
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              pagedata={pagedata}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
