import React, { useEffect, useState } from "react";

export const HomeArrowIcon = (props) => {
  const { height = "24", width = "24", size = "1.0", fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Menu">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.31115 9.07285C-0.103707 9.43742 -0.103707 10.0285 0.311151 10.3931C0.726008 10.7576 1.39862 10.7576 1.81348 10.3931L11 2.32006L20.1422 10.3541C20.5571 10.7187 21.2297 10.7187 21.6446 10.3541C22.0594 9.98953 22.0594 9.39844 21.6446 9.03387L11.7517 0.340163C11.3368 -0.0244079 10.6642 -0.0244079 10.2494 0.340163C10.2205 0.36553 10.1936 0.391993 10.1688 0.419401C10.1359 0.442174 10.1041 0.466901 10.0738 0.493579L0.31115 9.07285Z"
        />
        <path
          id="Union_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.00001 24C3.44772 24 3.00001 23.5523 3.00001 23L3.00001 13C3.00001 12.4477 3.44772 12 4.00001 12C4.55229 12 5.00001 12.4477 5.00001 13L5.00001 22L17 22L17 13C17 12.4477 17.4477 12 18 12C18.5523 12 19 12.4477 19 13L19 23C19 23.0345 18.9983 23.0686 18.9948 23.1022C18.9436 23.6065 18.5178 24 18 24L4.00001 24Z"
        />
      </g>
    </svg>
  );
};
