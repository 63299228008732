import React, { useState, useEffect, useLayoutEffect } from "react";
import { Grid, Box, Paper, Typography, Container, Stack } from "@mui/material";

import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";

import { useSelector, useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { Title } from "../common/Title";

import {
  pageOptions,
  SearchBoxStandard,
  colWidthSign,
} from "../common/ConstantsAndValues";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../common/ConstantsAndValues";

import CardInputs from "./CardInputs";
import BankAccountInputs from "./BankAccountInputs";
import KYCInputs from "./KYCInputs";
import { MyAccountSetupHead } from "./MyAccountSetupHead";

import ThreedotAddPmtAccMenu from "../CustomComponents/ThreedotAddPmtAccMenu";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import { MyTooltipButtons } from "../CustomComponents/MyButtons";

import log from "../../services/log";
import { getUserGroupService } from "../../services/user-service";
import { getUserGroup } from "../../redux/user/user.actions";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  table: {
    minWidth: colWidthSign.width,
  },
  Snackbar: {
    bottom: "104px",
  },
});

export default function MyAccountSetup(props) {
  const { data, org, page, head } = props;

  const department = useSelector((state) => state.orgs.selectdepartment);
  const rows_state = useSelector((state) => state.user.usergroup);
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState([]);
  //const [thisCard,    setThisCard]                     = useState([]);
  const [disable, setDisable] = React.useState(true);
  const [actionState, setActionState] = useState(""); //callback
  //const [rowId,     setRowId]                          = useState( "");
  //const [rowEdited, setRowEdited]                      = useState( "");
  const [checkedList, setCheckedList] = useState([]);

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    setRows(rows_state);
  }, [rows_state]);

  useEffect(() => {
    // log.info("patnlist", department);

    if (department) {
      const jsonData = {};
      jsonData.metadata = {};
      jsonData.metadata.orgId = department.metadata.orgId;
      jsonData.metadata.groupId = department.metadata.groupId;
      jsonData.metadata.departmentId = department.metadata.accountId;
      jsonData.metadata.providerId = user.metadata.accountId;
      log.info("patnlist", jsonData);
      dispatch(getUserGroup(jsonData));
    }
  }, [department]);

  const handleFilter = (event) => {
    const keyword = event.target.value;

    if (keyword !== "") {
      const results = data.filter(
        (
          v //pl check dat source
        ) => v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(data); //pl check dat source
    }
  };

  const handleDone = () => {
    setActionState("");
    console.log("Done");
  };

  const handleAddBankAccSave = (event) => {
    console.log("AddSave BankAcc", event);
    setDisable(false);
    //setActionState("");
  };
  const handleAddCardSave = (event) => {
    setDisable(false);
    console.log("Add Card", event);
    //setActionState("");
  };
  const handleAddKYCSave = (event) => {
    console.log("AddSave KYC", event);
    setDisable(false);
    //setActionState("");
  };

  const handleCancel = () => {
    setActionState("");
    console.log("Cancel Nothing to update");
  };
  const handleSave = () => {
    handleCancel();
    setActionState("");
    //console.log("Add Card...", thisCard);
  };

  const handleCheck = (e) => {
    var toggleList = [...checkedList];
    if (e.target.checked) {
      toggleList = [...checkedList, e.target.value];
    } else {
      toggleList.splice(checkedList.indexOf(e.target.value), 1);
    }
    setDisable(toggleList.length > 0 ? false : true);
    setCheckedList(toggleList);

    //console.log('Toggle Status list', toggleList);
  };

  function getFilterList(alist, val) {
    return alist.filter((item) => item.alias === val);
  }

  function getFilterList2(alist, val1, val2) {
    return alist.filter(
      (item) => item.alias === val1 && item.location_id === val2
    );
  }

  function getFilteredList3(alist, val1, val2, val3) {
    if (!val3) {
      return alist.filter(
        (item) => item.h_id === val1 && item.dept_id === val2
      );
    } else {
      return alist.filter(
        (item) =>
          item.h_id === val1 &&
          item.dept_id === val2 &&
          item.location_id === val3
      );
    }
  }

  const handleChangePage = (event, value) => {
    log.info("pagedata", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    setPage(newpagedata);
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "8px",
          overflowX: "hidden",
        }}
      >
        <div className="colfl0 flex-grow-1">
          <Title>Payment Methods</Title>
        </div>

        <div
          className="colfr0"
          height={{ xs: "42px" }}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {actionState !== "" && (
            <>
              <MyTooltipButtons
                onClick={handleCancel}
                btnKey={"cancel"}
                btnLabel={"Cancel"}
                enState={false}
              />

              {actionState !== "addcard" &&
                actionState !== "addbankacc" &&
                actionState !== "addkyc" && (
                  <>
                    &nbsp; &nbsp;
                    <MyTooltipButtons
                      onClick={handleSave}
                      btnKey={"save"}
                      btnLabel={"Update"}
                      enState={disable}
                    />
                  </>
                )}
            </>
          )}
          <div style={{ padding: "8px 8px 0px 24px" }}>
            <ThreedotAddPmtAccMenu
              setAction={setActionState}
              title={"Payment Accounts"}
            />
          </div>
        </div>
      </div>

      {actionState === "addcard" && (
        <div style={{ width: "100%", margin: "16px 0px" }}>
          <CardInputs
            handleAddSave={handleAddCardSave}
            handleCancel={handleCancel}
            action={setActionState}
          />
        </div>
      )}
      {actionState === "addbankacc" && (
        <div style={{ width: "100%", margin: "16px 0px" }}>
          <BankAccountInputs
            handleAddSave={handleAddBankAccSave}
            handleCancel={handleCancel}
            action={setActionState}
          />
        </div>
      )}

      {actionState === "addkyc" && (
        <div style={{ width: "100%", margin: "16px 0px" }}>
          <KYCInputs
            handleAddSave={handleAddKYCSave}
            handleCancel={handleCancel}
            action={setActionState}
          />
        </div>
      )}

      {actionState !== "addkyc" && (
        <>
          <div style={{ width: "100%", margin: "32px 0px 8px 0px" }}>
            <div className="colfl0"></div>

            <div className="colfl0">
              <SearchBoxStandard
                onChange={handleFilter}
                placeholder="Account"
                sx={{ marginRight: 1 }}
                className="text-color-primary text-color-2-border text-color-2-bg"
              />
            </div>
            <div className="clear" />
          </div>

          <div style={{ width: "100%" }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="collapsible table">
                <TableHead>
                  <MyAccountSetupHead />
                </TableHead>

                <TableBody className="text-color-primary text text-md">
                  {rows &&
                    rows.map((row) => (
                      <StyledTableRow
                        hover={true}
                        key={row.id}
                        //onClick={() => handleClick(row)}
                      >
                        <StyledTableCell align="left" key={"1" + row.id}>
                          {row.alias}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={"2" + row.id}>
                          {row.detail}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={"3" + row.id}>
                          {row.bank}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          key={"4" + row.id}
                          width="50px"
                        >
                          <span
                            className={
                              row.status === "active" ? "online" : "offline"
                            }
                          ></span>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div>
            <TablePaginationStandard
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              pagedata={pagedata}
            />
          </div>

          <div
            className="right text-color-gray"
            style={{ padding: "16px 0px 16px 0px" }}
          >
            {actionState === "xxxxx" && (
              <span>Note: A click on checkbox will toggle the status</span>
            )}
          </div>
        </>
      )}
    </>
  );
}
