import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Container,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@mui/material/styles";
import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";
import { ArrowRightIcon } from "../customIcons/ArrowRightIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  StyledPillButton,
  StyledTextField,
  useWindowSize,
} from "../common/ConstantsAndValues";
import { useMetaTheme } from "../theme/ThemeContext";
//import image from "../../assets/images/bg/home-bg-00-rect.png";
import { HomeGraph } from "./HomeGraph";
import log from "../../services/log";
import TextFieldWithSuffix from "../buttons/MainbarSubField";

const HomeMainSection = (props) => {
  const { updateEmail, submit, errors, ack } = props;
  const [email, setEmail] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const { theme } = useMetaTheme();

  const isXs = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isfullwidth = isXs || isSm;

  const data1 = [3.4, 5, 4.5, 1, -1, 6, 13, 18.65, 19];
  const data2 = [8, 12, 12.5, 8, 2, -1, 3, 4, 0];
  const data3 = [18, 7, 9, 10, 10, 6, 0, -3, -3];

  const arOrig = 2.08464;
  const size = useWindowSize();

  const [margin, setMargin] = useState({ marginTop: 170, marginLeft: 2 });

  useEffect(() => {
    if (size?.width > 0) {
      const newmarginTop = isfullwidth
        ? parseInt((size?.width * 170) / 1920)
        : parseInt((size?.width * 70) / 1920);
      const newmarginLeft = isfullwidth
        ? 2
        : parseInt((size?.width * 80) / 1920);
      setMargin({ marginTop: newmarginTop, marginLeft: newmarginLeft });
    }
  }, [size, isfullwidth]);

  const margintop = isXs ? 31 : isSm ? 5 : 0;

  //log.info("errorshome: ", errors)
  //log.info("ehome: ", ack)

  const handleLocal = (event) => {
    // log.info('input: ', event.target.value);
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    // log.info('email: ', email);
    setIsSelected(!isSelected);
    updateEmail(email);
    setTimeout(() => {
      submit();
    }, 2);
  };

  // log.info('margin: ', margin);

  return (
    <ThemeProvider theme={theme}>
      <Grid container m={0} mt={2} spacing={0}>
        <Grid
          item
          xs={12}
          sx={{
            flexGrow: 1,
            display: "flex",
            marginTop: { xs: 31, sm: 27, md: 5, lg: 0 },
          }}
        >
          <HomeGraph
            datasets={[data1, data2, data3]}
            isFullWidth={isfullwidth}
          />
        </Grid>

        <Grid
          item
          xs={12}
          pl={0}
          pt={2}
          style={{
            position: "absolute",
            left: "0px",
            top: isfullwidth ? "100px" : "168px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              //display: "flex",
              //flexDirection: { xs:"column", md: "row" },
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              className="colfl0"
              style={{
                width: isfullwidth ? "100%" : "63.1%",
                paddingLeft: "72px",
              }}
            >
              <div style={{ width: "md" }}>
                <Typography
                  variant="MPTitle"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    color: (theme) => theme?.palette?.gray?.black,
                    fontSize:
                      parseInt((size.width * 60) / 1920) < 30
                        ? 30
                        : parseInt((size.width * 60) / 1920),
                  }}
                  gutterBottom
                >
                  Introducing
                  <Typography
                    variant="MPTitle1"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      color: (theme) => theme?.palette?.gray?.black,
                      fontSize:
                        parseInt((size.width * 60) / 1920) < 30
                          ? 30
                          : parseInt((size.width * 60) / 1920),
                    }}
                    gutterBottom
                  >
                    Ease into Healthcare
                  </Typography>
                </Typography>
              </div>
              <hr
                align="left"
                color="#C9E1E9"
                style={{
                  zIndex: "-1",
                  height: "1px",
                  opacity: "0.3",
                }}
              />
              <div className="border-dbxxx" width="auto">
                <Typography
                  variant="MPSubTitle"
                  component="div"
                  width={isfullwidth ? 300 : 500}
                  sx={{
                    flexGrow: 1,
                    paddingLeft: "2px",
                    color: (theme) => theme?.palette?.gray?.main,
                    fontSize:
                      parseInt((size.width * 25) / 1920) < 12
                        ? 12
                        : parseInt((size.width * 25) / 1920),
                  }}
                  //color="gray"
                  gutterBottom
                >
                  Metalane revolutionizes healthcare by providing a single
                  platform which delivers accessible and affordable healthcare
                  solutions for the public, sustainable profitability for
                  businessess, feasibility for governments, and streamlined
                  operations for hospitals.
                </Typography>
              </div>
            </Box>
            {isfullwidth && (
              <Box
                className="colfr0 clear"
                style={{ width: isfullwidth ? "100%" : "36.9%" }}
              ></Box>
            )}
            <Box
              className="colfl0"
              style={{
                width: "auto",
                paddingLeft: isfullwidth ? "72px" : "20px",
                paddingTop: isfullwidth ? 30 : 0,
                //height: "820px",
                //backgroundImage: `url(${image})`,
                //backgroundRepeat:"no-repeat",
                //backgroundSize:"cover",
              }}
            >
              <div
                style={{
                  width: "auto",
                  marginTop: margin?.marginTop,
                  marginLeft: margin?.marginLeft,
                }}
              >
                <Typography
                  variant="MPInstructions"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    fontSize:
                      parseInt((size.width * 26) / 1920) < 12
                        ? 12
                        : parseInt((size.width * 26) / 1920),
                  }}
                  color="secondary"
                  gutterBottom
                >
                  We are almost ready to launch!
                  <br />
                  Be the first to know.
                </Typography>

                <div className="clear" style={{ margin: "10px 0px" }}>
                  <StyledTextField
                    theme={theme}
                    id="subscribe"
                    name="subscribe"
                    placeholder="Enter your email"
                    onChange={handleLocal}
                    label=""
                    variant="outlined"
                    size="small"
                  />
                </div>

                {errors !== null && errors !== "" && (
                  <Typography
                    variant="ItalicText400"
                    component="div"
                    sx={{
                      fontSize:
                        parseInt((size.width * 16) / 1920) < 10
                          ? 10
                          : parseInt((size.width * 16) / 1920),
                      color: theme?.palette?.error?.main,
                    }}
                    gutterBottom
                  >
                    {errors ? "* " + errors : ""}
                  </Typography>
                )}
                <div style={{ padding: "5px 0px" }}>
                  <StyledPillButton
                    className={isSelected ? "Mui-selected" : ""}
                    theme={theme}
                    pill={+true}
                    variant="contained"
                    endIcon={
                      <ArrowRightIcon
                        size={isfullwidth ? 0.7 : 0.9}
                        fill={theme?.palette?.gray?.contrastText}
                      />
                    }
                    disableRipple
                    disableElevation
                    disableFocusRipple
                    onClick={() => handleSubmit()}
                    sx={{ textTransform: "none" }}
                  >
                    Subscribe
                  </StyledPillButton>
                </div>
                {errors == null && email !== null && (
                  <Typography
                    variant="NormalText400"
                    component="div"
                    sx={{
                      fontSize:
                        parseInt((size.width * 16) / 1920) < 10
                          ? 10
                          : parseInt((size.width * 16) / 1920),
                      color: theme?.palette?.gray?.dark,
                    }}
                    gutterBottom
                  >
                    Thank you for subscribing!
                  </Typography>
                )}
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default HomeMainSection;
