import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Link } from "@material-ui/core";
import Select from "react-select";

import log from "../services/log";

function Analytics(props) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          //justifyContent: "center",
          //alignItems:     "center",
          //height:         "100%",
          width: "100%",
          marginBottom: "0px !important",
        }}
      ></Box>
    </>
  );
}

export default Analytics;
