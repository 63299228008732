import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import css file
import "../src/assets/css/root.scss";

// Import date picker css
import "../src/assets/css/react-datepicker.css";

// import toast
import { Toaster } from "react-hot-toast";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MetaThemeProvider } from "./components/theme/ThemeContext";

// adding service worker
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <MetaThemeProvider>
          <Toaster />
          <App />
        </MetaThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
