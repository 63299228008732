/**
 * filter for provider group
 * @param p
 * @param s
 * @param org
 * @returns {{}}
 */

export const buildProviderPrivateAppointmentQuery = (s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData.metadata["providerId"] = user?.metadata?.accountId;
  return jsonData;
};

/**
 * filter by user
 * creator of eveent
 * @param s
 * @param user
 * @returns {{}}
 */

export const buildUserPrivateAppointmentQuery = (s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData.metadata["userId"] = user?.metadata?.accountId;
  return jsonData;
};

/**
 * filter by guest
 * @param s
 * @param user
 * @returns {{}}
 */

export const buildGuestPrivateAppointmentQuery = (s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData.metadata["guestId"] = user?.metadata?.accountId;
  return jsonData;
};
