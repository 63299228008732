import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  PROFILE_DATA,
  EMAILVERIFY,
} from "./types";

import {
  registerService,
  loginService,
  logoutService,
  getProfileService,
  setProfileService,
  updateProfileService,
  sendVerificationCodeService,
  updateUserService,
  getUserService,
} from "../../services/auth-service";
import { orgsActionTypes } from "./orgs.types";

export const register = (payload) => (dispatch) => {
  return registerService(payload).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: REGISTER_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const login = (payload) => (dispatch) => {
  return loginService(payload).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LOGIN_FAIL,
      });
      return Promise.reject();
    }
  );
};

export const updateUser = (payload) => (dispatch) => {
  return updateUserService(payload).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getUser = (payload) => (dispatch) => {
  return getUserService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  // logoutService();
  dispatch({
    type: LOGOUT,
  });
};

export const verifyEmail = (payload, vdata) => (dispatch) => {
  return sendVerificationCodeService(payload).then(
    (response) => {
      dispatch({
        type: EMAILVERIFY,
        payload: vdata,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const updateProfile = (payload) => (dispatch) => {
  return updateProfileService(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_DATA,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const setUserProfile = (payload) => (dispatch) => {
  return setProfileService(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_DATA,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getProfile = (payload) => (dispatch) => {
  return getProfileService(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_DATA,
        payload: response.data.results,
      });
      return Promise.resolve(response.data.results);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};
