//common to metaorg and metapharma
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { getDrugs, selectDrugList } from "../../../redux/drugs/drugs.actions";
import { drugsquery } from "../../utils/drugsqueries";
import {
  colWidthSign,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import { DrugsListRow } from "./DrugsListRow";
import { DrugsListHead } from "./DrugsListHead";
import log from "../../../services/log";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export function DrugsList(props) {
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const druglist = useSelector((state) => state?.drugs?.druglist);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  const buildQuery = (paged) => {
    // log.info("druglist query", searchcontext);

    const searchquery = searchcontext
      ? drugsquery(searchcontext?.key, searchcontext?.value, paged)
      : drugsquery("name", "a", paged);
    return searchquery;
  };

  useEffect(() => {
    const resetpage = { ...pagedata, page: 0 };
    const searchquery = buildQuery(resetpage);
    // log.info("druglist serarch query", searchquery);
    dispatch(getDrugs(searchquery)).then((r) => {
      // log.info("drugslist", r)
      if (r.total.value && r.total.value > 0) {
        setPage({
          ...resetpage,
          count: r.total.value,
          pages: r.total.value / pagedata?.limit,
        });
      }
    });
  }, [searchcontext, dispatch]);

  useEffect(() => {
    if (druglist) {
      setRows(druglist);
    } else {
      setRows(null);
    }
  }, [druglist]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    const newlist = keyword
      ? druglist.filter((v) =>
          v._source.name.toLowerCase().startsWith(keyword.toLowerCase())
        )
      : druglist;
    setRows(newlist);
  };

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    // log.info("druglist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const searchquery = buildQuery(newpagedata);
    // log.info("druglist nextpage", searchquery);
    dispatch(getDrugs(searchquery)).then((r) => {
      log.info("drugslist", r);
      if (r.total.value && r.total.value > 0) {
        setPage(newpagedata);
      }
    });
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (row, add) => {
    // log.info("Prescribe", row);
    // log.info("Prescribe", add);

    if (row && add === true) {
      setCheckedRows(row);
      dispatch(selectDrugList(row));
    } else {
      setCheckedRows(null);
      dispatch(selectDrugList(null));
    }
  };

  // log.info("drugslist", searchcontext);

  return (
    <Grid container>
      <Grid item xs>
        <div>
          <div
            className="flex-grow-1"
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "36px",
              width: "100%",
            }}
          >
            <div>
              <div className="colfr0" width="100%">
                <SearchBoxStandard
                  onChange={handleFilter}
                  placeholder="drug name"
                  sx={{ marginRight: 1 }}
                  className="text-color-primary text-color-2-border text-color-2-bg"
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "16px" }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="collapsible table">
                <TableHead>
                  <DrugsListHead />
                </TableHead>

                <TableBody className="text-color-primary text text-md">
                  {rows &&
                    rows.map((row) => (
                      <DrugsListRow
                        key={row._source.Id}
                        row={row}
                        handleRowClick={handleRowClick}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div style={{ width: "100%" }}>
            <TablePaginationStandard
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              pagedata={pagedata}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
