import * as React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Divider from "@mui/material/Divider";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function HealthCard(props) {
  const { page, data } = props;
  const [expanded, setExpanded] = React.useState(false);

  //console.log('hc=', page, props)

  const val = props.data.score || 0;
  const pg = props.page || "";
  let contSum = "Score " + val + "";
  contSum = contSum.toString();

  const title = props.data.title || pg + " Risk";
  const date = props.data.date || "";
  const biomarkers = props.data.biomark || "Critical biomarkers";

  let v1 = props.data.normal || 0;
  let v2 = props.data.critical || 0;
  const u = props.data.uom || "";

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card width="auto" className="colfr">
      <CardHeader
        className="bg-standard-lightest"
        avatar={
          <Avatar
            sx={{ bgcolor: "#2d8daa", fontSize: "small" }}
            aria-label="recipe"
          >
            {pg}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={<span className="text-md text-color-primary">{title}</span>}
        subheader={date}
      />

      <CardContent>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            display: "flex",
            flexDirection: { xs: "row" },
            justifyContent: "space-between",
            paddingY: "10px",
          }}
        >
          Score &nbsp;
          <span className="text-color-inv text-sm">
            <b>{val}</b>
          </span>
          &nbsp; {biomarkers}
        </Typography>
      </CardContent>
      <CardActions disableSpacing className="bg-standard-lightest">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row", md: "row" },
            justifyContent: "space-between",
          }}
        >
          <Box>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
          <Box>
            <IconButton aria-label="share">{/* <ShareIcon /> */}</IconButton>
          </Box>
        </Box>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className="text-color-primary">
          {props.data.details.map((list) => (
            <span key={list}>
              <Typography paragraph>{list}</Typography>
              <Divider />
              <br />
            </span>
          ))}
          <span key={"r"}>
            <Typography paragraph>
              Range {v1 && <span> {v1} </span>} - {v2 && <span> {v2} </span>}
              {u && <span> {u} </span>}
            </Typography>
          </span>
        </CardContent>
      </Collapse>
    </Card>
  );
}
