import React, { PureComponent } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import "./PaginationArea.styl";
import { withTranslation } from "../../contextProviders";
import { pageOptions, tableParam } from "../../../../common/ConstantsAndValues";

class TablePagination extends PureComponent {
  static defaultProps = {
    pageOptions: pageOptions,
    rowsPerPage: tableParam.defLinesPerPage,
    currentPage: 0,
  };

  static propTypes = {
    /* Values to show in "Rows per page" select dropdown */
    pageOptions: PropTypes.array,
    rowsPerPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    nextPageFunc: PropTypes.func,
    prevPageFunc: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    recordCount: PropTypes.number.isRequired,
  };

  nextPage = () => {
    this.props.nextPageFunc(this.props.currentPage);
  };

  prevPage = () => {
    this.props.prevPageFunc(this.props.currentPage);
  };

  onRowsPerPageChange = (event) => {
    this.props.onRowsPerPageChange(parseInt(event.target.value));
  };

  renderPaginationButtons() {
    return (
      <div className="">
        <div className="form-inline form-group page-buttons noselect">
          <React.Fragment>
            <ul className="pagination-control no-margins  dicom-page-nav">
              <li className="page-item prev page-buttons  dicom-page-nav-item">
                <button
                  onClick={this.prevPage}
                  disabled={this.props.currentPage === 0}
                  className="btn page-link"
                >
                  {"< " + this.props.t("Previous")}
                </button>
              </li>
              <li
                className="page-item next page-buttons  dicom-page-nav-item"
                style={{ paddingLeft: "16px" }}
              >
                <button
                  onClick={this.nextPage}
                  disabled={
                    this.props.recordCount === 0 ||
                    this.props.rowsPerPage > this.props.recordCount
                  }
                  className="btn page-link"
                >
                  {this.props.t("Next") + " >"}
                </button>
              </li>
            </ul>
          </React.Fragment>
        </div>
      </div>
    );
  }

  renderRowsPerPageDropdown() {
    return (
      <div className="form-inline form-group rows-per-page text-color-dark">
        <span>{this.props.t("Show")}&nbsp;</span>
        <select
          className="text-color-primary"
          onChange={this.onRowsPerPageChange}
          defaultValue={this.props.rowsPerPage}
        >
          {this.props.pageOptions.map((pageNumber) => {
            return (
              <option key={pageNumber} value={pageNumber}>
                {pageNumber}
              </option>
            );
          })}
        </select>
        <span>&nbsp;{this.props.t("RowsPerPage")}</span>
      </div>
    );
  }

  render() {
    return (
      <Box
        className="pagination-area  bg-gray border-dbt"
        sx={{
          display: "flex",
          padding: "16px 16px 0px 16px",
          flexDirection: { sm: "row" },
          justifyContent: "space-between",
        }}
      >
        <div className="rows-dropdown" style={{ paddingTop: "8px" }}>
          {this.renderRowsPerPageDropdown()}
        </div>
        <div className="pagination-buttons">
          {this.renderPaginationButtons()}
        </div>
      </Box>
    );
  }
}

const connectedComponent = withTranslation("Common")(TablePagination);
export { connectedComponent as TablePagination };
