import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUserGroup } from "../../../redux/user/user.actions";

import ThreeDotPatientAddEdit from "../../CustomComponents/ThreeDotPatientAddEdit";
import OrgAddPatients from "../../organization/components/OrgAddPatients";

import log from "../../../services/log";
import PatientListORG2 from "../../organization/components/PatientListORG2";
import { buildDepartmentUserGroupQuery } from "../../dbcq/usergroupdb";

export default function PatientListMain(props) {
  const { data, org, page } = props;

  const department = useSelector((state) => state?.orgs?.selectdepartment);
  const rows_state = useSelector((state) => state?.user?.usergroup);
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState([]);
  const [disable, setDisable] = React.useState(true);
  const [actionState, setActionState] = useState("");
  const [checkedRow, setCheckedRow] = useState(null);
  const [selectedrow, setSelectedRow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setRows(rows_state);
  }, [rows_state]);

  useEffect(() => {
    // log.info("patnlist", department);
    if (department) {
      const resetpage = { ...pagedata, page: 0 };
      const jsonData = buildDepartmentUserGroupQuery(
        resetpage,
        searchcontext,
        department
      );
      dispatch(getUserGroup(jsonData)).then((r) => {
        log.info("patnlist", r);
        setPage({
          ...pagedata,
          count: r.totalResults,
          pages: r.totalPages,
        });
      });
    }
  }, [department, dispatch]);

  const handleDone = () => {
    setActionState("");
  };

  const handleCancel = () => {
    setActionState("");
    console.log("Cancel Nothing to update");
  };

  const handleAddPatientSave = () => {
    console.log("AddSave Patient");
    setActionState("");
  };

  const handleEditPatientSave = () => {
    setActionState("");
  };

  const handleSave = () => {
    handleCancel();
    setActionState("");
  };

  const handleCheck = (e) => {
    if (e) {
      setCheckedRow(e);
      setSelectedRow(true);
      // dispatch(selectPatientMedcine(newlist));
    } else {
      setCheckedRow(null);
      setSelectedRow(false);
      // dispatch(selectPatientMedcine([]));
    }
  };

  const handleChangeRows = (event, value) => {
    //pagination part
    setPage({ ...pagedata, limit: value });
  };

  // log.info("org patientlist", )

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "8px",
        }}
      >
        <div> &nbsp; </div>
        <div style={{ padding: "8px 8px 0px 24px" }}>
          <ThreeDotPatientAddEdit
            setAction={setActionState}
            rowIsSelected={rows}
          />
        </div>
      </div>

      <div>
        {actionState === "addPatient" ? (
          <OrgAddPatients
            handleAddPatientSave={handleAddPatientSave}
            handleCancel={handleCancel}
            org={org}
            page={page}
            location={""}
          />
        ) : actionState === "editPatient" ? (
          <></>
        ) : (
          <PatientListORG2 />
        )}
      </div>
    </>
  );
}
