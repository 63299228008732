//////////////////////////////////////////////
//           common for metamd              //
//////////////////////////////////////////////

import log from "../../../services/log";
import * as React from "react";
import { createContext, useContext, useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";

import { Outlet } from "react-router-dom";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import { format } from "date-fns";
import moment from "moment";

import { NavigateMD } from "./NavigateMD";
import {
  SubContentStyle,
  ContentStyle,
  colWidthRBarMD,
  checkListCal,
} from "../../common/ConstantsAndValues";

import { DatePickCal } from "../../common/DatePickCal";
import {
  CauseRSM,
  CalendarContainer,
  WideRSM,
} from "../../common/ConstantsAndValues";

//------------------------------------------------- RH BAR  CONTENTS ---------------------------------------------------//
import MessageList from "../../messages/MessageList";
import AppointmentList from "../../appointment/AppointmentList";
import TodoList from "../../appointment/TodoList";
import SBMessages from "../../messages/SBMessages";
import MessageSearch from "../../messages/MessageSearch";

import { RightMenu } from "../../common/menuRightBar";
import { menuListMetaMD_RS } from "../../common/ConstantsAndValues"; //temporary list

import MessageItemsList_OnlineNow from "../../messages/MessageItemsList_OnlineNow";
import { HealthMonitorConditions } from "../../healthmonitor/HealthMonitorConditions";
import { HealthMonitorConditionsAll } from "../../healthmonitor/HealthMonitorConditionsAll";

import { BlockAccManager } from "../../common/BlockAccManager";

import { markDatesOrig } from "../../../tests/CalMarkDatesTestData";
import { GroupSelection } from "../../messages/GroupSelection";
import { MemberMDOnlineNow } from "../../messages/MemberMDOnlineNow";

export const PatientContext = createContext();

export function ContainerMD(props) {
  const { containerType, auth, searchOptions } = props;

  const [provider, setPatient] = useState("");
  const [dateTogo, setDate] = useState(new Date());
  const [openRSM, setOpenRSM] = useState(false);

  const [page, setPage] = useState("");
  const [navX, setNavX] = useState(
    window.location.pathname
      .slice(
        window.location.pathname.lastIndexOf("/"),
        window.location.pathname.length
      )
      .substring(1)
  );

  const [markDates, setMarkDates] = useState(
    markDatesOrig.map((item) => item.eventdate)
  );
  const [markFilter, setMarkFilter] = useState(checkListCal);
  const [eventMask, setEventMask] = useState([]);

  const [dateFullFlag, setDateFullFlag] = useState(
    markDates.includes(moment(dateTogo).format("YYYY-MM-DD")) ? true : false
  );

  const openEventDateState = (date, flag) => {
    //working   this has date val from the child calendar pick
    setDate(date);
    setDateFullFlag(flag);
  };

  const handleEventFilter = (eventmask) => {
    log.info("rightcal", eventmask);
    setEventMask(eventmask);
    // setMarkFilter(filterList);
    // setMarkDates(filterMarkDates());
  };

  const openEventFilterState = (filterList) => {
    //Called from child Calendar pick  further from Select checkList
    setMarkFilter(filterList);
    setMarkDates(filterMarkDates());
  };

  function filterMarkDates() {
    //ALL
    if (
      (!markFilter[1].isChecked &&
        !markFilter[2].isChecked &&
        !markFilter[3].isChecked) ||
      markFilter[0].isChecked
    ) {
      return markDatesOrig.map((item) => item.eventdate);
    }
    //Others any combination
    const newItems = markDatesOrig.filter((newVal) => {
      return (
        (markFilter[1].isChecked && newVal.event === markFilter[1]._id) ||
        (markFilter[2].isChecked && newVal.event === markFilter[2]._id) ||
        (markFilter[3].isChecked && newVal.event === markFilter[3]._id)
      );
    });
    return newItems.map((item) => item.eventdate);
  }

  const openEventPage = (page) => {
    setPage(page);
  };

  const openMenuState = (nav) => {
    setNavX(nav);
    setOpenRSM(true);
  };

  const closeMenuState = () => {
    setOpenRSM(false);
  };

  useEffect(() => {
    setOpenRSM(CauseRSM.indexOf(navX) === -1 ? false : true);
    OutletRSM();
  }, [navX]);

  const getVal = (e) => {
    console.log("cbo ContainerMD.js ", e);
  };

  function OutletRSM() {
    const RSMWidth =
      WideRSM.indexOf(containerType.type) === -1
        ? colWidthRBarMD.width
        : colWidthRBarMD.extrawide;
    const widn = openRSM ? RSMWidth : "0px";

    return (
      <Grid id="GridRSM" item sx={{ width: { xs: widn, md: widn } }} mr={0}>
        <SubContentStyle>
          <Grid
            ml={2}
            mr={0}
            //pt = {4}
            sx={{ height: "auto", background: "white" }}
            container
            spacing={0}
            direction="column"
          >
            {/* PRIORITY CUSTOMISATION HERE FOR X-VAL OR Y-VAL OR BOTH */}
            {/*
                        <Box>
                            <GroupSelection />                           
                        </Box>
                        */}
            {(navX === "cal" || navX === "new") && (
              <Box textAlign="center">
                <CalendarContainer>
                  <DatePickCal
                    openEventDateState={openEventDateState}
                    markDates={markDates}
                    openEventFilterState={openEventFilterState}
                  />
                </CalendarContainer>
                <br />
              </Box>
            )}

            {/* COMMON RHS MENU CONTENT HERE */}

            {/* CUSTOMISATION HERE FOR X-VAL ONLY */}

            {navX === "messages" && (
              <Box mb={2}>
                <MemberMDOnlineNow sourcekey={"metauser"} />
                <Divider />
              </Box>
            )}

            {containerType.type === "reports" &&
              (navX === "reports" ||
                navX === "charts" ||
                navX === "analytics") && (
                <Box>
                  <HealthMonitorConditions
                    sourcekey={"metamd"}
                    openEventPage={openEventPage}
                  />
                </Box>
              )}
            {containerType.type === "health-monitor" &&
              navX === "conditions" && (
                <Box>
                  <HealthMonitorConditions
                    sourcekey={"metamd"}
                    openEventPage={openEventPage}
                  />
                </Box>
              )}
            {containerType.type === "health-monitor" && navX === "info" && (
              <Box>
                <HealthMonitorConditionsAll
                  sourcekey={"metamd"}
                  openEventPage={openEventPage}
                />
              </Box>
            )}

            {/* COMMON INFORMATION FOR RSMENU HERE */}
            {(navX === "privacy" || navX === "settings") &&
              containerType.type === "setup" && (
                <Box>
                  <BlockAccManager getVal={getVal} />
                </Box>
              )}
          </Grid>
        </SubContentStyle>
      </Grid>
    );
  }

  // log.info("md navigation", searchOptions)

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "auto",
        }}
      >
        <NavigateMD
          containerType={containerType}
          openMenuState={openMenuState}
          closeMenuState={closeMenuState}
          date={dateTogo}
          page={page}
          defaNav={navX}
          setOpenRSM={setOpenRSM}
          openRSM={openRSM}
          searchOptions={searchOptions}
        />
      </Box>

      <Grid
        sx={{ minHeight: "auto", background: "white" }}
        container
        spacing={0}
        direction="column"
      >
        <ContentStyle>
          <Grid
            container
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <Grid item xs zeroMinWidth>
              <SubContentStyle>
                <PatientContext.Provider value={provider}>
                  <Outlet
                    context={[
                      markDates,
                      dateTogo,
                      auth,
                      containerType.eventType,
                      page,
                      dateFullFlag,
                    ]}
                  />
                </PatientContext.Provider>
              </SubContentStyle>
            </Grid>

            {openRSM && <OutletRSM />}
          </Grid>
        </ContentStyle>
      </Grid>
    </div>
  );
}
