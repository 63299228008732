import { FeedbackActionTypes } from "./feedback.types";
import {
  uploadFeedbackImage,
  addNewFeedback,
  addNewFeedbackLanding,
} from "../../services/feedback-service";
import { failure, success } from "../../utils/redux";
import log from "../../services/log";

const uploadNewFeedbackImage = (payload) => (dispatch) => {
  dispatch({
    type: FeedbackActionTypes.UPLOAD_FEEDBACK_IMAGE,
  });
  return uploadFeedbackImage(payload).then(
    (response) => {
      dispatch({
        type: success(FeedbackActionTypes.UPLOAD_FEEDBACK_IMAGE),
        payload: response?.data?.data,
      });
      return Promise.resolve(response?.data);
    },
    (error) => {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.message) ||
        error?.message ||
        error.toString();
      log.info("Error user api ", message);
      dispatch({
        type: failure(FeedbackActionTypes.UPLOAD_FEEDBACK_IMAGE),
      });
      return Promise.reject();
    }
  );
};

const sendUserFeedback = (payload) => (dispatch) => {
  dispatch({
    type: FeedbackActionTypes.ADD_NEW_FEEDBACK,
  });
  return addNewFeedback(payload).then(
    (response) => {
      dispatch({
        type: success(FeedbackActionTypes.ADD_NEW_FEEDBACK),
        payload: response?.data?.data,
      });
      return Promise.resolve(response?.data);
    },
    (error) => {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.message) ||
        error?.message ||
        error.toString();
      log.info("Error user api ", message);
      dispatch({
        type: failure(FeedbackActionTypes.ADD_NEW_FEEDBACK),
      });
      return Promise.reject();
    }
  );
};

const sendUserFeedbackLanding = (payload) => (dispatch) => {
  dispatch({
    type: FeedbackActionTypes.ADD_NEW_FEEDBACK_LANDING,
  });
  return addNewFeedbackLanding(payload).then(
    (response) => {
      dispatch({
        type: success(FeedbackActionTypes.ADD_NEW_FEEDBACK_LANDING),
        payload: response?.data?.data,
      });
      return Promise.resolve(response?.data);
    },
    (error) => {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.message) ||
        error?.message ||
        error.toString();
      log.info("Error user api ", message);
      dispatch({
        type: failure(FeedbackActionTypes.ADD_NEW_FEEDBACK_LANDING),
      });
      return Promise.reject();
    }
  );
};
export { uploadNewFeedbackImage, sendUserFeedback, sendUserFeedbackLanding };
