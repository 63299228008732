import * as yup from "yup";

export const messageSchema = yup.object({
  id: yup.string(),
  sourceId: yup.string().required(),
  destId: yup.string().required(),
  threadId: "",
  seqno: yup.number().default(0),
  sourceName: yup.string(),
  destName: yup.string(),
  msgSubject: yup.string(),
  msgBody: yup.array(),
  nextReminder: yup.string(),
});
