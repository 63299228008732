import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

export const GraphArea = (props) => {
  const { height, width, datasets, offset } = props;

  useEffect(() => {
    if (datasets && datasets.length) {
      drawGraph();
    }
  }, [datasets, height, width]);

  const svgRef = useRef(null);

  const drawGraph = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous contents

    const xScale = d3
      .scaleLinear()
      .domain([0, datasets[0].length - 1])
      .range([0, width]);

    const allValues = datasets.flat();
    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(allValues)])
      .range([height, 0]);

    const area = d3
      .area()
      .x((d, i) => xScale(i))
      .y0(height)
      .y1((d) => yScale(d))
      .curve(d3.curveBasis);

    // Define the gradient
    const gradient = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "gradient")
      .attr("x1", "0%")
      .attr("x2", "100%")
      .attr("y1", "0%")
      .attr("y2", "0%");

    gradient
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#94C4D2")
      .attr("stop-opacity", 1);

    gradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#94C4D2")
      .attr("stop-opacity", 0.3);

    const gradientl = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "gradientl")
      .attr("x1", "100%")
      .attr("x2", "0%")
      .attr("y1", "0%")
      .attr("y2", "0%");

    gradientl
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#C9E1E9")
      .attr("stop-opacity", 0.8);

    gradientl
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#C9E1E9")
      .attr("stop-opacity", 0.4);

    const gradientr = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "gradientr")
      .attr("x1", "0%")
      .attr("x2", "100%")
      .attr("y1", "50%")
      .attr("y2", "0%");

    gradientr
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#C9E1E9")
      .attr("stop-opacity", 1);

    gradientr
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#C9E1E9")
      .attr("stop-opacity", 0);

    // Add the area
    svg
      .append("path")
      .datum(datasets[0])
      .attr("fill", "#94c4d2")
      .attr("opacity", 0.4)
      .attr("d", area);

    svg
      .append("path")
      .datum(datasets[1])
      .attr("fill", "url(#gradient)")
      .attr("opacity", 0.5)
      .attr("d", area);

    svg
      .append("path")
      .datum(datasets[2])
      .attr("fill", "url(#gradientl)")
      .attr("opacity", 0.5)
      .attr("d", area);
  };

  return <svg y={offset} ref={svgRef} width={width} height={height}></svg>;
};
