import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesSerialNo } from "../styles/CommonStyles";

const styles = stylesSerialNo;

export const CommonSerialNo = ({ invoice, label1, label2 }) => (
  <View style={styles.serialContainer}>
    <View style={styles.serialInnerContainer}>
      <View style={styles.serialNoContainer}>
        <Text style={styles.serialLabel}>{label1 + ":"}</Text>
        <Text style={styles.serialNo}>{invoice.invoice_no}</Text>
      </View>
      <View style={styles.serialNoDateContainer}>
        <Text style={styles.serialLabel}>{label2 + ":"}</Text>
        <Text style={styles.serialNoDate}>{invoice.trans_date}</Text>
      </View>
    </View>
  </View>
);
