import { LoincActionTypes } from "./loinc.types";

const INITIAL_STATE = {
  mdspecialities: null,
  metalabmap: null,
};

const loincReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoincActionTypes.MD_SPECIALITY:
      return {
        ...state,
        mdspecialities: action.payload,
      };
    case LoincActionTypes.DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
    case LoincActionTypes.ORGDEPARTMENTS:
      return {
        ...state,
        orgdepartments: action.payload,
      };
    case LoincActionTypes.LABCOMPONENTS:
      return {
        ...state,
        labcomponents: action.payload,
      };
    case LoincActionTypes.LABCOMPONENTROOT:
      return {
        ...state,
        labcomponentroot: action.payload,
      };
    case LoincActionTypes.LABCOMPONENTTREE:
      return {
        ...state,
        labcomponenttree: action.payload,
      };
    case LoincActionTypes.METALAB_MAP:
      return {
        ...state,
        metalabmap: action.payload,
      };
    default:
      return state;
  }
};

export default loincReducer;
