import log from "../../../services/log";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
// import "../../../react-datepicker.css";
import { Grid, Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  updateProfile,
  setUserProfile,
} from "../../../redux/actions/auth";
import { useFormik } from "formik";
import { userProfileSchema } from "../../schema/userSchema";
import { InputCheckBoxType2 } from "../../common/SelectCheck";
import { MyButtons } from "../../CustomComponents/MyButtons";

const Label = styled("label")({
  marginBottom: "0.2rem",
  fontSize: "0.8rem",
  position: "relative",
  "&.required:after": {
    color: "red",
    fontWeight: 600,
    content: '"*"',
    position: "absolute",
    right: "-10px",
  },
});

export default function Registration(props) {
  const { handleStepDone } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );
  const selectprofile = useSelector((state) =>
    state?.auth?.profile ? state.auth.profile[0] : null
  );
  const [bdate, setBdate] = useState(
    selectprofile ? Date.parse(selectprofile.dob) : null
  );

  const profileform = useFormik({
    initialValues: {
      accountId: user?.metadata ? user.metadata.accountId : null,
      email: user?.metadata ? user.metadata.email : null,
      name: user ? user.name : null,
      dob: "",
      gender: "",
      phone: "",
      mobile: "",
      emailpref: true,
      textpref: false,
      status: "active",
      id: null,
    },

    validationSchema: userProfileSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("clinicaltestinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData.metadata = {};
      jsonData.metadata.accountId = values.accountId;
      jsonData.metadata.email = values.email;
      jsonData.name = values.name;
      jsonData.dob = values.dob;
      jsonData.gender = values.gender;
      jsonData.phone = values.phone;
      jsonData.mobile = values.mobile;
      jsonData.emailpref = values.emailpref;
      jsonData.textpref = values.textpref;
      jsonData.status = values.status;

      // log.info("user input", jsonData);

      if (values.id) {
        jsonData.id = values.id;
        dispatch(updateProfile(jsonData));
      } else {
        dispatch(setUserProfile(jsonData));
      }
      handleStepDone();
    },
    onReset: (values) => {},
  });

  const handleStepDoneLocal = () => {
    log.info("step done", profileform.values.gender);
    handleStepDone();
  };

  useEffect(() => {
    profileform.setFieldValue("id", selectprofile?.id);
    profileform.setFieldValue("dob", selectprofile?.dob);
    profileform.setFieldValue("gender", selectprofile?.gender);
    profileform.setFieldValue("phone", selectprofile?.phone);
    profileform.setFieldValue("mobile", selectprofile?.mobile);
    profileform.setFieldValue("emailpref", selectprofile?.emailpref);
    profileform.setFieldValue("textpref", selectprofile?.textpref);
  }, [selectprofile]);

  useEffect(() => {
    if (user) {
      dispatch(
        getProfile({
          metadata: {
            accountId: user?.metadata?.accountId,
          },
        })
      );
    }
  }, [user, dispatch]);

  const handleDob = (value) => {
    profileform.setFieldValue("dob", value.toISOString());
    setBdate(value);
  };

  // log.info("registration", profileform.values);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="subtitle">
        Verify and update your information
      </Grid>

      <Grid
        item
        xs={12}
        className="flex-grow-1"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", lg: "row" },
          width: "100%",
        }}
      >
        <Box
          mr={2}
          className="flex-grow-1"
          sx={{ width: { xs: "100%", md: "100%", lg: "50%" } }}
        >
          <Label htmlFor="name" className="required">
            Name
          </Label>
          <input
            disabled={true}
            type="text"
            className="form-control"
            id="name"
            name="name"
            defaultValue={profileform.values.name}
            onChange={profileform.handleChange}
          />
        </Box>
        <Box
          mr={2}
          className="flex-grow-1"
          sx={{ width: { xs: "100%", md: "100%", lg: "50%" } }}
        ></Box>
      </Grid>

      <Grid
        item
        xs={12}
        className="flex-grow-1"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", lg: "row" },
          width: "100%",
        }}
      >
        <Box
          mr={2}
          className="flex-grow-1"
          sx={{ width: { xs: "100%", md: "100%", lg: "50%" } }}
        >
          <Label htmlFor="dob" className="required">
            Date of Birth
          </Label>
          <DatePicker
            selected={bdate}
            className="form-control"
            peekNextMonth={true}
            showMonthDropdown={true}
            showYearDropdown={true}
            dropdownMode="select"
            maxDate={new Date()}
            onChange={handleDob}
          />
        </Box>
        <Box
          mr={2}
          className="flex-grow-1"
          sx={{ width: { xs: "100%", md: "100%", lg: "50%" } }}
        >
          <Box>
            <Label className="required">Gender</Label>
          </Box>

          <Box
            mt={2}
            className=""
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Box>
              <input
                onChange={profileform.handleChange}
                type="radio"
                id="male"
                name="gender"
                value="male"
                className=""
                defaultChecked={profileform.values.gender === "male"}
              />
              <label className="my10" htmlFor="male">
                Male
              </label>
            </Box>
            <Box>
              <input
                onChange={profileform.handleChange}
                type="radio"
                id="female"
                name="gender"
                value="female"
                className=""
                defaultChecked={profileform.values.gender === "female"}
              />
              <label className="my10" htmlFor="female">
                Female
              </label>
            </Box>
            <Box>
              <input
                onChange={profileform.handleChange}
                type="radio"
                id="other"
                name="gender"
                value="other"
                className=""
                defaultChecked={profileform.values.gender === "other"}
              />
              <label
                className=""
                style={{ marginLeft: "10px" }}
                htmlFor="other"
              >
                Other
              </label>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} className="subtitle">
        Contact Information
      </Grid>

      <Grid
        item
        xs={12}
        className="flex-grow-1"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", lg: "row" },
          width: "100%",
        }}
      >
        <Box
          mr={2}
          className="flex-grow-1"
          sx={{ width: { xs: "100%", md: "100%", lg: "50%" } }}
        >
          <Label htmlFor="email" className="required">
            Registered Email
          </Label>
          <input
            disabled={true}
            type="text"
            className="form-control"
            value={profileform.values.email}
            id="email"
          />
        </Box>
        <Box
          mr={2}
          className="flex-grow-1"
          sx={{ width: { xs: "100%", md: "100%", lg: "50%" } }}
        >
          <Box>
            <Label>Contact Preferences</Label>
          </Box>

          <Box
            mt={2}
            className=""
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Box>
              <InputCheckBoxType2
                id="emailpref"
                name="emailpref"
                label="Email"
                isChecked={profileform.values.emailpref}
                onChange={profileform.handleChange}
                index={0}
              />
            </Box>
            <Box>
              <InputCheckBoxType2
                id="textpref"
                name="textpref"
                label="Text"
                isChecked={profileform.values.textpref}
                onChange={profileform.handleChange}
                index={0}
              />
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        className="flex-grow-1"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", lg: "row" },
          width: "100%",
        }}
      >
        <Box
          mr={2}
          className="flex-grow-1"
          sx={{ width: { xs: "100%", md: "100%", lg: "50%" } }}
        >
          <Label htmlFor="phno" className="required">
            Phone
          </Label>
          <input
            type="text"
            className="form-control"
            name="phone"
            defaultValue={profileform.values.phone}
            id="phone"
            onChange={profileform.handleChange}
          />
        </Box>

        <Box
          mr={2}
          className="flex-grow-1"
          sx={{ width: { xs: "100%", md: "100%", lg: "50%" } }}
        >
          <Label htmlFor="mobile" className="required">
            Mobile
          </Label>
          <input
            type="text"
            name="mobile"
            id="mobile"
            className="form-control"
            defaultValue={profileform.values.mobile}
            onChange={profileform.handleChange}
          />
        </Box>
      </Grid>

      <Grid item xs={12} className="text-color-primary">
        By choosing text, you agree to receive texts/calls, including by
        autodialed or prerecorded means, regarding this and future
        communication, Consent is not a condition of purchasing any goods or
        services. Normal charges may apply.
      </Grid>

      <Grid item xs={12}>
        <Box pt={2} pb={2} pl={0} pr={0} className="colfr">
          <MyButtons
            onClick={profileform.submitForm}
            btnKey={"update"}
            btnLabel={"Update Profile"}
            enState={false}
          />
          &nbsp; &nbsp;
          <MyButtons
            onClick={handleStepDoneLocal}
            btnKey={"done"}
            btnLabel={"Done"}
            enState={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
