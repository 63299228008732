// import node modules
import * as React from "react";
import { Box, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { getUserAccessFamilyList } from "../../../../redux/user/user.actions";

export const ProfileSection = ({ setCurrentStep }) => {
  const [isLoading, setLoading] = React.useState(false);
  const [familyData, setFamilyData] = React.useState([]);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const userList = useSelector((state) => state?.user?.userfamilylist?.results);
  const profile = useSelector((state) => state.auth.user.user);

  const isLoadingFamilyList = useSelector(
    (state) => state?.user?.userfamilylistloading
  );

  // const handleChange = (event, newValue) => {
  //   // console.log(event, newValue);
  //   setValue(newValue);
  // };

  const getFamilyList = async () => {
    setLoading(true);
    let params = { metadata: { accessId: profile?.metadata?.accountId } };
    dispatch(getUserAccessFamilyList(params));
    let res = userList;
    let dummyData = {
      created_at: profile.created_at,
      dob: "2010-06-14T21:06:06.853Z",
      id: profile.id,
      metadata: {
        // accessId: "b8ac5cc6-f720-46b6-9345-1ab2ecf58aad",
        role: profile.metadata.role,
        email: profile.metadata.email,
        accountId: profile.metadata.accountId,
      },
      name: profile.name,
      status: "active",
      updated_at: profile.updated_at,
    };
    res.unshift(dummyData);
    setFamilyData(res);
    setLoading(false);
  };

  React.useEffect(() => {
    getFamilyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "18px",
          color: "#404040",
          fontFamily: "DM SANS",
        }}
      >
        Hi {profile.name}, who is this appointment for?
      </Typography>

      <Box sx={{ mt: 2 }}>
        {(isLoadingFamilyList || isLoading) && (
          <>
            {/* Loading... */}
            {/* <Box className="bordered_tab_boundary_grey"></Box>
            <Box className="bordered_tab_boundary_grey"></Box> */}
          </>
        )}
        {!isLoadingFamilyList &&
          !isLoading &&
          familyData.map((data, index) => (
            <Box
              key={index}
              className="bordered_tab_boundary"
              onClick={() => setCurrentStep(1)}
            >
              <button className="bordered_tab">{data.name}</button>
              <ChevronRightIcon
                sx={{
                  fill: "#404040",
                  marginLeft: "1rem",
                  marginTop: "3px",
                }}
              />
            </Box>
          ))}
      </Box>
    </>
  );
};
