import useStyles from "./useStyles";
import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@material-ui/core";

function InputFormComponent(props) {
  const clases = useStyles();
  const {
    id,
    name,
    label,
    values,
    handleChange,
    errors,
    errorText,
    help,
    disabled,
    defaultValue,
  } = props;

  const [value, setValue] = useState();

  return (
    <>
      <FormControl variant="standard" fullWidth>
        <TextField
          key={props.id}
          style={{
            width: "100%",
            paddingBottom: "0px",
            marginTop: "0px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          name={name}
          label={<span className="text-color">{label}</span>}
          disabled={disabled}
          defaultValue={defaultValue}
          className={errors ? "" : "text-color"}
          error={errors ? true : false}
          variant="standard"
          onChange={handleChange}
        />

        {errors && !values && (
          <span className={clases.errorMsg}>Field is mandatory</span>
        )}
        {errors && errorText && (
          <span className={clases.errorMsg}>{errorText}</span>
        )}
        {help && <span className="text-color-primary">{help}</span>}
      </FormControl>
    </>
  );
}

export default InputFormComponent;
