import { useSelector } from "react-redux";
import log from "../../services/log";
import { ProviderApptSearch } from "./ProviderApptSearch";

export const AppointmentProvider = (props) => {
  const selectService = useSelector((state) => state?.appointment?.apptstype);

  log.info("appointment provider", selectService);

  return (
    <div>
      <ProviderApptSearch handleStepDone={props?.handleStepDone} />
    </div>
  );
};
