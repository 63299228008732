import React, { useRef, useEffect, useState } from "react";
import { HomeRectArea } from "./HomeRectArea";
import { GraphArea } from "./GraphArea";

export function HomeGraph({ datasets, isFullWidth }) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const arOrig = isFullWidth ? 1.48464 : 2.08464; // 0.4797; 2.08464
  const arGraph = 3.1840796; //0.3140625;
  const offset2 = 0;

  useEffect(() => {
    // Define a function to handle window resize
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <svg
      width={dimensions.width}
      height={parseInt(Math.ceil(dimensions.width / arOrig))}
    >
      <HomeRectArea
        isFullWidth={isFullWidth}
        width={dimensions.width}
        height={offset2 + parseInt(Math.ceil(dimensions.width / arOrig))}
      />

      <GraphArea
        datasets={datasets}
        width={dimensions.width}
        height={parseInt(Math.ceil(dimensions.width / arGraph))}
        offset={parseInt(
          Math.ceil(dimensions.width / arOrig) -
            Math.ceil(dimensions.width / arGraph)
        )}
      ></GraphArea>
    </svg>
  );
}
