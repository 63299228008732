import React, { useState, useEffect } from "react";
import { Grid, Box, styled, Paper } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { getAddressList } from "../../redux/actions/addresses";
import { getMDGroupList } from "../../redux/user/user.actions";

import { Title } from "../common/Title";
import ThreeDotLocnDeptMenu from "./ThreeDotLocnDeptMenu";

import { SpecialistList } from "../organization/components/SpecialistList";
import OrgInput from "../forms/OrgInput";
import OrgsSelectList from "../department/OrgsSelectList";
import GroupInput from "../forms/GroupInput";
import { NewAddressOrg } from "../forms/NewAddressOrg";
import OrgGroupSelectList from "../department/OrgGroupSelectList";
import { LocationOrg } from "../organization/components/LocationOrg";
import { SelectOrgLocation } from "./SelectOrgLocation";
import DepartmentNonList from "../department/DepartmentNonList";
import { SpecialistIndex } from "../department/SpecialistIndex";
import DeptAddSpecialists from "../department/DeptAddSpecialists";
import { EditTeamSpecialist } from "../organization/components/EditTeamSpecialist";
import DepartmentIndex from "../department/DepartmentIndex";
import ArrowDropDownTwoToneIcon from "@mui/icons-material/ArrowDropDownTwoTone";
import { OrgEditor } from "../organization/components/OrgEditor";

import log from "../../services/log";

export default function DeptList(props) {
  const { org } = props;

  const department = useSelector((state) => state?.orgs?.selectdepartment);
  const address = useSelector((state) => state?.addresses?.selectaddress);
  const orgs = useSelector((state) => state?.orgs?.orgs);
  const selectorg = useSelector((state) => state?.orgs?.selectorg);
  const selectorggroup = useSelector((state) => state?.orgs?.selectorggroup);
  const deptaddress = useSelector((state) => state?.addresses?.deptaddress);
  const spgrouplist = useSelector((state) => state?.user?.pgrouplist);

  const [expanded, setExpanded] = useState(false);
  const [alert, setAlert] = useState({
    msg: "",
    active: false,
  });

  const [actionState, setActionState] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (department && address) {
      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["departmentId"] = department?.metadata?.accountId;
      // jsonData.metadata['locId'] = address.metadata.accountId;
      // log.info("specialist jsonData", jsonData)
      dispatch(getMDGroupList(jsonData));
      // .then((r) => {
      //     log.info("specialist goruplist", r)
      // });
    }
  }, [department, address]);

  useEffect(() => {
    if (department) {
      const data = {};
      data.metadata = {};
      data.metadata.orgId = department?.metadata?.orgId;
      data.metadata.groupId = department?.metadata?.groupId;
      data.metadata.departmentId = department?.metadata?.accountId;
      // console.log("department loc fetch", data)
      dispatch(getAddressList(data));
    }
  }, [department]);

  const handleCancel = () => {
    setActionState("");
  };

  const handleChange = () => {
    setExpanded(!expanded);
  };

  function getFilterList2(alist, val1, val2) {
    return alist.filter(
      (item) => item.alias === val1 && item.location_id === val2
    );
  }

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "justified",
  }));

  log.info("orgs contents", actionState);

  return (
    <Grid container>
      <Grid item xs>
        <Box
          mb={2}
          sx={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="flex-grow-1">
            <div
              className="colfl0 text-color-primary cursor-pointer"
              style={{ fontSize: "24px", paddingTop: "6px", marginLeft: "0px" }}
              onClick={handleChange}
            >
              <ArrowDropDownTwoToneIcon fontSize="small" />
              &nbsp;
              {selectorg ? selectorg.orgName : ""}
              &nbsp;
              <span className="text-sm">
                {selectorggroup ? "( " + selectorggroup.groupName + " )" : ""}
              </span>
            </div>

            <div
              className="colfr0"
              style={{ paddingTop: "0px", marginRight: "16px" }}
            >
              <SelectOrgLocation />
            </div>
          </div>
          <div style={{ padding: "8px 0px 0px 8px" }}>
            <ThreeDotLocnDeptMenu
              setAction={setActionState}
              alertcallback={setAlert}
            />
          </div>
        </Box>

        <Box mb={2}>
          {expanded && (
            <Box p={2}>
              <Title>{"About"}</Title>

              {/* pending About data */}
            </Box>
          )}
        </Box>

        {actionState === "" ? (
          <Box mb={4} mt={0}>
            <SpecialistList specialistlist={spgrouplist} />
          </Box>
        ) : actionState === "addOrg" ? (
          <Box mb={4}>
            <OrgInput
              cancelCallback={handleCancel}
              title={"Add Organization Information"}
            />
          </Box>
        ) : actionState === "addOrgGroup" ? (
          <Box mb={4}>
            <GroupInput
              cancelCallback={handleCancel}
              title={"Add OrgGroup Information"}
            />
          </Box>
        ) : actionState === "addNewLoc" ? (
          <Box mb={4}>
            <NewAddressOrg
              cancel={handleCancel}
              title={"Add Location Information"}
            />
          </Box>
        ) : actionState === "selectOrg" ? (
          <Box mb={4}>
            <OrgsSelectList
              cancelEventPage={handleCancel}
              title={"Select Organization"}
            />
          </Box>
        ) : actionState === "selectOrgGroup" ? (
          <Box mb={4}>
            <OrgGroupSelectList
              cancelEventPage={handleCancel}
              title={"Select Organization Group"}
            />
          </Box>
        ) : actionState === "selectLoc" ? (
          <Box mb={4}>
            <LocationOrg cancel={handleCancel} title={"Select Location"} />
          </Box>
        ) : actionState === "addDept" ? (
          <Box mb={4}>
            <DepartmentNonList
              cancelEventPage={handleCancel}
              sourcekey={"metaorg"}
              org={org}
              title={"Add Department"}
            />
          </Box>
        ) : actionState === "enableDeptSearch" ? (
          <Box mb={4}>
            <DepartmentIndex
              cancelEventPage={handleCancel}
              sourcekey={"metaorg"}
              org={org}
              title={"Add Department"}
            />
          </Box>
        ) : actionState === "addSpecialist" ? (
          <Box mb={4}>
            <DeptAddSpecialists handleCancel={handleCancel} />
          </Box>
        ) : actionState === "editAbout" ? (
          <OrgEditor handleCancel={handleCancel} />
        ) : actionState === "editTeam" ? (
          <Box mb={4}>
            <EditTeamSpecialist
              handleCancel={handleCancel}
              title={"Update Specialist"}
            />
          </Box>
        ) : actionState === "enableSpecialistSearch" ? (
          <Box mb={4}>
            <SpecialistIndex handleCancel={handleCancel} />
          </Box>
        ) : (
          <Box />
        )}
      </Grid>
    </Grid>
  );
}
