// // importing node modules
import { ThemeProvider } from "@mui/material/styles";
import { useMetaTheme } from "../theme/ThemeContext";
import {
  Box,
  Toolbar,
  AppBar,
  Divider,
  Link as Linker,
  Typography,
  ListItemButton,
  ListItemText,
  ListItem,
  List,
} from "@mui/material";
// // importing other modules
import { useWindowSize, minWidthSize } from "../common/ConstantsAndValues";
import { HashLink as Link } from "react-router-hash-link";
import menuIcon from "../../assets/images/homepage/Menu.svg";
import crossIcon from "../../assets/images/homepage/Cross.svg";
import chevronRightMenu from "../../assets/images/homepage/ChevronRightMenu.svg";

// importing images
import logo from "../../assets/images/logo.png";
import { useState } from "react";

const LandingNavbar = ({ history }) => {
  //   // get theme
  const { theme } = useMetaTheme();

  //   // get sizing
  const size = useWindowSize();
  const [openMenu, setOpenMenu] = useState(false);

  const menuItems = [
    { title: "Home", section: "/#sectionOne" },
    { title: "About us", section: "/#sectionTwo" },
    { title: "Our Team", section: "/#sectionThree" },
    { title: "Contact us", section: "/#sectionFour" },
  ];
  return (
    <ThemeProvider theme={theme}>
      <AppBar
        color={"bgColor"}
        position="static"
        elevation={0}
        sx={{
          position: "fixed",
          zIndex: "99",
          boxShadow: "0 2px 15px 0 rgba(0,0,0,.1)",
        }}
      >
        {/*<Container >*/}
        <Toolbar sx={{ padding: "1rem 0", justifyContent: "space-between" }}>
          {size.width > minWidthSize.width && (
            <Linker href="/">
              <Box
                className="enable-link"
                id="imgLogoMain"
                component="img"
                sx={{
                  padding: (theme) => theme?.dashboardlogoSizes?.padding,
                  maxHeight: (theme) => theme?.dashboardlogoSizes?.maxHeight,
                  maxWidth: (theme) => theme?.dashboardlogoSizes?.maxWidth,
                }}
                alt="logo"
                src={logo}
              />
            </Linker>
          )}
          <Box
            sx={{ mr: 3, display: { xs: "block", sm: "none" } }}
            onClick={() => setOpenMenu(!openMenu)}
          >
            <img src={openMenu ? crossIcon : menuIcon} />
          </Box>

          <Box
            justifyContent="flex-end"
            gap={0}
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex" },
              alignItems: "center",
            }}
          >
            <Box className="flex cursor-pointer" sx={{}}>
              <Typography mx={3} sx={{ fontSize: "16px" }}>
                <Link to="/#sectionOne">Home</Link>
              </Typography>
              <Typography mx={3} sx={{ fontSize: "16px" }}>
                <Link to="/#sectionTwo">About us</Link>
              </Typography>
              <Typography mx={3} sx={{ fontSize: "16px" }}>
                <Link to="/#sectionThree"> Our team</Link>
              </Typography>
              <Typography mx={3} sx={{ fontSize: "16px" }}>
                <Link to="/#sectionFour"> Contact us</Link>
              </Typography>
            </Box>
          </Box>
        </Toolbar>
        {openMenu && (
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                background: "white",
                width: "100%",
                boxShadow: "0px 4px 0.5rem #dfdfdf",
              }}
            >
              {menuItems.map((data, index) => (
                <Link
                  key={index}
                  to={data.section}
                  onClick={() => setOpenMenu(false)}
                >
                  <Box className="mobile_menu_item">
                    {data.title}
                    <img src={chevronRightMenu} />
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
        )}
      </AppBar>
    </ThemeProvider>
  );
};
export default LandingNavbar;
