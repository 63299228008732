import * as React from "react";
import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { MyTooltipButtons } from "../../CustomComponents/MyButtons";
import { OtherTestsInput } from "../../othertests/OtherTestsInputMD";
import {
  setRadlabsOrder,
  setRadlabsOrderPresList,
} from "../../../redux/labs/labs.actions";

import log from "../../../services/log";
import { RadiologyTestsListTabsMD } from "./RadiologyTestsListTabsMD";
import { DicomNewPresView } from "./DicomNewPresView";

export const DicomNewMD = (props) => {
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const patient = useSelector((state) => state?.user?.selectedPatient);
  const searchtext = useSelector((state) => state?.user?.searchtext);
  const preslist = useSelector((state) => state?.labs?.radlabsorder);

  const [action, setAction] = useState("");
  const [checkedRows, setCheckedRows] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {}, []);

  const handlePrescribe = () => {
    // log.info("Prescribe", checkedRows);
    setAction("prescribe");
  };

  const handlePresList = () => {
    setAction("viewlist");
  };

  const handleCancel = () => {
    setAction("");
  };

  const handleReset = () => {
    // log.info("prescribelist reset");
    dispatch(setRadlabsOrderPresList([]));
  };

  const handleRemove = () => {
    log.info("prescribelist remove");
    dispatch(setRadlabsOrderPresList([]));
  };

  const handlePost = () => {
    // log.info("prescribelist post");
    preslist.forEach((d) => {
      dispatch(setRadlabsOrder(d));
    });
    // reset list after post
    dispatch(setRadlabsOrderPresList([]));
  };

  const handleSelectRows = (row) => {
    log.info("drugslist md", row);
    setCheckedRows(row);
  };

  // log.info("drugslist md", checkedRows);

  return (
    <Grid container>
      <Grid item xs>
        <div className="clear"></div>
        {action === "prescribe" ? (
          <div>
            <OtherTestsInput
              cancel={handleCancel}
              doctor={user}
              patient={patient}
              othertest={checkedRows}
            />
          </div>
        ) : action === "viewlist" ? (
          <DicomNewPresView cancel={handleCancel} />
        ) : (
          <div>
            <div className="colfr0">
              <MyTooltipButtons
                onClick={handlePresList}
                btnKey={"viewlist"}
                btnLabel={"View list"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handlePrescribe}
                btnKey={"prescriberad"}
                btnLabel={"Prescribe"}
                enState={false}
              />
            </div>

            <RadiologyTestsListTabsMD />
          </div>
        )}
      </Grid>
    </Grid>
  );
};
