import { LoincActionTypes } from "./loinc.types";

import { searchService } from "../../services/location-service";
import { SEARCH_SUBREGIONS } from "../actions/types";

const getMDSpeciality = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        dispatch({
          type: LoincActionTypes.MD_SPECIALITY,
          payload: response?.data?.data.hits,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

const getDepartments = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.data?.code == 200) {
        dispatch({
          type: LoincActionTypes.DEPARTMENTS,
          payload: response.data.hits,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

const getLabComponents = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.data?.code == 200) {
        let data = response?.data?.data?.hits;
        data.forEach((a) => (a.children = []));
        dispatch({
          type: LoincActionTypes.LABCOMPONENTS,
          payload: data,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

const getLabComponentRoot = (payload, root) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.data?.code == 200) {
        let data = response?.data?.data?.hits;
        data.forEach((a) => (a.children = []));
        dispatch({
          type: LoincActionTypes.LABCOMPONENTROOT,
          root: root,
          payload: data,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

const updateComponentTree = (data) => (dispatch) => {
  dispatch({
    type: LoincActionTypes.LABCOMPONENTTREE,
    payload: data,
  });
};

const setMetalabmap = (data) => (dispatch) => {
  dispatch({
    type: LoincActionTypes.METALAB_MAP,
    payload: data,
  });
};

export {
  getMDSpeciality,
  getDepartments,
  getLabComponents,
  getLabComponentRoot,
  updateComponentTree,
  setMetalabmap,
};
