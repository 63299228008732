import * as React from "react";
import {
  StyledTableCellHead,
  StyledTableRow,
} from "../../common/ConstantsAndValues";

export const proileMDTable = {
  title: {
    label: "Organization",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  status: {
    label: "Status",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  date: {
    label: "Associated since",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
};

export function ProfileMDHead() {
  const table = proileMDTable;

  return (
    <React.Fragment>
      <StyledTableRow style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead>{table.title.label}</StyledTableCellHead>
        <StyledTableCellHead>{table.status.label}</StyledTableCellHead>
        <StyledTableCellHead>{table.date.label}</StyledTableCellHead>
      </StyledTableRow>
    </React.Fragment>
  );
}
