import { Box, FormLabel, Grid, Typography } from "@mui/material";
import { BlueReloadIcon } from "../../../customIcons/BlueReloadIcon";
import { BluePencilIcon } from "../../../customIcons/BluePencilIcon";

export const ReviewSection = ({ setCurrentStep }) => {
  return (
    <>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "18px",
          color: "#404040",
          fontFamily: "DM SANS",
        }}
      >
        Please review all details below before booking your appointment
      </Typography>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "15px",
          color: "#404040",
          // fontFamily: "DM SANS",
          mt: 1,
        }}
      >
        To make changes to a section, click the edit icon TBD
      </Typography>
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                color: "#404040",
                fontFamily: "DM SANS",
                mt: 4,
              }}
            >
              Selected profile
            </Typography>
            <Box
              sx={{ display: "flex", mt: 4 }}
              onClick={() => setCurrentStep(0)}
            >
              <BlueReloadIcon size={0.9} />
              <Typography
                sx={{
                  fontWeight: "500",
                  // fontSize: "18px",
                  cursor: "pointer",
                  color: "#2A8AA7",
                  fontFamily: "DM SANS",
                  marginRight: "5px",
                }}
              >
                Start over
              </Typography>
            </Box>
          </Box>
          <Box className="review__card__blue">
            <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
              Patient
            </FormLabel>
            <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
              Anita Menon
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            onClick={() => setCurrentStep(1)}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                color: "#404040",
                fontFamily: "DM SANS",
                mt: 4,
              }}
            >
              Appointment details
            </Typography>{" "}
            <Box sx={{ display: "flex", mt: 4 }}>
              <BluePencilIcon size={0.9} />
              <Typography
                sx={{
                  fontWeight: "500",
                  // fontSize: "18px",
                  cursor: "pointer",
                  color: "#2A8AA7",
                  fontFamily: "DM SANS",
                  marginRight: "5px",
                }}
              >
                Edit
              </Typography>
            </Box>
          </Box>
          <Box className="review__card__blue">
            <Grid container className="">
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Reason for visit
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  Health check-up
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Details
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  No details provided
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            onClick={() => setCurrentStep(2)}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                color: "#404040",
                fontFamily: "DM SANS",
                mt: 4,
                marginRight: "5px",
              }}
            >
              Provider details
            </Typography>
            <Box sx={{ display: "flex", mt: 4 }}>
              <BluePencilIcon size={0.9} />
              <Typography
                sx={{
                  fontWeight: "500",
                  // fontSize: "18px",
                  cursor: "pointer",
                  color: "#2A8AA7",
                  fontFamily: "DM SANS",
                  marginRight: "5px",
                }}
              >
                Edit
              </Typography>
            </Box>
          </Box>
          <Box className="review__card__blue">
            <Grid container className="">
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Provider name
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  Dr. Aamir Khan
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Specialty
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  General medicine
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1, mt: 4 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Type of visit
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  In-person consult
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1, mt: 4 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Address
                </FormLabel>
                <Typography sx={{ fontWeight: "600" }}>
                  165, Tambakuwala, Dr Vigas Street, Charni Road, Mumbai
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            onClick={() => setCurrentStep(3)}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                color: "#404040",
                fontFamily: "DM SANS",
                mt: 4,
              }}
            >
              Date & time of visit
            </Typography>
            <Box sx={{ display: "flex", mt: 4 }}>
              <BluePencilIcon size={0.9} />
              <Typography
                sx={{
                  fontWeight: "500",
                  // fontSize: "18px",
                  cursor: "pointer",
                  color: "#2A8AA7",
                  fontFamily: "DM SANS",
                  marginRight: "5px",
                }}
              >
                Edit
              </Typography>
            </Box>
          </Box>
          <Box className="review__card__blue">
            <Grid container className="">
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Date
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  Thursday 13 October, 2023
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ px: 1 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  Time
                </FormLabel>
                <Typography sx={{ fontWeight: "600", lineHeight: "1" }}>
                  12:00 pm - 1:00 pm
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", pb: 6 }}>
        <button
          className={`${"meta__button_green"} mt-4`}
          onClick={() => setCurrentStep(5)}
        >
          Book appointment
        </button>
        <button
          className="meta__button_green_outlined mt-4 ml-3"
          onClick={() => setCurrentStep(3)}
        >
          Back: Date & Time
        </button>
      </Box>
    </>
  );
};
