import React, { useState, useEffect, useLayoutEffect } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, Container, Stack, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";
import { useOutletContext, useHistory, useLocation } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import Avatar from "@mui/material/Avatar";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import MoreVertTwoToneIcon from "@mui/icons-material/MoreVertTwoTone";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import MenuOpenTwoToneIcon from "@mui/icons-material/MenuOpenTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";

import { tableParam, CauseRSM, commonLinkData } from "./ConstantsAndValues";
import { MyArrowButtons } from "../CustomComponents/MyButtons";

import { setAppNavxState } from "../../redux/appstate/appstate.actions";

export default function MenuRightMore(props) {
  const { setOpenRSM, openRSM, handleChange, data } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const loc = useLocation();
  const commonLink = loc.pathname.substring(0, loc.pathname.lastIndexOf("/"));
  const navxstate = useSelector((state) => state?.appstate?.navxstate);

  const dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleChangeLocal = (event, newValue) => {
    dispatch(setAppNavxState(newValue));
    handleChange(event, newValue);
  };

  return (
    <Box mr={1} mt={1}>
      <Tooltip title="more...">
        <IconButton
          onClick={handleOpen}
          size="small"
          aria-controls={open == true ? "right-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open == true ? "true" : undefined}
          sx={{
            boxShadow: "0 !important",
            outline: "none",
            "&:focus": {
              border: "0 !important",
              outline: "none",
              boxShadow: "none",
              backgroundColor: "transparent",
            },
          }}
        >
          <MoreVertTwoToneIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="right-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            paddingLeft: 2,
            paddingRight: 2,
            minWidth: tableParam.width,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={handleClose}
          style={{
            padding: "4px 4px 4px 24px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="TitleMenu">OPTIONS</span>
          <span className="text-color-primary ">
            <MyArrowButtons
              onClick={handleClose}
              btnKey={"e"}
              btnLabel={"Close"}
              enState={false}
            />
          </span>
        </MenuItem>

        <Divider />

        {/*<Box maxHeight={tableParam.minHeight/2 } className="list">*/}
        <Box>
          <Box>
            <MenuItem
              key={"aaa"}
              onClick={() => setOpenRSM(!openRSM)}
              sx={{ fontSize: "small", paddingRight: "10px", maxWidth: "100%" }}
            >
              <ListItemIcon className="text-color my5">
                {openRSM ? <MenuOpenTwoToneIcon /> : <MenuTwoToneIcon />}
              </ListItemIcon>
              <ListItemText className="text-color ">
                <span style={{ fontSize: "small" }}>
                  {openRSM ? "Close Right Menu " : "Open Right Menu "}
                </span>
              </ListItemText>
            </MenuItem>
          </Box>

          {data &&
            data.map((row, i) => (
              <Box
                key={"bx" + i}
                className={
                  navxstate === row.link
                    ? "border-active menu-text flex-fill text-color-menu-active"
                    : "menu-text flex-fill text-color-menu"
                }
              >
                <MenuItem
                  key={"aa" + i}
                  onClick={(e) => handleChangeLocal(row.label, row.link)}
                  sx={{ fontSize: "small", maxWidth: "100%" }}
                >
                  <ListItemIcon className="text-color my5">
                    {row.icon}
                  </ListItemIcon>
                  <ListItemText className="text-color">
                    <span style={{ fontSize: "small" }}>{row.label}</span>
                  </ListItemText>
                </MenuItem>
              </Box>
            ))}

          {commonLinkData &&
            commonLinkData.map((row, i) => (
              <Box
                key={"bx" + i}
                className={
                  navxstate === row.link
                    ? "border-active menu-text flex-fill text-color-menu-active"
                    : "menu-text flex-fill text-color-menu"
                }
              >
                <MenuItem
                  key={"aa" + i}
                  onClick={(e) =>
                    handleChangeLocal(row.label, commonLink + row.link)
                  }
                  sx={{ fontSize: "small", maxWidth: "100%" }}
                >
                  <ListItemIcon className="text-color my5">
                    {row.icon}
                  </ListItemIcon>
                  <ListItemText className="text-color">
                    <span style={{ fontSize: "small" }}>{row.label}</span>
                  </ListItemText>
                </MenuItem>
              </Box>
            ))}
        </Box>
      </Menu>
    </Box>
  );
}
