import React, { useState } from "react";
import { Box, Link } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logout, register, updateUser } from "../../redux/actions/auth";
import { MyFullButtonsColor } from "../CustomComponents/MyButtons";
import Password from "../CustomComponents/Password";
import InputComponent from "../CustomComponents/InputComponent";
import InputActivityRole from "../CustomComponents/InputActivityRole";
import log from "../../services/log";
import { buildUserUpdateQuery } from "../dbcq/userdb";

export default function SignNewpass(props) {
  const auth = useSelector((store) => store.auth);
  const user = useSelector((store) => store.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({ passwordError: false, error: false });

  //console.log('signup', auth);

  const handleClickShowPassword = (e) => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = (e) => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handlePasswordChange = (event) => {
    setValues({
      ...values,
      password: event.target.value,
      passwordError: event.target.value ? false : true,
      error: event.target.value ? false : true,
    });
  };

  const handleConfirmPasswordChange = (event) => {
    const v = event?.target?.value;
    const p = values?.password;

    setValues({
      ...values,
      confirmPassword: v,
      confirmPasswordError: v && v === p ? false : true,
      error: v && v === p ? false : true,
    });
  };

  const handleSubmit = (event) => {
    // console.log("clicked");
    event.preventDefault();
    const jsonData = buildUserUpdateQuery(auth?.verify?.user, values?.password);
    dispatch(updateUser(jsonData)).then(
      (r) => {
        dispatch(logout());
        navigate("/sign");
      },
      (error) => {
        log.info("password update error - " + error);
      }
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          marginBottom: "0px !important",
        }}
      >
        <h3 className="text-color-primary">Enter New Password</h3>
        <span
          className="text-color-primary"
          sx={{ marginBottom: "16px" }}
        ></span>

        <Password
          id="password"
          name="password"
          label="New Password"
          errors={values.passwordError}
          showPassword={values.showPassword}
          handleClickShowPassword={handleClickShowPassword}
          handleChange={handlePasswordChange}
        />
        <Password
          id="confirmPassword"
          name="confirmPassword"
          label="Confirm New Password"
          errors={values.confirmPasswordError}
          errorText={"This should match Password"}
          showPassword={values.showConfirmPassword}
          handleClickShowPassword={handleClickShowConfirmPassword}
          handleChange={handleConfirmPasswordChange}
        />

        <Box className="clear">&nbsp; </Box>

        <MyFullButtonsColor
          onClick={handleSubmit}
          btnKey={"x"}
          btnLabel={"Update"}
          enState={false}
        />

        <Box className="clear">&nbsp; </Box>

        <Box
          className="clear text-color-read"
          sx={{
            padding: "16px",
            margin: "16px 0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Box className="clear">&nbsp; </Box>
          <center>
            Already have an account?
            <Link href="/sign" underline="always" className="text-color">
              SIGN IN
            </Link>
          </center>
          <Box className="clear">&nbsp; </Box>
          <center>
            Need an account?
            <Link href="/signup" underline="always" className="text-color">
              Register
            </Link>
          </center>
          <Box className="clear">&nbsp; </Box>
        </Box>
      </Box>
    </>
  );
}
