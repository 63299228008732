import React, { useState, useEffect, useRef } from "react";
import { Grid, Box, Container, Paper, Typography } from "@mui/material";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { MyButtons } from "../../CustomComponents/MyButtons";
import {
  pageOptions,
  tableParam,
  colWidthSign,
  SpanAnchor,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../../common/ConstantsAndValues";
import { Title } from "../../common/Title";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export default function InveListCollection(props) {
  const { handlePayPresc, handleHoldPresc, data, patient, doctor, err } = props; //

  const priceList = [];
  const tax = [];

  const [rows, setRows] = useState(data); //will hol

  //console.log('data=',data. rows);

  const classes = useStyles();
  let errMsg = err;

  const ref = useRef(null);

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = data.filter((det) => {
        return (
          det.ctestid.toLowerCase().startsWith(keyword.toLowerCase()) ||
          det.ctest.toLowerCase().startsWith(keyword.toLowerCase())
        );
      });
      setRows(results);
    } else {
      setRows(data); //important
    }
  };

  useEffect(() => {
    setRows(data); //important
  }, [data]);

  useEffect(() => {
    //setRows(data);       //important
  }, [rows]);

  const handleHoldThis = () => {
    handleHoldPresc(data); //whole prescriptions
  };

  //const handleResetThis = () => window.location.reload(false);
  //const handleResetThis = ()=>{ setRows();  setRows(data); setRows(data);console.log("reset");};

  const handleResetThis = () => {
    //does not reset the input fields at all. solution pending

    setRows(data); //important

    console.log("reset . not working properly"); //pending
  };

  const handleDone = () => {};

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list); //essential
  };

  return (
    <Grid container>
      <div className="text-color-error">{errMsg !== "" && errMsg}</div>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0"></div>
          <div className="colfr0" style={{ marginLeft: "40px" }}>
            {patient} {doctor && "[ " + doctor + " ]"}
          </div>
          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={filter}
              placeholder="C l i n i c a l &nbsp; &nbsp; T e s t s"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div>
          <MyButtons
            onClick={handleDone}
            btnKey={"done"}
            btnLabel={"Done"}
            enState={false}
          />
        </div>
      </div>
      <div className="clear"></div>
      <TableContainer
        component={Paper}
        elevation={0}
        style={{ minHeight: tableParam.minHeight, marginTop: "8px" }}
        className="list"
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <StyledTableRowHead>
              <StyledTableCellHead>{"ID"}</StyledTableCellHead>
              <StyledTableCellHead>{"C Tests"}</StyledTableCellHead>
              <StyledTableCellHead>{"Assigned"}</StyledTableCellHead>
              <StyledTableCellHead>{"Collection"}</StyledTableCellHead>
              <StyledTableCellHead>{"Remarks"}</StyledTableCellHead>

              <StyledTableCellHead>{"Instruction"}</StyledTableCellHead>
            </StyledTableRowHead>
          </TableHead>

          <TableBody>
            {rows && rows.length > 0 ? (
              rows.map((row, i) => (
                <StyledTableRow key={"row" + i} className="text-color lh-35">
                  <StyledTableCell
                    className={row.qty <= 0 ? "text-color-gray" : "text-color"}
                  >
                    <b>{row.masterid}</b>.{row.id}
                  </StyledTableCell>
                  <StyledTableCell
                    className={row.qty <= 0 ? "text-color-gray" : "text-color"}
                  >
                    {row.ctest}
                  </StyledTableCell>
                  <StyledTableCell className="border-db">
                    <input
                      key={row.id + i + "r"}
                      style={{ maxWidth: "100px", textAlign: "right" }}
                      defaultValue={row.assign}
                      name="assign"
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "50px" }}>
                    <input
                      key={row.id + i + "q"}
                      style={{ textAlign: "center" }}
                      defaultValue={row.specimen}
                      name="specimen"
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </StyledTableCell>

                  <StyledTableCell>
                    <input
                      key={row.id + i + "re"}
                      defaultValue={row.remarks}
                      name="remarks"
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </StyledTableCell>

                  <StyledTableCell>{row.instruction}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow key={"pt0" + 0}>
                <StyledTableCell colSpan="6">
                  <span className="text-color-error">No results found!</span>
                </StyledTableCell>
              </StyledTableRow>
            )}
            <StyledTableRow className="bg-gray">
              <StyledTableCell colSpan="6">{""}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
