import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Box, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { appointmentSchema } from "../schema/appointmentSchema";
import { createProviderAppointment } from "../../redux/appointment/appointment.actions";
import { colChart, eventsListCal } from "../common/ConstantsAndValues";
import { Title } from "../common/Title";
import { DialogMSStyles } from "../location/LocMSStyles";

import { MyTooltipButtons } from "../CustomComponents/MyButtons";
import { getGroupAddressList } from "../../redux/actions/addresses";
import log from "../../services/log";

export const CalInputformProvder = (props) => {
  const { closeForm } = props;

  const provider = useSelector((state) => state?.user?.selectmdgroup);
  const eventdates = useSelector((state) => state?.appointment?.dates);
  const user = useSelector((state) => state?.auth?.user?.user);
  const [options, setOption] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (provider) {
      dispatch(
        getGroupAddressList({
          metadata: {
            groupId: provider?.metadata?.groupId,
          },
        })
      );
    }
  }, [dispatch, provider]);

  const appointmentForm = useFormik({
    initialValues: {
      userId: null,
      email: null,
      guestId: "",
      guestName: "",
      userName: null,
      providerId: null,
      providerName: null,
      eventTitle: null,
      eventType: null,
      eventDescp: null,
      locId: "virtual",
      address: null,
      dateStart: null,
      dateEnd: null,
    },
    validationSchema: appointmentSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      log.info("clinicaltestinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData.metadata = {};
      jsonData.metadata.userId = values.userId;
      jsonData.metadata.providerId = values.providerId;
      jsonData.userName = values.userName;
      jsonData.providerName = values.providerName;
      jsonData.metadata.email = values.email;
      jsonData.eventTitle = values.eventTitle;
      jsonData.eventDescp = values.eventDescp;
      jsonData.metadata.eventType = values.eventType;
      jsonData.dateStart = values.dateStart;
      jsonData.dateEnd = values.dateEnd;
      jsonData["eventAddress"] = {};
      jsonData.eventAddress["locId"] = values.locId;
      jsonData.eventAddress["address"] = values.address;
      jsonData.metadata.guestId = values.guestId;
      jsonData.guestName = values.guestName;

      // log.info("metacal", jsonData);
      dispatch(createProviderAppointment(jsonData));
      closeForm();
    },
    onReset: (values) => {},
  });

  useEffect(() => {
    if (eventdates) {
      appointmentForm.setFieldValue("dateStart", eventdates.startStr);
      appointmentForm.setFieldValue("dateEnd", eventdates.endStr);
    }
  }, [eventdates]);

  useEffect(() => {
    if (eventsListCal) {
      const newoptions = [];
      eventsListCal.forEach((e) =>
        newoptions.push({ label: e.title, value: e })
      );
      setOption(newoptions);
    }
  }, [eventsListCal]);

  useEffect(() => {
    if (provider) {
      appointmentForm.setFieldValue(
        "providerId",
        provider?.metadata?.accountId
      );
      appointmentForm.setFieldValue("providerName", provider?.name);
    }
  }, [provider]);

  useEffect(() => {
    if (user) {
      appointmentForm.setFieldValue("userId", user?.metadata?.accountId);
      appointmentForm.setFieldValue("userName", user?.name);
      appointmentForm.setFieldValue("guestId", user?.metadata?.accountId);
      appointmentForm.setFieldValue("guestName", user?.name);
      appointmentForm.setFieldValue("email", user?.metadata?.email);
    }
  }, [user]);

  const handleSelect = (e) => {
    log.info("calinputform seloption", e);
    appointmentForm.setFieldValue("eventType", e.value.value);
    // setSelOption(e);
  };

  log.info("calinputform", eventdates);

  return (
    <Grid
      item
      xs
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: colChart.width }}>
        <Box
          mb={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "auto",
          }}
        >
          <Box className="colfl0">
            <Title>{"Event."}</Title>
          </Box>

          <Box className="colfr0">
            <MyTooltipButtons
              onClick={closeForm}
              btnKey={"cancel"}
              btnLabel={"Close"}
              enState={false}
            />
          </Box>
        </Box>
        <Box className="clear" />
        <Box
          mt={2}
          className="text-color-error"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          {appointmentForm.errors.eventType && (
            <span>{appointmentForm.errors.eventType}</span>
          )}
        </Box>

        <Box
          mt={2}
          className="text-color"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Select
            isDisabled={false}
            options={options}
            placeholder="Event Type"
            onChange={handleSelect}
            defaultValue={appointmentForm.values.eventType}
            isSearchable={true}
            styles={DialogMSStyles}
            menuPlacement="auto"
            menuPosition="fixed"
            isMulti={false}
          />
          <TextField
            autoFocus
            margin="dense"
            id="eventTitle"
            name="eventTitle"
            label="Title"
            error={appointmentForm.errors.eventTitle ? true : false}
            defaultValue={appointmentForm.values.eventTitle}
            fullWidth
            variant="standard"
            onChange={appointmentForm.handleChange}
          />
          &nbsp;
          <TextField
            autoFocus
            margin="dense"
            id="eventDescp"
            name="eventDescp"
            label="Description"
            defaultValue={appointmentForm.values.eventDescp}
            error={appointmentForm.errors.eventDescp ? true : false}
            fullWidth
            variant="standard"
            onChange={appointmentForm.handleChange}
          />
          &nbsp;
          <TextField
            autoFocus
            margin="dense"
            id="address"
            name="address"
            label="Location"
            defaultValue={appointmentForm.values.address}
            error={appointmentForm.errors.address ? true : false}
            fullWidth
            variant="standard"
            onChange={appointmentForm.handleChange}
          />
        </Box>
        <Box mt={2}>
          <Box className="colfr0">
            <MyTooltipButtons
              onClick={appointmentForm.handleSubmit}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
