import * as React from "react";
import { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  Toolbar,
  Menu,
  Button,
  IconButton,
  Divider,
  Fade,
  Tooltip,
  Link,
  Typography,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.png";
import {
  MainbarNavigation,
  MetaPages,
  useWindowSize,
  minWidthSize,
  StyledDDMainHeadButton,
  StyledMainMenuButton,
  StyledDDMainMenuButton,
  StyledDDMainMenuItemHead,
  StyledDDMainMenuContainer,
  StyledNHMenuItem,
} from "../common/ConstantsAndValues";
import styled from "styled-components";
import { MyArrowButtons } from "../CustomComponents/MyButtons";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import MenuHeaderMore from "./MenuHeaderMore";
import { setAppMainbarState } from "../../redux/appstate/appstate.actions";
import log from "../../services/log";
import { useMetaTheme } from "../theme/ThemeContext";
import { BurgerIcon } from "../customIcons/BurgerIcon";
import ThemeToggleButton from "../buttons/ThemeToggleButton";
import { MainbarButton } from "../buttons/MainbarButton";
import { MetaIconButton } from "../buttons/MetaIconButton";
import VerticalLine from "../CustomComponents/VeriticalLine";
import { PersonIcon } from "../customIcons/PersonIcon";
import { MetaSearchIcon } from "../customIcons/MetaSearchIcon";
import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * variants
 *   langing page
 *   patient dashboard
 *   doctor dahsboard
 *   ...
 *   icon - color and size
 *   iconbutton - state handler
 *   patient
 *   buttons -
 *     dashboard? open, close
 *     search - open, close
 *     alets - open, close
 *     user - drop down
 *
 * @returns {JSX.Element}
 * @constructor
 */

const MainBar = () => {
  const user = useSelector((state) => state?.auth?.user?.user);
  const mbarstate = useSelector((state) => state?.appstate?.mbarstate);
  const dashurl = useSelector((state) => state?.appstate?.dashurl);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menulist, setMenulist] = useState(MainbarNavigation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useMetaTheme();
  const isXs = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const sizeScale = 0.7;
  const buildMenulist = () => {};

  useEffect(() => {}, [user]);

  const handleChange = (event) => {
    log.info("MBC", event);
    const loginm = menulist.login;
    const loginstate = loginm.isOpen;

    // dispatch(setAppMainbarState({id: event}))
    // handleCommon(event)
  };

  const handleLogin = (event) => {
    // log.info("Mainbar login: ", event);
    navigate("/sign");
    // const newstate = !menulist.login.isOpen;
    // const newfill =
    //   newstate == true ? theme.palette.primary.main : theme.palette.gray.main;
    // const newmenus = {
    //   ...menulist,
    //   login: {
    //     ...menulist.login,
    //     icon: <PersonIcon size={0.7} fill={newfill} />,
    //     isOpen: newstate,
    //   },
    // };
    // setMenulist(newmenus);

    // dispatch(setAppMainbarState({id: event}))
    // handleCommon(event)
  };

  const handleSearch = (event) => {
    // log.info("Mainbar login: ", event);
    const newstate = !menulist.search.isOpen;
    const newfill =
      newstate == true ? theme.palette.primary.main : theme.palette.gray.main;
    const newmenus = {
      ...menulist,
      search: {
        ...menulist.search,
        icon: <MetaSearchIcon size={sizeScale} fill={newfill} />,
        isOpen: newstate,
      },
    };

    setMenulist(newmenus);
    // dispatch(setAppMainbarState({id: event}))
    // handleCommon(event)
  };

  const handleMorelist = (event) => {
    // log.info("Mainbar login: ", event);
    const newstate = !menulist?.menu?.isOpen;
    const newfill =
      newstate == true
        ? theme?.palette?.primary?.main
        : theme?.palette?.gray?.main;
    const newmenus = {
      ...menulist,
      menu: {
        ...menulist.menu,
        icon: <BurgerIcon size={sizeScale} fill={newfill} />,
        isOpen: newstate,
      },
    };

    setMenulist(newmenus);
    // dispatch(setAppMainbarState({id: event}))
    // handleCommon(event)
  };

  const handleCloseNavMenu = (event) => {
    log.info("mbar event key", event);
    dispatch(setAppMainbarState({ id: event.currentTarget.value }));
    handleCommon(event.currentTarget.value);
  };

  const handleCommon = (ectv) => {
    if (!ectv) {
      return;
    }
    // console.log("MBC", ectv);

    if (ectv) {
      if (ectv === "/dashboard") {
        navigate(dashurl);
      } else {
        navigate(ectv);
      }
    } else {
      navigate("/sign");
    }
    setAnchorEl(null);
  };

  // log.info("mainbar", mbarstate);

  const calltheme = (t) => {
    log.info("current theme: ", t);
  };

  // log.info("mainbar", mbarstate);

  return (
    <ThemeProvider theme={theme}>
      <AppBar color={"bgColor"} position="static">
        {/*<Container >*/}
        <Toolbar>
          <Box
            onClick={() => handleChange("/")}
            className="enable-link"
            id="imgLogoMain"
            component="img"
            sx={{
              padding: (theme) => theme?.logoSizes?.padding,
              maxHeight: (theme) => theme?.logoSizes?.maxHeight,
              maxWidth: (theme) => theme?.logoSizes?.maxWidth,
            }}
            alt="logo"
            src={logo}
          />

          <Box
            justifyContent="flex-end"
            gap={0}
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              marginRight: (theme) => theme.margins.zero,
            }}
          >
            <StyledNHMenuItem
              disableGutters={false}
              m={0}
              p={0}
              sx={{ marginRight: theme.margins.login }}
              key={menulist["login"]._id}
            >
              <MetaIconButton onClick={handleLogin} menu={menulist["login"]} />
            </StyledNHMenuItem>
            <Box
              sx={{
                height: "24px",
                width: "1px",
                backgroundColor: (theme) => theme.palette.gray.light,
                mx: 2, // Margin for spacing on both sides
              }}
            />

            <StyledNHMenuItem
              sx={{ marginLeft: (theme) => theme.margins.zero }}
              disableGutters={false}
              m={0}
              p={0}
              key={menulist["language"]._id}
            >
              <MetaIconButton
                onClick={handleLogin}
                menu={menulist["language"]}
              />
            </StyledNHMenuItem>

            <StyledNHMenuItem
              disableGutters={false}
              m={0}
              p={0}
              key={menulist["search"]._id}
            >
              <MetaIconButton
                onClick={handleSearch}
                menu={menulist["search"]}
              />
            </StyledNHMenuItem>

            <StyledNHMenuItem
              disableGutters={false}
              m={0}
              p={0}
              sx={{ marginRight: (theme) => theme.margins.zero }}
              key={menulist["menu"]._id}
            >
              <MetaIconButton
                onClick={handleMorelist}
                menu={menulist["menu"]}
              />
            </StyledNHMenuItem>
          </Box>
        </Toolbar>
        {/*</Container>*/}
      </AppBar>
    </ThemeProvider>
  );
};

export default MainBar;
