import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

import SecurityTwoToneIcon from "@mui/icons-material/SecurityTwoTone";
import { Title } from "./Title";
import { useSelector } from "react-redux";
import ThreedotMenu from "./ThreedotMenu";

import ManageAccountsSharpIcon from "@mui/icons-material/ManageAccountsSharp";
import { picPath, tableParam } from "./ConstantsAndValues";
import { FormControl, Input, InputAdornment } from "@mui/material";
import { TextField } from "@mui/material";

export default function SecurityList(props) {
  const { setAction } = props;
  const wallet = useSelector((state) => state?.user?.wallet);
  const handleSelected = (event, row) => {};

  return (
    <React.Fragment>
      <Box
        pt={1}
        ml={2}
        mr={0}
        style={{ display: "flex", justifyContent: "space-between" }}
        width="100%"
      >
        <Box className="">
          <Title>
            <SecurityTwoToneIcon /> My security wallet
          </Title>
        </Box>
        <Box className="right" mr={3}>
          <ThreedotMenu setAction={setAction} />
        </Box>
      </Box>

      <Box
        className="list border-db"
        pt={0}
        ml={0}
        mr={2}
        mb={1}
        height={tableParam.minHeight / 2}
        width="100%"
      >
        <List>
          {wallet && (
            <ListItem
              button
              key={wallet.index}
              onClick={(event) => handleSelected(event, wallet)}
            >
              <ListItemIcon>
                <ManageAccountsSharpIcon
                  className={wallet?.address ? "text-color-inv" : ""}
                />
                :
              </ListItemIcon>
              <ListItemText
                primary={
                  <span style={{ fontSize: "small" }}>{wallet?.address}</span>
                }
              />
            </ListItem>
          )}
        </List>
      </Box>
    </React.Fragment>
  );
}
