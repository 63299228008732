import React from "react";
import SignInAskus from "../components/sign/sign-askus";

import { Gen400Container } from "../components/common/ConstantsAndValues";

export const SignInAskusPage = () => (
  <Gen400Container>
    <SignInAskus />
  </Gen400Container>
);
