import { InstanceMetadata } from "./InstanceMetadata";
import { Metadata } from "./Metadata";
import { OHIFInstanceMetadata } from "./OHIFInstanceMetadata";
import { OHIFSeriesMetadata } from "./OHIFSeriesMetadata";
import { OHIFStudyMetadata } from "./OHIFStudyMetadata";
import { SeriesMetadata } from "./SeriesMetadata";
import { StudyMetadata } from "./StudyMetadata";

const metadata = {
  Metadata,
  StudyMetadata,
  SeriesMetadata,
  InstanceMetadata,
  OHIFStudyMetadata,
  OHIFSeriesMetadata,
  OHIFInstanceMetadata,
};

export {
  Metadata,
  StudyMetadata,
  SeriesMetadata,
  InstanceMetadata,
  OHIFStudyMetadata,
  OHIFSeriesMetadata,
  OHIFInstanceMetadata,
};

export default metadata;
