import { useSelector } from "react-redux";
import RTestsTaxLAB from "./RTestsTaxLAB"; //COMMON
import log from "../../../services/log";

export const RadiologyTestsTaxLAB = (props) => {
  const searchtext = useSelector((state) =>
    state.drugs.drugtext ? state.user.searchtext : null
  );

  return (
    <div>
      <RTestsTaxLAB searchText={searchtext && searchtext.drugs} />
    </div>
  );
};
