import * as yup from "yup";

export const specialistSchema = yup.object({
  id: yup.string(),
  orgId: yup.string().required("org is required"),
  groupId: yup.string().required("org group is required"),
  departmentId: yup.string().required("org department is required"),
  providerId: yup.string().required("provider is required"),
  providerRole: yup.string().required("provider role is required"),
  providerEmail: yup.string().required("provider email is required"),
  orgName: yup.string().required("org name is required"),
  name: yup.string().required("provider is required"),
  specialization: yup.array().required("provider skills are required"),
  status: yup.string().required("status is required"),
});

export const specialistIndexSchema = yup.object({
  departmentId: yup.string(),
  providerId: yup.string().required("provider is required"),
  name: yup.string().required("provider is required"),
  role: yup.string().required("provider is required"),
  address: yup.string().required("address is required"),
  services: yup.string().required("services is required"),
  location: yup.object().required("location is required"),
});
