import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTableHead } from "../styles/DiagnosticsStyles";

const styles = stylesTableHead;

export const RTestsTableHeader = () => (
  <View style={styles.container} fixed>
    <Text style={styles.description}>Investigation</Text>
    <Text style={styles.remarks}>Instruction</Text>
  </View>
);
