import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
//import {useOutletContext} from "react-router-dom";
import { SearchBoxStandard } from "../../common/ConstantsAndValues";
import { paymentAgingDays } from "../../common/ConstantsAndValues";
import { MyButtons, MyHelpButtons } from "../../CustomComponents/MyButtons";
import useStyles from "../../CustomComponents/useStyles";
import { getLabsOrderList } from "../../../redux/labs/labs.actions";
import AccReceivables from "../../payments/AccReceivables";
import { getLedgerList } from "../../../redux/payment/payment.actions";
import log from "../../../services/log";

export default function AccListORG() {
  const user = useSelector((state) => state?.auth?.user?.user);
  const selectedPatient = useSelector((state) => state?.user?.selectedPatient);
  const patientBills = useSelector((state) => state?.drugs?.patientlist);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState(patientBills);
  const [checkedRows, setCheckedRows] = useState([]);
  const [age, setAge] = useState(0);

  var today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
  const CD = Date.parse(date);
  const classes = useStyles();

  const dispatch = useDispatch();

  const buildQuery = () => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata["patientId"] = selectedPatient?.accountId;
    return jsonData;
  };

  useEffect(() => {
    if (selectedPatient) {
      dispatch(getLedgerList(buildQuery())).then((r) => {});
    }
  }, [selectedPatient, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = patientBills.filter((v) =>
        v.medTitle.startsWith(keyword.toLowerCase())
      ); //to correct this
      setRows(results);
    } else {
      setRows(patientBills);
    }
  };

  const handleChangePage = (event, value) => {
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: value };
    //log.info("patient druglist", drugsquery("name", query.name, newpagedata));
    //dispatch(getDrugs(drugsquery("name", query.name, newpagedata))).then((r) => {
    // log.info("drugslist", r)
    // if (r.total.value && r.total.value > 0) {
    //     setPage({... pagedata,
    //         count: r.total.value,
    //         pages: r.total.value/pagedata.limit,
    //     });
    // }
    //});
    setPage(newpagedata);
  };

  const handleChangeRows = (event, value) => {
    //pagination part
    setPage({ ...pagedata, limit: value });
  };

  const handleRowClick = (e) => {
    var updatedList = [...checkedRows];

    if (findArrayElement(updatedList, e)) {
      updatedList.splice(checkedRows.indexOf(e), 1);
    } else {
      updatedList = [...checkedRows, e];
    }

    setCheckedRows(updatedList);
    //console.log("Prescribe", updatedList);
  };

  const handleRemindNow = () => {
    console.log("remindnow", checkedRows);
  };

  const findArrayElement = function (array, title) {
    return array.find((item) => {
      return item === title;
    });
  };

  const handlePayAge = (a) => {
    setAge(a);
    if (!rows) {
      return;
    }
    setRows(
      rows.filter((itm) => CD - Date.parse(itm.billDate) - a * 86400 * 1000 > 0)
    );
  };

  return (
    <Grid container>
      <Box
        className="flex-grow-1"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: { xs: "row", md: "row" },
            marginRight: { xs: "16px", md: "0px" },
          }}
          key="maintitle"
        >
          <div className="colfl0">
            Receivable: {age < 1 ? "" : age + "+ days"}
          </div>

          <div className="colfr0" width="">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="B i l l s"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </Box>
        {/*<Box*/}
        {/*    sx= {{*/}
        {/*        display: "flex",*/}
        {/*        justifyContent: "flex-end",*/}
        {/*        flexDirection: { xs: 'row', md: 'row'},*/}
        {/*        marginRight: {xs:"16px", md: "0px"}*/}
        {/*    }}*/}
        {/*    key            = "aging"*/}
        {/*>*/}
        {/*    {(paymentAgingDays.length > 0 ) && paymentAgingDays.map((dy,i)=>(*/}
        {/*    <span key={"ard"+ i }>*/}
        {/*    &nbsp; &nbsp; */}
        {/*    <MyHelpButtons*/}
        {/*        onClick    = {()=>handlePayAge( dy )}*/}
        {/*        btnKey     = {"aging"}*/}
        {/*        btnLabel   = {dy}*/}
        {/*        enState    = {false}*/}
        {/*        btnTooltip = {"Age " + dy + " days"}*/}
        {/*    />*/}
        {/*    </span>*/}
        {/*    ))*/}
        {/*    }*/}

        {/*</Box>*/}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: { xs: "8px 16px 8px 0px", md: "0px" },
          }}
          key="remind"
        >
          <MyButtons
            onClick={handleRemindNow}
            btnKey={"back"}
            btnLabel={"Remind Now"}
            enState={checkedRows.length < 1}
          />
        </Box>
      </Box>

      <AccReceivables billlist={rows} />
    </Grid>
  );
}
