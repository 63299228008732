// import axios from "axios";
import { API_URL } from "./Constants";
import { axiosApiInstance } from "./axiosmiddleware";

const getPatientDrugsService = (payload) => {
  return axiosApiInstance.post(API_URL + "medicines/list", payload);
};

const setPatientDrugsService = (payload) => {
  return axiosApiInstance.post(API_URL + "medicines/set", payload);
};

const updatePatientDrugsService = (payload) => {
  return axiosApiInstance.post(API_URL + "medicines/update", payload);
};

const getMedicinelistService = (payload) => {
  return axiosApiInstance.post(API_URL + "medicinelist/list", payload);
};

const setMedicinelistService = (payload) => {
  return axiosApiInstance.post(API_URL + "medicinelist/set", payload);
};

const updateMedicinelistService = (payload) => {
  return axiosApiInstance.post(API_URL + "medicinelist/update", payload);
};

export {
  getPatientDrugsService,
  setPatientDrugsService,
  updatePatientDrugsService,
  getMedicinelistService,
  setMedicinelistService,
  updateMedicinelistService,
};
