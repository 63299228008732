import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Collapse,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
} from "@mui/material";
import { selectOrgAddress } from "../../redux/actions/addresses";
import {
  StyledTableCell,
  StyledTableRow,
  tableParam,
} from "../common/ConstantsAndValues";
import { MyIconButtons } from "../CustomComponents/MyButtons";

export function LocationListRow(props) {
  const { row, handleRowClick } = props;
  const selectaddress = useSelector((state) => state?.addresses?.selectaddress);
  const [open, setOpen] = useState(false);
  const [maplist, setMaplist] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectaddress) {
      const isSelect = selectaddress.id === row.id;
      setSelectedRow(isSelect);
    } else {
      setSelectedRow(false);
    }
  }, [selectaddress]);

  useEffect(() => {
    if (row) {
      const newmap = [];
      // newmap.push({id: 'instruction', label: "Test Instructions", value: row._source.instructions});
      // newmap.push({id: 'turnaround', label: "Estimated turnaround", value: row._source.turnaround});
      setMaplist(newmap);
    } else {
      setMaplist([]);
    }
  }, [row]);

  const handleClick = () => {
    // log.info("ct row", row);
    setOpen(!open);
  };

  const handleFilter = () => {};

  const handleRowClickLocal = (row) => {
    const newstate = !selectedRow;
    setSelectedRow(newstate);
    handleRowClick(row, newstate);
  };

  // log.info("lablistrow", row);

  return (
    <React.Fragment>
      <StyledTableRow
        key={row.id}
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
        onClick={() => handleRowClickLocal(row)}
      >
        <StyledTableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </StyledTableCell>

        <StyledTableCell component="th" scope="row">
          <b>{row.formatted_address}</b>
          <Typography variant={"body2"}>{row.street}</Typography>
        </StyledTableCell>
        <StyledTableCell align="right">
          <Typography variant={"body2"}>{row.city ? row.city : ""}</Typography>
        </StyledTableCell>
        <StyledTableCell align="right">{row.area}</StyledTableCell>
        <StyledTableCell align="right">{row.updated_at}</StyledTableCell>
      </StyledTableRow>

      <TableRow
        key={"details" + row.id}
        className="text-color-gray bg-standard-offwhite"
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">Test Information</div>
              </div>

              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {maplist &&
                      maplist.map((m) => (
                        <TableRow key={m.id}>
                          <TableCell>{m.label}</TableCell>
                          <TableCell>{m.value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
