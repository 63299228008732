export const buildDepartmentQuery = (p, s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p?.page;
  jsonData["limit"] = p?.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["providerRole"] = "metapharma";
  jsonData.metadata["providerId"] = user?.metadata?.accountId;
  return jsonData;
};

/**
 * filter by org, gorup, department
 * @param p
 * @param s
 * @param org
 * @param group
 * @param loc
 * @returns {{}}
 */
export const buildOrgGroupDepartmentQuery = (p, s, org, group, loc) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p?.page;
  jsonData["limit"] = p?.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["orgId"] = org?.metadata?.accountId;
  jsonData.metadata["groupId"] = group?.metadata?.groupId;
  jsonData.metadata["locId"] = loc?.metadata?.accountId;
  return jsonData;
};
