import * as React from "react";
import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import { colWidthSign, SearchBoxStandard } from "../common/ConstantsAndValues";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";

import { PCListRow } from "./PCListUSRRow";
import { PCListHead } from "./PCListUSRHead";
import log from "../../services/log";
import { buildPcareQuery } from "../dbcq/pcaredb";
import { initPcareReglist } from "../../redux/pcare/pcare.actions";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export function PCListUSR(props) {
  const { searchText, userType } = props;

  const user = useSelector((state) => state?.auth?.user?.user);
  const searchcontext = useSelector((state) => state?.user?.searchtext);
  const pclist = useSelector((state) => state?.pcare?.pcarelist);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const resetpage = { ...pagedata, page: 0 };
    const jsonData = buildPcareQuery(resetpage, searchcontext, user);
    dispatch(initPcareReglist([]));
  }, [searchText, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    const newlist = keyword
      ? pclist?.filter((v) =>
          v._source.name.toLowerCase().startsWith(keyword.toLowerCase())
        )
      : pclist;
    setRows(newlist);
  };

  const handleChangePage = (event, value) => {
    const newpagedata = { ...pagedata, page: parseInt(value) };
  };

  const handleChangeRows = (event, value) => {
    //pagination part
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  // log.info("ctlist", rows);

  return (
    <Grid container>
      <Grid item xs>
        <div
          className="flex-grow-1"
          style={{
            display: "flex",
            justifyContent: "space-between",
            lineHeight: "36px",
            width: "100%",
          }}
        >
          <div>
            <div className="colfl0" />
            <div className="colfr0" width="100%">
              <SearchBoxStandard
                onChange={handleFilter}
                placeholder="Search"
                sx={{ marginRight: 1 }}
                className="text-color-primary text-color-2-border text-color-2-bg"
              />
            </div>
          </div>
        </div>
        <div style={{ width: "100%", marginTop: "16px" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="collapsible table">
              <TableHead>
                <PCListHead />
              </TableHead>

              <TableBody className="text-color-primary text text-md">
                {pclist &&
                  pclist.map((row) => (
                    <PCListRow key={row._source.Id} row={row} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div style={{ width: "100%" }}>
          <TablePaginationStandard
            handleChangePage={handleChangePage}
            handleChangeRows={handleChangeRows}
            pagedata={pagedata}
          />
        </div>
      </Grid>
    </Grid>
  );
}
