import * as React from "react";
import { Grid } from "@mui/material";
import RepoListLABOMP from "./RepoListLABOMP";

export default function ImgyListLAB() {
  return (
    <Grid container>
      <RepoListLABOMP />
    </Grid>
  );
}

{
  /*  

abandoned
import React from "react";
import ReactDOM from "react-dom";

import PropTypes                                                  from "prop-types";
import { makeStyles }                                             from "@material-ui/core/styles";
import {Grid, Box, Typography, Tabs, Tab, AppBar }                from "@material-ui/core";

import {Title}                                                    from "../../common/Title"; 
  
import InveListDone                                               from "./InveListDone";
import InveListReports                                            from "./InveListReports";

import {CTEST_REQUESTS}                                           from "../../../tests/CTEST_Investigations";



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: "100%",
       // backgroundColor: theme.palette.background.paper
    }
}));

export default function RepoListLAB() {

    const classes                                               = useStyles();
    const [value, setValue]                                     = React.useState(0);
    const [RowsDetail,      setRowsDetail]                      = React.useState([]);  //list of drugs in Rows
    const [RowsDetail2Bill, setRowsDetail2Bill]                 = React.useState([]);  //each drug
    const [RowsDetail2Cancel, setRowsDetail2Cancel]             = React.useState([]);  //each drug
    const [patient , setPatient]                                = React.useState('');
    const [doctor ,  setDoctor]                                 = React.useState('');
    const [err , setErr]                                        = React.useState('');

    //status ="" or "fresh" ,  status = "alloted" 2 "hold"  3 "COLLECT" 4 "tests" 5 "done"  

    const CTEST_REQUESTS_fresh                                  = CTEST_REQUESTS.filter(itm=> itm.status==="" || itm.status==="fresh");
    const CTEST_REQUESTS_hold                                   = CTEST_REQUESTS.filter(itm=> itm.status==="hold");
    const CTEST_REQUESTS_raw                                    = CTEST_REQUESTS.filter(itm=> itm.status==="" || itm.status==="fresh" || itm.status==="hold" || itm.status==="done");
    const CTEST_REQUESTS_done                                   = CTEST_REQUESTS.filter(itm=> itm.status==="done");

    const REQ_TAB                                               = 0
    const REPORTS_TAB                                           = 1

 
    const handleRowsDetail = (plist)=> {
        //setRowsDetail( plist );  //IMPORTANT !!!

        const [ patn, doc, error, newDet] = getPatient(plist, CTEST_REQUESTS_done);  //RowsDetail populates here

        if (patn) {
            setPatient(patn); 
            setDoctor(doc);
            //-----------------TODO ---------------------------//
            //    set 
            //    status = "alloted" 
            // in RowsDetail 
            //-----------------TODO ends ----------------------// 

            setRowsDetail(newDet);
            setValue(REPORTS_TAB);               //  proceed next

            setErr("");
        }else {
            setValue(REQ_TAB);                 // remain there itself
            setErr(error);
        }
        console.log(  patn, doc, error, plist, RowsDetail);
    }   




    function getPatient(plist, sourceData) {
        let err             = "No Reports to collect" ;
        let patn            = "" ;
        let doc             = "" ;
        let newDet          = [] ;
        
        const list2Process  =sourceData.filter(itm =>(itm.status==="done" ) && plist.indexOf(itm.id)!== -1);

        if (list2Process.length <=0) { 
            return [patn, doc, err, newDet] ;
        }
        patn                = list2Process[0].patient;
        doc                 = list2Process[0].doctor;
        err                 = "";
        const otherPatnList = list2Process.filter(itm =>itm.patient !==patn);

        if (otherPatnList.length > 0) {
            patn            = "" ;
            doc             = "" ;
            err             = "Clinical Tests of one Patient allowed";
            newDet          = [] ;
        }else {
            newDet          = [];

            for(let i=0; i<list2Process.length; i++) {
              newDet = newDet.concat(list2Process[i].investigation);       //newDet.push(list2Process[i].investigation);
            }
        }   
     
        return [patn, doc, err, newDet] ;
    };



    const handlePayRows =(plist, holdlist, amt, amtwtax) =>{
        setRowsDetail2Bill( plist );

        if (holdlist.length ===0 ) {
            //-----------------TODO ---------------------------//
            //    set 
            //    status = "done" 
            //    
            //-----------------TODO ends ----------------------// 
        }else {
            //pending how to deal part Rows
            //PART HOLD data=== holdlist

        }

        //setValue();  END


        setRowsDetail([])

    };







    function handleChange(event, newValue) {
        setValue(newValue);
    }

    const handlePaymentClose = (status) =>{
        if (status) {
            setRowsDetail2Bill([]);
            setRowsDetail([]);
            setPatient("");
        }
    };
 

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label=""
                    //forceRenderTabPanel={true}
                >

                    <Tab label="Requests" {...a11yProps(REQ_TAB)} />   
                    <Tab label="Reports"   {...a11yProps(REPORTS_TAB)} />
                    
                </Tabs>
            </AppBar>


            <TabPanel value={value} index={REQ_TAB}>

                <InveListDone 
                    handleRowsDetail      = {handleRowsDetail} 
                    rawdata               = {CTEST_REQUESTS_raw} 
                    data                  = {CTEST_REQUESTS_done} 
                    filterTitle           = {"Reports"}
                    err                   = {err}
                />   

            </TabPanel>

            <TabPanel value={value} index={REPORTS_TAB}>

                <InveListReports 
                    handlePayRows         = {handlePayRows} 
                    //handleHoldRows        = {handleHoldRows} 
                    data                  = {RowsDetail}       
                    patient               = {patient}
                    doctor                = {doctor}
                    err                   = {err}
                />

            </TabPanel>


        </div>
    );
}


*/
}
