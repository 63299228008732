import axios from "axios";
import { API_URL } from "./Constants";
import { axiosApiInstance } from "./axiosmiddleware";

const postMessageService = (payload) => {
  return axiosApiInstance.post(API_URL + "messages/set", payload);
};

const postMessageListService = (payload) => {
  return axiosApiInstance.post(API_URL + "messagelist/set", payload);
};

const getMessageService = (payload) => {
  return axiosApiInstance.post(API_URL + "messages/get", payload);
};

const getMessagesService = (payload) => {
  return axiosApiInstance.post(API_URL + "messages/list", payload);
};

const getMessageListService = (payload) => {
  return axios.post(API_URL + "messagelist/list", payload);
};

const getClinicNotesService = (payload) => {
  return axiosApiInstance.post(API_URL + "clinicnotes/list", payload);
};

const postClinicNoteService = (payload) => {
  return axiosApiInstance.post(API_URL + "clinicnotes/set", payload);
};

export {
  getMessageService,
  getMessagesService,
  getMessageListService,
  postMessageService,
  postMessageListService,
  postClinicNoteService,
  getClinicNotesService,
};
