import React, { useEffect, useState } from "react";

export const BluePencilIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4101 7.27174L15.8892 5.65755L18.7034 8.23617L17.2243 9.85036L14.4101 7.27174ZM13.059 8.74631L6.01296 16.4359L6.13323 19.1895L8.77248 19.0742L15.8731 11.3249L13.059 8.74631ZM20.8443 8.86007C20.9688 8.7242 21.0487 8.56324 21.0848 8.3952C21.1633 8.05542 21.0615 7.68428 20.786 7.43188L16.5169 3.52005C16.3832 3.39753 16.2252 3.31822 16.06 3.28138C15.7166 3.19695 15.3389 3.29772 15.0831 3.57686L4.27386 15.3735C4.13964 15.52 4.05727 15.6956 4.02579 15.8779C4.00422 15.9671 3.99466 16.0608 3.99887 16.157L4.17341 20.1532C4.17744 20.2455 4.19387 20.3344 4.22101 20.4182C4.31193 20.8987 4.74424 21.252 5.2471 21.2301L9.24329 21.0555C9.47833 21.0453 9.69087 20.9547 9.85557 20.8114C9.91998 20.768 9.98033 20.7165 10.0351 20.6567L20.8443 8.86007Z"
        fill="#2A8AA7"
      />
    </svg>
  );
};
