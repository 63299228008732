import { PolicyActionTypes } from "./policy.types";

const INITIAL_STATE = {
  policylist: null,
};

const policyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PolicyActionTypes.POLICY_LIST:
      return {
        ...state,
        policylist: action.payload,
      };
    case PolicyActionTypes.POLICYSUBS_LIST:
      return {
        ...state,
        subslist: action.payload,
      };
    default:
      return state;
  }
};

export default policyReducer;
