import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  ActivityRoleOptions,
  signContainer,
} from "../common/ConstantsAndValues";
import { SelectOptions } from "../common/SelectOptions";

function InputActivityRole(props) {
  const { label, handleChange } = props;

  function handleSelect(event) {
    console.log("activity role", event.value);
    handleChange(event?.value ?? "metauser");
  }

  return (
    <SelectOptions
      openPage={handleSelect}
      data={ActivityRoleOptions}
      multi={false}
      title={label}
      wide={signContainer.width}
    />
  );
}

export default InputActivityRole;
