import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useStyles from "../CustomComponents/useStyles";
import {
  getLabsOrderList,
  selectLabsOrderListRow,
} from "../../redux/labs/labs.actions";
import log from "../../services/log";
import { Grid } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { DiaglabListHeadUSR } from "./DiaglabListHeadUSR";
import { DiaglabListRowUSR } from "./DiaglabListRowUSR";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import * as React from "react";

export function DiaglabListUSR(props) {
  const user = useSelector((state) => state?.auth?.user?.user);
  const selectedPatient = useSelector((state) => state.user.selectedPatient);
  const labslist = useSelector((state) => state.labs.labsorderlist);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [labs, setLabs] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  const buildQuery = () => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata["patientId"] = user?.metadata.patientId;
    return jsonData;
  };

  useEffect(() => {
    if (selectedPatient) {
      dispatch(getLabsOrderList(buildQuery())).then((r) => {
        log.info("ordered labs", r);
        if (r) {
          setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
        }
      });
    }
  }, [user]);

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    dispatch(getLabsOrderList(buildQuery())).then((r) => {
      log.info("labslist", r);
      if (r) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
    setPage(newpagedata);
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };
  const handleRowClick = (e, selectrow) => {
    log.info("labslist", e);
    if (selectrow) {
      setCheckedRows(e);
      dispatch(selectLabsOrderListRow(e));
    } else {
      setCheckedRows(null);
      dispatch(selectLabsOrderListRow(null));
    }
  };

  log.info("labslist", labslist);

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead className="">
              <DiaglabListHeadUSR />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {labslist &&
                labslist?.results.map((row) => (
                  <DiaglabListRowUSR
                    key={row.id}
                    row={row}
                    isRowSelected={checkedRows}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
