import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Dropdown, AboutContent, withModal } from "../../../ui";
//
import { UserPreferences } from "./../UserPreferences";
import OHIFLogo from "../OHIFLogo/OHIFLogo.js";
import "./Header.css";
import { Title } from "../../../../common/Title";

function Header(props) {
  const {
    t,
    user,
    userManager,
    modal: { show },
    useLargeLogo,
    linkPath,
    linkText,
    location,
    children,
  } = props;

  const [options, setOptions] = useState([]);
  const hasLink = linkText && linkPath;

  const aboutHandler = () => {
    show({
      content: AboutContent,
      title: t("OHIF Viewer - About"),
    });
  };

  const preferenceHandler = () => {
    show({
      content: UserPreferences,
      title: t("User Preferences"),
    });
  };

  useEffect(() => {
    const optionsValue = [
      {
        title: t("About"),
        icon: { name: "info" },
        link: "#",
        onClick: aboutHandler,
      },
      {
        title: t("Preferences"),
        icon: { name: "user" },
        link: "#",
        onClick: preferenceHandler,
      },
    ];

    if (user && userManager) {
      optionsValue.push({
        title: t("Logout"),
        icon: { name: "power-off" },
        onClick: () => userManager.signoutRedirect(),
      });
    }

    setOptions(optionsValue);
  }, [setOptions, show, t, user, userManager]);

  console.log(options);

  return (
    <>
      {/*<Title>{'INVESTIGATIONAL IMAGES'}</Title>*/}
      <div
        bgcolor="red"
        className={classNames("entry-header", { "header-big": useLargeLogo })}
      >
        <div className="header-left-box text-color ">
          {location && location.studyLink && (
            <Link
              to={location.studyLink}
              className="header-btn header-viewerLink text-color"
            >
              {t("Back to Viewer")}
            </Link>
          )}

          {children}

          {hasLink && (
            <Link
              className="btn btn-icon border-db btn-main text-vsm header-btn header-studyListLinkSection"
              to={{
                pathname: linkPath,
                state: { studyLink: location.pathname },
              }}
            >
              {t(linkText)}
            </Link>
          )}
        </div>

        <div className="header-menu">
          <span className="research-use">{t("List of Studies")}</span>
          <Dropdown title={t("Options")} list={options} align="right" />
        </div>
      </div>
    </>
  );
}

Header.propTypes = {
  // Study list, /
  linkText: PropTypes.string,
  linkPath: PropTypes.string,
  useLargeLogo: PropTypes.bool,
  //
  // location: PropTypes.object.isRequired,
  location: PropTypes.object,
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
  userManager: PropTypes.object,
  user: PropTypes.object,
  modal: PropTypes.object,
};

Header.defaultProps = {
  useLargeLogo: false,
  children: OHIFLogo(),
};

export default withTranslation(["Header", "AboutModal"])(withModal(Header));
