import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Stepper, Step, StepButton, StepContent } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { mdspecialityquery } from "../utils/elasticqueries";

import { getMDSpeciality } from "../../redux/loinc/loinc.actions";
import {
  getMDGroupList,
  getMDUser,
  setMDGroup,
} from "../../redux/user/user.actions";

import InputComponent from "../CustomComponents/InputComponent";
import InputSpecialistStatusRole from "../CustomComponents/InputSpecialistStatusRole";
import InputSpecializationList from "../CustomComponents/InputSpecializationList";
import { specialistSchema } from "../schema/specialistSchema";

import log from "../../services/log";
import { MyButtons, MyTooltipButtons } from "../CustomComponents/MyButtons";

export default function SpecialistStepper(props) {
  const { handleDone } = props;
  const mdspecialities = useSelector((state) => state?.loinc?.mdspecialities);
  const user = useSelector((state) => state?.auth?.user?.user);
  const org = useSelector((state) => state?.orgs?.selectorg);
  const group = useSelector((state) => state?.orgs?.selectorggroup);
  const department = useSelector((state) => state?.orgs?.selectdepartment);
  const mduser = useSelector(
    (state) => state?.user?.puser && state?.user?.puser[0]
  ); //SD changed this
  const location = useSelector((state) => state?.addresses?.selectaddress);

  const steps = [
    {
      label: "Specialist lookup",
      description:
        "Enter the registered email of specialist for lookup: the specialist pre-registeration with Metalane is required.",
    },
    {
      label: "Specialist information",
      description: "Verify the information retrieved from Metalane system",
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState([]);
  const [specialistmail, setSpecialistmail] = useState(null);

  const dispatch = useDispatch();
  const specialistform = useFormik({
    initialValues: {
      orgId: "",
      orgName: "",
      groupId: "",
      departmentId: department?.metadata?.accountId,
      providerId: "",
      providerEmail: null,
      providerRole: null,
      name: "",
      specialization: [],
      status: "active",
    },
    validationSchema: specialistSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("clinicaltestinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["orgId"] = values.orgId;
      jsonData.metadata["groupId"] = values.groupId;
      jsonData.metadata["departmentId"] = values.departmentId;
      jsonData.metadata["providerId"] = values.providerId;
      jsonData.metadata["providerEmail"] = values.providerEmail;
      jsonData.metadata["providerRole"] = values.providerRole;
      jsonData["orgName"] = values.orgName;
      jsonData["name"] = values.name;
      jsonData["specialization"] = values.specialization;
      jsonData["status"] = values.status;

      log.info("specialist input", jsonData);

      const jsonVal = {};
      jsonVal["metadata"] = {};
      jsonVal.metadata["departmentId"] = values.departmentId;
      jsonVal.metadata["providerEmail"] = values.providerEmail;

      dispatch(getMDGroupList(jsonVal)).then((r) => {
        // no record then add
        log.info("specialist stepper", r);
        if (r.results.length === 0) {
          dispatch(setMDGroup(jsonData));
        }
      });

      // dispatch(setMDGroup(jsonData));
      handleDone();
    },
    onReset: (values) => {},
  });

  useEffect(() => {
    specialistform.setFieldValue("orgId", group?.metadata?.orgId);
    specialistform.setFieldValue("groupId", group?.metadata?.accountId);
  }, [group]);

  useEffect(() => {
    specialistform.setFieldValue("orgName", org?.orgName);
  }, [org]);

  useEffect(() => {
    specialistform.setFieldValue("providerId", mduser?.metadata?.accountId);
    specialistform.setFieldValue("providerRole", mduser?.metadata?.role);
    specialistform.setFieldValue("providerEmail", mduser?.metadata?.email);
    specialistform.setFieldValue("name", mduser?.name);
  }, [mduser]);

  useEffect(() => {
    dispatch(getMDSpeciality(mdspecialityquery()));
  }, []);

  const buildQuery = (inemail) => {
    if (department) {
      return {
        metadata: {
          email: inemail,
        },
      };
    }
  };

  const totalSteps = () => {
    return steps?.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    // log.info("addressstepper", activeStep)
    if (activeStep == 0) {
      const query = buildQuery(specialistmail);
      log.info("addressstepper query", query);
      dispatch(getMDUser(query));
    }

    if (activeStep == 1) {
      specialistform.submitForm();
    }

    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleEmailChange = (event) => {
    log.info("email changed", event.target.value);
    setSpecialistmail(event.target.value);
  };

  const handleSpecialization = (data) => {
    // log.info("specialist change add", data);
    const newdata = [];
    data.forEach((d) => {
      newdata.push(d.label);
    });
    specialistform.setFieldValue("specialization", newdata);
  };

  log.info("specialist form error", specialistform.errors);

  return (
    <Box sx={{ width: "100%" }} mt={0} className="">
      <div className="colfr0">
        <MyTooltipButtons
          onClick={handleDone}
          btnKey={"cancel"}
          btnLabel={"Cancel"}
          enState={false}
        />
      </div>

      <Box className="colfr0" sx={{ maxWidth: 200 }}>
        <Stepper orientation="vertical" nonLinear activeStep={activeStep}>
          {steps.map((s, index) => (
            <Step key={s.label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {s.label}
              </StepButton>
              <StepContent>
                <Typography>{s.description}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>

      <div sx={{ marginTop: "0px" }} className="text-color-primary ">
        {allStepsCompleted() ? (
          <React.Fragment>
            <center>
              <Typography
                sx={{ padding: "32px 0px 16px 0px" }}
                className="text-color-primary"
              >
                All steps completed - you&apos;re done
              </Typography>
              <Typography className="text-color-dark">
                To proceed click on DONE
              </Typography>
              <Typography
                sx={{ padding: "0px 0px 16px 0px" }}
                className="text-color-gray"
              >
                <i>or</i> RESET <i>to repeat steps all over again</i>
              </Typography>
            </center>
            <Box
              sx={{ display: "flex", flexDirection: "row", pt: 2 }}
              className="border-dbt"
            >
              <Button
                onClick={handleDone}
                sx={{ margin: "0px 16px 0px 10px" }}
                className="btn-sm border-db btn-icon btn-round text-color cursor-pointer"
              >
                Done
              </Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography
              sx={{ padding: "0px 0px 8px 0px" }}
              className="text-color-primary-highlight"
            >
              Step {activeStep + 1}
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column" },
                justifyContent: "center",
                alignItems: "top",
                height: "100%",
                width: "100%",
                margin: "0px 16px 16px 0px",
              }}
              className="clear"
            >
              {activeStep == 0 && (
                <Box>
                  <InputComponent
                    id="email"
                    name="email"
                    label="Provide registered email"
                    // errors       = {specialistform.errors.email? true : false}
                    // errorText    = {specialistform.errors.email}
                    variant="standard"
                    handleChange={handleEmailChange}
                  />
                </Box>
              )}
              {activeStep == 1 && (
                <Box>
                  <InputComponent
                    id="name"
                    name="name"
                    label="Name"
                    errorText={specialistform.errors.name}
                    defaultValue={specialistform.values.name}
                    errors={specialistform.errors.name ? true : false}
                    variant="standard"
                    handleChange={specialistform.handleChange}
                  />
                  <InputSpecializationList
                    id="specialization"
                    name="specialization"
                    label="Specialization"
                    errorText={specialistform.errors.specialization}
                    defaultValue={specialistform.values.specialization}
                    errors={specialistform.errors.specialization ? true : false}
                    variant="standard"
                    handleChange={handleSpecialization}
                  />
                  &nbsp;
                  <InputComponent
                    id="providerEmail"
                    name="providerEmail"
                    label="Email"
                    errorText={specialistform.errors.providerEmail}
                    defaultValue={specialistform.values.providerEmail}
                    errors={specialistform.errors.providerEmail ? true : false}
                    variant="standard"
                    handleChange={specialistform.handleChange}
                  />
                  &nbsp;
                  <InputSpecialistStatusRole
                    id="status"
                    name="status"
                    label="Status"
                    values={specialistform.values.status}
                    errors={specialistform.errors.status}
                    handleChange={specialistform.handleChange}
                  />
                </Box>
              )}
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "row", pt: 2 }}
              className=""
            >
              <MyButtons
                onClick={handleBack}
                btnKey={"back"}
                btnLabel={"Back"}
                enState={activeStep === 0}
              />
              &nbsp;&nbsp;
              <MyButtons
                onClick={handleReset}
                btnKey={"reset"}
                btnLabel={"Reset"}
                enState={false}
              />
              <Box sx={{ flex: "1 1 auto" }} />
              {/*  ((specialistform.values && specialistform.values.providerEmail) || (activeStep == 0 )) */}
              <MyButtons
                onClick={handleNext}
                btnKey={"next"}
                btnLabel={"Next"}
                enState={false}
              />
              &nbsp;&nbsp;
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                    className="text-color-primary-highlight my10"
                  >
                    Step {activeStep + 1} Completed
                  </Typography>
                ) : (
                  <MyButtons
                    onClick={handleComplete}
                    btnKey={
                      completedSteps() === totalSteps() - 1
                        ? "add"
                        : "completestep"
                    }
                    btnLabel={
                      completedSteps() === totalSteps() - 1
                        ? "Add"
                        : "Complete Step"
                    }
                    enState={false}
                  />
                ))}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
