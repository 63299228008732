import { useDispatch } from "react-redux";
import { Box, Grid } from "@material-ui/core";
import { colChart } from "../common/ConstantsAndValues";
import { Title } from "../common/Title";
import TextField from "@mui/material/TextField";
import { MyFullButtons } from "../CustomComponents/MyButtons";
import React from "react";

export const AppointmentInputFormMD = (props) => {
  const { inputform, disableContinue } = props;

  const dispatch = useDispatch();

  const handleDone = () => {
    disableContinue(false);
  };

  // log.info("calinputform", appointmentForm.errors);

  return (
    <Grid
      item
      xs
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ with: colChart.width }}>
        <Box
          mb={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Title>{"Event information "}</Title>
          </Box>
        </Box>

        <Box className="text-color">
          {inputform?.errors?.eventType && (
            <div className="text-color-error">
              {inputform?.errors?.eventType}
            </div>
          )}
          <TextField
            autoFocus
            margin="dense"
            id="eventTitle"
            name="eventTitle"
            label="Title"
            error={inputform?.errors?.eventTitle ? true : false}
            defaultValue={inputform?.values?.eventTitle}
            fullWidth
            variant="standard"
            // onChange={appointmentForm.handleChange}
          />
          &nbsp;
          <TextField
            autoFocus
            margin="dense"
            id="eventDescp"
            name="eventDescp"
            label="Description"
            defaultValue={inputform?.values?.eventDescp}
            error={inputform?.errors?.eventDescp ? true : false}
            fullWidth
            variant="standard"
            onChange={inputform?.handleChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="providername"
            name="providername"
            label="Provider"
            defaultValue={inputform?.values?.providerName}
            error={inputform?.errors?.providerName ? true : false}
            fullWidth
            variant="standard"
            // onChange={appointmentForm.handleChange}
          />
          &nbsp;
          <TextField
            autoFocus
            margin="dense"
            id="address"
            name="address"
            label="Location"
            defaultValue={inputform?.values?.address}
            error={inputform?.errors?.address ? true : false}
            fullWidth
            variant="standard"
            // onChange={appointmentForm.handleChange}
          />
        </Box>
        <Box>
          <MyFullButtons
            onClick={handleDone}
            btnKey={"add"}
            btnLabel={"Add"}
            enState={false}
          />
        </Box>
      </Box>
    </Grid>
  );
};
