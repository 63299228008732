import log from "../../services/log";

export const labsQueries = (field, value) => {
  let jsonData = {};
  let wildcard = {};
  let wildcard2 = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "consumernames";
  jsonData.params["size"] = 250;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool = {};
  jsonData.params.query.bool["must"] = [];
  wildcard[field] = value;
  jsonData.params.query.bool.must.push({ wildcard: wildcard });
  return jsonData;
};

export const labsMetaQueries = (field, value, pagedata) => {
  let jsonData = {};
  let wildcard = {};
  let wildcard2 = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "labsmap";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page;
  jsonData.params["query"] = {};
  jsonData.params.query["match_phrase_prefix"] = {};
  jsonData.params.query.match_phrase_prefix = {};
  jsonData.params.query.match_phrase_prefix[field] = {};
  jsonData.params.query.match_phrase_prefix[field]["query"] = value;
  return jsonData;
};

export const radlabMetaQueries = (field, value, pagedata) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "radlabs";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page;
  jsonData.params["query"] = {};
  jsonData.params.query["match_phrase_prefix"] = {};
  jsonData.params.query.match_phrase_prefix = {};
  jsonData.params.query.match_phrase_prefix[field] = {};
  jsonData.params.query.match_phrase_prefix[field]["query"] = value;
  return jsonData;
};

export const labsautocompquery = (field = "common_name", value = "") => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "laborders";
  jsonData.params["size"] = 250;
  jsonData.params["query"] = {};
  jsonData.params.query["match_phrase_prefix"] = {};
  jsonData.params.query.match_phrase_prefix = {};
  jsonData.params.query.match_phrase_prefix[field] = {};
  jsonData.params.query.match_phrase_prefix[field]["query"] = value;
  return jsonData;
};

export const radlabsMetaQueries = (field, value, pagedata) => {
  let jsonData = {};
  let wildcard = {};
  let wildcard2 = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "radlabs";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page + 1;
  jsonData.params["query"] = {};
  jsonData.params.query["match_phrase_prefix"] = {};
  jsonData.params.query.match_phrase_prefix = {};
  jsonData.params.query.match_phrase_prefix[field] = {};
  jsonData.params.query.match_phrase_prefix[field]["query"] = value;
  return jsonData;
};

export const compsystemListQueries = (field, value, pagedata) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "compsystem";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page;
  jsonData.params["query"] = {};
  jsonData.params.query["match_phrase_prefix"] = {};
  jsonData.params.query.match_phrase_prefix = {};
  jsonData.params.query.match_phrase_prefix[field] = {};
  jsonData.params.query.match_phrase_prefix[field]["query"] = value;
  return jsonData;
};

export const labComponentQueries = (field, value) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "loincdb";
  jsonData.params["size"] = 1;
  jsonData.params["from"] = 0;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool["must"] = {};
  jsonData.params.query.bool.must["match"] = {};
  jsonData.params.query.bool.must.match[field] = value;
  return jsonData;
};

export const panelParentQueries = (field, value, pagedata) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "panels";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page;
  jsonData.params["query"] = {};
  jsonData.params["collapse"] = {};
  jsonData.params.collapse["field"] = "parentId";
  jsonData.params.query["match_phrase_prefix"] = {};
  jsonData.params.query.match_phrase_prefix = {};
  jsonData.params.query.match_phrase_prefix[field] = {};
  jsonData.params.query.match_phrase_prefix[field]["query"] = value;
  return jsonData;
};

export const panelTestQueries = (field, value, pagedata) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "panels";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page;
  jsonData.params["query"] = {};
  jsonData.params.query["match"] = {};
  jsonData.params.query.match = {};
  jsonData.params.query.match[field] = value;
  return jsonData;
};
