import * as yup from "yup";

export const clinicalNotesSchema = yup.object({
  id: yup.string(),
  threadId: yup.string(),
  orgId: yup.string().required("org is required"),
  groupId: yup.string().required("org group is required"),
  departmentId: yup.string().required("org department is required"),
  providerId: yup.string().required("provider is required"),
  role: yup.string().required("provider role is required"),
  patientName: yup.string().required("org name is required"),
  doctorName: yup.string().required("provider is required"),
  msg: yup.string(),
  msgSubject: yup.string(),
  msgBody: yup.string(),
  seqno: yup.number(),
});
