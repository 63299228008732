import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesSign } from "../styles/InvoiceStyles";

const styles = stylesSign;

export const InvoiceSign = ({ txtDeclare, txt }) => (
  <>
    <View style={styles.declareContainer}>
      <Text style={styles.declare}>{txtDeclare}</Text>
    </View>
    <View style={styles.signContainer}>
      <Text style={styles.sign}>{txt}</Text>
    </View>
  </>
);
