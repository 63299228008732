import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";

import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../CustomComponents/useStyles";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";

import AccListHead from "./AccListPHAHead";
import AccListRow from "./AccListPHARow";

import log from "../../services/log";

export default function AccListPHA(props) {
  const { billlist } = props;

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState(null);
  const [bills, setBills] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    log.info("billlist", billlist && billlist.results);
    if (billlist) {
      setBills(billlist.results);
      setPage({
        ...pagedata,
        pages: billlist.totalPages ? billlist.totalPages : 0,
        count: billlist.totalResults ? billlist.totalResults : 0,
      });
    }
  }, [billlist]);

  const handleChangePage = (event, value) => {
    log.info("patient paydues", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    //log.info("patient paydues", drugsquery("name", query.name, newpagedata));
    //dispatch(getDrugs(drugsquery("name", query.name, newpagedata))).then((r) => {
    // log.info("drugslist", r)
    // if (r.total.value && r.total.value > 0) {
    //     setPage({... pagedata,
    //         count: r.total.value,
    //         pages: r.total.value/pagedata.limit,
    //     });
    // }
    //});
    setPage(newpagedata);
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (e, selectrow) => {
    log.info("billlist", e);
    if (selectrow) {
      setCheckedRows(e);
      // dispatch(selectPatientMedcine(e));
    } else {
      setCheckedRows(null);
      // dispatch(selectPatientMedcine(null));
    }
  };

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead>
              <AccListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {bills &&
                bills.map((row) => (
                  <AccListRow
                    key={row.id}
                    row={row}
                    isRowSelected={checkedRows}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
