import React, { useState, useEffect, useLayoutEffect } from "react";
import { Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useSelector, useDispatch } from "react-redux";
import log from "../../services/log";

export function SelectOrgLocation() {
  const deptaddress = useSelector((state) => state.addresses.selectaddress);
  const dispatch = useDispatch();
  const addr =
    deptaddress && deptaddress.formatted_address
      ? deptaddress.formatted_address
      : "";

  return (
    <Box mt={1} sx={{ maxWidth: 400 }}>
      <InputLabel
        sx={{ minWidth: 20 }}
        variant="standard"
        htmlFor="uncontrolled-native"
      >
        {addr}
      </InputLabel>
    </Box>
  );
}
