import React, { useEffect, useState } from "react";

const AppointmentIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
    >
      <rect x="0.992188" y="18.7188" width="19.6356" height="2" rx="1" />
      <rect x="0.992188" y="6.71875" width="19.6356" height="2" rx="1" />
      <rect x="0.992188" y="3.04688" width="19.6356" height="2" rx="1" />
      <rect
        x="2.8125"
        y="3.04688"
        width="17.6758"
        height="2"
        rx="1"
        transform="rotate(90 2.8125 3.04688)"
      />
      <rect
        x="7.21875"
        y="0.984375"
        width="5.15332"
        height="2"
        rx="1"
        transform="rotate(90 7.21875 0.984375)"
      />
      <rect
        x="16.2266"
        y="0.984375"
        width="5.15332"
        height="2"
        rx="1"
        transform="rotate(90 16.2266 0.984375)"
      />
      <rect
        x="20.6328"
        y="3.04688"
        width="17.6758"
        height="2"
        rx="1"
        transform="rotate(90 20.6328 3.04688)"
      />
      <path d="M7.01654 13.796C7.30944 13.5031 7.78431 13.5031 8.07721 13.796L9.64158 15.3603C9.93448 15.6532 9.93448 16.1281 9.64158 16.421C9.34869 16.7139 8.87382 16.7139 8.58092 16.421L7.01655 14.8566C6.72365 14.5637 6.72365 14.0888 7.01654 13.796Z" />
      <rect
        x="8.07031"
        y="15.8906"
        width="8.23769"
        height="1.5"
        rx="0.75"
        transform="rotate(-45 8.07031 15.8906)"
      />
    </svg>
  );
};
export default AppointmentIcon;
