import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
//import {GraphMenuContainer} from "../doctorDashboard.styles";

import { GraphMenuContainer } from "../../common/ConstantsAndValues";

function GraphNavigate() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand href="#">Test analysis </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            {/*<Nav.Link href="#action1">Home</Nav.Link>*/}
            {/*<Nav.Link href="#action2">Link</Nav.Link>*/}
            <NavDropdown title="Select graph" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Line</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Bar</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Area</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">Stacked Bar</NavDropdown.Item>
            </NavDropdown>
            {/*<Nav.Link href="#" disabled>*/}
            {/*    Link*/}
            {/*</Nav.Link>*/}
          </Nav>
          {/*<Form className="d-flex">*/}
          {/*    <Form.Control*/}
          {/*        type="search"*/}
          {/*        placeholder="Search test"*/}
          {/*        className="me-2 text-center"*/}
          {/*        aria-label="Search"*/}
          {/*    />*/}
          {/*    /!*<Button variant="outline-success">Test Search</Button>*!/*/}
          {/*</Form>*/}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default GraphNavigate;
