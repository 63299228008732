import "./App.css";
import { Routes, Route, Redirect } from "react-router-dom";

import log from "./services/log";
import Header from "./components/header/Header";
import Footer from "./components/common/Footer";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//---------------------------------LOGIN SYS -------------------------------------//
import { SignInPage } from "./pages/signinpage";
import { SignUpPage } from "./pages/signuppage";
import { SignInResetPage } from "./pages/signinResetPage";
import { SignInAskusPage } from "./pages/signinAskusPage";
import { SignInCodePage } from "./pages/signincode";
import { SignNewpassPage } from "./pages/signinnewpass";

import { MetaGraph } from "./components/charts/components/graph";

//----------------------------ADDITIONAL DO  FOR EACH GROUP--------------------------//
import MetauserDoThisNow from "./components/patient/components/DoThisNow";
import MetamdDoThisNow from "./components/doctor/components/DoThisNow";
import MetaorgDoThisNow from "./components/organization/components/DoThisNow";
import MetalabDoThisNow from "./components/laboratory/components/DoThisNow";
import MetaconDoThisNow from "./components/connect/components/DoThisNow";
import MetadminDoThisNow from "./components/admin/components/DoThisNow";

//-----------------------------COMMON  (1)metauser, (2)metamd, (3)metaorg, (4)metainsu, (5)metaothers -----------------------//

import MessageItemsList from "./components/messages/MessageItemsList";
import AlertItemsList from "./components/messages/AlertItemsList";

import { SettingsPassword } from "./components/settings/SettingsPassword";

//------------------------------METACAL-------------------------------------//
import {
  MetaCalendarADM_TODO,
  MetaCalendarADM_APPT,
  MetaCalendarUSR_TODO,
  MetaCalendarUSR_APPT,
  MetaCalendarMD_TODO,
  MetaCalendarMD_APPT,
  MetaCalendarORG_TODO,
  MetaCalendarORG_APPT,
  MetaCalendarLAB_TODO,
  MetaCalendarLAB_APPT,
  MetaCalendarPHA_TODO,
  MetaCalendarPHA_APPT,
  MetaCalendarFIN_TODO,
  MetaCalendarFIN_APPT,
  MetaCalendarOS_TODO,
  MetaCalendarOS_APPT,
  MetaCalendar,
} from "./components/appointment/MetaCalendar";

//----------------------------- NON ADMIN AREA -----------------------------//
import { HomePage } from "./pages/Homepage";

import { HomeWB, HomeSWB } from "./pages/PagesHome";

import Demo from "./pages/Demo";
import Webinars from "./pages/Webinars";
import Analytics from "./pages/Analytics";

import HospitalList from "./components/users/HospitalList";
import LaboratoryList from "./components/users/LaboratoryList";
import PharmacyList from "./components/users/PharmacyList";
import SpecialistList from "./components/users/SpecialistList";
import ClinicList from "./components/users/ClinicList";

import AboutUs from "./pages/DashBoardAboutUs";
import { About, Vision, Mission, Careers, Roadmap } from "./pages/about/About";

import Enquiry from "./pages/enquiry";

import Blog from "./pages/blog";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

import Speciality from "./pages/speciality";

import {
  AboutFooter,
  ServicesFooter,
  BusinessTerms,
  ResourcesFooter,
  ConnectFooter,
} from "./pages/PagesFooter";

//import  from './pages/';

//----------------------------- ADMIN AREA -----------------------------//
import AdminDashboard from "./components/admin/AdminDashboard";

import PatientDashboard from "./components/patient/PatientDashboard";
import {
  ApptUSR,
  CNotesUSR,
  ImgyUSR,
  MediUSR,
  PcareUSR,
  QnsUSR,
  ResoUSR,
  PayUSR,
  InsuUSR,
  SetUSR,
  RepoUSR,
  RadRepoUSR,
  HmoniUSR,
  ProfUSR,
  TodoUSR,
} from "./components/common/PagesPatients";

import AnalyticsUSR from "./components/patient/components/AnalyticsUSR";
import { ClinicalNotesUSR } from "./components/patient/components/ClinicalNotesUSR";
import { ReportsListUSR } from "./components/patient/components/ReportsListUSR";
import { RadReportsListUSR } from "./components/patient/components/RadReportsListUSR";
import { ProfileListUSR } from "./components/patient/components/ProfileListUSR";
import InsuInfoUSR from "./components/patient/components/InsuInfoUSR";
import InsuCompareUSR from "./components/patient/components/InsuCompareUSR";
import { HmoniListUSR } from "./components/patient/components/HmoniListUSR";
import { HmoniCondUSR } from "./components/patient/components/HmoniCondUSR";
import CreatePdfUSR from "./components/patient/components/CreatePdfUSR";
import CreatePdfPNUSR from "./components/patient/components/CreatePdfPNUSR";
import AddApptUSR from "./components/patient/components/AddApptUSR";
import { MedicineListUSR } from "./components/patient/components/MedicineListUSR";
import MedicineRequestListUSR from "./components/patient/components/MedicineRequestListUSR";
import PCareListUSR from "./components/patient/components/PCareListUSR";
import QnsListUSR from "./components/patient/components/QnsListUSR";
import PaymentDuesListUSR from "./components/patient/components/PaymentDuesListUSR";
import PaymentLedgerUSR from "./components/patient/components/PaymentLedgerUSR";
import ResoListUSR from "./components/patient/components/ResoListUSR";
import SettingsUSR from "./components/patient/components/SettingsUSR";
import UploadsUSR from "./components/patient/components/UploadsUSR";
import DoListUSR from "./components/patient/components/DoListUSR";

import OrganizationDashboard from "./components/organization/OrganizationDashboard";
import {
  ApptORG,
  AssoORG,
  DeptORG,
  PatnORG,
  FaciORG,
  InfoORG,
  MoniORG,
  AccORG,
  ProfORG,
  RepoORG,
  ResoORG,
  SuppORG,
  InsuORG,
  ClinicalTestsORG,
  RadiologyTestsORG,
  DrugORG,
  SetORG,
  SpecORG,
  TodoORG,
} from "./components/common/PagesOrganizations";
import AnalyticsORG from "./components/organization/components/AnalyticsORG";
import AccListORG from "./components/organization/components/AccListORG";
import AccLedgerORG from "./components/organization/components/AccLedgerORG";
import AccTodoORG from "./components/organization/components/AccTodoORG";
import InfoListORG from "./components/organization/components/InfoListORG";
import InfoManageORG from "./components/organization/components/InfoManageORG";
import MoniListORG from "./components/organization/components/MoniListORG";
import DeptListORG from "./components/organization/components/DeptListORG";
import PatientORG from "./components/organization/components/PatientORG";
import PatientListORG from "./components/organization/components/PatientListORG";
import PatientListManageORG from "./components/organization/components/PatientListManageORG";
import DeptManageORG from "./components/organization/components/DeptManageORG";
import SpecListORG from "./components/organization/components/SpecListORG";
import AssoListORG from "./components/organization/components/AssoListORG";
import AssoTodoORG from "./components/organization/components/AssoTodoORG";
import FaciListORG from "./components/organization/components/FaciListORG";
import FaciTodoORG from "./components/organization/components/FaciTodoORG";
import ResoListORG from "./components/organization/components/ResoListORG";
import ResoTodoORG from "./components/organization/components/ResoTodoORG";
import PrivacyORG from "./components/organization/components/PrivacyORG";
import SetProfileListORG from "./components/organization/components/SetProfileListORG";
import SettingsORG from "./components/organization/components/SettingsORG";
import UploadsInsuORG from "./components/organization/components/UploadsInsuORG";
import UploadsORG from "./components/organization/components/UploadsORG";
import CreatePdfORG from "./components/organization/components/CreatePdfORG";
import CreatePdfPNORG from "./components/organization/components/CreatePdfPNORG";
import InsuInfoORG from "./components/organization/components/InsuInfoORG";
import InsuManageORG from "./components/organization/components/InsuManageORG";
import DoListORG from "./components/organization/components/DoListORG";
import DrugsListTabsOrg from "./components/organization/components/DrugsListTabsOrg";
import DrugsTaxTabsOrg from "./components/organization/components/DrugsTaxTabsOrg";
import { ClinicalTestsListTabsORG } from "./components/organization/components/ClinicalTestsListTabsORG";
import { ClinicalTestsTaxORG } from "./components/organization/components/ClinicalTestsTaxORG";
import { RadiologyTestsListTabsORG } from "./components/organization/components/RadiologyTestsListTabsORG";
import { RadiologyTestsTaxORG } from "./components/organization/components/RadiologyTestsTaxORG";

import DoctorDashboard from "./components/doctor/doctorDashboard";
import {
  ApptMD,
  CNotesMD,
  ImgyMD,
  MediMD,
  PatMD,
  PayMD,
  PCareMD,
  ProfMD,
  QnsMD,
  ResoMD,
  RadioMD,
  HMoniMD,
  RepoMD,
  InsuMD,
  SetMD,
  TodoMD,
} from "./components/common/PagesDoctors";
import AnalyticsMD from "./components/doctor/components/AnalyticsMD";
import { ClinicalNotesMD } from "./components/doctor/components/ClinicalNotesMD";
import AddApptMD from "./components/doctor/components/AddApptMD"; //to change this
import UpdateApptMD from "./components/doctor/components/UpdateApptMD"; //to change this
import { HmoniListMD } from "./components/doctor/components/HmoniListMD";
import { HmoniCondMD } from "./components/doctor/components/HmoniCondMD";

import { ReportsListMD } from "./components/doctor/components/ReportsListMD";
import { ReportsTestsListTabsMD } from "./components/doctor/components/ReportsTestsListTabsMD";
import ReportsRequestsMD from "./components/doctor/components/ReportsRequestsMD";

import { RadiologyListMD } from "./components/doctor/components/RadiologyListMD";
import RadiologyRequestsMD from "./components/doctor/components/RadiologyRequestsMD";
import CreatePdfMD from "./components/doctor/components/CreatePdfMD";
import CreatePdfPNMD from "./components/doctor/components/CreatePdfPNMD";
import DicomRequestsMD from "./components/doctor/components/DicomRequestsMD";
import { MedicineListMD } from "./components/doctor/components/MedicineListMD";
import PayListMD from "./components/doctor/components/PayListMD";
import PaymentLedgerMD from "./components/doctor/components/PaymentLedgerMD";
import PCareListMD from "./components/doctor/components/PCareListMD";
import QnsListMD from "./components/doctor/components/QnsListMD";
import ResoListMD from "./components/doctor/components/ResoListMD";
import { PatientListMD } from "./components/doctor/components/PatientListMD";
import { PatientsPrescribe } from "./components/doctor/components/PatientsPrescribe";
import { PatientSelected } from "./components/doctor/components/PatientSelected";
import {
  getClientSession,
  updateClientSession,
  setClientSession,
} from "./redux/user/user.actions";

import SettingsMD from "./components/doctor/components/SettingsMD";
import UploadsMD from "./components/doctor/components/UploadsMD";
import DoListMD from "./components/doctor/components/DoListMD";
import InsuInfoMD from "./components/doctor/components/InsuInfoMD";
import { DrugSearch } from "./components/doctor/components/DrugSearch";
import { OtherTestsSearch } from "./components/doctor/components/OtherTestsSearch";

import LaboratoryDashboard from "./components/laboratory/LaboratoryDashboard";
import {
  ClinicalTestsLAB,
  RadiologyTestsLAB,
  ApptLAB,
  AccLAB,
  InfoLAB,
  ImgyLAB,
  MoniLAB,
  PatnLAB,
  ProfLAB,
  ProfGroupLAB,
  RepoLAB,
  ResoLAB,
  SetLAB,
} from "./components/common/PagesLaboratory";
import AnalyticsLAB from "./components/laboratory/components/AnalyticsLAB";
import AccLedgerLAB from "./components/laboratory/components/AccLedgerLAB";
import AccListLAB from "./components/laboratory/components/AccListLAB";
import ApptMonitorLAB from "./components/laboratory/components/ApptMonitorLAB";
import InfoListLAB from "./components/laboratory/components/InfoListLAB";
import InfoManageLAB from "./components/laboratory/components/InfoManageLAB";
import MoniListLAB from "./components/laboratory/components/MoniListLAB";
import PatientListLAB from "./components/laboratory/components/PatientListLAB";
import InsuInfoLAB from "./components/laboratory/components/InsuInfoLAB";
import RepoListLAB from "./components/laboratory/components/RepoListLAB";
import ImgyListLAB from "./components/laboratory/components/ImgyListLAB";
import ResoListLAB from "./components/laboratory/components/ResoListLAB";
import ResoTodoLAB from "./components/laboratory/components/ResoTodoLAB";
import PrivacyLAB from "./components/laboratory/components/PrivacyLAB";
import ProfileGroupListLAB from "./components/laboratory/components/ProfileGroupListLAB";
import { ProfileListLAB } from "./components/laboratory/components/ProfileListLAB";
import SetProfileListLAB from "./components/laboratory/components/SetProfileListLAB";
import SettingsLAB from "./components/laboratory/components/SettingsLAB";
import UploadsLAB from "./components/laboratory/components/UploadsLAB";
import UploadsReportsLAB from "./components/laboratory/components/UploadsReportsLAB";
import DoListLAB from "./components/laboratory/components/DoListLAB";
import { ClinicalTestsListTabsLAB } from "./components/laboratory/components/ClinicalTestsListTabsLAB";
import { ClinicalTestsTaxLAB } from "./components/laboratory/components/ClinicalTestsTaxLAB";

import { RadiologyTestsListTabsLAB } from "./components/laboratory/components/RadiologyTestsListTabsLAB";
import { RadiologyTestsTaxLAB } from "./components/laboratory/components/RadiologyTestsTaxLAB";

import PharmacyDashboard from "./components/pharmacy/PharmacyDashboard";
import {
  ApptPHA,
  AccPHA,
  ProfPHA,
  ProfGroupPHA,
  InfoPHA,
  PresPHA,
  PatnPHA,
  OrderPHA,
  DrugPHA,
  SupoPHA,
  SetPHA,
} from "./components/common/PagesPharmacy";
import AnalyticsPHA from "./components/pharmacy/components/AnalyticsPHA";
import AccLedgerPHA from "./components/pharmacy/components/AccLedgerPHA";
import AccListPHA from "./components/pharmacy/components/AccListPHA";
import InfoListPHA from "./components/pharmacy/components/InfoListPHA";
import InfoManagePHA from "./components/pharmacy/components/InfoManagePHA";
import PresListPHA from "./components/pharmacy/components/PresListPHA";
import PresMonitorPHA from "./components/pharmacy/components/PresMonitorPHA";
import PatientListPHA from "./components/pharmacy/components/PatientListPHA";
import InsuInfoPHA from "./components/pharmacy/components/InsuInfoPHA";
import OrderListPHA from "./components/pharmacy/components/OrderListPHA";
import SupoListPHA from "./components/pharmacy/components/SupoListPHA";
import PrivacyPHA from "./components/pharmacy/components/PrivacyPHA";
import ProfileGroupListPHA from "./components/pharmacy/components/ProfileGroupListPHA";
import { ProfileListPHA } from "./components/pharmacy/components/ProfileListPHA";
import SetProfileListPHA from "./components/pharmacy/components/SetProfileListPHA";
import SettingsPHA from "./components/pharmacy/components/SettingsPHA";
import UploadsPHA from "./components/pharmacy/components/UploadsPHA";
import DoListPHA from "./components/pharmacy/components/DoListPHA";
import DrugsListTabsPHA from "./components/pharmacy/components/DrugsListTabsPHA";
import DrugsTaxTabsPHA from "./components/pharmacy/components/DrugsTaxTabsPHA";

import FinanceDashboard from "./components/finance/FinanceDashboard";
import {
  ApptFIN,
  AssoFIN,
  FaciFIN,
  InfoFIN,
  MoniFIN,
  AccFIN,
  ProfFIN,
  RepoFIN,
  ResoFIN,
  SetFIN,
  SpecFIN,
} from "./components/common/PagesFinance";
import AnalyticsFIN from "./components/finance/components/AnalyticsFIN";
import AccLedgerFIN from "./components/finance/components/AccLedgerFIN";
import AccListFIN from "./components/finance/components/AccListFIN";
import InfoListFIN from "./components/finance/components/InfoListFIN";
import InfoManageFIN from "./components/finance/components/InfoManageFIN";
import MoniListFIN from "./components/finance/components/MoniListFIN";
import SpecListFIN from "./components/finance/components/SpecListFIN";
import AssoListFIN from "./components/finance/components/AssoListFIN";
import AssoTodoFIN from "./components/finance/components/AssoTodoFIN";
import FaciListFIN from "./components/finance/components/FaciListFIN";
import FaciTodoFIN from "./components/finance/components/FaciTodoFIN";
import ResoListFIN from "./components/finance/components/ResoListFIN";
import ResoTodoFIN from "./components/finance/components/ResoTodoFIN";
import PrivacyFIN from "./components/finance/components/PrivacyFIN";
import SettingsFIN from "./components/finance/components/SettingsFIN";
import UploadsFIN from "./components/finance/components/UploadsFIN";
import DoListFIN from "./components/finance/components/DoListFIN";

import OtherServiceDashboard from "./components/otherservice/OtherServiceDashboard";
import {
  ApptOS,
  AssoOS,
  FaciOS,
  InfoOS,
  MoniOS,
  AccOS,
  ProfOS,
  RepoOS,
  ResoOS,
  SetOS,
  SpecOS,
} from "./components/common/PagesOtherService";
import AnalyticsOS from "./components/otherservice/components/AnalyticsOS";
import AccLedgerOS from "./components/otherservice/components/AccLedgerOS";
import AccListOS from "./components/otherservice/components/AccListOS";
import InfoListOS from "./components/otherservice/components/InfoListOS";
import InfoManageOS from "./components/otherservice/components/InfoManageOS";
import MoniListOS from "./components/otherservice/components/MoniListOS";
import SpecListOS from "./components/otherservice/components/SpecListOS";
import AssoListOS from "./components/otherservice/components/AssoListOS";
import AssoTodoOS from "./components/otherservice/components/AssoTodoOS";
import FaciListOS from "./components/otherservice/components/FaciListOS";
import FaciTodoOS from "./components/otherservice/components/FaciTodoOS";
import ResoListOS from "./components/otherservice/components/ResoListOS";
import ResoTodoOS from "./components/otherservice/components/ResoTodoOS";
import PrivacyOS from "./components/otherservice/components/PrivacyOS";
import SettingsOS from "./components/otherservice/components/SettingsOS";
import UploadsOS from "./components/otherservice/components/UploadsOS";
import DoListOS from "./components/otherservice/components/DoListOS";

import ConnectDashboard from "./components/connect/ConnectDashboard";
import {
  ApptCON,
  CusCON,
  DeptCON,
  ClinicCON,
  DStoreCON,
  PathoCON,
  FaciCON,
  FbkCON,
  HcareCON,
  HelpCON,
  InfoCON,
  SpecCON,
} from "./components/common/PagesConnect";
import Connect from "./components/connect/components/Connect";
import Feedback from "./components/connect/components/Feedback";

import { ProfileAnalytics } from "./components/patient/components/ProfileAnalytics"; //??
import ProfileInput from "./components/forms/ProfileInput";
import LineAreaZoomChart from "./components/charts/d3bar/LineAreaZoomChart";
import { ChartContainer } from "./components/reports/ChartContainer";
import { RadChartContainer } from "./components/reports/RadChartContainer";

import { ProfileListMD } from "./components/doctor/components/ProfileListMD";
import Admin from "./components/admin/components/Admin";
import { SetProfileListMD } from "./components/doctor/components/SetProfileListMD";
import { PrivacyMD } from "./components/doctor/components/PrivacyMD";
import { DicomViewUSR } from "./components/dicom/DicomViewUSR";
import { DicomViewMD } from "./components/dicom/DicomViewMD";
import { DicomViewLAB } from "./components/dicom/DicomViewLAB";
import { DicomViewer } from "./components/dicom/DicomViewer";
import { DicomFileView } from "./components/dicom/DicomFileView";
import { DicomImageView } from "./components/dicom/DicomImageView";
import { sessionInfo } from "./components/common/DeviceInfo";
import OrgRegionSetting from "./components/organization/components/OrgRegionSetting";

import { ProviderSearch } from "./components/providers/ProviderSearch";
import { ProviderSearchHospital } from "./components/providers/ProviderSearchHospital";
import { ProviderSearchPathology } from "./components/providers/ProviderSearchPathology";
import { ProviderSearchDrugStore } from "./components/providers/ProviderSearchDrugStore";
import { ProviderSearchSpecialist } from "./components/providers/ProviderSearchSpecialist";
import { ProviderSearchClinic } from "./components/providers/ProviderSearchClinic";
import { SetProfileList } from "./components/patient/components/SetProfileList";
import { ClinicalTestsPrescribe } from "./components/doctor/components/ClinicalTestsPrescribe";
import { RadNewPresMD } from "./components/doctor/components/RadNewPresMD";
import LabsRequestsUSR from "./components/patient/components/LabsRequestUSR";
import { HomePageSoon } from "./components/home/HomePageSoon";
import {
  FindCare,
  Appointments,
  AuthorizeSharing,
  HealthInsurance,
  Immunizations,
  MedicalRecords,
  Medications,
  Messages,
  Payments,
  TestResults,
  DashboardHome,
  TestResultsDetails,
} from "./components/patient/components";
import AppointmentPDF from "./components/patient/components/AppointmentPDF";
import { useLocation } from "react-router-dom";
import FooterLanding from "./components/common/FooterLanding";

function App() {
  const authData = useSelector((state) => state.auth);
  const location = useLocation();
  // const dispatch = useDispatch();
  // const session = sessionInfo();
  //
  //
  // useEffect(() => {
  //   const addSession = () => {
  //     const data = {};
  //     data.metadata = {};
  //     data.metadata.sessionId = session.sessionId;
  //     data.sessionInfo = {};
  //     data.sessionInfo.deviceName = session.deviceName;
  //     data.sessionInfo.deviceVersion = session.deviceVersion;
  //     data.lastlogin = Date.now()
  //     dispatch(setClientSession(data));
  //   }
  //
  //   dispatch(getClientSession({
  //     metadata: {sessionId: session.sessionId}
  //   })).then(
  //       (r) => {
  //         // log.info("app session", r);
  //         r.length === 0 ? addSession() :
  //             dispatch(updateClientSession({
  //               id: r.id,
  //               lastlogin: Date.now()
  //             }));
  //       },
  //       (error) => {
  //         addSession()
  //       });
  //
  // }, [dispatch, session]);

  // const mua = MetaUrlAuth({url: HeadUrl});

  return (
    <div>
      {/* <div className="d-none d-md-block"> */}
      <Header auth={authData} />
      {/* </div> */}

      {/*
          home
         */}

      <Routes>
        {/*<Route path="/" element={<HomePageSoon />} />*/}
        <Route path="/" element={<HomePage auth={authData} />} />
        <Route path="sign" element={<SignInPage auth={authData} />} />
        <Route path="signup" element={<SignUpPage auth={authData} />} />
        <Route path="askus" element={<SignInAskusPage />} />
        <Route path="reset" element={<SignInResetPage />} />
        <Route path="signcode" element={<SignInCodePage />} />
        <Route path="newpass" element={<SignNewpassPage />} />
        <Route path="terms" element={<TermsOfUse />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        {/*<Route path="aboutus" element={<AboutUs />}>*/}
        {/*    <Route path="about" element={<About   />} />*/}
        {/*    <Route path="vision" element={<Vision  />} />*/}
        {/*    <Route path="mission" element={<Mission />} />*/}
        {/*    <Route path="careers" element={<Careers  />} />*/}
        {/*    <Route path="roadmap" element={<Roadmap />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}

        {/*<Route path="enquiry" element={<Enquiry />} />*/}

        {/*<Route path="serv" element={<ServicesFooter id={'sersv'} title={'Services'}/>} >*/}

        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="servblog" element={<ServicesFooter id={'servb'} title={'Blog'}/>} >*/}
        {/*    <Route path="metablog" element={<Blog />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="servinsu" element={<ServicesFooter id={'servi'} title={'Insurance'}/>} >*/}
        {/*    <Route path="policy" element={<InsuCompareUSR auth={""} />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}

        {/*<Route path="wbd" element={<HomeWB id={'wbd'} title={'Demo'}/>} >*/}
        {/*    <Route path="demo" element={<Demo />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="wbw" element={<HomeWB id={'wbw'} title={'Webinars'}/>} >*/}
        {/*    <Route path="webinars" element={<Webinars />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="wba" element={<HomeWB id={'wba'} title={'Analytics'}/>} >*/}
        {/*    <Route path="analytics" element={<Analytics/>} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="wbju" element={<HomeWB id={'wbju'} title={'Join us'}/>} >*/}

        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}

        {/*<Route path="swbh" element={<HomeSWB id={'swbhosp'} title={'Hospital'}/>} >*/}
        {/*    <Route path="hospital" element={<HospitalList      />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="swbl" element={<HomeSWB id={'swblab'} title={'Laboratory'}/>} >*/}
        {/*    <Route path="laboratory" element={<LaboratoryList   />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="swbp" element={<HomeSWB id={'swbpharma'} title={'Pharmacy'}/>} >*/}
        {/*    <Route path="pharmacy" element={<PharmacyList     />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="swbi" element={<HomeSWB id={'swbpol'} title={'Policy'}/>} >*/}
        {/*    <Route path="policy" element={<InsuCompareUSR auth={""} />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="swbs" element={<HomeSWB id={'swbsp'} title={'Specialists'}/>} >*/}
        {/*    <Route path="specialist" element={<SpecialistList   />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="swbc" element={<HomeSWB id={'swbc'} title={'Clinics'}/>} >*/}
        {/*    <Route path="clinics" element={<ClinicList       />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}

        {/*<Route path="speciality" element={<Speciality />} >*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}

        {/*<Route path="business" element={<BusinessTerms id={'bt'} title={'Terms'}/>} >*/}

        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="businessprivacy" element={<BusinessTerms id={'bpt'} title={'Privacy'}/>} >*/}

        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="businesstou" element={<BusinessTerms id={'btou'} title={'Terms of use'}/>} >*/}

        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}

        {/*<Route path="resources" element={<ResourcesFooter id={'rdoc'} title={'Resources'}/>} >*/}
        {/*    <Route path="doc" element={<Resources />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}

        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}

        {/*<Route path="connecthelp" element={<ConnectFooter id={'chelp'} title={'Assistance'} />} >*/}

        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="connectfb" element={<ConnectFooter id={'cfb'} title={'Feedback'}  />} >*/}
        {/*    <Route path="feedback" element={<Feedback />} />*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="connectc" element={<ConnectFooter id={'ccc'} title={'Connect'}  />} >*/}
        {/*    <Route path="connect" element={<Connect />} />           //common to conn*/}
        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}
        {/*<Route path="connectsm" element={<ConnectFooter id={'csm'} title={'Sitemap'}  />} >*/}

        {/*    <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*</Route>*/}

        {/*<Route path="metasys" element={<AdminDashboard auth={authData}/>}>*/}
        {/*    <Route path="appointments" element={<MetaCalendarADM_APPT auth={authData} actPage={'appointments'}/>}/>*/}
        {/*    <Route path={"profile"} element={<Admin auth={authData}/>}/>*/}
        {/*    <Route path={"specialists"} element={<Admin auth={authData}/>}/>*/}
        {/*    <Route path={"facilities"} element={<Admin auth={authData}/>}/>*/}
        {/*    <Route path={"qns"} element={<Admin auth={authData}/>}/>*/}
        {/*    <Route path={"resources"} element={<Admin auth={authData}/>}/>*/}
        {/*    <Route path={"payments"} element={<Admin auth={authData}/>}/>*/}
        {/*    <Route path={"setup"} element={<Admin auth={authData}/>}/>*/}
        {/*    <Route path={"do"} element={<MetadminDoThisNow auth={authData}/>}/>*/}
        {/*    <Route path="region" element={<OrgRegionSetting/>}/>*/}
        {/*</Route>*/}

        {/*
         * metauser
         */}
        <Route path="metauser" element={<PatientDashboard auth={authData} />}>
          {/* new routes */}
          <Route path="home" element={<DashboardHome auth={authData} />} />

          <Route path="find-care" element={<FindCare auth={authData} />} />
          <Route
            path="appointments"
            element={<Appointments auth={authData} />}
          />

          <Route
            path="authorize-sharing"
            element={<AuthorizeSharing auth={authData} />}
          />
          <Route
            path="health-insurance"
            element={<HealthInsurance auth={authData} />}
          />
          <Route
            path="immunizations"
            element={<Immunizations auth={authData} />}
          />
          <Route
            path="medical-records"
            element={<MedicalRecords auth={authData} />}
          />
          <Route path="medications" element={<Medications auth={authData} />} />
          <Route path="messages" element={<Messages auth={authData} />} />
          <Route path="payments" element={<Payments auth={authData} />} />
          <Route
            path="test-results/:id"
            element={<TestResultsDetails auth={authData} />}
          />
          <Route
            path="test-results"
            element={<TestResults auth={authData} />}
          />
          {/* new routes */}
          <Route path="profile" element={<ProfUSR auth={authData} />}>
            <Route
              path={"test"}
              element={<LineAreaZoomChart auth={authData} />}
            />
            <Route path="list" element={<ProfileListUSR auth={authData} />} />
            <Route path="update" element={<ProfileInput auth={authData} />} />
            <Route
              path="analytics"
              element={<ProfileAnalytics auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            {/* <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            /> */}
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="todo" element={<TodoUSR auth={authData} />}>
            <Route
              path="cal"
              element={
                <MetaCalendarUSR_TODO auth={authData} actPage={"todo"} />
              }
            />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="appointments" element={<ApptUSR auth={authData} />}>
            <Route
              path="cal"
              element={
                <MetaCalendarUSR_APPT
                  auth={authData}
                  actPage={"appointments"}
                />
              }
            />
            <Route path="new" element={<AddApptUSR auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="notes" element={<CNotesUSR auth={authData} />}>
            <Route
              path="notes"
              element={<ClinicalNotesUSR auth={authData} />}
            />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="reports" element={<RepoUSR auth={authData} />}>
            <Route
              path="reports"
              element={<ReportsListUSR auth={authData} />}
            />
            <Route
              path="requests"
              element={<LabsRequestsUSR auth={authData} />}
            />
            <Route path="charts" element={<ChartContainer auth={authData} />} />
            <Route path="analytics" element={<MetaGraph auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="radreports" element={<RadRepoUSR auth={authData} />}>
            <Route
              path="requests"
              element={<RadReportsListUSR auth={authData} />}
            />
            <Route path="dicom" element={<DicomViewUSR auth={authData} />}>
              <Route path="files" element={<DicomFileView />} />
              <Route path="view" element={<DicomViewer gtype="metauser" />} />
              <Route path="images" element={<DicomImageView />} />
            </Route>
            <Route
              path="charts"
              element={<RadChartContainer auth={authData} />}
            />
            <Route path="analytics" element={<MetaGraph auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="mimages" element={<ImgyUSR auth={authData} />}>
            <Route path="dicom" element={<DicomViewUSR auth={authData} />}>
              <Route path="files" element={<DicomFileView />} />
              <Route path="view" element={<DicomViewer gtype="metauser" />} />
              <Route path="images" element={<DicomImageView />} />
            </Route>
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="medicine" element={<MediUSR auth={authData} />}>
            <Route path="list" element={<MedicineListUSR auth={authData} />} />
            <Route
              path="requests"
              element={<MedicineRequestListUSR auth={authData} />}
            />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="monitor" element={<HmoniUSR auth={authData} />}>
            <Route path="info" element={<HmoniListUSR auth={authData} />} />
            <Route
              path="conditions"
              element={<HmoniCondUSR auth={authData} />}
            />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="care" element={<PcareUSR auth={authData} />}>
            <Route path="list" element={<PCareListUSR auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="qns" element={<QnsUSR auth={authData} />}>
            <Route path="list" element={<QnsListUSR auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="resources" element={<ResoUSR auth={authData} />}>
            <Route path="list" element={<ResoListUSR auth={authData} />} />
            <Route
              path="createpdf"
              element={<CreatePdfPNUSR auth={authData} />}
            />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="payments" element={<PayUSR auth={authData} />}>
            <Route
              path="list"
              element={<PaymentDuesListUSR auth={authData} />}
            />
            <Route
              path="createpdf"
              element={<CreatePdfUSR auth={authData} />}
            />
            <Route
              path="ledger"
              element={<PaymentLedgerUSR auth={authData} />}
            />
            <Route
              path="settings"
              element={<SetProfileList auth={authData} />}
            />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="insurance" element={<InsuUSR auth={authData} />}>
            <Route path="info" element={<InsuInfoUSR auth={authData} />} />
            <Route
              path="compare"
              element={<InsuCompareUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="setup" element={<SetUSR auth={authData} />}>
            <Route
              path="privacy"
              element={<SetProfileList auth={authData} />}
            />
            <Route path="settings" element={<SettingsUSR auth={authData} />} />
            <Route path="uploads" element={<UploadsUSR auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="qns" element={<QnsUSR auth={authData} />}>
            <Route path="list" element={<QnsListUSR auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="resources" element={<ResoUSR auth={authData} />}>
            <Route path="list" element={<ResoListUSR auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="do" element={<MetauserDoThisNow auth={authData} />}>
            <Route path="list" element={<DoListUSR auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsUSR auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>
        </Route>
        <Route path="appointmentpdf" element={<AppointmentPDF />} />
        {/*
          metamd
          */}
        <Route path="metamd" element={<DoctorDashboard auth={authData} />}>
          <Route path="profile" element={<ProfMD auth={authData} />}>
            <Route path="list" element={<ProfileListMD auth={authData} />} />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="todo" element={<TodoMD auth={authData} />}>
            <Route
              path="cal"
              element={<MetaCalendarMD_TODO auth={authData} actPage={"todo"} />}
            />
            <Route
              path="list"
              element={<MetaCalendarMD_TODO auth={authData} actPage={"todo"} />}
            />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="appointments" element={<ApptMD auth={authData} />}>
            <Route path="new" element={<AddApptMD auth={authData} />} />
            <Route
              path="cal"
              element={
                <MetaCalendarMD_APPT auth={authData} actPage={"appointments"} />
              }
            />
            <Route
              path="list"
              element={
                <MetaCalendarMD_APPT auth={authData} actPage={"appointments"} />
              }
            />
            <Route path="update" element={<UpdateApptMD auth={authData} />} />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="patients" element={<PatMD auth={authData} />}>
            <Route path="list" element={<PatientListMD auth={authData} />} />
            <Route
              path="prescribe"
              element={<PatientsPrescribe auth={authData} />}
            />
            <Route
              path="createpdf"
              element={<CreatePdfPNMD auth={authData} />}
            />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="notes" element={<CNotesMD auth={authData} />}>
            <Route path="notes" element={<ClinicalNotesMD />} />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="reports" element={<RepoMD auth={authData} />}>
            <Route path="results" element={<ReportsListMD auth={authData} />} />
            <Route
              path="requests"
              element={<ReportsRequestsMD auth={authData} />}
            />
            <Route
              path="newtests"
              element={<ClinicalTestsPrescribe auth={authData} />}
            />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="rt" element={<RadioMD auth={authData} />}>
            <Route
              path="results"
              element={<RadiologyListMD auth={authData} />}
            />
            <Route
              path="requests"
              element={<DicomRequestsMD auth={authData} />}
            />
            <Route
              path="newtests"
              element={<OtherTestsSearch auth={authData} />}
            />
            <Route path="dicom" element={<DicomViewMD auth={authData} />}>
              <Route path="files" element={<DicomFileView />} />
              <Route path="view" element={<DicomViewer gtype="metamd" />} />
              <Route path="images" element={<DicomImageView />} />
            </Route>
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="mimages" element={<ImgyMD auth={authData} />}>
            <Route path="dicom" element={<DicomViewMD auth={authData} />}>
              <Route path="files" element={<DicomFileView />} />
              <Route path="view" element={<DicomViewer gtype="metamd" />} />
              <Route path="images" element={<DicomImageView />} />
            </Route>
            {/*
                  <Route path="requests" element={<DicomRequestsMD auth={authData}/>}/>
                  <Route path="newproc" element={<OtherTestsSearch auth={authData}/>}/>

                  <Route path="analytics" element={<AnalyticsMD auth={authData}/>}/>
                  */}
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="medicine" element={<MediMD auth={authData} />}>
            <Route path="list" element={<MedicineListMD auth={authData} />} />
            <Route path="newmed" element={<DrugSearch auth={authData} />} />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="monitor" element={<HMoniMD auth={authData} />}>
            <Route path="info" element={<HmoniListMD auth={authData} />} />
            <Route
              path="conditions"
              element={<HmoniCondMD auth={authData} />}
            />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="payments" element={<PayMD auth={authData} />}>
            <Route path="list" element={<PayListMD auth={authData} />} />
            <Route path="createpdf" element={<CreatePdfMD auth={authData} />} />
            <Route
              path="settings"
              element={<SetProfileListMD auth={authData} />}
            />
            <Route
              path="ledger"
              element={<PaymentLedgerMD auth={authData} />}
            />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="care" element={<PCareMD auth={authData} />}>
            <Route path="list" element={<PCareListMD auth={authData} />} />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="qns" element={<QnsMD auth={authData} />}>
            <Route path="list" element={<QnsListMD auth={authData} />} />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="resources" element={<ResoMD auth={authData} />}>
            <Route path="list" element={<ResoListMD auth={authData} />} />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="insurance" element={<InsuMD auth={authData} />}>
            <Route path="info" element={<InsuInfoMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="setup" element={<SetMD auth={authData} />}>
            <Route path="privacy" element={<PrivacyMD auth={authData} />} />
            <Route
              path="password"
              element={<SettingsPassword auth={authData} />}
            />
            <Route path="settings" element={<SettingsMD auth={authData} />} />
            <Route path="uploads" element={<UploadsMD auth={authData} />} />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="do" element={<MetamdDoThisNow auth={authData} />}>
            <Route path="list" element={<DoListMD auth={authData} />} />
            <Route path="analytics" element={<AnalyticsMD auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>
        </Route>

        {/*
         * metaorg
         */}
        <Route
          path="metaorg"
          element={<OrganizationDashboard auth={authData} />}
        >
          <Route path="profile" element={<ProfORG auth={authData} />}>
            <Route
              path="analytics"
              element={<AnalyticsORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="info" element={<InfoORG auth={authData} />}>
            <Route path="info" element={<InfoListORG auth={authData} />} />
            <Route path="manage" element={<InfoManageORG auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="todo" element={<ApptORG auth={authData} />}>
            <Route
              path="cal"
              element={
                <MetaCalendarORG_TODO
                  auth={authData}
                  actPage={"appointments"}
                />
              }
            />
            <Route
              path="analytics"
              element={<AnalyticsORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="appointments" element={<ApptORG auth={authData} />}>
            <Route
              path="cal"
              element={
                <MetaCalendarORG_APPT
                  auth={authData}
                  actPage={"appointments"}
                />
              }
            />
            <Route
              path="analytics"
              element={<AnalyticsORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="monitor" element={<MoniORG auth={authData} />}>
            <Route path="list" element={<MoniListORG auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="departments" element={<DeptORG auth={authData} />}>
            <Route path="info" element={<DeptListORG auth={authData} />} />
            <Route path="manage" element={<DeptManageORG auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="patients" element={<PatnORG auth={authData} />}>
            <Route path="list" element={<PatientListORG auth={authData} />} />
            <Route
              path="createpdf"
              element={<CreatePdfPNORG auth={authData} />}
            />
            <Route
              path="policy"
              element={<PatientListManageORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="specialists" element={<SpecORG auth={authData} />}>
            <Route path="info" element={<SpecListORG auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="reports" element={<RepoORG auth={authData} />}></Route>

          <Route path="associates" element={<AssoORG auth={authData} />}>
            <Route path="list" element={<AssoListORG auth={authData} />} />
            <Route path="todo" element={<AssoTodoORG auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="facilities" element={<FaciORG auth={authData} />}>
            <Route path="list" element={<FaciListORG auth={authData} />} />
            <Route path="todo" element={<FaciTodoORG auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="resources" element={<ResoORG auth={authData} />}>
            <Route path="list" element={<ResoListORG auth={authData} />} />
            <Route path="todo" element={<ResoTodoORG auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="accounts" element={<AccORG auth={authData} />}>
            <Route path="list" element={<AccListORG auth={authData} />} />
            <Route
              path="createpdf"
              element={<CreatePdfORG auth={authData} />}
            />
            <Route path="ledger" element={<AccLedgerORG auth={authData} />} />
            <Route
              path="settings"
              element={<SetProfileListORG auth={authData} />}
            />
            <Route path="todo" element={<AccTodoORG auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="insurance" element={<InsuORG auth={authData} />}>
            <Route path="info" element={<InsuInfoORG auth={authData} />} />
            <Route path="manage" element={<InsuManageORG auth={authData} />} />
            <Route
              path="uploads"
              element={<UploadsInsuORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="ct" element={<ClinicalTestsORG auth={authData} />}>
            <Route
              path="list"
              element={<ClinicalTestsListTabsORG auth={authData} />}
            />
            <Route
              path="tax"
              element={<ClinicalTestsTaxORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
          </Route>

          <Route path="rt" element={<RadiologyTestsORG auth={authData} />}>
            <Route
              path="list"
              element={<RadiologyTestsListTabsORG auth={authData} />}
            />
            <Route
              path="tax"
              element={<RadiologyTestsTaxORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
          </Route>

          <Route path="drugs" element={<DrugORG auth={authData} />}>
            <Route path="list" element={<DrugsListTabsOrg auth={authData} />} />
            <Route path="tax" element={<DrugsTaxTabsOrg auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="setup" element={<SetORG auth={authData} />}>
            <Route path="privacy" element={<PrivacyORG auth={authData} />} />
            <Route path="settings" element={<SettingsORG auth={authData} />} />
            <Route path="uploads" element={<UploadsORG auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>

          <Route path="do" element={<MetaorgDoThisNow auth={authData} />}>
            <Route path="list" element={<DoListORG auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsORG auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route
              path="region"
              element={<OrgRegionSetting auth={authData} />}
            />
          </Route>
        </Route>

        {/*
          metalab
          */}
        <Route path="metalab" element={<LaboratoryDashboard auth={authData} />}>
          <Route path="profile" element={<ProfLAB auth={authData} />}>
            <Route path="list" element={<ProfileListLAB auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsLAB auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="group" element={<ProfGroupLAB auth={authData} />}>
            <Route
              path="list"
              element={<ProfileGroupListLAB auth={authData} />}
            />
            <Route
              path="analytics"
              element={<AnalyticsLAB auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>
          <Route path="info" element={<InfoLAB auth={authData} />}>
            <Route path="info" element={<InfoListLAB auth={authData} />} />
            <Route path="manage" element={<InfoManageLAB auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="monitor" element={<MoniLAB auth={authData} />}>
            <Route path="list" element={<MoniListLAB auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsLAB auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="appointments" element={<ApptLAB auth={authData} />}>
            <Route
              path="cal"
              element={
                <MetaCalendarLAB_APPT auth={authData} actPage={"laboratory"} />
              }
            />
            <Route
              path="investigations"
              element={<ApptMonitorLAB auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="patients" element={<PatnLAB auth={authData} />}>
            <Route path="list" element={<PatientListLAB auth={authData} />} />
            <Route path="manage" element={<InsuInfoLAB auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="reports" element={<RepoLAB auth={authData} />}>
            <Route path="list" element={<RepoListLAB auth={authData} />} />
            <Route
              path="uploads"
              element={<UploadsReportsLAB auth={authData} />}
            />
            <Route
              path="analytics"
              element={<AnalyticsLAB auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="mimages" element={<ImgyLAB auth={authData} />}>
            <Route path="dicom" element={<DicomViewLAB auth={authData} />}>
              <Route path="files" element={<DicomFileView />} />
              <Route path="view" element={<DicomViewer gtype="metalab" />} />
              <Route path="images" element={<DicomImageView />} />
            </Route>
            <Route path="list" element={<ImgyListLAB auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="ct" element={<ClinicalTestsLAB auth={authData} />}>
            <Route
              path="list"
              element={<ClinicalTestsListTabsLAB auth={authData} />}
            />
            <Route
              path="tax"
              element={<ClinicalTestsTaxLAB auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="rt" element={<RadiologyTestsLAB auth={authData} />}>
            <Route
              path="list"
              element={<RadiologyTestsListTabsLAB auth={authData} />}
            />
            <Route
              path="tax"
              element={<RadiologyTestsTaxLAB auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="resources" element={<ResoLAB auth={authData} />}>
            <Route path="list" element={<ResoListLAB auth={authData} />} />
            <Route path="todo" element={<ResoTodoLAB auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsLAB auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="accounts" element={<AccLAB auth={authData} />}>
            <Route path="list" element={<AccListLAB auth={authData} />} />
            <Route path="ledger" element={<AccLedgerLAB auth={authData} />} />
            <Route
              path="settings"
              element={<SetProfileListLAB auth={authData} />}
            />
            <Route
              path="analytics"
              element={<AnalyticsLAB auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="setup" element={<SetLAB auth={authData} />}>
            <Route path="privacy" element={<PrivacyLAB auth={authData} />} />
            <Route path="settings" element={<SettingsLAB auth={authData} />} />
            <Route path="uploads" element={<UploadsLAB auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsLAB auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="do" element={<MetalabDoThisNow auth={authData} />}>
            <Route path="list" element={<DoListLAB auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsLAB auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>
        </Route>

        {/*
          metapharma
          */}

        <Route
          path="metapharma"
          element={<PharmacyDashboard auth={authData} />}
        >
          <Route path="profile" element={<ProfPHA auth={authData} />}>
            <Route path="list" element={<ProfileListPHA auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsPHA auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="group" element={<ProfGroupPHA auth={authData} />}>
            <Route
              path="list"
              element={<ProfileGroupListPHA auth={authData} />}
            />
            <Route
              path="analytics"
              element={<AnalyticsPHA auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="info" element={<InfoPHA auth={authData} />}>
            <Route path="info" element={<InfoListPHA auth={authData} />} />
            <Route path="manage" element={<InfoManagePHA auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="appointments" element={<ApptPHA auth={authData} />}>
            <Route
              path="cal"
              element={
                <MetaCalendarPHA_APPT auth={authData} actPage={"pharmacy"} />
              }
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="prescriptions" element={<PresPHA auth={authData} />}>
            <Route path="list" element={<PresListPHA auth={authData} />} />
            <Route
              path="monitor"
              element={<PresMonitorPHA auth={authData} />}
            />
            <Route
              path="analytics"
              element={<AnalyticsPHA auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="patients" element={<PatnPHA auth={authData} />}>
            <Route path="list" element={<PatientListPHA auth={authData} />} />
            <Route path="manage" element={<InsuInfoPHA auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="orders" element={<OrderPHA auth={authData} />}>
            <Route path="list" element={<OrderListPHA auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="drugs" element={<DrugPHA auth={authData} />}>
            <Route path="list" element={<DrugsListTabsPHA auth={authData} />} />
            <Route path="tax" element={<DrugsTaxTabsPHA auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="support" element={<SupoPHA auth={authData} />}>
            <Route path="info" element={<SupoListPHA auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="accounts" element={<AccPHA auth={authData} />}>
            <Route path="list" element={<AccListPHA auth={authData} />} />
            <Route path="ledger" element={<AccLedgerPHA auth={authData} />} />
            <Route
              path="settings"
              element={<SetProfileListPHA auth={authData} />}
            />
            <Route
              path="analytics"
              element={<AnalyticsPHA auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="setup" element={<SetPHA auth={authData} />}>
            <Route path="privacy" element={<PrivacyPHA auth={authData} />} />
            <Route path="settings" element={<SettingsPHA auth={authData} />} />
            <Route path="uploads" element={<UploadsPHA auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsPHA auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="do" element={<MetalabDoThisNow auth={authData} />}>
            <Route path="list" element={<DoListPHA auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsPHA auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>
        </Route>

        {/*
          metafin
          */}
        <Route path="metafin" element={<FinanceDashboard auth={authData} />}>
          <Route path="profile" element={<ProfFIN auth={authData} />}>
            <Route
              path="analytics"
              element={<AnalyticsFIN auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="info" element={<InfoFIN auth={authData} />}>
            <Route path="info" element={<InfoListFIN auth={authData} />} />
            <Route path="manage" element={<InfoManageFIN auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="appointments" element={<ApptFIN auth={authData} />}>
            <Route
              path="cal"
              element={
                <MetaCalendarFIN_APPT
                  auth={authData}
                  actPage={"appointments"}
                />
              }
            />
            <Route
              path="analytics"
              element={<AnalyticsFIN auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="monitor" element={<MoniFIN auth={authData} />}>
            <Route path="list" element={<MoniListFIN auth={authData} />} />
            <Route path="analysis" element={<AnalyticsFIN auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="specialists" element={<SpecFIN auth={authData} />}>
            <Route path="info" element={<SpecListFIN auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsFIN auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="reports" element={<RepoFIN auth={authData} />}></Route>

          <Route path="associates" element={<AssoFIN auth={authData} />}>
            <Route path="list" element={<AssoListFIN auth={authData} />} />
            <Route path="todo" element={<AssoTodoFIN auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsFIN auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="facilities" element={<FaciFIN auth={authData} />}>
            <Route path="list" element={<FaciListFIN auth={authData} />} />
            <Route path="todo" element={<FaciTodoFIN auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsFIN auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="resources" element={<ResoFIN auth={authData} />}>
            <Route path="list" element={<ResoListFIN auth={authData} />} />
            <Route path="todo" element={<ResoTodoFIN auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsFIN auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="accounts" element={<AccFIN auth={authData} />}>
            <Route path="list" element={<AccListFIN auth={authData} />} />
            <Route path="ledger" element={<AccLedgerFIN auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsFIN auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="setup" element={<SetFIN auth={authData} />}>
            <Route path="privacy" element={<PrivacyFIN auth={authData} />} />
            <Route path="settings" element={<SettingsFIN auth={authData} />} />
            <Route path="uploads" element={<UploadsFIN auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsFIN auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="do" element={<MetalabDoThisNow auth={authData} />}>
            <Route path="list" element={<DoListFIN auth={authData} />} />
            <Route
              path="analytics"
              element={<AnalyticsFIN auth={authData} />}
            />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>
        </Route>

        {/*
          metaos
          */}
        <Route
          path="metasrv"
          element={<OtherServiceDashboard auth={authData} />}
        >
          <Route path="profile" element={<ProfOS auth={authData} />}>
            <Route path="analytics" element={<AnalyticsOS auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="info" element={<InfoOS auth={authData} />}>
            <Route path="info" element={<InfoListOS auth={authData} />} />
            <Route path="manage" element={<InfoManageOS auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="appointments" element={<ApptOS auth={authData} />}>
            <Route
              path="cal"
              element={
                <MetaCalendarOS_APPT auth={authData} actPage={"appointments"} />
              }
            />
            <Route path="analytics" element={<AnalyticsOS auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="monitor" element={<MoniOS auth={authData} />}>
            <Route path="list" element={<MoniListOS auth={authData} />} />
            <Route path="analytics" element={<AnalyticsOS auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="specialists" element={<SpecOS auth={authData} />}>
            <Route path="info" element={<SpecListOS auth={authData} />} />
            <Route path="analytics" element={<AnalyticsOS auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="reports" element={<RepoOS auth={authData} />}></Route>

          <Route path="associates" element={<AssoOS auth={authData} />}>
            <Route path="list" element={<AssoListOS auth={authData} />} />
            <Route path="todo" element={<AssoTodoOS auth={authData} />} />
            <Route path="analytics" element={<AnalyticsOS auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="facilities" element={<FaciOS auth={authData} />}>
            <Route path="list" element={<FaciListOS auth={authData} />} />
            <Route path="todo" element={<FaciTodoOS auth={authData} />} />
            <Route path="analytics" element={<AnalyticsOS auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="resources" element={<ResoOS auth={authData} />}>
            <Route path="list" element={<ResoListOS auth={authData} />} />
            <Route path="todo" element={<ResoTodoOS auth={authData} />} />
            <Route path="analytics" element={<AnalyticsOS auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="accounts" element={<AccOS auth={authData} />}>
            <Route path="list" element={<AccListOS auth={authData} />} />
            <Route path="ledger" element={<AccLedgerOS auth={authData} />} />
            <Route path="analytics" element={<AnalyticsOS auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="setup" element={<SetOS auth={authData} />}>
            <Route path="privacy" element={<PrivacyOS auth={authData} />} />
            <Route path="settings" element={<SettingsOS auth={authData} />} />
            <Route path="uploads" element={<UploadsOS auth={authData} />} />
            <Route path="analytics" element={<AnalyticsOS auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>

          <Route path="do" element={<MetalabDoThisNow auth={authData} />}>
            <Route path="list" element={<DoListOS auth={authData} />} />
            <Route path="analytics" element={<AnalyticsOS auth={authData} />} />
            <Route
              path="notifications"
              element={<AlertItemsList auth={authData} />}
            />
            <Route
              path="messages"
              element={<MessageItemsList auth={authData} />}
            />
            <Route path="region" element={<OrgRegionSetting />} />
          </Route>
        </Route>

        {/*
         * metacon
         */}

        {/*<Route   path="metacon" element={<ConnectDashboard />}  >*/}
        {/*    <Route path="contactus" element={<CusCON   />} >*/}
        {/*        <Route path="connect" element={<Connect   />} />*/}
        {/*    </Route>*/}
        {/*    <Route path="help" element={<HelpCON      />} />*/}
        {/*    <Route path="feedback" element={<FbkCON   />} >*/}
        {/*        <Route path="fb" element={<Feedback     />} />*/}
        {/*    </Route>*/}

        {/*    <Route path="info" element={<InfoCON  />} />*/}

        {/*    <Route path="appointments" element={<ApptCON  />} >*/}
        {/*        <Route path="list" element={<Connect   />} />*/}
        {/*    </Route>*/}
        {/*    <Route path="healthcare" element={<HcareCON />} >*/}
        {/*        <Route path="list" element={<ProviderSearchHospital   auth={authData}/>} />*/}
        {/*    </Route>*/}
        {/*    <Route path="clinic" element={<ClinicCON />} >*/}
        {/*        <Route path="list" element={<ProviderSearchClinic     auth={authData}/>} />*/}
        {/*    </Route>*/}
        {/*    <Route path="drugstore" element={<DStoreCON auth={authData} />} >*/}
        {/*        <Route path="list" element={<ProviderSearchDrugStore  auth={authData}/>} />*/}
        {/*    </Route>*/}
        {/*    <Route path="pathology" element={<PathoCON auth={authData} />} >*/}
        {/*        <Route path="list" element={<ProviderSearchPathology  auth={authData}/>} />*/}
        {/*    </Route>*/}
        {/*    <Route path="specialists" element={<SpecCON  />}>*/}
        {/*        <Route path="list" element={<ProviderSearchSpecialist auth={authData}/>} />*/}
        {/*    </Route>*/}

        {/*    <Route path="departments" element={<DeptCON auth={authData} />} >*/}
        {/*        <Route path="list" element={<ProviderSearch           auth={authData}/>} />*/}
        {/*    </Route>*/}

        {/*    <Route path="facilities" element={<FaciCON  />} >*/}
        {/*        <Route path="list" element={<ProviderSearch           auth={authData}/>} />*/}
        {/*        <Route path="do" element={<MetaconDoThisNow />} />*/}
        {/*        <Route path="region" element={<OrgRegionSetting />} />*/}
        {/*    </Route>*/}
        {/*</Route>*/}

        {/* Fallback to Home Page */}
        <Route path="*" element={<HomePageSoon />} />
      </Routes>
      {/* <div className="d-none d-md-block"> */}
      {location.pathname === "/" ||
      location.pathname === "/terms" ||
      location.pathname === "/privacypolicy" ? (
        <FooterLanding />
      ) : (
        <Footer />
      )}
      {/* </div> */}
    </div>
  );
}

export default App;
