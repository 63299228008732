import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { register } from "../../redux/actions/auth";
import { Box, Link } from "@material-ui/core";
import { MyFullButtonsColor } from "../CustomComponents/MyButtons";
import Password from "../CustomComponents/Password";
import InputComponent from "../CustomComponents/InputComponent";
import InputActivityRole from "../CustomComponents/InputActivityRole";

import { validateEmail } from "../common/validateComponents";

export default function LoginAskus(props) {
  const auth = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  console.log("signup", auth);
  const navigate = useNavigate();

  const [values, setValues] = useState({ groupId: "metaorg" });

  const handleClickShowPassword = (e) => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleDisplayNameChange = (event) => {
    setValues({
      ...values,
      displayName: event.target.value,
      displayNameError: event.target.value ? false : true,
      error: event.target.value ? false : true,
    });
  };

  const handleEmailChange = (event) => {
    const ev = event.target.value;
    const em = !validateEmail(ev);

    setValues({
      ...values,
      email: ev,
      emailError: em,
      error: em,
    });
  };

  const handleSubmit = (event) => {
    console.log("clicked");

    event.preventDefault();

    const { displayName, email } = values;

    const errdisp = !!displayName ? false : true;
    const erremail = !validateEmail(email);

    const err = !!displayName && validateEmail(email) ? false : true;

    setValues({
      ...values,

      displayName: displayName ? displayName : "",
      email: email ? email : "",
      displayNameError: errdisp,
      emailError: erremail,
      error: err,
    });

    console.log("final", err, displayName, email);

    if (!err) {
      console.log("Entered");
      console.log(values);
      dispatch(register(values.displayName, values.email)).then(
        (r) => {
          console.log(r);
          navigate("/sign"); //MK pl check this part
        },
        (error) => {
          console.log(error);
          alert("Error: " + error);
        }
      );
      setValues({
        ...values,
        error: false,
      });
    }
  };

  const emailerrortext = (
    <i>Provide valid e-Mail Address. Your username will be send to this ID</i>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          marginBottom: "0px !important",
        }}
      >
        <h3 className="text-color-primary">Forgot Username</h3>
        <span className="text-color-primary">
          Your username will be emailed to your registered email.
        </span>

        <InputComponent
          id="displayName"
          name="displayName"
          label="Legal Name"
          errors={values.displayNameError}
          variant="standard"
          handleChange={handleDisplayNameChange}
        />

        <InputComponent
          id="email"
          name="email"
          label="Registered Email"
          errors={values.emailError}
          errorText={emailerrortext}
          variant="standard"
          handleChange={handleEmailChange}
        />

        <Box className="clear">&nbsp; </Box>
        <Box className="clear">&nbsp; </Box>

        <MyFullButtonsColor
          onClick={handleSubmit}
          btnKey={"x"}
          btnLabel={"Proceed"}
          enState={false}
        />

        <Box className="clear">&nbsp; </Box>

        <Box
          className="clear text-color-read"
          sx={{
            padding: "16px",
            margin: "16px 0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Box className="clear">&nbsp; </Box>
          <center>
            Already have an account?
            <Link href="/sign" underline="always" className="text-color">
              SIGN IN
            </Link>
          </center>
          <Box className="clear">&nbsp; </Box>
          <center>
            Need an account?
            <Link href="/signup" underline="always" className="text-color">
              Register
            </Link>
          </center>
        </Box>
      </Box>
    </>
  );
}
