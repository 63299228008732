import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as React from "react";
import {
  Box,
  Collapse,
  Typography,
  IconButton,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableFooter,
} from "@mui/material";
import { tableParam } from "../common/ConstantsAndValues";
import { MyIconButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";

export function PolicyListRow(props) {
  const { row, selectRow, selectedRows } = props;
  const [open, setOpen] = useState(false);
  const [maplist, setMaplist] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // set row state on reload
    if (selectedRows && row) {
      const selectrow = selectedRows.find((r) => r.id === row.id);
      if (selectrow) {
        setSelectedRow(true);
      }
    }
  }, []);

  useEffect(() => {
    if (row) {
      const listdata = [];
      listdata.push({ id: "opd", label: "OPD Coverage", value: "None" });
      row.hiiCoverage
        ? listdata.push({
            id: "hii",
            label: "Hospitalizaiton Coverage",
            value: row.hiiCoverage.coverage,
          })
        : listdata.push({
            id: "hii",
            label: "Hospitalizaiton Coverage",
            value: "",
          });
      row.dentalCoverage
        ? listdata.push({
            id: "dental",
            label: "Hospitalizaiton Coverage",
            value: row.dentalCoverage.coverage,
          })
        : listdata.push({
            id: "dental",
            label: "Dental Coverage",
            value: "None",
          });
      row.visionCoverage
        ? listdata.push({
            id: "vision",
            label: "Hospitalizaiton Coverage",
            value: row.visionCoverage.coverage,
          })
        : listdata.push({
            id: "vision",
            label: "Vision Coverage",
            value: "None",
          });
      // log.info("ct row", r);
      setMaplist(listdata);
    }
  }, []);

  const handleClick = () => {
    log.info("ct row", row);
    // if (row) {
    //     const jsonData = {};
    //     jsonData['metadata'] = {};
    //     jsonData.metadata['metalabId'] = row.labId;
    //     dispatch(getMetaLabMap(jsonData)).then((r) => {
    //         log.info("ct row", r);
    //         setMaplist(r)
    //     });
    // }
    setOpen(!open);
  };

  const handleFilter = () => {};

  const handelRowlocal = (row) => {
    log.info("healthpolicylist", row);
    setSelectedRow(!selectedRow);
    selectRow(row);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => handelRowlocal(row)}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
      >
        <TableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </TableCell>
        <TableCell component="th" scope="row">
          <b>{row.policyName}</b>
        </TableCell>
        <TableCell align="right">
          <Typography variant={"body2"}>{row.providerName}</Typography>
        </TableCell>
        <TableCell align="right">Rs. {row.hiiCoverage.coverage}</TableCell>
        <TableCell align="right">{row.hiiCoverage.period}</TableCell>
      </TableRow>

      <TableRow className="text-color-gray bg-standard-offwhite">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">
                  Policy information
                </div>
              </div>

              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {maplist &&
                      maplist.map((m) => (
                        <TableRow key={m.id}>
                          <TableCell>{m.label}</TableCell>
                          <TableCell>{m.value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
