import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createProviderAppointment,
  setAppServices,
} from "../../../redux/appointment/appointment.actions";
import { getProfile } from "../../../redux/actions/auth";

import Registration from "./RegistrationUSR";
import InputServiceType from "../../docs/InputServiceType";
import { AppointmentProvider } from "../../providers/AppointmentProvider";
import { MetaCalendar } from "../../appointment/MetaCalendar";
import { AppointmentInput } from "../../appointment/AppointmentInput";

import { appointmentSchema } from "../../schema/appointmentSchema";
import { AppointmentStepper } from "../../appointment/AppointmentStepper";
import log from "../../../services/log";

/**
 * selectService - appoint service selection to redux
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export default function AddApptUSR(props) {
  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );
  const address = useSelector((state) => state?.addresses?.selectaddress);
  const profile = useSelector((state) => state?.user?.profile);
  const selectService = useSelector((state) => state?.appointment?.apptstype);
  const services = useSelector((state) => state?.appointment?.aptservs);
  const selectdate = useSelector((state) => state?.appointment?.dates);
  const labsorder = useSelector((state) => state?.labs?.labsorder);
  const docorder = useSelector((state) => state?.user?.docorder);
  const provider = useSelector(
    (state) => state?.directory?.selectproviderlistrow
  );

  const [disableContinue, setDisableContinue] = useState(true);
  const [serviceType, setSerivceType] = useState(
    selectService ? selectService : null
  );
  const [activeStep, setActiveStep] = useState(0);
  const [alertMsg, setAlertMsg] = useState("");
  const [anchorAddress, setAnchorAddress] = useState(address);

  const dispatch = useDispatch();

  const appointmentform = useFormik({
    initialValues: {
      userId: user?.metadata?.accountId,
      email: user?.metadata?.email,
      guestId: user?.metadata?.accountId,
      guestName: user?.name,
      userName: user?.name,
      eventTitle: null,
      eventType: null,
      eventDescp: null,
      locId: "virtual",
      departmentId: "",
      providerId: "",
      providerName: "",
      address: "",
      dateStart: null,
      dateEnd: null,
    },
    validationSchema: appointmentSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      log.info("appointment", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData.metadata = {};
      jsonData.metadata.userId = values.userId;
      jsonData.metadata.providerId = values.providerId;
      jsonData.metadata.departmentId = values.departmentId;
      jsonData.userName = values.userName;
      jsonData.metadata.email = values.email;
      jsonData.eventTitle = values.eventTitle;
      jsonData.eventDescp = values.eventDescp;
      jsonData.metadata.eventType = values.eventType;
      jsonData.dateStart = values.dateStart;
      jsonData.dateEnd = values.dateEnd;
      jsonData["eventAddress"] = {};
      jsonData.eventAddress["locId"] = values.locId;
      jsonData.eventAddress["address"] = values.address;
      jsonData.metadata.guestId = values.guestId;
      jsonData.guestName = values.guestName;
      jsonData.providerName = values.providerName;

      log.info("metacal", jsonData);
      dispatch(createProviderAppointment(jsonData));
      setDisableContinue(true);
    },
  });

  const [appointment, setAppointment] = useState({
    location: {},
    timeSlot: {},
    date: new Date(),
  });

  // retriew patient profile
  useEffect(() => {
    dispatch(
      getProfile({
        metadata: {
          accountId: user?.metadata?.accountId,
        },
      })
    );
  }, [user, dispatch]);

  // fork flow
  useEffect(() => {
    if (selectService) {
      setSerivceType(selectService);
    } else setSerivceType(null);
  }, [selectService]);

  useEffect(() => {
    if (provider) {
      appointmentform.setFieldValue(
        "departmentId",
        provider?._source.departmentId
      );
      appointmentform.setFieldValue("providerId", provider?._source.providerId);
      appointmentform.setFieldValue("providerName", provider?._source.name);
      appointmentform.setFieldValue("address", provider?._source.address);
    }
  }, [provider]);

  useEffect(() => {
    if (selectdate) {
      appointmentform.setFieldValue("dateStart", selectdate?.startStr);
      appointmentform.setFieldValue("dateEnd", selectdate?.endStr);
      setDisableContinue(false);
    }
  }, [selectdate]);

  const selectSlot = (location, slottime, postfix) => {
    setAppointment((prevAppointment) => {
      if (
        prevAppointment.location.id === location.id &&
        prevAppointment.timeSlot.time === slottime.time
      ) {
        return { ...prevAppointment, location: {}, timeSlot: {} };
      }
      return {
        ...prevAppointment,
        location: location,
        timeSlot: slottime,
        postfix: postfix,
      };
    });
  };

  // update service selection
  const handleDone = (event) => {
    log.info("addappt", event);
    switch (event) {
      case "metalab":
        dispatch(setAppServices(labsorder));
        break;
      case "metamd":
        log.info("addappt", docorder);
        dispatch(setAppServices(docorder));
        break;
      default:
        break;
    }
  };

  const getSpecificService = (servicetype) => {
    return (
      <div>
        <AppointmentProvider handleStepDone={handleStepDone} />
      </div>
    );
  };

  const handleStepDone = () => {
    log.info("appointment registration done");
    setDisableContinue(false);
  };

  const steps = [
    {
      label: "Who ?",
      component: <Registration handleStepDone={handleStepDone} />,
    },
    {
      label: "Reason for visit",
      component: (
        <InputServiceType
          form={appointmentform}
          disableContinue={setDisableContinue}
        />
      ),
    },
    {
      label: "Service provider",
      component: getSpecificService(serviceType),
    },
    {
      label: "Date and time",
      component: <MetaCalendar disableContinue={setDisableContinue} />,
    },
    {
      label: "Verify and submit",
      component: (
        <AppointmentInput
          inputform={appointmentform}
          disableContinue={setDisableContinue}
        />
      ),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setDisableContinue(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setDisableContinue(true);
  };

  const [alignment, setAlignment] = React.useState("web");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  log.info("appointment", appointmentform.values);

  return (
    <Grid container spacing={2}>
      <Grid item md={8}>
        {/*<Box className="text-color-error">{alertMsg}</Box>*/}

        {steps[activeStep].component}
      </Grid>

      <Grid item md={4}>
        <AppointmentStepper
          dataFields={appointmentform.values}
          setActiveNextStep={handleNext}
          setActiveBackStep={handleBack}
          steps={steps}
          setDisableContinue={setDisableContinue}
          disableContinue={disableContinue}
          handleSubmit={appointmentform.handleSubmit}
        />
      </Grid>
    </Grid>
  );
}
