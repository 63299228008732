import * as React from "react";

import { ContainerMD, usePatientContext } from "./ContainerMD";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import TimelineTwoToneIcon from "@mui/icons-material/TimelineTwoTone";
import AddAlertTwoToneIcon from "@mui/icons-material/AddAlertTwoTone";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import SwitchAccountTwoToneIcon from "@mui/icons-material/SwitchAccountTwoTone";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import AcUnitTwoToneIcon from "@mui/icons-material/AcUnitTwoTone";

import { getAge } from "../../common/ConstantsAndValues";
import LocalHospitalTwoToneIcon from "@mui/icons-material/LocalHospitalTwoTone";
import LocalPharmacyTwoToneIcon from "@mui/icons-material/LocalPharmacyTwoTone";
import GroupTwoToneIcon from "@mui/icons-material/GroupTwoTone";

const patientMenuOptions = [
  {
    _id: 550,
    icon: <GroupTwoToneIcon fontSize="small" />,
    title: "My association",
    value: null,
  },
  {
    _id: 551,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Hospital association",
    value: { departmentId: "metaphar", name: "" },
  },
  {
    _id: 551,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Department association",
    value: { departmentId: "metaphar", name: "" },
  },
];

export function PatientSelected() {
  const saved = localStorage.getItem("patient");
  const initialValue = JSON.parse(saved);
  const age = initialValue ? getAge(initialValue.dob) : "";

  const containerType = {
    id: initialValue ? initialValue.id : "patientInfo",
    label: initialValue ? initialValue.name : "Patient",
    detail: initialValue ? initialValue.sex.substr(0, 1) : "",
    age: age,
    type: "health-monitor",
    menuList: [
      {
        _id: "mdcondition",
        label: "Condition",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/patient/monitor",
      },
      {
        _id: "mdanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd/patient/analytics",
      },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/patient/notifications",
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/patient/messages",
      },
    ],

    // SIMILAR to one in HmoniUSR(props)  in PagesPatient.js  inside common
    // DIFFERNT LINKS
    //comboWidth: 200px,
    comboLabel: "CONDITION",
    comboList: [
      {
        _id: "ckd",
        label: "Kidney CKD",
        icon: <TableRowsTwoToneIcon />,
        link: "./monitor/ckd",
      },
      {
        _id: "dm",
        label: "Diabetes Mellitus",
        icon: <TimelineTwoToneIcon />,
        link: "./monitor/dm",
      },
      {
        _id: "ms",
        label: "Multiple Sclerosis",
        icon: <AddAlertTwoToneIcon />,
        link: "./monitor/ms",
      },
    ],
  };

  console.log("pat sel ct", patientMenuOptions);

  return (
    <ContainerMD
      searchOptions={patientMenuOptions}
      containerType={containerType}
    />
  );
}
