import * as React from "react";
import { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { DicomView } from "./DicomView";
import { PatientListPath, HeadUrl } from "../common/ConstantsAndValues";

export function DicomViewMD() {
  const navigate = useNavigate();
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const [markDates, dateTest, auth, eventType] = useOutletContext();

  // const saved = localStorage.getItem("patient");
  // const patient = JSON.parse(saved);
  //
  // localStorage.setItem("prevURL", JSON.stringify(HeadUrl));
  //
  // useEffect(() => {
  //     if (patient.id === null){
  //
  //         navigate(PatientListPath);
  //
  //     }
  // },[])

  return <DicomView />;
}
