import { v4 as uuidv4 } from "uuid";
import { browserName, browserVersion } from "react-device-detect";
import log from "../../services/log";
console.log("deviceinof", browserName);

export function sessionInfo() {
  let deviceId = localStorage.getItem("deviceId");
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem("deviceId", deviceId);
  }

  return {
    sessionId: deviceId,
    sessionInfo: {
      deviceName: browserName,
      deviceVersion: browserVersion,
    },
  };
}
