import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../CustomComponents/useStyles";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import { OtherTestsListRow } from "./OtherTestsListRow";
import { OtherTestsListHead } from "./OtherTestsListHead";
import {
  getRadOrderList,
  selectRadLabsRow,
} from "../../redux/labs/labs.actions";
import log from "../../services/log";

export function OtherTestsList(props) {
  const selectedPatient = useSelector((state) => state?.user?.selectedPatient);
  const othertestlist = useSelector((state) => state?.labs?.radlabsorderlist);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState(null);
  const [oTests, setOTests] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();

  const buildQuery = () => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata["patientId"] = selectedPatient?.metadata?.patientId;
  };

  useEffect(() => {
    dispatch(getRadOrderList(buildQuery())).then((r) => {
      if (r) {
        setPage({
          ...pagedata,
          pages: othertestlist?.totalPages,
          count: othertestlist?.totalResults,
        });
      }
    });
  }, [selectedPatient]);

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    dispatch(getRadOrderList(buildQuery())).then((r) => {
      if (r) {
        setPage({
          ...pagedata,
          pages: othertestlist?.totalPages,
          count: othertestlist?.totalResults,
        });
      }
    });
    setPage(newpagedata);
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (e, selectrow) => {
    log.info("othertestlist", e);
    if (selectrow) {
      setCheckedRows(e);
      dispatch(selectRadLabsRow(e));
    } else {
      setCheckedRows(null);
      dispatch(selectRadLabsRow(null));
    }
  };

  log.info("othertestlist", oTests);

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead>
              <OtherTestsListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {othertestlist &&
                othertestlist?.results.map((row) => (
                  <OtherTestsListRow
                    key={row.id}
                    row={row}
                    isRowSelected={checkedRows}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
