/**
 * filter org groups
 * @param p
 * @param s
 * @param user
 * @returns {{}}
 */
export const buildOrgGroupsQuery = (p, s, org) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p?.page;
  jsonData["limit"] = p?.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["orgId"] = org?.metadata?.accountId;
  return jsonData;
};
