import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { PatientPolicyList } from "../../insurance/PatientPolicyList";

export default function InsuInfoUSR() {
  return (
    <Grid container>
      <PatientPolicyList />
    </Grid>
  );
}
