import React from "react";
import { useRef, useEffect } from "react";
import ReactDOM from "react-dom";

import { Editor, Range } from "slate";
import { ReactEditor, useSlate } from "slate-react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import BoldButton from "../Buttons/BoldButton";
import ItalicButton from "../Buttons/ItalicButton";
import UnderlinedButton from "../Buttons/UnderlinedButton";
import StrikethroughButton from "../Buttons/StrikethroughButton";
import CodeButton from "../Buttons/CodeButton";
import BulletedListButton from "../Buttons/BulletedListButton";
import NumberedListButton from "../Buttons/NumberedListButton";

const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

const useEStyles = makeStyles((theme) => ({
  hoveringToolbar: {
    padding: theme.spacing(1 / 4),
    zIndex: 1,
    top: "-10000px",
    left: "-10000px",
    opacity: 0,
    backgroundColor: theme.palette.grey[200],
    transition: "opacity 0.75s",
  },
}));

/**
 * A hovering toolbar that is, a toolbar that appears over a selected text, and only when there is
 * a selection.
 *
 * If no children are provided it displays the following buttons:
 * Bold, italic, underlined, strike through and code.
 *
 * Children will typically be `ToolbarButton`.
 */
export default function HoveringToolbar({ children, className, ...props }) {
  const classes = useEStyles();
  const ref = useRef();
  const editor = useSlate();

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    ) {
      el.removeAttribute("style");
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();
    el.style.opacity = 1;
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight - 4}px`;
    el.style.left = `${
      rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
    }px`;
  });

  return (
    <Portal>
      <Box
        borderRadius="borderRadius"
        ref={ref}
        className={className ? className : classes.hoveringToolbar}
        {...props}
      >
        {!children && (
          <React.Fragment>
            <BoldButton />
            <ItalicButton />
            <UnderlinedButton />
            <StrikethroughButton />
            <CodeButton />
            <BulletedListButton />
            <NumberedListButton />
          </React.Fragment>
        )}
        {children && children}
      </Box>
    </Portal>
  );
}
