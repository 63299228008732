import React, { useEffect, useState } from "react";

const InstagramIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#19445E" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.820312 9C0.820312 5.40486 0.820312 3.60729 1.677 2.31255C2.05956 1.73439 2.5547 1.23925 3.13287 0.856692C4.4276 0 6.22517 0 9.82031 0C13.4155 0 15.213 0 16.5078 0.856692C17.0859 1.23925 17.5811 1.73439 17.9636 2.31255C18.8203 3.60729 18.8203 5.40486 18.8203 9C18.8203 12.5951 18.8203 14.3927 17.9636 15.6874C17.5811 16.2656 17.0859 16.7608 16.5078 17.1433C15.213 18 13.4155 18 9.82031 18C6.22517 18 4.4276 18 3.13287 17.1433C2.5547 16.7608 2.05956 16.2656 1.677 15.6874C0.820312 14.3927 0.820312 12.5951 0.820312 9ZM14.479 9.00316C14.479 11.5765 12.3929 13.6626 9.81957 13.6626C7.24625 13.6626 5.16016 11.5765 5.16016 9.00316C5.16016 6.42984 7.24625 4.34375 9.81957 4.34375C12.3929 4.34375 14.479 6.42984 14.479 9.00316ZM9.81957 12.0862C11.5223 12.0862 12.9026 10.7059 12.9026 9.00316C12.9026 7.30046 11.5223 5.92016 9.81957 5.92016C8.11687 5.92016 6.73656 7.30046 6.73656 9.00316C6.73656 10.7059 8.11687 12.0862 9.81957 12.0862ZM14.6612 5.20527C15.2659 5.20527 15.7561 4.7151 15.7561 4.11045C15.7561 3.50579 15.2659 3.01562 14.6612 3.01562C14.0566 3.01562 13.5664 3.50579 13.5664 4.11045C13.5664 4.7151 14.0566 5.20527 14.6612 5.20527Z"
        fill="#19445E"
      />
    </svg>
  );
};
export default InstagramIcon;
