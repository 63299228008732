import * as yup from "yup";

export const userRegisterSchema = yup.object({
  orgId: yup.string().required("org is required"),
  groupId: yup.string().required("org group is required"),
  departmentId: yup.string().required("org department is required"),
  patientId: yup.string().required("patient is required"),
  email: yup.string().required("patient email is required"),
  providerId: yup.string(),
  providerRole: yup.string(),
  loincNumber: yup.string(),
  name: yup.string().required("name is required"),
  dob: yup.string(),
  status: yup.string().required("status is required"),
});

export const userAccountSchema = yup.object({
  email: yup.string().email().required("email is required"),
  role: yup.string().required("role is required"),
  password: yup.string(),
  confirmpassword: yup
    .string()
    .when("password", (password, field) =>
      password ? field.required().oneOf([yup.ref("password")]) : field
    ),
  name: yup.string().required("name is required"),
  accountId: yup.string(),
  login: yup.string(),
  phone: yup.string(),
  isEmailVerified: yup.boolean(),
});

export const userSigninSchema = yup.object({
  email: yup.string().email().required("email is required"),
  passowrd: yup.string(),
  username: yup.string(),
  showpassword: yup.boolean(),
});

export const userProfileSchema = yup.object({
  accountId: yup.string().required("user is required"),
  email: yup.string().email().required("email is required"),
  name: yup.string(),
  dob: yup.string(),
  gender: yup.string(),
  phone: yup.string(),
  mobile: yup.string(),
  emailpref: yup.boolean(),
  textpref: yup.boolean(),
  status: yup.string(),
});
