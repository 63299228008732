import {
  Box,
  IconButton,
  Typography,
  ThemeProvider,
  Tooltip,
} from "@mui/material";

import * as React from "react";
import { useMetaTheme } from "../theme/ThemeContext";
import log from "../../services/log";
import { useLocation, useNavigate } from "react-router-dom";

export function InnerNavIconButton(props) {
  const { onClick, enState, menu } = props;
  const { theme } = useMetaTheme();

  const navigate = useNavigate();

  const handleClick = () => {
    if (menu._id === 10029) {
      navigate("/metauser/home");
    }
  };

  const location = useLocation();

  const currentPath = location.pathname;

  return (
    <ThemeProvider theme={theme}>
      <Tooltip title={menu?.title}>
        <IconButton
          aria-label="expand row"
          size="small"
          color="primary"
          // onClick={() => navigate("/metauser/profile/home")}
          onClick={handleClick}
          sx={{
            padding: "0px",
            "&:hover": {
              backgroundColor: "transparent",
              svg: {
                fill: (theme) => theme?.palette?.primary?.light,
              },
            },
            "&:focus": {
              outline: "none",
              border: 0,
            },
          }}
        >
          <Box
            display="flex"
            className={
              currentPath === "/metauser/home" && menu._id === 10029
                ? "homeSelected"
                : ""
            }
            alignItems="left"
            sx={{
              padding: 0.5,
              display: "flex",
              alignItems: "center",
              svg: { fill: "#404040" },
              "&:hover": {
                svg: { fill: (theme) => theme?.palette?.primary?.light },
              },
            }}
          >
            {menu?.icon}
            <Typography
              variant="body2"
              marginLeft={1}
              sx={{
                color: menu?.isOpen
                  ? theme.palette.primary.main
                  : theme.palette.gray.main,
                fontSize: "16px",
              }}
            >
              {menu?.label}
            </Typography>
          </Box>
        </IconButton>
      </Tooltip>
    </ThemeProvider>
  );
}
