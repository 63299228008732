import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDrugsOrderlist,
  selectPatientMedcine,
} from "../../redux/drugs/drugs.actions";
import { getMedicine } from "../../redux/actions/reports";
import { Title } from "../common/Title";
import { pageOptions, SearchBoxStandard } from "../common/ConstantsAndValues";
import useStyles from "../CustomComponents/useStyles";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import { MedListHead } from "./PrescMedListPHAHead";
import { MedListRow } from "./PrescMedListPHARow";
import { build2PatientlistQuery } from "../dbcq/patientlistdb";
import log from "../../services/log";

export function PrescMedListPHA(props) {
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const group = useSelector((state) => state?.drugs?.pharmagroup);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [medicines, setMedicines] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const resetpage = { ...pagedata, page: 0 };
    const jsonData = build2PatientlistQuery(resetpage, searchcontext, group);
    dispatch(getDrugsOrderlist(jsonData)).then((r) => {
      log.info("medicineorder list: ", r);
      if (r.results) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  }, [dispatch, searchcontext]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = medicines.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(medicines);
    }
  };

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const jsonData = build2PatientlistQuery(newpagedata, searchcontext, group);
    dispatch(getDrugsOrderlist(jsonData)).then((r) => {
      log.info("medicineorder list: ", r);
      if (r.results) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  //log.info("medicinelist111111", medicinelist );

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead className="bg-standard-lightest text-md">
              <MedListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {medicines &&
                medicines.map((row) => <MedListRow key={row.id} row={row} />)}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
