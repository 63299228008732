import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
import { ThemeTabs } from "../../tabs/ThemeTabs";

import { RefreshIcon } from "../../customIcons/RefreshIcon";
import {
  FirstPageIcon,
  LastPageIcon,
  NextPageIcon,
  PreviousPageIcon,
  SearchTableIcon,
} from "../../customIcons/TableIcons";
import { useRef, useState } from "react";
import { PlusIcon } from "../../customIcons/PlusIcon";
import {
  customStylesFieldHover,
  formattedArray,
  customStylesFilter,
} from "../../tabs/TabsStaticData";
import Select from "react-select";
import { MetaQuillEditor } from "../../editor/MetaQuillEditor";
import useOnClickOutside from "../../../utils/use-on-click-outside";
import { MetaSearchIcon } from "../../customIcons/MetaSearchIcon";
import { ChevronDownBlueIcon } from "../../customIcons/ChevronDownBlueIcon";
import { AttachmentIcon } from "../../customIcons/AttachmentIcon";

export const Inbox = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  // outside click for profile dropdown
  const squareBoxRef = useRef(null);

  const clickOutsidehandler = (event) => {
    event.stopPropagation();
    setShowFilters(false);
  };
  useOnClickOutside(squareBoxRef, clickOutsidehandler);

  const [messageList, setMessageList] = useState([
    {
      type: 0,
      name: "Dr. Aamir Khan",
      message: "Request for Consultation or Appointment",
      date: "12 October, 2023",
    },
    {
      type: 0,
      name: "Dr. Aamir Khan",
      message: "Request for Consultation or Appointment",
      date: "12 October, 2023",
    },
    {
      type: 0,
      name: "Dr. Aamir Khan",
      message: "Request for Consultation or Appointment",
      date: "12 October, 2023",
    },
    {
      type: 0,
      name: "Dr. Aamir Khan",
      message: "Request for Consultation or Appointment",
      date: "12 October, 2023",
    },
    {
      type: 0,
      name: "Dr. Aamir Khan",
      message: "Request for Consultation or Appointment",
      date: "12 October, 2023",
    },
    {
      type: 1,
      name: "Dr. Aamir Khan",
      message: "Request for Consultation or Appointment",
      date: "12 October, 2023",
    },
    {
      type: 1,
      name: "Dr. Aamir Khan",
      message: "Request for Consultation or Appointment",
      date: "12 October, 2023",
    },
    {
      type: 1,
      name: "Dr. Aamir Khan",
      message: "Request for Consultation or Appointment",
      date: "12 October, 2023",
    },
    {
      type: 1,
      name: "Dr. Aamir Khan",
      message: "Request for Consultation or Appointment",
      date: "12 October, 2023",
    },
    {
      type: 1,
      name: "Dr. Aamir Khan",
      message: "Request for Consultation or Appointment",
      date: "12 October, 2023",
    },
  ]);

  const doctorList = [
    { label: "Dr Mahesh", value: "Dr Mahesh" },
    { label: "Dr Ramesh", value: "Dr Ramesh" },
  ];

  const dateLabelFilter = [
    {
      label: "13 October Health check-up",
      value: "13 October Health check-up",
    },
    {
      label: "15 October Tests",
      value: "15 October Tests",
    },
  ];
  return (
    <Box>
      <Box sx={{ mx: 1, display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "22px",
            color: "#404040",
            fontFamily: "DM SANS",
          }}
        >
          View all incoming messages
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            onClick={() => setShowSearch(!showSearch)}
            sx={{
              mt: 1.5,
              // mb: 2,
              mx: 1,
              display: "flex",
              cursor: "pointer",
            }}
          >
            {showSearch ? (
              <ChevronDownBlueIcon size={0.7} />
            ) : (
              <SearchTableIcon size={0.6} />
            )}
            <Typography
              sx={{
                color: showSearch ? "#2A8AA7" : "#585858",
                fontFamily: "DM SANS",
                fontWeight: "500",
              }}
            >
              Search Inbox
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 1.5,
              // mb: 2,
              mx: 1,
              display: "flex",
              cursor: "pointer",
            }}
          >
            <RefreshIcon size={0.8} />
            <Typography
              sx={{
                color: "#585858",
                fontFamily: "DM SANS",
                fontWeight: "500",
              }}
            >
              Refresh
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 0.8,
              // mb: 2,
              mx: 1,
              display: "flex",
              cursor: "pointer",
              border: "1px solid #585858",
              padding: "3px 12px",
              borderRadius: "25px",
            }}
          >
            <Typography
              sx={{
                color: "#585858",
                fontFamily: "DM SANS",
                fontWeight: "500",
              }}
            >
              Compose new
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography
        sx={{
          mx: 1,

          color: "#585858",
          fontFamily: "DM SANS",
        }}
      >
        Last updated on 12 October, 2023 at 11:59 pm
      </Typography>

      {showSearch && (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              left: "15px",
              top: "20px",
            }}
          >
            <MetaSearchIcon size={0.7} />
          </Box>
          <input className="meta__input__search" placeholder="Search" />
        </Box>
      )}

      <Box sx={{ mb: 2, mt: 2, position: "relative" }}>
        <div className="yellowlabel" onClick={() => setShowFilters(true)}>
          <p className="mb-0">Add a filter</p> <PlusIcon size={0.45} />
        </div>
        {showFilters && (
          <div className="filterBox" ref={squareBoxRef}>
            <Box>
              <FormLabel
                sx={{
                  fontSize: "14px",
                  marginLeft: 1,
                  fontFamily: "DM SANS",
                  marginBottom: 0,
                }}
              >
                Provider Category
              </FormLabel>
            </Box>
            <Box>
              <FormControlLabel
                sx={{ margin: "0 !important", fontSize: "15px" }}
                control={<Checkbox size="small" />}
                label="Doctor"
              />
            </Box>
            <Box>
              <FormControlLabel
                sx={{ margin: "0 !important", fontSize: "15px" }}
                control={<Checkbox size="small" />}
                label="Clinic"
              />
            </Box>
            <Box>
              <FormControlLabel
                sx={{ margin: "0 !important", fontSize: "15px" }}
                control={<Checkbox size="small" />}
                label="Laboratory"
              />
            </Box>
            <Box>
              <FormControlLabel
                sx={{ margin: "0 !important", fontSize: "15px" }}
                control={<Checkbox size="small" />}
                label="Pharmacy"
              />
            </Box>
            <Box>
              <FormLabel
                sx={{ fontSize: "15px", marginLeft: 1, fontFamily: "DM SANS" }}
              >
                Provider Name
              </FormLabel>
            </Box>
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              isMulti
              className=""
              placeholder={"Select a Person"}
              onChange={(value) => {
                // setSpeciality(value.value);
              }}
              styles={customStylesFilter}
              isClearable={true}
              isSearchable={true}
              // isLoading={providerListLoading}
              options={formattedArray(doctorList)}
            />
            <Box>
              <FormLabel
                sx={{
                  fontSize: "15px",
                  marginLeft: 1,
                  marginTop: 1,
                  fontFamily: "DM SANS",
                }}
              >
                Label
              </FormLabel>
            </Box>
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              isMulti
              className=""
              placeholder={"Select a Filter"}
              onChange={(value) => {
                // setSpeciality(value.value);
              }}
              styles={customStylesFilter}
              isClearable={true}
              isSearchable={true}
              // isLoading={providerListLoading}
              options={formattedArray(dateLabelFilter)}
            />
            <button
              className={`${"meta__button_orange"} mt-3 mb-2 w-p-100`}
              // onClick={() => setCurrentStep(2)}
            >
              Apply Filter
            </button>
          </div>
        )}
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                Name
              </TableCell>
              <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                Subject
              </TableCell>
              <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                Received On
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontFamily: "DM SANS", padding: "5px" }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messageList.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontWeight: row.type === 0 ? "600" : "500",
                    fontFamily: "DM SANS",
                    fontSize: "15px",
                    padding: "10px 5px",
                  }}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: row.type === 0 ? "600" : "500",
                    fontFamily: "DM SANS",
                    fontSize: "15px",
                    padding: "10px 5px",
                  }}
                >
                  {row.message}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: row.type === 0 ? "600" : "500",
                    fontFamily: "DM SANS",
                    fontSize: "15px",
                    padding: "10px 5px",
                  }}
                >
                  {row.date}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: "DM SANS",
                    fontSize: "15px",
                    padding: "10px 5px",
                  }}
                  align="right"
                >
                  <Link color={"#2A8AA7"} href="#">
                    {"View Message"}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          paddingTop: "1.5rem",
          borderTop: `1px solid #e9e9e9`,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box sx={{ mx: 1, cursor: "pointer" }}>
          <FirstPageIcon />
        </Box>
        <Box sx={{ mx: 2, cursor: "pointer" }}>
          <PreviousPageIcon />
        </Box>
        <Typography
          sx={{
            paddingTop: "2px",
            fontFamily: "DM SANS",
            fontSize: (theme) => theme.typography.button.fontSize,
          }}
        >
          Page
          <Box
            sx={{
              display: "inline",
              border: "1px solid #C7C7C7",
              px: 0.6,
              borderRadius: "4px",
            }}
          >
            1
          </Box>
          / 22
        </Typography>
        <Box sx={{ mx: 2, cursor: "pointer" }}>
          <NextPageIcon />
        </Box>
        <Box sx={{ mx: 1, cursor: "pointer" }}>
          <LastPageIcon />
        </Box>
      </Box>
    </Box>
  );
};
export const Sent = () => {
  return (
    <Box sx={{ mx: 1, display: "flex", justifyContent: "space-between" }}>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "22px",
          color: "#404040",
          fontFamily: "DM SANS",
        }}
      >
        View all sent messages
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            mt: 1,
            mb: 2,
            mx: 1,
            display: "flex",
            cursor: "pointer",
          }}
        >
          <SearchTableIcon size={0.6} />
          <Typography
            sx={{
              color: "#585858",
              fontFamily: "DM SANS",
              fontWeight: "500",
            }}
          >
            Search sent
          </Typography>
        </Box>

        <Box
          sx={{
            mt: 0.8,
            mb: 2,
            mx: 1,
            display: "flex",
            cursor: "pointer",
          }}
        >
          <RefreshIcon size={0.8} />
          <Typography
            sx={{
              color: "#585858",
              fontFamily: "DM SANS",
              fontWeight: "500",
            }}
          >
            Refresh
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export const Compose = () => {
  const doctorList = [
    { label: "Dr Mahesh", value: "Dr Mahesh" },
    { label: "Dr Ramesh", value: "Dr Ramesh" },
  ];
  return (
    <Box>
      <Box sx={{ mx: 1 }}>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "22px",
            color: "#404040",
            fontFamily: "DM SANS",
          }}
        >
          Compose a new message
        </Typography>
      </Box>
      <Typography
        sx={{
          mx: 1,
          mt: 1,
          width: "60%",
          color: "#585858",
          fontFamily: "DM SANS",
        }}
      >
        If you're having a medical emergency, please call your doctor or visit
        the nearest hospital. Do not attempt to access emergency care through
        this website.
      </Typography>
      <Grid container sx={{ flexGrow: 1, mt: 2, mx: 2 }}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
            <span className="required_dot"></span>
            To
          </FormLabel>
          <Box sx={{ width: "95%" }}>
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              className=""
              placeholder={"Select a Person"}
              onChange={(value) => {
                // setSpeciality(value.value);
              }}
              styles={customStylesFieldHover}
              isClearable={true}
              isSearchable={true}
              // isLoading={providerListLoading}
              options={formattedArray(doctorList)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
          <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
            <span className="required_dot"></span>
            From
          </FormLabel>
          <Box sx={{ width: "95%" }}>
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              className=""
              placeholder={"Select a Person"}
              onChange={(value) => {
                // setSpeciality(value.value);
              }}
              styles={customStylesFieldHover}
              isClearable={true}
              isSearchable={true}
              // isLoading={providerListLoading}
              options={formattedArray(doctorList)}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ flexGrow: 1, mt: 2, mx: 2 }}>
        <Grid item xs={12} sm={6} md={6} lg={9}>
          <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
            <span className="required_dot"></span>
            Subject
          </FormLabel>
          <textarea className="meta__textarea" rows={1}></textarea>
        </Grid>
      </Grid>

      <Grid container sx={{ flexGrow: 1, mt: 2, mx: 2 }}>
        <Grid item xs={12} sm={6} md={6} lg={9}>
          <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
            <span className="required_dot"></span>
            Message
          </FormLabel>
          <MetaQuillEditor />
        </Grid>
      </Grid>
      <Box container sx={{ mt: 1, mx: 2, display: "flex" }}>
        <Box sx={{ mt: 0.4 }}>
          <AttachmentIcon size={0.9} />
          <span className="attachfile_text">
            You may attach up to three files. Accepted file types up to 5 MB
            (jpg, png, pdf)
          </span>
        </Box>
      </Box>
      <Box sx={{ display: "flex", mx: 2 }}>
        <button
          className={`${"meta__button_orange"} mt-4`}
          // onClick={() => setCurrentStep(2)}
        >
          Send message
        </button>
        <button
          className="meta__button_green_outlined mt-4 ml-3"
          // onClick={() => setCurrentStep(0)}
        >
          Save draft
        </button>
      </Box>
    </Box>
  );
};

function Messages() {
  const tabs = [
    { id: 1009, title: "Inbox", notify: true },
    { id: 1010, title: "Sent" },
    { id: 1011, title: "Compose" },
  ];
  return (
    <>
      <ThemeTabs tabs={tabs} />
    </>
  );
}
export default Messages;
