import * as React from "react";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  colWidthSign,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import { MyButtons } from "../../CustomComponents/MyButtons";
import { MedicineListMDD } from "../../medicine/MedicineListMDD";
import { getPatientDrugs } from "../../../redux/drugs/drugs.actions";
import { selectedPatient } from "../../../redux/user/user.actions";

import log from "../../../services/log";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export function MedicineListMD() {
  const user = useSelector((state) => state?.auth?.user?.user);
  const selectedPatient = useSelector((state) => state?.user?.selectedPatient);
  const medicines = useSelector((state) => state?.drugs?.patientlist);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(medicines);
  const [checkedRows, setCheckedRows] = useState(null);
  const [disablePres, setDisablePres] = useState(false);
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = medicines.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(medicines);
    }
  };

  const handleChangeRows = (event, value) => {
    //pagination part
    setPage({ ...pagedata, limit: value });
  };

  const handleRowClick = (e) => {
    var updatedList = [...checkedRows];

    if (findArrayElement(updatedList, e)) {
      updatedList.splice(checkedRows.indexOf(e), 1);
    } else {
      updatedList = [...checkedRows, e];
    }

    setCheckedRows(updatedList);
  };

  const handlePrescribe = () => {
    console.log("Prescribe/Repeat", checkedRows);
  };

  const findArrayElement = function (array, title) {
    return array.find((item) => {
      return item === title;
    });
  };

  // log.info("medicinelist rows", selectedPatient);

  return (
    <Grid container>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div></div>
        <div>
          <MyButtons
            onClick={handlePrescribe}
            btnKey={"prescribedrug"}
            btnLabel={"Prescribe"}
            enState={false}
          />
        </div>
      </div>

      <MedicineListMDD medicinelist={rows} />
    </Grid>
  );
}
