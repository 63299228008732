// import axios from "axios";
import { API_URL } from "./Constants";
import { axiosApiInstance } from "./axiosmiddleware";

const getLedgerListService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "ledger/list", payload);
};

const setLedgerListService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "ledger/set", payload);
};

const updateLedgerListService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "ledger/update", payload);
};

export { getLedgerListService, setLedgerListService, updateLedgerListService };
