import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { HealthPolicyList } from "../../insurance/HealthPolicyList";

export default function InsuCompareUSR() {
  const handleRowsDetail = (policy) => {
    console.log("Get Details of ", policy);
  };

  return (
    <Grid container>
      <HealthPolicyList />
    </Grid>
  );
}
