import * as React from "react";
import {
  Box,
  Paper,
  TableContainer,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getAddress } from "../../../redux/actions/locs";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from "../../../redux/actions/auth";

import ProfileUser from "../../profile/components/ProfileUserUSR";
import ProfileListData from "../../profile/components/ProfileListDataUSR";
import ThreeDotProfile from "../../profile/components/ThreedotProfile";
import { AddressInputPart } from "../../forms/AddressInputPartUSR";
import Registration from "./RegistrationUSR";

import log from "../../../services/log";

export function ProfileListUSR(props) {
  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );
  const profile = useSelector((state) => state?.auth?.profile);
  const address = useSelector((state) => state?.locs?.address);

  const dispatch = useDispatch();
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });

  const [openState, setOpenState] = useState("");
  // const [openUserProfile, setUserProfile]      = useState(false);
  const [openUserAddress, setUserAddress] = useState(false);

  const buildQuery = () => {
    const jsonData = {};
    jsonData.metadata = {};
    jsonData["sortBy"] = "updated_at:desc";
    jsonData["page"] = pagedata.page;
    jsonData["limit"] = pagedata.limit;
    jsonData.metadata.userId = user?.metadata.accountId;
    jsonData.metadata.role = "metauser";
    return jsonData;
  };
  // fetch address
  useEffect(() => {
    if (user) {
      // log.info("profilelist query", jsonData);
      dispatch(getAddress(buildQuery()));
    }
  }, [user, dispatch]);

  // fetch profile
  useEffect(() => {
    if (user) {
      dispatch(getProfile(buildQuery())).then((r) => {
        log.info("md profile", r);
        if (r.results) {
          setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
        }
      });
    }
  }, [user, dispatch]);

  const handleAddressUpdate = () => {
    setUserAddress(true);
  };

  const handleClose = () => {
    setOpenState("");
  };

  return (
    <Box>
      <Box className={"colfr"}>
        <ThreeDotProfile openState={setOpenState} />
      </Box>
      &nbsp;
      <Box>
        {openState === "updateAddress" ? (
          <AddressInputPart cancel={handleClose} />
        ) : openState === "updateProfile" ? (
          <Registration
            //handleClose={handleClose}
            handleStepDone={handleClose}
          />
        ) : (
          <Box>
            <TableContainer component={Paper}>
              {profile && <ProfileUser privacy={false} label={"Personal"} />}
              {address && (
                <ProfileListData
                  privacy={false}
                  label={"Address"}
                  updateCallback={handleAddressUpdate}
                />
              )}
            </TableContainer>
          </Box>
        )}
      </Box>
    </Box>
  );
}
