import React, { useState, useEffect, useLayoutEffect } from "react";

import { Grid, Box, Typography, Container, Stack } from "@mui/material";
import FoundationTwoToneIcon from "@mui/icons-material/FoundationTwoTone";
import { SelectOptionsAuto } from "../common/SelectOptions";
import { Title } from "../common/Title";
import { tableParam, colChart } from "../common/ConstantsAndValues";
import { useDispatch, useSelector } from "react-redux";
import log from "../../services/log";
import Select from "react-select";

import { getOrgs, selectOrg } from "../../redux/actions/orgs";
import { MyTooltipButtons, MyHelpButtons } from "../CustomComponents/MyButtons";

export default function OrgsSelectList(props) {
  const { cancelEventPage, title } = props;
  const user = useSelector((state) => state?.auth?.user?.user);
  const orgs = useSelector((state) => state?.orgs?.orgs);
  const selectorg = useSelector((state) => state?.orgs?.selectorg);

  const [selectedOption, setSelectedOption] = useState(
    selectorg ? { label: selectorg.orgName, value: selectorg } : null
  );
  const [options, setOptions] = useState([
    { label: "test", value: "something" },
  ]);
  const [err, setErr] = useState("");
  const dispatch = useDispatch();

  const errMsg = "Selection required";

  useEffect(() => {
    if (user) {
      dispatch(getOrgs({ metadata: { userId: user.metadata.accountId } }));
      // .then((r) => {log.info("org list", r)});
    }
  }, [user]);

  useEffect(() => {
    let newoptions = [];
    if (orgs && orgs.length > 0) {
      orgs.forEach((option) =>
        newoptions.push({ label: option.orgName, value: option })
      );
      // console.log("Selected options",newoptions);
      setOptions(newoptions);
    } else {
      setOptions(newoptions);
    }
  }, [orgs]);

  function handleSelect(event) {
    // log.info("orgs handle select", event);
    setSelectedOption(event);
  }

  const handleDone = () => {
    // console.log("Selected options", selectedOptions);
    if (!selectedOption) {
      setErr(errMsg);
      return;
    }
    setErr("");
    dispatch(selectOrg(selectedOption.value));
    cancelEventPage();
  };

  // log.info("orgs select", options);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%", md: "60%", lg: "50%" },
        }}
      >
        <Box mb={1}>
          <div className="colfl0">
            <Title>{title ? title : ""} </Title>
          </div>
          <div className="colfr0">
            <MyTooltipButtons
              onClick={cancelEventPage}
              btnKey={"cancel"}
              btnLabel={"Cancel"}
              enState={false}
            />
          </div>
        </Box>
        <Box className="clear text-color-error" mb={1}>
          {err}
        </Box>
        <Box className="text-color">
          <SelectOptionsAuto
            openPage={handleSelect}
            data={options}
            multi={false}
            title={"organization"}
            wide={0}
          />
        </Box>
        <Box className="clear" />
        <Box mt={2} pt={2} className="border-dbt">
          <Box className="colfr0">
            <MyHelpButtons
              onClick={handleDone}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
              btnTooltip={"Add"}
            />
          </Box>
          <Box className="clear" />
        </Box>
        <Box className="clear" />
      </Grid>
    </Grid>
  );
}
