import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import useStyles from "../CustomComponents/useStyles";

import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";

import { selectDrugPresListRow } from "../../redux/drugs/drugs.actions";
import { MedicinePresListRow } from "./MedicinePresListRow";

import log from "../../services/log";
import { MedicinePresListHead } from "./MedicinePresListHead";

export function MedicinePresList(props) {
  const { medicinelist } = props;

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [medicines, setMedicines] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    // log.info("medicinelist", medicinelist.results)
    if (medicinelist) {
      setMedicines(medicinelist);
    }
  }, [medicinelist]);

  const handleChangePage = (event, value) => {
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    setPage(newpagedata);
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };
  const handleRowClick = (e, selectrow) => {
    log.info("medicinelist", e);
    if (selectrow) {
      setCheckedRows(e);
      dispatch(selectDrugPresListRow(e));
    } else {
      setCheckedRows(null);
      dispatch(selectDrugPresListRow(null));
    }
  };

  log.info("medicinelist", medicines);

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead className="">
              <MedicinePresListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {medicines &&
                medicines?.map((row) => (
                  <MedicinePresListRow
                    key={row.metadata.medicineId}
                    row={row}
                    isRowSelected={checkedRows}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
