export const providerquery = () => {
  let jsonData = {};
  let wildcard = { appTypeKeywords: "*" };
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "apptypes";
  jsonData.params["size"] = 20;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool["must"] = [];
  jsonData.params.query.bool.must.push({ wildcard: wildcard });
  return jsonData;
};

export const reasonquery = (value) => {
  let jsonData = {};
  let wildcard = { appTypeKeywords: "*" };
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "conditions";
  jsonData.params["size"] = 10;
  jsonData.params["query"] = {};
  jsonData.params.query["match_phrase_prefix"] = {};
  jsonData.params.query.match_phrase_prefix["conditionKeywords"] = {};
  jsonData.params.query.match_phrase_prefix.conditionKeywords["query"] = value;
  return jsonData;
};
