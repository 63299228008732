import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Grid } from "@mui/material";
import enUS from "date-fns/locale/en-US";
import FullCalendar, { formatDate, ViewContentArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import { CalendartypeADM_TODO } from "./CalendartypeADM_TODO";
import { CalendartypeADM_APPT } from "./CalendartypeADM_APPT";
import { CalendartypeUSR_TODO } from "./CalendartypeUSR_TODO";
import { CalendartypeUSR_APPT } from "./CalendartypeUSR_APPT";
import { CalendartypeMD_TODO } from "./CalendartypeMD_TODO";
import { CalendartypeMD_APPT } from "./CalendartypeMD_APPT";
import { CalendartypeORG_TODO } from "./CalendartypeORG_TODO";
import { CalendartypeORG_APPT } from "./CalendartypeORG_APPT";
import { CalendartypeLAB_TODO } from "./CalendartypeLAB_TODO";
import { CalendartypeLAB_APPT } from "./CalendartypeLAB_APPT";
import { CalendartypePHA_TODO } from "./CalendartypePHA_TODO";
import { CalendartypePHA_APPT } from "./CalendartypePHA_APPT";
import { CalendartypeFIN_TODO } from "./CalendartypeFIN_TODO";
import { CalendartypeFIN_APPT } from "./CalendartypeFIN_APPT";
import { CalendartypeOS_TODO } from "./CalendartypeOS_TODO";
import { CalendartypeOS_APPT } from "./CalendartypeOS_APPT";
import { Calendartype } from "./Calendartype";

import log from "../../services/log";

export class MetaCalendarADM_APPT extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeADM_APPT
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}

export class MetaCalendarADM_TODO extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeADM_TODO
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}

export class MetaCalendarUSR_TODO extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeUSR_TODO
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}

export class MetaCalendarUSR_APPT extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeUSR_APPT
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}

export class MetaCalendarMD_TODO extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeMD_TODO
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}
export class MetaCalendarMD_APPT extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeMD_APPT
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}

export class MetaCalendarORG_TODO extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeORG_TODO
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}

export class MetaCalendarORG_APPT extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeORG_APPT
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}

export class MetaCalendarLAB_TODO extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeLAB_TODO
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}
export class MetaCalendarLAB_APPT extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeLAB_APPT
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}

export class MetaCalendarPHA_TODO extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypePHA_TODO
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}
export class MetaCalendarPHA_APPT extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypePHA_APPT
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}

export class MetaCalendarFIN_TODO extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeFIN_TODO
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}
export class MetaCalendarFIN_APPT extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeFIN_APPT
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}

export class MetaCalendarOS_TODO extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeOS_TODO
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}
export class MetaCalendarOS_APPT extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;
    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <CalendartypeOS_APPT
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}

/**
 * view provider calendar
 */

export class MetaCalendar extends React.Component {
  render() {
    const plugins = [listPlugin];
    const actPage = this.props.actPage;

    return (
      <Grid container width="100%" sx={{ overflow: "hidden" }}>
        <Calendartype
          plugin={plugins}
          view={"lalpath"}
          actPage={actPage}
          showSlots={false}
        />
      </Grid>
    );
  }
}

function reportNetworkError() {
  alert("This action could not be completed");
}
