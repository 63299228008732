import React from "react";
import { Page, Text, Document, StyleSheet } from "@react-pdf/renderer";
import { CommonHeader } from "./CommonHeader";
import { InvoiceTitle } from "./InvoiceTitle";
import { CommonInvoiceAddr } from "./CommonAddrAddr";
import { CommonSerialNo } from "./CommonSerialNo";
import { InvoiceItemsTable } from "./InvoiceItemsTable";
import { InvoiceSign } from "./InvoiceSign";
import { InvoiceDeclaration } from "./InvoiceDeclaration";

import { stylesLogo } from "../styles/CommonStyles";

import { tableParam } from "../../common/ConstantsAndValues";
import logo from "../../../assets/images/logo.png";
import logoSm from "../../../assets/images/logoSm.png";

const styles = stylesLogo;
const countTR = tableParam.tableRowsCount ? tableParam.tableRowsCount : 15;
const onRenderDocument = ({ blob, filename }) => {
  var blobUrl = URL.createObjectURL(blob);
  //save
};

const Invoice = ({ title, content }) => (
  <Document
    title="Invoice"
    author="Metalane"
    subject="Online generated document"
    keywords="Metalane online pdf document"
    creator="Metalane  webapp"
    file="invoice"
  >
    <Page size="A4" style={styles.page} title={title} author={"Metalane"} wrap>
      <CommonHeader logo={logo} invoice={content.parent[0]} />
      <InvoiceTitle title={title} />
      <CommonSerialNo invoice={content} label1={"Invoice No"} label2={"Date"} />
      <CommonInvoiceAddr
        invoice={content}
        parentAddress={content.parent[0]}
        label1={"Bill to"}
        label2={""}
      />
      <InvoiceItemsTable tableRowsCount={countTR} invoice={content.items} />
      <InvoiceSign
        txtDeclare={content.invoice_declare}
        txt={content.invoice_sign}
      />
      <InvoiceDeclaration
        txtDeclare={"No signature needed being computer generated Invoice"}
      />
      <Text
        style={styles.pageNos}
        render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default Invoice;
