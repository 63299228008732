import React, { useEffect, useMemo, useState } from "react";
import {
  createMaterialEditor,
  HoveringToolbar,
  MaterialEditable,
  MaterialSlate,
  Toolbar,
} from "./index";
import { Grid, Paper, Typography } from "@mui/material";
import { CnParam } from "../common/ConstantsAndValues";
import log from "../../services/log";

export const TextEditorView = (props) => {
  const { addToMsg } = props;
  const [value, setValue] = useState(addToMsg ? JSON.parse(addToMsg) : []);
  const editor = useMemo(() => createMaterialEditor(props.editorId), []);
  const indicatorColor = "#FDFDFE";

  const handleChangeNotes = (value) => {
    // setValue(value)
    // addTextEditor(value)
  };

  // log.info("textview", props);
  // value.map(v => log.info("textview", v))

  log.info("textview vsl", value);
  return (
    <Grid container sx={{ border: "none" }}>
      <Grid item>
        <Typography variant={"body2"}></Typography>
      </Grid>

      <Paper
        style={{
          minHeight: CnParam.minHeight,
          maxHeight: CnParam.maxHeight,
          height: "auto",
          width: "100%",
          overflowX: "hidden",
          background: indicatorColor,
        }}
      >
        <MaterialSlate
          value={value}
          editor={editor}
          onChange={handleChangeNotes}
          className="editor"
        >
          <MaterialEditable key="k1" readOnly={true} isColm={false} />
        </MaterialSlate>
      </Paper>
    </Grid>
  );
};
