import * as React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { UploadJsonFile } from "../../docfiles/UploadJsonFileInsuORG";

export default function UploadsInsuORG() {
  return (
    <Grid container>
      <Grid item xs p={4} className="border-db">
        <center>
          <UploadJsonFile />
        </center>
      </Grid>
    </Grid>
  );
}
