import React from "react";
import { useEffect, useState } from "react";
import { Grid, Box, Typography, Container } from "@mui/material";
import { Title } from "../common/Title";
import { UploadFile } from "../common/UploadFile";
import { FileUploadPage } from "../common/FileUploadPage";
import { SelectOptions } from "../common/SelectOptions";
import InputComponent from "../CustomComponents/InputComponent";
import {
  DocIdentityProof,
  DocAddressProof,
  colChart,
} from "../common/ConstantsAndValues";
import { MyHelpButtons } from "../CustomComponents/MyButtons";

export default function KYCInputs(props) {
  const { handleAddSave, handleCancel, action } = props;

  const [privateKey, setPrivateKey] = useState("");
  const [values, setValues] = useState({});
  const [disable, setDisable] = useState(true);

  const changeSubmit = (file) => {
    console.log("file1", file);
    changeSubmitCommon(file);
  };
  const changeSubmit2 = (file) => {
    console.log("file2", file);
    changeSubmitCommon(file.name);
  };

  async function changeSubmitCommon(file) {
    const formData = new FormData();
    formData.append("file", file);
    //https://metalane.ai/upload
    try {
      const response = await fetch("E:metauxmetauxmetappsrccomponentscommon", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("File upload is  successfully");
      } else {
        alert("Failed to upload the file due to errors");
      }
    } catch (error) {
      console.error("Erroruploading:", error);
      alert("Error occurred while uploading the file");
    }
  }

  const handleChangePan = (event) => {
    const ev = event.target.value;
    const em = ev ? false : true;
    setValues({
      ...values,
      PAN: ev,
      PANError: em,
    });
    ev && setDisable(false);
  };

  const handleChangeName = (event) => {
    const ev = event.target.value;
    const em = ev ? false : true;
    setValues({
      ...values,
      Name: ev,
      NameError: em,
    });
    ev && setDisable(false);
  };

  const handleChangeDob = (event) => {
    const ev = event.target.value;
    const em = ev ? false : true;
    setValues({
      ...values,
      DOB: ev,
      DOBError: em,
    });
    ev && setDisable(false);
  };

  const handleChangeAadhar = (event) => {
    const ev = event.target.value;
    const em = ev ? false : true;
    setValues({
      ...values,
      AADHAR: ev,
      AADHARError: em,
    });
    ev && setDisable(false);
  };

  const handleAddSaveHere = () => {
    handleAddSave(values);
    action("");
  };

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12}>
        <Title>Identity Proof</Title>
      </Grid>

      <Grid item xs={6}>
        <InputComponent
          id="pan"
          name="pan"
          label="Pan Card Number"
          defaultValue={""}
          variant="standard"
          handleChange={handleChangePan}
        />
        <InputComponent
          id="panName"
          name="panName"
          label="Full Name as on Card"
          defaultValue={""}
          variant="standard"
          handleChange={handleChangeName}
        />
        <InputComponent
          id="panDOB"
          name="panDOB"
          label="DOB"
          defaultValue={""}
          variant="standard"
          handleChange={handleChangeDob}
        />
        {/*
                &nbsp;
                &nbsp;
                <SelectOptions 
                    //openPage     = { handleAccTypePage }
                    data         = { DocIdentityProof }
                    multi        = { false }
                    title        = { "Identity Proof" }
                    wide         = { colChart.width }
                />                
                */}
      </Grid>

      <FileUploadPage handleSubmit={changeSubmit} title={""} />

      <Grid item xs={12}>
        <Title>Address Proof</Title>
      </Grid>

      <Grid item xs={6}>
        <InputComponent
          id="aadhar"
          name="aadhar"
          label="Aadhar Card Number"
          defaultValue={""}
          variant="standard"
          handleChange={handleChangeAadhar}
        />
        {/*
                <SelectOptions 
                    //openPage     = { handleAccTypePage }
                    data         = { DocAddressProof }
                    multi        = { false }
                    title        = { "Address Proof" }
                />
                */}
      </Grid>

      <FileUploadPage handleSubmit={changeSubmit2} title={""} />

      <Grid item xs={12}>
        <div style={{ margin: "16px 0px" }}>
          <div className="colfr">
            <MyHelpButtons
              onClick={handleAddSaveHere}
              btnKey={"update"}
              btnLabel={"Update"}
              enState={disable}
              btnTooltip={"Update"}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

{
  /*

server

PAN Schema

{
  "txnId": "f7f1469c-29b0-4325-9dfc-c567200a70f7",
  "format": "xml",
  "certificateParameters": {
    "panno": "ABCD123EF",
    "PANFullName": "RAMESHWAR KUMAR SINGH",
    "UID": "123412341234",
    "FullName": "Sunil Kumar",
    "DOB": "31-12-1980",
    "GENDER": "Male"
  },.....


*/
}
