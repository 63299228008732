import * as React from "react";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { SearchBoxStandard } from "../../common/ConstantsAndValues";
import { ClinicalTestsPresListView } from "./ClinicalTestsPresListView";
import { MyTooltipButtons } from "../../CustomComponents/MyButtons";
import { DiaglabList } from "../../labs/DiaglabList";
import { ClinicalTestReorderInput } from "../../clinicaltests/ClinicalTestReorderInputMDD";
import log from "../../../services/log";

/**
 * request list MD
 * children viewlist, requestlist
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export default function ReportsRequestsMD(props) {
  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );
  const selectedPatient = useSelector((state) => state?.user?.selectedPatient);
  const labslist = useSelector((state) => state?.labs?.labsorder);
  const selectreqrows = useSelector((state) => state?.labs?.labsorderselect);

  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);
  const [actionState, setActionState] = useState("");
  const [disablePres, setDisablePres] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    selectreqrows ? setDisablePres(false) : setDisablePres(true);
  }, [selectreqrows]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = labslist.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(labslist);
    }
  };

  const handleRowClick = (e) => {
    var updatedList = [...checkedRows];

    if (findArrayElement(updatedList, e)) {
      updatedList.splice(checkedRows.indexOf(e), 1);
    } else {
      updatedList = [...checkedRows, e];
    }

    setCheckedRows(updatedList);
    //console.log("Prescribe", updatedList);
  };

  const handlePrescribe = () => {
    console.log("Prescribe Repeat", checkedRows);
    setActionState("prescribe");
  };

  const findArrayElement = function (array, title) {
    return array.find((item) => {
      return item === title;
    });
  };

  const handleViewlist = () => {
    setActionState("viewlist");
  };

  const handleCancelLocal = () => {
    setActionState("");
  };

  log.info("labs request", disablePres);

  return (
    <Grid container>
      <Grid item xs>
        <div className="clear"></div>
        <div
          className="flex-grow-1"
          style={{
            display: "flex",
            justifyContent: "space-between",
            lineHeight: "36px",
            width: "100%",
          }}
        >
          <div>
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Clinical Test"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
          <div>
            <MyTooltipButtons
              onClick={handleViewlist}
              btnKey={"viewlist"}
              btnLabel={"View"}
              enState={false}
            />
            &nbsp; &nbsp;
            <MyTooltipButtons
              onClick={handlePrescribe}
              btnKey={"prescribedrug"}
              btnLabel={"Prescribe"}
              enState={false}
            />
          </div>
        </div>

        {actionState === "viewlist" ? (
          <ClinicalTestsPresListView handleCancel={handleCancelLocal} />
        ) : actionState === "prescribe" ? (
          <div>
            <ClinicalTestReorderInput
              cancel={handleCancelLocal}
              doctor={user}
              patient={selectedPatient}
              // clinicaltest={checkedRows}
            />
          </div>
        ) : (
          <DiaglabList
            labslist={labslist}
            seletedRows={checkedRows}
            selectRows={setCheckedRows}
          />
        )}
      </Grid>
    </Grid>
  );
}
