import React from "react";
import { Grid, Paper, Box, Typography, Container } from "@mui/material";
import { useOutletContext } from "react-router-dom";

import { HealthSpeedo } from "../../CustomComponents/HealthSpeedo";
import { HealthCard } from "../../CustomComponents/HealthCard";
import { HealthAccordion } from "../../CustomComponents/HealthAccordion";

import { cardData } from "../../../tests/DM_Data";

export function DM(props) {
  //const [markDates, dateTest, auth, eventType] = useOutletContext();
  const [markDates, dateTest, auth, eventType, page, patient] =
    useOutletContext();

  const [selectedItems, setSelectedItems] = React.useState([]);

  return (
    <>
      <Box
        px={2}
        sx={{
          width: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box px={2} width="50%">
          <HealthSpeedo page={page} data={cardData} />
        </Box>

        <Box px={2} key="c" width="50%">
          <HealthCard page={page} data={cardData} />
        </Box>
      </Box>
      <Box
        px={2}
        mt={2}
        sx={{
          width: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: { xs: "column" },
        }}
      >
        <Box px={2} key="a">
          <HealthAccordion page={page} data={cardData} />
        </Box>
      </Box>
    </>
  );
}
