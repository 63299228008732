// import axios from "axios";
import { API_URL } from "./Constants";
import { axiosApiInstance } from "./axiosmiddleware";

const uploadFeedbackImage = (payload) => {
  return axiosApiInstance.post(API_URL + "feedback/upload", payload);
};

const addNewFeedback = (payload) => {
  return axiosApiInstance.post(API_URL + "feedback/set", payload);
};

const addNewFeedbackLanding = (payload) => {
  return axiosApiInstance.post(API_URL + "subscribes/set", payload);
};

export { uploadFeedbackImage, addNewFeedback, addNewFeedbackLanding };
