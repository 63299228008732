import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import useStyles from "../CustomComponents/useStyles";
import { getPatientDrugs } from "../../redux/drugs/drugs.actions";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectPatientMedcine } from "../../redux/drugs/drugs.actions";
import { getMedicine } from "../../redux/actions/reports";
import { pageOptions, SearchBoxStandard } from "../common/ConstantsAndValues";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import { MedicineListMDDHead } from "./MedicineListMDDHead";
import { MedicineListMDDRow } from "./MedicineListMDDRow";
import log from "../../services/log";
import { buildPatientMedicinesQuery } from "../dbcq/medicinedb";

export function MedicineListMDD(props) {
  const { medicinelist } = props;

  const selectedPatient = useSelector((state) => state?.user?.selectedPatient);
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const medicines = useSelector((state) => state?.drugs?.patientdruglist);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setRows(medicines);
  }, [medicines]);

  useEffect(() => {
    if (selectedPatient) {
      const resetpage = { ...pagedata, page: 0 };
      const jsonData = buildPatientMedicinesQuery(
        resetpage,
        searchcontext,
        selectedPatient
      );
      dispatch(getPatientDrugs(jsonData)).then((r) => {
        // log.info("received medicine list", r);
        if (r) {
          setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
        }
      });
    }
  }, [selectedPatient]);

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const jsonData = buildPatientMedicinesQuery(
      newpagedata,
      searchcontext,
      selectedPatient
    );
    dispatch(getPatientDrugs(jsonData)).then((r) => {
      // log.info("received medicine list", r);
      if (r) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
    setPage(newpagedata);
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (e, selectrow) => {
    log.info("medicinelist", e);
    if (selectrow) {
      setCheckedRows(e);
      dispatch(selectPatientMedcine(e));
    } else {
      setCheckedRows(null);
      dispatch(selectPatientMedcine(null));
    }
  };

  log.info("medicinelist", rows);

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead>
              <MedicineListMDDHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {rows &&
                rows?.map((row) => (
                  <MedicineListMDDRow
                    key={row.id}
                    row={row}
                    isRowSelected={checkedRows}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
