import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { MDSpecialitySelect } from "../../CustomComponents/MDSpeciality";
import { DepartmentSelect } from "../../CustomComponents/DepartmentSelect";

export default function DeptManage() {
  return (
    <Grid container>
      Manage...
      <DepartmentSelect />
      <MDSpecialitySelect />
    </Grid>
  );
}
