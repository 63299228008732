import { distance } from "react-dnd-touch-backend/dist/utils/math";

export const locIndexQueries = (document) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData.params.index = "locationmap";
  jsonData.params.document = document;
  return jsonData;
};

export const locByDistanceSearchQueries = (
  field = "departmentId",
  value = "",
  pagedata,
  distance,
  location
) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "locationmap";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool["must"] = {};
  jsonData.params.query.bool["filter"] = {};
  jsonData.params.query.bool.must["match"] = {};
  jsonData.params.query.bool.must.match[field] = value;
  jsonData.params.query.bool.filter["geo_distance"] = {};
  jsonData.params.query.bool.filter["geo_distance"]["distance"] =
    distance + "km";
  jsonData.params.query.bool.filter["geo_distance"]["location"] = location;
  return jsonData;
};

export const locByDistanceWildSearchQueries = (
  field = "departmentId",
  value = "",
  pagedata,
  distance,
  location
) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "locationmap";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool["must"] = {};
  jsonData.params.query.bool["filter"] = {};
  jsonData.params.query.bool.must["wildcard"] = {};
  jsonData.params.query.bool.must.wildcard[field] = value;
  jsonData.params.query.bool.filter["geo_distance"] = {};
  jsonData.params.query.bool.filter["geo_distance"]["distance"] =
    distance + "km";
  jsonData.params.query.bool.filter["geo_distance"]["location"] = location;
  return jsonData;
};
