import React from "react";
import { useOutletContext } from "react-router-dom";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import log from "../../../services/log";
import PatientListMain from "../../patient/components/PatientListMain";

export default function PatientListORG() {
  const [org] = useOutletContext();

  return (
    <TableContainer component={Paper}>
      <PatientListMain userType={"ORG"} org={org} />
    </TableContainer>
  );
}
