import React, { createContext, useContext, useState } from "react";
import { metaThemeConstants } from "./metaPalletes";
import { createTheme } from "@mui/material/styles";
import log from "../../services/log";
// import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const MetaThemeContext = createContext();

export const useMetaTheme = () => {
  return useContext(MetaThemeContext);
};

export const MetaThemeProvider = ({ children }) => {
  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1280,
        xl: 1920,
      },
    },
    logoSizes: {
      padding: "10px 10px 10px 30px",
      maxHeight: 60,
      maxWidth: 220,
    },
    margins: {
      zero: 0,
      login: 6,
    },
    dashboardlogoSizes: {
      padding: "12px 10px 10px 30px",
      maxHeight: 70,
      maxWidth: 230,
    },
    palette: {
      type: "dark",
      bgColor: {
        main: "#ffffff",
      },
      primary: {
        dark: "#ce5303",
        main: "#ff6d0d",
        standard: "#ff924a",
        lightDark: "#ffb585",
        light: "#ffdac2",
        lightLight: "#fff1e7",
      },

      secondary: {
        dark: "#19445e",
        main: "#2a8aa7",
        standard: "#5fa7bd",
        lightDark: "#94c4d2",
        light: "#c9e1e9",
        lightLight: "#eaf4f7",
        contrastText: "fffffff",
      },
      gray: {
        black: "#000000",
        dark: "#202020",
        main: "#585858",
        standard: "#8f8f8f",
        lightDark: "#c7c7c7",
        light: "#e9e9e9",
        white: "#ffffff",
        contrastText: "#ffffff",
      },
      footer: {
        main: "#2abaa7",
      },
      error: {
        main: "#f44336",
      },
      errorLight: {
        main: "#fee3e1",
      },
      active: {
        main: metaThemeConstants?.pallete?.secondary?.main,
      },
      warning: {
        main: "#ffc700",
      },
      warningLight: {
        main: "#fff709",
      },
      info: {
        main: "#e5f2df",
      },
      success: {
        main: "#52a72a",
      },
    },

    typography: {
      fontSize: 12,
      button: {
        fontFamily: '"DM Sans"',
        fontSize: "14px",
        fontWeight: 200,
        lineHeight: 1.5,
        letterSpacing: "0.025em",
      },
      fontFamily: '"DM Sans"',
      MPMTitle: {
        fontFamily: '"DM Sans"',
        lineHeight: "1.0",
        fontWeight: "400",
        fontSize: "24px",
        letterSpacing: "1.61px",
      },
      MPTitle: {
        //fontSize: '60px',
        lineHeight: "1.0",
        fontWeight: "200",
        fontFamily: '"DM Sans"',
        //color: metaThemeConstants?.palette?.gray?.black,
      },
      MPTitle1: {
        //fontSize: '60px',
        lineHeight: "1.0",
        fontWeight: "500",
        fontFamily: '"DM Sans"',
        //color: metaThemeConstants?.palette?.gray?.black,
      },
      MPSubTitle: {
        //fontSize: '25px',
        lineHeight: "1.35",
        fontWeight: "400",
        fontFamily: '"DM Sans"',
        //color: metaThemeConstants?.palette?.gray?.main,
      },
      MPInstructions: {
        //fontSize: '26px',
        lineHeight: "1.45",
        fontWeight: "200",
        fontFamily: '"DM Sans"',
        //color: metaThemeConstants?.palette?.secondary?.main,
      },
      h1: {
        fontSize: "4rem",
        fontWeight: 500,
        lineHeight: "3.5rem",
        fontFamily: '"DM Sans"',
      },
      h2: {
        fontSize: "3.5rem",
        fontFamily: '"DM Sans"',
        fontWeight: 400,
        lineHeight: "3.5rem",
      },
      h3: {
        fontSize: "3.0rem",
        fontFamily: '"DM Sans"',
      },
      h4: {
        fontSize: "2.5rem",
        fontFamily: '"DM Sans"',
      },
      h5: {
        //fontSize: '2.0rem',
        fontFamily: '"DM Sans"',
      },
      h6: {
        fontSize: "1.25rem",
        fontFamily: '"DM Sans"',
        lineHeight: "1.5rem",
        fontWeight: 200,
      },
      NormalText400: {
        //fontSize: "16px",
        fontFamily: '"DM Sans"',
        lineHeight: "1.0",
        fontWeight: 400,
      },
      ItalicText400: {
        //fontSize: "16px",
        fontFamily: '"DM Sans"',
        lineHeight: "1.0",
        fontWeight: 400,
        fontStyle: "italic",
      },
      Copyright: {
        fontSize: "12px",
        fontFamily: '"DM Sans"',
        lineHeight: "1.16",
        fontWeight: 400,
      },
    },

    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeSmall: {
            // Define custom styles for small size here
            width: 36,
            height: 36,
          },
          sizeMedium: {
            // Define custom styles for medium size here
            width: 48,
            height: 48,
          },
          sizeLarge: {
            // Define custom styles for large size here
            width: 60,
            height: 60,
          },
        },
      },

      MetaButtonPrimay: {
        default: {
          padding: "15px",
          height: "20px",
          fontSize: "20px",
          textAlign: "left",
          radius: 30,
        },
        sizeLarge: {
          height: "20px",
          fontSize: "24px",
        },
        sizeSmall: {
          height: "16px",
          fontSize: "16px",
        },
      },

      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          outlined: {
            borderColor: metaThemeConstants?.pallete?.gray?.dark,
          },
          sizeLarge: {
            width: "600px",
            height: "60px",
            textAlign: "left",
          },
          sizeSmall: {
            width: "100px",
            height: "30px",
            textAlign: "left",
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          sizeLarge: {
            width: "300px",
            height: "40px",
            textAlign: "left",
          },
          sizeSmall: {
            width: "100px",
            height: "30px",
            textAlign: "left",
          },
          root: {
            "&:focus": {
              outline: "none",
              boxShadow: "none",
            },
            "&:focus-visible": {
              boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
            },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            height: "50px", // Adjust this to your desired height
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            "&:hover": {
              backgroundColor: "#ff8000",
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1em",
            color: "#444444",
            backgroundColor: "#ffffff",
            border: `1px solid #dddddd`,
          },
          arrow: {
            color: "#444444",
          },
        },
      },
    },
  });

  const [theme, setTheme] = useState(customTheme);

  return (
    <MetaThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </MetaThemeContext.Provider>
  );
};
