import React from "react";
import { useOutletContext } from "react-router-dom";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import DeptList from "../../CustomComponents/DeptList";

export default function DeptListORG() {
  const [org] = useOutletContext();

  return (
    <TableContainer component={Paper}>
      <DeptList userType={"ORG"} org={org} />
    </TableContainer>
  );
}
