import {
  SELECT_MESSAGE,
  LIST_MESSAGES,
  LIST_ALERTS,
  LIST_CLINIC_NOTES,
} from "../actions/types";

import { messageActionTypes } from "../actions/message.types";

const initialState = {
  message: null,
  messages: null,
  alerts: null,
  clinicnotes: null,
  newnotes: null,
  receiverslist: null,
};

export default function messageReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case messageActionTypes.SELECT_MESSAGE:
      return {
        ...state,
        message: payload,
      };
    case messageActionTypes.LIST_MESSAGES:
      return {
        ...state,
        messages: payload.results,
      };
    case messageActionTypes.LIST_ALERTS:
      return {
        ...state,
        alerts: payload.results,
      };
    case messageActionTypes.LIST_CLINICNOTES:
      return {
        ...state,
        clinicnotes: payload.results,
      };
    case messageActionTypes.NEW_CLINICNOTES:
      return {
        ...state,
        newclinicnotes: payload,
      };
    case messageActionTypes.RECEIVERS_LIST:
      return {
        ...state,
        receiverslist: payload,
      };

    default:
      return state;
  }
}
