import { TextField, Button } from "@mui/material";

import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";

import * as yup from "yup";
import InputComponent from "../CustomComponents/InputComponent";
import InputFormComponent from "../CustomComponents/InputFormComponent";
import React, { useEffect } from "react";
import { Box, Link } from "@material-ui/core";
import log from "../../services/log";
import { updatePolicy } from "../../redux/policy/policy.actions";
import { useDispatch } from "react-redux";

const policySchema = yup.object({
  id: yup.string().required("policy selection is required"),
  policyName: yup.string().required("policy selection is required"),
  familyDiscount: yup.number().min(0).max(100),
  discount: yup.number().min(0).max(100),
  ncb: yup.number().min(0).max(100),
});

export const PolicyUpdate = (props) => {
  const { policy } = props;

  const dispatch = useDispatch();
  const policyform = useFormik({
    initialValues: policy
      ? {
          id: policy.id,
          policyName: policy.policyName,
          familyDiscount: policy.policyPremium.familyDiscount
            ? policy.policyPremium.familyDiscount
            : 0,
          discount: policy.policyPremium.discount
            ? policy.policyPremium.discount
            : 0,
          ncb: policy.policyPremium.ncb ? policy.policyPremium.ncb : 0,
        }
      : {
          id: "",
          policyName: "",
          familyDiscount: 0,
          discount: 0,
          ncb: 0,
        },
    validationSchema: policySchema,
    onSubmit: (values) => {
      // log.info(JSON.stringify(values, null, 2));
      const jsonData = {};
      jsonData["id"] = values.id;
      jsonData["familyDiscount"] = values.familyDiscount;
      jsonData["discount"] = values.discount;
      jsonData["ncb"] = values.ncb;

      // log.info("policyupdate", jsonData);
      dispatch(updatePolicy(jsonData));
    },
  });

  log.info("policyupdate", policyform);

  return (
    <div>
      <form>
        <InputComponent
          id="policyName"
          name="policyName"
          label="Policy Name"
          errors={policyform.errors.policyName ? true : false}
          errorText={policyform.errors.policyName}
          defaultValue={policyform.values.policyName}
          variant="standard"
          disabled={true}
          handleChange={policyform.handleChange}
        />
        &nbsp; &nbsp;
        <InputComponent
          id="familyDiscount"
          name="familyDiscount"
          label="Family discount percent"
          errors={policyform.errors.familyDiscount ? true : false}
          errorText={policyform.errors.familyDiscount}
          defaultValue={policyform.values.familyDiscount}
          variant="standard"
          disabled={false}
          handleChange={policyform.handleChange}
        />
        &nbsp; &nbsp;
        <InputComponent
          id="discount"
          name="discount"
          label="Discount percent"
          errors={policyform.errors.discount ? true : false}
          errorText={policyform.errors.discount}
          defaultValue={policyform.values.discount}
          variant="standard"
          disabled={false}
          handleChange={policyform.handleChange}
        />
        &nbsp; &nbsp;
        <InputFormComponent
          id="ncb"
          name="ncb"
          label="No claim bonus percent"
          errors={policyform.errors.ncb ? true : false}
          errorText={policyform.errors.ncb}
          defaultValue={policyform.values.ncb}
          variant="standard"
          disabled={false}
          handleChange={policyform.handleChange}
        />
        <Box
          mt={2}
          mr={2}
          mb={4}
          className="btn-container text-color-white"
          component="div"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Link
            onClick={policyform.handleSubmit}
            target=""
            className="btn btn-main-2-inv btn-icon btn-round text-color-white"
          >
            Save
          </Link>
        </Box>
      </form>
    </div>
  );
};
