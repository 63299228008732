export const CTEST_REQUESTS = [
  {
    id: "PP1",
    patient: "P001",
    doctor: "a123",
    investigation: [
      {
        id: 10,
        masterid: "PP1",
        ctestid: "R001",
        ctest: "RBC test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 1,
        masterid: "PP1",
        ctestid: "R002",
        ctest: "Blood tests",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 2,
        masterid: "PP1",
        ctestid: "R003",
        ctest: "swab test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 3,
        masterid: "PP1",
        ctestid: "R004",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 4,
        masterid: "PP1",
        ctestid: "R005",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "fresh",
    currency: "USD",
    billAmount: "32.00",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "",
  },
  {
    id: "PP1a",
    patient: "P001",
    doctor: "a123",
    investigation: [
      {
        id: 1,
        masterid: "PP1a",
        ctestid: "R002",
        ctest: "Blood tests",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "a",
      },
      {
        id: 2,
        masterid: "PP1a",
        ctestid: "R003",
        ctest: "swab test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "a",
      },
      {
        id: 3,
        masterid: "PP1a",
        ctestid: "R004",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "a",
      },
      {
        id: 4,
        masterid: "PP1a",
        ctestid: "R005",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "a",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "done",
    currency: "USD",
    billAmount: "32.00",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "",
  },

  {
    id: "PP2",
    patient: "P001",
    doctor: "b234",
    investigation: [
      {
        id: 1,
        masterid: "PP2",
        ctestid: "R001",
        ctest: "RBC Count",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 2,
        masterid: "PP2",
        ctestid: "R002",
        ctest: "Blood tests",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 0,
        masterid: "PP2",
        ctestid: "R003",
        ctest: "swab test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "",
    currency: "USD",
    billAmount: "30.00",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "",
  },
  {
    id: "PP3",
    patient: "P001",
    doctor: "a124",
    investigation: [
      {
        id: 1,
        masterid: "PP3",
        ctestid: "R001",
        ctest: "Routine Blood testst",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 2,
        masterid: "PP3",
        ctestid: "R002",
        ctest: "Blood tests",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 3,
        masterid: "PP3",
        ctestid: "R004",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "hold",
    currency: "USD",
    billAmount: "40.50",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "",
  },

  {
    id: "PP4",
    patient: "P001",
    doctor: "a125",
    investigation: [
      {
        id: 11,
        masterid: "PP4",
        ctestid: "R001",
        ctest: "Platelet Count",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 12,
        masterid: "PP4",
        ctestid: "R002",
        ctest: "Blood tests",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "",
    currency: "USD",
    billAmount: "500.00",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "",
  },
  {
    id: "PP5",
    patient: "P001",
    doctor: "a126",
    investigation: [
      {
        id: 1,
        masterid: "PP5",
        ctestid: "R001",
        ctest: "RBC test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "paid",
    currency: "USD",
    billAmount: "3000.00",
    paid: true,
    paidDate: "2023-02-09",
    payType: "CC",
    payMethod: "CC xxxx2456",
    transactionNo: "BNM5678",
    remarks: "needs money receipt",
  },
  {
    id: "PP6",
    patient: "P002",
    doctor: "b235",
    investigation: [
      {
        id: 1,
        masterid: "PP6",
        ctestid: "R001",
        ctest: "RBC Count",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 2,
        masterid: "PP6",
        ctestid: "R002",
        ctest: "Blood tests",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "",
    currency: "USD",
    billAmount: "68",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "",
  },
  {
    id: "PP7",
    patient: "P001",
    doctor: "b123",
    investigation: [
      {
        id: 10,
        masterid: "PP7",
        ctestid: "R001",
        ctest: "RBC test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 3,
        masterid: "PP7",
        ctestid: "R003",
        ctest: "swab test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 4,
        masterid: "PP7",
        ctestid: "R005",
        ctest: "stool test ",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "done",
    currency: "USD",
    billAmount: "34",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "",
  },
  {
    id: "PP8",
    patient: "P006",
    doctor: "b122",
    investigation: [
      {
        id: 1,
        masterid: "PP8",
        ctestid: "R001",
        ctest: "Routine Blood testst",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 3,
        masterid: "PP8",
        ctestid: "R003",
        ctest: "swab test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 4,
        masterid: "PP8",
        ctestid: "R005",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "billraised",
    currency: "USD",
    billAmount: "28.89",
    paid: true,
    paidDate: "2022-03-11",
    payType: "IB",
    payMethod: "IB xxxx1240",
    transactionNo: "VBG56789",
    remarks: "clearance due",
  },
  {
    id: "PP9",
    patient: "P007",
    doctor: "b121",
    investigation: [
      {
        id: 1,
        masterid: "PP9",
        ctestid: "R001",
        ctest: "TSH",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 2,
        masterid: "PP9",
        ctestid: "R002",
        ctest: "Blood tests",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 4,
        masterid: "PP9",
        ctestid: "R004",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "",
    currency: "USD",
    billAmount: "223",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "",
  },
  {
    id: "PP10",
    patient: "P008",
    doctor: "a123",
    investigation: [
      {
        id: 1,
        masterid: "PP10",
        ctestid: "R001",
        ctest: "Platelet Count",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 2,
        masterid: "PP10",
        ctestid: "R002",
        ctest: "Blood tests",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 3,
        masterid: "PP10",
        ctestid: "R003",
        ctest: "swab test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 4,
        masterid: "PP10",
        ctestid: "R001",
        ctest: "this syrup ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 5,
        masterid: "PP10",
        ctestid: "R005",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "done",
    currency: "USD",
    billAmount: "1000",
    paid: true,
    paidDate: "2023-02-11",
    payType: "IB",
    payMethod: "Bank A/c xxx4567",
    transactionNo: "XCV45678",
    remarks: "advance",
  },
  {
    id: "PP11",
    patient: "P003",
    doctor: "b122",
    investigation: [
      {
        id: 1,
        masterid: "PP11",
        ctestid: "R001",
        ctest: "e tab",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 2,
        masterid: "PP11",
        ctestid: "R002",
        ctest: "Blood tests",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 3,
        masterid: "PP11",
        ctestid: "R003",
        ctest: "swab test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 4,
        masterid: "PP11",
        ctestid: "R005",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "",
    currency: "USD",
    billAmount: "1000",
    paid: true,
    paidDate: "2022-01-01",
    payType: "CC",
    payMethod: "CC Amazon Pay xxxx2345",
    transactionNo: "XXC45677",
    remarks: "advance",
  },

  {
    id: "PP12",
    patient: "P002",
    doctor: "b122a",
    investigation: [
      {
        id: 1,
        masterid: "PP12",
        ctestid: "R001",
        ctest: "RBC Count",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 3,
        masterid: "PP12",
        ctestid: "R003",
        ctest: "swab test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 4,
        masterid: "PP12",
        ctestid: "R004",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: 5,
    currency: "USD",
    billAmount: "1000",
    paid: true,
    paidDate: "2022-01-01",
    payType: "CC",
    payMethod: "CC Amazon Pay xxxx2345",
    transactionNo: "XXC45677",
    remarks: "advance",
  },

  {
    id: "PP13",
    patient: "P002",
    doctor: "b122b",
    investigation: [
      {
        id: 1,
        masterid: "PP13",
        ctestid: "R001",
        ctest: "Routine Blood testst",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 2,
        masterid: "PP13",
        ctestid: "R002",
        ctest: "Blood tests",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "",
    currency: "USD",
    billAmount: "1000",
    paid: true,
    paidDate: "2022-01-01",
    payType: "CC",
    payMethod: "CC Amazon Pay xxxx2345",
    transactionNo: "XXC45677",
    remarks: "advance",
  },
  {
    id: "PP14",
    patient: "P001",
    doctor: "b121a",
    investigation: [
      {
        id: 3,
        masterid: "PP14",
        ctestid: "R003",
        ctest: "swab test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 4,
        masterid: "PP14",
        ctestid: "R005",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "",
    currency: "USD",
    billAmount: "223",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "",
  },
  {
    id: "PP15",
    patient: "P003",
    doctor: "b121b",
    investigation: [
      {
        id: 2,
        masterid: "PP15",
        ctestid: "R002",
        ctest: "Blood tests",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 3,
        masterid: "PP15",
        ctestid: "R003",
        ctest: "swab test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 4,
        masterid: "PP15",
        ctestid: "R005",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "hold",
    currency: "USD",
    billAmount: "223",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "",
  },
  {
    id: "PP16",
    patient: "P004",
    doctor: "b121c",
    investigation: [
      {
        id: 1,
        masterid: "PP16",
        ctestid: "R001",
        ctest: "RBC test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 2,
        masterid: "PP16",
        ctestid: "R002",
        ctest: "Blood tests",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 3,
        masterid: "PP16",
        ctestid: "R003",
        ctest: "swab test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 4,
        masterid: "PP16",
        ctestid: "R004",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "done",
    currency: "USD",
    billAmount: "223",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "",
  },
  {
    id: "PP17",
    patient: "P001",
    doctor: "b121d",
    investigation: [
      {
        id: 1,
        masterid: "PP17",
        ctestid: "R001",
        ctest: "RBC test",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
      {
        id: 4,
        masterid: "PP17",
        ctestid: "R005",
        ctest: "stool test ",
        speciment: "",
        qty: "",
        uom: "",
        rate: "",
        taxpc: "",
        amt: "0.00",
        amtwtax: "0.00",
        remarks: "",
        assign: "CS001",
        instruction: "",
        report: "",
      },
    ],
    assign: "CS001",
    instruction: "",
    status: "done",
    currency: "USD",
    billAmount: "223",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "",
  },
];
