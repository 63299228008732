import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { SearchBoxStandard } from "../../common/ConstantsAndValues";
import PartyLedgerORG from "../../payments/PartyLedgerORG";
import log from "../../../services/log";

export default function AccLedgerORG() {
  const patientBills = useSelector((state) => state?.payment?.ledgerlist); //to correct this
  const [rows, setRows] = useState([]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = patientBills.filter((v) =>
        v.medTitle.startsWith(keyword.toLowerCase())
      ); //to correct this
      setRows(results);
    } else {
      setRows(patientBills);
    }
  };

  return (
    <Grid container>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0"></div>

          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Transactions"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div></div>
      </div>

      <PartyLedgerORG />
    </Grid>
  );
}
