import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Typography, MenuItem, Select } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

export default function ProfileInput() {
  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <div>
        <Box itemID={"contatInformation"} sx={{ width: 240 }}>
          <Typography variat={"header"}>Details About Me</Typography>
          <FormControl variant="standard" sx={{ m: 1, mt: 1, width: "240px" }}>
            <Input
              id="standard-adornment-weight"
              value={values.firstName}
              onChange={handleChange("weight")}
              endAdornment={
                <InputAdornment position="start">First</InputAdornment>
              }
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
            <FormHelperText id="standard-weight-helper-text">
              Name
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, mt: 1, width: "240px" }}>
            <Input
              id="standard-adornment-weight"
              value={values.lastName}
              onChange={handleChange("weight")}
              endAdornment={
                <InputAdornment position="start">Last</InputAdornment>
              }
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
            <FormHelperText id="standard-weight-helper-text">
              Name
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, mt: 1, width: "240px" }}>
            <Input
              id="standard-adornment-weight"
              value={values.weight}
              onChange={handleChange("weight")}
              endAdornment={
                <InputAdornment position="start">lb</InputAdornment>
              }
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
            <FormHelperText id="standard-weight-helper-text">
              Weight
            </FormHelperText>
          </FormControl>

          <FormControl sx={{ m: 1, width: "240px" }} variant="standard">
            <Input
              id="standard-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText id="filled-weight-helper-text">
              Password
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, mt: 1, width: "240px" }}>
            <InputLabel id="gender">Gender</InputLabel>
            <Select
              aria-label={"Gender"}
              value={values.gender}
              onChange={handleChange("gender")}
              aria-describedby="standard-weight-helper-text"
            >
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>
    </Box>
  );
}
