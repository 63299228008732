// import node modules
import * as React from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";

import { useMetaTheme } from "../theme/ThemeContext";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../customIcons/SocialIcons";
import { ArrowUpBlueIcon } from "../customIcons/ArrowUpBlueIcon";
import { ChevronRight } from "../customIcons/ChevronRight";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Spinner from "../Loader/index";
// import other modules
import { setSubscribe } from "../../redux/subscribe/subscribe.actions";

const socialIconList = [
  {
    title: "facebook",
    icon: <FacebookIcon />,
    url: "",
    padding: "0px 10px",
  },
  {
    title: "twitter",
    icon: <TwitterIcon />,
    url: "",
    padding: "0px 5px",
  },
  {
    title: "instagram",
    icon: <InstagramIcon />,
    url: "",
    padding: "0px 6px",
  },
  {
    title: "linkedin",
    icon: <LinkedinIcon />,
    url: "",
    padding: "0px 6px",
  },
  {
    title: "youtube",
    icon: <YoutubeIcon />,
    url: "",
    padding: "0px 5px",
  },
];
const Footer = () => {
  const [successValue, setSuccessValue] = React.useState("");
  const dispatch = useDispatch();

  // subsribe validation
  const subscribeValidateSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email")
      .required("Please enter a valid email"),
  });

  // subsribe inital values
  const subscribeValueSchema = () => {
    return {
      email: "",
    };
  };

  // subsribe submit function
  const subscribeNow = async (values, { resetForm }) => {
    var params = {
      email: values.email,
    };
    let response = await dispatch(setSubscribe(params));
    if (response.code === 201) {
      resetForm(subscribeValueSchema);
      setSuccessValue("Thank you for subscribing!");
      setTimeout(() => {
        setSuccessValue("");
      }, 2000);
    }
  };

  const { theme } = useMetaTheme();

  // scroll to top
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const footerItems = [
    {
      headingTitle: "Company",
      headingItems: [
        { item: "About us" },
        { item: "Leadership" },
        { item: "Careers" },
        { item: "Blog" },
      ],
    },
    {
      headingTitle: "Support",
      headingItems: [{ item: "Help" }, { item: "FAQ" }, { item: "Demo" }],
    },
    {
      headingTitle: "Connect",
      headingItems: [{ item: "Contact us" }, { item: "Feedback" }],
    },
    {
      headingTitle: "Download",
      headingItems: [{ item: "iOS" }, { item: "Android" }],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ background: "#f5f9fb" }}>
        <Grid container>
          <Grid item xs={5} px={6} py={8}>
            <Typography sx={{ fontSize: "42px", color: "#404040" }}>
              Introducing
            </Typography>
            <Typography
              sx={{ fontSize: "42px", color: "#404040", fontWeight: 700 }}
            >
              Ease into Healthcare
            </Typography>
            <Typography>
              Metalane revolutionizes healthcare by providing an all-in-one SaaS
              platform which enables affordable and accessible care for
              patients, sustainable profitability for doctors and clinics,
              feasibility for governments, and streamlined operations for
              hospitals.
            </Typography>
          </Grid>
          <Grid item xs={7} px={6} py={8}>
            <Grid container sx={{ mt: 10 }}>
              {footerItems.map((data, index) => (
                <Grid item xs={3} key={index}>
                  <Typography sx={{ color: "202020", fontWeight: 700, mb: 2 }}>
                    {data.headingTitle}
                  </Typography>
                  {data.headingItems.map((pair, idx) => (
                    <Typography
                      key={idx}
                      sx={{
                        my: 1,
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                          transition: "0.4s ease",
                        },
                      }}
                    >
                      {pair.item}
                    </Typography>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ background: "#19445E" }}>
        <Grid container>
          <Grid item xs={4} px={6} py={6}>
            <Box sx={{ display: "flex", width: "fit-content", mb: 2 }}>
              {socialIconList.map((data, index) => (
                <Box
                  key={index}
                  className="social_icon"
                  sx={{
                    padding: `${data.padding}`,
                  }}
                >
                  {data.icon}
                </Box>
              ))}
            </Box>
            <Typography variant="subtitle1" className="text-white">
              Copyright © 2023 Metalane.ai. All rights reserved.
            </Typography>
          </Grid>
          <Grid item xs={5} px={6} py={4}>
            <Typography
              sx={{ fontWeight: 600, mt: 1.5, fontSize: "15px" }}
              className="text-white"
            >
              Subscribe to our newsletter
            </Typography>
            <Formik
              initialValues={subscribeValueSchema()}
              validationSchema={subscribeValidateSchema}
              onSubmit={subscribeNow}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Box className="flex">
                    <Box>
                      <Field
                        name="email"
                        className="subscribe_input"
                        placeholder="Enter your email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-msg-white pl-1"
                      />
                      {successValue !== "" && (
                        <Box className="error-msg-white pl-1">
                          {successValue}
                        </Box>
                      )}
                    </Box>
                    <button
                      className="subscribe_button"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {!isSubmitting ? (
                        <>
                          Subscribe
                          <ChevronRight size={0.8} />
                        </>
                      ) : (
                        <Spinner />
                      )}
                    </button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={3} px={4} py={5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                cursor: "pointer",
                width: "fit-content",
                marginLeft: "auto",
                svg: {
                  fill: "#94C4D2",
                },
                "&:hover": {
                  svg: { fill: "#FF6D0D" },
                },
              }}
              onClick={scrollToTop}
            >
              <Typography
                variant="subtitle1"
                align="right"
                sx={{ color: "#94C4D2", fontWeight: 600 }}
              >
                Back to top
              </Typography>
              <ArrowUpBlueIcon size={0.8} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end", mt: 2.5 }}>
              <Typography variant="subtitle1" className="text-white">
                Privacy policy
              </Typography>
              <Divider
                orientation="vertical"
                sx={{
                  height: "24px",
                  marginTop: "2px",
                  background: "white",
                  mx: 1,
                }}
                flexItem
              />
              <Typography variant="subtitle1" className="text-white">
                Website terms of use
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
