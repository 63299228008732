import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

import { useFormik } from "formik";

import { Title } from "../common/Title";
import { tableParam, colChart } from "../common/ConstantsAndValues";

import { getDepartments } from "../../redux/loinc/loinc.actions";
import { departmentsquery } from "../utils/elasticqueries";

import {
  addOrgDepartments,
  existOrgDepartment,
} from "../../redux/actions/orgs";
import { departmentSchema } from "../schema/departmentSchema";
import log from "../../services/log";
import { MyTooltipButtons } from "../CustomComponents/MyButtons";

export default function DepartmentNonList(props) {
  const { cancelEventPage, title } = props;

  const departments = useSelector((state) => state.loinc.departments);
  const orgroup = useSelector((state) => state.orgs.selectorggroup);
  const location = useSelector((state) => state.addresses.selectaddress);
  const orgdepartment = useSelector((state) => state.orgs.selectdepartment);

  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );

  const [selectedOptions, setSelectedOptions] = useState(null);
  const [options, setOptions] = useState(null);

  const deptaddressform = useFormik({
    initialValues: {
      orgId: orgroup ? orgroup.metadata.orgId : null,
      groupId: orgroup ? orgroup.metadata.accountId : null,
      locId: location ? location.metadata.accountId : null,
      loincNumber: "",
      departmentId: "",
      departmentName: null,
      departmentDescp: null,
      status: "active",
    },
    validationSchema: departmentSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("medicineinput", JSON.stringify(values, null, 2));
      const data = {};
      data.metadata = {};
      data.metadata.orgId = values.orgId;
      data.metadata.groupId = values.groupId;
      data.metadata.locId = values.locId;
      data.metadata.loincNumber = values.loincNumber;
      data.departmentName = values.departmentName;
      data.departmentDescp = values.departmentDescp;
      data.status = values.status;

      const vdata = {};
      vdata.metadata = {};
      vdata.metadata.loincNumber = values.loincNumber;
      vdata.metadata.locId = values.locId;

      // log.info("department info", data);

      dispatch(existOrgDepartment(vdata)).then((r) => {
        log.info("department info", r);
        if (r.length == 0) {
          dispatch(addOrgDepartments(data));
        }
      });

      cancelEventPage();
    },
    onReset: (values) => {},
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDepartments(departmentsquery()));
  }, []);

  useEffect(() => {
    if (departments) {
      let newoptions = [];
      departments.forEach((d) => {
        newoptions.push({ label: d._source["deptName"], value: d });
      });
      setOptions(newoptions);
    } else {
      setOptions([]);
    }
  }, [departments]);

  const MSStyles = {
    control: (styles) => ({ ...styles }),
    container: (styles) => ({ ...styles, width: colChart.width }),
    option: (styles) => ({ ...styles }),
    // menu:      styles => ({ ...styles,  width: colChart.width }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: colChart.width,
    }),
  };

  log.info("deptindexform", deptaddressform.errors);

  return (
    <Box
      display="flex"
      width={"100%"}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        display="flex"
        width={colChart.width}
        justifyContent="left"
        flexDirection="column"
      >
        <Box mb={1}>
          <div className="colfl0">
            <Title>{title ? title : ""} </Title>
          </div>
          <div className="colfr0">
            <MyTooltipButtons
              onClick={deptaddressform.handleSubmit}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
            />
            &nbsp;
            <MyTooltipButtons
              onClick={cancelEventPage}
              btnKey={"cancel"}
              btnLabel={"Cancel"}
              enState={false}
            />
          </div>
        </Box>
        <Box className="clear"> </Box>
        <Box className="text-color-primary">
          <Select
            isDisabled={false}
            options={options}
            placeholder="Select Department"
            defaultValue={selectedOptions}
            isSearchable={true}
            styles={MSStyles}
            menuPlacement="auto"
            menuPosition="fixed"
            isMulti={false}
            onChange={(data) => {
              deptaddressform.setFieldValue(
                "departmentDescp",
                data.value._source["deptDescp"]
              );
              deptaddressform.setFieldValue(
                "departmentName",
                data.value._source["deptName"]
              );
              deptaddressform.setFieldValue(
                "departmentId",
                data.value._source.deptId
              );
              deptaddressform.setFieldValue(
                "loincNumber",
                data.value._source.deptId
              );
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
