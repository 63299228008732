import React, { useEffect, useState } from "react";

const TwitterIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#19445E" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <path
        d="M16.8558 1.66406H19.8076L13.3588 9.03462L20.9453 19.0643H15.0051L10.3526 12.9813L5.02902 19.0643H2.07544L8.97307 11.1806L1.69531 1.66406H7.78628L11.9918 7.2241L16.8558 1.66406ZM15.8198 17.2975H17.4555L12.1765 10.3178L6.89753 3.33805H5.14234L15.8198 17.2975Z"
        fill="#19445E"
      />
    </svg>
  );
};
export default TwitterIcon;
