import React from "react";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Tabs, Tab, AppBar } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { ReportsTestsListMD } from "./ReportsTestsListMD";
import { ReportsTestsPanelMD } from "./ReportsTestsPanelMD";
import LocalPharmacyTwoToneIcon from "@mui/icons-material/LocalPharmacyTwoTone";
import { Tooltip } from "@mui/material";
import log from "../../../services/log";
import { ClinicalTestsPrescribe } from "./ClinicalTestsPrescribe";
import { themeTABS, StyledTab } from "../../common/ConstantsAndValues";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={0} mt={2}>
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

export function ReportsTestsListTabsMD() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const DRUG_TAB = 0;
  const OTC_TAB = 1;

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <ThemeProvider theme={themeTABS}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label=""
            selectionFollowsFocus={true}
          >
            <StyledTab
              label="Reports Tests"
              {...a11yProps(DRUG_TAB)}
              className="text-color"
              disableFocusRipple={true}
              disableRipple={true}
              sx={{
                boxShadow: "0 !important",
                outline: "none",
                "&:focus": {
                  border: "0 !important",
                  outline: "none",
                  boxShadow: "none",
                },
              }}
            />
            <StyledTab
              label="Test Panel"
              {...a11yProps(OTC_TAB)}
              className="text-color"
              disableFocusRipple={true}
              disableRipple={true}
              sx={{
                boxShadow: "0 !important",
                outline: "none",
                "&:focus": {
                  border: "0 !important",
                  outline: "none",
                  boxShadow: "none",
                },
              }}
            />
          </Tabs>
        </AppBar>
      </ThemeProvider>
      <TabPanel value={value} index={DRUG_TAB}>
        <ReportsTestsListMD />
      </TabPanel>
      <TabPanel value={value} index={OTC_TAB}>
        <ReportsTestsPanelMD />
      </TabPanel>
    </div>
  );
}
