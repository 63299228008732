import { DrugsActionTypes } from "./drugs.types";

const INITIAL_STATE = {
  druglist: null,
  selectmedrows: [],
  preslist: null,
  patientdruglist: null,
  presorderlist: [],
  otcdrugselect: null,
  drugorderselect: null,
  orderlist: null,
};

const drugsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DrugsActionTypes.DRUGS_LIST:
      return {
        ...state,
        druglist: action.payload,
      };
    case DrugsActionTypes.DRUGS_TEXT:
      return {
        ...state,
        drugtext: action.payload,
      };
    case DrugsActionTypes.DRUGS_PRESLIST:
      return {
        ...state,
        preslist: action.payload,
      };
    case DrugsActionTypes.DRUGS_PATIENTLIST:
      return {
        ...state,
        patientdruglist: action.payload,
      };
    case DrugsActionTypes.MEDICINE_SELECT:
      return {
        ...state,
        selectmedrows: action.payload,
      };
    case DrugsActionTypes.DRUGS_SELECT:
      return {
        ...state,
        selectdrugs: action.payload,
      };
    case DrugsActionTypes.DRUGS_ORDERLIST:
      return {
        ...state,
        presorderlist: action.payload,
      };
    case DrugsActionTypes.OTCDRUGS_LIST:
      return {
        ...state,
        otcdrugslist: action.payload,
      };
    case DrugsActionTypes.OTCDRUGS_PRICELIST:
      return {
        ...state,
        otcdrugspricelist: action.payload,
      };
    case DrugsActionTypes.OTCDRUGS_SELECT:
      return {
        ...state,
        otcdrugselect: action.payload,
      };
    case DrugsActionTypes.DRUGS_PRICELIST:
      return {
        ...state,
        drugspricelist: action.payload,
      };
    case DrugsActionTypes.DRUGS_ORDERSELECT:
      return {
        ...state,
        drugorderselect: action.payload,
      };
    case DrugsActionTypes.PHARMAGOUP_SELECT:
      return {
        ...state,
        pharmagroup: action.payload,
      };

    case DrugsActionTypes.DRUGSORDERS:
      return {
        ...state,
        orderlist: action.payload,
      };

    default:
      return state;
  }
};

export default drugsReducer;
