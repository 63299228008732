import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const DicomFile = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="text-color-primary dicom">{props.children}</div>
    </DndProvider>
  );
};

export default DicomFile;
