import { hashById } from "./utils";
import { EventsActionTypes } from "./types";

import log from "../../../services/log";

export function weekendsVisible(weekendsVisible = true, action) {
  switch (action.type) {
    case EventsActionTypes.TOGGLE_WEEKENDS:
      return !weekendsVisible;
    default:
      return weekendsVisible;
  }
}

export function eventsReducer(state = {}, action) {
  const { type, payload } = action;
  // log.info("aptreducer", action);

  switch (type) {
    case EventsActionTypes.RECEIVE_EVENTS:
      return hashById(payload);

    case EventsActionTypes.CREATE_EVENT:
    case EventsActionTypes.UPDATE_EVENT:
      return {
        ...state,
        [payload.id]: payload,
      };

    case EventsActionTypes.DELETE_EVENT:
      eventsReducer = { ...state };
      delete eventsReducer[payload.eventId];
      return eventsReducer;

    case EventsActionTypes.EVENT_FILTERS:
      return {
        ...state,
        mask: payload.mask,
      };

    case EventsActionTypes.SELECT_DATE:
      return {
        ...state,
        select: payload.select,
      };
    case EventsActionTypes.SELECT_DISABLEDATE:
      return {
        ...state,
        selectdisabled: payload,
      };
    case EventsActionTypes.CLIENTAPPT_LIST:
      return {
        ...state,
        clientapptlist: payload,
      };

    case EventsActionTypes.DATES_AVAILABLE:
      return {
        ...state,
        slotaavailable: payload,
      };
    case EventsActionTypes.SETAPPT_DATE:
      return {
        ...state,
        apptdateset: payload,
      };

    default:
      return state;
  }
}
