import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Box, Grid } from "@mui/material";

import Calendar from "react-calendar";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import moment from "moment";

import { checkListCal, myDateFormat } from "../common/ConstantsAndValues";
import { SelectCheck } from "./SelectCheck";
import log from "../../services/log";
import "./Calendar.css";
import {
  selectEventDate,
  setEventFetchMask,
} from "../appointment/redux/actions";

import { useDispatch, useSelector } from "react-redux";

const showDateDisabled = false; //will disable the markdate if set true and cannot navigate
const markDate = true; //will mark the markDates  with "x"

export function DatePickCal(props) {
  const { openEventDateState, markDates, openEventFilterState } = props;

  const eventsmask = useSelector((state) => state?.events?.mask);
  const selectdisabled = useSelector((state) =>
    state?.events?.selectdisabled ? state.events.selectdisabled : false
  );
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [note, setNote] = useState("");

  useEffect(() => {
    if (checkListCal) {
      !eventsmask && dispatch(setEventFetchMask({ mask: checkListCal }));
    }
  }, [checkListCal]);

  let Flag = false;

  const handleClickDay = (e) => {
    log.info("datepick", Flag);
    log.info("datepick", e);

    const seldate = format(e, myDateFormat);
    if (markDates.includes(seldate)) {
      Flag = true;
      setNote(seldate + " - This date is occupied");
    } else {
      Flag = false; //needed for resetting clicks
      setNote("");
    }
  };

  const onDateChange = (newDate) => {
    // log.info("datepick", Flag);
    // log.info("datepick", newDate);

    setDate(newDate);
    dispatch(selectEventDate({ select: newDate }));
  };

  const disableClick = () => {};

  return (
    <div className="react-calendar">
      <Calendar
        className="react-calendar"
        onChange={selectdisabled === true ? disableClick : onDateChange}
        value={date}
        showNeighboringMonth={true}
        locale={"en-US"}
        onClickDay={selectdisabled === true ? disableClick : handleClickDay}
        tileDisabled={({ activeStartDate, date, view }) =>
          showDateDisabled &&
          markDates.find((x) => x === moment(date).format("YYYY-MM-DD"))
        }
        tileContent={({ date, view }) => {
          if (markDates.find((x) => x === moment(date).format("YYYY-MM-DD"))) {
            return markDate ? "x" : "";
          }
        }}
        tileClassName={({ date, view }) => {
          if (markDates.find((x) => x === moment(date).format("YYYY-MM-DD"))) {
            return markDate ? "text-color-gray bg-gray" : "";
          }
        }}

        // onDisabledDayError={handleDisabledSelect}
      />

      <Box ml={1} mr={1} className="text-color-error ">
        {note}&nbsp;
      </Box>

      <Box ml={1} mt={2}>
        <SelectCheck
          //eventsmask={eventsmask}
          //setEventFetchMask={setEventFetchMask}
          checkList={checkListCal}
          title={"Events"}
        />
      </Box>
    </div>
  );
}
