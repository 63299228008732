import { Box, Grid } from "@mui/material";
import DateRangeInputs from "../common/DayRange";
import { ReportSelect } from "./ReportSelect";
import log from "../../services/log";

export function ReportChartHeader(props) {
  const {
    fromDateCallback,
    toDateCallback,
    fromDate,
    toDate,
    selectReport,
    tests,
    page,
  } = props;

  const fromCallBack = (date) => {
    fromDateCallback(date);
  };

  const toCallBack = (date) => {
    toDateCallback(date);
  };

  const reportCallback = (report) => {
    log.info("selected stream", report);
    selectReport(report);
  };

  return (
    <Grid p={0} mb={2} container>
      <Grid
        p={1}
        pt={1}
        item
        xs
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        className="text-color-primary  "
        //border="1px solid var(--bg-color-lighter)"
      >
        <Box pl={1} alignItems={"left"} fontSize={16}>
          {/* { 'added for alignment' }*/}
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box
            pl={1}
            content="span"
            display="inline-flex"
            sx={{ width: "auto" }}
          >
            <ReportSelect
              reportCallback={reportCallback}
              tests={tests}
              page={page}
            />
          </Box>

          <Box
            pl={1}
            content="span"
            display="inline-flex"
            sx={{ width: "auto" }}
          >
            <DateRangeInputs
              toCallBack={toCallBack}
              fromCallBack={fromCallBack}
              fromDate={fromDate}
              toDate={toDate}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
