import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { MenuItem } from "@mui/material";
import { Select } from "@material-ui/core";
import { NativeSelect } from "@mui/material";

import useStyles from "./useStyles";

import { SpecialistStatusRoleOptions } from "../common/ConstantsAndValues";

export default function InputSpecialistStatusRole(props) {
  const { id, values, label, handleChange, name, errors } = props;
  const clases = useStyles();

  return (
    <>
      <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="SpecialistStatusRole">
          <span className="text-color">{label}</span>
        </InputLabel>

        <NativeSelect
          variant="outlined"
          py={1}
          ml={1}
          px={2}
          className="text-color-primary"
          style={{
            width: "100%",
            marginTop: "40px",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "2px",
            paddingBottom: "3px",
          }}
          error={errors ? true : false}
          className={errors ? "" : "text-color"}
          labelid="SpecialistStatusRole"
          label="Status"
          id="SpecialistStatusRole"
          name={name}
          type={"text"}
          value={values}
          onChange={handleChange}
        >
          <option value={""}>{""}</option>

          {SpecialistStatusRoleOptions.map((option) => (
            <option
              key={option.label}
              value={option.value}
              className="text-color"
            >
              {option.label}
            </option>
          ))}
        </NativeSelect>
        {errors && !values && (
          <span className={clases.errorMsg}>Field is mandatory</span>
        )}
      </FormControl>
    </>
  );
}
