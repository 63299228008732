import React, { useEffect, useState } from "react";

export const WhiteTickIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{
        transform: `scale(${size})`,
        position: "absolute",
        top: "37px",
        left: "3px",
        zIndex: "55",
      }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
    >
      <g clipPath="url(#clip0_1190_17684)">
        <path
          d="M1.43061 7.03033C1.7235 6.73744 2.19837 6.73744 2.49127 7.03033L5.39306 9.93213C5.68596 10.225 5.68596 10.6999 5.39306 10.9928C5.10017 11.2857 4.6253 11.2857 4.3324 10.9928L1.43061 8.09099C1.13771 7.7981 1.13771 7.32322 1.43061 7.03033Z"
          fill="white"
        />
        <rect
          x="3.83594"
          y="10.4609"
          width="12.4347"
          height="1.5"
          rx="0.75"
          transform="rotate(-45 3.83594 10.4609)"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1190_17684">
          <rect
            width="13.4531"
            height="13.4531"
            fill="white"
            transform="translate(0.273438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
