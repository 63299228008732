/**
 * filter by user
 * @param s
 * @param user
 * @returns {{}}
 */

export const buildUserAppointmentQuery = (s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData.metadata["userId"] = user?.metadata?.accountId;
  return jsonData;
};

/**
 * filter by provider
 * @param s
 * @param user
 * @returns {{}}
 */
export const buildProviderAppointmentQuery = (s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData.metadata["providerId"] = user?.metadata?.accountId;
  return jsonData;
};

/**
 * filter by guest
 * @param s
 * @param user
 * @returns {{}}
 */
export const buildGuestAppointmentQuery = (s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData.metadata["guestId"] = user?.metadata?.accountId;
  return jsonData;
};
