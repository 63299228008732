////////////////////////////////////////////////////
//                 common for metafin             //
////////////////////////////////////////////////////
import * as React from "react";

import { ContainerFIN } from "../finance/components/ContainerFIN";
import { commonSearchMenuOptions } from "./ConstantsAndValues";

import InventoryTwoToneIcon from "@mui/icons-material/InventoryTwoTone";
import PersonalVideoTwoToneIcon from "@mui/icons-material/PersonalVideoTwoTone";
import FormatListBulletedTwoToneIcon from "@mui/icons-material/FormatListBulletedTwoTone";

import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import TimelineTwoToneIcon from "@mui/icons-material/TimelineTwoTone";
import AddAlertTwoToneIcon from "@mui/icons-material/AddAlertTwoTone";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import CreateNewFolderTwoToneIcon from "@mui/icons-material/CreateNewFolderTwoTone";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import UpgradeTwoToneIcon from "@mui/icons-material/UpgradeTwoTone";
import SwitchAccountTwoToneIcon from "@mui/icons-material/SwitchAccountTwoTone";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";

export function ApptFIN() {
  const containerType = {
    eventType: ["event"],
    type: "appointment",
    label: "Appointments",
    menuList: [
      {
        _id: "finlist",
        label: "Calendar",
        icon: <CalendarMonthTwoToneIcon />,
        link: "/metafin/appointments/cal",
        more: false,
      },
      {
        _id: "finanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metafin/appointments/analytics",
        more: true,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/appointments/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/appointments/messages",
        more: true,
      },
    ],
    comboLabel: "Any more addl combo box",
    comboList: [],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function AssoFIN() {
  const containerType = {
    type: "associates",
    label: "Associates",
    menuList: [
      {
        _id: "finlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/associates/list",
        more: false,
      },
      {
        _id: "fintodo",
        label: "To Do",
        icon: <FormatListBulletedTwoToneIcon />,
        link: "/metafin/associates/todo",
        more: false,
      },
      {
        _id: "finanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metafin/associates/analytics",
        more: true,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/associates/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/associates/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function DeptFIN() {
  const containerType = {
    type: "departments",
    label: "Departments",
    menuList: [
      {
        _id: "finlist",
        label: "Info",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/departments/info",
        more: false,
      },
      {
        _id: "finmanage",
        label: "Manage",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metafin/departments/manage",
        more: false,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/departments/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/departments/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function FaciFIN() {
  const containerType = {
    type: "facilities",
    label: "Facilities",
    menuList: [
      {
        _id: "finlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/facilities/list",
        more: false,
      },
      {
        _id: "fintodo",
        label: "To Do",
        icon: <FormatListBulletedTwoToneIcon />,
        link: "/metafin/facilities/todo",
        more: false,
      },
      {
        _id: "finanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metafin/facilities/analytics",
        more: true,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/facilities/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/facilities/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function InfoFIN() {
  const containerType = {
    type: "info",
    label: "Information",
    menuList: [
      {
        _id: "finlist",
        label: "Info",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/info/info",
        more: false,
      },
      {
        _id: "finmmanage",
        label: "Manage",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metafin/info/manage",
        more: false,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/info/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/info/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function MoniFIN() {
  const containerType = {
    type: "monitor",
    label: "Monitor",
    menuList: [
      {
        _id: "finlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/monitor/list",
        more: false,
      },
      {
        _id: "finanalysis",
        label: "Analysis",
        icon: <TimelineTwoToneIcon />,
        link: "/metafin/monitor/analysis",
        more: false,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/monitor/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/monitor/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function AccFIN() {
  const containerType = {
    type: "accounts",
    label: "Accounts",
    menuList: [
      {
        _id: "finlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/accounts/list",
        more: false,
      },
      {
        _id: "finledger",
        label: "Ledger",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/accounts/ledger",
        more: false,
      },
      {
        _id: "finanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metafin/accounts/analytics",
        more: true,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/accounts/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/accounts/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ProfFIN() {
  const containerType = {
    type: "profile",
    label: "Profile",
    menuList: [
      {
        _id: "finlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/profile/list",
        more: false,
      },
      {
        _id: "finanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metafin/profile/analytics",
        more: true,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/profile/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/profile/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function RepoFIN() {
  const containerType = {
    type: "reports",
    label: "Reports",
    menuList: [
      {
        _id: "finlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/reports/list",
        more: false,
      },
      {
        _id: "finanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metafin/reports/analytics",
        more: true,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/reports/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/reports/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ResoFIN() {
  const containerType = {
    type: "resources",
    label: "Resources",
    menuList: [
      {
        _id: "finlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/resources/list",
        more: false,
      },
      {
        _id: "fintodo",
        label: "To Do",
        icon: <FormatListBulletedTwoToneIcon />,
        link: "/metafin/resources/todo",
        more: false,
      },
      {
        _id: "finanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metafin/resources/analytics",
        more: true,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/resources/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/resources/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function SuppFIN() {
  const containerType = {
    type: "supplies",
    label: "Supplies",
    menuList: [
      {
        _id: "finlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/supplies/list",
        more: false,
      },
      {
        _id: "fintodo",
        label: "To Do",
        icon: <FormatListBulletedTwoToneIcon />,
        link: "/metafin/supplies/todo",
        more: false,
      },
      {
        _id: "finsupply",
        label: "Supplies",
        icon: <InventoryTwoToneIcon />,
        link: "/metafin/supplies/supplies",
        more: false,
      },
      {
        _id: "finanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metafin/supplies/analytics",
        more: true,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/supplies/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/supplies/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function SetFIN() {
  const containerType = {
    eventType: ["setup"],
    type: "setup",
    label: "Settings",
    menuList: [
      {
        _id: "finlist",
        label: "Accounts",
        icon: <PrivacyTipTwoToneIcon />,
        link: "/metafin/setup/privacy",
        more: false,
      },
      {
        _id: "finsettings",
        label: "Settings",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metafin/setup/settings",
        more: false,
      },
      {
        _id: "finuploads",
        label: "Uploads",
        icon: <UpgradeTwoToneIcon />,
        link: "/metafin/setup/uploads",
        more: false,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/setup/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/setup/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function SpecFIN() {
  const containerType = {
    type: "specialists",
    label: "Specialists",
    menuList: [
      {
        _id: "finlist",
        label: "Info",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/specialists/info",
        more: false,
      },
      {
        _id: "finanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metafin/specialists/analytics",
        more: true,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/specialists/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/specialists/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function TodoFIN() {
  const containerType = {
    type: "todo",
    label: "Management",
    menuList: [
      {
        _id: "finlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/todo/list",
        more: false,
      },
      {
        _id: "finanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metafin/todo/analytics",
        more: true,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/todo/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/todo/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function MetafinDoThisNowOrig() {
  const containerType = {
    type: "do",
    label: "Broadcast Now",
    menuList: [
      {
        _id: "finlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metafin/do/list",
        more: false,
      },
      {
        _id: "finanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metafin/do/analytics",
        more: true,
      },
      {
        _id: "finalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metafin/do/notifications",
        more: true,
      },
      {
        _id: "finmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metafin/do/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerFIN
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}
