import React, { useState } from "react";
import ReactSpeedometer from "./speedo";

export const MetaBmiDial = (props) => {
  console.log(props);

  return (
    <ReactSpeedometer
      currentValueText={props.currentValueText}
      value={props.values.value}
      forceRender={true}
      segments={props.values.segments}
      segmentColors={props.segmentColors}
      width={props.values.width}
      height={props.values.height}
      textColor={"#AAA"}
      customSegmentStops={props.customSegmentStops}
      customSegmentLabels={props.innerLabels}
      ringWidth={props.values.ringWidth}
      customSegmentInnerLabels={props.innerLabels}
      customSegmentOuterLabels={props.outerLabels}
    />
  );
};
export default MetaBmiDial;
