import React, { useState } from "react";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Container,
  Stack,
  Tooltip,
  Button,
  Checkbox,
} from "@mui/material";
import { MyTooltipSpan } from "../CustomComponents/MyButtons";
import { Title } from "./Title";

export function FileUploadPage(props) {
  const { handleSubmit, title } = props;
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleSubmitHere = () => {
    handleSubmit(selectedFile);
  };

  return (
    <>
      <Grid item xs={5}>
        {title && <Title>{title}</Title>}
        <Box mb={2}>
          <input
            type="file"
            name="file"
            onChange={changeHandler}
            className="border-db text-color-primary"
            style={{ padding: "8px", width: "100%" }}
          />
        </Box>

        {isSelected && (
          <Box ml={1}>
            <div className="colfl0">Filename:</div>
            <div className="colfr0 text-color-primary right">
              {selectedFile?.name}
            </div>
            <div className="colfl0 clear">Type:</div>
            <div
              className="colfr0 text-color-primary right"
              style={{ width: "75%" }}
            >
              {selectedFile.type}
            </div>
            <div className="colfl0 clear">Size(kb):</div>
            <div className="colfr0 text-color-primary right">
              {parseFloat(selectedFile.size / 1024).toFixed(2)}
            </div>
            <div className="colfl0 clear">Modified on:</div>
            <div className="colfr0 text-color-primary right">
              {selectedFile.lastModifiedDate.toLocaleDateString()}
            </div>
            <div className="clear" />
          </Box>
        )}
      </Grid>
      <Grid item xs={1}>
        <MyTooltipSpan
          onClick={handleSubmitHere}
          btnKey={"upload"}
          btnLabel={"Upload"}
          enState={false}
        />
      </Grid>
    </>
  );
}

{
  /*
        <Tooltip
            title = {"Upload"}
        >
            <span
                style              = {{marginRight:"0px"}}
                onClick            = {handleSubmitHere}
                className          = "btn-sm border-db btn-icon btn-round text-color cursor-pointer" 
            >    
                <UploadFileTwoToneIcon />
            </span> 
        </Tooltip>

            <MyTooltipSpan
                onClick    = {handleSubmitHere}
                btnKey     = {"upload"}
                btnLabel   = {"Upload"}
                enState    = {false} 
            />

*/
}
