const metaPalette = {
  primary: {
    dark: "#19445e",
    maind: "#2a8aa7",
    main: "#5fa7bd",
    mainl: "#94c4d2",
    light: "#c9e1e9",
    lightl: "#eaf4f7",
  },
  secondary: {
    dark: "#ce5303",
    maind: "#ff6d0d",
    main: "#ff924a",
    mainl: "#ffb585",
    light: "#ffdac2",
    lightl: "#fff1e7",
  },
  gray: {
    black: "#000000",
    dark: "#202020",
    maind: "#585858",
    main: "#8f8f8f",
    mainl: "#c7c7c7",
    light: "#e9e9e9",
    white: "#ffffff",
  },
};

export const metaThemeConstants = {
  breakpoints: {
    values: {
      xs: 0, // Mobile
      sm: 640, // Start of Tablet
      md: 1024, // End of Tablet and start of Desktop
      lg: 1280, // Larger Desktop
      xl: 1536, // Extra-large Desktop
    },
  },
  logobox: {
    padding: 2,
    height: 60,
    width: 220,
    maxHeight: 60,
    maxWidth: 220,
  },
  pallete: metaPalette,
};
