import * as React from "react";
import { Grid } from "@mui/material";
import MyAccountSetup from "../../payments/MyAccountSetupPHA";

export default function SetProfileListPHA() {
  return (
    <Grid container>
      <Grid item xs>
        <MyAccountSetup />
      </Grid>
    </Grid>
  );
}
