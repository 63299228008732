import * as React from "react";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Grid, Box, Typography } from "@mui/material";
import { Form, useFormik } from "formik";
import { SelectOptionsAuto } from "../common/SelectOptions";
import { useDispatch, useSelector } from "react-redux";
import { setDrugPrecriptionList } from "../../redux/drugs/drugs.actions";

import { LocMSStyles } from "../location/LocMSStyles";
import { doseFrequency, doseRoute, doseUnits } from "./MedicineDosage";

import InputFormComponent from "../CustomComponents/InputFormComponent";
import { MyTooltipButtons, MyHelpButtons } from "../CustomComponents/MyButtons";

import log from "../../services/log";
import { drugPrescriptionSchema } from "../schema/prescriptionSchema";

export const MedicineInput = (props) => {
  const { doctor, patient, cancel } = props;

  const preslist = useSelector((state) => state?.drugs?.preslist);
  const medicine = useSelector((state) => state?.drugs?.selectdrugs);

  const [routeOptions, setRouteOptions] = useState([]);
  const [freqOptions, setFreqOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (doseUnits) {
      const newoptions = [];
      doseUnits.forEach((v) =>
        newoptions.push({ label: v.abbr + "-" + v.value, value: v })
      );
      setUnitOptions(newoptions);
    } else {
      setUnitOptions([]);
    }
  }, [doseUnits]);

  useEffect(() => {
    if (doseRoute) {
      const newoptions = [];
      doseRoute.forEach((v) =>
        newoptions.push({ label: v.abbr + "-" + v.value, value: v })
      );
      setRouteOptions(newoptions);
    } else {
      setRouteOptions([]);
    }
  }, [doseRoute]);

  useEffect(() => {
    if (doseFrequency) {
      const newoptions = [];
      doseFrequency.forEach((v) =>
        newoptions.push({ label: v.abbr + "-" + v.value, value: v })
      );
      setFreqOptions(newoptions);
    } else {
      setFreqOptions([]);
    }
  }, [doseFrequency]);

  const medicineform = useFormik({
    initialValues: {
      doctorId: doctor ? doctor.metadata.accountId : "",
      doctorName: doctor ? doctor.name : "",
      patientId: patient ? patient.metadata.patientId : "",
      patientName: patient ? patient.name : "",
      medicineId: medicine ? medicine._source.Id : "",
      medicineName: medicine ? medicine._source.name : "",
      medicineSalt: medicine ? medicine._source.salt : "",
      dose: 1,
      doseRoute: null,
      doseFrequency: null,
      doseDuration: 1,
      refill: 0,
      refillPeriod: "",
      status: "active",
      currentlist: preslist ? [...preslist] : [],
    },
    validationSchema: drugPrescriptionSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("medicineinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["doctorId"] = values.doctorId;
      jsonData.metadata["patientId"] = values.patientId;
      jsonData.metadata["medicineId"] = values.medicineId;
      jsonData["doctorName"] = values.doctorName;
      jsonData["patientName"] = values.patientName;
      jsonData["medicineName"] = {};
      jsonData.medicineName["name"] = values.medicineName;
      jsonData.medicineName["salt"] = values.medicineSalt;
      jsonData["medicineDosage"] = {};
      jsonData.medicineDosage["dose"] = values.dose;
      jsonData.medicineDosage["doseUnit"] = values.doseUnit.value.value;
      jsonData.medicineDosage["doseRoute"] = values.doseRoute.value.value;
      jsonData.medicineDosage["doseFrequency"] =
        values.doseFrequency.value.value;
      jsonData.medicineDosage["doseDuration"] = values.doseDuration;
      jsonData["refills"] = {};
      jsonData.refills["numRefill"] = values.refill;
      jsonData.refills["period"] = values.period;
      jsonData["status"] = values.status;

      values.currentlist.push(jsonData);
      // log.info("medicineinput", preslist);
      dispatch(setDrugPrecriptionList(values.currentlist));
      cancel();
    },
    onReset: (values) => {
      values.dose = 1;
    },
  });

  const doseRouteOptions = (routes) => {
    const options = [];
    routes.forEach((v) => options.push(v.value));
    return options;
  };

  const addPrescription = () => {
    // dispatch(setPrecriptionList());
  };

  const cancelEvent = () => {
    cancel();
  };

  const handleDoseFrequency = (e) => {
    log.info("medicineinput", e);
    medicineform.setFieldValue("doseFrequency", e);
  };

  const handleRouteChange = (e) => {
    log.info("medicineinput", e);
    medicineform.setFieldValue("doseRoute", e);
  };

  const handleUnitChange = (e) => {
    log.info("medicineinput", e);
    medicineform.setFieldValue("doseUnit", e);
  };

  log.info("medicineinput", medicineform.values);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%", md: "50%", lg: "40%" },
          minWidth: { xs: "100%", sm: "90%", md: "50%", lg: "40%" },
        }}
      >
        <form>
          <Box className="colfr0 " mb={1}>
            <MyTooltipButtons
              onClick={cancelEvent}
              btnKey={"cancel"}
              btnLabel={"Cancel"}
              enState={false}
            />
          </Box>
          <Box className="clear"></Box>
          <Box width="100%" className="text-color-primary">
            {medicineform.values.medicineName && (
              <Box className="border-db p10">
                <Typography className="text-color-primary">
                  {medicineform?.values?.medicineName}
                </Typography>
              </Box>
            )}
            <Box className="border-db p10 mb-30">
              <Typography>
                Contains: <i>{medicineform?.values?.medicineSalt}</i>
              </Typography>
            </Box>
            <Box>
              <InputFormComponent
                id="dose"
                name="dose"
                label="Dose"
                errorText={medicineform.errors.dose}
                defaultValue={medicineform.values.dose}
                errors={medicineform.errors.dose ? true : false}
                variant="standard"
                handleChange={medicineform.handleChange}
              />
              &nbsp;
              <SelectOptionsAuto
                openPage={handleUnitChange} //medicineform.values.doseUnit
                data={unitOptions}
                multi={false}
                title={"dose unit"}
                wide={0}
              />
              &nbsp;
              <SelectOptionsAuto
                openPage={handleRouteChange} //medicineform.values.dosageRoute
                data={routeOptions}
                multi={false}
                title={"dose route"}
                wide={0}
              />
              &nbsp;
              <SelectOptionsAuto
                openPage={handleDoseFrequency} //medicineform.values.doseFrequency
                data={freqOptions}
                multi={false}
                title={"dose frequency"}
                wide={0}
              />
              &nbsp;
              <InputFormComponent
                id="doseDuration"
                name="doseDuration"
                label="Duration (Days)"
                errorText={medicineform.errors.doseDuration}
                defaultValue={medicineform.values.doseDuration}
                errors={medicineform.errors.doseDuration ? true : false}
                variant="standard"
                handleChange={medicineform.handleChange}
              />
              &nbsp;
              <InputFormComponent
                id="refill"
                name="refill"
                label="Refills"
                errorText={medicineform.errors.refill}
                defaultValue={medicineform.values.refill}
                errors={medicineform.errors.refill ? true : false}
                variant="standard"
                handleChange={medicineform.handleChange}
              />
            </Box>
          </Box>
        </form>
        <Box className="clear" />
        <Box mt={2} pt={2} className="border-dbt">
          <Box className="colfr0">
            <MyHelpButtons
              onClick={medicineform.handleSubmit}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
              btnTooltip={"Add"}
            />
          </Box>
          <Box className="clear" />
        </Box>
        <Box className="clear" />
        <Box mt={2} className="border-dbt p10 text-color-error">
          {medicineform.errors.doctorName ? (
            <Typography className="text-color-primary-highlight">
              {medicineform.errors.DoctorName}
            </Typography>
          ) : (
            <div />
          )}
          {medicineform.errors.patientName ? (
            <Typography className="text-color-primary-highlight">
              {medicineform.errors.patientName}
            </Typography>
          ) : (
            <div />
          )}
          {medicineform.errors.medicineName ? (
            <Typography className="text-color-primary-highlight">
              {medicineform.errors.medicineName}
            </Typography>
          ) : (
            <div />
          )}
          {medicineform.errors.doseUnit ? (
            <Typography className="text-color-primary-highlight">
              {medicineform.errors.doseUnit}
            </Typography>
          ) : (
            <div />
          )}
          {medicineform.errors.doseRoute ? (
            <Typography className="text-color-primary-highlight">
              {medicineform.errors.doseRoute}
            </Typography>
          ) : (
            <div />
          )}
          {medicineform.errors.doseFrequency ? (
            <Typography className="text-color-primary-highlight">
              {medicineform.errors.doseFrequency}
            </Typography>
          ) : (
            <div />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

{
  /*

                            &nbsp;
                            <Select
                                options={unitOptions}
                                placeholder={"dose unit"}
                                value={medicineform.values.doseUnit}
                                onChange={handleUnitChange}
                                isSearchable={true}
                                styles={LocMSStyles}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                isMulti={false}
                                error={true}
                            />
                            &nbsp;
                            <Select
                                options={routeOptions}
                                placeholder={"dose route"}
                                value={medicineform.values.dosageRoute}
                                defaultValue={medicineform.values.doseRoute}
                                onChange={handleRouteChange}
                                isSearchable={true}
                                styles={LocMSStyles}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                isMulti={false}
                            />
                            &nbsp;
                            <Select
                                options={freqOptions}
                                placeholder={"dose frequency"}
                                value={medicineform.values.doseFrequency}
                                onChange={handleDoseFrequency}
                                defaultValue={medicineform.values.doseFrequency}
                                isSearchable={true}
                                styles={LocMSStyles}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                isMulti={false}
                            />
*/
}
