import * as React from "react";
import { StyledTableCell, StyledTableRow } from "../common/ConstantsAndValues";

const providerListApptTable = {
  service: {
    label: "Provider",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  address: {
    label: "Address",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  city: {
    label: "City",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
};

export function ProviderListApptHead() {
  const table = providerListApptTable;

  return (
    <React.Fragment>
      <StyledTableRow style={{ fontWeight: 700 }}>
        <StyledTableCell />
        <StyledTableCell>{table.service.label}</StyledTableCell>
        <StyledTableCell>{table.address.label}</StyledTableCell>
        {/*<StyledTableCell align="right">{table.city.label}</StyledTableCell>*/}
      </StyledTableRow>
    </React.Fragment>
  );
}
