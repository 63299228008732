//////////////////////////////////////////////
//           common for metauser            //
//////////////////////////////////////////////

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BottomNavigation,
  BottomNavigationAction,
  Grid,
  Box,
  Typography,
  SearchIcon,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  CauseRSM,
  TriggerRSM,
  themeTABS,
} from "../../common/ConstantsAndValues";
import MenuRightMore from "../../common/MenuRightMore";
import { labsautocompquery } from "../../utils/labsqueries";
import { getLabsList } from "../../../redux/labs/labs.actions";
import { setDrugSearchText } from "../../../redux/drugs/drugs.actions";
import { searchUserText } from "../../../redux/user/user.actions";
import { SearchBoxMenuBar } from "../../search/SearchBoxMenuBar";
import { setAppNavxState } from "../../../redux/appstate/appstate.actions";

import log from "../../../services/log";

export function NavigateUSR(props) {
  const {
    containerType,
    openMenuState,
    closeMenuState,
    dateTogo,
    page,
    defaNav,
    setOpenRSM,
    searchOptions,
    openRSM,
  } = props;

  const openSideBar = useSelector((state) => state?.appstate?.opensidebar);
  const navxstate = useSelector((state) => state?.appstate?.navxstate);
  const osbpad = openSideBar ? "0px" : "16px";

  const [searchText, setSearchText] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openMenu, setOpenMenu] = useState(false);
  const [Navi, setNavi] = useState("");

  const handleChange = (event, newValue) => {
    //log.info("navx value", event);
    dispatch(setAppNavxState(newValue));

    if (newValue) {
      navigate(newValue);
    }

    setValue(newValue);
    const newNavi = newValue
      .slice(newValue.lastIndexOf("/"), newValue.length)
      .substring(1);
    setNavi(newNavi);

    if (CauseRSM.indexOf(newNavi) !== -1) {
      openMenuState(newNavi);
    } else {
      closeMenuState();
    }
  };

  const updateText = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const handleSearch = () => {
    console.log(searchText);
  };
  const mtitle = page ? page : containerType.label;

  function NaviSearch() {
    return (
      <SearchBoxMenuBar
        handleSearch={handleSearch}
        placeholder=" Search"
        updateText={updateText}
        searchOptions={searchOptions}
      />
    );
  }

  function NaviContent() {
    return (
      <ThemeProvider theme={themeTABS}>
        <BottomNavigation showLabels value={value} onChange={handleChange}>
          {containerType &&
            containerType.menuList
              .filter((itm) => itm.more === false)
              .map((menu) => (
                <BottomNavigationAction
                  key={menu._id}
                  value={menu.link}
                  label={menu.label}
                  icon={menu.icon}
                  sx={{
                    boxShadow: "0 !important",
                    outline: "none",
                    "&:focus": {
                      border: "0 !important",
                      outline: "none",
                      boxShadow: "none",
                    },
                  }}
                  className={
                    navxstate === menu.link
                      ? "border-active menu-text flex-fill text-color-menu-active"
                      : "menu-text flex-fill text-color-menu"
                  }
                />
              ))}
          <MenuRightMore
            setOpenRSM={setOpenRSM}
            openRSM={openRSM}
            handleChange={handleChange}
            data={containerType.menuList.filter((itm) => itm.more === true)}
          ></MenuRightMore>
        </BottomNavigation>
      </ThemeProvider>
    );
  }

  return (
    <></>
    // <Box pt={1} pb={1} display="flex" className="text-color-primary">
    //   <Box
    //     justifyContent="flex-end"
    //     flexDirection="column"
    //     sx={{
    //       flexGrow: 1,
    //       display: { xs: "flex", sm: "none", md: "none" },
    //       marginRight: 0,
    //     }}
    //   >
    //     <div
    //       style={{
    //         fontSize: "20px",
    //         padding: "16px 20px 0px 0px",
    //         width: "100%",
    //       }}
    //     >
    //       <div className="colfr0">{mtitle}</div>
    //     </div>
    //     <div style={{ padding: "8px 20px 8px 0px", width: "100%" }}>
    //       <div className="colfr0">
    //         <NaviSearch />
    //       </div>
    //     </div>
    //     <div style={{ width: "100%" }}>
    //       <div className="colfr0">
    //         <NaviContent />
    //       </div>
    //     </div>
    //   </Box>
    //   <Box
    //     justifyContent="space-between"
    //     flexDirection="column"
    //     sx={{
    //       flexGrow: 1,
    //       display: { xs: "none", sm: "flex", md: "none" },
    //       marginRight: 0,
    //     }}
    //   >
    //     <div
    //       style={{
    //         fontSize: "20px",
    //         padding: "16px 20px 8px 0px",
    //         width: "100%",
    //       }}
    //     >
    //       <div className="colfr0">{mtitle}</div>
    //     </div>
    //     <div>
    //       <Box className="colfr0" justifyContent="flex-end">
    //         <NaviContent />
    //       </Box>
    //       <div
    //         className="colfr0"
    //         style={{ marginRight: "16px", paddingTop: "6px" }}
    //       >
    //         <NaviSearch />
    //       </div>
    //     </div>
    //   </Box>

    //   <Box
    //     justifyContent="space-between"
    //     flexDirection="row"
    //     sx={{
    //       flexGrow: 1,
    //       display: { xs: "none", sm: "none", md: "flex" },
    //       marginRight: 0,
    //     }}
    //   >
    //     <div style={{ fontSize: "20px" }} className="flex-grow-1">
    //       {mtitle}
    //     </div>
    //     <div>
    //       <div
    //         className="colfl0"
    //         style={{ marginRight: "16px", paddingTop: "6px" }}
    //       >
    //         <NaviSearch />
    //       </div>
    //       <Box className="colfr0" justifyContent="flex-end">
    //         <NaviContent />
    //       </Box>
    //     </div>
    //   </Box>
    // </Box>
  );
}
