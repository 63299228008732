import { Dialog } from "@mui/material";
import { DialogTitle } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { ListItemButton } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import log from "../../services/log";
import { getAppointmentGuestList } from "../../redux/appointment/appointment.actions";

export const AffirmationDialog = (props) => {
  const { onClose, open, title, data } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    // log.info("affirmation", value)
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open ? open : false}>
      <DialogTitle>{title}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {data.map((d) => (
          <ListItem key={d.id} disableGutters>
            <ListItemButton
              className="btn-sm btn-icon btn-round text-center text-color cursor-pointer"
              onClick={() => handleListItemClick(d.value)}
              key={d.id}
            >
              <ListItemText secondary={d.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
