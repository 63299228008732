import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import log from "../../services/log";
import { useDispatch, useSelector } from "react-redux";
import { setPolicy } from "../../redux/policy/policy.actions";

export function UploadJsonFile(props) {
  const org = useSelector((state) => state?.orgs?.selectorg);

  const dispatch = useDispatch();
  const writePolicy = (policies) => {
    policies.forEach((policy) => {
      policy.metadata["providerId"] = org?.metadata?.accountId;
      dispatch(setPolicy(policy));
      log.info("policy", policy);
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          writePolicy(json);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };
      reader.readAsText(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} className="border-db" />
      <p className="text-color-primary">
        Drag 'n' Drop or Click to Select Keyfile
      </p>
    </div>
  );
}
