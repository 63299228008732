import * as yup from "yup";

export const orgInputSchema = yup.object({
  id: yup.string(),
  userId: yup.string().required("admin is required"),
  email: yup.string().required("admin email is required"),
  orgName: yup.string().required("organization name is required"),
  orgDescp: yup.string().required("organization description is required"),
  slotDuration: yup.string().required("slot duration is required"),
  daysOfWeek: yup.array().required("working days are required"),
  startTime: yup.string().required("working time is required"),
  endTime: yup.string().required("working time is required"),
  secureId: yup.boolean(),
  mobile: yup
    .number()
    .typeError("it is not a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(10)
    .required("A phone number is required"),
  status: yup.string(),
});

export const orgGroupSchema = yup.object({
  id: yup.string(),
  orgId: yup.string().required("organization is required"),
  userId: yup.string().required("organization is required"),
  email: yup.string().required("email is required"),
  groupName: yup.string().required("name is required"),
  groupDescp: yup.string().required("description is required"),
  status: yup.string(),
});

export const orgProfile = yup.object({
  id: yup.string(),
  accountId: yup.string().required("organization is required"),
  email: yup.string().required("email is required"),
  name: yup.string().required("name is required"),
  about: yup.string().required("description is required"),
});
