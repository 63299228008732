import React from "react";
//import Link from '@material-ui/core/Link';
import { makeStyles } from "@material-ui/core/styles";
//import Table from '@material-ui/core/Table';
//import TableBody from '@material-ui/core/TableBody';
//import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
//import TableRow from '@material-ui/core/TableRow';

import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { Title } from "../common/Title";

//
function createData(id, date, details, source, readFlag, replyFlag) {
  return { id, date, details, source, readFlag, replyFlag };
}

const TopHeading = (props) => (
  <TableCell
    className="text-color-primary"
    display="flex"
    sx={{
      //fontSize:  '18px',
      fontWeight: 200,
    }}
  >
    {props.children}
  </TableCell>
);
const rows = [
  createData(0, "16 Mar, 2022", "please read prescription well", "rk", 1, 1),
  createData(1, "17 Mar, 2022", "appointment request", "me", 1, 0),
  createData(2, "18 Mar, 2022", "reschedule", "Boston", 1, 1),
  createData(3, "19 Mar, 2022", "do tests", "rk", 1, 0),
  createData(4, "20 Mar, 2022", "please collect reports", "labs", 0, 0),
];

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function MessageList() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Title>Recent Messages</Title>
      <Table size="small" className="text-color" width="100%">
        <TableHead>
          <TableRow>
            <TopHeading>Date</TopHeading>
            <TopHeading>Message</TopHeading>
            <TopHeading>Source</TopHeading>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.details}</TableCell>
              <TableCell>{row.source}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="text-color">
        <Link className="text-color" href="#" onClick={preventDefault}>
          {/*    more...*/}
        </Link>
      </div>
    </React.Fragment>
  );
}
