/**
 * filter address for user
 * @param p
 * @param s
 * @param user
 * @returns {{}}
 */
export const buildAddressQuery = (p, s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p?.page;
  jsonData["limit"] = p?.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["userId"] = user?.metadata?.accountId;
  return jsonData;
};
