import StudyListRoute from "./viewer/studylist/StudyListRoute";
import { DicomContext, withDicomContext } from "./viewer/context/DicomContext";
import { useContext, useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import OHIF from "./core";
import { useSelector, useDispatch } from "react-redux";
import { setServers } from "./core/redux/actions";
import log from "../../services/log";

import { HeadUrl } from "../common/ConstantsAndValues";

const { urlUtil: UrlUtil } = OHIF.utils;

export const DicomViewer = (props) => {
  const { gtype } = props;

  const useDicomContext = () => useContext(DicomContext);
  const { appConfig, activeContexts } = useDicomContext();
  const [imagelist, setImageList] = useState(null);
  const [dicomservers, setDicomServers] = useState(
    appConfig.appConfig.servers.dicomWeb[0]
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  localStorage.setItem("prevURL", JSON.stringify(HeadUrl));

  const thisUrl = "/" + gtype + "/mimages/dicom/images";

  useEffect(() => {
    dicomservers.active = true;
    dicomservers.type = "dicomWeb";
    dispatch(setServers([dicomservers]));
  }, [dicomservers]);

  const imageSelected = (images) => {
    setImageList(images);
    log.info("DicomViewer", images);

    if (gtype) {
      navigate(thisUrl, { state: images });
    }
  };

  return (
    <StudyListRoute
      setImageList={imageSelected}
      server={appConfig.appConfig.servers.dicomWeb[0]}
    />
  );
};
