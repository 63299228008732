import React from "react";
import { useState } from "react";

import { TextEditor } from "../editor/TextEditor";
import log from "../../services/log";

export const NotesEditor = (props) => {
  const { addNotes, addToMsg, isProtect, isColm, isFullHeight } = props;

  const addTextEditor = (e) => {
    // console.log("noteseditor", e);
    addNotes(e);
  };

  return (
    <TextEditor
      editorId={"clinicNotes"}
      addTextEditor={addTextEditor}
      addToMsg={addToMsg}
      isProtect={isProtect}
      isColm={isColm}
      isFullHeight={isFullHeight}
    />
  );
};
