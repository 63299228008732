let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

export function getHashValues(hash) {
  return Object.values(hash); // needs modern browser
}

export function hashById(array) {
  let hash = {};

  for (let item of array) {
    hash[item.id] = item;
  }

  return hash;
}

export function excludeById(array, id) {
  return array.filter((item) => item.id !== id);
}

export function getTodayStr() {
  return new Date().toISOString().replace(/T.*$/, "");
}

export function createEventId() {
  return String(eventGuid++);
}

export function aggregateEvents(events) {
  var aggregatedEvents = [];
  var eventsMap = {};

  events?.forEach(function (event) {
    var date = event.dateStart.split("T")[0];
    if (!eventsMap[date]) {
      eventsMap[date] = [];
    }
    eventsMap[date].push(event);
  });

  for (var date in eventsMap) {
    aggregatedEvents.push({
      start: date,
      count: eventsMap[date].length,
      source: eventsMap[date],
    });
  }

  return aggregatedEvents;
}
