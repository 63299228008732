import React from "react";
import ReactDOM from "react-dom";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, Tabs, Tab, AppBar } from "@mui/material";

import { Title } from "../../common/Title";

export default function PresMonitorPHA() {
  return <div></div>;
}
