const defaultHotkeys = {
  "mod+b": {
    type: "mark",
    value: "bold",
  },
  "mod+i": {
    type: "mark",
    value: "italic",
  },
  "mod+u": {
    type: "mark",
    value: "underlined",
  },
  "mod+`": {
    type: "mark",
    value: "code",
  },
  "shift+enter": {
    type: "newline",
    value: "",
  },
};
export default defaultHotkeys;
