import React, { useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import { Button, Typography } from "@mui/material";
import ZoomInTwoToneIcon from "@mui/icons-material/ZoomInTwoTone";
import DateRangeInputs from "../common/DayRange";
import { MetaGraphtest, MetaGraphtest2 } from "./components/MetaGraphs";
//import LineAreaChart                                       from "./d3bar/LineAreaChart";
//import {DateRangeSlider}                                   from "../common/DateRangeSlider";
import LineAreaZoomChart from "./d3bar/LineAreaZoomChart";
import { tableParam, colChart } from "../common/ConstantsAndValues";
import log from "../../services/log";

/**
 * data stream -
 *  date, value
 *  multiple streams
 *  m1, m2, ...
 *  m1 (m1date, value), m2(m2date, value), m3(m3date, value) ...
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export const ChartFormat = (props) => {
  const { chartType, selectedChart, fromDate, toDate, data, streamdata, page } =
    props;

  //const [selectedItems, setSelectedItems] = React.useState([]);
  //const legendData = [portfolioData, schcData, vcitData];
  const [rangeIndex, setRangeIndex] = useState([0, 100]);

  const desc = streamdata ? streamdata[0].desc : "";

  const zoomWindow = (value) => {
    //console.log(value)
    setRangeIndex(value);
  };

  log.info("chart ", chartType);

  return (
    <Grid
      mt={1}
      mb={1}
      pt={1}
      item
      sx={{
        width: "auto",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
      }}
      className={selectedChart ? "text-color-primary border-db" : ""}
    >
      <Box p={2} mb={0} display="flex" sx={{ width: "auto" }}>
        {props.selectedChart && streamdata && (
          <LineAreaZoomChart
            chartType={chartType}
            data={props.data}
            streamdata={streamdata[0]}
            period={[fromDate, toDate]}
          />
        )}
      </Box>

      <Box
        className="text-color-read "
        p={2}
        mb={0}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        sx={{
          width: {
            xs: "100%",
            sm: "80%",
            md: colChart.width / 2,
            lg: colChart.width / 2,
          },
          minWidth: "120px",
          whiteSpace: "pre-wrap",
          overflowWrap: "break-word",
          fontSize: { xs: 12, sm: 12, md: 14, lg: 16 },
        }}
      >
        <div sx={{ width: "100%" }}>
          <Typography className="text-color">{selectedChart?.label}</Typography>
        </div>
        <div sx={{ width: "100%" }}>
          <Typography variant={"body2"}>{desc}</Typography>
        </div>
      </Box>
    </Grid>
  );
};
