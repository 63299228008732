import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, createRef, useState } from "react";
import { Grid, Box } from "@mui/material";
import {
  getProviderAppointmentByid,
  getAppointmentGuestList,
  getProviderAppointmentList,
  updateProviderAppointment,
  removeProviderAppointment,
  setApptDates,
  getProviderAppointmentRangeList,
  getAppointmentDateSlots,
} from "../../redux/appointment/appointment.actions";

import { format } from "date-fns";
import { CalEventmove } from "./CalEventmove";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  eventColorBG,
  useWindowSize,
  TriggerCAL,
  calV,
  calInstruction,
} from "../common/ConstantsAndValues";
import { CalInputform } from "./CalInputform";
import { Calupdateform } from "./Calupdateform";
import { EventView } from "./EventView";
import log from "../../services/log";
import { selectRightDisable, setEventSelectDate } from "./redux/actions";

function renderEventContent(eventInfo) {
  return <span className="text-color">{eventInfo.event.title}</span>;
}

/**
 * view provider calendar
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function Calendartype(props) {
  const { disableContinue } = props;

  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );
  const provider = useSelector(
    (state) => state?.directory?.selectproviderlistrow
  );
  const apptlist = useSelector((state) => state?.appointment?.clientapptlist);
  const eventmask = useSelector((state) => state?.events?.mask);
  const selectdate = useSelector((state) => state?.events?.select);
  const appt = useSelector((state) => state?.appointment?.appt);
  const newdate = useSelector((state) => state?.appointment?.dates);
  const selectorg = useSelector((state) => state?.orgs?.selectorg);

  const dispatch = useDispatch();
  const calendarRef = createRef();
  const size = useWindowSize();
  const calOffset =
    size.width < TriggerCAL.a
      ? 8
      : size.width < TriggerCAL.b && size.width >= TriggerCAL.a
      ? 4
      : 0;

  const buildQuery = () => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata["providerId"] = provider?._source?.providerId;
    // log.info("provider task list", jsonData);
    return jsonData;
  };

  const buildRangeQuery = (range) => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata["providerId"] = provider?._source?.providerId;
    jsonData["range"] = {};
    jsonData.range["startDate"] = range?.startStr;
    jsonData.range["endDate"] = range?.endStr;
    // log.info("provider task list", jsonData);
    return jsonData;
  };

  const buildslotQuery = (rangeinfo) => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata["providerId"] = provider?._source?.providerId;
    jsonData["range"] = {};
    jsonData.range["startDate"] = rangeinfo.startStr;
    jsonData.range["endDate"] = rangeinfo.endStr;
    // log.info("provider task list", jsonData);
    return jsonData;
  };

  useEffect(() => {
    if (user) {
      dispatch(getProviderAppointmentList(buildQuery()));
    }
  }, [provider, dispatch, size]);

  const [eventInfo, setEventInfo] = useState({});
  const [date, setDate] = useState(new Date());
  const [eventdate, setEventDate] = useState(new Date());
  const [datePast, setDatePast] = useState(false);
  const [dateFlag, setDateFlag] = useState(false);
  const [alertMessage, setAlertMessage] = useState(calInstruction.default);
  const [calstate, setCalState] = useState("");
  const [eventmove, setEventmove] = useState(false);

  const today = new Date(new Date().setHours(0, 0, 0, 0));

  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    log.info("metacal add event", calendarApi);

    if (calendarApi && apptlist && eventmask) {
      calendarApi.removeAllEvents();

      // all event setting
      const allevent = eventmask.filter((e) => e._id === "all");

      log.info("metacal allevent", allevent);
      allevent.filter((e) => {
        /**
         * rendering all events
         */
        if (e.isChecked) {
          // all events
          apptlist.forEach((v) => {
            calendarApi.addEvent(
              {
                title: v.eventTitle,
                start: v.dateStart,
                end: v.dateEnd,
                id: v.id,
                sourceId: v.metadata.eventId,
                backgroundColor: eventColorBG.mask,
              },
              true
            );
          });
        } else {
          // fitler checked events
          const events = eventmask.filter(
            (e) => e._id !== "all" && e.isChecked == true
          );
          events.forEach((f) => {
            const filtevents = apptlist.filter(
              (e) => e.metadata.eventType === f._id
            );

            filtevents &&
              filtevents.forEach((v) => {
                calendarApi.addEvent(
                  {
                    title: v.eventTitle,
                    start: v.dateStart,
                    end: v.dateEnd,
                    id: v.id,
                    sourceId: v.metadata.eventId,
                    backgroundColor: eventColorBG.mask,
                  },
                  true
                );
              });
          });
        }
      });
    }
  }, [apptlist, eventmask]);

  useEffect(() => {
    /**
     * setting from rsm
     * @type {CalendarApi}
     */
    const calendarApi = calendarRef?.current?.getApi();

    if (selectdate) {
      calendarApi?.gotoDate(selectdate);
      setDate(selectdate);

      {
        calendarApi?.view.type === "dayGridMonth" &&
          calendarApi?.changeView("timeGridDay");
      }
    }
  }, [selectdate]);

  const updateEvent = () => {
    // console.log('update event', eventInfo);

    dispatch(
      getAppointmentGuestList({ metadata: { eventId: appt.metadata.eventId } })
    ).then((r) => {
      // log.info("event move", r);
      r.results &&
        r.results.forEach((e) => {
          dispatch(
            updateProviderAppointment({
              id: e.id,
              dateEnd: newdate.endStr,
              dateStart: newdate.startStr,
            })
          );
        });
    });
  };

  const removeEvent = () => {
    eventInfo.remove();
  };

  const handleDateSelect = (selectInfo) => {
    log.info("datecal", selectInfo);
    setAlertMessage("");
    dispatch(selectRightDisable(true));

    //ADD Ph-1
    let calendarApi = selectInfo.view.calendar;
    const ispast = selectInfo.start < today ? true : false;
    setDate(selectInfo.start);
    setEventDate(selectInfo);
    setDatePast(ispast);

    const seldate = format(selectInfo.start, "yyyy-MM-dd");
    // setDateFlag((markDates.includes(seldate))? true: false )

    calendarApi.unselect(); // clear date selection

    setEventInfo(selectInfo);
    dispatch(
      setApptDates({
        startStr: selectInfo.startStr,
        endStr: selectInfo.endStr,
        allDay: selectInfo.allDay,
      })
    );
    dispatch(setEventSelectDate(true));
  };

  const handleCancel = () => {
    /**
     * closes form
     */
    setCalState("");
    dispatch(selectRightDisable(false));
  };

  /**
   * date clicked from calendar
   * @param event
   */
  const handleDateClick = (event) => {
    //ADD Ph-2
    log.info("date clicked", event.view);

    const calendarApi = calendarRef.current.getApi();
    if (event?.view?.type === "dayGridMonth") {
      calendarApi.changeView("timeGridDay", event.date);
    } else {
      let alertmsg = datePast
        ? "Past date "
        : dateFlag
        ? "Booking closed "
        : "";
      // log.info("date clicked", alertmsg);

      if (alertmsg != "") {
        alertmsg += format(date, "yyyy-MM-dd");
        setAlertMessage(alertmsg);
      } else {
        setAlertMessage("");
        // setCalState('inputform')
      }
    }
  };

  /**
   * this trigger the event update
   * @param clickInfo
   */
  const handleEventClick = (clickInfo) => {
    log.info("metacal event", clickInfo.event);
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView("timeGridDay");

    // if (event?.view?.type === 'dayGridMonth') {
    //     calendarApi.changeView('timeGridDay');
    // }

    // let dt = clickInfo.event.start
    // setDate(dt)
    // setDatePast(dt < today ? true : false)
    // const seldate = format(dt, 'yyyy-MM-dd');
    //
    // // setDateFlag(markDates && (markDates.includes(seldate))? true: false )
    //
    // if (clickInfo.event != undefined) {
    //     const jsonData = {};
    //     jsonData['id'] = clickInfo.event._def.publicId;
    //     dispatch(getProviderAppointmentByid(jsonData));
    //     setCalState('eventview')
    // }
  };

  /**
   * called on evnet darag start
   * @param removeInfo
   */
  const handleEventRemove = (removeInfo) => {
    // console.log('moving from', removeInfo)
    setEventmove(true);
  };

  /**
   * executed at the end of move
   * @param addInfo
   */
  const handleEventAdd = (addInfo) => {
    // console.log("adding movedto", addInfo)
    if (addInfo.event !== null) {
      const jsonData = {};
      jsonData["id"] = addInfo.event._def.publicId;
      dispatch(getProviderAppointmentByid(jsonData));
      if (eventmove) {
        /**
         * do update for event move
         */
        updateEvent();
        setEventmove(false);
      }
    }
  };

  const handleEventDropAllow = (changeInfoAllow) => {
    {
      /*Drop case*/
    }

    // log.info("event dragallow", changeInfoAllow);

    //DRAGDROP Ph-0?

    const dt = changeInfoAllow.start;
    const seldate = format(dt, "yyyy-MM-dd");
    const past = dt < today ? true : false;

    // const full = markDates && (markDates.includes(seldate))? true: false ;

    setDate(dt);
    setDatePast(past);
    // setDateFlag(full)

    dispatch(
      setApptDates({
        startStr: changeInfoAllow.startStr,
        endStr: changeInfoAllow.endStr,
        allDay: changeInfoAllow.allDay,
      })
    );
  };

  const handleEventMouseEnter = (Info) => {
    //not used
  };
  const handleEventDrop = (changeInfo) => {
    {
      /*Drop case*/
    }
    log.info("dragdrop", changeInfo);
    //DRAGDROP Ph-1
  };

  /**
   * drag of event
   * this event does not work
   * @param changeInfo
   */
  const handleEventChange = (changeInfo) => {
    // log.info("moveevent to ", changeInfo.event);
    if (!datePast && !dateFlag) {
      dispatch(
        updateProviderAppointment({
          id: changeInfo.event.id,
          eventTitle: changeInfo.event.title,
          dateStart: changeInfo.event.startStr,
          dateEnd: changeInfo.event.endStr,
        })
      );
    }
  };

  /**
   * update date states
   */
  const updateDatesState = (range) => {
    let day = 60 * 60 * 24 * 1000;
    const starttime = range.end.getTime();
    const endtime = range.start.getTime();
    const days = Math.floor((starttime - endtime) / day);
    let datesAvailable = Array(days).fill(16);
    dispatch(getAppointmentDateSlots(buildslotQuery(range))).then((r) =>
      log.info("received slots info", r)
    );
    let filterday = range.start.getTime();
    log.info("days", days);
    log.info("aggregating events ", range.start.getTime());
    log.info("aggregating events ", range.end.getTime());
    log.info("available slots", datesAvailable);
  };

  /**
   * get called on range select
   * @param rangeInfo
   */
  const handleDates = (rangeInfo) => {
    // log.info('calendar range', rangeInfo);
    if (user && rangeInfo) {
      dispatch(getProviderAppointmentRangeList(buildRangeQuery(rangeInfo)));
    }
    updateDatesState(rangeInfo);
  };

  const handleSize = (event) => {
    let contentApi = event.view.calendar;
    contentApi.changeView(
      window.innerWidth < 800 ? "timeGridDay" : "timeGridWeek"
    );
  };

  log.info("patient appointment list ", apptlist);

  return (
    <Grid container width="100%">
      {alertMessage && (
        <div className="text-color-error App my5" style={{ width: "100%" }}>
          {alertMessage}
        </div>
      )}

      {calstate === "" ? (
        <div style={{ width: "100%" }}>
          <FullCalendar
            height={"auto"}
            aspectRatio={2}
            ref={calendarRef}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              center: "title",
              left: "prev,today,next",
              right: "dayGridMonth,timeGridWeek,timeGridDay,list",
            }}
            initialView={"timeGridDay"}
            views={{
              month: { titleFormat: calV[0 + calOffset] },
              week: { titleFormat: calV[1 + calOffset] },
              day: { titleFormat: calV[2 + calOffset] },
              list: { titleFormat: calV[3 + calOffset] },
            }}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={props.weekendsVisible}
            datesSet={handleDates}
            select={handleDateSelect}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            eventAllow={handleEventDropAllow}
            eventDrop={handleEventDrop}
            eventMouseEnter={handleEventMouseEnter}
            eventChange={handleEventChange}
            eventContent={renderEventContent}
            eventAdd={handleEventAdd}
            eventRemove={handleEventRemove}
            windowResize={handleSize}
            // eventOverlap = { false }

            businessHours={{
              daysOfWeek: selectorg
                ? selectorg?.workingHours?.daysOfWeek
                : [0, 1, 2, 3, 4, 5, 6],
              startTime: selectorg
                ? selectorg?.workingHours?.startTime
                : "00:00",
              endTime: selectorg ? selectorg?.workingHours?.endTime : "24:00",
            }}
            // slotEventOverlap = {false}

            slotMinTime={
              selectorg ? selectorg?.workingHours?.startTime : "00:00"
            }
            slotMaxTime={selectorg ? selectorg?.workingHours?.endTime : "24:00"}
            slotDuration={
              selectorg ? selectorg?.workingHours?.slotDuration : "00:30:00"
            }

            //validRange = {{ start: Date, end: null }}
          />
        </div>
      ) : calstate === "inputform" ? (
        <CalInputform closeForm={handleCancel} />
      ) : calstate === "eventview" ? (
        <>
          <Grid
            item
            xs={12}
            mb={4}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <EventView closeForm={handleCancel} eventRemove={removeEvent} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Calupdateform closeForm={handleCancel} />
          </Grid>
        </>
      ) : (
        <div />
      )}
    </Grid>
  );
}
