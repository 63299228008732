import * as React from "react";
import { useEffect, useState } from "react";
import { Grid, Link } from "@material-ui/core";
import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useOutletContext } from "react-router-dom";
import {
  pageOptions,
  tableParam,
  colWidthSign,
  SpanAnchor,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../../common/ConstantsAndValues";
import { Title } from "../../common/Title";

import { ReportReportsMDD } from "../../clinicaltests/ReportReportsMDD";

import { selectedPatient } from "../../../redux/user/user.actions";

import log from "../../../services/log";
import { getLabsList } from "../../../redux/labs/labs.actions";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export function ReportsListMD(props) {
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState([]);
  const dispatch = useDispatch();

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (rows && keyword !== "") {
      const results = rows.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(null);
    }
  };

  const handleRowClick = (e) => {
    var updatedList = [...checkedRows];

    if (findArrayElement(updatedList, e)) {
      updatedList.splice(checkedRows.indexOf(e), 1);
    } else {
      updatedList = [...checkedRows, e];
    }

    setCheckedRows(updatedList);
    //console.log("Prescribe", updatedList);
  };

  const handlePrescribe = () => {
    console.log("Prescribe/Repeat", checkedRows);
  };

  const findArrayElement = function (array, title) {
    return array.find((item) => {
      return item === title;
    });
  };

  // log.info("labs list");

  return (
    <Grid container>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Clinical Test"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
      </div>

      <ReportReportsMDD />
    </Grid>
  );
}

{
  /*
OLD FILE
import * as React                                          from 'react';
import {useEffect, useState}                               from "react";
import { useNavigate, useOutletContext }                   from "react-router-dom";

import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow} from '@mui/material';

import { useSelector, useDispatch }                        from "react-redux";

import {getLabs, getReportData}                            from "../../../redux/actions/reports";

import {reportTable, Row }                                 from "../../CustomComponents/reportTable";
import {StyledTableCellHead, StyledTableRowHead}           from "../../common/ConstantsAndValues";

import {PatientListPath, HeadUrl}                          from "../../common/ConstantsAndValues";



export function ReportsListMD(props) {
    const navigate                       = useNavigate();
    const user                           = useSelector(state => state.auth.user?state.auth.user.user:null);

    const [markDates, dateTest, auth, eventType, page]   = useOutletContext();

    // const saved = localStorage.getItem("patient");
    // const patient = JSON.parse(saved);
    // localStorage.setItem("prevURL", JSON.stringify(HeadUrl));

    // useEffect(() => {
    //     if (patient){
    //         if (patient.id === null){
    //             navigate(PatientListPath);
    //         }
    //     }else {
    //         navigate(PatientListPath);
    //     }
    // },[])

    const [labgroups, setLabGroup] = useState("labg1")
    const [labid, setLabId] = useState('')
    const [reports, setReports] = useState([]);
    const dispatch= useDispatch();

    useEffect(() => {
        dispatch(getLabs({
            "groupId": labgroups
        })).then((r) => setReports(r.results))

    }, [user, labgroups])

    console.log('reportslist', reports, user)

    return (
        <TableContainer>

            <Table aria-label="collapsible table">
                <TableHead className="bg-standard-lightest text-md">
                    <StyledTableRowHead >
                        <StyledTableCellHead />
                        <StyledTableCellHead>{page} {reportTable.title.label}</StyledTableCellHead>
                        <StyledTableCellHead align="right">{reportTable.value.label}</StyledTableCellHead>
                        <StyledTableCellHead align="right">{reportTable.minValue.label}</StyledTableCellHead>
                        <StyledTableCellHead align="right">{reportTable.maxValue.label}</StyledTableCellHead>
                        <StyledTableCellHead align="right">{reportTable.completedAt.label}</StyledTableCellHead>
                    </StyledTableRowHead>
                </TableHead>
                <TableBody className="text-color-primary text text-md">
                    {reports.map( (report) =>
                        <Row key={report.labId} row={report} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
*/
}
