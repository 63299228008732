import React, { useState, useEffect, useLayoutEffect } from "react";
import { Grid, Box, Link, Paper, TableContainer } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { PatientListPath } from "../../common/ConstantsAndValues";
import * as componentsMap from "../../healthmonitor/study/";

export function HmoniListUSR() {
  const navigate = useNavigate();
  const [markDates, dateTest, auth, eventType, page] = useOutletContext();

  const saved = localStorage.getItem("patient");
  const patient = JSON.parse(saved);

  //console.log("User Health:", patient);

  useEffect(() => {}, [patient, page]);

  const MyPage = componentsMap[page];

  return (
    <TableContainer component={Paper}>{MyPage && <MyPage />}</TableContainer>
  );
}

export const HmoniListUSRxxx = () => {
  const [selected, setSelected] = React.useState("");

  const changeSelectOptionHandler = (event) => {
    setSelected(event.target.value);
  };

  /** Different arrays for different dropdowns */
  const algorithm = [
    "Searching Algorithm",
    "Sorting Algorithm",
    "Graph Algorithm",
  ];
  const language = ["C++", "Java", "Python", "C#"];
  const dataStructure = ["Arrays", "LinkedList", "Stack", "Queue"];

  /** Type variable to store different array for different dropdown */
  let type = null;

  /** This will be used to create set of options that user will see */
  let options = null;

  /** Setting Type variable according to dropdown */
  if (selected === "Algorithm") {
    type = algorithm;
  } else if (selected === "Language") {
    type = language;
  } else if (selected === "Data Structure") {
    type = dataStructure;
  }

  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }
  return (
    <div>
      <form>
        <div>
          <select onChange={changeSelectOptionHandler}>
            <option>Choose...</option>
            <option>Algorithm</option>
            <option>Language</option>
            <option>Data Structure</option>
          </select>
        </div>
        {type && (
          <div>
            <select>{options}</select>
          </div>
        )}
      </form>
    </div>
  );
};
