export const regionquery = (field, value) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "divisions";
  jsonData.params["size"] = 250;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool = {};
  jsonData.params.query.bool["must"] = [];
  let wildcard = {};
  wildcard[field] = value;
  jsonData.params.query.bool.must.push({ wildcard: wildcard });
  return jsonData;
};

export const regionmatchquery = (field, value) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "divisions";
  jsonData.params["size"] = 250;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool = {};
  jsonData.params.query.bool["must"] = [];
  let match = {};
  match[field] = value;
  jsonData.params.query.bool.must.push({ match: match });
  return jsonData;
};

export const subregionmatchquery = (field, value) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "subdivisions";
  jsonData.params["size"] = 250;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool = {};
  jsonData.params.query.bool["must"] = [];
  let match = {};
  match[field] = value;
  jsonData.params.query.bool.must.push({ match: match });
  return jsonData;
};

export const subregionquery = (field, value) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "subdivisions";
  jsonData.params["size"] = 250;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool = {};
  jsonData.params.query.bool["must"] = [];
  let wildcard = {};
  wildcard[field] = value;
  jsonData.params.query.bool.must.push({ wildcard: wildcard });
  return jsonData;
};

export const answerlistquery = (field, value) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "answerlist";
  jsonData.params["size"] = 50;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool = {};
  jsonData.params.query.bool["must"] = [];
  let wildcard = {};
  wildcard[field] = value;
  jsonData.params.query.bool.must.push({ wildcard: wildcard });
  return jsonData;
};

export const mdspecialityquery = (
  field = "listOID",
  value = "1.3.6.1.4.1.12009.10.1.3558"
) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "answerlist";
  jsonData.params["size"] = 250;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool = {};
  jsonData.params.query.bool["must"] = [];
  let wildcard = {};
  wildcard[field] = value;
  jsonData.params.query.bool.must.push({ wildcard: wildcard });
  return jsonData;
};

export const departmentsquery = (field = "deptId", value = "*") => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "departments";
  jsonData.params["size"] = 250;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool = {};
  jsonData.params.query.bool["must"] = [];
  let wildcard = {};
  wildcard[field] = value;
  jsonData.params.query.bool.must.push({ wildcard: wildcard });
  return jsonData;
};

export const labsystemquery = (
  field = "root",
  value = "LP432695-7.LP29693-6"
) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "compsystem";
  jsonData.params["size"] = 250;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool = {};
  jsonData.params.query.bool["must"] = [];
  let wildcard = {};
  wildcard[field] = value;
  jsonData.params.query.bool.must.push({ match: wildcard });
  return jsonData;
};
