import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
  tableParam,
} from "../../common/ConstantsAndValues";
import { MyIconButtons } from "../../CustomComponents/MyButtons";
import log from "../../../services/log";

export function SpecialistListRow(props) {
  const { row, handleRowClick } = props;
  const selectmedrows = useSelector((state) => state?.user?.orgspecialist);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [maplist, setMaplist] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // log.info("specialist listrow", selectmedrows);
    if (selectmedrows) {
      const isSelected = selectmedrows.id === row.id;
      setSelectedRow(isSelected);
    }
  }, [selectmedrows]);

  useEffect(() => {
    if (row) {
      const newmap = [];
      setMaplist(newmap);
    }
  }, [row]);

  const handleClick = () => {
    log.info("specialist");
    setOpen(!open);
  };

  const handleRowClickLocal = (e) => {
    const newstate = !selectedRow;
    setSelectedRow(newstate);
    handleRowClick(e, newstate);
  };

  // log.info("specialist row", row);

  return (
    <React.Fragment>
      <StyledTableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
        onClick={() => handleRowClickLocal(row)}
      >
        <StyledTableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <b>{row.name}</b>
        </StyledTableCell>
        <StyledTableCell align="right">
          <Typography variant={"body2"}>{row.specialization}</Typography>
        </StyledTableCell>
        <StyledTableCell align="right">{row.status}</StyledTableCell>
        <StyledTableCell align="right">
          {row.updated_at ? row.updated_at : ""}
        </StyledTableCell>
      </StyledTableRow>

      <TableRow className="text-color-gray bg-standard-offwhite">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">Other Details</div>
              </div>
              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {maplist &&
                      maplist.map((m) => (
                        <TableRow key={m.id}>
                          <TableCell>{m.label}</TableCell>
                          <TableCell>{m.value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
