export const userIndexQueries = (document) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData.params.index = "userdb";
  jsonData.params.document = document;
  return jsonData;
};

export const usersquery = (field = "name", value = "a", pagedata) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "userdb";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool["filter"] = [];
  jsonData.params.query.bool["must"] = [];
  const should = {};
  should["role"] = "metauser";
  jsonData.params.query.bool.must.push({ match: should });
  const filter = {};
  filter["match_phrase_prefix"] = {};
  filter.match_phrase_prefix[field] = {};
  filter.match_phrase_prefix[field]["query"] = value;
  jsonData.params.query.bool.filter.push(filter);
  return jsonData;
};
