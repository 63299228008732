import React, { useEffect, useState } from "react";

export const RoundedCrossBlack = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      fill="none"
      viewBox={viewbox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Cancel">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0742 16.4857C15.4647 16.8762 16.0979 16.8762 16.4884 16.4857C16.8789 16.0952 16.8789 15.462 16.4884 15.0715L13.4169 12L16.4884 8.92851C16.8789 8.53798 16.8789 7.90482 16.4884 7.51429C16.0979 7.12377 15.4647 7.12377 15.0742 7.51429L12.0027 10.5858L8.93117 7.51429C8.54065 7.12377 7.90748 7.12377 7.51696 7.51429C7.12643 7.90482 7.12643 8.53798 7.51696 8.92851L10.5885 12L7.51696 15.0715C7.12643 15.462 7.12643 16.0952 7.51696 16.4857C7.90748 16.8762 8.54065 16.8762 8.93117 16.4857L12.0027 13.4142L15.0742 16.4857Z"
          fill="#585858"
        />
        <rect
          id="Rectangle"
          x="2.20312"
          y="2.20312"
          width="19.592"
          height="19.592"
          rx="9.79598"
          stroke="#585858"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
