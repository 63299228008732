import * as React from "react";
import { useState } from "react";
import { Grid, Box, Link, TextField } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import CloseIcon from "@mui/icons-material/Close";

export default function SearchDialog(props) {
  const { open, setOpen, doSearch } = props;
  const [eventSearch, setEventSearch] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    setOpen(false);
    doSearch(eventSearch);
    console.log("dd1:", eventSearch);
  };

  const handleChange = (e) => {
    //abandoned
    setEventSearch(e.target.value);
  };
  //<Box onClick={handleClose}  className="text-color" sx={{ float: "right" }} ><CloseIcon /></Box>

  return (
    <Box component="div">
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        className="search-form"
      >
        <DialogTitle className="bg-gray text-color-primary" width="100%">
          <Box className="colfl">Search</Box>
          <Box onClick={handleClose} className="colfr text-color">
            <CloseIcon />
          </Box>
        </DialogTitle>

        <DialogContent
          className="text-color-primary"
          sx={{ marginX: 0, marginTop: 2, paddingTop: 2 }}
        >
          <DialogContentText> </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="strSearch"
            label=""
            fullWidth
            variant="standard"
            onChange={handleChange}
          />
        </DialogContent>

        <DialogActions sx={{ margin: 0, marginRight: 2, marginBottom: 2 }}>
          <Link
            onClick={handleAdd}
            className="btn btn-main-2-inv btn-icon btn-round text-color"
          >
            <SearchTwoToneIcon />
          </Link>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
