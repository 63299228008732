// import node modules
import * as React from "react";
import { ThemeTabs } from "../../tabs/ThemeTabs";
import { useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Link,
} from "@mui/material";

import {
  FirstPageIcon,
  LastPageIcon,
  MessageIcon,
  NextPageIcon,
  PreviousPageIcon,
} from "../../customIcons/TableIcons";
import { PlusIcon } from "../../customIcons/PlusIcon";

export const LabResultsBody = () => {
  const [selected, setSelected] = React.useState([]);

  const [resultList, setresultList] = useState([
    {
      title: "Thyroid stimulating hormone (TSH)",
      orderedBy: "Samantha Fernandes",
      hasMessage: true,
      date: "12 October, 2023",
      status: "pending",
    },
    {
      title: "Complete blood count (CBC)",
      orderedBy: "Samantha Fernandes",
      hasMessage: false,
      date: "12 October, 2023",
      status: "ready",
    },
    {
      title: "Hemoglobin",
      orderedBy: "Samantha Fernandes",
      hasMessage: true,
      date: "12 October, 2023",
      status: "ready",
    },
    {
      title: "Lipid profile",
      orderedBy: "Samantha Fernandes",
      hasMessage: false,
      date: "12 October, 2023",
      status: "ready",
    },
  ]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = resultList.map((n) => n.title);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box>
      <Box sx={{ mx: 1 }}>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "22px",
            color: "#404040",
            fontFamily: "DM SANS",
          }}
        >
          All results
        </Typography>

        <Box
          sx={{
            mt: 1,
            mb: 2,
            display: "flex",
          }}
        >
          <Typography
            sx={{
              color: "#8F8F8F",
              fontFamily: "DM SANS",
            }}
          >
            Please note that some lab results may not yet be available, and some
            lab results may not
            <br /> be released electronically due to regulatory restrictions.
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <div className="greylabel">
            <PlusIcon size={0.45} />
            <p className="mb-0">Add a filter</p>
          </div>
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                  <Checkbox
                    color="primary"
                    size="small"
                    onClick={handleSelectAllClick}
                    sx={{
                      transform: "scale(0.9)",
                      padding: 0,
                      margin: 0,
                      color: "#404040",
                      "&.Mui-checked": {
                        color: "#2A8AA7",
                      },
                    }}
                  />
                </TableCell>
                <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                  Name
                </TableCell>
                <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                  Ordered by
                </TableCell>
                <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                  Date
                </TableCell>
                <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultList.map((row, index) => {
                const isItemSelected = isSelected(row.title);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    key={row.name}
                    onClick={(event) => handleClick(event, row.title)}
                    selected={isItemSelected}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        size="small"
                        checked={isItemSelected}
                        sx={{
                          transform: "scale(0.9)",
                          padding: 0,
                          margin: 0,
                          color: "#404040",
                          "&.Mui-checked": {
                            color: "#2A8AA7",
                          },
                        }}
                        color="primary"
                        // checked={isItemSelected}
                        // inputProps={{
                        // "aria-labelledby": labelId,
                        // }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontFamily: "DM SANS",
                        fontSize: "15px",
                        padding: "18px 5px",
                      }}
                    >
                      <Link href="/metauser/test-results/1" color={"#2A8AA7"}>
                        {row.title}
                      </Link>
                    </TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        fontFamily: "DM SANS",
                        fontSize: "15px",
                        padding: "18px 5px",
                      }}
                    >
                      {row.orderedBy}
                      {row.hasMessage && (
                        <Box sx={{ ml: 1 }}>
                          <MessageIcon />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM SANS",
                        fontSize: "15px",
                        padding: "18px 5px",
                      }}
                    >
                      {row.date}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM SANS",
                        fontSize: "15px",
                        padding: "18px 5px",
                      }}
                      // align="right"
                    >
                      {row.status === "pending" ? (
                        <div>
                          <span className="yellow_dot"></span>
                          Pending
                        </div>
                      ) : (
                        <div>
                          <span className="green_dot"></span>Ready to view
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            paddingTop: "1.5rem",
            borderTop: `1px solid #e9e9e9`,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box sx={{ mx: 1, cursor: "pointer" }}>
            <FirstPageIcon />
          </Box>
          <Box sx={{ mx: 2, cursor: "pointer" }}>
            <PreviousPageIcon />
          </Box>
          <Typography
            sx={{
              paddingTop: "2px",
              fontFamily: "DM SANS",
              fontSize: (theme) => theme.typography.button.fontSize,
            }}
          >
            Page
            <Box
              sx={{
                display: "inline",
                border: "1px solid #C7C7C7",
                px: 0.6,
                borderRadius: "4px",
              }}
            >
              1
            </Box>
            / 22
          </Typography>
          <Box sx={{ mx: 2, cursor: "pointer" }}>
            <NextPageIcon />
          </Box>
          <Box sx={{ mx: 1, cursor: "pointer" }}>
            <LastPageIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
function TestResults() {
  const tabs = [
    { id: "1002", title: "Lab results", notify: true },
    { id: "1003", title: "Lab prescriptions" },
  ];
  return <ThemeTabs tabs={tabs} />;
}
export default TestResults;
