import React, { useState, useEffect } from "react";
import { Grid, Box, Container, Paper, Typography } from "@material-ui/core";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { MyButtons } from "../../CustomComponents/MyButtons";
import {
  pageOptions,
  tableParam,
  colWidthSign,
  SpanAnchor,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../../common/ConstantsAndValues";
import { Title } from "../../common/Title";

import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export default function InveListRaw(props) {
  const { handleRowsDetail, data, filterTitle, err } = props;

  const [detail, setDetail] = useState("");
  const [errMsg, setErrMsg] = useState(err);
  const [foundRows, setFoundRows] = useState(data);
  const [checkedRows, setCheckedRows] = useState([]);

  const classes = useStyles();

  //console.log('p list',data, foundRows);

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = data.filter((det) => {
        return (
          det.patient.toLowerCase().startsWith(keyword.toLowerCase()) ||
          det.doctor.toLowerCase().startsWith(keyword.toLowerCase()) ||
          det.id.toLowerCase().startsWith(keyword.toLowerCase())
        );
      });
      setFoundRows(results);
    } else {
      setFoundRows(data); //important
    }
    setDetail(keyword);
  };

  const handleNextStep = () => {
    if (checkedRows.length <= 0) {
      setErrMsg("Select Bill please");
      return;
    }
    handleRowsDetail(checkedRows);
  };

  const handleCheck = (e) => {
    var updatedList = [...checkedRows];
    if (e.target.checked) {
      updatedList = [...checkedRows, e.target.value];
    } else {
      updatedList.splice(checkedRows.indexOf(e.target.value), 1);
    }
    setCheckedRows(updatedList);
  };

  return (
    <Grid container>
      <div className="text-color-error">{errMsg && errMsg}</div>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0">
            <Title>{filterTitle}</Title>
          </div>
          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={filter}
              placeholder="I n v e s t i g a t i o n s"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div>
          <MyButtons
            onClick={handleNextStep}
            btnKey={"proceed"}
            btnLabel={"Proceed"}
            enState={checkedRows.length > 0 ? false : true}
          />
        </div>
      </div>
      <div className="clear"></div>
      <TableContainer
        component={Paper}
        elevation={0}
        style={{ minHeight: tableParam.minHeight, marginTop: "8px" }}
        className="list"
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <StyledTableRowHead>
              <StyledTableCellHead>{"ID"}</StyledTableCellHead>
              <StyledTableCellHead>{"Patient"}</StyledTableCellHead>
              <StyledTableCellHead>{"Doctor"}</StyledTableCellHead>
              <StyledTableCellHead>{"Investigations"}</StyledTableCellHead>
              <StyledTableCellHead>{"Instructions"}</StyledTableCellHead>
              <StyledTableCellHead>{"Remarks"}</StyledTableCellHead>
            </StyledTableRowHead>
          </TableHead>

          <TableBody>
            {foundRows && foundRows.length > 0 ? (
              foundRows.map((det, i) => (
                <StyledTableRow
                  key={"pt" + i}
                  className={
                    (det.paid ? "text-color-gray" : "text-color", " lh-35")
                  }
                >
                  <StyledTableCell
                    onChange={handleCheck}
                    className={det.paid ? "text-color-gray" : "text-color"}
                  >
                    <input
                      value={det.id}
                      type="checkbox"
                      onChange={handleCheck}
                    />
                    &nbsp;
                    <b>{det.id}</b>
                  </StyledTableCell>
                  <StyledTableCell>{det.patient}</StyledTableCell>
                  <StyledTableCell>{det.doctor}</StyledTableCell>
                  <StyledTableCell>
                    {det.investigation.length > 0
                      ? det.investigation[0].ctest + " ..."
                      : det.investigation}
                  </StyledTableCell>
                  <StyledTableCell>{det.instruction}</StyledTableCell>
                  <StyledTableCell>{det.remarks}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow key={"pt0" + 0}>
                <StyledTableCell colSpan="7">
                  <span className="text-color-error">No results found!</span>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
