import React, { useState, useEffect, useLayoutEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  Container,
  Stack,
  IconButton,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
//import MuiAccordion from "@material-ui/core/Accordion";
//import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
//import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import AddLocationTwoToneIcon from "@mui/icons-material/AddLocationTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import PlaylistAddCheckTwoToneIcon from "@mui/icons-material/PlaylistAddCheckTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertTwoToneIcon from "@mui/icons-material/MoreVertTwoTone";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import PersonAdd from "@mui/icons-material/PersonAdd";
import TerrainTwoToneIcon from "@mui/icons-material/TerrainTwoTone";

import { tableParam } from "../common/ConstantsAndValues";
import { MyArrowButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";
import { selectDeptAddress } from "../../redux/actions/addresses";
import { useSelector } from "react-redux";

export default function ThreeDotLocnDeptMenu(props) {
  const { setAction, alertcallback } = props;

  const selectorg = useSelector((state) => state?.orgs?.selectorg);
  const selectorggroup = useSelector((state) => state?.orgs?.selectorggroup);
  const department = useSelector((state) => state?.orgs?.selectdepartment);
  const deptaddress = useSelector((state) => state?.addresses?.deptaddress);
  const deptspecialist = useSelector((state) => state?.user?.orgspecialist);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openflag, setOpen] = useState(false);

  const handleAddOrgClick = (event) => {
    setAction("addOrg");
    setOpen(false);
  };

  const handleSelectOrgClick = () => {
    setAction("selectOrg");
    setOpen(false);
  };

  const handleAddOrgGroupClick = (event) => {
    if (!selectorg) {
      // organization is selected before this operation
      alertcallback({ active: true, msg: "organization is not selected" });
    }
    setAction("addOrgGroup");
    setOpen(false);
  };

  const handleSelectOrgGroupClick = () => {
    setAction("selectOrgGroup");
    setOpen(false);
  };

  const handleAddDeptClick = (event) => {
    if (!selectorggroup) {
      // organization group is configured and selected before this operation
      alertcallback({
        active: true,
        msg: "organization group is not selected",
      });
    }
    setAction("addDept");
    setOpen(false);
  };

  const handleAddLocnClick = (event) => {
    if (!department) {
      // department is selected before doing location add operation
      alertcallback({ active: true, msg: "department is not selected" });
    }
    setAction("addLocn");
    setOpen(false);
  };

  const handleEditAboutClick = (event) => {
    if (!deptaddress) {
      // department should be set  before doing the operation
      alertcallback({
        active: true,
        msg: "department location is not selected",
      });
    }
    setAction("editAbout");
    setOpen(false);
  };

  const handleGeoLocationClick = () => {
    setAction("geoLocn");
    setOpen(false);
  };

  const handleAddSpecialistClick = () => {
    if (!deptaddress) {
      // department should be set  before doing the operation
      alertcallback({
        active: true,
        msg: "department location is not selected",
      });
    }
    setAction("addSpecialist");
    setOpen(false);
  };

  const handleEditTeamClick = () => {
    if (!deptaddress) {
      // department should be set  before doing the operation
      alertcallback({
        active: true,
        msg: "department location is not selected",
      });
    }
    setAction("editTeam");
    setOpen(false);
  };

  const handleSelectLoc = () => {
    setAction("selectLoc");
    setOpen(false);
  };

  const handleAddNewLoc = () => {
    setAction("addNewLoc");
    setOpen(false);
  };

  const handleDeptSearch = () => {
    setAction("enableDeptSearch");
    setOpen(false);
  };

  const handleSpecialistSearch = () => {
    setAction("enableSpecialistSearch");
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setAction("");
  };

  return (
    <>
      {/*Locations*/}
      <Tooltip title="more...">
        <IconButton
          onClick={handleOpen}
          size="small"
          aria-controls={openflag == true ? "locn-dept-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openflag == true ? "true" : undefined}
          sx={{
            ml: 2,
            boxShadow: "0 !important",
            outline: "none",
            "&:focus": {
              border: "0 !important",
              outline: "none",
              boxShadow: "none",
              backgroundColor: "transparent",
            },
          }}
        >
          <MoreVertTwoToneIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="locn-dept-menu"
        open={openflag}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            paddingLeft: 2,
            paddingRight: 2,
            minWidth: tableParam.width,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={handleClose}
          style={{
            padding: "4px 4px 4px 24px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="TitleMenu">MENU</span>
          <span className="text-color-primary ">
            <MyArrowButtons
              onClick={handleClose}
              btnKey={"e"}
              btnLabel={"Close"}
              enState={false}
            />
          </span>
        </MenuItem>

        <Divider />

        <Box>
          <MenuItem width={"100%"} key={"addOrg"} onClick={handleAddOrgClick}>
            <ListItemIcon>
              <AddBoxTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="text-color">
              Update Organization
            </ListItemText>
          </MenuItem>
        </Box>
        <Box>
          <MenuItem
            key={"selectOrg"}
            onClick={handleSelectOrgClick}
            disabled={selectorg == null ? true : false}
          >
            <ListItemIcon>
              <PlaylistAddCheckTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="text-color">
              Select Organization
            </ListItemText>
          </MenuItem>
        </Box>
        <Box>
          <MenuItem
            key={"editAbout"}
            onClick={handleEditAboutClick}
            disabled={selectorg == null ? true : false}
          >
            <ListItemIcon>
              <EditTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="text-color">
              Edit About Organization
            </ListItemText>
          </MenuItem>
        </Box>
        <Divider />
        <Box>
          <MenuItem
            key={"addOrgGroup"}
            onClick={handleAddOrgGroupClick}
            disabled={selectorg == null ? true : false}
          >
            <ListItemIcon>
              <AddLocationTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="text-color">
              Add Organization Group
            </ListItemText>
          </MenuItem>
        </Box>

        <Box>
          <MenuItem key={"addOrgGroup"} onClick={handleSelectOrgGroupClick}>
            <ListItemIcon>
              <PlaylistAddCheckTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="text-color">
              Select Organization Group
            </ListItemText>
          </MenuItem>
        </Box>

        <Divider />
        <Box>
          <MenuItem
            key={"addDeptLoc"}
            value={"addNewDeptLoc"}
            onClick={handleAddNewLoc}
          >
            <ListItemIcon>
              <AddLocationTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="text-color">Add New Location</ListItemText>
          </MenuItem>
        </Box>

        <Box>
          <MenuItem
            width={"100%"}
            key={"selectDeptLoc"}
            value={"selectDeptLoc"}
            onClick={handleSelectLoc}
          >
            <ListItemIcon>
              <PlaylistAddCheckTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="text-color">Select Location</ListItemText>
          </MenuItem>
        </Box>

        <Divider />
        <Box>
          <MenuItem width={"100%"} key={"addDept"} onClick={handleAddDeptClick}>
            <ListItemIcon>
              <AddBoxTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="text-color">Add Department</ListItemText>
          </MenuItem>
        </Box>

        <Box>
          <MenuItem
            width={"100%"}
            key={"addDept"}
            onClick={handleDeptSearch}
            disabled={department == null ? true : false}
          >
            <ListItemIcon>
              <AddBoxTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="text-color">
              Enable Department Search
            </ListItemText>
          </MenuItem>
        </Box>

        <Box>
          <MenuItem
            width={"fit-content"}
            key={"addSpecialist"}
            onClick={handleAddSpecialistClick}
          >
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            <ListItemText className="text-color">Add Specialist</ListItemText>
          </MenuItem>
        </Box>
        <Box>
          <MenuItem
            width={"100%"}
            key={"addDept"}
            onClick={handleSpecialistSearch}
            disabled={deptspecialist == null ? true : false}
          >
            <ListItemIcon>
              <AddBoxTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="text-color">
              Enable Specialist Search
            </ListItemText>
          </MenuItem>
        </Box>
        <Box>
          <MenuItem
            key={"editTeam"}
            onClick={handleEditTeamClick}
            disabled={deptspecialist == null ? true : false}
          >
            <ListItemIcon>
              <EditTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="text-color">
              Update Specialist
            </ListItemText>
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
}
