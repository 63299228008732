import React from "react";
import SignInCode from "../components/sign/sign-code";

import { Gen400Container } from "../components/common/ConstantsAndValues";

export const SignInCodePage = () => (
  <Gen400Container>
    <SignInCode />
  </Gen400Container>
);
