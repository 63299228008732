import * as React from "react";
import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
//import Divider from '@mui/material/Divider';
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Grid, Box, Typographyn } from "@mui/material";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import TextField from "@material-ui/core/TextField";
import Fab from "@mui/material/Fab";

import PersonTwoToneIcon from "@mui/icons-material/PersonTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import ContentPasteSearchTwoToneIcon from "@mui/icons-material/ContentPasteSearchTwoTone";
//import CircleIcon from '@mui/icons-material/Circle';

import { Title } from "../common/Title";
import {
  SubContentStyle,
  ContentStyle,
  colWidthRBarUSR,
  picPath,
  tableParam,
} from "../common/ConstantsAndValues";

import { HealthConditionRows } from "../../tests/HealthConditions";

export function HealthMonitorConditions(props) {
  const { openEventPage, sourcekey } = props;
  const [page, setPage] = useState("");
  const [isActive, setIsActive] = useState(true);

  let Rows = HealthConditionRows;
  let u = "Health Conditions";

  const onPageChange = (newPage) => {
    setPage(newPage);
    openEventPage(newPage);
  };

  return (
    <>
      <Box className="" pt={1} ml={2} mr={0}>
        <div onClick={() => setIsActive(!isActive)} className="cursor-pointer">
          <Title>
            <ContentPasteSearchTwoToneIcon /> {u}
          </Title>
        </div>
      </Box>
      {isActive && (
        <Box
          className="list border-dbt border-dbb"
          pt={0}
          ml={2}
          mr={0}
          mb={1}
          height={tableParam.minHeight}
          width="90%"
        >
          <ul className="list-group list-group-flush">
            {Rows !== null
              ? Rows.map((row) => {
                  return (
                    <li
                      className={` list-group-item cursor-pointer ${
                        page === row.code ? "text-color-inv" : "text-color"
                      }`}
                      key={row.id}
                      onClick={() => onPageChange(row.code)}
                    >
                      <div className="colfl0 ">{row.name}</div>
                      <div className="colfr0" width="20%">
                        {row.code}
                      </div>
                    </li>
                  );
                })
              : null}
          </ul>
        </Box>
      )}
    </>
  );
}
