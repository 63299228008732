import { failure, success } from "../../utils/redux";
import { AppointmentActionTypes } from "./appointment.types";
const initialState = {
  testTypes: null,
  docsetting: null,
  appt: null,
  dates: null,
  clientapptlist: null,
  dateslots: null,
  providerData: null,
  isLoadingProviderData: false,
  reasonData: null,
  isLoadingReasonData: false,
};

export default function appointmentReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case AppointmentActionTypes.GET_TEST_TYPES:
      return {
        ...state,
        testTypes: payload.data,
      };
    case AppointmentActionTypes.APPTSTYPE:
      return {
        ...state,
        apptstype: payload,
      };
    case AppointmentActionTypes.APPTDOCSETTING:
      return {
        ...state,
        docsetting: payload,
      };
    case AppointmentActionTypes.APPTSERVS:
      return {
        ...state,
        aptservs: payload,
      };
    case AppointmentActionTypes.APPT_LIST:
      return {
        ...state,
        apptlist: payload,
      };
    case AppointmentActionTypes.APPT_GLIST:
      return {
        ...state,
        apptglist: payload,
      };
    case AppointmentActionTypes.APPT_MLIST:
      return {
        ...state,
        apptmlist: payload,
      };
    case AppointmentActionTypes.APPT_GET:
      return {
        ...state,
        appt: payload,
      };
    case AppointmentActionTypes.EVENT_FILTERS:
      return {
        ...state,
        eventmask: payload,
      };
    case AppointmentActionTypes.GUESTS_LIST:
      return {
        ...state,
        guestslist: payload,
      };
    case AppointmentActionTypes.EVENT_DATES:
      return {
        ...state,
        dates: payload,
      };
    case AppointmentActionTypes.CLIENTAPPT_LIST:
      return {
        ...state,
        clientapptlist: payload,
      };
    case AppointmentActionTypes.DATE_SLOTS:
      return {
        ...state,
        dateslots: payload,
      };

    // provider search data
    case AppointmentActionTypes.PROVIDER_LIST:
      return {
        ...state,
        isLoadingProviderData: true,
      };

    case success(AppointmentActionTypes.PROVIDER_LIST):
      return {
        ...state,
        providerData: payload,
        isLoadingProviderData: false,
      };

    case failure(AppointmentActionTypes.PROVIDER_LIST):
      return {
        ...state,
        providerData: null,
        isLoadingProviderData: false,
      };

    // reason search data
    case AppointmentActionTypes.REASON_LIST:
      return {
        ...state,
        isLoadingReasonData: true,
      };

    case success(AppointmentActionTypes.REASON_LIST):
      return {
        ...state,
        reasonData: payload,
        isLoadingReasonData: false,
      };

    case failure(AppointmentActionTypes.REASON_LIST):
      return {
        ...state,
        reasonData: null,
        isLoadingReasonData: false,
      };

    default:
      return state;
  }
}
