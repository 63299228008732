import React, { useEffect, useState } from "react";

const CalendarTableIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <rect
        x="0.273438"
        y="18"
        width="19.6356"
        height="2"
        rx="1"
        fill="#404040"
      />
      <rect
        x="0.273438"
        y="6"
        width="19.6356"
        height="2"
        rx="1"
        fill="#404040"
      />
      <rect
        x="0.273438"
        y="2.32812"
        width="19.6356"
        height="2"
        rx="1"
        fill="#404040"
      />
      <rect
        x="2.09375"
        y="2.32812"
        width="17.6758"
        height="2"
        rx="1"
        transform="rotate(90 2.09375 2.32812)"
        fill="#404040"
      />
      <rect
        x="6.5"
        y="0.265625"
        width="5.15332"
        height="2"
        rx="1"
        transform="rotate(90 6.5 0.265625)"
        fill="#404040"
      />
      <rect
        x="15.5078"
        y="0.265625"
        width="5.15332"
        height="2"
        rx="1"
        transform="rotate(90 15.5078 0.265625)"
        fill="#404040"
      />
      <rect
        x="19.9141"
        y="2.32812"
        width="17.6758"
        height="2"
        rx="1"
        transform="rotate(90 19.9141 2.32812)"
        fill="#404040"
      />
    </svg>
  );
};
export default CalendarTableIcon;
