import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import log from "../../services/log";
import Delta from "quill-delta";
import Quill from "quill";

// Convert HTML to Delta
export const htmlToDelta = (htmlContent) => {
  const tempQuill = new Quill(document.createElement("div"));
  tempQuill.clipboard.dangerouslyPasteHTML(htmlContent);
  return tempQuill.getContents();
};

// Convert Delta to HTML
export const deltaToHtml = (delta) => {
  const tempQuill = new Quill(document.createElement("div"));
  tempQuill.setContents(delta);
  return tempQuill.root.innerHTML;
};

export const MetaQuillEditor = (props) => {
  const [text, setText] = useState("");
  const handleTextChange = (value) => {
    setText(value);
  };

  log.info("quill text", htmlToDelta(text));

  return (
    <div>
      <ReactQuill value={text} onChange={handleTextChange} />
    </div>
  );
};
