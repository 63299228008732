import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableFooter,
} from "@mui/material";
import { Box, Typography, Collapse, IconButton } from "@mui/material";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { SearchBoxStandard, tableParam } from "../../common/ConstantsAndValues";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../../common/ConstantsAndValues";
import { MyIconButtons } from "../../CustomComponents/MyButtons";
import log from "../../../services/log";

export function ClinicalTestsListRow(props) {
  const { row, index, handleRowClick } = props;

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [maplist, setMaplist] = useState(false);

  const selectrow = useSelector((state) => state.labs.labsselectrow);
  const dispatch = useDispatch();

  useEffect(() => {
    // log.info("CTlistrow", row);
    if (selectrow) {
      const temp = selectrow && row._source.labId === selectrow._source.labId;
      setSelectedRow(temp);
    }
  }, [selectrow]);

  const handleClick = () => {
    // log.info("CTlist");
    setOpen(!open);
  };

  const handleFilter = () => {};

  const handleRowClickLocal = (e) => {
    // log.info("CTlist", e);
    const newstate = !selectedRow;
    setSelectedRow(newstate);
    handleRowClick(e, newstate);
  };

  return (
    <React.Fragment>
      <StyledTableRow
        key={"rr" + index}
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
        onClick={() => handleRowClickLocal(row)}
      >
        <StyledTableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <b>{row._source["name"]}</b>
        </StyledTableCell>
        <StyledTableCell align="right">
          <Typography variant={"body2"}>{row._source.specimen}</Typography>
        </StyledTableCell>
        <StyledTableCell align="right">
          {row._source.instructions}
        </StyledTableCell>
      </StyledTableRow>

      <TableRow
        className="text-color-gray bg-standard-offwhite"
        key={"rrd" + index}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">Test Information</div>
                <div className="colfl">
                  <SearchBoxStandard
                    onChange={handleFilter}
                    placeholder="Property Name"
                    sx={{ marginRight: 1 }}
                    className="text-color-primary text-color-2-border text-color-2-bg"
                  />
                </div>
              </div>

              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow className="bg-gray">
                      <TableCell>Properties</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={"store" + row._source["Id"]}>
                      <TableCell>{"Group"}</TableCell>
                      <TableCell>{row._source.groupName}</TableCell>
                    </TableRow>
                    <TableRow key={"preg" + row._source["Id"]}>
                      <TableCell>{"Turnaround"}</TableCell>
                      <TableCell>{row._source.turnaround}</TableCell>
                    </TableRow>
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
