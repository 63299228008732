import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  logout,
  register,
  updateUser,
  verifyEmail,
} from "../../redux/actions/auth";
import { Box, Link } from "@material-ui/core";
import InputComponent from "../CustomComponents/InputComponent";
import { MyButtonsColor } from "../CustomComponents/MyButtons";
import log from "../../services/log";
import { getSixDigitRandom } from "../utils/random";

export default function SignInCode(props) {
  const auth = useSelector((store) => store.auth);
  const verify = useSelector((store) => store.auth.verify);
  const user = useSelector((store) => store.auth.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [values, setValues] = useState({
    codeError: false,
    codeErrorText: "incorrect code",
  });

  const handleCodeVerification = (event) => {
    setValues({
      ...values,
      code: event.target.value,
    });
  };

  // const gotodash = () => {
  //   if (user && user.role === "metadmin") {
  //     navigate("/metadmin");
  //   } else if (user && user.role === "metauser") {
  //     navigate("/metauser");
  //   } else if (user && user.role === "metaorg") {
  //     navigate("/metaorg");
  //   } else if (user && user.role === "metamd") {
  //     navigate("/metamd");
  //   } else {
  //     navigate("/");
  //   }
  // };
  const handleSubmit = (event) => {
    event.preventDefault();

    log.info("auth: ", auth);

    if (
      auth?.verify?.action === "verifyemail" &&
      auth?.verify?.code === values?.code
    ) {
      dispatch(
        updateUser({
          metadata: auth.verify.user.metadata,
          isEmailVerified: true,
        })
      );

      dispatch(logout());
      navigate("/sign");
    } else if (
      auth?.verify?.action === "passwordupdate" &&
      auth?.verify?.code === values?.code
    ) {
      navigate("/newpass");
    } else {
      setValues({
        ...values,
        codeError: true,
        codeErrorText: "incorrect code, resend code",
      });
    }
  };

  const handleSendCode = () => {
    console.log("sending code", user);
    const newcode = getSixDigitRandom();
    dispatch(
      verifyEmail(
        {
          code: newcode,
          user: {
            email: user.user.metadata.email,
            name: user.name,
          },
        },
        {
          code: newcode,
          user: user,
          action: "verifyemail",
        }
      )
    );
  };

  // console.log('signup', values);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          marginBottom: "0px !important",
        }}
      >
        <h3 className="text-color-primary">Enter Verification Code</h3>
        <span className="text-color-primary" sx={{ marginBottom: "16px" }}>
          Verification code is sent to your registered email.
        </span>

        <InputComponent
          id="vercode"
          name="vercode"
          label="Vefification code"
          errors={values.codeError}
          errorText={values.codeErrorText}
          help={"Enter numeric code"}
          variant="standard"
          handleChange={handleCodeVerification}
        />

        <Box className="clear">&nbsp; </Box>
        <Box className="clear">&nbsp; </Box>

        <div
          mt={2}
          className="text-color-white"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <div className="colfl0">
            <MyButtonsColor
              onClick={handleSendCode}
              btnKey={"x"}
              btnLabel={"Send code again"}
              enState={false}
            />
          </div>

          <div className="colfr0">
            <MyButtonsColor
              onClick={handleSubmit}
              btnKey={"x"}
              btnLabel={"Verify code"}
              enState={false}
            />
          </div>
        </div>
      </Box>
    </>
  );
}
