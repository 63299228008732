////////////////////////////////////////////////////
//                 common for metamd              //
////////////////////////////////////////////////////
import * as React from "react";

import { ContainerORG } from "../organization/components/ContainerORG";
import {
  commonSearchMenuOptions,
  ctlabMenuOptions,
  drugSearchMenuOptions,
  orgAccountSearchMenuOptions,
  orgPatientSearchMenuOptions,
  rtlabMenuOptions,
} from "./ConstantsAndValues";

import InventoryTwoToneIcon from "@mui/icons-material/InventoryTwoTone";
import PersonalVideoTwoToneIcon from "@mui/icons-material/PersonalVideoTwoTone";
import FormatListBulletedTwoToneIcon from "@mui/icons-material/FormatListBulletedTwoTone";

import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import TimelineTwoToneIcon from "@mui/icons-material/TimelineTwoTone";
import AddAlertTwoToneIcon from "@mui/icons-material/AddAlertTwoTone";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import CreateNewFolderTwoToneIcon from "@mui/icons-material/CreateNewFolderTwoTone";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import UpgradeTwoToneIcon from "@mui/icons-material/UpgradeTwoTone";
import SwitchAccountTwoToneIcon from "@mui/icons-material/SwitchAccountTwoTone";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import NoteAddTwoToneIcon from "@mui/icons-material/NoteAddTwoTone";
import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone";

export function ApptORG() {
  const containerType = {
    eventType: ["event"],
    type: "appointment",
    label: "Appointments",
    menuList: [
      {
        _id: "orglist",
        label: "Calendar",
        icon: <CalendarMonthTwoToneIcon />,
        link: "/metaorg/appointments/cal",
        more: false,
      },
      {
        _id: "organalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metaorg/appointments/analytics",
        more: true,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/appointments/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/appointments/messages",
        more: true,
      },
    ],
    comboLabel: "Any more addl combo box",
    comboList: [],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function AssoORG() {
  const containerType = {
    type: "associates",
    label: "Associates",
    menuList: [
      {
        _id: "orglist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metaorg/associates/list",
        more: false,
      },
      //{ _id: 'orgtodo',            label: 'To Do',         icon: <FormatListBulletedTwoToneIcon />,link: '/metaorg/associates/todo',            more: false, },
      {
        _id: "organalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metaorg/associates/analytics",
        more: true,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/associates/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/associates/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function DeptORG() {
  const containerType = {
    type: "departments",
    label: "Departments",
    menuList: [
      {
        _id: "orglist",
        label: "Info",
        icon: <TableRowsTwoToneIcon />,
        link: "/metaorg/departments/info",
        more: false,
      },
      // { _id: 'orgmanage',          label: 'Manage',          icon: <ManageAccountsTwoToneIcon />,   link: '/metaorg/departments/manage',         more: false, },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/departments/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/departments/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function PatnORG() {
  const containerType = {
    type: "patn",
    label: "Patients",
    menuList: [
      {
        _id: "orglist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metaorg/patients/list",
        more: false,
      },
      {
        _id: "orgmmanage",
        label: "policy",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metaorg/patients/policy",
        more: false,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/patients/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/patients/messages",
        more: true,
      },
      {
        _id: "orgcrpdf",
        label: "PDF",
        icon: <NoteAddTwoToneIcon />,
        link: "/metaorg/patients/createpdf",
        more: false,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={orgPatientSearchMenuOptions}
    />
  );
}

export function FaciORG() {
  const containerType = {
    type: "facilities",
    label: "Facilities",
    menuList: [
      {
        _id: "orglist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metaorg/facilities/list",
        more: false,
      },
      //{ _id: 'orgtodo',            label: 'To Do',         icon: <FormatListBulletedTwoToneIcon />,link: '/metaorg/facilities/todo',          more: false, },
      {
        _id: "organalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metaorg/facilities/analytics",
        more: true,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/facilities/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/facilities/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function InfoORG() {
  const containerType = {
    type: "info",
    label: "Information",
    menuList: [
      {
        _id: "orglist",
        label: "Info",
        icon: <TableRowsTwoToneIcon />,
        link: "/metaorg/info/info",
        more: false,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/info/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/info/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function MoniORG() {
  const containerType = {
    type: "monitor",
    label: "Monitor",
    menuList: [
      {
        _id: "orglist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metaorg/monitor/list",
        more: false,
      },
      {
        _id: "organalytics",
        label: "analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metaorg/monitor/analytics",
        more: true,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/monitor/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/monitor/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function AccORG() {
  const containerType = {
    type: "accounts",
    label: "Accounts",
    menuList: [
      {
        _id: "orglist",
        label: "List",
        icon: <ListAltTwoToneIcon />,
        link: "/metaorg/accounts/list",
        more: false,
      },
      {
        _id: "orgledger",
        label: "Ledger",
        icon: <TableRowsTwoToneIcon />,
        link: "/metaorg/accounts/ledger",
        more: false,
      },
      {
        _id: "orgcrpdf",
        label: "PDF",
        icon: <NoteAddTwoToneIcon />,
        link: "/metaorg/accounts/createpdf",
        more: false,
      },
      {
        _id: "orgsettings",
        label: "Settings",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metaorg/accounts/settings",
        more: false,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/accounts/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/accounts/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={orgAccountSearchMenuOptions}
    />
  );
}

export function ProfORG() {
  const containerType = {
    type: "profile",
    label: "Profile",
    menuList: [
      {
        _id: "orglist",
        label: "List",
        icon: <ListAltTwoToneIcon />,
        link: "/metaorg/profile/list",
        more: false,
      },
      {
        _id: "organalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metaorg/profile/analytics",
        more: true,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/profile/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/profile/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function RepoORG() {
  const containerType = {
    type: "reports",
    label: "Reports",
    menuList: [
      {
        _id: "orglist",
        label: "List",
        icon: <ListAltTwoToneIcon />,
        link: "/metaorg/reports/list",
        more: false,
      },
      {
        _id: "organalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metaorg/reports/analytics",
        more: true,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/reports/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/reports/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ResoORG() {
  const containerType = {
    type: "resources",
    label: "Resources",
    menuList: [
      {
        _id: "orglist",
        label: "List",
        icon: <ListAltTwoToneIcon />,
        link: "/metaorg/resources/list",
        more: false,
      },
      {
        _id: "organalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metaorg/resources/analytics",
        more: true,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/resources/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/resources/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function SuppORG() {
  const containerType = {
    type: "supplies",
    label: "Supplies",
    menuList: [
      {
        _id: "orglist",
        label: "List",
        icon: <ListAltTwoToneIcon />,
        link: "/metaorg/supplies/list",
        more: false,
      },
      {
        _id: "orgmonitor",
        label: "Supplies",
        icon: <InventoryTwoToneIcon />,
        link: "/metaorg/supplies/supplies",
        more: false,
      },
      {
        _id: "organalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metaorg/supplies/analytics",
        more: true,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/supplies/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/supplies/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function InsuORG() {
  const containerType = {
    eventType: ["insu"],
    type: "insurance",
    label: "Health Insurance",
    menuList: [
      {
        _id: "orginfo",
        label: "Info",
        icon: <SwitchAccountTwoToneIcon />,
        link: "/metaorg/insurance/info",
        more: false,
      },
      {
        _id: "orgmanage",
        label: "Manage",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metaorg/insurance/manage",
        more: false,
      },
      {
        _id: "usruploads",
        label: "Uploads",
        icon: <UpgradeTwoToneIcon />,
        link: "/metaorg/insurance/uploads",
        more: false,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/insurance/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/insurance/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ClinicalTestsORG() {
  const containerType = {
    type: "ct",
    label: "Clinical Tests",
    menuList: [
      {
        _id: "lablist",
        label: "List",
        icon: <ListAltTwoToneIcon />,
        link: "/metaorg/ct/list",
        more: false,
      },
      {
        _id: "labtax",
        label: "Tax",
        icon: <FormatListBulletedTwoToneIcon />,
        link: "/metaorg/ct/tax",
        more: false,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/ct/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/ct/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={ctlabMenuOptions}
    />
  );
}

export function RadiologyTestsORG() {
  const containerType = {
    type: "rt",
    label: "Radiology Tests",
    menuList: [
      {
        _id: "rlablist",
        label: "List",
        icon: <ListAltTwoToneIcon />,
        link: "/metaorg/rt/list",
        more: false,
      },
      {
        _id: "rlabtax",
        label: "Tax",
        icon: <FormatListBulletedTwoToneIcon />,
        link: "/metaorg/rt/tax",
        more: false,
      },
      {
        _id: "rlabalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/rt/notifications",
        more: true,
      },
      {
        _id: "rlabmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/rt/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={rtlabMenuOptions}
    />
  );
}

export function DrugORG() {
  const containerType = {
    type: "drugs",
    label: "Drugs",
    menuList: [
      {
        _id: "pharmalist",
        label: "List",
        icon: <ListAltTwoToneIcon />,
        link: "/metaorg/drugs/list",
        more: false,
      },
      {
        _id: "pharmatax",
        label: "Tax",
        icon: <TableRowsTwoToneIcon />,
        link: "/metaorg/drugs/tax",
        more: false,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/drugs/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/drugs/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={drugSearchMenuOptions}
    />
  );
}

export function SetORG() {
  const containerType = {
    eventType: ["setup"],
    type: "setup",
    label: "Settings",
    menuList: [
      {
        _id: "orglist",
        label: "Accounts",
        icon: <PrivacyTipTwoToneIcon />,
        link: "/metaorg/setup/privacy",
        more: false,
      },
      {
        _id: "orgsettings",
        label: "Settings",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metaorg/setup/settings",
        more: false,
      },
      {
        _id: "orguploads",
        label: "Uploads",
        icon: <UpgradeTwoToneIcon />,
        link: "/metaorg/setup/uploads",
        more: false,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/setup/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/setup/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function SpecORG() {
  const containerType = {
    type: "specialists",
    label: "Specialists",
    menuList: [
      {
        _id: "orglist",
        label: "Info",
        icon: <ListAltTwoToneIcon />,
        link: "/metaorg/specialists/info",
        more: false,
      },
      {
        _id: "organalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metaorg/specialists/analytics",
        more: true,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/specialists/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/specialists/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function TodoORG() {
  const containerType = {
    type: "todo",
    label: "Management",
    menuList: [
      {
        _id: "orglist",
        label: "List",
        icon: <ListAltTwoToneIcon />,
        link: "/metaorg/todo/list",
        more: false,
      },
      {
        _id: "organalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metaorg/todo/analytics",
        more: true,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/todo/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/todo/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function MetaorgDoThisNowOrig() {
  const containerType = {
    type: "do",
    label: "Broadcast Now",
    menuList: [
      {
        _id: "orglist",
        label: "List",
        icon: <ListAltTwoToneIcon />,
        link: "/metaorg/do/list",
        more: false,
      },
      {
        _id: "organalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metaorg/do/analytics",
        more: true,
      },
      {
        _id: "orgalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaorg/do/notifications",
        more: true,
      },
      {
        _id: "orgmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metaorg/do/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerORG
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}
