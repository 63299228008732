import React, { useState, useEffect, createRef, useContext } from "react";
import cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import dicomParser from "dicom-parser";
import { Outlet } from "react-router-dom";
import { store } from "../../redux/store";
import log from "../../services/log";

import {
  ModalProvider,
  OHIFModal,
  ViewerbaseDragDropContext,
  DialogProvider,
  Toolbar,
  SnackbarProvider,
  LoggerProvider,
  ErrorBoundary,
  DragAndDropProvider,
} from "./ui";

import dicomcornerstone from "./extensions/dicom-cornerstone";
import dicomrt from "./extensions/dicom-rt";
// import microscopy from "./extensions/dicom-microscopy";
// import dicopdf from "./extensions/dicom-pdf";
// import vtkExtension from "./extensions/vtk";
// import segmentation from "./extensions/dicom-segmentation";

import { DicomModalContext } from "./ui";
import { appInit } from "./viewer/appInit";
import {
  GenericViewerCommands,
  MeasurementsPanel,
} from "./viewer/appExtensions";
import { DicomAppProvider } from "./viewer/context/DicomContext";
import { OHIFStandaloneViewer } from "./viewer/OHIFStandaloneViewer";
import { OHIFStudyMetadataSource } from "./core/classes";
import ViewerLocalFileData from "./viewer/connectedComponents/ViewerLocalFileData";
import viewerbaseDragDropContext from "./ui/utils/viewerbaseDragDropContext";
import DicomFile from "./dragdrop/DicomFile";
import ConnectedOHIFStandaloneViewer from "./viewer/OHIFStandaloneViewer";
import { DICOM_URL } from "../../services/Constants";

let commandsManager;
let servicesManager;
let hotkeysManager;
let extensionManager;

const IsTest = false;

const TestServer = [
  {
    name: "DCM4CHEE",
    wadoUriRoot: "https://server.dcmjs.org/dcm4chee-arc/aets/DCM4CHEE/wado",
    qidoRoot: "https://server.dcmjs.org/dcm4chee-arc/aets/DCM4CHEE/rs",
    wadoRoot: "https://server.dcmjs.org/dcm4chee-arc/aets/DCM4CHEE/rs",
    qidoSupportsIncludeField: true,
    imageRendering: "wadors",
    thumbnailRendering: "wadors",
    enableStudyLazyLoad: true,
    supportsFuzzyMatching: true,
  },
];

const MetaServer = [
  {
    name: "Orthanc",
    wadoUriRoot: DICOM_URL + "/orthanc/wado",
    qidoRoot: DICOM_URL + "/orthanc/dicom-web",
    qidoroot: DICOM_URL + "/orthanc",
    wadoRoot: DICOM_URL + "/orthanc/dicom-web",
    qidoSupportsIncludeField: false,
    imageRendering: "wadouri",
    thumbnailRendering: "wadouri",
    enableStudyLazyLoad: true,
    supportsFuzzyMatching: true,
  },
];

export function DicomView(props) {
  log.info("starting dicom service", process.env);

  const config = {
    routerBasename: "/",
    extensions: [
      GenericViewerCommands,
      MeasurementsPanel,
      dicomcornerstone,
      dicomrt,
    ],
    showStudyList: true,
    filterQueryParam: false,
    disableServersCache: false,
    studyPrefetcher: {
      enabled: true,
      order: "closest",
      displaySetCount: 3,
      preventCache: false,
      prefetchDisplaySetsTimeout: 300,
      maxNumPrefetchRequests: 100,
      displayProgress: true,
      includeActiveDisplaySet: true,
    },
    servers: {
      dicomWeb: IsTest ? TestServer : MetaServer,
    },

    // Extensions should be able to suggest default values for these?
    // Or we can require that these be explicitly set
    hotkeys: [
      {
        commandName: "incrementActiveViewport",
        label: "Next Viewport",
        keys: ["right"],
      },
      {
        commandName: "decrementActiveViewport",
        label: "Previous Viewport",
        keys: ["left"],
      },

      // ~ Cornerstone Extension
      { commandName: "rotateViewportCW", label: "Rotate Right", keys: ["r"] },
      { commandName: "rotateViewportCCW", label: "Rotate Left", keys: ["l"] },
      { commandName: "invertViewport", label: "Invert", keys: ["i"] },
      {
        commandName: "flipViewportVertical",
        label: "Flip Horizontally",
        keys: ["h"],
      },
      {
        commandName: "flipViewportHorizontal",
        label: "Flip Vertically",
        keys: ["v"],
      },
      { commandName: "scaleUpViewport", label: "Zoom In", keys: ["+"] },
      { commandName: "scaleDownViewport", label: "Zoom Out", keys: ["-"] },
      { commandName: "fitViewportToWindow", label: "Zoom to Fit", keys: ["="] },
      { commandName: "resetViewport", label: "Reset", keys: ["space"] },
      // clearAnnotations
      { commandName: "nextImage", label: "Next Image", keys: ["down"] },
      { commandName: "previousImage", label: "Previous Image", keys: ["up"] },
      // firstImage
      // lastImage
      {
        commandName: "previousViewportDisplaySet",
        label: "Previous Series",
        keys: ["pagedown"],
      },
      {
        commandName: "nextViewportDisplaySet",
        label: "Next Series",
        keys: ["pageup"],
      },
      // ~ Cornerstone Tools
      { commandName: "setZoomTool", label: "Zoom", keys: ["z"] },
      // ~ Window level presets
      {
        commandName: "windowLevelPreset1",
        label: "W/L Preset 1",
        keys: ["1"],
      },
      {
        commandName: "windowLevelPreset2",
        label: "W/L Preset 2",
        keys: ["2"],
      },
      {
        commandName: "windowLevelPreset3",
        label: "W/L Preset 3",
        keys: ["3"],
      },
      {
        commandName: "windowLevelPreset4",
        label: "W/L Preset 4",
        keys: ["4"],
      },
      {
        commandName: "windowLevelPreset5",
        label: "W/L Preset 5",
        keys: ["5"],
      },
      {
        commandName: "windowLevelPreset6",
        label: "W/L Preset 6",
        keys: ["6"],
      },
      {
        commandName: "windowLevelPreset7",
        label: "W/L Preset 7",
        keys: ["7"],
      },
      {
        commandName: "windowLevelPreset8",
        label: "W/L Preset 8",
        keys: ["8"],
      },
      {
        commandName: "windowLevelPreset9",
        label: "W/L Preset 9",
        keys: ["9"],
      },
    ],
    cornerstoneExtensionConfig: {},
    maxConcurrentMetadataRequests: 5,
  };

  const [init, setInit] = useState(null);
  const [studies, setStudies] = useState(null);
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    appInit(config, [], []).then(setInit);
  }, []);

  if (!init) {
    return null;
  }

  window.cornerstone = cornerstone;
  window.cornerstoneWADOImageLoader = cornerstoneWADOImageLoader;
  window.store = store;
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

  commandsManager = init.commandsManager;
  servicesManager = init.servicesManager;
  hotkeysManager = init.hotkeysManager;
  extensionManager = init.extensionManager;

  // Set appConfig
  const appConfig = init.appConfig;

  return (
    <div style={{ height: "100%" }}>
      {/*<LoggerProvider service={servicesManager.services['LoggerService']}>*/}
      <SnackbarProvider
        service={servicesManager.services["UINotificationService"]}
      >
        <DialogProvider service={servicesManager.services["UIDialogService"]}>
          <ModalProvider
            modal={OHIFModal}
            service={servicesManager.services["UIModalService"]}
          >
            <DicomAppProvider config={init}>
              <Outlet />
            </DicomAppProvider>
          </ModalProvider>
        </DialogProvider>
      </SnackbarProvider>
      {/*</LoggerProvider>*/}
    </div>
  );
}

export { commandsManager, extensionManager, servicesManager, hotkeysManager };
