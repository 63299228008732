import React from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    position: "relative",
  },
  suffix: {
    position: "absolute",
    top: "50%",
    right: 12, // adjust this based on your requirements
    transform: "translateY(-50%)",
    color: "red", // default placeholder color for MUI
    pointerEvents: "none", // allows clicking through this onto the real input
  },
  textField: {
    width: "100%",
  },
});

function TextFieldWithSuffix() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TextField
        className={classes.textField}
        placeholder="Enter value"
        variant="outlined"
      />
      <span className={classes.suffix}>*</span>
    </div>
  );
}

export default TextFieldWithSuffix;
