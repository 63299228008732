import * as React from "react";
import { StyledTableCell, StyledTableRow } from "../common/ConstantsAndValues";

export const addressListTable = {
  address: {
    label: "Address",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  city: {
    label: "City",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  area: {
    label: "State",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  updatedon: {
    label: "Updated on",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.format("YYYY-MM-DD"),
  },
};

export function LocationListHead() {
  const table = addressListTable;

  return (
    <React.Fragment>
      <StyledTableRow style={{ fontWeight: 700 }}>
        <StyledTableCell />
        <StyledTableCell>{table.address.label}</StyledTableCell>
        <StyledTableCell align="right">{table.city.label}</StyledTableCell>
        <StyledTableCell align="right">{table.area.label}</StyledTableCell>
        <StyledTableCell align="right">{table.updatedon.label}</StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}
