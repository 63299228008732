export const CodeNameCodeSequenceValues = {
  ImagingMeasurementReport: "126000",
  ImageLibrary: "111028",
  ImagingMeasurements: "126010",
  MeasurementGroup: "125007",
  ImageLibraryGroup: "126200",
  TrackingUniqueIdentifier: "112040",
  TrackingIdentifier: "112039",
  Finding: "121071",
  FindingSite: "G-C0E3", // SRT
  CornerstoneFreeText: "CORNERSTONEFREETEXT", // CST4
  Score: "246262008",
};

export const RELATIONSHIP_TYPE = {
  INFERRED_FROM: "INFERRED FROM",
  SELECTED_FROM: "SELECTED FROM",
};

export const CodingSchemeDesignators = {
  SRT: "SRT",
  cornerstoneTools4: "CST4",
};

export default {
  CodeNameCodeSequenceValues,
  RELATIONSHIP_TYPE,
  CodingSchemeDesignators,
};
