import * as React from "react";
import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { MyTooltipButtons } from "../../CustomComponents/MyButtons";
import { ClinicalTestsInput } from "../../clinicaltests/ClinicalTestsInput";
import {
  setLabsOrder,
  setLabsOrderPresList,
} from "../../../redux/labs/labs.actions";
import { ReportsTestsListTabsMD } from "./ReportsTestsListTabsMD";
import { ClinicalTestsPresListView } from "./ClinicalTestsPresListView";
import log from "../../../services/log";

export const LabNewOrderMD = (props) => {
  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );
  const patient = useSelector((state) => state?.user?.selectedPatient);
  const searchtext = useSelector((state) => state?.user?.searchtext);
  const preslist = useSelector((state) => state?.labs?.labsorder);
  const moreselect = useSelector((state) => state?.labs?.moreselect);

  const [action, setAction] = useState("");
  const [checkedRows, setCheckedRows] = useState(null);
  const [disablePres, setDisablePres] = useState(true);

  useEffect(() => {
    if (moreselect) {
      const newstate = moreselect._source.code.startsWith("LP") === false;
      setDisablePres(!newstate);
    } else setDisablePres(true);
  }, [moreselect]);

  const dispatch = useDispatch();
  const handlePrescribe = () => {
    // log.info("Prescribe", checkedRows);
    setAction("prescribe");
  };

  const handlePresList = () => {
    setAction("viewlist");
  };

  const handleCancel = () => {
    setAction("");
  };

  const handleReset = () => {
    // log.info("prescribelist reset");
    dispatch(setLabsOrderPresList([]));
  };

  const handleRemove = () => {
    // log.info("prescribelist remove");
    dispatch(setLabsOrderPresList([]));
  };

  const handlePost = () => {
    // log.info("labslist post");
    preslist?.forEach((d) => {
      dispatch(setLabsOrder(d));
    });
    // reset list after post
    dispatch(setLabsOrderPresList([]));
  };

  const handleSelectRows = (row) => {
    log.info("clinicaltest md", row);
    setCheckedRows(row);
  };

  // log.info("drugslist md", checkedRows);

  return (
    <Grid container>
      <Grid item xs>
        <div className="clear"></div>
        {action === "prescribe" ? (
          <div>
            <ClinicalTestsInput
              cancel={handleCancel}
              doctor={user}
              patient={patient}
              clinicaltest={checkedRows}
            />
          </div>
        ) : action === "viewlist" ? (
          <ClinicalTestsPresListView handleCancel={handleCancel} />
        ) : (
          <div>
            <div className="colfr0">
              <MyTooltipButtons
                onClick={handlePresList}
                btnKey={"viewlist"}
                btnLabel={"View"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handlePrescribe}
                btnKey={"prescribelab"}
                btnLabel={"Prescribe"}
                enState={false}
              />
            </div>
            <ReportsTestsListTabsMD />
          </div>
        )}
      </Grid>
    </Grid>
  );
};
