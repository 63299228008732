import { locsActionTypes } from "../actions/locs.types";
const initialState = {
  addresssearch: null,
  address: null,
  autocom: null,
  regions: null,
  subregions: null,
  regiondata: null,
  countries: null,
  areas: null,
  area: null,
  region: null,
  subregion: null,
  country: null,
  device: null,
};

export default function locationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case locsActionTypes.ADDRESS_DATA:
      return {
        ...state,
        address: payload,
      };
    case locsActionTypes.ADDRESS_AUTO:
      return {
        ...state,
        autocoma: payload,
      };
    case locsActionTypes.ADDRESSBYID_AUTO:
      return {
        ...state,
        addressgeo: payload,
      };

    case locsActionTypes.ADDRESS_AUTOCOMPLETE:
      return {
        ...state,
        addresssearch: payload,
      };
    case locsActionTypes.SEARCH_REGIONS:
      return {
        ...state,
        regions: payload,
      };
    case locsActionTypes.SEARCH_SUBREGIONS:
      return {
        ...state,
        subregions: payload,
      };

    case locsActionTypes.SEARCH_REGIONDATA:
      return {
        ...state,
        regiondata: payload,
      };

    case locsActionTypes.SEARCH_COUNTRIES:
      return {
        ...state,
        countries: payload,
      };
    case locsActionTypes.SEARCH_AREAS:
      return {
        ...state,
        areas: payload,
      };
    case locsActionTypes.SELECTED_AREA:
      return {
        ...state,
        area: payload,
      };
    case locsActionTypes.SELECTED_REGION:
      return {
        ...state,
        region: payload,
      };
    case locsActionTypes.SELECTED_SUBREGION:
      return {
        ...state,
        subregion: payload,
      };
    case locsActionTypes.SELECTED_COUNTRY:
      return {
        ...state,
        country: payload,
      };

    case locsActionTypes.DEVICEINFO:
      return {
        ...state,
        device: payload,
      };
    case locsActionTypes.ADDRESS_NEW:
      return {
        ...state,
        newaddress: payload,
      };
    case locsActionTypes.ADDRESS_ANCHOR:
      return {
        ...state,
        anchoraddress: payload,
      };

    default:
      return state;
  }
}
