import React, { useEffect, useState } from "react";

const MessageIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Comment" clipPath="url(#clip0_817_23725)">
        <g id="Menu">
          <rect
            id="Menu line"
            x="2.09375"
            y="15.5625"
            width="5.35345"
            height="2"
            rx="1"
            fill="#404040"
          />
          <rect
            id="Menu line_2"
            x="8.09375"
            y="9.38281"
            width="8"
            height="2"
            rx="1"
          />
          <rect
            id="Menu line_3"
            x="10.8047"
            y="15.5625"
            width="11.109"
            height="2"
            rx="1"
          />
          <rect
            id="Menu line_4"
            x="6.85938"
            y="15.2109"
            width="7.25559"
            height="2"
            rx="1"
            transform="rotate(60 6.85938 15.2109)"
          />
          <rect
            id="Menu line_5"
            x="13.125"
            y="16.2148"
            width="7.251"
            height="2"
            rx="1"
            transform="rotate(120 13.125 16.2148)"
          />
          <rect
            id="Menu line 1"
            x="2.27344"
            y="3.20312"
            width="19.6356"
            height="2"
            rx="1"
          />
          <rect
            id="Menu line 2"
            x="4.09375"
            y="3.20312"
            width="14.3589"
            height="2"
            rx="1"
            transform="rotate(90 4.09375 3.20312)"
          />
          <rect
            id="Menu line 3"
            x="21.9141"
            y="3.20312"
            width="14.3589"
            height="2"
            rx="1"
            transform="rotate(90 21.9141 3.20312)"
          />
        </g>
        <circle
          id="Ellipse 25"
          cx="20.0625"
          cy="4.20312"
          r="3.70312"
          fill="#FF6D0D"
        />
      </g>
      <defs>
        <clipPath id="clip0_817_23725">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default MessageIcon;
