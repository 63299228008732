import * as React from "react";
import {
  StyledTableRowHead,
  StyledTableCellHead,
} from "../common/ConstantsAndValues";

export const pTable = {
  title: {
    label: "Drug",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  rate: {
    label: "Rate",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  qty: {
    label: "Qty",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  packageType: {
    label: "Package",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  amt: {
    label: "Amt",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  taxpc: {
    label: "Tax%",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  totAmt: {
    label: "Total Amt",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  comment: {
    label: "Comment",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  completedAt: {
    label: "Recorded on",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
};

export function MedProcListHead() {
  const table = pTable;
  return (
    <React.Fragment>
      <StyledTableRowHead style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead>{table.title.label} </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.rate.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.qty.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.packageType.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.amt.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.taxpc.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.totAmt.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.comment.label}
        </StyledTableCellHead>
      </StyledTableRowHead>
    </React.Fragment>
  );
}
