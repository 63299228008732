import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableFooter,
} from "@mui/material";
import {
  getMDGroupList,
  selectSpecialist,
} from "../../../redux/user/user.actions";
import useStyles from "../../CustomComponents/useStyles";
import { SpecialistListRow } from "./SpecialistListRow";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import { SpecialistListHead } from "./SpecialistListHead";
import { buildDepartmentGroupsQuery } from "../../dbcq/mdgroupdb";
import log from "../../../services/log";

/**
 * per department specialist
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function SpecialistList(props) {
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const department = useSelector((state) => state?.orgs?.selectdepartment);
  const specialistlist = useSelector((state) => state?.user?.pgrouplist);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [specialist, setSpecialist] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setSpecialist(specialistlist ?? []);
  }, [specialistlist]);

  useEffect(() => {
    if (department) {
      const resetpage = { ...pagedata, page: 0 };
      const jsonData = buildDepartmentGroupsQuery(
        resetpage,
        searchcontext,
        department
      );
      // log.info("specialist jsonData", jsonData)
      dispatch(getMDGroupList(jsonData)).then((r) => {
        if (r.results) {
          setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
        }
      });
    }
  }, [department]);

  const handleChangePage = (event, value) => {
    // log.info("specialist list", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const jsonData = buildDepartmentGroupsQuery(
      newpagedata,
      searchcontext,
      department
    );
    // log.info("specialist jsonData", jsonData)
    dispatch(getMDGroupList(jsonData)).then((r) => {
      if (r.results) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (e, selectrow) => {
    // log.info("specialist row", e)
    if (selectrow) {
      // const newlist = [];
      // newlist.push(e)
      setCheckedRows(e);
      dispatch(selectSpecialist(e));
    } else {
      setCheckedRows(null);
      dispatch(selectSpecialist(null));
    }
  };

  // log.info("specialist", specialistlist);

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead>
              <SpecialistListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {specialist?.length > 0 &&
                specialist?.map((row) => (
                  <SpecialistListRow
                    key={row.id}
                    row={row}
                    isRowSelected={checkedRows}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
