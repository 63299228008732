/**
 * filter by email
 * @param p
 * @param s
 * @param user
 * @returns {{}}
 */
export const buildUserEmailQuery = (p, s, values) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p?.page;
  jsonData["limit"] = p?.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["email"] = values?.email;
  return jsonData;
};

/**
 * password update query
 * @param p
 * @param s
 * @param values
 * @returns {{}}
 */
export const buildUserUpdateQuery = (user, password) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["id"] = user?.id;
  jsonData["password"] = password;
  return jsonData;
};
