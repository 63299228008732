import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@material-ui/core";

import {
  StyledTableCell,
  StyledTableCellHead,
  StyledTableRow,
  StyledTableRowHead,
  tableParam,
} from "../common/ConstantsAndValues";
import useStyles from "../CustomComponents/useStyles";
import log from "../../services/log";

export default function PolicyCompare(props) {
  const { err, maprows } = props;
  const [errMsg, setErrMsg] = useState(err);
  const [selectRows, setSelectRow] = useState(null);
  const [mapdata, setMapdata] = useState(null);
  const [headdata, setHeadata] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const newmapdatarows = [];

    // push provider
    const prrowcells = [];
    prrowcells.push("Provider");
    for (let index = 0; index < 3 && index < maprows.length; index++) {
      prrowcells.push(maprows[index].providerName);
    }
    newmapdatarows.push({ id: "provider", values: prrowcells });

    // Plan
    const plrowcells = [];
    plrowcells.push("Plan");
    for (let index = 0; index < 3 && index < maprows.length; index++) {
      plrowcells.push(maprows[index].metadata.policyPlanType);
    }
    newmapdatarows.push({ id: "plan", values: plrowcells });

    // opd
    const oprowcells = [];
    oprowcells.push("OPD coverage");
    for (let index = 0; index < 3 && index < maprows.length; index++) {
      maprows[index].opdCoverage
        ? oprowcells.push(maprows[index].opdCoverage.coverage)
        : oprowcells.push("None");
    }
    newmapdatarows.push({ id: "opd", values: oprowcells });

    // hospital
    const hirowcells = [];
    hirowcells.push("Hospital coverage");
    for (let index = 0; index < 3 && index < maprows.length; index++) {
      maprows[index].opdCoverage
        ? hirowcells.push(maprows[index].hiiCoverage.coverage)
        : hirowcells.push("None");
    }
    newmapdatarows.push({ id: "hii", values: hirowcells });

    // dental
    const derowcells = [];
    derowcells.push("Dental coverage");
    for (let index = 0; index < 3 && index < maprows.length; index++) {
      maprows[index].dentalCoverage
        ? derowcells.push(maprows[index].dentalCoverage.coverage)
        : derowcells.push("None");
    }
    newmapdatarows.push({ id: "dental", values: derowcells });

    // vision
    const virowcells = [];
    virowcells.push("Vision coverage");
    for (let index = 0; index < 3 && index < maprows.length; index++) {
      maprows[index].visionCoverage
        ? virowcells.push(maprows[index].visionCoverage.coverage)
        : virowcells.push("None");
    }
    newmapdatarows.push({ id: "vision", values: virowcells });

    // discount
    const drowcells = [];
    drowcells.push("Discount");
    for (let index = 0; index < 3 && index < maprows.length; index++) {
      maprows[index].discount
        ? drowcells.push(maprows[index].discount)
        : drowcells.push("None");
    }
    newmapdatarows.push({ id: "discount", values: drowcells });

    // discount
    const fmrowcells = [];
    fmrowcells.push("Family discount");
    for (let index = 0; index < 3 && index < maprows.length; index++) {
      maprows[index].familyDiscount
        ? fmrowcells.push(maprows[index].familyDiscount)
        : fmrowcells.push("None");
    }
    newmapdatarows.push({ id: "familydiscount", values: fmrowcells });

    // ncb
    const ncrowcells = [];
    ncrowcells.push("No claim bonus");
    for (let index = 0; index < 3 && index < maprows.length; index++) {
      maprows[index].ncb
        ? ncrowcells.push(maprows[index].ncb)
        : ncrowcells.push("None");
    }
    newmapdatarows.push({ id: "ncb", values: ncrowcells });

    setMapdata(newmapdatarows);

    const headcells = [];
    headcells.push("Freature Description");
    for (let index = 0; index < 3 && index < maprows.length; index++) {
      headcells.push(maprows[index].policyName);
    }
    setHeadata({ id: "comarehead", values: headcells });
  }, [maprows]);

  // log.info("policy compare", mapdata);

  return (
    <Grid container>
      <div className="text-color-error">{errMsg && errMsg}</div>

      <TableContainer
        component={Paper}
        elevation={0}
        style={{ minHeight: tableParam.minHeight, marginTop: "18px" }}
        className="list"
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <StyledTableRowHead>
              {headdata &&
                headdata.values.map((value, i) => (
                  <StyledTableCellHead key={"polh" + i}>
                    {value}
                  </StyledTableCellHead>
                ))}
            </StyledTableRowHead>
          </TableHead>

          <TableBody>
            {mapdata &&
              mapdata.map((det, i) => (
                <StyledTableRow key={det.id} className="text-color">
                  {det.values.map((value, j) => (
                    <StyledTableCell
                      scope="row"
                      key={i + "pold" + j}
                      style={{ verticalAlign: "top" }}
                    >
                      {value}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
