import React, { useState, useEffect } from "react";

import { Grid, Box } from "@mui/material";
import { Title } from "../common/Title";
import { tableParam, colChart } from "../common/ConstantsAndValues";
import { useDispatch, useSelector } from "react-redux";
import { SelectOptionsAuto } from "../common/SelectOptions";
import { getOrgGroups } from "../../redux/actions/orgs";
import { selectOrgGroup } from "../../redux/actions/orgs";
import { MyTooltipButtons, MyHelpButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";
import { buildOrgGroupsQuery } from "../dbcq/groupsdb";

const MSStyles = {
  control: (styles) => ({ ...styles }),
  container: (styles) => ({ ...styles, width: colChart.width }),
  option: (styles) => ({ ...styles }),
  // menu:      styles => ({ ...styles,  width: colChart.width }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: colChart.width,
  }),
};

export default function OrgGroupSelectList(props) {
  const { cancelEventPage, title } = props;

  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const orggroups = useSelector((state) => state?.orgs?.orggroups);
  const selectorg = useSelector((state) => state?.orgs?.selectorg);
  const selectorggroup = useSelector((state) => state?.orgs?.selectorggroup);

  const [selectedOption, setSelectedOption] = useState(
    selectorggroup
      ? { label: selectorggroup.groupName, value: selectorggroup }
      : null
  );
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [options, setOptions] = useState([]);
  const [err, setErr] = useState("");
  const dispatch = useDispatch();
  const errMsg = "Selection of Group is required";

  useEffect(() => {
    if (selectorg) {
      const resetpage = { ...pagedata, page: 0 };
      const jsonData = buildOrgGroupsQuery(resetpage, searchcontext, selectorg);
      dispatch(getOrgGroups(jsonData)).then((r) => {
        if (r.results) {
          setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
        }
      });
    }
  }, [selectorg, dispatch]);

  // set options
  useEffect(() => {
    let newoptions = [];
    if (orggroups && orggroups.length > 0) {
      // data.forEach((d) => console.log("Selected options", d));
      orggroups.forEach((d) =>
        newoptions.push({ label: d.groupName, value: d })
      );
      // console.log("Selected options", newoptions);
      setOptions(newoptions);
    } else {
      setOptions(newoptions);
    }
  }, [orggroups]);

  function handleSelect(event) {
    setSelectedOption(event);
    setErr(event ? "" : errMsg);
  }

  const handleDone = () => {
    if (!selectedOption) {
      setErr(errMsg);
      return;
    }
    setErr("");
    dispatch(selectOrgGroup(selectedOption.value));
    cancelEventPage();
  };

  // log.info("orggroups", orggroups);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%", md: "60%", lg: "50%" },
        }}
      >
        <Box mb={1}>
          <div className="colfl0">
            <Title>{title ? title : ""} </Title>
          </div>
          <div className="colfr0">
            <MyTooltipButtons
              onClick={cancelEventPage}
              btnKey={"cancel"}
              btnLabel={"Cancel"}
              enState={false}
            />
          </div>
        </Box>
        <Box className="clear text-color-error" mb={1}>
          {err}
        </Box>
        <Box className="text-color">
          <SelectOptionsAuto
            openPage={handleSelect}
            data={options}
            multi={false}
            title={"organization group"}
            wide={0}
          />
        </Box>

        <Box className="clear" />
        <Box mt={2} pt={2} className="border-dbt">
          <Box className="colfr0">
            <MyHelpButtons
              onClick={handleDone}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
              btnTooltip={"Add"}
            />
          </Box>
          <Box className="clear" />
        </Box>
        <Box className="clear" />
      </Grid>
    </Grid>
  );
}
