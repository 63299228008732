// import node modules
import * as React from "react";
import Select, { components } from "react-select";
import ReactDatePicker from "react-datepicker";
import { forwardRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  FormLabel,
  Checkbox,
  FormControlLabel,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Link,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// import other modules
import {
  customStylesField,
  doctorSearchGrid,
  formattedArray,
} from "../../tabs/TabsStaticData";
import { CalendarIcon, LocationPinIcon } from "../../customIcons/FindCare";
import { ThemeTabs } from "../../tabs/ThemeTabs";
import {
  CalendarTableIcon,
  FirstPageIcon,
  LastPageIcon,
  LocationTableIcon,
  NextPageIcon,
  PreviousPageIcon,
  SearchTableIcon,
} from "../../customIcons/TableIcons";
import { MetaSearchIcon } from "../../customIcons/MetaSearchIcon";

export const FindCareBody = () => {
  const theme = useTheme();
  const [selectedSearch, setSelectedSearch] = useState(0);
  const [doctorList, setDoctorList] = useState([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const FindCareSearchArea = () => {
    const [startDate, setStartDate] = useState();

    const doctorType = [
      { label: "General Medicine", value: 0 },
      { label: "Dermatology", value: 1 },
      { label: "Pediatrics", value: 2 },
      { label: "Ob/Gyn", value: 3 },
      { label: "Dermatology", value: 4 },
      { label: "ENT", value: 5 },
      { label: "Homeopathy", value: 6 },
      { label: "Ayurveda", value: 7 },
    ];

    const names = [
      { label: "Praveen Gupta", value: 0 },
      { label: "Aman Gupta", value: 1 },
    ];

    const searchDoctors = () => {
      let data = [
        {
          name: "Dr. Aamir Khan",
          address: "C-73/602, Vasant Vihar, Khopat, Thane",
          date: "12 October, 2023",
        },
        {
          name: "Dr. Aamir Khan",
          address: "C-73/602, Vasant Vihar, Khopat, Thane",
          date: "12 October, 2023",
        },
        {
          name: "Dr. Aamir Khan",
          address: "C-73/602, Vasant Vihar, Khopat, Thane",
          date: "12 October, 2023",
        },
        {
          name: "Dr. Aamir Khan",
          address: "C-73/602, Vasant Vihar, Khopat, Thane",
          date: "12 October, 2023",
        },
        {
          name: "Dr. Aamir Khan",
          address: "C-73/602, Vasant Vihar, Khopat, Thane",
          date: "12 October, 2023",
        },
        {
          name: "Dr. Aamir Khan",
          address: "C-73/602, Vasant Vihar, Khopat, Thane",
          date: "12 October, 2023",
        },
        {
          name: "Dr. Aamir Khan",
          address: "C-73/602, Vasant Vihar, Khopat, Thane",
          date: "12 October, 2023",
        },
        {
          name: "Dr. Aamir Khan",
          address: "C-73/602, Vasant Vihar, Khopat, Thane",
          date: "12 October, 2023",
        },
        {
          name: "Dr. Aamir Khan",
          address: "C-73/602, Vasant Vihar, Khopat, Thane",
          date: "12 October, 2023",
        },
        {
          name: "Dr. Aamir Khan",
          address: "C-73/602, Vasant Vihar, Khopat, Thane",
          date: "12 October, 2023",
        },
      ];
      setDoctorList(data);
    };

    const providers = [
      { title: "Doctor" },
      { title: "Clinic" },
      { title: "Pharmacy" },
      { title: "Laboratory" },
    ];

    const IconInput = forwardRef(({ value, onClick, onChange }, ref) => (
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        >
          <CalendarIcon size={0.9} />
        </Box>
        <input
          placeholder="Choose appointment date"
          className="meta__input"
          value={value}
          onClick={onClick}
          onChange={onChange}
          ref={ref}
        />
      </Box>
    ));

    // function CustomTabPanel(props) {
    //   const { children, value, index, ...other } = props;

    //   return (
    //     <div
    //       role="tabpanel"
    //       hidden={value !== index}
    //       id={`simple-tabpanel-${index}`}
    //       aria-labelledby={`simple-tab-${index}`}
    //       {...other}
    //     >
    //       {value === index && (
    //         <Box sx={{ p: 3 }}>
    //           <Typography>{children}</Typography>
    //         </Box>
    //       )}
    //     </div>
    //   );
    // }

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <MetaSearchIcon size={0.7} />
        </components.DropdownIndicator>
      );
    };

    return (
      <Box>
        <Grid container sx={{ mt: 1.5, flexGrow: 1 }}>
          {doctorSearchGrid.map((data, index) => (
            <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
              <Paper
                onClick={() => setSelectedSearch(data.key)}
                sx={{
                  borderRadius: "10px",
                  cursor: "pointer",
                  display: "flex",
                  padding: "1.5rem 2rem",
                  margin: "0.5rem",
                  boxShadow: "0px 4px 30px 0px rgba(202, 202, 202, 0.25);",
                  border:
                    selectedSearch === data.key
                      ? "2px solid #2A8AA7"
                      : "2px solid #fff",
                  background:
                    selectedSearch === data.key
                      ? "rgba(234, 244, 247, 0.5)"
                      : "",
                  transition: "0.4s ease",
                  "&:hover": {
                    border: "2px solid #000",
                  },
                }}
              >
                <Box>{data.icon}</Box>
                <Box sx={{ ml: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "16px",
                      fontFamily: "DM SANS",
                    }}
                  >
                    {data.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#8f8f8f",
                      fontWeight: "400",
                      fontSize: "13px",
                      fontFamily: "DM SANS",
                    }}
                  >
                    {data.subtitle}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ mt: 4, mb: 4, mx: 1 }} />

        <Box sx={{ mx: 1 }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "22px",
              color: "#404040",
              fontFamily: "DM SANS",
            }}
          >
            Start your search
          </Typography>

          <Grid container sx={{ mt: 4, flexGrow: 1 }}>
            <Grid>
              <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                <span className="required_dot"></span>
                Provider Category
              </FormLabel>
              <Box sx={{ width: "100%" }}>
                <Box sx={{}}>
                  <Tabs
                    className="find_care_tabs"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "transparent",
                        color: "black",
                      },
                    }}
                    sx={{
                      "& .MuiTab-root.Mui-selected": {
                        background: "#EAF4F7 !important",
                        // borderRight: "none",
                        border: "1px solid #94C4D2",
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                  >
                    {providers.map((data, index) => (
                      <Tab
                        className="find_care_tab"
                        label={data.title}
                        key={index}
                        {...a11yProps(index)}
                      />
                    ))}
                  </Tabs>
                </Box>
                {/* {providers.map((data, index) => (
                 <CustomTabPanel value={value} index={index}>
                   {data.title} Area
                 </CustomTabPanel>
              ))} */}
              </Box>
            </Grid>
            {value === 0 ? (
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ ml: 2 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  <span className="required_dot"></span>
                  Provider specialty
                </FormLabel>
                <Box sx={{ width: "95%" }}>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className=""
                    placeholder={"Select a speciality"}
                    onChange={(value) => {}}
                    styles={customStylesField}
                    isClearable={true}
                    isSearchable={true}
                    options={formattedArray(doctorType)}
                  />
                </Box>
              </Grid>
            ) : value === 1 ? (
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ ml: 2 }}>
                <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                  <span className="required_dot"></span>
                  Name
                </FormLabel>
                <Box sx={{ width: "95%" }}>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator,
                    }}
                    className=""
                    isSearchable={true}
                    placeholder={"Enter a name"}
                    onChange={(value) => {}}
                    styles={customStylesField}
                    isClearable={true}
                    options={formattedArray(names)}
                  />
                </Box>
                {/* <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      right: "24px",
                      top: "6px",
                    }}
                  >
                    <MetaSearchIcon size={0.7} />
                  </Box>
                  <input placeholder="Enter a name" className="meta__input" />
                </Box> */}
              </Grid>
            ) : null}
          </Grid>
          <Grid container sx={{ mt: 2, flexGrow: 1 }}>
            <Grid item xs={12} sm={6} md={6} lg={3} sx={{ my: 1 }}>
              <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                <span className="required_dot"></span>
                Location
              </FormLabel>
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    right: "8px",
                    top: "7px",
                  }}
                >
                  <LocationPinIcon size={0.8} />
                </Box>
                <input
                  placeholder="Search by zip/location"
                  className="meta__input"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} sx={{ my: 1 }}>
              <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                <span className="required_dot"></span>
                Date
              </FormLabel>

              <ReactDatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control"
                peekNextMonth={true}
                showMonthDropdown={true}
                showYearDropdown={true}
                dropdownMode="select"
                customInput={<IconInput />}
                maxDate={new Date()}
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      transform: "scale(0.9)",
                      padding: 0,
                      margin: 0,
                      color: "#404040",
                      "&.Mui-checked": {
                        color: "#404040",
                      },
                    }}
                  />
                }
                sx={{ ml: 0.5, mt: 0.5, mb: 0 }}
                label={
                  <Typography
                    sx={{ fontFamily: "DM Sans", ml: 0.5, fontSize: "14px" }}
                  >
                    {"  "} I am flexible
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          {/* <Grid container sx={{ mt: 1, flexGrow: 1 }}>
            <Grid item xs={12} sm={6} md={6} lg={3} sx={{ my: 1 }}>
              <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                <span className="required_dot"></span>
                Provider Category
              </FormLabel>
              <Box sx={{ width: "95%" }}>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className=""
                  isSearchable={false}
                  placeholder={"Select a category"}
                  onChange={(value) => {}}
                  styles={customStylesField}
                  options={formattedArray(serviceType)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} sx={{ my: 1 }}>
              <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                <span className="required_dot"></span>
                Location
              </FormLabel>
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    right: "8px",
                    top: "5px",
                  }}
                >
                  <LocationPinIcon size={0.8} />
                </Box>
                <input
                  placeholder="Search by zip/location"
                  className="meta__input"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} sx={{ my: 1 }}>
              <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                <span className="required_dot"></span>
                Date
              </FormLabel>

              <ReactDatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control"
                peekNextMonth={true}
                showMonthDropdown={true}
                showYearDropdown={true}
                dropdownMode="select"
                customInput={<IconInput />}
                maxDate={new Date()}
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      transform: "scale(0.9)",
                      padding: 0,
                      margin: 0,
                      color: "#404040",
                      "&.Mui-checked": {
                        color: "#404040",
                      },
                    }}
                  />
                }
                sx={{ ml: 0.5, mt: 0.5, mb: 0 }}
                label={
                  <Typography
                    sx={{ fontFamily: "DM Sans", ml: 0.5, fontSize: "14px" }}
                  >
                    {"  "} I am flexible
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1, flexGrow: 1 }}>
            <Grid item xs={12} sm={6} md={6} lg={3} sx={{ my: 1 }}>
              <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                <span className="required_dot"></span>
                Provider specialty
              </FormLabel>
              <Box sx={{ width: "95%" }}>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className=""
                  isSearchable={false}
                  placeholder={"Select a speciality"}
                  onChange={(value) => {}}
                  styles={customStylesField}
                  options={formattedArray(doctorType)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} sx={{ my: 1, ml: 0.5 }}>
              <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
                Include out-of-insurance network results
              </FormLabel>
              <Box sx={{ width: "95%" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        
                        sx={{
                          "&, &.Mui-checked": {
                            color: "#404040",
                          },
                        }}
                      />
                    }
                    label={<span className="text-14 dm_sans">Yes</span>}
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        
                        sx={{
                          "&, &.Mui-checked": {
                            color: "#404040",
                          },
                        }}
                      />
                    }
                    label={<span className="text-14 dm_sans">No</span>}
                  />
                </RadioGroup>
              </Box>
            </Grid>
          </Grid> */}

          <button className="meta__button mt-2" onClick={searchDoctors}>
            Search
          </button>
        </Box>
      </Box>
    );
  };
  const FindCareSearchBody = () => {
    return (
      <Box>
        <Box sx={{ mx: 1 }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "22px",
              color: "#404040",
              fontFamily: "DM SANS",
            }}
          >
            Search results
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                mt: 1,
                mb: 2,
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  color: "#8F8F8F",
                  fontFamily: "DM SANS",
                }}
              >
                Filter results
              </Typography>
              <ChevronRightIcon
                style={{ color: "#8f8f8f", fontSize: 20 }}
                sx={{ mt: 0.5 }}
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  mt: 1,
                  mb: 2,
                  mx: 1,
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <SearchTableIcon size={0.6} />
                <Typography
                  sx={{
                    color: "#585858",
                    fontFamily: "DM SANS",
                    fontWeight: "500",
                  }}
                >
                  Search Table
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 1,
                  mb: 2,
                  mx: 1,
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <CalendarTableIcon size={0.7} />
                <Typography
                  sx={{
                    color: "#585858",
                    fontFamily: "DM SANS",
                    fontWeight: "500",
                  }}
                >
                  Flexible
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 1,
                  mb: 2,
                  mx: 1,
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <LocationTableIcon size={0.65} />
                <Typography
                  sx={{
                    color: "#585858",
                    fontFamily: "DM SANS",
                    fontWeight: "500",
                  }}
                >
                  Location
                </Typography>
              </Box>
            </Box>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                    Name
                  </TableCell>
                  <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                    Address
                  </TableCell>
                  <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                    Date
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontFamily: "DM SANS", padding: "5px" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {doctorList.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontFamily: "DM SANS",
                        fontSize: "15px",
                        padding: "10px 5px",
                      }}
                    >
                      <Link href="#" color={"#2A8AA7"}>
                        {row.name}
                      </Link>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM SANS",
                        fontSize: "15px",
                        padding: "10px 5px",
                      }}
                    >
                      {row.address}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM SANS",
                        fontSize: "15px",
                        padding: "10px 5px",
                      }}
                    >
                      {row.date}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM SANS",
                        fontSize: "15px",
                        padding: "10px 5px",
                      }}
                      align="right"
                    >
                      <Link color={"#2A8AA7"} href="#">
                        {"Book Appointment"}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              paddingTop: "1.5rem",
              borderTop: `1px solid #e9e9e9`,
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Box sx={{ mx: 1, cursor: "pointer" }}>
              <FirstPageIcon />
            </Box>
            <Box sx={{ mx: 2, cursor: "pointer" }}>
              <PreviousPageIcon />
            </Box>
            <Typography
              sx={{
                paddingTop: "2px",
                fontFamily: "DM SANS",
                fontSize: (theme) => theme.typography.button.fontSize,
              }}
            >
              Page
              <Box
                sx={{
                  display: "inline",
                  border: "1px solid #C7C7C7",
                  px: 0.6,
                  borderRadius: "4px",
                }}
              >
                1
              </Box>
              / 22
            </Typography>
            <Box sx={{ mx: 2, cursor: "pointer" }}>
              <NextPageIcon />
            </Box>
            <Box sx={{ mx: 1, cursor: "pointer" }}>
              <LastPageIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return doctorList.length > 0 ? (
    <FindCareSearchBody />
  ) : (
    <FindCareSearchArea />
  );
};

function FindCare() {
  const tabs = [
    { id: 1000, title: "Find a provider, specialist, or medical office" },
    { id: 1001, title: "My Providers" },
  ];
  return (
    <>
      <ThemeTabs tabs={tabs} />
    </>
  );
}

export default FindCare;
