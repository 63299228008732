// const dummyfn = (params) => {
// };

// const log = {
//   error: dummyfn,
//   warn: dummyfn,
//   info: dummyfn,
//   trace: dummyfn,
//   debug: dummyfn,
//   time: dummyfn,
//   timeEnd: dummyfn,
// };

const log = {
  error: console.error,
  warn: console.warn,
  info: console.log,
  trace: console.trace,
  debug: console.debug,
  time: console.time,
  timeEnd: console.timeEnd,
};

export default log;
