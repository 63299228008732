import * as React from "react";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { Form, useFormik } from "formik";
import { Grid, Box, Typography } from "@mui/material";
import { LocMSStyles } from "../location/LocMSStyles";
import InputFormComponent from "../CustomComponents/InputFormComponent";
import { useDispatch, useSelector } from "react-redux";
import { setPrecriptionList } from "../../redux/drugs/drugs.actions";
import { MyTooltipButtons, MyHelpButtons } from "../CustomComponents/MyButtons";
import { setRadlabsOrderPresList } from "../../redux/labs/labs.actions";
import { radPrescriptionSchema } from "../schema/prescriptionSchema";

import log from "../../services/log";

export const OtherTestsInput = (props) => {
  const { doctor, patient, othertest, cancel } = props;

  const preslist = useSelector((state) => state.labs.radlabpreslist);
  const radlabselect = useSelector((state) => state.labs.radlabselect);
  const dispatch = useDispatch();

  const othertestform = useFormik({
    initialValues: {
      doctorId: doctor ? doctor.metadata.accountId : "",
      doctorName: doctor ? doctor.name : "",
      patientId: patient ? patient.metadata.patientId : "",
      patientName: patient ? patient.name : "",
      labName: radlabselect ? radlabselect._source.common_name : "",
      labId: radlabselect ? radlabselect._source.loinc_num : "",
      status: "approved",
      currentlist: preslist ? [...preslist] : [],
    },
    validationSchema: radPrescriptionSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      log.info("othertestinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["doctorId"] = values.doctorId;
      jsonData.metadata["patientId"] = values.patientId;
      jsonData.metadata["labId"] = values.labId;
      jsonData.metadata["loincNumber"] = values.labId;
      jsonData["doctorName"] = values.doctorName;
      jsonData["patientName"] = values.patientName;
      jsonData["labName"] = values.labName;
      jsonData["status"] = values.status;

      values.currentlist.push(jsonData);
      log.info("othertestinput", values.currentlist);
      dispatch(setRadlabsOrderPresList(values.currentlist));
      cancel();
    },
    onReset: (values) => {
      values.dose = 1;
    },
  });

  const addPrescription = () => {
    // dispatch(setPrecriptionList());
  };

  const cancelEvent = () => {
    cancel();
  };

  // log.info("othertestinput", othertestform.errors);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%", md: "50%", lg: "40%" },
        }}
      >
        <form>
          <Box className="colfr0 " mb={1}>
            <MyTooltipButtons
              onClick={cancelEvent}
              btnKey={"cancel"}
              btnLabel={"Cancel"}
              enState={false}
            />
          </Box>
          <Box className="clear"></Box>
          <Box width="100%" className="text-color">
            <Box className="border-db p10 mb-30">
              <Typography className="text-color-primary">
                {othertestform.values.labName}
              </Typography>
            </Box>
            <Box>
              <InputFormComponent
                id="comment"
                name="comment"
                label="Comment"
                errorText={othertestform.errors.dose}
                defaultValue={othertestform.values.dose}
                errors={othertestform.errors.dose ? true : false}
                variant="standard"
                handleChange={othertestform.handleChange}
              />
            </Box>
          </Box>
        </form>
        <Box className="clear" />
        <Box mt={2} pt={2} className="border-dbt">
          <Box className="colfr0">
            <MyHelpButtons
              onClick={othertestform.handleSubmit}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
              btnTooltip={"Add"}
            />
          </Box>
          <Box className="clear" />
        </Box>
        <Box className="clear" />
        <Box mt={2} className="border-dbt p10 text-color-error">
          {othertestform.errors.labName ? (
            <Typography className="text-color-primary-highlight">
              {othertestform.errors.labName}
            </Typography>
          ) : (
            <div />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
