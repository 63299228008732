import * as yup from "yup";

export const appointmentSchema = yup.object({
  id: yup.string(),
  orgId: yup.string(),
  groupId: yup.string(),
  guestId: yup.string(),
  departmentId: yup.string(),
  providerId: yup.string(),
  userId: yup.string().required("user is required"),
  email: yup.string().required("user email is required"),
  guestName: yup.string(),
  providerName: yup.string(),
  userName: yup.string().required("user name is required"),
  eventType: yup.string().required("event type is required"),
  eventTitle: yup.string().required("event title is required"),
  eventDescp: yup.string().required("event description is required"),
  locId: yup.string(),
  address: yup.string().required("event location is required"),
  dateStart: yup.string().required("event start is required"),
  dateEnd: yup.string().required("event end is required"),
  command: yup.string(),
});

export const appointmentUpdateSchema = yup.object({
  id: yup.string().required("user is required"),
  orgId: yup.string(),
  groupId: yup.string(),
  departmentId: yup.string(),
  providerId: yup.string(),
  locId: yup.string(),
  guestId: yup.string(),
  userId: yup.string().required("user is required"),
  email: yup.string().required("user email is required"),
  eventId: yup.string().required("event is required"),
  address: yup.string().required("event location is required"),
  eventTitle: yup.string().required("event title is required"),
  eventDescp: yup.string().required("event description is required"),
  dateStart: yup.string().required("event start is required"),
  dateEnd: yup.string().required("event end email is required"),
});
