import {
  IdcardIcon,
  MedicationIcon,
  ScopeIcon,
  TubeIcon,
} from "../customIcons/FindCare";

export const doctorSearchGrid = [
  {
    key: 1,
    icon: <IdcardIcon size={0.8} />,
    title: "Doctor by name",
    subtitle: "Find your doctor",
    route: "/",
  },
  {
    key: 2,
    icon: <ScopeIcon size={0.8} />,
    title: "Doctor by specialty",
    subtitle: "Ready to serve you",
    route: "/",
  },
  {
    key: 3,
    icon: <MedicationIcon size={0.8} />,
    title: "Pharmacies near me",
    subtitle: "Reasonably priced",
    route: "/",
  },
  {
    key: 4,
    icon: <TubeIcon size={0.8} />,
    title: "Labs near me",
    subtitle: "Accredited and qualified",
    route: "/",
  },
];

export const formattedArray = (array) => {
  return array?.map((item) => {
    return {
      label: item.label,
      value: item.value,
    };
  });
};

export const formattedArrayFamilyList = (array) => {
  return array.map((item) => {
    return {
      label: item.name,
      value: item.name,
    };
  });
};

export const customStylesField = {
  container: (provided) => ({
    ...provided,
    display: "inline-block",
    // width: "100%",
    minHeight: "1px",
    textAlign: "left",
    // border: "none",
    margin: 0,
    padding: 0,
    width: "100%",
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
    border: "1px solid #C7C7C7",
    // borderRadius: "20px",
    // width: "90%",
    fontSize: "15px",
    fontWeight: "500",
    color: "#585858",
    fontFamily: "DM SANS",
    // height: 35,

    paddingLeft: "10px",
    // minHeight: 35,
    "&:hover": {
      div: {
        div: {
          svg: {
            fill: "#FF6D0D",
          },
        },
      },
    },
  }),
  input: (provided) => ({
    ...provided,
    // minHeight: "1px",
    margin: 0,
    padding: 0,
    height: 40,
    minHeight: 40,
    // height: 0,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    minHeight: "1px",
    paddingTop: "0",
    paddingBottom: "0",
    color: "#8F8F8F",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    minHeight: "1px",
    height: "24px",
    background: "#fff",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: "1px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    //   minHeight: '1px',
    //   height: '40px',
    margin: 0,
    padding: 0,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  singleValue: (provided) => ({
    ...provided,
    minHeight: "1px",
    paddingBottom: "2px",
    color: "#585858",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#EAF4F7" : "white",
    color: "#404040",
    fontSize: "15px",
    cursor: "pointer",
    fontFamily: "DM SANS",
    fontWeight: state.isSelected ? "600" : "400",
    borderLeft: state.isSelected ? "4px solid #5FA7BD" : "",
    "&:hover": {
      transition: !state.isSelected ? "0.4s ease" : "",
      borderLeft: !state.isSelected ? "6px solid #94C4D2" : "",
    },
  }),
};

export const customStylesFieldHover = {
  container: (provided) => ({
    ...provided,
    display: "inline-block",
    // width: "100%",
    minHeight: "1px",
    textAlign: "left",
    // border: "none",
    margin: 0,
    padding: 0,
    width: "100%",
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    border: "1px solid #C7C7C7",
    // borderRadius: "20px",
    // width: "90%",
    fontSize: "15px",
    fontWeight: "500",
    color: "#585858",
    fontFamily: "DM SANS",
    // height: 35,

    borderRadius: "8px",
    paddingLeft: "10px",
    // minHeight: 35,
    "&:hover": {
      border: "2px solid black",
      div: {
        div: {
          svg: {
            // fill: "#FF6D0D",
          },
        },
      },
    },
  }),
  menu: (provided) => ({ ...provided, border: "2px solid black" }),
  input: (provided) => ({
    ...provided,
    // minHeight: "1px",
    margin: 0,
    padding: 0,
    height: 40,
    minHeight: 40,
    // height: 0,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    minHeight: "1px",
    paddingTop: "0",
    paddingBottom: "0",
    color: "#8F8F8F",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    minHeight: "1px",
    height: "24px",
    background: "#fff",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: "1px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    //   minHeight: '1px',
    //   height: '40px',
    margin: 0,
    padding: 0,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  singleValue: (provided) => ({
    ...provided,
    minHeight: "1px",
    paddingBottom: "2px",
    color: "#585858",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#EAF4F7" : "white",
    color: "#404040",
    fontSize: "15px",
    cursor: "pointer",
    fontFamily: "DM SANS",
    fontWeight: state.isSelected ? "600" : "400",
    borderLeft: state.isSelected ? "4px solid #5FA7BD" : "",
    "&:hover": {
      transition: !state.isSelected ? "0.4s ease" : "",
      borderLeft: !state.isSelected ? "6px solid #94C4D2" : "",
    },
  }),
};

export const customStylesFilter = {
  container: (provided) => ({
    ...provided,
    display: "inline-block",
    // width: "100%",
    minHeight: "1px",
    textAlign: "left",
    // border: "none",
    margin: 0,
    padding: 0,
    width: "100%",
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    border: "1px solid #C7C7C7",
    // borderRadius: "20px",
    // width: "90%",
    fontSize: "15px",
    fontWeight: "500",
    color: "#585858",
    fontFamily: "DM SANS",
    // height: 35,

    borderRadius: "8px",
    paddingLeft: "10px",
    // minHeight: 35,
    "&:hover": {
      border: "2px solid black",
      div: {
        div: {
          svg: {
            // fill: "#FF6D0D",
          },
        },
      },
    },
  }),
  menu: (provided) => ({ ...provided, border: "2px solid black" }),
  input: (provided) => ({
    ...provided,
    // minHeight: "1px",
    margin: 0,
    padding: 0,
    height: 40,
    minHeight: 40,
    // height: 0,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    minHeight: "1px",
    paddingTop: "0",
    paddingBottom: "0",
    color: "#8F8F8F",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    minHeight: "1px",
    height: "24px",
    background: "#fff",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: "1px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    //   minHeight: '1px',
    //   height: '40px',
    margin: 0,
    padding: 0,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  singleValue: (provided) => ({
    ...provided,
    minHeight: "1px",
    paddingBottom: "2px",
    color: "#585858",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#EAF4F7" : "white",
    color: "#404040",
    fontSize: "15px",
    cursor: "pointer",
    fontFamily: "DM SANS",
    fontWeight: state.isSelected ? "600" : "400",
    borderLeft: state.isSelected ? "4px solid #5FA7BD" : "",
    "&:hover": {
      transition: !state.isSelected ? "0.4s ease" : "",
      borderLeft: !state.isSelected ? "6px solid #94C4D2" : "",
    },
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#EAF4F7",
    borderRadius: "25px",
  }),
};

export const customStyles = {
  container: (provided) => ({
    ...provided,
    display: "inline-block",
    // width: "100%",
    minHeight: "1px",
    textAlign: "left",
    border: "none",
    margin: 0,
    padding: 0,
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
    border: "none",
    borderRadius: "20px",
    width: "200px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#585858",
    fontFamily: "DM SANS",
    height: 35,
    minHeight: 35,
    backgroundColor: "rgba(233, 233, 233, 0.15)",
    "&:hover": {
      div: {
        div: {
          svg: {
            fill: "#FF6D0D",
          },
        },
      },
    },
  }),
  input: (provided) => ({
    ...provided,
    // minHeight: "1px",
    margin: 0,
    padding: 0,
    height: 35,
    minHeight: 35,
    // height: 0,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    minHeight: "1px",
    paddingTop: "0",
    paddingBottom: "0",
    color: "#8F8F8F",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    minHeight: "1px",
    height: "24px",
    background: "#fff",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: "1px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    //   minHeight: '1px',
    //   height: '40px',
    margin: 0,
    padding: 0,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  singleValue: (provided) => ({
    ...provided,
    minHeight: "1px",
    paddingBottom: "2px",
    color: "#585858",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#EAF4F7" : "white",
    color: "#404040",
    cursor: "pointer",
    fontWeight: state.isSelected ? "600" : "500",
    borderLeft: state.isSelected ? "4px solid #5FA7BD" : "",
    "&:hover": {
      transition: !state.isSelected ? "0.4s ease" : "",
      borderLeft: !state.isSelected ? "6px solid #94C4D2" : "",
    },
  }),
};
