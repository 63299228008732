import React, { Component, Fragment } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import Invoice from "./templates/Invoice";
import { invoiceData } from "../../tests/InvoiceData";

export const MetaInvoiceViewer = () => {
  const sampleTitle = "Invoice";
  const sampleContent = invoiceData;

  return (
    <Fragment>
      <PDFViewer width="100%" height="600px">
        <Invoice title={sampleTitle} content={sampleContent} />
      </PDFViewer>
    </Fragment>
  );
};
