//////////////////////////////////////////////
//           common for metauser            //
//////////////////////////////////////////////

import log from "../../../services/log";
import * as React from "react";
import { createContext, useContext, useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";

import { Outlet } from "react-router-dom";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import { format } from "date-fns";
import moment from "moment";

import { NavigateUSR } from "./NavigateUSR";
import {
  SubContentStyle,
  ContentStyle,
  colWidthRBarUSR,
  checkListCal,
} from "../../common/ConstantsAndValues";

import { DatePickCal } from "../../common/DatePickCal";
import {
  CauseRSM,
  CalendarContainer,
  WideRSM,
} from "../../common/ConstantsAndValues";

//------------------------------------------------- RH BAR  CONTENTS -------------------------------------------------//
import MessageList from "../../messages/MessageList";
import AppointmentList from "../../appointment/AppointmentList";
import TodoList from "../../appointment/TodoList";
import SBMessages from "../../messages/SBMessages";
import MessageSearch from "../../messages/MessageSearch";

import MessageItemsList_OnlineNow from "../../messages/MessageItemsList_OnlineNow";

import { HealthMonitorConditions } from "../../healthmonitor/HealthMonitorConditions";
import { HealthMonitorConditionsAll } from "../../healthmonitor/HealthMonitorConditionsAll";
import { ListHealthPolicies } from "../../insurance/ListHealthPolicies";
import { BlockAccManager } from "../../common/BlockAccManager";

import { markDatesOrig } from "../../../tests/CalMarkDatesTestData";

export const DoctorContext = createContext();

export function ContainerUSR(props) {
  const { containerType, auth, searchOptions } = props;

  const [provider, setDoctor] = useState("");
  const [dateTogo, setDate] = useState(new Date());
  const [openRSM, setOpenRSM] = useState(false);
  //const [visible,  setVisible]               = useState(true);

  const [page, setPage] = useState("");
  const [navX, setNavX] = useState(
    window.location.pathname
      .slice(
        window.location.pathname.lastIndexOf("/"),
        window.location.pathname.length
      )
      .substring(1)
  );

  const [markDates, setMarkDates] = useState(
    markDatesOrig.map((item) => item.eventdate)
  );
  const [markFilter, setMarkFilter] = useState(checkListCal);
  const [eventMask, setEventMask] = useState([]);

  const [dateFullFlag, setDateFullFlag] = useState(
    markDates.includes(moment(dateTogo).format("YYYY-MM-DD")) ? true : false
  );

  const openEventDateState = (date, flag) => {
    //Called from the child calendar pick
    setDate(date);
    setDateFullFlag(flag);
  };

  const handleEventFilter = (eventmask) => {
    log.info("rightcal", eventmask);
    setEventMask(eventmask);
    // setMarkFilter(filterList);
    // setMarkDates(filterMarkDates());
  };

  function filterMarkDates() {
    //ALL
    if (
      (!markFilter[1].isChecked &&
        !markFilter[2].isChecked &&
        !markFilter[3].isChecked) ||
      markFilter[0].isChecked
    ) {
      return markDatesOrig.map((item) => item.eventdate);
    }
    //Others any combination
    const newItems = markDatesOrig.filter((newVal) => {
      return (
        (markFilter[1].isChecked && newVal.event === markFilter[1]._id) ||
        (markFilter[2].isChecked && newVal.event === markFilter[2]._id) ||
        (markFilter[3].isChecked && newVal.event === markFilter[3]._id)
      );
    });
    return newItems.map((item) => item.eventdate);
  }

  const openEventPage = (page) => {
    //called from HealthMonitorConditions
    setPage(page);
  };

  const openMenuState = (nav) => {
    //called from NavigateUSR
    setNavX(nav);
    setOpenRSM(true);
  };

  const closeMenuState = () => {
    //called from NavigateUSR
    setOpenRSM(false);
  };

  useEffect(() => {
    setOpenRSM(CauseRSM.indexOf(navX) === -1 ? false : true);
    OutletRSM();
  }, [navX]);

  const getVal = (e) => {
    //call from BlockAccountManager
    console.log("cbo  ", e);
  };

  function OutletRSM() {
    const RSMWidth =
      WideRSM.indexOf(containerType.type) === -1
        ? colWidthRBarUSR.width
        : colWidthRBarUSR.extrawide;
    const widn = openRSM ? RSMWidth : "0px";

    return (
      <Grid id="GridRSM" item sx={{ width: { xs: widn, md: widn } }} mr={0}>
        <SubContentStyle>
          <Grid
            ml={2}
            mr={0}
            //pt = {4}
            sx={{ height: "auto", background: "white" }}
            container
            spacing={0}
            direction="column"
          >
            {/* PRIORITY CUSTOMISATION HERE FOR X-VAL OR Y-VAL OR BOTH */}

            {(navX === "cal" || navX === "new") && (
              <Box textAlign="center">
                <CalendarContainer>
                  <DatePickCal
                    openEventDateState={openEventDateState}
                    markDates={markDates}
                    openEventFilterState={setEventMask}
                  />
                </CalendarContainer>
                <br />
              </Box>
            )}

            {/* COMMON RHS MENU CONTENT HERE */}

            {/*<Divider />*/}

            {/* CUSTOMISATION HERE FOR X-VAL ONLY */}

            {navX === "messages" && (
              <Box mb={2}>
                <MessageItemsList_OnlineNow sourcekey={"metauser"} />
                <Divider />
              </Box>
            )}

            {containerType.type === "reports" &&
              (navX === "reports" ||
                navX === "charts" ||
                navX === "analytics") && (
                <Box>
                  <HealthMonitorConditions
                    sourcekey={"metauser"}
                    openEventPage={openEventPage}
                  />
                </Box>
              )}
            {containerType.type === "health-monitor" &&
              navX === "conditions" && (
                <Box>
                  <HealthMonitorConditions
                    sourcekey={"metauser"}
                    openEventPage={openEventPage}
                  />
                </Box>
              )}
            {containerType.type === "health-monitor" && navX === "info" && (
              <Box>
                <HealthMonitorConditionsAll
                  sourcekey={"metauser"}
                  openEventPage={openEventPage}
                />
              </Box>
            )}

            {/* CUSTOMISATION HERE FOR XY-VAL BOTH*/}

            {(navX === "privacy" || navX === "settings") &&
              containerType.type === "setup" && (
                <Box>
                  <BlockAccManager getVal={getVal} />
                </Box>
              )}

            {containerType.type === "insurance" && navX == "info" && (
              <Box>
                <ListHealthPolicies />
              </Box>
            )}

            {containerType.type === "reports" && navX === "analytics" && (
              <Box></Box>
            )}

            {/* CUSTOMISATION HERE FOR Y-VAL ONLY*/}

            {containerType.type === "todo" && <Box>{/*<TodoList/>*/}</Box>}
            {containerType.type === "appointmentsxxxxxxx" && (
              <Box>
                <AppointmentList />
              </Box>
            )}
            {containerType.type === "appointmentsyyyyyyyyyy" && (
              <Box>
                <SBMessages />
              </Box>
            )}
          </Grid>
        </SubContentStyle>
      </Grid>
    );
  }

  //window.addEventListener('scroll', toggleVisible);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "auto",
        }}
      >
        <NavigateUSR
          containerType={containerType}
          openMenuState={openMenuState}
          closeMenuState={closeMenuState}
          dateTogo={dateTogo}
          page={page}
          defaNav={navX}
          setOpenRSM={setOpenRSM}
          openRSM={openRSM}
          searchOptions={searchOptions}
        />
      </Box>

      <Grid
        sx={{ height: "auto", background: "white" }}
        container
        spacing={0}
        direction="column"
      >
        {/* <ContentStyle> */}
        <Grid
          container
          spacing={0}
          sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
        >
          <Grid item xs zeroMinWidth>
            <SubContentStyle>
              <DoctorContext.Provider value={provider}>
                <Outlet
                  context={[
                    markDates,
                    dateTogo,
                    auth,
                    containerType.eventType,
                    page,
                    dateFullFlag,
                  ]}
                />
              </DoctorContext.Provider>
            </SubContentStyle>
          </Grid>

          {/* {openRSM && <OutletRSM />} */}
        </Grid>
        {/* </ContentStyle> */}
      </Grid>
    </div>
  );
}
