import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
} from "@mui/material";
import { getMetaLabs } from "../../redux/labs/labs.actions";
import {
  getPolicies,
  getPolicySubsList,
} from "../../redux/policy/policy.actions";
import { ApprovePolicyListRow } from "./ApprovePolicyListRow";
import { ApprovePolicyListHead } from "./ApprovePolicyListHead";
import { Title } from "../common/Title";
import { pageOptions, SearchBoxStandard } from "../common/ConstantsAndValues";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import { MyButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";

export default function ApprovePolicyList(props) {
  const user = useSelector((state) => state?.auth?.user?.user);
  const org = useSelector((state) => state?.orgs?.selectorg);
  const policylist = useSelector((state) => state?.policies?.subslist);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const table = "Loboratory Tests";
  const dispatch = useDispatch();

  useEffect(() => {
    setRows(policylist);
  }, []);

  const buildQuery = (paged) => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata["providerId"] = org ? org.metadata.accountId : "";
    jsonData["page"] = paged.page;
    jsonData["limit"] = paged.limit;
    jsonData["sortBy"] = "updated_at";
    return jsonData;
  };

  useEffect(() => {
    const jsonQuery = buildQuery(pagedata);
    dispatch(getPolicySubsList(jsonQuery)).then((r) => {
      // log.info("policylist", r)
      if (r.results) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  }, [org, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = policylist.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(policylist);
    }
  };

  const handleChangePage = (event, value) => {
    log.info("libslist", value);
    log.info("lablist", pagedata);

    const newpage = { ...pagedata, page: parseInt(value) };
    const jsonQuery = buildQuery(newpage);
    dispatch(getPolicySubsList(jsonQuery)).then((r) => {
      log.info("labslist", r);
      setRows(r.results);
      setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
    });
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleApprove = () => {
    //todo
  };

  return (
    <Grid container>
      <div className="clear"></div>
      <div
        bgcolor="red"
        width="100%"
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0"></div>

          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Subscriber"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MyButtons
            onClick={handleApprove}
            btnKey={"approve"}
            btnLabel={"Approve"}
            enState={false}
          />
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead className="text-color-primary">
              <ApprovePolicyListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {rows &&
                rows.map((row) => (
                  <ApprovePolicyListRow key={row.labTitle} row={row} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
