////////////////////////////////////////////////////
//                 common for metamd              //
////////////////////////////////////////////////////

import * as React from "react";

import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import TimelineTwoToneIcon from "@mui/icons-material/TimelineTwoTone";
import AddAlertTwoToneIcon from "@mui/icons-material/AddAlertTwoTone";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import CreateNewFolderTwoToneIcon from "@mui/icons-material/CreateNewFolderTwoTone";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import UpgradeTwoToneIcon from "@mui/icons-material/UpgradeTwoTone";
import SwitchAccountTwoToneIcon from "@mui/icons-material/SwitchAccountTwoTone";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import FileOpenTwoToneIcon from "@mui/icons-material/FileOpenTwoTone";
import ViewCozyTwoToneIcon from "@mui/icons-material/ViewCozyTwoTone";
import MedicationTwoToneIcon from "@mui/icons-material/MedicationTwoTone";
import FilterAltTwoToneIcon from "@mui/icons-material/FilterAltTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import PlaylistAddCheckTwoToneIcon from "@mui/icons-material/PlaylistAddCheckTwoTone";
import NoteAddTwoToneIcon from "@mui/icons-material/NoteAddTwoTone";
import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone";

import { ContainerMD, ContainerMDc2 } from "../doctor/components/ContainerMD";
import { ask4Patient } from "./CommonMessages";
import {
  getAge,
  patientSearchMenuOptions,
  commonSearchMenuOptions,
  mdProfileSearchMenuOptions,
  reportsMDSearchMenuOptions,
  drugSearchMenuOptions,
  mimageMDSearchMenuOptions,
  rtmdSearchMenuOptions,
} from "./ConstantsAndValues";

import { useSelector } from "react-redux";
import GroupTwoToneIcon from "@mui/icons-material/GroupTwoTone";
import LocalHospitalTwoToneIcon from "@mui/icons-material/LocalHospitalTwoTone";

export function ProfMD() {
  const containerType = {
    eventType: ["prof"],
    type: "profile",
    label: "My Profile",
    menuList: [
      {
        _id: "mdlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/profile/list",
        more: false,
      },
      {
        _id: "mdanalytics",
        label: "analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd/profile/analytics",
        more: true,
      },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/profile/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/profile/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={mdProfileSearchMenuOptions}
    />
  );
}

export function TodoMD() {
  //2
  const containerType = {
    eventType: ["todo"],
    type: "todo",
    label: "My Tasks",
    menuList: [
      {
        _id: "mdlist",
        label: "Calendar",
        icon: <CalendarMonthTwoToneIcon />,
        link: "/metamd/todo/cal",
        more: false,
      },
      {
        _id: "mdanalytics",
        label: "analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd/todo/analytics",
        more: true,
      },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/todo/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/todo/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ApptMD() {
  ///3
  const containerType = {
    eventType: ["appt"],
    type: "appointments",
    label: "My Appointments",
    menuList: [
      {
        _id: "mdlist",
        label: "Calendar",
        icon: <CalendarMonthTwoToneIcon />,
        link: "/metamd/appointments/cal",
        more: false,
      },
      // { _id: 'mdnew',       label: 'New',         icon: <CreateNewFolderTwoToneIcon />, link: '/metamd/appointments/new',        more: false, },
      {
        _id: "mdanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd/appointments/analytics",
        more: true,
      },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/appointments/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/appointments/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function PatMD() {
  const patientprofile = useSelector((state) => state.user.patientprofile);

  const containerType = {
    eventType: ["patients"],
    type: "patients",
    label: "Patients",
    id: patientprofile ? patientprofile.id : "",

    menuList: [
      {
        _id: "mdlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/patients/list",
        more: false,
      },
      {
        _id: "mdprescribe",
        label: "Prescribe",
        icon: <EditTwoToneIcon />,
        link: "/metamd/patients/prescribe",
        more: false,
      },
      {
        _id: "mdanalytics",
        label: "analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd/patients/analytics",
        more: true,
      },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/patients/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/patients/messages",
        more: true,
      },
      {
        _id: "mdcrpdf",
        label: "PDF",
        icon: <NoteAddTwoToneIcon />,
        link: "/metamd/patients/createpdf",
        more: false,
      },
    ],
  };

  const patientMenuOptions = [
    {
      _id: 550,
      icon: <GroupTwoToneIcon fontSize="small" />,
      title: "My network",
      value: null,
    },
    {
      _id: 551,
      icon: <LocalHospitalTwoToneIcon fontSize="small" />,
      title: "Hospital network",
      value: { departmentId: "metaphar", name: "" },
    },
  ];

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={patientSearchMenuOptions}
    />
  );
}

export function CNotesMD() {
  //5

  const saved = localStorage.getItem("patient");
  const initialValue = JSON.parse(saved);
  const patientprofile = useSelector((state) => state.user.patientprofile);

  const containerType = {
    eventType: ["cn"],
    type: "clinical-notes",
    label: "Clinical Notes",
    id: initialValue ? initialValue.id : "patientInfo",

    menuList: [
      {
        _id: "mdlist",
        label: "Notes",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/notes/notes",
        more: false,
      },
      //{ _id: 'mdanalytics', label: 'analytics',   icon: <TimelineTwoToneIcon />,       link: '/metamd/notes/analytics' ,        more: true, },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/notes/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/notes/messages",
        more: true,
      },
    ],
    comboLabel: "Any more addl combo box",
    comboList: [],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function RepoMD() {
  //6

  const patientprofile = useSelector((state) => state.user.patientprofile);

  const containerType = {
    eventType: ["reports"],
    type: "reports",
    label: "Reports",
    id: "patientRepo",

    menuList: [
      {
        _id: "mdlist",
        label: "Results",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/reports/results",
        more: false,
      },
      {
        _id: "mdreq",
        label: "Requests",
        icon: <PlaylistAddCheckTwoToneIcon />,
        link: "/metamd/reports/requests",
        more: false,
      },
      {
        _id: "mdtests",
        label: "New",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/reports/newtests",
        more: false,
      },
      //{ _id: 'mdanalytics',label: 'analytics',   icon: <TimelineTwoToneIcon />,       link: '/metamd/reports/analytics' ,      more: true, },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/reports/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/reports/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={reportsMDSearchMenuOptions}
    />
  );
}

export function RadioMD() {
  const patientprofile = useSelector((state) => state.user.patientprofile);

  const containerType = {
    eventType: ["rt"],
    type: "rt",
    label: "Radiology Reports",
    id: "patientRadio",

    menuList: [
      {
        _id: "mdreq",
        label: "Requests",
        icon: <PlaylistAddCheckTwoToneIcon />,
        link: "/metamd/rt/requests",
        more: false,
      },
      {
        _id: "mdtests",
        label: "New",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/rt/newtests",
        more: false,
      },
      {
        _id: "mdfile",
        label: "File",
        icon: <FileOpenTwoToneIcon />,
        link: "/metamd/rt/dicom/files",
        more: false,
      },
      {
        _id: "mdview",
        label: "View",
        icon: <ViewCozyTwoToneIcon />,
        link: "/metamd/rt/dicom/view",
        more: false,
      },

      //{ _id: 'mdanalytics',label: 'analytics',   icon: <TimelineTwoToneIcon />,       link: '/metamd/rt/analytics' ,      more: true, },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/rt/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/rt/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={rtmdSearchMenuOptions}
    />
  );
}

export function ImgyMD() {
  //7

  const patientprofile = useSelector((state) => state.user.patientprofile);

  const containerType = {
    type: "medical-images",
    label: "Images",
    id: "patientImgy",

    menuList: [
      {
        _id: "mdfile",
        label: "File",
        icon: <FileOpenTwoToneIcon />,
        link: "/metamd/mimages/dicom/files",
        more: false,
      },
      {
        _id: "mdview",
        label: "View",
        icon: <ViewCozyTwoToneIcon />,
        link: "/metamd/mimages/dicom/view",
        more: false,
      },
      //{ _id: 'mdanalytics', label: 'Analytics',   icon: <TimelineTwoToneIcon />,       link: '/metamd/mimages/analytics',       more: true, },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/mimages/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/mimages/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={mimageMDSearchMenuOptions}
    />
  );
}

export function MediMD() {
  //8

  const patientprofile = useSelector((state) => state.user.patientprofile);

  const containerType = {
    eventType: ["med"],
    type: "medicines",
    label: "Medicines",
    id: "patientMedi",

    menuList: [
      {
        _id: "mdlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/medicine/list",
        more: false,
      },
      {
        _id: "newmed",
        label: "New",
        icon: <MedicationTwoToneIcon />,
        link: "/metamd/medicine/newmed",
        more: false,
      },
      {
        _id: "mdanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd/medicine/analytics",
        more: true,
      },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/medicine/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/medicine/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={drugSearchMenuOptions}
    />
  );
}

export function HMoniMD() {
  ///9

  const patientprofile = useSelector((state) => state.user.patientprofile);

  const containerType = {
    eventType: ["hm"],
    type: "health-monitor",
    label: "Health Monitor",
    id: "patientHM",

    menuList: [
      {
        _id: "mdinfo",
        label: "Info",
        icon: <SwitchAccountTwoToneIcon />,
        link: "/metamd/monitor/info",
        more: false,
      },
      {
        _id: "mdconditions",
        label: "Conditions",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/monitor/conditions",
        more: false,
      },
      {
        _id: "mdanalytics",
        label: "analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd/monitor/analytics",
        more: true,
      },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/monitor/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/monitor/messages",
        more: true,
      },
    ],
    // comboLabel: 'Any more addl combo box',
    comboList: [],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function PCareMD() {
  //10

  const patientprofile = useSelector((state) => state.user.patientprofile);

  const containerType = {
    eventType: ["pc"],
    type: "preventive-care",
    label: "Preventive Care",
    id: "patientPC",

    menuList: [
      {
        _id: "mdlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/care/list",
        more: false,
      },
      {
        _id: "mdanalytics",
        label: "analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd/care/analytics",
        more: true,
      },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/care/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/care/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function QnsMD() {
  //11

  const patientprofile = useSelector((state) => state.user.patientprofile);

  const containerType = {
    eventType: ["qns"],
    type: "questionnaire",
    label: "Questionnaire",
    id: "patientPC",

    menuList: [
      {
        _id: "mdlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/qns/list",
        more: false,
      },
      {
        _id: "mdanalytics",
        label: "analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd/qns/analytics",
        more: true,
      },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/qns/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/qns/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ResoMD() {
  //12
  const containerType = {
    eventType: ["resources"],
    type: "resources",
    label: "My Resources",
    menuList: [
      {
        _id: "mdlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/resources/list",
        more: false,
      },
      {
        _id: "mdanalytics",
        label: "analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd/resources/analytics",
        more: true,
      },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/resources/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/resources/messages",
        more: true,
      },
    ],
  };

  return <ContainerMD containerType={containerType} />;
}

export function PayMD() {
  //13
  const containerType = {
    eventType: ["pmt"],
    type: "payment",
    label: "Payments",
    menuList: [
      {
        _id: "mdlist",
        label: "List",
        icon: <ListAltTwoToneIcon />,
        link: "/metamd/payments/list",
        more: false,
      },
      {
        _id: "mdledger",
        label: "Ledger",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/payments/ledger",
        more: false,
      },
      {
        _id: "mdcrpdf",
        label: "PDF",
        icon: <NoteAddTwoToneIcon />,
        link: "/metamd/payments/createpdf",
        more: false,
      },
      {
        _id: "mdsettings",
        label: "Settings",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metamd/payments/settings",
        more: false,
      },
      {
        _id: "mdanalytics",
        label: "analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd/payments/analytics",
        more: true,
      },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/payments/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/payments/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function InsuMD() {
  //14
  const containerType = {
    eventType: ["insu"],
    type: "insurance",
    label: "Health Insurance",
    menuList: [
      {
        _id: "mdinfo",
        label: "Info",
        icon: <SwitchAccountTwoToneIcon />,
        link: "/metamd/insurance/info",
        more: false,
      },
      {
        _id: "mdalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/insurance/notifications",
        more: true,
      },
      {
        _id: "mdmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/insurance/messages",
        more: true,
      },
    ],
  };

  return <ContainerMD containerType={containerType} />;
}

export function SetMD() {
  //14
  const containerType = {
    eventType: ["setup"],
    type: "setup",
    label: "Settings",
    menuList: [
      {
        _id: "mdlist",
        label: "Accounts",
        icon: <PrivacyTipTwoToneIcon />,
        link: "/metamd/setup/privacy",
        more: false,
      },
      {
        _id: "mdsettings",
        label: "Settings",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metamd/setup/settings",
        more: false,
      },
      {
        _id: "mduploads",
        label: "Uploads",
        icon: <UpgradeTwoToneIcon />,
        link: "/metamd/setup/uploads",
        more: false,
      },
    ],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function MetamdDoThisNowOrig() {
  const containerType = {
    eventType: ["do"],
    type: "do",
    label: "Assign Task",
    menuList: [
      {
        _id: "dmlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metamd/do/list",
        more: false,
      },
      {
        _id: "dmanalytics",
        label: "analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd/do/analytics",
        more: true,
      },
      {
        _id: "dmalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metamd/do/notifications",
        more: true,
      },
      {
        _id: "dmmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metamd/do/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerMD
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}
