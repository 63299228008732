import React from "react";
import HomeMainSection from "./HomeMainSection";
import { useFormik } from "formik";
import log from "../../services/log";
import { subscribeSchema } from "../schema/subscribeSchema";
import { setSubscribe } from "../../redux/subscribe/subscribe.actions";
import { useDispatch } from "react-redux";

export const HomePageSoon = () => {
  const dispatch = useDispatch();
  const subscribeForm = useFormik({
    initialValues: {
      email: null,
      comment: "from main subscribe",
    },
    validationSchema: subscribeSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("subscribe", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData.metadata = {};
      jsonData.metadata.email = values.email;
      jsonData["comment"] = values.comment;

      // log.info("subcribe data: ", jsonData);
      dispatch(setSubscribe(jsonData));
    },
    onReset: (values) => {},
  });

  const handleEmail = (email) => {
    subscribeForm.setFieldValue("email", email);
  };

  const handleSubmit = (email) => {
    subscribeForm.submitForm();
  };

  // log.info("errors: ", subscribeForm?.errors?.email);

  return (
    <div>
      <HomeMainSection
        updateEmail={handleEmail}
        submit={handleSubmit}
        errors={subscribeForm?.errors?.email}
        ack={subscribeForm?.email}
      />
    </div>
  );
};
