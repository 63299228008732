import AboutModal from "./AboutModal.json";
import Buttons from "./Buttons.json";
import CineDialog from "./CineDialog.json";
import Common from "./Common.json";
import DatePicker from "./DatePicker.json";
import Header from "./Header.json";
import UserPreferencesModal from "./UserPreferencesModal.json";
import MeasurementTable from "./MeasurementTable.json";

export default {
  "pt-BR": {
    AboutModal,
    Buttons,
    CineDialog,
    Common,
    DatePicker,
    Header,
    UserPreferencesModal,
    MeasurementTable,
  },
};
