import React, { useEffect, useState } from "react";

export const BlueReloadIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <g clip-path="url(#clip0_1635_15151)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0285 14.6875C18.0313 14.6713 18.0345 14.6551 18.038 14.639C18.0528 14.5703 18.0732 14.5034 18.0986 14.4389C18.2643 14.0186 18.6444 13.7031 19.1038 13.7031C19.6502 13.7031 20.0998 14.1479 20.0294 14.6898C20.0146 14.8039 19.9972 14.9175 19.9773 15.0306C19.9668 15.0902 19.9556 15.1496 19.9437 15.2089C19.6984 16.4318 19.1563 17.5842 18.3553 18.5602C17.2316 19.9295 15.6679 20.8667 13.9306 21.2123C12.1933 21.5578 10.39 21.2903 8.82783 20.4553C7.26567 19.6203 6.04136 18.2695 5.3635 16.6331C4.68565 14.9966 4.5962 13.1757 5.11038 11.4806C5.62457 9.7856 6.71058 8.32128 8.18337 7.33719C9.65617 6.3531 11.4246 5.91013 13.1874 6.08375C14.3646 6.1997 15.4907 6.58584 16.4819 7.20262L15.5444 4.3989C15.3692 3.87512 15.6519 3.30853 16.1757 3.13339C16.6994 2.95824 17.266 3.24087 17.4412 3.76465L19.1035 8.73593C19.1176 8.76714 19.1303 8.79937 19.1414 8.83258C19.3165 9.35636 19.0339 9.92294 18.5101 10.0981L13.43 11.7968C12.9063 11.9719 12.3397 11.6893 12.1645 11.1655C11.9894 10.6417 12.272 10.0752 12.7958 9.90001L15.574 8.97102C14.8064 8.46211 13.9213 8.14436 12.9934 8.05297C11.6863 7.92422 10.3749 8.25271 9.2827 8.98246C8.19055 9.71221 7.38522 10.7981 7.00392 12.055C6.62263 13.312 6.68896 14.6623 7.19163 15.8758C7.69429 17.0894 8.60218 18.0911 9.7606 18.7102C10.919 19.3294 12.2563 19.5278 13.5446 19.2715C14.8329 19.0153 15.9924 18.3203 16.8257 17.3049C17.4485 16.5461 17.8602 15.6435 18.0285 14.6875Z"
          fill="#2A8AA7"
        />
      </g>
      <defs>
        <clipPath id="clip0_1635_15151">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.4375 0.640625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
