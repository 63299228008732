export const timeOptions = {
  daysOfWeek: [
    { id: 0, value: "Sunday" },
    { id: 1, value: "Monday" },
    { id: 2, value: "Tuesday" },
    { id: 3, value: "Wednesday" },
    { id: 4, value: "Thursday" },
    { id: 5, value: "Friday" },
    { id: 6, value: "Saturday" },
  ],

  timeSlots: [
    { id: "time00", value: "00:30" },
    { id: "time01", value: "01:00" },
    { id: "time02", value: "01:30" },
    { id: "time03", value: "02:00" },
    { id: "time04", value: "02:30" },
    { id: "time05", value: "03:00" },
    { id: "time06", value: "03:30" },
    { id: "time07", value: "04:00" },
    { id: "time08", value: "04:30" },
    { id: "value09", value: "05:00" },
    { id: "time10", value: "05:30" },
    { id: "time11", value: "06:00" },
    { id: "time12", value: "06:30" },
    { id: "time13", value: "07:00" },
    { id: "time14", value: "07:30" },
    { id: "time15", value: "08:00" },
    { id: "time16", value: "08:30" },
    { id: "time17", value: "09:00" },
    { id: "time18", value: "09:30" },
    { id: "time19", value: "10:00" },
    { id: "time20", value: "10:30" },
    { id: "time21", value: "11:00" },
    { id: "time22", value: "11:30" },
    { id: "time23", value: "12:00" },
    { id: "time24", value: "12:30" },
    { id: "time25", value: "13:00" },
    { id: "time26", value: "13:30" },
    { id: "time27", value: "14:00" },
    { id: "time28", value: "14:30" },
    { id: "time29", value: "15:00" },
    { id: "time30", value: "15:30" },
    { id: "time31", value: "16:00" },
    { id: "time32", value: "16:30" },
    { id: "time33", value: "17:00" },
    { id: "time34", value: "17:30" },
    { id: "time35", value: "18:00" },
    { id: "time36", value: "18:30" },
    { id: "time37", value: "19:00" },
    { id: "time38", value: "19:30" },
    { id: "time39", value: "20:00" },
    { id: "time40", value: "20:30" },
    { id: "time41", value: "21:00" },
    { id: "time42", value: "21:30" },
    { id: "time43", value: "22:00" },
    { id: "time44", value: "22:30" },
    { id: "time45", value: "23:00" },
    { id: "time46", value: "23:30" },
    { id: "time47", value: "24:00" },
  ],
};
