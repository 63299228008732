import React, { useEffect, useState } from "react";

const AuthorizeSharingIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
    >
      <rect x="4.42969" y="20.7188" width="16.7618" height="2" rx="1" />
      <rect x="4.42969" y="8.71875" width="16.7618" height="2" rx="1" />
      <rect
        x="6.25"
        y="8.71875"
        width="14"
        height="2"
        rx="1"
        transform="rotate(90 6.25 8.71875)"
      />
      <rect
        x="21.1953"
        y="8.71875"
        width="14"
        height="2"
        rx="1"
        transform="rotate(90 21.1953 8.71875)"
      />
      <rect
        x="13.7188"
        y="13.7188"
        width="4"
        height="2"
        rx="1"
        transform="rotate(90 13.7188 13.7188)"
      />
      <path d="M16.9153 8.0948C17.5042 8.15525 18.0478 7.72866 17.9771 7.14092C17.9389 6.8232 17.8659 6.50886 17.7586 6.20266C17.5323 5.55698 17.1585 4.96204 16.6617 4.45678C16.1649 3.95153 15.5564 3.54736 14.8759 3.27073C14.1954 2.99411 13.4583 2.85127 12.7129 2.85156C11.9674 2.85186 11.2305 2.99528 10.5502 3.27245C9.86999 3.54961 9.26182 3.95426 8.76553 4.45991C8.26925 4.96555 7.89603 5.56079 7.67033 6.20665C7.56328 6.513 7.49055 6.82747 7.45268 7.14528C7.38263 7.73301 7.92651 8.15905 8.51525 8.09814C9.12213 8.03535 9.5381 7.46705 9.72123 6.88506C9.7295 6.85878 9.73819 6.83259 9.74731 6.8065C9.88009 6.42654 10.0996 6.07637 10.3916 5.7789C10.6836 5.48143 11.0414 5.24337 11.4415 5.08032C11.8417 4.91727 12.2753 4.83289 12.7138 4.83272C13.1523 4.83254 13.586 4.91658 13.9863 5.07931C14.3866 5.24205 14.7446 5.47982 15.0369 5.77706C15.3291 6.0743 15.549 6.4243 15.6822 6.80415C15.6913 6.83029 15.7001 6.85653 15.7084 6.88286C15.892 7.46463 16.3084 8.0325 16.9153 8.0948Z" />
    </svg>
  );
};
export default AuthorizeSharingIcon;
