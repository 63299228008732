import * as React from "react";
import { useEffect, useState } from "react";
import { InputLabel, FormControl, NativeSelect } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMDSpeciality } from "../../redux/loinc/loinc.actions";
import { mdspecialityquery } from "../utils/elasticqueries";
import log from "../../services/log";

export function MDSpecialitySelect(props) {
  const mdspecialities = useSelector((state) => state?.loinc?.mdspecialities);
  const dispatch = useDispatch();
  const [speciality, setSpeciality] = useState(null);

  const handleChange = (event) => {
    log.info("mdspeciality", event.target.value);
    setSpeciality(event.target.value);
  };

  useEffect(() => {
    dispatch(getMDSpeciality(mdspecialityquery())).then((r) =>
      log.info("mdspecialities", r)
    );
  }, []);

  return (
    <FormControl fullWidth className="text-color-primary" variant="outlined">
      <InputLabel
        sx={{ minWidth: 20 }}
        variant="standard"
        htmlFor="uncontrolled-native"
      >
        MD Specialties
      </InputLabel>
      <NativeSelect
        disableUnderline
        key={"selregion"}
        variant="standard"
        sx={{
          marginTop: "20px",
          height: 28,
          marginRight: 15,
          minWidth: "100%",
          color: "var(--color-highlight)",
          "& .MuiSvgIcon-root": {
            color: "var(--color-highlight)",
          },
        }}
        defaultValue={speciality}
        onChange={handleChange}
        inputProps={{
          name: "region",
          id: "uncontrolled-native",
        }}
      >
        {mdspecialities &&
          mdspecialities.map((g) => (
            <option
              key={g._source["answerId"]}
              value={g._source["displayText"]}
              className="text-color"
              p={2}
            >
              {g._source["displayText"]}
            </option>
          ))}
      </NativeSelect>
    </FormControl>
  );
}
