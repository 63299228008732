import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  TableContainer,
  TableBody,
} from "@mui/material";
import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  Collapse,
} from "@mui/material";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { AreaSelectLoc } from "../../location/AreaSelectLoc";
import { CountrySelectLoc } from "../../location/CountrySelectLoc";
import { SubregionSelectLoc } from "../../location/SubregionSelectLoc";
import { RegionSelectLoc } from "../../location/RegionSelectLoc";
import { searchSubregions } from "../../../redux/actions/locs";
import { regionquery } from "../../utils/elasticqueries";

import { TitleProper } from "../../common/Title";
import { MyIconButtons } from "../../CustomComponents/MyButtons";
import log from "../../../services/log";

export default function OrgRegionSetting(props) {
  const [open, setOpen] = useState(false);
  const usersetting = useSelector((state) => state.auth.profile);

  const label = "My Region Settings";

  const dispatch = useDispatch();
  useEffect(() => {
    // fetch regions, subregions, countries
    dispatch(searchSubregions(regionquery("sub-region", "*")));
  }, []);

  return (
    <Grid container spacing={2} ml={0}>
      <Grid item xs={12}>
        <Box className="colfl">
          <MyIconButtons onClick={() => setOpen(!open)} enState={open} />
        </Box>
        <Box className="colfl">
          <TitleProper>{label}</TitleProper>
        </Box>
        <Box className="clear" />
      </Grid>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid item xs ml={9}>
          <RegionSelectLoc title={"Region"} />
        </Grid>
        <Grid item xs ml={9}>
          <SubregionSelectLoc title={"Sub-Region"} />
        </Grid>
        <Grid item xs ml={9}>
          <CountrySelectLoc title={"Country"} />
        </Grid>
        <Grid item xs ml={9}>
          <AreaSelectLoc title={"Area"} />
        </Grid>
      </Collapse>
    </Grid>
  );
}
