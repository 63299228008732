import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import useStyles from "../CustomComponents/useStyles";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import { ClinicalTestsPresListRow } from "./ClinicalTestsPresListRow";

import { ClinicalTestPresListHead } from "./ClinicalTestPresListHead";
import { selectLabsOrderPresListRow } from "../../redux/labs/labs.actions";
import log from "../../services/log";

export function ClinicalTestsPresList(props) {
  const preslist = useSelector((state) => state?.labs?.labsorder);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });

  const [clinicaltests, setClinicaltests] = useState([]);
  const [checkedRows, setCheckedRows] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    // log.info("clinicaltestlist", clinicaltestlist.results)
    if (preslist) {
      // setClinicaltests(preslist)
    }
  }, [preslist]);

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient CT list", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    setPage(newpagedata);
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };
  const handleRowClick = (e, selectrow) => {
    log.info("clinicaltestlist", e);
    if (selectrow) {
      setCheckedRows(e);
      dispatch(selectLabsOrderPresListRow(e));
    } else {
      setCheckedRows(null);
      dispatch(selectLabsOrderPresListRow(e));
    }
  };

  log.info("clinicaltestlist", preslist);

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead className="">
              <ClinicalTestPresListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {clinicaltests &&
                clinicaltests?.map((row) => (
                  <ClinicalTestsPresListRow
                    key={row?.metadata?.loincNumber}
                    row={row}
                    isRowSelected={checkedRows}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
