//will make combo box reusable
import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { NativeSelect } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

{
  /* REFRENCE FORMAT containerType 

PLEASE DO NOT DELETE THIS

    const containerType = {
        id:       "", 
        label:    "",
        detail:   "",
        age:      "",
        type:    "health-monitor",
        menuList: [
                { _id: 'mdcondition',  label: 'Condition', icon: <TableRowsTwoToneIcon />,     link: '/metamd/patient/monitor'},
                ...
                { _id: 'mdmsg',        label: 'Messages',  icon: <MessageTwoToneIcon />,       link: '/metamd/patient/messages' }
        ],
        comboWidth: '100%',
        comboLabel: 'CONDITION',
        comboList: [
		{ _id: 'ckd',    	label: 'Kidney CKD',    	icon: <TableRowsTwoToneIcon />,    	link: './monitor/ckd'},    //default is ckd
		{ _id: 'dm',    	label: 'Diabetes Mellitus',    	icon: <TimelineTwoToneIcon />,    	link: './monitor/dm'},
		{ _id: 'ms',    	label: 'Multiple Sclerosis',    icon: <AddAlertTwoToneIcon />,    	link: './monitor/ms'},
	],
    } 

*/
}

export function SelectComboNavigate(props) {
  //ok deployed
  const { containerType } = props;
  const [value, setValue] = React.useState("/");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setValue(event.target.value);
    navigate(event.target.value);
  };

  return (
    <Box minWidth={containerType.comboWidth}>
      <FormControl fullWidth className="text-color-primary" variant="outlined">
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          {"Select " + containerType.comboLabel}
        </InputLabel>
        <NativeSelect
          variant="outlined"
          sx={{
            width: "100%",
            marginTop: "10px",
            height: 30,
            marginRight: 15,
            color: "var(--color-highlight)",
            "& .MuiSvgIcon-root": {
              color: "var(--color-highlight)",
            },
          }}
          defaultValue={30}
          onChange={handleChange}
          inputProps={{
            name: "condition",
            id: "uncontrolled-native",
          }}
        >
          <option key="x" value="" className="text-color" p={2}></option>
          {containerType.comboList.map((menu) => (
            <option
              key={menu._id}
              value={menu.link}
              className="text-color"
              p={2}
            >
              {menu.label}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </Box>
  );
}

export function SelectCombo(props) {
  const { containerType, cboHandleChange } = props;

  const [value, setValue] = React.useState("/");

  const handleChange = (event) => {
    setValue(event.target.value);
    cboHandleChange(event.target.value); //Call from parent
  };

  return (
    <Box minWidth={containerType.comboWidth}>
      <FormControl fullWidth className="text-color-primary" variant="outlined">
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          {containerType.comboLabel}
        </InputLabel>
        <NativeSelect
          variant="outlined"
          sx={{
            width: "100%",
            marginTop: "20px",
            height: 40,
            marginRight: 0,
            marginLeft: 0,
            color: "var(--color-highlight)",
            "& .MuiSvgIcon-root": {
              color: "var(--color-highlight)",
            },
          }}
          defaultValue={""}
          onChange={handleChange}
          inputProps={{
            name: "condition",
            id: "uncontrolled-native",
          }}
        >
          <option
            key=""
            value=""
            className="text-color cursor-pointer"
            p={0}
          ></option>
          {containerType.comboList.map((menu) => (
            <option
              key={menu._id}
              value={menu.value}
              className="text-color cursor-pointer"
              p={0}
            >
              {menu.label}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </Box>
  );
}
