/**
 * filter by department
 * @param p
 * @param s
 * @param group
 * @returns {{}}
 */

export const buildDepartmentUserGroupQuery = (p, s, department) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p.page;
  jsonData["limit"] = p.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["orgId"] = department?.metadata?.orgId;
  jsonData.metadata["groupId"] = department?.metadata?.groupId;
  jsonData.metadata["departmentId"] = department?.metadata?.accountId;
  return jsonData;
};

/**
 * filter by group
 * @param p
 * @param s
 * @param department
 * @returns {{}}
 */
export const buildOrgGroupUserGroupQuery = (p, s, group) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p.page;
  jsonData["limit"] = p.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["groupId"] = group?.metadata.groupId;
  return jsonData;
};

/**
 * by group and department
 * @param p
 * @param s
 * @param group
 * @returns {{}}
 */
export const buildDepartmentGroupUserGroupQuery = (p, s, group) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p.page;
  jsonData["limit"] = p.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["groupId"] = group?.metadata.groupId;
  jsonData.metadata["departmentId"] = group?.metadata.departmentId;
  return jsonData;
};
