// import node modules
import * as React from "react";
import { ThemeTabs } from "../../tabs/ThemeTabs";
import { useState } from "react";

import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  FormLabel,
  Checkbox,
  FormControlLabel,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Link,
} from "@mui/material";
import LineAreaChart from "../../graphs/LineAreaChart";

export const LabResultsDetails = () => {
  const data = [75.0, 104.0, 369.0, 300.0, 92.0];
  return (
    <Box>
      <Box sx={{ mx: 1 }}>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "22px",
            color: "#404040",
            fontFamily: "DM SANS",
          }}
        >
          Thyroid stimulating hormone (TSH) - Details
        </Typography>
        {/* <LineAreaChart /> */}
      </Box>
    </Box>
  );
};
function TestResultsDetails() {
  const tabs = [
    { id: "1006", title: "Lab results > Details", notify: true },
    { id: "1003", title: "Lab prescriptions" },
  ];
  return <ThemeTabs tabs={tabs} />;
}
export default TestResultsDetails;
