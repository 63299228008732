import { useState } from "react";
import { useSelector } from "react-redux";

import { RadiologyTestsPanel4ORG } from "../../laboratory/components/RadiologyTestsPanel4ORG";
import { RadiologyMoreTestPanel4ORG } from "../../laboratory/components/RadiologyMoreTestPanel4ORG";

import log from "../../../services/log";

export const RadiologyTestsPanelLAB = (props) => {
  const searchtext = useSelector((state) =>
    state.user.searchtext ? state.user.searchtext : null
  );
  let searchcontext = useSelector((state) =>
    state.user.searchtext ? state.user.searchcontext : null
  );
  const [actionState, setActionState] = useState("");

  // log.info("rtlist searchtext", searchtext);
  // log.info("rtlist searchcontext", searchcontext);
  searchcontext = { ...searchcontext, name: searchtext };

  const handleCancel = () => {
    setActionState("");
  };

  log.info("rtlist searchtext", actionState);

  return (
    <div>
      {actionState === "moretests" ? (
        <RadiologyMoreTestPanel4ORG handleBack={handleCancel} />
      ) : (
        <RadiologyTestsPanel4ORG
          searchText={searchtext && searchcontext}
          setActionState={setActionState}
        />
      )}
    </div>
  );
};
