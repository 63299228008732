export const doseUnits = [
  { abbr: "amt", value: "amount" },
  { abbr: "cc", value: "milliliter (cubic centimeter)" },
  { abbr: "dg", value: "decigram (0.1 gram)" },
  { abbr: "dL", value: "deciliter (0.1 liter)" },
  { abbr: "g", value: "gram" },
  { abbr: "gm", value: "gram" },
  { abbr: "gtt", value: "drop(s)" },
  { abbr: "IU", value: "international unit" },
  { abbr: "mg", value: "milligram (0.001 gram)" },
  { abbr: "mL", value: "milliliter (0.001 liter)" },
  { abbr: "oz", value: "ounce" },
  { abbr: "Ṫ", value: "one" },
  { abbr: "ṪṪ", value: "two" },
  { abbr: "ṪṪṪ", value: "three" },
  { abbr: "ṪṪṪṪ", value: "four" },
  { abbr: "ss", value: "one half" },
  { abbr: "ss", value: "sliding scale (for insulin)" },
  { abbr: "tbsp", value: "tablespoon" },
  { abbr: "tsp", value: "teaspoon" },
  { abbr: "U", value: "unit(s)" },
];

export const doseRoute = [
  { abbr: "aa", value: "affected area" },
  { abbr: "au", value: "each ear or both ears" },
  { abbr: "id", value: "intradermal" },
  { abbr: "im", value: "intramuscular" },
  { abbr: "inf", value: "infusion" },
  { abbr: "in", value: "intranasal" },
  { abbr: "iv", value: "intravenous" },
  { abbr: "ivp", value: "intravenous push" },
  { abbr: "ivpb", value: "intravenous piggyback" },
  { abbr: "od", value: "right eye" },
  { abbr: "os", value: "left eye" },
  { abbr: "po", value: "by mouth" },
  { abbr: "pv", value: "vagina or vaginally" },
  { abbr: "vag", value: "vagina or vaginally" },
  { abbr: "sl", value: "sublingual" },
  { abbr: "sc", value: "subcutaneous" },
  { abbr: "sq", value: "subcutaneous" },
  { abbr: "subq", value: "subcutaneous" },
  { abbr: "subcu", value: "subcutaneous" },
];

export const doseFrequency = [
  { abbr: "ad lib", value: "as desired" },
  { abbr: "alt dieb", value: "every other day" },
  { abbr: "alt hor", value: "alternate hours" },
  { abbr: "alt noct", value: "alternate nights" },
  { abbr: "bid", value: "twice a day" },
  { abbr: "dieb alt", value: "every other day" },
  { abbr: "od", value: "once daily" },
  { abbr: "oh", value: "every hour" },
  { abbr: "om", value: "every morning" },
  { abbr: "on", value: "every night" },
  { abbr: "prn", value: "as required" },
  { abbr: "q1h", value: "every 1 hour" },
  { abbr: "q2h", value: "every 2 hour" },
  { abbr: "q3h", value: "every 3 hour" },
  { abbr: "q4h", value: "every 4 hour" },
  { abbr: "q6h", value: "every 6 hour" },
  { abbr: "qd", value: "once daily" },
  { abbr: "qh", value: "every hour" },
  { abbr: "qid", value: "four times a day" },
  { abbr: "qod", value: "every other day" },
  { abbr: "qp", value: "as much as desired" },
  { abbr: "qs", value: "a sufficient quantity" },
  { abbr: "qv", value: "as much as desired" },
  { abbr: "qhs", value: "at bedtime" },
  { abbr: "tid", value: "three times a day" },
];
