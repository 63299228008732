import extensions from "./extensions";
import preferences from "./preferences";
import servers from "./servers";
import studies from "./studies";
import timepointManager from "./timepointManager";
import viewports from "./viewports";

const dicomreducers = {
  extensions,
  preferences,
  servers,
  studies,
  timepointManager,
  viewports,
};

export default dicomreducers;
