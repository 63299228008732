export const drugHold = [
  { abbr: "shortage", value: "Requested Quantity Not Available" },
  { abbr: "pmt", value: "Payment Source not clear" },
  { abbr: "qns", value: "Drug not understood" },
  { abbr: "unit", value: "Drug in tablet or syrup" },
  { abbr: "na", value: "Drug not available" },
];

export const drugPackage = [
  { abbr: "tab", value: "Tablet" },
  { abbr: "cap", value: "Capsule" },
  { abbr: "strip", value: "Strip of Tablets/Capsules" },
  { abbr: "bottle", value: "Liquid or Syrup in Bottle" },
  { abbr: "tube", value: "Drug/ ointment in Tubes" },
  { abbr: "num", value: "Drug sold in number" },
];
