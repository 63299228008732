//COMMON
import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Container,
  Paper,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  pageOptions,
  tableParam,
  colWidthSign,
  SpanAnchor,
  SearchBoxStandard,
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../../common/ConstantsAndValues";
import { MedTestsTaxOptions } from "../../common/ConstantsAndValues";
import ThreedotAddEditMenu from "../../CustomComponents/ThreedotAddEditMenu";
import { drugotcpricequery, drugsquery } from "../../utils/drugsqueries";
import {
  getDrugotcPrice,
  getDrugs,
  selectDrugList,
} from "../../../redux/drugs/drugs.actions";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import { CTestsTaxLABHead } from "./CTestsTaxLABHead";
import log from "../../../services/log";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export default function RTestsTaxLAB(props) {
  const { searchText } = props;

  const [rows, setRows] = useState([]);
  const [actionState, setActionState] = useState("");
  const [disable, setDisable] = useState(true);
  const [rowId, setRowId] = useState("");
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });

  const CTlist = useSelector((state) => state?.drugs?.drugotcpricelist);

  const classes = useStyles();
  const dispatch = useDispatch();
  let errMsg = "";

  const ref = useRef(null);

  const buildQuery = (searchtext, paged) => {
    const searchquery = searchtext
      ? searchtext.name
        ? drugotcpricequery("name", searchtext.name, paged)
        : drugotcpricequery("name", "a", paged)
      : drugotcpricequery("name", "a", paged);
    return searchquery;
  };

  useEffect(() => {
    // log.info("CT serarch query", searchText);
    const resetpage = { ...pagedata, page: 0 };
    const searchquery = buildQuery(searchText, resetpage);
    dispatch(getDrugotcPrice(searchquery)).then((r) => {
      log.info("CTslist", r);
      if (r.total.value && r.total.value > 0) {
        setPage({
          ...resetpage,
          count: r.total.value,
          pages: r.total.value / pagedata.limit,
        });
      }
    });
  }, [searchText]);

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      // setRows(results);
    } else {
      // setRows(data);
    }
  };

  useEffect(() => {
    if (CTlist) {
      setRows(CTlist.hits);
    }
  }, [CTlist]);

  const handleResetThis = () => window.location.reload(true);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    commonInput(name, index, value);
  };

  const commonInput = (name, index, value) => {
    const list = [...rows];

    if (list.length <= 0) {
      return;
    }

    list[index][name] = value;
    setRows(list); //essential
    setDisable(false);
  };

  const handleChangePage = (event, value) => {
    // // log.info("CTtax", value);
    // // log.info("CTtax", pagedata);
    //const newpagedata = {... pagedata, page: parseInt(value)}
    //const searchquery = buildQuery(searchText, newpagedata)
    // // log.info("CTlist nextpage", searchquery);
    //dispatch(getDrugs(searchquery)).then((r) => {
    //    log.info("CTtax", r)
    //    if (r.total.value && r.total.value > 0) {
    //        setPage(newpagedata);
    //    }
    //});
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  log.info("CT tax", rows);

  return (
    <Grid container>
      {!!errMsg && (
        <div
          className="text-color-error"
          style={{ width: "100%", textAlign: "center" }}
        >
          1{errMsg}
        </div>
      )}
      <div className="clear"></div>

      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0"></div>
          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={filter}
              placeholder="Name"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>

        <div
          style={{
            padding: "8px 0px 0px 16px",
            display: "flex",
            justifyContent: "space-between",
            maxHeight: "36px",
          }}
        >
          {/*<ThreedotAddEditMenu */}
          {/*    setAction = {setActionState} */}
          {/*    title     = {"Manage Group"}*/}
          {/*/>*/}
        </div>
      </div>

      <div className="clear"></div>

      <TableContainer component={Paper} className="list">
        <Table className={classes.table} aria-label="collapsible table">
          <TableHead>
            <CTestsTaxLABHead />
          </TableHead>

          <TableBody>
            {rows.map((row, i) => (
              <StyledTableRow key={row._source.Id} className="text-color lh-35">
                <StyledTableCell className="text-color">
                  <b>{row._source.name}</b>
                </StyledTableCell>
                <StyledTableCell className="">
                  {row._source.packinfo}
                </StyledTableCell>
                <StyledTableCell className="" align={"right"}>
                  {row._source.price}
                </StyledTableCell>
                <StyledTableCell className="" align={"right"}>
                  {row._source.hsncode}
                </StyledTableCell>
                <StyledTableCell className="">
                  {row._source.discountcode}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="clear"></div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
