import DicomFile from "./dragdrop/DicomFile";
import ViewerLocalFileData from "./viewer/connectedComponents/ViewerLocalFileData";

export const DicomFileView = () => {
  return (
    <DicomFile>
      <ViewerLocalFileData />
    </DicomFile>
  );
};
