////////////////////////////////////////////////////
//                 common for metauser            //
////////////////////////////////////////////////////

import * as React from "react";

import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import TimelineTwoToneIcon from "@mui/icons-material/TimelineTwoTone";
import AppsTwoToneIcon from "@mui/icons-material/AppsTwoTone";
import AddAlertTwoToneIcon from "@mui/icons-material/AddAlertTwoTone";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import CreateNewFolderTwoToneIcon from "@mui/icons-material/CreateNewFolderTwoTone";
import AddchartTwoToneIcon from "@mui/icons-material/AddchartTwoTone";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import UpgradeTwoToneIcon from "@mui/icons-material/UpgradeTwoTone";
import SwitchAccountTwoToneIcon from "@mui/icons-material/SwitchAccountTwoTone";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import FileOpenTwoToneIcon from "@mui/icons-material/FileOpenTwoTone";
import ViewCozyTwoToneIcon from "@mui/icons-material/ViewCozyTwoTone";
import DifferenceTwoToneIcon from "@mui/icons-material/DifferenceTwoTone";
import PlaylistAddCheckTwoToneIcon from "@mui/icons-material/PlaylistAddCheckTwoTone";
import NoteAddTwoToneIcon from "@mui/icons-material/NoteAddTwoTone";
import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone";

import {
  ContainerUSR,
  ContainerUSR_MD,
} from "../patient/components/ContainerUSR";
import { ask4Patient } from "./CommonMessages";
import { getAge, commonSearchMenuOptions } from "./ConstantsAndValues";
import {
  AppointmentIcon,
  AuthorizeSharingIcon,
  FindCareIcon,
  HealthInsuranceIcon,
  ImmunizationIcon,
  MedicalRecordsIcon,
  MedicationsIcon,
  MessagesIcon,
  PaymentsIcon,
  TestResultsIcon,
} from "../customIcons/PatientDashboard";

export function ProfUSR() {
  //1
  const containerType = {
    type: "profile",
    label: "My Profile",
    menuList: [
      {
        _id: "usrlist",
        label: "List",
        icon: <AccountBoxTwoToneIcon />,
        link: "/metauser/profile/list",
        more: false,
      },
      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/profile/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/profile/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/profile/messages",
        more: true,
      },

      // new routes
      {
        _id: "usrlist",
        label: "List",
        icon: <AuthorizeSharingIcon />,
        link: "/metauser/profile/authorize-sharing",
        more: false,
      },
      {
        _id: "usrlist",
        label: "List",
        icon: <HealthInsuranceIcon />,
        link: "/metauser/profile/health-insurance",
        more: false,
      },
      {
        _id: "usrlist",
        label: "List",
        icon: <ImmunizationIcon />,
        link: "/metauser/profile/immunizations",
        more: false,
      },
      {
        _id: "usrlist",
        label: "List",
        icon: <MedicalRecordsIcon />,
        link: "/metauser/profile/medical-records",
        more: false,
      },
      {
        _id: "usrlist",
        label: "List",
        icon: <MedicationsIcon />,
        link: "/metauser/profile/medications",
        more: false,
      },
      {
        _id: "usrlist",
        label: "List",
        icon: <MessagesIcon />,
        link: "/metauser/profile/messages",
        more: false,
      },
      {
        _id: "usrlist",
        label: "List",
        icon: <FindCareIcon />,
        link: "/metauser/profile/find-care",
        more: false,
      },
      {
        _id: "usrlist",
        label: "List",
        icon: <PaymentsIcon />,
        link: "/metauser/profile/payments",
        more: false,
      },
      {
        _id: "usrlist",
        label: "List",
        icon: <AppointmentIcon />,
        link: "/metauser/profile/appointments",
        more: false,
      },
      {
        _id: "usrlist",
        label: "List",
        icon: <TestResultsIcon />,
        link: "/metauser/profile/test-results",
        more: false,
      },
      // new routes
    ],
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function TodoUSR(props) {
  const { auth } = props;

  const containerType = {
    eventType: ["todo"],
    type: "todo",
    label: "My To Do List",
    menuList: [
      {
        _id: "usrlist",
        label: "Calendar",
        icon: <CalendarMonthTwoToneIcon />,
        link: "/metauser/todo/cal",
        more: false,
      },
      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/todo/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/todo/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/todo/messages",
        more: true,
      },
    ],
    comboList: [],
  };
  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ApptUSR(props) {
  const { auth } = props;
  const containerType = {
    eventType: ["labs"],
    type: "appointments",
    label: "My Appointments",
    menuList: [
      {
        _id: "usrlist",
        label: "Calendar",
        icon: <CalendarMonthTwoToneIcon />,
        link: "/metauser/appointments/cal",
        more: false,
      },
      {
        _id: "mdnew",
        label: "New",
        icon: <CreateNewFolderTwoToneIcon />,
        link: "/metauser/appointments/new",
        more: false,
      },
      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/appointments/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/appointments/notifications",
        more: true,
      },
      {
        _id: "usermsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/appointments/messages",
        more: true,
      },
    ],
    comboList: [],
  };
  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function CNotesUSR() {
  //5

  const saved = localStorage.getItem("patient");
  const initialValue = JSON.parse(saved);

  const age = initialValue ? getAge(initialValue.dob) : "";

  const containerType = {
    eventType: ["cn"],
    type: "clinical-notes",
    label: "Clinical Notes",
    id: initialValue ? initialValue.id : "patientInfo",
    name: initialValue ? initialValue.name : ask4Patient,
    detail:
      initialValue && initialValue.sex ? initialValue.sex.substr(0, 1) : "",
    age: age,
    menuList: [
      {
        _id: "usrlist",
        label: "Notes",
        icon: <TableRowsTwoToneIcon />,
        link: "/metauser/notes/notes",
        more: false,
      },
      {
        _id: "useranalytics",
        label: "analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/notes/analytics",
        more: true,
      },
      {
        _id: "useralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/notes/notifications",
        more: true,
      },
      {
        _id: "usermsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/notes/messages",
        more: true,
      },
    ],
    comboLabel: "Any more addl combo box",
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function RepoUSR() {
  //4
  const containerType = {
    type: "reports",
    label: "My Laboratory Reports",
    menuList: [
      {
        _id: "usrlist",
        label: "Reports",
        icon: <SummarizeTwoToneIcon />,
        link: "/metauser/reports/reports",
        more: false,
      },
      {
        _id: "labrequests",
        label: "Requests",
        icon: <PlaylistAddCheckTwoToneIcon />,
        link: "/metauser/reports/requests",
        more: false,
      },
      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/reports/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/reports/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/reports/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function RadRepoUSR() {
  //4
  const containerType = {
    type: "radreports",
    label: "My Radiology Reports",
    menuList: [
      {
        _id: "usrradlist",
        label: "Requests",
        icon: <SummarizeTwoToneIcon />,
        link: "/metauser/radreports/requests",
        more: false,
      },

      {
        _id: "usrfile",
        label: "File",
        icon: <FileOpenTwoToneIcon />,
        link: "/metauser/radreports/dicom/files",
        more: false,
      },
      {
        _id: "usrview",
        label: "View",
        icon: <ViewCozyTwoToneIcon />,
        link: "/metauser/radreports/dicom/view",
        more: false,
      },

      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/radreports/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/radreports/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/radreports/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ImgyUSR() {
  //5
  const containerType = {
    type: "medical-images",
    label: "My Investigational Images",
    menuList: [
      {
        _id: "usrfile",
        label: "File",
        icon: <FileOpenTwoToneIcon />,
        link: "/metauser/mimages/dicom/files",
        more: false,
      },
      {
        _id: "usrview",
        label: "View",
        icon: <ViewCozyTwoToneIcon />,
        link: "/metauser/mimages/dicom/view",
        more: false,
      },
      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/mimages/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/mimages/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/mimages/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function MediUSR() {
  //6
  const containerType = {
    type: "medicines",
    label: "My Medicines",
    menuList: [
      {
        _id: "usrlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metauser/medicine/list",
        more: false,
      },
      {
        _id: "usrreq",
        label: "Requests",
        icon: <PlaylistAddCheckTwoToneIcon />,
        link: "/metauser/medicine/requests",
        more: false,
      },
      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/medicine/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/medicine/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/medicine/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function HmoniUSR(props) {
  const { auth } = props;

  const containerType = {
    type: "health-monitor",
    label: "My Health Monitor",
    menuList: [
      {
        _id: "usrinfo",
        label: "Info",
        icon: <SwitchAccountTwoToneIcon />,
        link: "/metauser/monitor/info",
        more: false,
      },
      {
        _id: "usrconditions",
        label: "Conditions",
        icon: <TableRowsTwoToneIcon />,
        link: "/metauser/monitor/conditions",
        more: false,
      },
      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/monitor/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/monitor/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/monitor/messages",
        more: true,
      },
    ],
    comboLabel: "CONDITION",
    comboList: [
      {
        _id: "ckd",
        label: "Kidney CKD",
        icon: <TableRowsTwoToneIcon />,
        link: "/metauser/monitor/ckd",
      },
      {
        _id: "dm",
        label: "Diabetes Mellitus",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/monitor/dm",
      },
      {
        _id: "ms",
        label: "Multiple Sclerosis",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/monitor/ms",
      },
    ],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function MoniUSR() {
  //not used
  const containerType = {
    type: "monitor",
    label: "My Monitor",
    menuList: [
      {
        _id: "usrlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metauser/monitor/list",
        more: false,
      },
      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/monitor/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/monitor/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/monitor/messages",
        more: true,
      },
    ],
    comboList: [],
  };
  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function PcareUSR() {
  //7
  const containerType = {
    type: "care",
    label: "Preventive Care",
    menuList: [
      {
        _id: "usrlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metauser/care/list",
        more: false,
      },
      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/care/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/care/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/care/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function QnsUSR() {
  //8
  const containerType = {
    type: "qns",
    label: "My Questions",
    menuList: [
      {
        _id: "usrlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metauser/qns/list",
        more: false,
      },
      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/qns/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/qns/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/qns/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ResoUSR() {
  //9
  const containerType = {
    type: "resources",
    label: "My Resources",
    menuList: [
      {
        _id: "usrlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metauser/resources/list",
        more: false,
      },
      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/resources/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/resources/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/resources/messages",
        more: true,
      },
      {
        _id: "usrcrpdf",
        label: "PDF",
        icon: <NoteAddTwoToneIcon />,
        link: "/metauser/resources/createpdf",
        more: false,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function PayUSR() {
  //10
  const containerType = {
    type: "payments",
    label: "My Payments",
    menuList: [
      {
        _id: "usrlist",
        label: "List",
        icon: <ListAltTwoToneIcon />,
        link: "/metauser/payments/list",
        more: false,
      },
      {
        _id: "usrledger",
        label: "Ledger",
        icon: <TableRowsTwoToneIcon />,
        link: "/metauser/payments/ledger",
        more: false,
      },
      {
        _id: "usrcrpdf",
        label: "PDF",
        icon: <NoteAddTwoToneIcon />,
        link: "/metauser/payments/createpdf",
        more: false,
      },
      {
        _id: "usrsettings",
        label: "Settings",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metauser/payments/settings",
        more: false,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/payments/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/payments/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function InsuUSR() {
  //14
  const containerType = {
    eventType: ["insu"],
    type: "insurance",
    label: "Health Insurance",
    menuList: [
      {
        _id: "usrinfo",
        label: "Info",
        icon: <SwitchAccountTwoToneIcon />,
        link: "/metauser/insurance/info",
        more: false,
      },
      {
        _id: "usrcompare",
        label: "Compare",
        icon: <DifferenceTwoToneIcon />,
        link: "/metauser/insurance/compare",
        more: false,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/insurance/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/insurance/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function SetUSR() {
  //11
  const containerType = {
    type: "setup",
    label: "My Setup",
    menuList: [
      {
        _id: "usrlist",
        label: "Accounts",
        icon: <PrivacyTipTwoToneIcon />,
        link: "/metauser/setup/privacy",
        more: false,
      },
      {
        _id: "usrsettings",
        label: "Settings",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metauser/setup/settings",
        more: false,
      },
      {
        _id: "usruploads",
        label: "Uploads",
        icon: <UpgradeTwoToneIcon />,
        link: "/metauser/setup/uploads",
        more: false,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/setup/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/setup/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function MetauserDoThisNowOrig() {
  const containerType = {
    type: "do",
    label: "Add a Member",
    menuList: [
      {
        _id: "usrlist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metauser/do/list",
        more: false,
      },
      {
        _id: "usranalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metauser/do/analytics",
        more: true,
      },
      {
        _id: "usralert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metauser/do/notifications",
        more: true,
      },
      {
        _id: "usrmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metauser/do/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerUSR
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}
