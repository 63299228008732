import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Collapse,
} from "@mui/material";
import { getMetaLabs } from "../../redux/labs/labs.actions";
import { Title } from "../common/Title";
import { pageOptions, SearchBoxStandard } from "../common/ConstantsAndValues";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import { getPolicies } from "../../redux/policy/policy.actions";
import { PolicyListRow } from "./PolicyListPatientViewRow";
import { PolicyListHead } from "./PolicyListPatientViewHead";
import { PolicyUpdate } from "./PolicyPatientUpdate"; //to be disabled
import log from "../../services/log";

export function PolicyListPatientView(props) {
  const org = useSelector((state) => state?.orgs?.selectorg);
  const policylist = useSelector((state) => state?.policies?.policylist);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [actionState, setActionState] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (policylist) {
      setRows(policylist);
    }
  }, [policylist]);

  useEffect(() => {
    if (org) {
      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["providerId"] = org?.metadata?.accountId;
      jsonData["page"] = pagedata.page;
      jsonData["limit"] = pagedata.limit;
      jsonData["sortBy"] = "updated_at";
      dispatch(getPolicies(jsonData)).then((r) => {
        // log.info("policylist", r)
        if (r.results) {
          setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
        }
      });
    }
  }, [org, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = policylist.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(policylist);
    }
  };

  const handleChangePage = (event, value) => {
    log.info("libslist", value);
    log.info("lablist", pagedata);
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData["page"] = value + 1;
    jsonData["limit"] = pagedata.limit;
    jsonData["sortBy"] = "updated_at";
    dispatch(getMetaLabs(jsonData)).then((r) => {
      // log.info("labslist", r)
      setRows(r.results);
      setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
    });
    setPage({ ...pagedata, page: parseInt(value) });
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleCancel = () => {
    // log.info("policylist");
    setActionState("");
  };

  const handleEdit = () => {
    // log.info("policylist");
    setActionState("edit");
  };

  const handleSelectRow = (row) => {
    setSelectRow(row);
  };

  log.info("policylist", rows);

  return (
    <Grid container>
      <div className="clear"></div>
      <div
        bgcolor="red"
        width="100%"
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0"></div>

          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Policy"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div></div>
      </div>

      {actionState === "edit" ? (
        <div style={{ width: "100%" }}>
          <PolicyUpdate policy={selectRow} />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <PolicyListHead />
                </TableHead>

                <TableBody className="text-color-primary text text-md">
                  {rows &&
                    rows.map((row) => (
                      <PolicyListRow
                        row={row}
                        selectedrow={selectRow}
                        handleSelectRow={handleSelectRow}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div style={{ width: "100%" }}>
            <TablePaginationStandard
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              pagedata={pagedata}
            />
          </div>
        </div>
      )}
    </Grid>
  );
}
