import ProviderNonList from "./ProviderNonList";
import { Box, Grid } from "@mui/material";
import * as React from "react";
//import ThreedotMsgMember from "./ThreedotMsgMember";
import DotGroupMenu from "./DotGroupMenu";
import { useState } from "react";
import log from "../../services/log";
import OrgNonList from "./OrgNonList";
import OrgGroupNonList from "./OrgGroupNonList";
import OrgDepartmentNonList from "./OrgDepartmentNonList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import PermIdentityTwoToneIcon from "@mui/icons-material/PermIdentityTwoTone";
import ListItemText from "@mui/material/ListItemText";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

export const GroupSelection = (props) => {
  const { actionState } = props;

  const [filtertile, setFilterTitle] = useState("metaorg");
  const [actionFilter, setActionFilter] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [orglist, setOrgList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const handleOpen = (value) => {
    // log.info("groupselection", value)
    setOpenFilter(true);
  };

  // organization (default metalane), organizationGroup (default null), organizationDepartment (default null)
  const handleClose = () => {
    setOpenFilter(false);
  };

  log.info("groupselection", actionFilter);
  log.info("groupselection", openFilter);

  return (
    <Grid>
      <Box className="colfr0" mt={1} ml={1} mr={3} mb={1}>
        <DotGroupMenu
          setActionFilter={setActionFilter}
          setFilterOpen={handleOpen}
        />
      </Box>
      <Box>
        {openFilter && actionFilter === "org" && (
          <OrgNonList
            openEventPage={handleOpen}
            cancelEventPage={handleClose}
            title={"Organization"}
            placeholder={"organizations"}
          />
        )}
        {openFilter && actionFilter === "orgGroup" && (
          <OrgGroupNonList
            openEventPage={handleOpen}
            cancelEventPage={handleClose}
            title={"OrgGroup"}
            placeholder={"groups"}
          />
        )}
        {openFilter && actionFilter === "orgDept" && (
          <OrgDepartmentNonList
            openEventPage={handleOpen}
            cancelEventPage={handleClose}
            title={"OrgDepartment"}
            placeholder={"departments"}
          />
        )}
      </Box>
    </Grid>
  );
};
