import { AppStateActionTypes } from "./appstate.types";

export const setAppMainbarState = (payload) => (dispatch) => {
  return dispatch({
    type: AppStateActionTypes.MBAR_STATE,
    payload: payload,
  });
};

export const setAppLsmState = (payload) => (dispatch) => {
  return dispatch({
    type: AppStateActionTypes.LSM_STATE,
    payload: payload,
  });
};

export const setAppTopnavState = (payload) => (dispatch) => {
  return dispatch({
    type: AppStateActionTypes.TOPNAV_STATE,
    payload: payload,
  });
};

export const setAppSidebarOpenState = (payload) => (dispatch) => {
  return dispatch({
    type: AppStateActionTypes.OPENSIDEBAR,
    payload: payload,
  });
};

export const setAppNavxState = (payload) => (dispatch) => {
  return dispatch({
    type: AppStateActionTypes.NAVX_STATE,
    payload: payload,
  });
};

export const setAppDashUrl = (payload) => (dispatch) => {
  return dispatch({
    type: AppStateActionTypes.DASH_URL,
    payload: payload,
  });
};
