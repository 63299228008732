import React from "react";
import ReactDOM from "react-dom";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, Tabs, Tab, AppBar } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { Title } from "../../common/Title";

import InveListRaw from "./InveListRaw";
import InveListCollection from "./InveListCollection";
//import InveListHold                                               from "./InveListHold";
//import InveListProcess                                            from "./InveListProcess";

import { CTEST_REQUESTS } from "../../../tests/CTEST_Investigations";
//import {CTEST_TAXRATE, CTEST_PRICELIST }                          from "../../../tests/CTEST_PRICELIST";
import { themeTABS, StyledTab } from "../../common/ConstantsAndValues";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={0} mt={2}>
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

export default function ApptMonitorLAB() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [RowsDetail, setRowsDetail] = React.useState([]); //list of drugs in Rows
  const [RowsDetail2Bill, setRowsDetail2Bill] = React.useState([]); //each drug
  const [RowsDetail2Cancel, setRowsDetail2Cancel] = React.useState([]); //each drug
  const [patient, setPatient] = React.useState("");
  const [doctor, setDoctor] = React.useState("");
  const [err, setErr] = React.useState("");

  //status ="" or "fresh" ,  status = "alloted" 2 "hold"  3 "COLLECT" 4 "tests" 5 "done"

  const CTEST_REQUESTS_fresh = CTEST_REQUESTS.filter(
    (itm) => itm.status === "" || itm.status === "fresh"
  );
  const CTEST_REQUESTS_hold = CTEST_REQUESTS.filter(
    (itm) => itm.status === "hold"
  );
  //const CTEST_REQUESTS_complete                               = CTEST_REQUESTS.filter(itm=> itm.status==="done");

  const REQUEST_TAB = 0;
  const COLLECT_TAB = 1;
  //const HOLD_TAB                                                    = 2
  //const COLTN_TAB                                                   = 3
  //const PROCESS_TAB                                                 = 4
  //const REPORT_TAB                                                  = 5

  {
    /*==========================================  STEP 1 ==============================================*/
  }
  const handleRowsDetail = (plist) => {
    //setRowsDetail( plist );  //IMPORTANT !!!

    const [patn, doc, error, newDet] = getPatient(plist, CTEST_REQUESTS_fresh); //RowsDetail populates here

    if (patn) {
      setPatient(patn);
      setDoctor(doc);
      //-----------------TODO ---------------------------//
      //    set
      //    status = "alloted"
      // in RowsDetail
      //-----------------TODO ends ----------------------//

      setRowsDetail(newDet);
      setValue(COLLECT_TAB); //  proceed next

      setErr("");
    } else {
      setValue(REQUEST_TAB); // remain there itself
      setErr(error);
    }
    //console.log(  patn, doc, error, plist, RowsDetail);
  };

  {
    /*==========================================  STEP 3 ==============================================*/
  }
  const handleHoldRowsDetail = (plist) => {
    const [patn, doc, error, newDet] = getPatient(plist, CTEST_REQUESTS_hold); //RowsDetail populates here
    let currDet = newDet;
    if (RowsDetail.length > 0) {
      if (patn === patient) {
        //same patient so concat

        currDet = currDet.concat(RowsDetail); //ADD TO EXISTING
      } else {
        //ASK ?  if overwrite or continue whatever in [PROCESS] first
        //currently OVERWRITE
      }
    } else {
      //ok
    }

    if (patn) {
      setPatient(patn);
      setDoctor(doc);
      //-----------------TODO ---------------------------//
      //    set
      //    status = "alloted"    //from
      // in RowsDetail
      //-----------------TODO ends ----------------------//

      setRowsDetail(currDet);
      setValue(COLLECT_TAB); //  proceed next

      setErr("");
    } else {
      // setValue(COLTN_TAB);                  // remain there itself
      setErr(error);
    }
  };

  {
    /*========================================== LIBRARY GET ==============================================*/
  }
  function getPatient(plist, sourceData) {
    let err = "No Clinical Tests to process";
    let patn = "";
    let doc = "";
    let newDet = [];

    const list2Process = sourceData.filter(
      (itm) =>
        (itm.status === "" ||
          itm.status === "fresh" ||
          itm.status === "hold") &&
        plist.indexOf(itm.id) !== -1
    );

    if (list2Process.length <= 0) {
      return [patn, doc, err, newDet];
    }
    patn = list2Process[0].patient;
    doc = list2Process[0].doctor;
    err = "";
    const otherPatnList = list2Process.filter((itm) => itm.patient !== patn);

    if (otherPatnList.length > 0) {
      patn = "";
      doc = "";
      err = "Clinical Tests of one Patient allowed";
      newDet = [];
    } else {
      newDet = [];

      for (let i = 0; i < list2Process.length; i++) {
        newDet = newDet.concat(list2Process[i].investigation); //newDet.push(list2Process[i].investigation);
      }
    }

    return [patn, doc, err, newDet];
  }

  {
    /*========================================== STEP 3 FINAL ==============================================*/
  }
  const handlePayRows = (plist, holdlist, amt, amtwtax) => {
    setRowsDetail2Bill(plist);

    if (holdlist.length === 0) {
      //-----------------TODO ---------------------------//
      //    set
      //    status = "done"
      //
      //-----------------TODO ends ----------------------//
    } else {
      //pending how to deal part Rows
      //PART HOLD data=== holdlist
    }

    //setValue();  END

    {
      /*  =========================ON BILL SUCCESS - RESET ======================= */
    }
    setRowsDetail([]);
    {
      /*  =========================ON BILL SUCCESS - RESET ======================= */
    }
  };

  {
    /*========================================== STEP 2 HOLD==============================================*/
  }
  const handleHoldRows = (plist) => {
    //-----------------TODO ---------------------------//
    //    set
    //    status = "hold"
    // in RowsDetail
    //-----------------TODO ends ----------------------//

    console.log("to hold", plist);

    //setValue(COLTN_TAB);
  };

  const handleHoldRowsCancel = (plist) => {
    setRowsDetail2Cancel(plist);
    //cancel
    //-----------------TODO ---------------------------//
    //    set
    //    status = "cancel"  or delete
    //
    //-----------------TODO ends ----------------------//

    console.log("Cancel", plist);
  };

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const handlePaymentClose = (status) => {
    if (status) {
      setRowsDetail2Bill([]);
      setRowsDetail([]);
      setPatient("");
    }
  };

  return (
    <div className={classes.root}>
      <ThemeProvider theme={themeTABS}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label=""
            selectionFollowsFocus={true}
          >
            <StyledTab
              label="Requests"
              {...a11yProps(REQUEST_TAB)}
              className="text-color"
              disableFocusRipple={true}
              disableRipple={true}
              sx={{
                boxShadow: "0 !important",
                outline: "none",
                "&:focus": {
                  border: "0 !important",
                  outline: "none",
                  boxShadow: "none",
                },
              }}
            />
            <StyledTab
              label="Collection"
              {...a11yProps(COLLECT_TAB)}
              className="text-color"
              disableFocusRipple={true}
              disableRipple={true}
              sx={{
                boxShadow: "0 !important",
                outline: "none",
                "&:focus": {
                  border: "0 !important",
                  outline: "none",
                  boxShadow: "none",
                },
              }}
            />

            {/*
                        <StyledTab label="Queue"     {...a11yProps(HOLD_TAB)} />
                        <StyledTab label="Collect"  {...a11yProps(COLTN_TAB)} />
                        <StyledTab label="Process"  {...a11yProps(PROCESS_TAB)} />
                        <StyledTab label="Report"   {...a11yProps(REPORT_TAB)} />
                        */}
          </Tabs>
        </AppBar>
      </ThemeProvider>

      <TabPanel value={value} index={REQUEST_TAB}>
        <InveListRaw
          handleRowsDetail={handleRowsDetail}
          data={CTEST_REQUESTS_fresh}
          filterTitle={"Filter"}
          err={err}
        />
      </TabPanel>

      <TabPanel value={value} index={COLLECT_TAB}>
        <InveListCollection
          handlePayRows={handlePayRows}
          handleHoldRows={handleHoldRows}
          data={RowsDetail}
          patient={patient}
          doctor={doctor}
          err={err}
        />
      </TabPanel>

      {/*
            <TabPanel value={value} index={HOLD_TAB}>

                <InveListHold 
                    handleHoldRowsDetail  = {handleHoldRowsDetail}   
                    handleHoldRowsCancel  = {handleHoldRowsCancel}
                    data                  = {CTEST_REQUESTS_hold} 
                    err                   = {err}
                />

            </TabPanel>
            <TabPanel value={value} index={COLTN_TAB}>

            </TabPanel>
            <TabPanel value={value} index={PROCESS_TAB}>

                <InveListProcess 
                    handlePayRows         = {handlePayRows} 
                    handleHoldRows        = {handleHoldRows} 
                    data                  = {RowsDetail}       
                    patient               = {patient}
                    doctor                = {doctor}
                    err                   = {err}
                />

            </TabPanel>
            <TabPanel value={value} index={REPORT_TAB}>

            </TabPanel>
            */}
    </div>
  );
}
