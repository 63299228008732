import React from "react";

// import HomeSection from "../components/home2/components/HomeSection";
import OurTeam from "../components/landingPage/OurTeam";
import ContactUs from "../components/landingPage/ContactUs";
import AboutUs from "../components/landingPage/AboutUs";
import HeroSection from "../components/landingPage/HeroSection";

export const HomePage = (props) => {
  return (
    <>
      <HeroSection />
      <AboutUs />
      <OurTeam />
      <ContactUs />
      {/* <HomeSection /> */}
    </>
  );
};
