import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import log from "../../services/log";
import { setMetalabmap } from "../../redux/loinc/loinc.actions";
import { getLabsList } from "../../redux/labs/labs.actions";
import { labsautocompquery } from "../utils/labsqueries";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { Box } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import {
  getAddressAutocomp,
  getLocAutocomp,
  getLocByplaceAutocomp,
  setNewLocAddress,
} from "../../redux/actions/locs";
import { AutoMSStyles } from "./LocMSStyles";

export default function AddressAutocomplete(props) {
  const { title, defaultValue } = props;
  const country = useSelector((state) => state.locs.country);
  const area = useSelector((state) => state.locs.area);
  const addresses = useSelector((state) => state.locs.addresssearch);

  const [value, setValue] = useState(defaultValue);
  const [inputValue, setInputValue] = useState("a");
  const [options, setOptions] = useState([{}]);
  const [open, setOpen] = useState(false);

  const labslist = useSelector((state) => state.locs.address);

  const loading = open && options.length === 0;
  const dispatch = useDispatch();

  useEffect(() => {
    let jsonData = {};
    let valueset = area
      ? area._source.name + ", " + inputValue
      : "Delhi, " + inputValue;
    jsonData["input"] = valueset;
    jsonData["country"] = [];
    jsonData.country.push(country ? country._source["alpha-2"] : "IN");
    log.info("address autocomplete change", jsonData);
    dispatch(getAddressAutocomp(jsonData));
    // .then((r) => {log.info("address autocomplete", r)});
  }, [inputValue]);

  useEffect(() => {
    if (addresses) {
      const newoptions = [];
      addresses.forEach((u) =>
        newoptions.push({ label: u.description, value: u })
      );
      setOptions(newoptions);
    } else {
      setOptions([]);
    }
  }, [addresses]);

  const handleChange = (event, newValue) => {
    log.info("selected address change", newValue);
    setValue(newValue);
    dispatch(setNewLocAddress(newValue));
  };

  const handleInputValue = (event, newInputValue) => {
    log.info("address autocomplete", newInputValue);
    setInputValue(newInputValue);
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      label={title}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
        ),
      }}
    />
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // log.info("autocomplete", value);

  return (
    <Box mt={2} mb={2}>
      <Autocomplete
        style={AutoMSStyles}
        id="loincnumber"
        value={value}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputValue}
        options={options}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => (option ? option.label : "")}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        renderInput={renderInput}
      />
    </Box>
  );
}
