import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const headBC = "#e1f2f7";
const footBC = "#e1e2f7";
const bline = "#e0e0e0";
const bcolor = "#2a8aa7";

export const stylesLogo = StyleSheet.create({
  viewer: {
    //background: 'red',
  },
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 40,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  headContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px dotted gray",
    marginBottom: 10,
    flexWrap: "wrap",
  },
  logoContainer: {
    flexDirection: "column",
    width: "auto",
  },
  logo: {
    width: 74,
    marginLeft: "0",
    marginRight: "auto",
  },
  addressContainer: {
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingTop: 5,
    flexGrow: 1,
    lineHeight: 1.2,
    marginLeft: 20,
  },
  address: {
    fontFamily: "Helvetica-Oblique",
  },
  orgContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  org: {
    color: bcolor,
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: 500,
  },
  addrContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  addr: {
    fontSize: 9,
    color: "gray",
  },
  connContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  connInnerContainer: {
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  conn: {},
  connDetContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  connDet: {
    color: "gray",
  },
  header: {
    color: bcolor,
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: 500,
    marginTop: 10,
    borderBottom: "1px solid " + bcolor,
  },
  headerNB: {
    color: bcolor,
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: 500,
    marginTop: 10,
    //borderBottom: '1px solid ' + bcolor,
  },
  label: {
    color: "gray",
    width: 60,
  },
  icon: {
    color: "gray",
    width: 12,
    marginRight: 10,
  },
  pageNos: {
    position: "absolute",
    bottom: 20,
    paddingTop: 5,
    left: 60,
    right: 60,
    textAlign: "center",
    borderTop: "1px solid #F0F0F0",
  },
  note: {
    color: "gray",
  },
  paragraph: {
    paddingTop: 5,
    paddingBottom: 5,
    //color: 'gray',
  },
});

export const stylesTitle = StyleSheet.create({
  titleContainer: {
    flexDirection: "column",
    marginTop: 10,
    marginBottom: 0,
  },
  reportTitle: {
    color: bcolor,
    letterSpacing: 4,
    fontSize: 25,
    textAlign: "center",
    textTransform: "uppercase",
  },
  reportTitleSm: {
    color: bcolor,
    letterSpacing: 4,
    fontSize: 14,
    textAlign: "center",
    textTransform: "uppercase",
  },
});

export const stylesSerialNo = StyleSheet.create({
  serialContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  serialInnerContainer: {
    flexDirection: "column",
    justifyContent: "flex-end",
  },

  serialNoContainer: {
    flexDirection: "row",
    marginTop: 6,
    justifyContent: "space-between",
  },
  serialNoDateContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  serialNo: {
    fontSize: 12,
    fontStyle: "bold",
  },
  serialNoDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  serialLabel: {
    color: "gray",
    width: 60,
  },
});

export const stylesAddress = StyleSheet.create({
  headContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px dotted gray",
  },
  clientContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "auto",
  },
  clientLabel: {
    color: "gray",
    marginTop: 2,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  client: {
    //width: 74,
    marginLeft: "0",
    marginRight: "auto",
  },

  addressContainer: {
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingTop: 5,
    flexGrow: 1,
    //lineHeight: 1.2,
    marginLeft: 20,
  },

  address: {
    fontFamily: "Helvetica-Oblique",
  },
  addrContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  addrLabel: {
    color: "gray",
    marginTop: 2,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  name: {
    fontSize: 10,
    color: bcolor,
  },
  desig: {
    fontSize: 9,
    color: "gray",
  },
  addr: {
    fontSize: 9,
    color: "gray",
  },
  connContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  connInnerContainer: {
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  conn: {},
  connDetContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  connDet: {
    color: "gray",
  },
  label: {
    color: "gray",
    width: 60,
  },

  detContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  detaillabel: {
    color: "gray",
    width: 300,
  },
  detail: {},
  icon: {
    color: "gray",
    width: 12,
    marginRight: 10,
  },
});
