import React from "react";
import SignReset from "../components/sign/sign-reset";

import { Gen400Container } from "../components/common/ConstantsAndValues";

export const SignInResetPage = () => (
  <Gen400Container>
    <SignReset />
  </Gen400Container>
);
