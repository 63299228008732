import * as React from "react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
  tableParam,
} from "../common/ConstantsAndValues";
import { MyIconButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";

export function ProviderListApptRow(props) {
  const { row, handleRowClick } = props;
  const selectprovider = useSelector(
    (state) => state.directory.selectproviderlistrow
  );
  const [open, setOpen] = useState(false);
  const [maplist, setMaplist] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  // const dispatch = useDispatch();

  useEffect(() => {
    if (selectprovider && row) {
      const isSelect = selectprovider?._id === row?._id;
      setSelectedRow(isSelect);
    } else {
      setSelectedRow(false);
    }
  }, [selectprovider, row]);

  useEffect(() => {
    if (row) {
      const newmap = [];
      // newmap.push({id: 'instruction', label: "Test Instructions", value: row._source.instructions});
      // newmap.push({id: 'turnaround', label: "Estimated turnaround", value: row._source.turnaround});
      setMaplist(newmap);
    } else {
      setMaplist([]);
    }
  }, [row]);

  const handleClick = () => {
    // log.info("ct row", row);
    setOpen(!open);
  };

  const handleRowClickLocal = (row) => {
    const newstate = !selectedRow;
    setSelectedRow(newstate);
    handleRowClick(row, newstate);
  };

  log.info("providerlistrow", row);

  return (
    <React.Fragment>
      <StyledTableRow
        key={row.id}
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
        onClick={() => handleRowClickLocal(row)}
      >
        <StyledTableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </StyledTableCell>

        <StyledTableCell component="th" scope="row">
          <b>{row._source.name}</b>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant={"body2"}>{row._source.address}</Typography>
        </StyledTableCell>
        {/*<StyledTableCell align="right">{row._source.address}</StyledTableCell>*/}
        {/*<StyledTableCell align="right">{row.updated_at}</StyledTableCell>*/}
      </StyledTableRow>

      <TableRow
        key={"details" + row.id}
        className="text-color-gray bg-standard-offwhite"
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">More Information</div>
              </div>

              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {maplist &&
                      maplist.map((m) => (
                        <TableRow key={m.id}>
                          <TableCell>{m.label}</TableCell>
                          <TableCell>{m.value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
