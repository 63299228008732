import React, { useState, useEffect, useLayoutEffect } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, Container, Stack, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import Avatar from "@mui/material/Avatar";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";

import { Title } from "../../common/Title";
import log from "../../../services/log";

export default function InfoList() {
  const [markDates, dateTest, auth, eventType, page, org] = useOutletContext();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "justified",
  }));

  return (
    <Grid container sx={{ display: "flex", flexDirection: "column" }}>
      <h3 className="text-color-primary">{""}</h3>
      <Box>
        <Title>About:</Title>
      </Box>
      <Box className="text-color-primary">{"..."}</Box>
    </Grid>
  );
}
