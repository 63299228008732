"use strict";
var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
      };
    return __assign.apply(this, arguments);
  };
var __spreadArray =
  (this && this.__spreadArray) ||
  function (to, from, pack) {
    if (pack || arguments.length === 2)
      for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
          if (!ar) ar = Array.prototype.slice.call(from, 0, i);
          ar[i] = from[i];
        }
      }
    return to.concat(ar || Array.prototype.slice.call(from));
  };
exports.__esModule = true;
exports.EVENTS = void 0;
var pubSubServiceInterface_1 = require("../_shared/pubSubServiceInterface");
var sortBy_1 = require("../../utils/sortBy");
var ProtocolEngine_1 = require("./ProtocolEngine");
var EVENTS = {
  STAGE_CHANGE: "event::hanging_protocol_stage_change",
  PROTOCOL_CHANGED: "event::hanging_protocol_changed",
  NEW_LAYOUT: "event::hanging_protocol_new_layout",
  CUSTOM_IMAGE_LOAD_PERFORMED:
    "event::hanging_protocol_custom_image_load_performed",
};
exports.EVENTS = EVENTS;
var HangingProtocolService = (function () {
  function HangingProtocolService(commandsManager, servicesManager) {
    this.hpAlreadyApplied = [];
    this.customViewportSettings = [];
    this.displaySets = [];
    this.customAttributeRetrievalCallbacks = {
      NumberOfStudyRelatedSeries: {
        name: "The number of series in the study",
        callback: function (metadata) {
          var _a, _b;
          return (_a = metadata.NumberOfStudyRelatedSeries) !== null &&
            _a !== void 0
            ? _a
            : (_b = metadata.series) === null || _b === void 0
            ? void 0
            : _b.length;
        },
      },
      NumberOfSeriesRelatedInstances: {
        name: "The number of instances in the display set",
        callback: function (metadata) {
          return metadata.numImageFrames;
        },
      },
      ModalitiesInStudy: {
        name: "Gets the array of the modalities for the series",
        callback: function (metadata) {
          var _a;
          return (_a = metadata.ModalitiesInStudy) !== null && _a !== void 0
            ? _a
            : (metadata.series || []).reduce(function (prev, curr) {
                var Modality = curr.Modality;
                if (Modality && prev.indexOf(Modality) == -1)
                  prev.push(Modality);
                return prev;
              }, []);
        },
      },
    };
    this.listeners = {};
    this.registeredImageLoadStrategies = {};
    this.activeImageLoadStrategyName = null;
    this.customImageLoadPerformed = false;
    /**
     * displaySetMatchDetails = <displaySetId, match>
     * DisplaySetId is the id defined in the hangingProtocol object itself
     * and match is an object that contains information about
     */
    this.displaySetMatchDetails = new Map();
    /**
     * An array that contains for each viewport (viewportIndex) specified in the
     * hanging protocol, an object of the form
     */
    this.viewportMatchDetails = [];
    this._commandsManager = commandsManager;
    this._servicesManager = servicesManager;
    this.protocols = new Map();
    this.protocolEngine = undefined;
    this.protocol = undefined;
    this.stage = undefined;
    this.studies = [];
    Object.defineProperty(this, "EVENTS", {
      value: EVENTS,
      writable: false,
      enumerable: true,
      configurable: false,
    });
    Object.assign(this, pubSubServiceInterface_1["default"]);
  }
  HangingProtocolService.prototype.reset = function () {
    this.studies = [];
    this.protocols = new Map();
    this.hpAlreadyApplied = [];
    this.viewportMatchDetails = [];
    // this.ProtocolEngine.reset()
  };
  HangingProtocolService.prototype.getDefaultProtocol = function () {
    return this.getProtocolById("default");
  };
  HangingProtocolService.prototype.getMatchDetails = function () {
    return {
      viewportMatchDetails: this.viewportMatchDetails,
      displaySetMatchDetails: this.displaySetMatchDetails,
      hpAlreadyApplied: this.hpAlreadyApplied,
    };
  };
  /**
   * It loops over the protocols map object, and checks whether the protocol
   * is a function, if so, it executes it and returns the result as a protocol object
   * otherwise it returns the protocol object itself
   *
   * @returns all the hanging protocol registered in the HangingProtocolService
   */
  HangingProtocolService.prototype.getProtocols = function () {
    // this.protocols is a map of protocols with the protocol id as the key
    // and the protocol or a function that returns a protocol as the value
    var protocols = [];
    var keys = this.activeProtocolIds || this.protocols.keys();
    // @ts-ignore
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
      var protocolId = keys_1[_i];
      var protocol = this.getProtocolById(protocolId);
      if (protocol) {
        protocols.push(protocol);
      }
    }
    return protocols;
  };
  /**
   * Returns the protocol with the given id, it will get the protocol from the
   * protocols map object and if it is a function, it will execute it and return
   * the result as a protocol object
   *
   * @param protocolId - the id of the protocol
   * @returns protocol - the protocol with the given id
   */
  HangingProtocolService.prototype.getProtocolById = function (id) {
    var protocol = this.protocols.get(id);
    if (protocol instanceof Function) {
      try {
        var generatedProtocol =
          this._getProtocolFromGenerator(protocol).protocol;
        return generatedProtocol;
      } catch (error) {
        console.warn(
          "Error while executing protocol generator for protocol "
            .concat(id, ": ")
            .concat(error)
        );
      }
    } else {
      return protocol;
    }
  };
  /**
   * It adds a protocol to the protocols map object. If a protocol with the given
   * id already exists, warn the user and overwrite it.  This can be used to
   * set a new "default" protocol.
   *
   * @param {string} protocolId - The id of the protocol.
   * @param {Protocol} protocol - Protocol - This is the protocol that you want to
   * add to the protocol manager.
   */
  HangingProtocolService.prototype.addProtocol = function (
    protocolId,
    protocol
  ) {
    if (this.protocols.has(protocolId)) {
      console.warn(
        "A protocol with id ".concat(
          protocolId,
          " already exists. It will be overwritten."
        )
      );
    }
    if (!(protocol instanceof Function)) {
      protocol = this._validateProtocol(protocol);
    }
    this.protocols.set(protocolId, protocol);
  };
  /**
   * Add a given protocol object as active.
   * If active protocols ids is null right now, then the specified
   * protocol will become the only active protocol.
   */
  HangingProtocolService.prototype.addActiveProtocol = function (id) {
    if (!id) {
      return;
    }
    if (!this.activeProtocolIds) {
      this.activeProtocolIds = [];
    }
    this.activeProtocolIds.push(id);
  };
  /**
   * Sets the active hanging protocols to use, by name.  If the value is empty,
   * then resets the active protocols to all the named items.
   */
  HangingProtocolService.prototype.setActiveProtocols = function (
    hangingProtocol
  ) {
    if (!hangingProtocol || !hangingProtocol.length) {
      this.activeProtocolIds = null;
      console.log("No active protocols, setting all to active");
      return;
    }
    if (typeof hangingProtocol === "string") {
      this.setActiveProtocols([hangingProtocol]);
      return;
    }
    this.activeProtocolIds = __spreadArray([], hangingProtocol, true);
  };
  /**
   * Run the hanging protocol decisions tree on the active study,
   * studies list and display sets, firing a hanging protocol event when
   * complete to indicate the hanging protocol is ready.
   *
   * @param params is the dataset to run the hanging protocol on.
   * @param params.activeStudy is the "primary" study to hang  This may or may
   *        not be displayed by the actual viewports.
   * @param params.studies is the list of studies to hang
   * @param params.displaySets is the list of display sets associated with
   *        the studies to display in viewports.
   * @param protocol is a specific protocol to apply.
   * @returns
   */
  HangingProtocolService.prototype.run = function (_a, protocolId) {
    var studies = _a.studies,
      displaySets = _a.displaySets,
      activeStudy = _a.activeStudy;
    this.studies = __spreadArray([], studies, true);
    this.displaySets = displaySets;
    this.activeStudy = activeStudy || studies[0];
    this.protocolEngine = new ProtocolEngine_1["default"](
      this.getProtocols(),
      this.customAttributeRetrievalCallbacks
    );
    if (protocolId && typeof protocolId === "string") {
      var protocol = this.getProtocolById(protocolId);
      this._setProtocol(protocol);
      return;
    }
    var matchedProtocol = this.protocolEngine.run({
      studies: this.studies,
      activeStudy: activeStudy,
      displaySets: displaySets,
    });
    this._setProtocol(matchedProtocol);
  };
  /**
   * Returns true, if the hangingProtocol has a custom loading strategy for the images
   * and its callback has been added to the HangingProtocolService
   * @returns {boolean} true
   */
  HangingProtocolService.prototype.hasCustomImageLoadStrategy = function () {
    return (
      this.activeImageLoadStrategyName !== null &&
      this.registeredImageLoadStrategies[
        this.activeImageLoadStrategyName
      ] instanceof Function
    );
  };
  HangingProtocolService.prototype.getCustomImageLoadPerformed = function () {
    return this.customImageLoadPerformed;
  };
  /**
   * Set the strategy callback for loading images to the HangingProtocolService
   * @param {string} name strategy name
   * @param {Function} callback image loader callback
   */
  HangingProtocolService.prototype.registerImageLoadStrategy = function (
    name,
    callback
  ) {
    if (callback instanceof Function && name) {
      this.registeredImageLoadStrategies[name] = callback;
    }
  };
  HangingProtocolService.prototype.setHangingProtocolAppliedForViewport =
    function (i) {
      this.hpAlreadyApplied[i] = true;
    };
  /**
   * Adds a custom attribute to be used in the HangingProtocol UI and matching rules, including a
   * callback that will be used to calculate the attribute value.
   *
   * @param attributeId The ID used to refer to the attribute (e.g. 'timepointType')
   * @param attributeName The name of the attribute to be displayed (e.g. 'Timepoint Type')
   * @param callback The function used to calculate the attribute value from the other attributes at its level (e.g. study/series/image)
   * @param options to add to the "this" object for the custom attribute retriever
   */
  HangingProtocolService.prototype.addCustomAttribute = function (
    attributeId,
    attributeName,
    callback,
    options
  ) {
    if (options === void 0) {
      options = {};
    }
    this.customAttributeRetrievalCallbacks[attributeId] = __assign(
      __assign({}, options),
      { id: attributeId, name: attributeName, callback: callback }
    );
  };
  /**
   * Switches to the next protocol stage in the display set sequence
   */
  HangingProtocolService.prototype.nextProtocolStage = function () {
    console.log("ProtocolEngine::nextProtocolStage");
    if (!this._setCurrentProtocolStage(1)) {
      console.log("ProtocolEngine::nextProtocolStage failed");
    }
  };
  /**
   * Switches to the previous protocol stage in the display set sequence
   */
  HangingProtocolService.prototype.previousProtocolStage = function () {
    console.log("ProtocolEngine::previousProtocolStage");
    if (!this._setCurrentProtocolStage(-1)) {
      console.log("ProtocolEngine::previousProtocolStage failed");
    }
  };
  /**
   * Executes the callback function for the custom loading strategy for the images
   * if no strategy is set, the default strategy is used
   */
  HangingProtocolService.prototype.runImageLoadStrategy = function (data) {
    var loader =
      this.registeredImageLoadStrategies[this.activeImageLoadStrategyName];
    var loadedData = loader({
      data: data,
      displaySetsMatchDetails: this.displaySetMatchDetails,
      viewportMatchDetails: this.viewportMatchDetails,
    });
    // if loader successfully re-arranged the data with the custom strategy
    // and returned the new props, then broadcast them
    if (!loadedData) {
      return;
    }
    this.customImageLoadPerformed = true;
    this._broadcastChange(this.EVENTS.CUSTOM_IMAGE_LOAD_PERFORMED, loadedData);
  };
  HangingProtocolService.prototype._validateProtocol = function (protocol) {
    protocol.id = protocol.id || protocol.name;
    var defaultViewportOptions = {
      toolGroupId: "default",
      viewportType: "stack",
    };
    // Automatically compute some number of attributes if they
    // aren't present.  Makes defining new HPs easier.
    protocol.name = protocol.name || protocol.id;
    var stages = protocol.stages;
    // Generate viewports automatically as required.
    stages.forEach(function (stage) {
      if (!stage.viewports) {
        stage.viewports = [];
        var _a = stage.viewportStructure.properties,
          rows = _a.rows,
          columns = _a.columns;
        for (var i = 0; i < rows * columns; i++) {
          stage.viewports.push({
            viewportOptions: defaultViewportOptions,
            displaySets: [],
          });
        }
      } else {
        stage.viewports.forEach(function (viewport) {
          viewport.viewportOptions =
            viewport.viewportOptions || defaultViewportOptions;
          if (!viewport.displaySets) {
            viewport.displaySets = [];
          } else {
            viewport.displaySets.forEach(function (displaySet) {
              displaySet.options = displaySet.options || {};
            });
          }
        });
      }
    });
    return protocol;
  };
  /**
   * It applied the protocol to the current studies and display sets based on the
   * protocolId that is provided.
   * @param protocolId - name of the protocol to be set
   * @param protocol - protocol object (optional), if not provided, the protocol
   * will be retrieved from the list of protocols by its name
   * @param matchingDisplaySets - predefined display sets to be used for the protocol
   */
  HangingProtocolService.prototype.setProtocol = function (
    protocolId,
    protocol,
    matchingDisplaySets
  ) {
    var _a;
    if (!protocol) {
      var foundProtocol = this.protocols.get(protocolId);
      if (!foundProtocol) {
        console.warn(
          "HangingProtocolService::setProtocol - protocol ".concat(
            protocolId,
            " not found"
          )
        );
        return;
      }
      if (foundProtocol instanceof Function) {
        try {
          console.log("block is commented");
          // mukesh
          // (_a = this._getProtocolFromGenerator(foundProtocol),
          //     protocol = _a.protocol, matchingDisplaySets = _a.matchingDisplaySets);
        } catch (error) {
          console.warn(
            "HangingProtocolService::setProtocol - protocol ".concat(
              protocolId,
              " failed to execute"
            ),
            error
          );
          return;
        }
      } else {
        protocol = foundProtocol;
      }
    }
    this._setProtocol(protocol, matchingDisplaySets);
  };
  HangingProtocolService.prototype._setProtocol = function (
    protocol,
    matchingDisplaySets
  ) {
    this.stage = 0;
    this.protocol = protocol;
    var imageLoadStrategy = protocol.imageLoadStrategy;
    if (imageLoadStrategy) {
      // check if the imageLoadStrategy is a valid strategy
      if (
        this.registeredImageLoadStrategies[imageLoadStrategy] instanceof
        Function
      ) {
        this.activeImageLoadStrategyName = imageLoadStrategy;
      }
    }
    this._updateViewports(matchingDisplaySets);
    this._broadcastChange(this.EVENTS.PROTOCOL_CHANGED, {
      viewportMatchDetails: this.viewportMatchDetails,
      displaySetMatchDetails: this.displaySetMatchDetails,
      hpAlreadyApplied: this.hpAlreadyApplied,
    });
  };
  /**
   * Retrieves the number of Stages in the current Protocol or
   * undefined if no protocol or stages are set
   */
  HangingProtocolService.prototype._getNumProtocolStages = function () {
    if (
      !this.protocol ||
      !this.protocol.stages ||
      !this.protocol.stages.length
    ) {
      return;
    }
    return this.protocol.stages.length;
  };
  /**
   * Retrieves the current Stage from the current Protocol and stage index
   *
   * @returns {*} The Stage model for the currently displayed Stage
   */
  HangingProtocolService.prototype._getCurrentStageModel = function () {
    return this.protocol.stages[this.stage];
  };
  HangingProtocolService.prototype._getProtocolFromGenerator = function (
    protocolGenerator
  ) {
    var _a = protocolGenerator({
        servicesManager: this._servicesManager,
        commandsManager: this._commandsManager,
      }),
      protocol = _a.protocol,
      matchingDisplaySets = _a.matchingDisplaySets;
    var validatedProtocol = this._validateProtocol(protocol);
    return {
      protocol: validatedProtocol,
      matchingDisplaySets: matchingDisplaySets,
    };
  };
  /**
   * Updates the viewports with the selected protocol stage.
   */
  HangingProtocolService.prototype._updateViewports = function (
    matchingDisplaySets
  ) {
    var _this = this;
    // Make sure we have an active protocol with a non-empty array of display sets
    if (!this._getNumProtocolStages()) {
      console.log("No protocol stages - nothing to display");
      return;
    }
    // each time we are updating the viewports, we need to reset the
    // matching applied
    this.hpAlreadyApplied = [];
    // reset displaySetMatchDetails
    this.displaySetMatchDetails = new Map();
    if (matchingDisplaySets) {
      this.displaySetMatchDetails = new Map(
        Object.entries(matchingDisplaySets)
      );
    }
    var _a = this.protocol.displaySetSelectors,
      displaySetSelectors = _a === void 0 ? {} : _a;
    // Retrieve the current stage
    var stageModel = this._getCurrentStageModel();
    // If the current stage does not fulfill the requirements to be displayed,
    // stop here.
    if (
      !stageModel ||
      !stageModel.viewportStructure ||
      !stageModel.viewports ||
      !stageModel.viewports.length
    ) {
      console.log("Stage cannot be applied", stageModel);
      return;
    }
    this.customImageLoadPerformed = false;
    var layoutType = stageModel.viewportStructure.layoutType;
    // Retrieve the properties associated with the current display set's viewport structure template
    // If no such layout properties exist, stop here.
    var layoutProps = stageModel.viewportStructure.properties;
    if (!layoutProps) {
      console.log("No viewportStructure.properties in", stageModel);
      return;
    }
    var numCols = layoutProps.columns,
      numRows = layoutProps.rows,
      _b = layoutProps.layoutOptions,
      layoutOptions = _b === void 0 ? [] : _b;
    this._broadcastChange(this.EVENTS.NEW_LAYOUT, {
      layoutType: layoutType,
      numRows: numRows,
      numCols: numCols,
      layoutOptions: layoutOptions,
    });
    // Matching the displaySets
    for (var _i = 0, _c = stageModel.viewports; _i < _c.length; _i++) {
      var viewport = _c[_i];
      for (var _d = 0, _e = viewport.displaySets; _d < _e.length; _d++) {
        var displaySet = _e[_d];
        var displaySetId = displaySet.id;
        // skip matching if already matched
        if (this.displaySetMatchDetails.has(displaySetId)) {
          continue;
        }
        var displaySetSelector = displaySetSelectors[displaySetId];
        if (!displaySetSelector) {
          console.warn("No display set selector for", displaySetId);
          continue;
        }
        var _f = this._matchImages(displaySetSelector),
          bestMatch = _f.bestMatch,
          matchingScores = _f.matchingScores;
        this.displaySetMatchDetails.set(displaySetId, bestMatch);
        if (bestMatch) {
          bestMatch.matchingScores = matchingScores;
        }
      }
    }
    // Loop through each viewport
    stageModel.viewports.forEach(function (viewport, viewportIndex) {
      var viewportOptions = viewport.viewportOptions;
      _this.hpAlreadyApplied.push(false);
      // DisplaySets for the viewport, Note: this is not the actual displaySet,
      // but it is a info to locate the displaySet from the displaySetService
      var displaySetsInfo = [];
      viewport.displaySets.forEach(function (_a) {
        var id = _a.id,
          _b = _a.displaySetIndex,
          displaySetIndex = _b === void 0 ? 0 : _b,
          displaySetOptions = _a.options;
        var viewportDisplaySetMain = _this.displaySetMatchDetails.get(id);
        // Use the display set index to allow getting the "next" match, eg
        // matching all display sets, and get the displaySetIndex'th item
        var viewportDisplaySet =
          !viewportDisplaySetMain || displaySetIndex === 0
            ? viewportDisplaySetMain
            : viewportDisplaySetMain.matchingScores[displaySetIndex];
        if (viewportDisplaySet) {
          var SeriesInstanceUID = viewportDisplaySet.SeriesInstanceUID,
            displaySetInstanceUID = viewportDisplaySet.displaySetInstanceUID;
          var displaySetInfo = {
            SeriesInstanceUID: SeriesInstanceUID,
            displaySetInstanceUID: displaySetInstanceUID,
            displaySetOptions: displaySetOptions,
          };
          displaySetsInfo.push(displaySetInfo);
        } else {
          console.warn(
            "\n             The hanging protocol viewport is requesting to display ".concat(
              id,
              " displaySet that is not\n             matched based on the provided criteria (e.g. matching rules).\n            "
            )
          );
        }
      });
      _this.viewportMatchDetails[viewportIndex] = {
        viewportOptions: viewportOptions,
        displaySetsInfo: displaySetsInfo,
      };
    });
  };
  // Match images given a list of Studies and a Viewport's image matching reqs
  HangingProtocolService.prototype._matchImages = function (displaySetRules) {
    // TODO: matching is applied on study and series level, instance
    // level matching needs to be added in future
    var _this = this;
    // Todo: handle fusion viewports by not taking the first displaySet rule for the viewport
    var _a = displaySetRules.studyMatchingRules,
      studyMatchingRules = _a === void 0 ? [] : _a,
      seriesMatchingRules = displaySetRules.seriesMatchingRules;
    var matchingScores = [];
    var highestStudyMatchingScore = 0;
    var highestSeriesMatchingScore = 0;
    console.log(
      "ProtocolEngine::matchImages",
      studyMatchingRules,
      seriesMatchingRules
    );
    this.studies.forEach(function (study) {
      var studyDisplaySets = _this.displaySets.filter(function (it) {
        return it.StudyInstanceUID === study.StudyInstanceUID;
      });
      var studyMatchDetails = _this.protocolEngine.findMatch(
        study,
        studyMatchingRules,
        { studies: _this.studies, displaySets: studyDisplaySets }
      );
      // Prevent bestMatch from being updated if the matchDetails' required attribute check has failed
      if (studyMatchDetails.requiredFailed === true) {
        return;
      }
      highestStudyMatchingScore = studyMatchDetails.score;
      _this.debug(
        "study",
        study.StudyInstanceUID,
        "display sets #",
        _this.displaySets.length
      );
      _this.displaySets.forEach(function (displaySet) {
        var _a;
        var StudyInstanceUID = displaySet.StudyInstanceUID,
          SeriesInstanceUID = displaySet.SeriesInstanceUID,
          displaySetInstanceUID = displaySet.displaySetInstanceUID;
        if (StudyInstanceUID !== study.StudyInstanceUID) return;
        var seriesMatchDetails = _this.protocolEngine.findMatch(
          displaySet,
          seriesMatchingRules,
          // Todo: why we have images here since the matching type does not have it
          {
            studies: _this.studies,
            instance:
              (_a = displaySet.images) === null || _a === void 0
                ? void 0
                : _a[0],
          }
        );
        // Prevent bestMatch from being updated if the matchDetails' required attribute check has failed
        if (seriesMatchDetails.requiredFailed === true) {
          _this.debug(
            "Display set required failed",
            displaySet,
            seriesMatchingRules
          );
          return;
        }
        _this.debug("Found displaySet for rules", displaySet);
        highestSeriesMatchingScore = Math.max(
          seriesMatchDetails.score,
          highestSeriesMatchingScore
        );
        var matchDetails = {
          passed: [],
          failed: [],
        };
        matchDetails.passed = matchDetails.passed.concat(
          seriesMatchDetails.details.passed
        );
        matchDetails.passed = matchDetails.passed.concat(
          studyMatchDetails.details.passed
        );
        matchDetails.failed = matchDetails.failed.concat(
          seriesMatchDetails.details.failed
        );
        matchDetails.failed = matchDetails.failed.concat(
          studyMatchDetails.details.failed
        );
        var totalMatchScore =
          seriesMatchDetails.score + studyMatchDetails.score;
        var imageDetails = {
          StudyInstanceUID: StudyInstanceUID,
          SeriesInstanceUID: SeriesInstanceUID,
          displaySetInstanceUID: displaySetInstanceUID,
          matchingScore: totalMatchScore,
          matchDetails: matchDetails,
          sortingInfo: {
            score: totalMatchScore,
            study: study.StudyInstanceUID,
            series: parseInt(displaySet.SeriesNumber),
          },
        };
        _this.debug("Adding display set", displaySet, imageDetails);
        matchingScores.push(imageDetails);
      });
    });
    if (matchingScores.length === 0) {
      console.log("No match found");
    }
    // Sort the matchingScores
    var sortingFunction = (0, sortBy_1["default"])(
      {
        name: "score",
        reverse: true,
      },
      {
        name: "study",
        reverse: true,
      },
      {
        name: "series",
      }
    );
    matchingScores.sort(function (a, b) {
      return sortingFunction(a.sortingInfo, b.sortingInfo);
    });
    var bestMatch = matchingScores[0];
    console.log(
      "ProtocolEngine::matchImages bestMatch",
      bestMatch,
      matchingScores
    );
    return {
      bestMatch: bestMatch,
      matchingScores: matchingScores,
    };
  };
  /**
   * Check if the next stage is available
   * @return {Boolean} True if next stage is available or false otherwise
   */
  HangingProtocolService.prototype._isNextStageAvailable = function () {
    var numberOfStages = this._getNumProtocolStages();
    return this.stage + 1 < numberOfStages;
  };
  /**
   * Check if the previous stage is available
   * @return {Boolean} True if previous stage is available or false otherwise
   */
  HangingProtocolService.prototype._isPreviousStageAvailable = function () {
    return this.stage - 1 >= 0;
  };
  /**
   * Changes the current stage to a new stage index in the display set sequence.
   * It checks if the next stage exists.
   *
   * @param {Integer} stageAction An integer value specifying wheater next (1) or previous (-1) stage
   * @return {Boolean} True if new stage has set or false, otherwise
   */
  HangingProtocolService.prototype._setCurrentProtocolStage = function (
    stageAction
  ) {
    //reseting the applied protocols
    this.hpAlreadyApplied = [];
    // Check if previous or next stage is available
    if (stageAction === -1 && !this._isPreviousStageAvailable()) {
      return false;
    } else if (stageAction === 1 && !this._isNextStageAvailable()) {
      return false;
    }
    // Sets the new stage
    this.stage += stageAction;
    // Log the new stage
    this.debug(
      "ProtocolEngine::setCurrentProtocolStage stage = ".concat(this.stage)
    );
    // Since stage has changed, we need to update the viewports
    // and redo matchings
    this._updateViewports();
    // Everything went well
    this._broadcastChange(this.EVENTS.STAGE_CHANGE, {
      viewportMatchDetails: this.viewportMatchDetails,
      hpAlreadyApplied: this.hpAlreadyApplied,
      displaySetMatchDetails: this.displaySetMatchDetails,
    });
    return true;
  };
  /** Set this.debugLogging to true to show debug level logging - needed
   * to be able to figure out why hanging protocols are or are not applying.
   */
  HangingProtocolService.prototype.debug = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    if (this.debugLogging) {
      console.log.apply(console, args);
    }
  };
  /**
   * Broadcasts hanging protocols changes.
   *
   * @param {string} eventName The event name.add
   * @param {object} eventData.source The measurement source.
   * @param {object} eventData.measurement The measurement.
   * @param {boolean} eventData.notYetUpdatedAtSource True if the measurement was edited
   *      within the measurement service and the source needs to update.
   * @return void
   */
  // Todo: why do we have a separate broadcastChange function here?
  HangingProtocolService.prototype._broadcastChange = function (
    eventName,
    eventData
  ) {
    var hasListeners = Object.keys(this.listeners).length > 0;
    var hasCallbacks = Array.isArray(this.listeners[eventName]);
    if (hasListeners && hasCallbacks) {
      this.listeners[eventName].forEach(function (listener) {
        listener.callback(eventData);
      });
    }
  };
  return HangingProtocolService;
})();

exports["default"] = HangingProtocolService;
