import * as React from "react";
import { StyledTableCell, StyledTableRow } from "../common/ConstantsAndValues";

export const prescriptionTable = {
  title: {
    label: "Title",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  orderby: {
    label: "Approved by",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  completedAt: {
    label: "Recorded on",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
};

export function OtherTestsListHead() {
  const table = prescriptionTable;
  return (
    <React.Fragment>
      <StyledTableRow style={{ fontWeight: 700 }}>
        <StyledTableCell />
        <StyledTableCell>{table.title.label}</StyledTableCell>
        <StyledTableCell align="right">{table.orderby.label}</StyledTableCell>
        <StyledTableCell align="right">
          {table.completedAt.label}
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}
