//////////////////////////////////////////////
//           common for metaorg             //
//////////////////////////////////////////////

import * as React from "react";
import { createContext, useContext, useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";

import { Outlet } from "react-router-dom";
import ReactDOM from "react-dom";
import Divider from "@mui/material/Divider";

import { NavigateLAB } from "./NavigateLAB";
import {
  SubContentStyle,
  ContentStyle,
  colWidthRBarLAB,
  menuListMetaOrg_RS,
  checkListCal,
} from "../../common/ConstantsAndValues";

import { DatePickCal } from "../../common/DatePickCal";
import {
  CauseRSM,
  CalendarContainer,
  WideRSM,
} from "../../common/ConstantsAndValues";

//------------------------------------------------- RH BAR  CONTENTS ---------------------------------------------------//
import DepartmentGroups from "../../department/DepartmentGroups";
import DepartmentList from "../../department/DepartmentList";

import MessageList from "../../messages/MessageList";
import AppointmentList from "../../appointment/AppointmentList";
import TodoList from "../../appointment/TodoList";
import SBMessages from "../../messages/SBMessages";
import MessageSearch from "../../messages/MessageSearch";

import { RightMenu } from "../../common/menuRightBar";

import MessageItemsList_OnlineNow from "../../messages/MessageItemsList_OnlineNow";
import { SelectComboNavigate } from "../../common/SelectCombo";
import { BlockAccManager } from "../../common/BlockAccManager";

import { markDatesOrig } from "../../../tests/CalMarkDatesTestData";

export const OrganizationContext = createContext();

export function ContainerLAB(props) {
  const { containerType, auth, searchOptions } = props;

  const [provider, setPatient] = useState("");
  const [dateTogo, setDate] = useState(new Date());
  const [openRSM, setOpenRSM] = useState(false);
  const [page, setPage] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [navX, setNavX] = useState(
    window.location.pathname
      .slice(
        window.location.pathname.lastIndexOf("/"),
        window.location.pathname.length
      )
      .substring(1)
  );
  const [markDates, setMarkDates] = useState(
    markDatesOrig.map((item) => item.eventdate)
  );
  const [markFilter, setMarkFilter] = useState(checkListCal);

  const org = "H002"; //shall be derive from login credential

  const openEventDateState = (date, flag) => {
    //working   this has date val from the child calendar pick

    setDate(date);
    //setDateFullFlag(flag)    //flag not used
  };

  const openEventFilterState = (filterList) => {
    //Called from child Calendar pick  further from Select checkList
    setMarkFilter(filterList);
    setMarkDates(filterMarkDates());
  };

  function filterMarkDates() {
    //ALL
    if (
      (!markFilter[1].isChecked &&
        !markFilter[2].isChecked &&
        !markFilter[3].isChecked) ||
      markFilter[0].isChecked
    ) {
      return markDatesOrig.map((item) => item.eventdate);
    }
    //Others any combination
    const newItems = markDatesOrig.filter((newVal) => {
      return (
        (markFilter[1].isChecked && newVal.event === markFilter[1]._id) ||
        (markFilter[2].isChecked && newVal.event === markFilter[2]._id) ||
        (markFilter[3].isChecked && newVal.event === markFilter[3]._id)
      );
    });
    return newItems.map((item) => item.eventdate);
  }

  const openEventPage = (page, pagetitle) => {
    //called from DepartmentList /DepartmentGroups
    setPage(page);
    setPageTitle(pagetitle);
  };

  const openMenuState = (nav) => {
    setNavX(nav);
    setOpenRSM(true);
  };

  const closeMenuState = () => {
    setOpenRSM(false);
  };

  useEffect(() => {
    setOpenRSM(CauseRSM.indexOf(navX) === -1 ? false : true);
    OutletRSM();
  }, []);

  const getVal = (e) => {
    console.log("cbo LAB ", e);
  };

  function OutletRSM() {
    const RSMWidth =
      WideRSM.indexOf(containerType.type) === -1
        ? colWidthRBarLAB.width
        : colWidthRBarLAB.extrawide;
    const widn = openRSM ? RSMWidth : "0px";

    return (
      <Grid id="GridRSM" item sx={{ width: { xs: widn, md: widn } }} mr={0}>
        <SubContentStyle>
          <Grid
            ml={2}
            mr={0}
            //pt = {4}
            sx={{
              height: "auto",
              background: "white",
            }} /* //borderLeft: '1px solid var(--bg-color)' */
            container
            spacing={0}
            direction="column"
          >
            {/* PRIORITY CUSTOMISATION HERE FOR X-VAL OR Y-VAL OR BOTH */}

            {navX === "cal" && (
              <Box textAlign="center">
                <CalendarContainer>
                  <DatePickCal
                    openEventDateState={openEventDateState}
                    markDates={markDates} //filtered
                    openEventFilterState={openEventFilterState}
                  />
                </CalendarContainer>

                <br />
              </Box>
            )}

            {/* COMMON RHS MENU CONTENT HERE */}

            {/* CUSTOMISATION HERE 
                        ((containerType.type === "profile") || (containerType.type === "patients") || (containerType.type === "todo") || (containerType.type === "appointments")) && 
                        */}

            {navX === "messages" && (
              <Box mb={2}>
                <MessageItemsList_OnlineNow sourcekey={"metauser"} />
                <Divider />
              </Box>
            )}

            {(containerType.type == "departments" ||
              containerType.type == "specialists") &&
              navX === "info" && (
                <Box>
                  <DepartmentList
                    sourcekey={"metaorg"}
                    openEventPage={openEventPage}
                    org={org}
                  />
                </Box>
              )}

            {(navX === "privacy" || navX === "settings") &&
              containerType.type === "setup" && (
                <Box>
                  <BlockAccManager getVal={getVal} />
                </Box>
              )}
          </Grid>
        </SubContentStyle>
      </Grid>
    );
  }

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "auto",
        }}
      >
        <NavigateLAB
          containerType={containerType}
          openMenuState={openMenuState}
          closeMenuState={closeMenuState}
          date={dateTogo}
          setOpenRSM={setOpenRSM}
          openRSM={openRSM}
          pageTitle={pageTitle}
          searchOptions={searchOptions}
        />
      </Box>

      <Grid
        sx={{ minHeight: "auto", background: "white" }}
        container
        spacing={0}
        direction="column"
      >
        <ContentStyle>
          <Grid
            container
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <Grid item xs zeroMinWidth>
              <SubContentStyle>
                <OrganizationContext.Provider value={provider}>
                  <Outlet
                    context={[
                      markDates,
                      dateTogo,
                      auth,
                      containerType.eventType,
                      page,
                      org,
                      pageTitle,
                    ]}
                  />
                </OrganizationContext.Provider>
              </SubContentStyle>
            </Grid>

            {openRSM && <OutletRSM />}
          </Grid>
        </ContentStyle>
      </Grid>
    </div>
  );
}
