import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import log from "../../../services/log";
//import MDSetting from "./MDSetting";

export default function SettingsMD() {
  return <Grid container></Grid>;
}
