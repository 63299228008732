import * as React from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Box, InputLabel, FormControl } from "@mui/material";

import { LocMSStyles } from "./LocMSStyles";
import { SelectCountry, SelectRegion } from "../../redux/actions/locs";
import log from "../../services/log";

export function RegionSelectLoc(props) {
  const { title } = props;

  const regiondata = useSelector((state) => state.locs.regiondata);
  const region = useSelector((state) => state.locs.region);

  const dispatch = useDispatch();
  const [selOption, setSelOption] = useState(
    region ? { label: region._source.region, value: region } : null
  );
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (regiondata) {
      const regionids = [];
      const noptions = [];
      const rfilter = (id) => {
        if (regionids.includes(id) === false) {
          regionids.push(id);
          return true;
        }
        return false;
      };

      const sregions = regiondata.filter((r) =>
        rfilter(r._source["region-code"])
      );
      sregions.forEach((r) =>
        noptions.push({ label: r._source.region, value: r })
      );
      // log.info("regions", sregions);
      setOptions(noptions);
    }
  }, [regiondata]);

  const handleSelect = (event) => {
    // console.log('selectedregion',event);
    setSelOption(event);
    dispatch(SelectRegion(event.value));
  };

  return (
    <FormControl
      fullWidth
      className="text-color-primary"
      variant="outlined"
      size="small"
    >
      &nbsp;
      <InputLabel
        sx={{ minWidth: 20 }}
        variant="standard"
        htmlFor="uncontrolled-native"
      >
        {title}
      </InputLabel>
      <Box className="text-color">
        <Select
          options={options}
          value={selOption}
          placeholder={"Select " + title}
          onChange={handleSelect}
          isSearchable={true}
          styles={LocMSStyles}
          menuPlacement="auto"
          menuPosition="fixed"
          isMulti={false}
        />
      </Box>
    </FormControl>
  );
}
