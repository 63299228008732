export const AppointmentActionTypes = {
  GET_TEST_TYPES: "GET_TEST_TYPES",
  EVENT_FILTERS: "EVENT_FILTERS",
  APPTSTYPE: "APPTSTYPE",
  APPT_LIST: "APPT_LIST",
  APPT_MLIST: "APPT_MLIST",
  APPT_GLIST: "APPT_GLIST",
  GUESTS_LIST: "GUESTS_LIST",
  APPTDOCSETTING: "APPTDOCSETTING",
  APPTSERVS: "APPTSERVS",
  APPT_GET: "APPT_GET",
  EVENT_DATES: "EVENT_DATES",
  CLIENTAPPT_LIST: "CLIENTAPPT_LIST",
  DATE_SLOTS: "DATE_SLOTS",
  //
  PROVIDER_LIST: "PROVIDER_LIST",
  REASON_LIST: "REASON_LIST",
  APPOINTMENT_LIST: "APPOINTMENT_LIST",
  APPOINTMENT_DATES: "APPOINTMENT_DATES",
  APPOINTMENT_SLOTS: "APPOINTMENT_SLOTS",
};
