import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser, register, verifyEmail } from "../../redux/actions/auth";
import { Box, Link } from "@material-ui/core";
import { MyFullButtonsColor } from "../CustomComponents/MyButtons";
import Password from "../CustomComponents/Password";
import InputComponent from "../CustomComponents/InputComponent";
import InputActivityRole from "../CustomComponents/InputActivityRole";
import log from "../../services/log";
import { validateEmail } from "../common/validateComponents";
import { getSixDigitRandom } from "../utils/random";
import { buildUserEmailQuery } from "../dbcq/userdb";

export default function LoginReset(props) {
  const auth = useSelector((store) => store.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [values, setValues] = useState({
    loginNameError: false,
    emailError: true,
    error: false,
  });

  // const handleClickShowPassword = (e) => {
  //     setValues({
  //         ...values,
  //         showPassword: !values.showPassword,
  //     });
  // };
  //
  // const handleLoginNameChange = (event) => {
  //     setValues({
  //         ...values,
  //         loginName:          event.target.value,
  //         loginNameError:     event.target.value ? false : true,
  //         error:              event.target.value ? false : true,
  //     });
  // };

  const handleEmailChange = (event) => {
    log.info("input email:  ", event.target.value);
    const ev = event.target.value;
    const em = !validateEmail(ev);

    setValues({
      ...values,
      email: ev,
      emailError: em,
      error: em,
    });
  };

  const handleSubmit = (event) => {
    // console.log("clicked");
    event.preventDefault();
    if (values?.emailError == false) {
      const resetpage = { ...pagedata, page: 0 };
      const jsonData = buildUserEmailQuery(resetpage, searchcontext, values);
      log.info("user query: ", values);

      dispatch(getUser(jsonData))
        .then(
          (r) => {
            console.log("reset", r.results);
            if (r.results.length == 1) {
              const newcode = getSixDigitRandom();
              r.results.map((u) => {
                dispatch(
                  verifyEmail(
                    {
                      code: newcode,
                      user: {
                        email: u?.metadata?.email,
                        name: u?.name,
                      },
                    },
                    {
                      code: newcode,
                      user: u,
                      action: "passwordupdate",
                    }
                  )
                );
              });
              navigate("/signcode");
            } else {
              setValues({
                ...values,
                emailError: true,
                emailErrorText: "account associated with email is not found",
              });
            }
          },
          (error) => console.log(error)
        )
        .catch((err) => console.log(err));
    }
  };

  const emailerrortext = (
    <i>
      Provide valid e-Mail Address. Temporary password will be send to this ID
    </i>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          marginBottom: "0px !important",
        }}
      >
        <h3 className="text-color-primary">Reset Password</h3>

        {/*<InputComponent*/}
        {/*    id="loginName"*/}
        {/*    name="loginName"*/}
        {/*    label="Legal Name"*/}
        {/*    errors={values.loginNameError}*/}
        {/*    errorText = {values.loginErrorText}*/}
        {/*    variant="standard"*/}
        {/*    handleChange={handleLoginNameChange}*/}
        {/*/>*/}

        <InputComponent
          id="email"
          name="email"
          label="Email"
          errors={values.emailError}
          errorText={values.emailErrorText}
          variant="standard"
          handleChange={handleEmailChange}
        />

        <Box className="clear">&nbsp; </Box>

        <span className="text-color-primary" sx={{ marginBottom: "16px" }}>
          Reset code will be sent to registered email.
        </span>

        <Box className="clear">&nbsp; </Box>

        <MyFullButtonsColor
          onClick={handleSubmit}
          btnKey={"x"}
          btnLabel={"Proceed"}
          enState={false}
        />

        <Box className="clear">&nbsp; </Box>

        <Box
          className="clear text-color-read"
          sx={{
            padding: "16px",
            margin: "16px 0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Box className="clear">&nbsp; </Box>
          <center>
            Already have an account?
            <Link href="/sign" underline="always" className="text-color">
              SIGN IN
            </Link>
          </center>
          <Box className="clear">&nbsp; </Box>
          <center>
            Need an account?
            <Link href="/signup" underline="always" className="text-color">
              Register
            </Link>
          </center>
          <Box className="clear">&nbsp; </Box>
        </Box>
      </Box>
    </>
  );
}
