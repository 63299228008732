import { useState } from "react";
import { useSelector } from "react-redux";

import { ClinicalMoreTestPanel } from "./ClinicalMoreTestPanel"; //COMMON
import { ClinicalTestsPanel } from "./ClinicalTestsPanel"; //COMMON

import log from "../../../services/log";

export const ClinicalTestsPanelLAB = () => {
  const searchtext = useSelector((state) =>
    state.user.searchtext ? state.user.searchtext : null
  );
  let searchcontext = useSelector((state) =>
    state.user.searchtext ? state.user.searchcontext : null
  );
  const [actionState, setActionState] = useState("");

  searchcontext = { ...searchcontext, name: searchtext };

  const handleCancel = () => {
    setActionState("");
  };

  log.info("ctlist searchtext", actionState);

  return (
    <div>
      {actionState === "moretests" ? (
        <ClinicalMoreTestPanel handleBack={handleCancel} />
      ) : (
        <ClinicalTestsPanel
          searchText={searchtext && searchcontext}
          setActionState={setActionState}
        />
      )}
    </div>
  );
};
