import {
  getAlertService,
  getAlertsService,
  getAlertListService,
} from "../../services/alerts-service";

import { ALERTS, LIST_ALERTS, LIST_MESSAGES } from "./types";

export const getAlertList = (payload) => (dispatch) => {
  return getAlertListService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getAlerts = (payload) => (dispatch) => {
  return getAlertsService(payload).then(
    (response) => {
      dispatch({
        type: LIST_ALERTS,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getAlert = (payload) => (dispatch) => {
  return getAlertService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};
