import React, { useEffect, useState } from "react";

const LastPageIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Forward-right">
        <path
          id="Vector 9"
          d="M4.64844 8.21875L12.375 12.6172L4.64844 16.7923L4.64844 8.21875Z"
          fill="#585858"
          stroke="#585858"
          strokeLinejoin="round"
        />
        <path
          id="Vector 10"
          d="M11.6172 8.21875L19.3438 12.6172L11.6172 16.7923V8.21875Z"
          fill="#585858"
          stroke="#585858"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export default LastPageIcon;
