import Buttons from "./Buttons.json";
import Common from "./Common.json";
import Header from "./Header.json";

export default {
  nl: {
    Buttons,
    Common,
    Header,
  },
};
