import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import QueryPatientPolicies from "../../insurance/QueryPatientPolicies";
import { POLICY_DATA } from "../../../tests/POLICY_DATA";

export default function PatientListManageORG() {
  const data = POLICY_DATA;

  const filterTitle = "Medical Policy";
  const err = "";

  const handleRowsDetail = (policy) => {
    console.log("Approve this ", policy);
  };

  return (
    <Grid container>
      <QueryPatientPolicies
        handleRowsDetail={handleRowsDetail}
        data={data}
        filterTitle={filterTitle}
        err={err}
      />
    </Grid>
  );
}
