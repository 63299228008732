import { ThemeTabs } from "../../tabs/ThemeTabs";

function Immunizations() {
  const tabs = [];
  return (
    <>
      <ThemeTabs tabs={tabs} />
    </>
  );
}
export default Immunizations;
