import { QnsActionTypes } from "./qns.types";

export const initQnslist = (payload) => (dispatch) => {
  return dispatch({
    type: QnsActionTypes.QNSLIST,
    payload: payload,
  });
};

// export const getQnsList = (payload) => (dispatch) => {
//     return qnsListService(payload).then(
//         (response) => {
//             dispatch({
//                 type: QnsActionTypes.QNSLIST,
//                 payload: response.data.results
//             });
//             return Promise.resolve(response.data);
//         },
//         (error) => {
//             const message =
//                 (error.response &&
//                     error.response.data &&
//                     error.response.data.message) ||
//                 error.message ||
//                 error.toString();
//             dispatch({
//                 type: resourceActionTypes.RESOURCE_LIST,
//                 payload: null
//             });
//             return Promise.reject();
//         }
//     );
// };
