import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const headBC = "#e1f2f7";
const footBC = "#e1e2f7";
const bline = "#e0e0e0";
const bcolor = "#2a8aa7";

export const stylesTable = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 8,
    borderWidth: 1,
    borderColor: bline,
    flexWrap: "wrap",
  },
});

export const stylesTableHead = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: bline,
    backgroundColor: headBC,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 22,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    width: "60%",
    borderRightColor: headBC,
    borderRightWidth: 1,
  },
  qty: {
    width: "10%",
    borderRightColor: headBC,
    borderRightWidth: 1,
  },
  rate: {
    width: "15%",
    borderRightColor: headBC,
    borderRightWidth: 1,
  },
  amount: {
    width: "15%",
  },
});

export const stylesTableRow = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: bline,
    borderBottomWidth: 1,
    alignItems: "center",
    //height: 24,
    fontStyle: "bold",
    flexWrap: "wrap",
  },
  description: {
    width: "60%",
    textAlign: "left",
    borderRightColor: bline,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 8,
  },
  qty: {
    width: "10%",
    borderRightColor: bline,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  rate: {
    width: "15%",
    borderRightColor: bline,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  amount: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});

export const stylesTableFooter = StyleSheet.create({
  row: {
    flexDirection: "row",
    backgroundColor: footBC,
    borderBottomColor: bline,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 20,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderRightColor: footBC,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});

export const stylesSign = StyleSheet.create({
  declareContainer: {
    color: "gray",
    flexDirection: "row",
    marginTop: 4,
    justifyContent: "flex-end",
  },
  declare: {
    color: "gray",
    letterSpacing: 4,
    textTransform: "uppercase",
  },
  signContainer: {
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "flex-end",
  },
  sign: {
    color: bcolor,
    paddingTop: 0,
    paddingBottom: 20,
    marginBottom: 20,
    letterSpacing: 1,
  },
});
