import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { useFormik } from "formik";
import { locDocIndex } from "../../redux/actions/locs";
import { locIndexQueries } from "../utils/locationmap";
import { colChart } from "../common/ConstantsAndValues";
import { Grid, Box, TextField } from "@mui/material";
import { Title } from "../common/Title";
import { specialistIndexSchema } from "../schema/specialistSchema";
import log from "../../services/log";
import { MyTooltipButtons, MyHelpButtons } from "../CustomComponents/MyButtons";

export const SpecialistIndex = (props) => {
  const { handleCancel, title } = props;

  const department = useSelector((state) => state.orgs.selectdepartment);
  const orgroup = useSelector((state) => state.orgs.selectorggroup);
  const location = useSelector((state) => state.addresses.selectaddress);
  const mduser = useSelector((state) => state.user.orgspecialist);

  const specialistindexform = useFormik({
    initialValues: {
      departmentId: department ? department.metadata.accountId : "",
      providerId: mduser ? mduser.metadata.accountId : null,
      name: mduser ? mduser.name : null,
      role: mduser ? mduser.metadata.providerRole : null,
      address: location ? location.formatted_address : null,
      services: department ? department.departmentDescp : null,
      location: location ? location.location : null,
    },
    validationSchema: specialistIndexSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("specialistindex", JSON.stringify(values, null, 2));

      const data = {};
      data.providerId = values.providerId;
      data.departmentId = values.departmentId;
      data.name = values.name;
      data.role = values.role;
      data.location = values.location;
      data.address = values.address;
      data.services = values.services;

      // log.info("department info", locIndexQueries(data));

      dispatch(locDocIndex(locIndexQueries(data)));
    },
    onReset: (values) => {},
  });

  const dispatch = useDispatch();

  const MSStyles = {
    control: (styles) => ({ ...styles }),
    container: (styles) => ({ ...styles, width: colChart.width }),
    option: (styles) => ({ ...styles }),
    // menu:      styles => ({ ...styles,  width: colChart.width }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: colChart.width,
    }),
  };

  // log.info("specialist index", specialistindexform.errors);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%", md: "60%", lg: "50%" },
        }}
      >
        <Box mb={1}>
          <div className="colfl0">
            <Title>{title ? title : ""} </Title>
          </div>
          <div className="colfr0">
            <MyTooltipButtons
              onClick={handleCancel}
              btnKey={"cancel"}
              btnLabel={"Cancel"}
              enState={false}
            />
          </div>
        </Box>
        <Box className="clear"> </Box>
        <Box className="text-color">
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Specialist"
            defaultValue={specialistindexform.values.name}
            error={specialistindexform.errors.name ? true : false}
            fullWidth
            variant="standard"
          />
        </Box>

        <Box className="clear" />
        <Box mt={2} pt={2} className="border-dbt">
          <Box className="colfr0">
            <MyHelpButtons
              onClick={specialistindexform.handleSubmit}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
              btnTooltip={"Add"}
            />
          </Box>
          <Box className="clear" />
        </Box>
        <Box className="clear" />
      </Grid>
    </Grid>
  );
};
