import { SubscribeActionTypes } from "./subscribe.types";
const INITIAL_STATE = {
  subscribeslist: null,
};

const subscribeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SubscribeActionTypes.SUBSCRIBE:
      return {
        ...state,
        subscribeslist: action.payload,
      };
    default:
      return state;
  }
};

export default subscribeReducer;
