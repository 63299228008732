// import axios from "axios";
import { API_URL } from "./Constants";
import { axiosApiInstance } from "./axiosmiddleware";

const getReportDataService = (payload) => {
  return axiosApiInstance.post(API_URL + "labsdata/list", payload);
};

const getLabsService = (payload) => {
  return axiosApiInstance.post(API_URL + "labs/list", payload);
};

const getLabsMapService = (payload) => {
  return axiosApiInstance.post(API_URL + "labsmap/list", payload);
};

const getLabGroupNodeService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "labgroup/node", payload);
};

const getMedicineService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "medicines/list", payload);
};

const postMedicineService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "medicines/set", payload);
};

const setLabOrderService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "labsorder/set", payload);
};

const getLabOrderService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "labsorder/list", payload);
};

const getRadOrderService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "radorder/list", payload);
};

const setRadOrderService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "radorder/set", payload);
};

export {
  getReportDataService,
  getLabsService,
  getLabGroupNodeService,
  getMedicineService,
  postMedicineService,
  getLabsMapService,
  getLabOrderService,
  setLabOrderService,
  getRadOrderService,
  setRadOrderService,
};
