import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import InputComponent from "../CustomComponents/InputComponent";
import { colChart } from "../common/ConstantsAndValues";
import { setOrgGroup } from "../../redux/actions/orgs";
import { useFormik } from "formik";
import { orgGroupSchema } from "../schema/orgInputSchema";
import { Title } from "../common/Title";
import { MyTooltipButtons, MyHelpButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";

const GroupInput = (props) => {
  const { cancelCallback, title } = props;
  const user = useSelector((state) => state?.auth?.user?.user);
  const org = useSelector((state) => state?.orgs?.selectorg);

  const dispatch = useDispatch();
  const [values, setValues] = useState({
    nameError: false,
    despError: false,
  });

  const groupform = useFormik({
    initialValues: {
      id: "",
      userId: null,
      orgId: null,
      email: null,
      groupName: null,
      groupDescp: null,
      status: "active",
    },
    validationSchema: orgGroupSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("orginput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData.metadata = {};
      jsonData.metadata.orgId = values.orgId;
      jsonData.metadata.email = values.email;
      jsonData.metadata.userId = values.userId;
      jsonData.groupName = values.groupName;
      jsonData.groupDescp = values.groupDescp;
      jsonData.status = values.status;

      // log.info("orginput", jsonData);
      dispatch(setOrgGroup(jsonData));

      cancelCallback();
    },
    onReset: (values) => {},
  });

  useEffect(() => {
    if (org) {
      groupform.setFieldValue("orgId", org.metadata.accountId);
    }
  }, [org]);

  useEffect(() => {
    if (user) {
      groupform.setFieldValue("email", user.metadata.email);
      groupform.setFieldValue("userId", user.metadata.accountId);
    }
  }, [user]);

  const handleCancel = (event) => {
    cancelCallback();
  };

  // log.info("org group", groupform.values);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%", md: "60%", lg: "50%" },
        }}
      >
        <Box mb={1}>
          <div className="colfl0">
            <Title>{title ? title : ""} </Title>
          </div>
          <div className="colfr0">
            <MyTooltipButtons
              onClick={handleCancel}
              btnKey={"cancel"}
              btnLabel={"Cancel"}
              enState={false}
            />
          </div>
        </Box>
        <Box className="clear text-color-error"></Box>
        {groupform.errors ? (
          groupform.errors.orgId ? (
            <div className="text-color-error my5" style={{ width: "100%" }}>
              {groupform.errors.orgId}
            </div>
          ) : groupform.errors.userId ? (
            <div className="text-color-error my5" style={{ width: "100%" }}>
              {groupform.errors.userId}
            </div>
          ) : (
            <div />
          )
        ) : (
          <div />
        )}
        <InputComponent
          id="groupName"
          name="groupName"
          label="Group Name"
          errors={groupform.errors.groupName ? true : false}
          variant="standard"
          disabled={false}
          defaultValue={groupform.values.groupName}
          handleChange={groupform.handleChange}
        />
        &nbsp;
        <InputComponent
          id="groupDescp"
          name="groupDescp"
          label="Group desription"
          defaultValue={groupform.values.groupDescp}
          errors={groupform.errors.groupDescp ? true : false}
          variant="standard"
          handleChange={groupform.handleChange}
        />
        <Box className="clear" />
        <Box mt={2} pt={2} className="border-dbt">
          <Box className="colfr0">
            <MyHelpButtons
              onClick={groupform.handleSubmit}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
              btnTooltip={"Add"}
            />
          </Box>
          <Box className="clear" />
        </Box>
        <Box className="clear" />
      </Grid>
    </Grid>
  );
};

export default GroupInput;
