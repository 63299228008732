import { useState } from "react";
import { useSelector } from "react-redux";

import { ReportsTestsList4MD } from "../../laboratory/components/ReportsTestsList4MD";
import { ReportsMoreTestList4MD } from "../../laboratory/components/ReportsMoreTestList4MD";

import log from "../../../services/log";

export const ReportsTestsListMD = (props) => {
  const searchtext = useSelector((state) =>
    state?.user?.searchtext ? state.user.searchtext : null
  );
  let searchcontext = useSelector((state) =>
    state?.user?.searchtext ? state.user.searchcontext : null
  );
  const [actionState, setActionState] = useState("");

  // log.info("rtlist searchtext", searchtext);
  // log.info("rtlist searchcontext", searchcontext);
  searchcontext = { ...searchcontext, name: searchtext };

  const handleCancel = () => {
    setActionState("");
  };

  // log.info("ctlist searchtext", actionState);

  return (
    <div>
      {actionState === "moretests" ? (
        <ReportsMoreTestList4MD handleBack={handleCancel} />
      ) : (
        <ReportsTestsList4MD
          searchText={searchtext && searchcontext}
          setActionState={setActionState}
        />
      )}
    </div>
  );
};
