import AboutModal from "./AboutModal.json";
import Buttons from "./Buttons.json";
import CineDialog from "./CineDialog.json";
import Common from "./Common.json";
import DatePicker from "./DatePicker.json";
import Header from "./Header.json";
import MeasurementTable from "./MeasurementTable.json";
import StudyList from "./StudyList.json";
import UserPreferencesModal from "./UserPreferencesModal.json";
import ViewportDownloadForm from "./ViewportDownloadForm.json";
import ToolTip from "./ToolTip.json";
import StudyBrowser from "./StudyBrowser.json";
import SidePanel from "./SidePanel.json";
import PatientInfo from "./PatientInfo.json";
import Modes from "./Modes.json";
import Modals from "./Modals.json";

export default {
  "test-LNG": {
    AboutModal,
    Buttons,
    CineDialog,
    Common,
    DatePicker,
    Header,
    MeasurementTable,
    StudyList,
    UserPreferencesModal,
    ViewportDownloadForm,
    ToolTip,
    StudyBrowser,
    PatientInfo,
    Modes,
    SidePanel,
    Modals,
  },
};
