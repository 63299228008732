import * as yup from "yup";

export const addressInputSchema = yup.object({
  orgId: yup.string().min(2).required("org is required"),
  groupId: yup.string().min(2).required("org is required"),
  countrycode: yup.string().required("country is required"),
  country: yup.string().required("country is required"),
  region: yup.string().required("region is required"),
  regioncode: yup.number().required("region is required"),
  subregion: yup.string().required("sub-region is required"),
  subregioncode: yup.number().required("sub-region is required"),
  area: yup.string().required("area is required"),
  areacode: yup.string().required("area is required"),
  userId: yup.string().required("user is required"),
  email: yup.string().required("user is required"),
  name: yup.string().required("user is required"),
  street: yup.string().required("street is required"),
  formattedAddress: yup.string().required("street is required"),
  lat: yup.number().required("loc is required"),
  lon: yup.number().required("loc is required"),
  city: yup.string(),
  status: yup.string(),
});
