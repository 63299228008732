import {
  getMessagesService,
  getMessageService,
  getMessageListService,
  postMessageService,
  postMessageListService,
  getClinicNotesService,
  postClinicNoteService,
} from "../../services/messages-service";
import { messageActionTypes } from "./message.types";

export const setMessage = (message) => (dispatch) => {
  dispatch({
    type: messageActionTypes.SET_MESSAGES,
    payload: message,
  });
};

export const setNewClinicalNote = (message) => (dispatch) => {
  dispatch({
    type: messageActionTypes.NEW_CLINICNOTES,
    payload: message,
  });
};

export const postMessageList = (payload) => (dispatch) => {
  return postMessageListService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const postMessage = (payload) => (dispatch) => {
  return postMessageService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getMessage = (payload) => (dispatch) => {
  return getMessageService(payload).then(
    (response) => {
      dispatch({
        type: messageActionTypes.SELECT_MESSAGE,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getMessages = (payload) => (dispatch) => {
  return getMessagesService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getMessageList = (payload) => (dispatch) => {
  return getMessageListService(payload).then(
    (response) => {
      dispatch({
        type: messageActionTypes.LIST_MESSAGES,
        payload: response.data,
      });
      console.log("message response", response.data);
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getClinicNotes = (payload) => (dispatch) => {
  // console.log("response", payload);
  return getClinicNotesService(payload).then(
    (response) => {
      dispatch({
        type: messageActionTypes.LIST_CLINICNOTES,
        payload: response.data,
      });
      console.log("response", response.data);
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const postClinicNote = (payload) => (dispatch) => {
  return postClinicNoteService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const setReceiversList = (message) => (dispatch) => {
  dispatch({
    type: messageActionTypes.RECEIVERS_LIST,
    payload: message,
  });
};
