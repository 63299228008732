import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AdminMenu } from "../common/newMenuSidebar";
import { PageContent } from "../common/ConstantsAndValues";
// import log from "../../services/log";
import { patientMenuList } from "../../routes/PatientRoutes";
// import { MessageBubbleIcon } from "../customIcons/MessageBubbleIcon";
import FeedbackModal from "../modals/FeedbackModal";

export default function PatientDashboard(props) {
  const [modalState, setModalState] = React.useState(false);
  const { auth } = props;
  const isAuthorized =
    auth?.user?.user?.metadata?.role === "metasys" ||
    auth?.user?.user?.metadata?.role === "metauser";

  // log.info("url authorization", isAuthorized);

  return isAuthorized ? (
    <div className="wrapper d-flex h-100">
      {modalState && <FeedbackModal setModalState={setModalState} />}
      <AdminMenu menuList={patientMenuList}> </AdminMenu>
      <PageContent className="bg-gradient-menu">
        <Outlet></Outlet>

        {/* chat bubble */}
        {/* <div className="chat_circle">
          <MessageBubbleIcon />
        </div> */}
        <div className="feedback_button" onClick={() => setModalState(true)}>
          Feedback
        </div>
      </PageContent>
    </div>
  ) : (
    <Navigate to="/sign" />
  );
}
