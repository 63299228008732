import React, { PureComponent, useState } from "react";
import MetaBarChart from "./barchart";
import MetaAreaChart from "./areachart";
import { Grid, Box, Typography } from "@mui/material";
import MetaLineCharts from "./MetaLineCharts";
import moment from "moment";

const al_data = {
  name: "Albumin",
  min: 3.2,
  max: 4.7,
  unit: "g/dL",
  title: "KIDNEY FUNCTION PANEL - Details",
  xlable: "Dates",
  ylable: "Values g/dL",
  data: [
    {
      timestamp: 1267498251,
      lower: 3.2,
      upper: 4.7,
      albumin: 3.9,
    },
    {
      timestamp: 1323917451,
      lower: 3.2,
      upper: 4.7,
      albumin: 3.8,
    },
    {
      timestamp: 1343789451,
      lower: 3.2,
      upper: 4.7,
      albumin: 3.7,
    },
    {
      timestamp: 1397789451,
      lower: 3.2,
      upper: 4.7,
      albumin: 4.2,
    },
    {
      timestamp: 1461034251,
      lower: 3.2,
      upper: 4.7,
      albumin: 3.9,
    },
    {
      timestamp: 1550717451,
      lower: 3.2,
      upper: 4.7,
      albumin: 3.7,
    },
    {
      timestamp: 1579575051,
      lower: 3.2,
      upper: 4.7,
      albumin: 4.2,
    },
    {
      timestamp: 1611802251,
      lower: 3.2,
      upper: 4.7,
      albumin: 4.1,
    },
    {
      timestamp: 1618023051,
      lower: 3.2,
      upper: 4.7,
      albumin: 3.8,
    },
    {
      timestamp: 1634784651,
      lower: 3.2,
      upper: 4.7,
      albumin: 4.1,
    },
  ],
};

const ca_data = {
  name: "Calcium",
  min: 8.2,
  max: 10.2,
  unit: "mg/dL",
  title: "KIDNEY FUNCTION PANEL - Details",
  xlable: "Dates",
  ylable: "Values mg/dL",
  data: [
    {
      timestamp: 1267498251,
      lower: 8.2,
      upper: 10.2,
      calcium: 9.0,
    },
    {
      timestamp: 1323917451,
      lower: 8.2,
      upper: 10.2,
      calcium: 9.0,
    },
    {
      timestamp: 1343789451,
      lower: 8.2,
      upper: 10.2,
      calcium: 9.2,
    },
    {
      timestamp: 1397789451,
      lower: 8.2,
      upper: 10.2,
      calcium: 9.2,
    },
    {
      timestamp: 1418093451,
      lower: 8.2,
      upper: 10.2,
      calcium: 9.2,
    },
    {
      timestamp: 1461034251,
      lower: 8.2,
      upper: 10.2,
      calcium: 8.7,
    },
    {
      timestamp: 1508986251,
      lower: 8.2,
      upper: 10.2,
      calcium: 9.6,
    },
    {
      timestamp: 1550717451,
      lower: 8.2,
      upper: 10.2,
      calcium: 9.3,
    },
    {
      timestamp: 1579575051,
      lower: 8.2,
      upper: 10.2,
      calcium: 9.8,
    },
    {
      timestamp: 1611802251,
      lower: 8.2,
      upper: 10.2,
      calcium: 9.7,
    },
    {
      timestamp: 1634784651,
      lower: 8.2,
      upper: 10.2,
      calcium: 9.7,
    },
  ],
};

const initialData = [
  {
    name: "2017-1-20",
    tsh: 9.36,
    tshp: 9.0,
  },
  {
    name: "2017-5-24",
    tsh: 0.22,
    tshp: 8.5,
  },
  {
    name: "2017-7-20",
    tsh: 0.34,
    tshp: 7.5,
  },
  {
    name: "2019-2-21",
    tsh: 0.08,
    tshp: 6.6,
  },
  {
    name: "2017-5-24",
    tsh: 0.05,
    tshp: 6.0,
  },
  {
    name: "2020-1-21",
    tsh: 0.13,
    tshp: 5.4,
  },
];

const color = ["#82ca9d", "#FFCC80", "#8884d8", "#82ca9d", "orange", "pink"];

function getIntro(label) {
  return label;
}

export const MetaAxisTick = (props) => {
  const { x, y, stroke, payload } = props;
  const dateFormatter = (date) => {
    return moment(date).format("DD/MM/YY HH:mm");
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        textAnchor="end"
        fill="#666"
        transform="rotate(-45)"
      >
        {dateFormatter(payload.value)}
      </text>
    </g>
  );
};

export function CustomTooltip(props) {
  const { payload, label, active } = props;
  const dateFormatter = (date) => {
    return moment(date).format("DD/MM/YY HH:mm");
  };

  if (active) {
    return (
      <Typography sx={{ spacing: 0 }}>
        <Typography itemID={"date"}>
          Recorder on: {dateFormatter(getIntro(label))}
        </Typography>
        <Typography itemID={"value"}>recorded: {payload[2].value}</Typography>
        <p className="label">
          normal range: {payload[0].value}-{payload[1].value}
        </p>
      </Typography>
    );
  }
  return null;
}

const idata = [al_data, ca_data];

export class MetaGraphtest extends React.Component {
  state = {
    dataIndex: 0,
    data: idata[0].data,
    title: idata[0].title,
    chatType: "Line",
    testType: idata[0].name,
    xlable: "Dates",
    ylable: "Values mg/dL",
    left: 0,
    right: idata[0].data.length,
    refAreaLeft: "",
    refAreaRight: "",
    top: idata[0].upper + 1,
    bottom: idata[0].lower - 1,
    top2: idata[0].upper + 20,
    bottom2: idata[0].lower - 20,
    animation: true,
  };

  handleChange = (e) => {
    this.setState({ chartType: e.target.value });
  };

  handleTestChange = (e) => {
    let dataIndex = 0;
    const { name } = this.state;
    switch (e.target.value) {
      case "Albumin":
        dataIndex = 0;
        break;
      case "Calcium":
        dataIndex = 1;
        break;
      default:
        return;
    }

    this.setState({
      dataIndex: dataIndex,
      name: idata[dataIndex].name,
      data: idata[dataIndex].data,
      testType: idata[dataIndex].name,
      xlable: idata[dataIndex].xlable,
      ylable: idata[dataIndex].ylable,
      left: 0,
      right: idata[dataIndex].data.length,
      top: idata[dataIndex].upper + 1,
      bottom: idata[dataIndex].lower - 1,
      top2: idata[dataIndex].upper + 20,
      bottom2: idata[dataIndex].lower - 20,
    });
  };

  fetchChart = () => {
    console.log(this.state);
    const { chartType } = this.state;
    switch (chartType) {
      case "Line":
        return <MetaLineCharts {...this.state} />;
      case "Bar":
        return <MetaBarChart {...this.state} />;
      case "Area":
        return <MetaAreaChart {...this.state} />;
      case "Stacked":
        return <MetaBarChart stacked {...this.state} />;
      default:
        return <MetaLineCharts {...this.state} />;
    }
  };

  zoomOut() {
    const { dataIndex } = this.state;
    this.setState(() => ({
      data: idata[dataIndex].data,
      refAreaLeft: "",
      refAreaRight: "",
      left: 0,
      right: idata[dataIndex].data.length,
    }));
  }

  render() {
    const { title } = this.state;

    return (
      <Grid p={0} mb={2} container>
        <Grid className="wrapper d-flex">{this.fetchChart()}</Grid>
      </Grid>
    );
  }
}

export const MetaGraphtest2 = (props) => {
  const { data, title, chartType, xlabel, ylabel, labName } = props;

  const [dataIndex, setDataIndex] = useState(0);
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(data.length);
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const [animation, setAnimation] = useState(true);
  const [top, setTop] = useState(data.upper + 1);
  const [bottom, setBottom] = useState(data.upper - 1);
  const [top2, setTop2] = useState(data.upper + 20);
  const [bottom2, setBottom2] = useState(data.upper - 20);

  const fetchChart = () => {
    console.log(chartType);
    switch (chartType) {
      case "Line":
        return <MetaLineCharts {...this.state} />;
      case "Bar":
        return <MetaBarChart {...this.state} />;
      case "Area":
        return <MetaAreaChart {...this.state} />;
      case "Stacked":
        return <MetaBarChart stacked {...this.state} />;
      default:
        return <MetaLineCharts {...this.state} />;
    }
  };

  const zoomOut = () => {
    setRefAreaLeft("");
    setRefAreaRight("");
    setLeft(0);
    setRight(data.length);
  };

  return (
    <Grid p={0} mb={2} container>
      <Grid className="wrapper d-flex">{this.fetchChart()}</Grid>
    </Grid>
  );
};
