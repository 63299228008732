import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Collapse,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
  tableParam,
} from "../common/ConstantsAndValues";
import { MyIconButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";

export function ClinicalTestsPresListRow(props) {
  const { row, handleRowClick } = props;
  const selectedrows = useSelector((state) => state?.labs?.labsorderselect);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [maplist, setMaplist] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    log.info("labslistrow", selectedrows);
    if (selectedrows) {
      let isSelected =
        selectedrows.metadata.loincNumber === row.metadata.loincNumber;
      setSelectedRow(isSelected);
    }
  }, [selectedrows]);

  useEffect(() => {
    if (row) {
      const newmap = [];
      newmap.push({
        id: "loinc",
        label: "LoincNumber",
        value: row?.metadata?.loincNumber,
      });
      newmap.push({ id: "name", label: "Common name", value: row?.commonName });
      newmap.push({ id: "comment", label: "Comment", value: row?.comment });
      newmap.push({ id: "status", label: "Status", value: row?.status });
      setMaplist(newmap);
    }
  }, [row]);

  const handleClick = () => {
    log.info("medicinelist");
    setOpen(!open);
  };

  const handleRowClickLocal = (e) => {
    log.info("druglist", e);
    const newstate = !selectedRow;
    setSelectedRow(newstate);
    handleRowClick(e, newstate);
  };

  // log.info("medicinelist row", row);

  return (
    <React.Fragment>
      <StyledTableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
        onClick={() => handleRowClickLocal(row)}
      >
        <StyledTableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <b>{row.labName}</b>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant={"body2"}>{row.commonName}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left">{row.doctorName}</StyledTableCell>
        {/*<StyledTableCell align="right">{row.updated_at? row.updated_at: ''}</StyledTableCell>*/}
      </StyledTableRow>

      <TableRow className="text-color-gray bg-standard-offwhite">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">Details</div>
              </div>
              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {maplist &&
                      maplist.map((m, index) => (
                        <TableRow key={m.id + index}>
                          <TableCell>{m.label}</TableCell>
                          <TableCell>{m.value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
