import * as React from "react";
import { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
//import Divider from '@mui/material/Divider';
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Grid, Box, Typographyn } from "@mui/material";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import TextField from "@material-ui/core/TextField";
import Fab from "@mui/material/Fab";

import PersonTwoToneIcon from "@mui/icons-material/PersonTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import ContentPasteSearchTwoToneIcon from "@mui/icons-material/ContentPasteSearchTwoTone";
//import CircleIcon from '@mui/icons-material/Circle';

import { Title } from "../common/Title";
import {
  SubContentStyle,
  ContentStyle,
  colWidthRBarUSR,
  picPath,
  tableParam,
} from "../common/ConstantsAndValues";
import { SelectCombo } from "../common/SelectCombo";

import {
  HealthConditionRowsAll,
  HealthConditionGroups,
} from "../../tests/HealthConditions";

const Rows = HealthConditionRowsAll;
const Groups = HealthConditionGroups;
const g = "Health Group";
const u = "Health Conditions List";

const containerType = {
  comboWidth: "100%",
  comboLabel: "Health Group",
  comboList: HealthConditionGroups,
};

export function HealthMonitorConditionsAll(props) {
  const { openEventPage, sourcekey } = props;
  const [page, setPage] = useState("");
  //const [pageGroup, setPageGroup]        = useState('')
  const [healthList, setHealthList] = useState(Rows);
  const [isActive, setIsActive] = useState(false);

  function getFilteredList(alist, id, fvalue) {
    if (!fvalue) {
      return alist;
    }
    return alist.filter((item) => item.typ === id); //ref:==> id, code, name, typ, status
  }

  useEffect(() => {
    setHealthList(Rows);
  }, []);

  const cboHandleChange = (event) => {
    //called in combo

    //setPageGroup(event);
    setIsActive(event ? true : false);

    {
      /* GET ID FROM Groups*/
    }
    const found = Groups.filter((x) => {
      return x.label === event;
    });
    const fid = found ? found[0]._id : "";

    console.log("Group selected", fid, event);

    {
      /* FILTER HEALTH LIST Rows */
    }
    const flist = getFilteredList(Rows, fid, event);
    setHealthList(flist);
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
    openEventPage(newPage); //to parent
  };

  return (
    <>
      <Box className="" p={1} ml={0} mr={0} mb={1} height="auto" width="auto">
        <SelectCombo
          containerType={containerType}
          cboHandleChange={cboHandleChange}
        />
      </Box>

      {isActive && (
        <Box>
          <Box className="" pt={1} ml={0} mr={0} mt={2}>
            <Title>
              <ContentPasteSearchTwoToneIcon /> {u}
            </Title>
          </Box>

          <Box
            className="list border-dbt border-dbb"
            pt={0}
            ml={-2}
            mr={2}
            mb={1}
            height={tableParam.minHeight}
            width="auto"
          >
            <ul className="list-group list-group-flush ">
              {healthList !== null
                ? healthList.map((row) => {
                    return (
                      <li
                        className={` list-group-item cursor-pointer ${
                          page === row.code ? "text-color-inv" : "text-color"
                        }`}
                        key={row.id}
                        onClick={() => onPageChange(row.code)}
                      >
                        <div className="colfl0">{row.name}</div>
                        <div className="colfr0" width="25%">
                          {row.code}
                        </div>
                      </li>
                    );
                  })
                : null}
            </ul>
          </Box>
        </Box>
      )}
    </>
  );
}
