import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Paper,
  Typography,
  Box,
  TableRow,
  TableContainer,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  NativeSelect,
  Avatar,
  Checkbox,
} from "@mui/material";

import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

import { useDispatch, useSelector } from "react-redux";

import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ExpandCircleDownTwoToneIcon from "@mui/icons-material/ExpandCircleDownTwoTone";
import AdjustIcon from "@mui/icons-material/Adjust";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";

import { getMessage, getMessageList } from "../../redux/actions/message";
import {
  pageOptions,
  tableParam,
  colWidthSign,
} from "../common/ConstantsAndValues";
import { StyledTableCell, StyledTableRow } from "../common/ConstantsAndValues";

import { NotesEditor } from "../common/NotesEditor";
import { TextEditor } from "../editor/TextEditor";
import { Slate, Editable, withReact } from "slate-react";
import { createEditor, Descendant } from "slate";
import { useMemo } from "react";
import { TextEditorView } from "../editor/TextEditorView";
import ThreeDotMessageReplyForward from "../CustomComponents/ThreeDotMessageReplyForward";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import log from "../../services/log";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  table: {
    minWidth: colWidthSign.width,
  },
  Snackbar: {
    bottom: "104px",
  },
});

function Row(props) {
  const { row, reply, addToMain, addForwMain } = props;

  const [open, setOpen] = useState(false);

  const message = useSelector((state) => state.messages.message);
  const editor = useMemo(() => withReact(createEditor()), []);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(
      getMessage({
        id: row.messageId,
      })
    );
  }, [row]);

  const handleReplyClick = (row) => {
    // log.info('messagetable', row);
    addToMain(row);
  };

  const handleForwardClick = (row) => {
    // log.info('messagetable', row);
    addForwMain(row);
  };

  const dateFormat = (v) => {
    return new Date(v).toLocaleDateString();
  };

  return (
    <React.Fragment>
      {message && (
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell width="11px" align="left">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <AdjustIcon /> : <ExpandCircleDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            <Typography variant={"body1"}>
              <span className="text-color-primary-off">{row.sourceName}</span> -
              <span className="text-color-gray">
                {dateFormat(message.updated_at)}
              </span>
            </Typography>
            <Typography variant={"body2"}>
              <b>{message.msg.msgSubject}</b> &nbsp;&nbsp;
            </Typography>
          </StyledTableCell>

          {reply && (
            <StyledTableCell
              width="3px"
              key={row.title}
              //title="Manage"
              className="text-color-gray"
              align="right"
              sx={{ verticalAlign: "top" }}
            >
              <ThreeDotMessageReplyForward
                row={row}
                handleReplyClick={handleReplyClick}
                handleForwardClick={handleForwardClick}
              />
            </StyledTableCell>
          )}
        </StyledTableRow>
      )}

      <TableRow>
        {message && (
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box mt={2} ml={6} mb={2} className="colfl">
                <Table
                  // aria-label="simple table"
                  size="small"
                  aria-label="purchases"
                  className="border-db"
                  width="100%;"
                >
                  <TableBody>
                    {message.msg.msgBody &&
                      message.msg.msgBody.map((historyRow) => (
                        <TableRow key={historyRow.date}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="bg-standard-lightest"
                          >
                            <Typography variant={"body2"}>
                              {message.msg.msgBody.map((msg, index) => (
                                <TextEditorView addToMsg={msg} />
                              ))}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
}

export default function MetaMessageTable(props) {
  const { replyMsg, forwardMsg } = props;
  const RowsPPage = tableParam.defLinesPerPage;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPPage);

  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const mrows = useSelector((state) => state.messages.messages);

  let lcount = mrows ? parseInt(mrows.length) : 0;

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const jsonData = {};
      jsonData.metadata = {};
      jsonData.metadata.destId = user.metadata && user.metadata.accountId;
      dispatch(getMessageList(jsonData));
    }
  }, [user]);

  const handleChangePage = (event, newPage) => {
    log.info("messagetable", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    log.info("messagetable", event);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const addToMain = (message) => {
    log.info("messagetable", message);
    replyMsg(message);
  };

  const addForwMain = (message) => {
    log.info("messagetable", message);
    forwardMsg(message);
  };

  // log.info("messagetable", mrows.length)

  const pagedata = [{ count: lcount, limit: rowsPerPage, page: page }];

  return (
    <div>
      <TableContainer
        component={Paper}
        elevation={0}
        style={{ minHeight: tableParam.minHeight }}
      >
        <Table aria-label="simple table">
          <TableBody>
            {mrows && mrows.length ? (
              mrows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <Row
                    key={row.id}
                    reply={props.reply}
                    row={row}
                    addToMain={() => addToMain(row)}
                    addForwMain={() => addForwMain(row)}
                  />
                ))
            ) : (
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <div className="text-color-error">No data found</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/*not working 
            <TablePaginationStandard
                handleChangePage       = {handleChangePage}
                handleChangeRows       = {handleChangeRowsPerPage}
                pagedata               = {pagedata} 
            />
            */}

      <TablePagination
        className="bg-gray border-dbt"
        rowsPerPageOptions={pageOptions}
        component="div"
        count={mrows && lcount ? lcount : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        backIconButtonProps={{
          "aria-label": "< Previous",
        }}
        nextIconButtonProps={{
          "aria-label": "Next >",
        }}
      />
    </div>
  );
}
