// import node modules
import * as React from "react";
import { ThemeTabs } from "../../tabs/ThemeTabs";

function DashboardHome() {
  const tabs = [
    { id: 1004, title: "Dashboard" },
    { id: 1005, title: "Alerts & notifications" },
  ];
  return <ThemeTabs tabs={tabs} />;
}
export default DashboardHome;
