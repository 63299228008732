//metauser
import * as React from "react";
import { Grid } from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { ReportChartHeader } from "./ReportChartHeader";
import { testList } from "../../tests/TestData";
import { ChartFormat } from "../charts/ChartFormat";

import { datac as datatest } from "../../tests/Data_LC";

export const RadChartContainer = (props) => {
  const [markDates, dateTest, auth, eventType, page] = useOutletContext();

  console.log("page", page);

  let testdata = datatest.items;
  testdata.forEach((i) => {
    i.date = new Date(i.date);
    i.value = Number(i.value);
  });

  const reports = useMemo(() => testList.kdfp);
  const [reportSet, setReportSet] = useState(reports);
  const [chartType, setChartType] = useState("Line");
  const [selectedChart, setSelectedChart] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log(selectedChart);
    if (selectedChart) {
      setData(testdata);
    } else {
      setData([]);
    }

    if (data && toDate && fromDate) {
      const to = new Date(toDate);
      const from = new Date(fromDate);
      const fdata = data.filter((d) => d.date >= from && d.date <= to);
      setData(fdata);
    }
  }, [testdata, selectedChart, fromDate, toDate]);

  // console.log(data)
  const selectReport = (report) => {
    setSelectedChart(report);
  };

  const fromDateCallback = (date) => {
    setFromDate(date);
  };

  const toDateCallback = (date) => {
    setToDate(date);
  };

  const handleChartType = (e) => {
    setChartType()(e.target.value);
  };

  const handleRerportSet = (e) => {
    setReportSet()(e.target.value);
  };

  return (
    <Grid p={0} mb={2} container>
      <ReportChartHeader
        fromDateCallback={fromDateCallback}
        toDateCallback={toDateCallback}
        selectReport={selectReport}
        tests={reportSet}
        page={page}
      />

      <ChartFormat
        chartType={chartType}
        selectedChart={selectedChart}
        fromDate={fromDate}
        toDate={toDate}
        data={data}
        page={page}
      />
    </Grid>
  );
};

export default RadChartContainer;
