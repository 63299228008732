import React from "react";
import { Grid, Paper, Box, Typography, Container } from "@mui/material";

import { Title } from "../../common/Title";

export function CKD() {
  return (
    <div>
      <Title>General Study of CKD</Title>

      <Title>Symptoms</Title>

      <Title>How it spreads</Title>

      <Title>Prevention</Title>
      <Title>Testing & Diagnosis</Title>

      <Title>Treatment</Title>

      <Title>Additional Information</Title>

      <Title>More...</Title>
    </div>
  );
}
