import { SubscribeActionTypes } from "./subscribe.types";
import log from "../../services/log";
import { setSubscribeService } from "../../services/subscribeService";

export const initSubscribelist = (payload) => (dispatch) => {
  return dispatch({
    type: SubscribeActionTypes.SUBSCRIBE,
    payload: payload,
  });
};

export const setSubscribe = (payload) => (dispatch) => {
  return setSubscribeService(payload)?.then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};
