// fetch from backend
export const testGroupList = {
  _Id: "labGroups",
  label: "Lab Groups",
  labs: [
    {
      _Id: "",
      label: "",
    },
    {
      _Id: "KDFP",
      label: "KIDNEY FUNCTION PANEL",
    },
  ],
};

export const testList = {
  kdfp: {
    _Id: "kdfp",
    label: "KIDNEY FUNCTION PANEL",
    labs: [
      {
        _Id: "repnone",
        label: "",
        link: "none",
        xlabel: "none",
        ylabel: "none",
        color: "none",
        desc: "",
      },
      {
        _Id: "repalbumin",
        label: "Albumin",
        link: "albumin",
        xlabel: "Date",
        ylabel: "mg/dL",
        color: "#FF0000",
        desc: "An albumin blood test checks levels of albumin in your blood. Low albumin levels might indicate a problem with your liver, kidneys or other health conditions. High albumin levels are typically the result of dehydration or severe dehydration. The test is very quick and doesn’t carry any serious risks",
      },
      {
        _Id: "repcalcium",
        label: "Calcium",
        link: "calcium",
        xlabel: "Date",
        ylabel: "mg/dL",
        color: "#FF8000",
        desc: "cc",
      },
      {
        _Id: "repchloride",
        label: "Chloride",
        link: "chloride",
        xlabel: "Date",
        ylabel: "mg/dL",
        color: "#FF00ff",
        desc: "chlor",
      },
      {
        _Id: "repcreatinine",
        label: "Creatinine",
        link: "creatinine",
        xlabel: "Date",
        ylabel: "mg/dL",
        color: "#FFf080",
        desc: "creat....",
      },
      {
        _Id: "repglucose",
        label: "Glucose",
        link: "glucose",
        xlabel: "Date",
        ylabel: "mg/dL",
        color: "#00FF00",
        desc: "glucose",
      },
      {
        _Id: "repphosphorus",
        label: "Phosphorus",
        link: "phosporus",
        xlabel: "Date",
        ylabel: "mg/dL",
        color: "#44FF00",
        desc: "phos..",
      },
      {
        _Id: "reppotassium",
        label: "Potassium",
        link: "potassium",
        xlabel: "Date",
        ylabel: "mg/dL",
        color: "#88FF88",
        desc: "",
      },
      {
        _Id: "repsodium",
        label: "Sodium",
        link: "sodium",
        xlabel: "Date",
        ylabel: "mg/dL",
        color: "#0000FF",
        desc: "",
      },
      {
        _Id: "repnitrogen",
        label: "Urea Nitrogen",
        link: "nitrogen",
        xlabel: "Date",
        ylabel: "mg/dL",
        color: "#3388FF",
        desc: "",
      },
      {
        _Id: "repegfr",
        label: "eGFR",
        link: "egfr",
        xlabel: "Date",
        ylabel: "mg/dL",
        color: "#8800FF",
        desc: "",
      },
    ],
  },
};

const data = [{ dataora: "2019-10-20T04:00:00Z", valore: -0.04 }];
export const al_data = {
  name: "Albumin",
  min: 3.2,
  max: 4.7,
  unit: "g/dL",
  title: "KIDNEY FUNCTION PANEL",
  xlable: "Dates",
  ylable: "Values g/dL",
  data: [
    {
      timestamp: 1267498251,
      lower: 3.2,
      upper: 4.7,
      albumin: 3.9,
    },
    {
      timestamp: 1323917451,
      lower: 3.2,
      upper: 4.7,
      albumin: 3.8,
    },
    {
      timestamp: 1343789451,
      lower: 3.2,
      upper: 4.7,
      albumin: 3.7,
    },
    {
      timestamp: 1397789451,
      lower: 3.2,
      upper: 4.7,
      albumin: 4.2,
    },
    {
      timestamp: 1461034251,
      lower: 3.2,
      upper: 4.7,
      albumin: 3.9,
    },
    {
      timestamp: 1550717451,
      lower: 3.2,
      upper: 4.7,
      albumin: 3.7,
    },
    {
      timestamp: 1579575051,
      lower: 3.2,
      upper: 4.7,
      albumin: 4.2,
    },
    {
      timestamp: 1611802251,
      lower: 3.2,
      upper: 4.7,
      albumin: 4.1,
    },
    {
      timestamp: 1618023051,
      lower: 3.2,
      upper: 4.7,
      albumin: 3.8,
    },
    {
      timestamp: 1634784651,
      lower: 3.2,
      upper: 4.7,
      albumin: 4.1,
    },
  ],
};

function createData(id, name, sex, dob, address, city, state, zipcode) {
  return { id, name, sex, dob, address, city, state, zipcode };
}
export const patientRows = [
  createData(
    "p1",
    "Mukesh",
    "Male",
    "1966-08-07",
    "Tissiack Pl",
    "Fremont",
    "CA",
    "94539"
  ),
  createData(
    "p2",
    "Sangita",
    "Female",
    "1970-11-21",
    "Tissiack Pl",
    "Fremont",
    "CA",
    "94539"
  ),
];

export default patientRows;
