import axios from "axios";
import { API_URL } from "./Constants";
import log from "./log";
import { axiosApiInstance } from "./axiosmiddleware";

const setSubscribeService = (payload) => {
  return axios.post(API_URL + "subscribes/set", payload);
};

export { setSubscribeService };
