// import axios from "axios";
import { API_URL } from "./Constants";
import { axiosApiInstance } from "./axiosmiddleware";

const getAlertsService = (payload) => {
  return axiosApiInstance.post(API_URL + "alerts/list", payload);
};

const getAlertService = (payload) => {
  return axiosApiInstance.post(API_URL + "alerts/get", payload);
};

const getAlertListService = (payload) => {
  return axiosApiInstance.post(API_URL + "alertlist/list", payload);
};

const sendAlertService = (payload) => {
  return axiosApiInstance.post(API_URL + "alertlist/set", payload);
};

export {
  getAlertService,
  getAlertsService,
  getAlertListService,
  sendAlertService,
};
