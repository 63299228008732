import React, { useEffect, useState } from "react";

export const CancelIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.22174"
        y="0.807617"
        width="24"
        height="2"
        rx="1"
        transform="rotate(45 2.22174 0.807617)"
      />
      <rect
        x="0.807556"
        y="17.7783"
        width="24"
        height="2"
        rx="1"
        transform="rotate(-45 0.807556 17.7783)"
      />
    </svg>
  );
};
