import * as yup from "yup";

export const labPrescriptionSchema = yup.object({
  doctorId: yup.string().required("doctor information is required"),
  doctorName: yup.string().required("doctor information is required"),
  patientId: yup.string().required("patient informaiton is required"),
  patientName: yup.string().required("patient informaiton is required"),
  providerId: yup.string(),
  providerName: yup.string(),
  labId: yup.string().required("lab information is required"),
  labName: yup.string().required("lab information is required"),
  loincNumber: yup.string().required("lab information is required"),
  commonName: yup.string(),
  comment: yup.string(),
  doctorApproval: yup.boolean(),
  status: yup.string(),
});

export const drugPrescriptionSchema = yup.object({
  doctorId: yup.string().required("doctor information is required"),
  doctorName: yup.string().required("doctor information is required"),
  patientId: yup.string().required("patient information is required"),
  patientName: yup.string().required("patient information is required"),
  medicineId: yup.string().required("medicine information is required"),
  medicineName: yup.string().required("medicine information is required"),
  medicineSalt: yup.string(),
  dose: yup.number().required("dose information is required"),
  doseRoute: yup.object().required("dose route info is required"),
  doseUnit: yup.object().required("dose unit info is required"),
  doseFrequency: yup.object().required("dose frequency is required"),
  doseDuration: yup.number().required("dose duration is required"),
  refill: yup.number().min(0),
  refillPeriod: yup.string(),
  status: yup.string(),
});

export const radPrescriptionSchema = yup.object({
  doctorId: yup.string().required("doctor information is required"),
  doctorName: yup.string().required("doctor information is required"),
  patientId: yup.string().required("patient information is required"),
  patientName: yup.string().required("patient information is required"),
  labId: yup.string().required("lab information is required"),
  loincNumber: yup.string(),
  labName: yup.string().required("lab information is required"),
  status: yup.string(),
});
