import React, { useEffect, useState } from "react";

const ImmunizationIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
    >
      <g clipPath="url(#clip0_368_2876)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3762 2.61168C16.807 2.08401 17.584 2.00547 18.1117 2.43625L22.5778 6.08233C23.1054 6.51311 23.184 7.29009 22.7532 7.81776C22.3224 8.34543 21.5454 8.42396 21.0177 7.99318L19.6772 6.89877C19.6456 6.9605 19.607 7.01985 19.5613 7.07576L18.5018 8.37353L19.415 9.11905C19.5575 9.23535 19.6672 9.37689 19.743 9.53194C19.9775 9.88408 19.9696 10.3604 19.6935 10.7079C19.6632 10.7585 19.6288 10.8075 19.5904 10.8545C19.5522 10.9013 19.5112 10.9446 19.4679 10.9844L13.2152 18.6433C12.8659 19.0711 12.236 19.1348 11.8082 18.7855C11.7871 18.7684 11.767 18.7505 11.7477 18.7321C11.6797 18.6954 11.6141 18.6517 11.5519 18.601L10.3275 17.6013L5.99886 22.9035C5.82367 23.1181 5.50769 23.15 5.2931 22.9748C5.07851 22.7996 5.04657 22.4836 5.22176 22.2691L9.55039 16.9669L8.27844 15.9285C8.14315 15.8181 8.03739 15.6849 7.96216 15.5389C7.78388 15.2632 7.75223 14.9142 7.87923 14.6104C7.92471 14.4624 7.99902 14.3205 8.10302 14.1931C8.17912 14.0999 8.26603 14.0207 8.36045 13.9558L14.2918 6.6905C14.3146 6.65595 14.3394 6.62222 14.3662 6.58944C14.3929 6.55667 14.421 6.52563 14.4503 6.49633L14.4791 6.46111C14.776 6.09747 15.2756 5.99692 15.6816 6.18941C15.8306 6.23481 15.9735 6.30939 16.1016 6.41402L16.9526 7.10872L18.0121 5.81094C18.0577 5.75504 18.1081 5.70535 18.1623 5.66199L16.5517 4.3471C16.024 3.91632 15.9455 3.13934 16.3762 2.61168ZM12.5815 16.2571L17.2513 10.537L15.1403 8.81366L10.4705 14.5337L12.5815 16.2571Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_368_2876">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.71875 0.71875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ImmunizationIcon;
