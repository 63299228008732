import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from "@mui/material";
import {
  SearchBoxStandard,
  StyledTableCell,
  StyledTableRow,
  tableParam,
} from "../common/ConstantsAndValues";
import { MyIconButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";

export function PaymentDuesRow(props) {
  const { row, handleRowClick } = props;
  const selectmedrows = useSelector((state) => state?.drugs?.selectmedrows);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [maplist, setMaplist] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    log.info("medicinelistrow", selectmedrows);
    if (selectmedrows) {
      let isSelected = false;
      selectmedrows.forEach((r) => {
        // log.info("medicinlistrow", r)
        if (r.metadata.medicineId === row.metadata.medicineId) {
          isSelected = true;
        }
      });
      setSelectedRow(isSelected);
    }
  }, [selectmedrows]);

  useEffect(() => {
    if (row) {
      const newmap = [];

      newmap.push({
        id: "name",
        label: "Name",
        value: row?.medicineName?.name,
      });
      newmap.push({
        id: "salt",
        label: "Contains",
        value: row?.medicineName?.salt,
      });
      newmap.push({
        id: "dose",
        label: "Dose",
        value: row?.medicineDosage?.dose,
      });
      newmap.push({
        id: "unit",
        label: "Unit",
        value: row?.medicineDosage?.doseUnit,
      });
      newmap.push({
        id: "freq",
        label: "Frquency",
        value: row?.medicineDosage?.doseFrequency,
      });
      newmap.push({
        id: "route",
        label: "Route",
        value: row?.medicineDosage?.doseRoute,
      });
      newmap.push({
        id: "refill",
        label: "Refills",
        value: row?.refills?.numRefill,
      });
      newmap.push({ id: "det1", label: "Detail", value: row?.detail });
      newmap.push({ id: "det2", label: "Raisedby", value: row?.raisedby });

      setMaplist(newmap);
    }
  }, [row]);

  const handleClick = () => {
    log.info("medicinelist");
    setOpen(!open);
  };

  const handleRowClickLocal = (e) => {
    log.info("druglist", e);
    const newstate = !selectedRow;
    setSelectedRow(newstate);
    handleRowClick(e, newstate);
  };

  // log.info("medicinelist row", row);

  return (
    <React.Fragment>
      <StyledTableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
        onClick={() => handleRowClickLocal(row)}
      >
        <StyledTableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </StyledTableCell>

        <StyledTableCell component="th" scope="row">
          <b>{row.invoice}</b>
        </StyledTableCell>
        {/*<StyledTableCell align="right">{row.detail}</StyledTableCell>*/}
        <StyledTableCell align="right">{row.amount}</StyledTableCell>
        <StyledTableCell align="right">{row.raisedby}</StyledTableCell>
        <StyledTableCell align="right">
          {row.updated_at ? row.updated_at : ""}
        </StyledTableCell>
      </StyledTableRow>

      <TableRow className="text-color-gray bg-standard-offwhite">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">
                  Medicine Request Details
                </div>
              </div>
              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {maplist &&
                      maplist.map((m) => (
                        <TableRow key={m.id}>
                          <TableCell>{m.label}</TableCell>
                          <TableCell>{m.value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
