import * as React from "react";
import {
  StyledTableRowHead,
  StyledTableCellHead,
} from "../common/ConstantsAndValues";

export const policyTable = {
  name: {
    label: "Policy",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  provider: {
    label: "Provider",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  hii: {
    label: "Coverage",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  period: {
    label: "Period",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.format("YYYY-MM-DD"),
  },
};

export function PolicyListHead() {
  const table = policyTable;

  return (
    <React.Fragment>
      <StyledTableRowHead key={"policyHead"} style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead>{table.name.label}</StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.provider.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.hii.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.period.label}
        </StyledTableCellHead>
      </StyledTableRowHead>
    </React.Fragment>
  );
}
