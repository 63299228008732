/**
 * filtered for pharmacy
 * @param p
 * @param s
 * @param group
 * @returns {{}}
 */
export const buildPatientlistQuery = (p, s, group) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p?.page;
  jsonData["limit"] = p?.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["groupId"] = group?.metadata?.groupId;
  return jsonData;
};

/**
 * filtered for patient
 * @param p
 * @param s
 * @param group
 * @returns {{}}
 */
export const build2PatientlistQuery = (p, s, group, patient) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p?.page;
  jsonData["limit"] = p?.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["groupId"] = group?.metadata?.groupId;
  jsonData.metadata["patientId"] = patient?.metadata?.accountId;
  return jsonData;
};
