import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { tableParam } from "../common/ConstantsAndValues";

import { Title } from "../common/Title";
import log from "../../services/log";
import { useSelector } from "react-redux";
import { TextEditorView } from "../editor/TextEditorView";
import { InstructionCN } from "../common/ConstantsAndValues";

export function ClinicalNotesView(props) {
  const { clinicalnotes, patient, title } = props;

  const user = useSelector((state) => state?.auth?.user?.user);
  const selectedpatient = useSelector((state) => state?.user?.selectedPatient);

  useEffect(() => {}, [user, patient]);

  // log.info("CN View", clinicalnotes);

  return (
    <Grid item xs zeroMinWidth className="clear">
      <Box
        component="div"
        width="100%"
        maxHeight={tableParam.minHeight}
        className="scrolly clear"
      >
        <Title>{title}</Title>
        <Box mb={2}>
          {clinicalnotes &&
            clinicalnotes?.map((note) => (
              <TextEditorView addToMsg={note.msgBody} />
            ))}
        </Box>
        {clinicalnotes?.length === 0 && (
          <Box mt={2} mb={2}>
            {InstructionCN}
          </Box>
        )}
      </Box>
    </Grid>
  );
}
