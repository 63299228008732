import React from "react";
import { useEffect, useState } from "react";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { Grid, Box, Typography, Container } from "@mui/material";

import { MyHelpButtons } from "../CustomComponents/MyButtons";
import { Title } from "../common/Title";
import { validateEmail } from "../common/validateComponents";
import InputComponent from "../CustomComponents/InputComponent";

export default function CardInputs(props) {
  const { handleAddSave, handleCancel, action } = props;
  //const {handleCardNum, handleCardExpDate, handleCardCVV, handleError, data, handleAddSave} = props;

  const {
    meta,
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const [values, setValues] = useState({});
  const [disable, setDisable] = useState(true);

  const handleCardNum = (event) => {
    const ev = event.target.value;
    setValues({
      ...values,
      card: ev,
    });
    ev && setDisable(false);
  };
  const handleCardExpDate = (event) => {
    const ev = event.target.value;
    setValues({
      ...values,
      cardExpDate: ev,
    });
    ev && setDisable(false);
  };
  const handleCardCVV = (event) => {
    //abandoned
    const ev = event.target.value;
    setValues({
      ...values,
      cardcvv: ev,
    });
    ev && setDisable(false);
  };

  const handleError = (err) => {
    const ev = err === "" || err === undefined ? false : true;
    setValues({
      ...values,
      cardError: ev,
      error: err,
    });
    !ev && setDisable(false);
  };

  const handleChangeIssueBank = (event) => {
    const ev = event.target.value;
    const em = ev ? false : true;
    setValues({
      ...values,
      issueBank: ev,
      //nameError:          em ,
      //error:              em ,
    });
    ev && setDisable(false);
  };

  const handleChangeName = (event) => {
    const ev = event.target.value;
    const em = ev ? false : true;
    setValues({
      ...values,
      name: ev,
      //nameError:          em ,
      //error:              em ,
    });
    ev && setDisable(false);
  };

  const handleChangePhone = (event) => {
    const ev = event.target.value;
    const em = ev ? false : true;
    setValues({
      ...values,
      phone: ev,
      //phoneError:         em ,
      //error:              em ,
    });
    ev && setDisable(false);
  };

  const handleChangeEmail = (event) => {
    const ev = event.target.value;
    const em = !validateEmail(ev);
    setValues({
      ...values,
      email: ev,
      emailError: em,
    });
    ev && setDisable(false);
  };

  const handleAddSaveHere = () => {
    handleAddSave(values);
    action("");
    console.log("meta=", meta);
  };

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={5}>
        <Title>Add Card</Title>
        <PaymentInputsWrapper {...wrapperProps}>
          <Box p={2}>
            <svg {...getCardImageProps({ images })} p={2} />
            <input
              {...getCardNumberProps({
                onBlur: () => handleError(meta.error),
                onChange: handleCardNum,
              })}
              defaultValue={""}
            />
            <input
              {...getExpiryDateProps({
                onBlur: () => handleError(meta.error),
                onChange: handleCardExpDate,
              })}
            />
            {/*<input {...getCVCProps({        onBlur: ()=>handleError(meta.error), onChange: handleCardCVV    })} />*/}
            <div className="clear"></div>
          </Box>
        </PaymentInputsWrapper>
      </Grid>

      <Grid item xs={7}>
        <InputComponent
          id="name"
          name="name"
          label="Full Name as on the Card"
          defaultValue={""}
          variant="standard"
          handleChange={handleChangeName}
        />
        &nbsp;&nbsp;
        <InputComponent
          id="phone"
          name="phone"
          label="Registered Phone Number"
          defaultValue={""}
          variant="standard"
          handleChange={handleChangePhone}
        />
      </Grid>
      <Grid item xs={5}>
        <InputComponent
          id="issuebank"
          name="issuebank"
          label="Issued by Bank"
          defaultValue={""}
          variant="standard"
          handleChange={handleChangeIssueBank}
        />
      </Grid>
      <Grid item xs={7}>
        <InputComponent
          id="email"
          name="email"
          label="Registered eMail ID"
          defaultValue={""}
          variant="standard"
          handleChange={handleChangeEmail}
        />
        <div style={{ margin: "16px 0px" }}>
          <div className="colfr">
            <MyHelpButtons
              onClick={handleAddSaveHere}
              btnKey={"update"}
              btnLabel={"Update"}
              enState={disable}
              btnTooltip={"Update"}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
