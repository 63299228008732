//COMMON
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { ClinicalTestsListRow } from "./ClinicalTestsListRow";
import { ClinicalTestsListHead } from "./ClinicalTestsListHead";
import { MyButtons } from "../../CustomComponents/MyButtons";
import {
  colWidthSign,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import { labsMetaQueries } from "../../utils/labsqueries";
import { getLabsList, selectLabsRow } from "../../../redux/labs/labs.actions";
import log from "../../../services/log";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export function ClinicalTestsList(props) {
  const { selectRows, setActionState } = props;

  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const ctlist = useSelector((state) => state?.labs?.labslist);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ctlist) {
      setRows(ctlist);
    }
  }, [ctlist]);

  const buildQuery = (paged) => {
    // log.info("ctlist serarch query", searchtext);
    const searchquery = searchcontext
      ? labsMetaQueries(searchcontext.key, searchcontext.value, paged)
      : labsMetaQueries("name", "a", paged);
    return searchquery;
  };

  useEffect(() => {
    // log.info("drug serarch query", searchText);
    const resetpage = { ...pagedata, page: 0 };
    const searchquery = buildQuery(resetpage);
    log.info("ctlist serarch query", searchquery);
    dispatch(getLabsList(searchquery)).then((r) => {
      log.info("ctlist", r);
      if (r.total.value && r.total.value > 0) {
        setPage({
          ...resetpage,
          count: r.total.value,
          pages: r.total.value / pagedata.limit,
        });
      }
    });
  }, [searchcontext, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    const newlist = keyword
      ? ctlist.filter((v) =>
          v._source.name.toLowerCase().startsWith(keyword.toLowerCase())
        )
      : ctlist;
    setRows(newlist);
  };

  const handleChangePage = (event, value) => {
    // log.info("ctlist", value);
    // log.info("ctlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const searchquery = buildQuery(newpagedata);
    // log.info("ctlist nextpage", searchquery);
    dispatch(getLabsList(searchquery)).then((r) => {
      log.info("ctlist", r);
      if (r.total.value && r.total.value > 0) {
        setPage(newpagedata);
      }
    });
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (row, add) => {
    // log.info("Prescribe", row);
    // log.info("Prescribe", add);

    if (row && add === true) {
      setCheckedRows(true);
      dispatch(selectLabsRow(row));
    } else {
      setCheckedRows(false);
      dispatch(selectLabsRow(null));
    }
  };

  const handleGroupTests = () => {
    setActionState("moretests");
  };

  log.info("ctlist", searchcontext);

  return (
    <div>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Test Name"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div>
          <MyButtons
            onClick={handleGroupTests}
            btnKey={"info"}
            btnLabel={"Lab"}
            enState={false}
          />
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead>
              <ClinicalTestsListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {rows &&
                rows.map((row, index) => (
                  <ClinicalTestsListRow
                    key={row._source.labId + index}
                    index={index}
                    row={row}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </div>
  );
}
