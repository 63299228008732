import { useSelector } from "react-redux";
import RTestsTaxLAB from "../../laboratory/components/RTestsTaxLAB";
import log from "../../../services/log";

export const RadiologyTestsTaxORG = (props) => {
  const searchtext = useSelector((state) =>
    state.drugs.drugtext ? state.user.searchtext : null
  );

  return (
    <div>
      <RTestsTaxLAB searchText={searchtext && searchtext.drugs} />
    </div>
  );
};
