import { PaymentActionTypes } from "./payment.types";
import {
  getLedgerListService,
  setLedgerListService,
  updateLedgerListService,
} from "../../services/payment-service";

export const getLedgerList = (payload) => (dispatch) => {
  return getLedgerListService(payload).then(
    (response) => {
      dispatch({
        type: PaymentActionTypes.LEDGER_LIST,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const setLedgerList = (payload) => (dispatch) => {
  return setLedgerListService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const updateLedgerList = (payload) => (dispatch) => {
  return updateLedgerListService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};
