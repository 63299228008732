import React, { useEffect, useState } from "react";

export const AlertIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_363_3475)">
        <mask id="path-1-inside-1_363_3475" fill="white">
          <path d="M20.8141 8.45683C20.8141 6.29112 19.8994 4.21412 18.2712 2.68273C16.643 1.15134 14.4347 0.291016 12.1321 0.291016C9.8295 0.291015 7.62121 1.15134 5.99302 2.68273C4.36483 4.21411 3.45013 6.29112 3.45013 8.45683H5.43858C5.43858 6.78714 6.14379 5.18583 7.39907 4.00518C8.65435 2.82454 10.3569 2.16125 12.1321 2.16125C13.9073 2.16125 15.6099 2.82454 16.8651 4.00519C18.1204 5.18584 18.8256 6.78714 18.8256 8.45683H20.8141Z" />
        </mask>
        <path
          d="M20.8141 8.45683V10.4568H22.8141V8.45683H20.8141ZM18.2712 2.68273L19.6414 1.22587L18.2712 2.68273ZM12.1321 0.291016V-1.70898V0.291016ZM5.99302 2.68273L4.62278 1.22587L5.99302 2.68273ZM3.45013 8.45683L1.45013 8.45683L1.45013 10.4568L3.45013 10.4568L3.45013 8.45683ZM5.43858 8.45683L5.43858 10.4568H7.43858L7.43858 8.45683H5.43858ZM7.39907 4.00518L6.02883 2.54833L7.39907 4.00518ZM12.1321 2.16125V0.161253V2.16125ZM18.8256 8.45683H16.8256V10.4568H18.8256V8.45683ZM22.8141 8.45683C22.8141 5.71976 21.6569 3.12154 19.6414 1.22587L16.901 4.13959C18.1418 5.30669 18.8141 6.86249 18.8141 8.45683H22.8141ZM19.6414 1.22587C17.6301 -0.665892 14.9269 -1.70898 12.1321 -1.70898V2.29102C13.9425 2.29102 15.6559 2.96857 16.901 4.13959L19.6414 1.22587ZM12.1321 -1.70898C9.33728 -1.70898 6.63412 -0.665893 4.62278 1.22587L7.36326 4.13958C8.6083 2.96857 10.3217 2.29102 12.1321 2.29102V-1.70898ZM4.62278 1.22587C2.60728 3.12154 1.45013 5.71975 1.45013 8.45683L5.45013 8.45683C5.45013 6.86249 6.12239 5.30669 7.36326 4.13958L4.62278 1.22587ZM3.45013 10.4568H5.43858L5.43858 6.45683L3.45013 6.45683L3.45013 10.4568ZM7.43858 8.45683C7.43858 7.35851 7.90134 6.27841 8.76932 5.46204L6.02883 2.54833C4.38624 4.09326 3.43858 6.21577 3.43858 8.45683H7.43858ZM8.76932 5.46204C9.64144 4.64177 10.8491 4.16125 12.1321 4.16125L12.1321 0.161253C9.86465 0.161253 7.66726 1.0073 6.02883 2.54833L8.76932 5.46204ZM12.1321 4.16125C13.4151 4.16125 14.6228 4.64177 15.4949 5.46204L18.2354 2.54833C16.597 1.0073 14.3996 0.161254 12.1321 0.161253L12.1321 4.16125ZM15.4949 5.46204C16.3629 6.27841 16.8256 7.35851 16.8256 8.45683H20.8256C20.8256 6.21577 19.878 4.09326 18.2354 2.54833L15.4949 5.46204ZM18.8256 10.4568H20.8141V6.45683H18.8256V10.4568Z"
          mask="url(#path-1-inside-1_363_3475)"
        />
        <path
          d="M3.45013 19.5698L3.45013 8.06982L5.45013 8.06982L5.45013 19.5698L3.45013 19.5698Z"
        />
        <path
          d="M18.8141 19.5771L18.8141 8.07715L20.8141 8.07715L20.8141 19.5771L18.8141 19.5771Z"
        />
        <rect y="17.5771" width="24" height="2" rx="1" />
        <rect x="9.47066" y="22" width="5.2834" height="2" rx="1" />
      </g>
      <defs>
        <clipPath id="clip0_363_3475">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
