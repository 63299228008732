import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography, Link } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { useOutletContext } from "react-router-dom";

import { Title } from "../../common/Title";
import { SearchBoxStandard } from "../../common/ConstantsAndValues";

import useStyles from "../../CustomComponents/useStyles";
import { getLabsOrderList } from "../../../redux/labs/labs.actions";

import PartyLedger from "../../payments/PartyLedgerFIN";

import log from "../../../services/log";

export default function AccLedger() {
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const selectedPatient = useSelector((state) => state.user.selectedPatient);
  const patientBills = useSelector((state) => state.drugs.patientlist); //to correct this

  const [markDates, dateTest, auth, eventType] = useOutletContext();
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const classes = useStyles();

  const dispatch = useDispatch();
  const reports = useSelector((state) => state.labs.lablist);

  useEffect(() => {
    if (selectedPatient) {
      dispatch(
        getLabsOrderList({
          metadata: {
            patientId: selectedPatient.accountId,
          },
        })
      );
    }
  }, [selectedPatient]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = patientBills.filter((v) =>
        v.medTitle.startsWith(keyword.toLowerCase())
      ); //to correct this
      setRows(results);
    } else {
      setRows(patientBills);
    }
  };

  const handleChangePage = (event, value) => {
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: value };
    //log.info("patient druglist", drugsquery("name", query.name, newpagedata));
    //dispatch(getDrugs(drugsquery("name", query.name, newpagedata))).then((r) => {
    // log.info("drugslist", r)
    // if (r.total.value && r.total.value > 0) {
    //     setPage({... pagedata,
    //         count: r.total.value,
    //         pages: r.total.value/pagedata.limit,
    //     });
    // }
    //});
    setPage(newpagedata);
  };
  const handleChangeRows = (event, value) => {
    //pagination part
    setPage({ ...pagedata, limit: value });
  };

  const handleRowClick = (e) => {
    var updatedList = [...checkedRows];

    if (findArrayElement(updatedList, e)) {
      updatedList.splice(checkedRows.indexOf(e), 1);
    } else {
      updatedList = [...checkedRows, e];
    }

    setCheckedRows(updatedList);
    //console.log("Prescribe", updatedList);
  };

  //const handlePrescribe = () =>{
  //    console.log("Prescribe Repeat", checkedRows);
  //}

  const findArrayElement = function (array, title) {
    return array.find((item) => {
      return item === title;
    });
  };

  return (
    <Grid container>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0"></div>

          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Transactions"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div>
          {/*
                    <Link
                        onClick={handlePrescribe}
                        className="btn btn-transparent btn-icon btn-round text-color"
                    >
                        {"Prescribe"}
                    </Link>
                    */}
        </div>
      </div>

      <PartyLedger
        billlist={rows}
        //group        = {"patient"}
        //patient      = {selectedPatient}
      />
    </Grid>
  );
}
