import React, { useEffect, useState } from "react";

export const ChevronRight = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})`, marginLeft: "10px" }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <rect
        x="1.94531"
        y="0.804688"
        width="13.0016"
        height="2"
        rx="1"
        transform="rotate(45 1.94531 0.804688)"
        fill="#19445E"
      />
      <rect
        x="0.53125"
        y="17.7734"
        width="13"
        height="2"
        rx="1"
        transform="rotate(-45 0.53125 17.7734)"
        fill="#19445E"
      />
    </svg>
  );
};
