import React, { Component } from "react";
import PropTypes from "prop-types";
import { matchPath } from "react-router";
import withRouter from "./lib/withRouter";
import { Route, Routes } from "react-router-dom";
import { NProgress } from "@tanem/react-nprogress";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import {
  ViewerbaseDragDropContext,
  ErrorBoundary,
  asyncComponent,
  retryImport,
} from "../ui";

import * as RoutesUtil from "./routes/routesUtil";

import NotFound from "./routes/NotFound.js";
import { Bar, Container } from "./OHIFStandaloneViewer";
import "./OHIFStandaloneViewer.css";
import "./variables.css";
import "./theme-tide.css";

// Contexts
import { DicomContext } from "./context/DicomContext";
const CallbackPage = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "CallbackPage" */ "./routes/CallbackPage.js")
  )
);

class OHIFStandaloneViewer extends Component {
  static contextType = DicomContext;
  state = {
    isLoading: false,
  };

  static propTypes = {
    // history: PropTypes.object.isRequired,
    // user: PropTypes.object,
    setContext: PropTypes.func,
    userManager: PropTypes.object,
    location: PropTypes.object,
  };

  componentDidMount() {
    // this.unlisten = this.props.history.listen((location, action) => {
    //     if (this.props.setContext) {
    //         this.props.setContext(window.location.pathname);
    //     }
    // });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    console.log("standalone", this.props);
    const { appConfig = {} } = this.context;
    return (
      <Routes>
        <Route exact path="/silent-refresh.html" onEnter={RoutesUtil.reload} />
      </Routes>
    );

    // const noMatchingRoutes = !routes.find(r =>
    //     matchPath(currentPath, {
    //         path: r.path,
    //         exact: true,
    //     })
    // );
  }
}

const ConnectedOHIFStandaloneViewer = connect(null, null)(OHIFStandaloneViewer);

export default withRouter(ConnectedOHIFStandaloneViewer);
