import { failure, success } from "../../utils/redux";
import { UserActionTypes } from "./user.types";

const INITIAL_STATE = {
  currentUser: null,
  userAccount: null,
  usergroup: null,
  selectedUser: null,
  sendtoUser: null,
  users: null,
  selectedPatient: null,
  session: null,
  puser: null,
  pgorup: null,
  pgrouplist: null,
  docdomains: null,
  docdomain: null,
  doctypes: null,
  profile: null,
  userslist: null,
  docorder: null,
  searchtext: null,
  searchcontext: null,
  patientprofile: null,
  patients: null,
  wallet: null,
  orgspecialist: null,
  mainbar: null,
  userfamilylist: null,
  userfamilylistloading: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.MAINBAR_STATES:
      return {
        ...state,
        mainbar: action.payload,
      };
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case UserActionTypes.SET_USER_ACCOUNT:
      return {
        ...state,
        userAccount: action.payload,
      };
    case UserActionTypes.USERGROUP:
      return {
        ...state,
        usergroup: action.payload,
      };
    case UserActionTypes.SELECT_USERGROUP:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case UserActionTypes.SENDTO_USER:
      return {
        ...state,
        sendtoUser: action.payload,
      };
    case UserActionTypes.GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case UserActionTypes.SELECT_PATIENT:
      return {
        ...state,
        selectedPatient: action.payload,
      };
    case UserActionTypes.USERWALLET:
      return {
        ...state,
        wallet: action.payload,
      };
    case UserActionTypes.CLIENTSESSION:
      return {
        ...state,
        session: action.payload,
      };
    case UserActionTypes.GET_MDUSER:
      return {
        ...state,
        puser: action.payload,
      };
    case UserActionTypes.GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UserActionTypes.MDUSERGROUPLIST:
      return {
        ...state,
        pgrouplist: action.payload,
      };
    case UserActionTypes.MDUSERGROUP:
      return {
        ...state,
        pgroup: action.payload,
      };
    case UserActionTypes.DOCDOMAINS:
      return {
        ...state,
        docdomains: action.payload,
      };
    case UserActionTypes.DOCDOMAIN:
      return {
        ...state,
        docdomain: action.payload,
      };
    case UserActionTypes.DOCDOMAINVIEW:
      return {
        ...state,
        domainview: action.payload,
      };
    case UserActionTypes.DOCTYPES:
      return {
        ...state,
        doctypes: action.payload,
      };
    case UserActionTypes.USER_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case UserActionTypes.DOCORDER:
      return {
        ...state,
        docorder: action.payload,
      };
    case UserActionTypes.USERS_LIST:
      return {
        ...state,
        userslist: action.payload,
      };
    case UserActionTypes.PATIENT_PROFILE:
      return {
        ...state,
        patientprofile: action.payload,
      };
    case UserActionTypes.SEARCH_TEXT:
      return {
        ...state,
        searchcontext: { ...state.searchcontext, value: action.payload },
      };
    case UserActionTypes.SEARCH_CONTEXT:
      return {
        ...state,
        searchcontext: action.payload,
      };
    case UserActionTypes.ORGSPECIALIST:
      return {
        ...state,
        orgspecialist: action.payload,
      };
    case UserActionTypes.SELECT_MDUSERGROUP:
      return {
        ...state,
        selectmdgroup: action.payload,
      };

    case UserActionTypes.PATIENTLIST:
      return {
        ...state,
        patients: action.payload,
      };
    case UserActionTypes.GET_USER_ACCESS_LIST:
      return {
        ...state,
        userfamilylistloading: true,
      };

    case success(UserActionTypes.GET_USER_ACCESS_LIST):
      return {
        ...state,
        userfamilylist: action.payload,
        userfamilylistloading: false,
      };

    case failure(UserActionTypes.GET_USER_ACCESS_LIST):
      return {
        ...state,
        userfamilylistloading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
