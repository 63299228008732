import React, { useEffect, useState } from "react";

const MedicationsIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
    >
      <g clipPath="url(#clip0_368_223)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5578 8.76408L12.5562 8.76681L11.119 11.2562L7.4538 9.14007L8.89103 6.65072L8.89481 6.64412C9.17896 6.20052 9.61911 5.87762 10.1302 5.74066C10.6729 5.59526 11.2511 5.67138 11.7376 5.95228C12.2241 6.23318 12.5791 6.69585 12.7245 7.2385C12.8619 7.75105 12.8016 8.29531 12.5578 8.76408ZM14.3303 9.68576C14.3176 9.71314 14.3036 9.74018 14.2883 9.76681L12.2427 13.3098L12.2344 13.3244L12.2259 13.3389L10.2358 16.7859C10.2324 16.7918 10.2289 16.7977 10.2254 16.8034L10.2255 16.8035C9.67972 17.7489 8.78069 18.4388 7.72623 18.7213C6.67177 19.0039 5.54826 18.8559 4.60286 18.3101C3.65746 17.7643 2.96761 16.8653 2.68507 15.8108C2.40253 14.7563 2.55044 13.6328 3.09627 12.6874L3.09649 12.6876C3.09976 12.6816 3.10309 12.6757 3.10649 12.6698L7.11393 5.72876L7.11383 5.7287L7.12916 5.70237L7.15898 5.65072C7.17349 5.62559 7.18893 5.60132 7.20522 5.57794C7.75711 4.71005 8.61558 4.07821 9.61315 3.81091C10.6676 3.52837 11.7911 3.67628 12.7365 4.22211C13.6819 4.76793 14.3718 5.66696 14.6543 6.72142C14.9212 7.71735 14.804 8.77488 14.3303 9.68576ZM4.80645 13.7218C4.542 14.2008 4.47294 14.7642 4.61483 15.2937C4.76023 15.8364 5.11525 16.299 5.60178 16.5799C6.08831 16.8608 6.6665 16.937 7.20915 16.7916C7.73913 16.6496 8.19281 16.3076 8.47539 15.8385C8.48426 15.8208 8.4937 15.8033 8.50372 15.7859L9.88559 13.3925L6.22042 11.2764L4.83854 13.6698C4.8283 13.6876 4.81759 13.7049 4.80645 13.7218ZM16.6057 19.3107C15.8727 18.8875 15.4041 18.1822 15.2579 17.4111L20.7471 17.4111C20.6903 17.7119 20.5826 18.0084 20.4208 18.2885C19.6496 19.6243 17.9415 20.082 16.6057 19.3107ZM19.3986 14.4734C20.0928 14.8742 20.5498 15.5279 20.7204 16.251L15.2831 16.251C15.3443 15.9922 15.4437 15.7378 15.5835 15.4956C16.3547 14.1598 18.0628 13.7022 19.3986 14.4734ZM22.1814 19.3049C20.8488 21.6131 17.8974 22.4039 15.5893 21.0713C13.2812 19.7387 12.4903 16.7873 13.8229 14.4792C15.1555 12.1711 18.1069 11.3802 20.415 12.7128C22.7232 14.0454 23.514 16.9968 22.1814 19.3049Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_368_223">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.71875 0.71875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default MedicationsIcon;
