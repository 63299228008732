import * as React from "react";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, InputLabel } from "@mui/material";

import {
  searchAreas,
  searchSubregions,
  SelectCountry,
} from "../../redux/actions/locs";
import { subregionmatchquery, subregionquery } from "../utils/elasticqueries";

import { LocMSStyles } from "./LocMSStyles";
import log from "../../services/log";

export function CountrySelectLoc(props) {
  const { title } = props;

  const regiondata = useSelector((state) => state.locs.regiondata);
  const country = useSelector((state) => state.locs.country);
  const subregion = useSelector((state) => state.locs.subregion);

  const dispatch = useDispatch();
  const [options, setOptions] = useState(null);
  const [selOption, setSelOption] = useState(
    country ? { label: country._source.name, value: country } : null
  );

  useEffect(() => {
    if (regiondata && subregion) {
      const noptions = [];
      const sublist = regiondata.filter(
        (r) =>
          r._source["sub-region-code"] === subregion._source["sub-region-code"]
      );

      // log.info("countryselect", sublist);
      sublist.forEach((a) => {
        noptions.push({ label: a._source.name, value: a });
      });

      setOptions(noptions);
    }
  }, [subregion, regiondata]);

  const handleSelect = (event) => {
    // console.log('selectedregion',event);
    setSelOption(event);
    dispatch(SelectCountry(event.value));
  };

  // log.info("countryselect", country);

  return (
    <FormControl
      fullWidth
      className="text-color-primary"
      variant="outlined"
      size="small"
    >
      &nbsp;
      <InputLabel
        sx={{ minWidth: 20 }}
        variant="standard"
        htmlFor="uncontrolled-native"
      >
        {title}
      </InputLabel>
      <Box className="text-color">
        <Select
          isDisbled={subregion ? false : true}
          options={options}
          value={selOption}
          placeholder={"Select " + title}
          onChange={handleSelect}
          isSearchable={true}
          styles={LocMSStyles}
          menuPlacement="auto"
          menuPosition="fixed"
          isMulti={false}
        />
      </Box>
    </FormControl>
  );
}
