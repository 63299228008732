/**
 * filter by patient
 * @param p
 * @param s
 * @param pharma
 * @returns {{}}
 */
export const buildPatientMedicinesQuery = (p, s, patient) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p?.page;
  jsonData["limit"] = p?.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["patientId"] = patient?.metadata?.patientId;
  return jsonData;
};

export const buildUserMedicinesQuery = (p, s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p.page;
  jsonData["limit"] = p.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["patientId"] = user?.metadata?.accountId;
  return jsonData;
};
