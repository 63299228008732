export const UserActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_USER_ACCOUNT: "SET_USER_ACCOUNT",
  SELECT_PATIENT: "SELECT_PATIENT",
  USERGROUP: "USERGROUP",
  SELECT_USERGROUP: "SELECT_USERGROUP",
  SENDTO_USER: "SENDTO_USER",
  GET_USERS: "GET_USERS",
  USERS_LIST: "USERS_LIST",
  SEARCH_TEXT: "SEARCH_TEXT",
  SEARCH_CONTEXT: "SEARCH_CONTEXT",
  USER_PROFILE: "USER_PROFILE",
  PATIENT_PROFILE: "PATIENT_PROFILE",
  GET_USER: "GET_USER",
  GET_MDUSER: "GET_MDUSER",
  MDUSERGROUP: "MDUSERGROUP",
  MDUSERGROUPLIST: "MDUSERGROUPLIST",
  SELECT_MDUSERGROUP: "SELECT_MDUSERGROUP",
  USERWALLET: "USERWALLET",
  CLIENTSESSION: "CLIENTSESSION",
  DOCDOMAIN: "DOCDOMAIN",
  DOCDOMAINS: "DOCDOMAINS",
  DOCDOMAINVIEW: "DOCDOMAINVIEW",
  DOCTYPES: "DOCTYPES",
  DOCORDER: "DOCORDER",
  ORGSPECIALIST: "ORGSPECIALIST",
  PATIENTLIST: "PATIENTLIST",
  MAINBAR_STATES: "MAINBAR_STATES",
  GET_USER_ACCESS_LIST: "GET_USER_ACCESS_LIST",
  SET_USER_ACCESS_LIST: "SET_USER_ACCESS_LIST",
};
