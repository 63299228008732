import * as React from "react";
import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";

import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../CustomComponents/useStyles";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";

import { ReportsOrderListHead } from "./ReportsOrderListHead";
import { ReportsOrderListRow } from "./ReportsOrderListRow";

import log from "../../services/log";

export function ReportsOrderList(props) {
  const { medicineorderlist, searchText, selectRows } = props;

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState(null);
  const [medicines, setMedicines] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    log.info(
      "medicineorderlist",
      medicineorderlist && medicineorderlist.results
    );
    if (medicineorderlist) {
      setMedicines(medicineorderlist.results);
      setPage({
        ...pagedata,
        pages: medicineorderlist.totalPages ? medicineorderlist.totalPages : 0,
        count: medicineorderlist.totalResults
          ? medicineorderlist.totalResults
          : 0,
      });
    }
  }, [medicineorderlist]);

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    //log.info("patient druglist", drugsquery("name", query.name, newpagedata));
    //dispatch(getDrugs(drugsquery("name", query.name, newpagedata))).then((r) => {
    // log.info("drugslist", r)
    // if (r.total.value && r.total.value > 0) {
    //     setPage({... pagedata,
    //         count: r.total.value,
    //         pages: r.total.value/pagedata.limit,
    //     });
    // }
    //});
    setPage(newpagedata);
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };
  const handleRowClick = (e, selectrow) => {
    log.info("medicineorderlist", e);
    if (selectrow) {
      setCheckedRows(e);
      // dispatch(selectPatientMedcine(e));
    } else {
      setCheckedRows(null);
      // dispatch(selectPatientMedcine(null));
    }
  };

  // log.info("medicineorderlist", medicines);

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead className="">
              <ReportsOrderListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {medicines &&
                medicines.map((row) => (
                  <ReportsOrderListRow
                    key={row.id}
                    row={row}
                    isRowSelected={checkedRows}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
