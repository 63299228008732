import * as React from "react";
import Select from "react-select";
import { useEffect, useState } from "react";
import { Button, Divider } from "@mui/material";
import { Box, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { appointmentSchema } from "../schema/appointmentSchema";
import MetaUserSearch from "../search/MetaUserSearch";
import { DialogMSStyles } from "../location/LocMSStyles";
import {
  getAppointmentGuestList,
  removeAppointment,
} from "../../redux/appointment/appointment.actions";
import { createAppointment } from "../../redux/appointment/appointment.actions";
import { colChart } from "../common/ConstantsAndValues";
import { Title } from "../common/Title";
import {
  MyButtons,
  MyTooltipButtons,
  MyHelpButtons,
} from "../CustomComponents/MyButtons";
import log from "../../services/log";

const MSStyles = {
  // control:   styles => ({ ...styles,}),
  container: (styles) => ({ ...styles, width: colChart.width }),
  option: (styles) => ({ ...styles }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: colChart.width,
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid lightgray",
    boxShadow: "none",
  }),
};

export const Calupdateform = (props) => {
  const { closeForm } = props;

  const appt = useSelector((state) => state?.appointment?.appt);
  const apptglist = useSelector((state) => state?.appointment?.apptglist);

  const [options, setOptions] = useState([]);
  const [selOption, setSelOption] = useState([]);
  const [addoption, setAddoption] = useState(null);

  const dispatch = useDispatch();
  const appointmentguestForm = useFormik({
    initialValues: {
      id: "",
      orgId: "",
      groupId: "",
      departmentId: "",
      userId: null,
      email: null,
      guestId: "",
      guestName: "",
      userName: null,
      eventTitle: null,
      eventDescp: null,
      locId: "virtual",
      address: null,
      dateStart: null,
      dateEnd: null,
      command: "",
    },
    validationSchema: appointmentSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      log.info("clinicaltestinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData.metadata = {};
      jsonData.metadata.userId = values.userId;
      jsonData.userName = values.userName;
      jsonData.metadata.email = values.email;
      jsonData.metadata.eventId = values.eventId;
      jsonData.eventTitle = values.eventTitle;
      jsonData.eventDescp = values.eventDescp;
      jsonData.metadata.eventType = values.eventType;
      jsonData.dateStart = values.dateStart;
      jsonData.dateEnd = values.dateEnd;
      jsonData["eventAddress"] = {};
      jsonData.eventAddress["locId"] = values.locId;
      jsonData.eventAddress["address"] = values.address;
      jsonData.metadata.guestId = values.guestId;
      jsonData.guestName = values.guestName;

      log.info("metacal", jsonData);

      dispatch(createAppointment(jsonData));
      closeForm();
    },
    onReset: (values) => {},
  });

  useEffect(() => {
    if (appt) {
      dispatch(
        getAppointmentGuestList({
          metadata: { eventId: appt.metadata.eventId },
        })
      );
    }
  }, [appt]);

  useEffect(() => {
    /**
     * for updating and
     * adding guests
     */
    if (appt) {
      appointmentguestForm.setFieldValue("id", appt.id);
      appointmentguestForm.setFieldValue("eventId", appt.metadata.eventId);
      appointmentguestForm.setFieldValue("eventType", appt.metadata.eventType);
      appointmentguestForm.setFieldValue("eventTitle", appt.eventTitle);
      appointmentguestForm.setFieldValue("eventDescp", appt.eventDescp);
      appointmentguestForm.setFieldValue("dateStart", appt.dateStart);
      appointmentguestForm.setFieldValue("dateEnd", appt.dateEnd);
      appointmentguestForm.setFieldValue("userId", appt.metadata.userId);
      appointmentguestForm.setFieldValue("userName", appt.userName);
      appointmentguestForm.setFieldValue("email", appt.metadata.email);
    }
  }, [appt]);

  useEffect(() => {
    if (apptglist) {
      const newoptions = [];
      apptglist.forEach((e) => {
        newoptions.push({ label: e.guestName, value: e });
      });
      setOptions(newoptions);
    }
  }, [apptglist]);

  const addguest = (value) => {
    // log.info("gurestadd", value.value);
    appointmentguestForm.setFieldValue("guestId", value.value.accountId);
    appointmentguestForm.setFieldValue("guestName", value.value.name);
    setAddoption(value);
  };

  const handleSelect = (event) => {
    const newval = [];
    newval.push(event);
    setSelOption(newval);
  };

  /**
   * removing the selected guest
   * from event
   */
  const removeGuest = () => {
    // log.info("calupdateform", selOption);
    selOption.forEach((guest) => {
      // log.info("calupdateform", guest.value);
      dispatch(
        getAppointmentGuestList({
          metadata: {
            eventId: guest.value.metadata.eventId,
            guestId: guest.value.metadata.guestId,
          },
        })
      ).then((r) => {
        // log.info("guest removed", r);
        r.results &&
          r.results.forEach((e) => {
            dispatch(
              removeAppointment({
                id: e.id,
              })
            );
          });
      });
    });
  };

  // log.info("calupdateform", appt);

  return (
    <Grid
      item
      xs
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: colChart.width }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "auto",
          }}
        >
          <Title>{"Guest List"}</Title>

          <Box className="colfr0">
            <MyTooltipButtons
              onClick={removeGuest}
              btnKey={"remove"}
              btnLabel={selOption.length === 1 ? "Remove" : ""}
              enState={selOption.length === 1 ? false : true}
            />
          </Box>
        </Box>
        <Box className="clear" />
        <Box>
          <MetaUserSearch
            title={"add guest"}
            defaultValue={addoption}
            addsearch={addguest}
          />
        </Box>

        <Box
          mt={2}
          className="text-color-error"
          sx={{ display: "flex", flexDirection: "column" }}
        ></Box>

        <Box
          mt={2}
          className="text-color"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Select
            varialnt="standard"
            options={options}
            // value={selOption}
            placeholder="guest list"
            defaultValue={selOption}
            onChange={handleSelect}
            isSearchable={true}
            styles={MSStyles}
            menuPlacement="auto"
            menuPosition="fixed"
            isMulti={false}
          />
        </Box>

        <Box mt={2}>
          <Box className="colfr0">
            <MyTooltipButtons
              onClick={appointmentguestForm.handleSubmit}
              btnKey={"addloc"}
              btnLabel={"Add"}
              enState={false}
            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

{
  /*

        <Box sx={{width: colChart.width}}>
            <Box mb={2}
                 sx={{display:"flex", flexDirection:"row", justifyContent: "space-between"}}>
                <Box> <Title>{"Guest List"}</Title></Box>
                <Box>
                    <MyTooltipButtons 
                        onClick    = {appointmentguestForm.handleSubmit}
                        btnKey     = {"addloc"}
                        btnLabel   = {"Add"}
                        enState    = {false} 
                    />
                    &nbsp; &nbsp;
                    <MyTooltipButtons 
                        onClick    = {removeGuest}
                        btnKey     = {"remove"}
                        btnLabel   = {selOption.length === 1? "Remove": ""}
                        enState    = {selOption.length === 1 ? false: true} 
                    />
                </Box>
            </Box>

            <Box>
                <MetaUserSearch
                    title={"add guest"}
                    defaultValue={addoption}
                    addsearch={addguest}
                />
            </Box>
            &nbsp;
            <Box className="text-color">
                <Select
                    varialnt="standard"
                    options={options}
                    // value={selOption}
                    placeholder="guest list"
                    defaultValue={selOption}
                    onChange={handleSelect}
                    isSearchable={true}
                    styles={MSStyles}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    isMulti={false}
                />
            </Box>
        </Box>

    )
}

*/
}
