import React from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import {
  Grid,
  Box,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableFooter,
} from "@mui/material";
import * as yup from "yup";
import log from "../../services/log";
import InputComponent from "../CustomComponents/InputComponent";
import InputFormComponent from "../CustomComponents/InputFormComponent";
import { PolicyCard } from "./PolicyCard";
import { tableParam } from "../common/ConstantsAndValues";
import { MyButtons } from "../CustomComponents/MyButtons";

const policySchema = yup.object({
  subscriberId: yup.string().required("user login is required"),
  subscriberName: yup.string().required("user login is required"),
  policyId: yup.string().required("policy selection is required"),
  policyName: yup.string().required("policy selection is required"),
  providerId: yup.string().required("policy selection is required"),
  providerName: yup.string().required("policy selection is required"),
  hii: yup.number().min(0),
  opd: yup.number().min(0),
  dental: yup.number().min(0),
  vision: yup.number().min(0),
  premium: yup.number().min(0),
  period: yup.string(),
  currency: yup.string(),
  status: yup.string(),
});

export const PolicyEnrol = (props) => {
  const { policy } = props;

  const validpolicy = policy && policy.length === 1;

  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );

  const calpremium = (p) => {
    log.info("policyenrol", p);
    // const prm = p.policyPremium.premium.find((p) )
    // let premium = p.policyPremium.premium[0][0];
    // premium = premium - p.familyDiscount && p.familyDiscount > 0 ? (premium * p.familyDiscount)/100 : 0;
    // premium = premium - p.dicount && p.dicount > 0 ? (premium * p.discount)/100 : 0;
    return 100;
  };

  log.info("policyenrol", policy);

  const policyform = useFormik({
    initialValues:
      policy && policy.length == 1
        ? {
            subscriberId: user.metadata.accountId,
            subscriberName: user.name,
            policyId: policy[0].metadata.policyId,
            providerId: policy[0].metadata.providerId,
            policyName: policy[0].policyName,
            providerName: policy[0].providerName,
            premium: calpremium(policy[0]),
            period: policy[0].policyPremium.period,
            opd: policy[0].opdCoverage.coverage,
            hii: policy[0].hiiCoverage.coverage,
            dental: policy[0].dentalCoverage.coverage,
            vision: policy[0].hiiCoverage.coverage,
            status: "inprocess",
          }
        : {
            id: "",
            policyName: "",
            premium: 0,
            period: "",
            status: "",
          },
    validationSchema: policySchema,
    onSubmit: (values) => {
      log.info(JSON.stringify(values, null, 2));
    },
  });

  log.info("policyupdate", policyform);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%", md: "50%", lg: "40%" },
        }}
      >
        {validpolicy === false && (
          <span className="text-color-error">
            {"Invalid selection - selection must be made for a policy to enrol"}
          </span>
        )}
        <form>
          <Box mt={2} className="clear">
            <div className="colfl0 TitleMenu text-color-primary">
              Policy information
            </div>
            <div className="colfr0">
              <MyButtons
                onClick={policyform.handleSubmit}
                btnKey={"save"}
                btnLabel={"Save"}
                enState={false}
              />
            </div>
          </Box>
          <Box pt={2} className="clear" />
          <Box
            className="clear list border-db"
            pt={0}
            ml={0}
            mr={0}
            mb={1}
            height={tableParam.minHeight / 2}
          >
            <Table size="small" aria-label="purchases">
              <TableBody>
                <TableRow key={policyform.values.providerId}>
                  <TableCell>{"Provider"}</TableCell>
                  <TableCell colSpan="4">
                    {policyform.values.providerName}
                  </TableCell>
                </TableRow>
                <TableRow key={policyform.values.policyId}>
                  <TableCell>{"Policy"}</TableCell>
                  <TableCell colSpan="4">
                    {policyform.values.policyName}
                  </TableCell>
                </TableRow>
                <TableRow key={"coverage"}>
                  <TableCell>{"Coverage"}</TableCell>
                  <TableCell>{policyform.values.vision}</TableCell>
                  <TableCell>{policyform.values.dental}</TableCell>
                  <TableCell>{policyform.values.hii}</TableCell>
                  <TableCell>{policyform.values.opd}</TableCell>
                </TableRow>
                <TableRow key={"premium"}>
                  <TableCell>{"Premium"}</TableCell>
                  <TableCell colSpan="2">{policyform.values.premium}</TableCell>
                  <TableCell colSpan="2">{policyform.values.period}</TableCell>
                </TableRow>
                <TableRow key={policyform.values.policyId}>
                  <TableCell>{"Subscriber"}</TableCell>
                  <TableCell colSpan="4">
                    {policyform.values.subscriberName}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter></TableFooter>
            </Table>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};
