import React, { useEffect, useState } from "react";

const MessagesIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
    >
      <rect x="0.8125" y="12.7812" width="5.35345" height="2" rx="1" />
      <rect x="9.52344" y="12.7812" width="11.109" height="2" rx="1" />
      <rect
        x="5.57812"
        y="12.4297"
        width="7.25559"
        height="2"
        rx="1"
        transform="rotate(60 5.57812 12.4297)"
      />
      <rect
        x="11.8438"
        y="13.4375"
        width="7.251"
        height="2"
        rx="1"
        transform="rotate(120 11.8438 13.4375)"
      />
      <rect x="0.992188" y="0.421875" width="19.6356" height="2" rx="1" />
      <rect
        x="2.8125"
        y="0.421875"
        width="14.3589"
        height="2"
        rx="1"
        transform="rotate(90 2.8125 0.421875)"
      />
      <rect
        x="20.6328"
        y="0.421875"
        width="14.3589"
        height="2"
        rx="1"
        transform="rotate(90 20.6328 0.421875)"
      />
    </svg>
  );
};
export default MessagesIcon;
