import * as React from "react";
import {
  StyledTableRowHead,
  StyledTableCellHead,
} from "../common/ConstantsAndValues";

export const pTable = {
  title: {
    label: "Invoice",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  detail: {
    label: "Detail",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  party: {
    label: "Party",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  amount: {
    label: "Amount",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  paymentMethod: {
    label: "Method",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  paymentDate: {
    label: "Payment Date",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  comment: {
    label: "Comment",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  paymentId: {
    label: "Payment ID",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  completedAt: {
    label: "Recorded on",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
};

export default function PartyLedgerHead() {
  const tableHead = pTable;
  return (
    <React.Fragment>
      <StyledTableRowHead style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead>{tableHead.title.label}</StyledTableCellHead>
        <StyledTableCellHead align="right">
          {tableHead.amount.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {tableHead.completedAt.label}
        </StyledTableCellHead>
      </StyledTableRowHead>
    </React.Fragment>
  );
}

{
  /*
                <StyledTableCellHead align="right">{tableHead.detail.label}</StyledTableCellHead>
                <StyledTableCellHead align="right">{tableHead.party.label}</StyledTableCellHead>

                <StyledTableCellHead>              {tableHead.paymentMethod.label}</StyledTableCellHead>
                <StyledTableCellHead>              {tableHead.paymentDate.label}</StyledTableCellHead>

                <StyledTableCellHead>              {tableHead.comment.label}</StyledTableCellHead>
                <StyledTableCellHead>              {tableHead.paymentId.label}</StyledTableCellHead>
*/
}
