import { Grid } from "@mui/material";
import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import * as d3 from "d3";

import { dimensions } from "../commons/chartConstants";
import { easeLinear, interpolate, line, select, zoom } from "d3";
import useResizeObserver from "./useResizeObserver";
import { color } from "../commons/chartConstants";
//import {DateRangeSlider} from "../../common/DateRangeSlider";
import ZoomInTwoToneIcon from "@mui/icons-material/ZoomInTwoTone";
import { Button } from "@mui/material";
import log from "../../../services/log";

export const LineAreaZoomChart = (props) => {
  const parseDate = d3.timeParse("%m-%d-%Y");
  const parseTime = d3.timeParse("%Y");
  const formatTime = d3.timeFormat("%d-%m-%Y");

  const { chartType, data, streamdata, period } = props;

  const thisArea = chartType === "line" ? false : true;
  const [isArea, setIsArea] = useState(thisArea);
  const [activeIndex, setActiveIndex] = useState(null);
  const [prevItems, setPrevItems] = useState([]);
  const [chartData, setChartData] = useState({
    data: null,
    yMinValue: 0,
    yMaxValue: 0,
    domain: 0,
  });

  const currentRef = useRef(null);

  const { top, bottom, right, left } = dimensions.margin;
  const width = dimensions.width - left - right;
  const height = dimensions.height - top - bottom;

  useEffect(() => {
    if (data && period) {
      const fromDate = parseDate(period[0]);
      const toDate = parseDate(period[1]);
      const dataTimeFiltered = data.filter(
        (d) => d?.date >= fromDate && d?.date <= toDate
      );
      const yMinValue = d3?.min(dataTimeFiltered, (d) => d?.value);
      const yMaxValue = d3?.max(dataTimeFiltered, (d) => d?.value);
      const domain = d3.extent(dataTimeFiltered, (d) => d.date);
      setChartData({
        ...chartData,
        data: dataTimeFiltered,
        yMinValue: yMinValue,
        yMaxValue: yMaxValue,
        domain: domain,
      });
    }
  }, [data, period]);

  const getX = chartData?.data
    ? d3
        .scaleTime()
        .domain(d3.extent(chartData?.data, (d) => d.date))
        .range([left, width])
    : null;

  const getY = d3
    .scaleLinear()
    .domain([chartData?.yMinValue - 1, chartData?.yMaxValue + 2])
    .range([height - bottom, top]);

  useEffect(() => {
    if (getX && chartData) {
      const svgEl = d3.select(currentRef.current);
      svgEl.selectAll("*").remove();

      const svg = svgEl
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      const g = svg.append("g");

      const xAxis = d3
        .axisBottom(getX)
        .ticks(5)
        .tickSize(-height + bottom);
      const yposition = height - bottom;

      g.append("g")
        .attr("class", "x axis axisLCx")
        .attr("transform", `translate(0, ${height - bottom})`)
        .call(xAxis.tickFormat(d3.timeFormat("%b-%d-%y")))
        .selectAll("text")
        .attr("opacity", 0.75)
        //.attr("color", "blue")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-40)");

      const yAxisCall = d3.axisLeft(getY);
      g.append("g")
        .attr("transform", `translate(${left}, 0)`)
        .attr("class", "y axis axisLCy")
        .call(yAxisCall);

      // X label
      g.append("text")
        .attr("class", "x axis-label axisLCx")
        .attr("x", left + 30)
        .attr("y", height)
        .attr("opacity", 0.5)
        .attr("font-size", "20px")
        .attr("text-anchor", "middle")
        //.text("Dates")
        .text(streamdata?.xlabel);

      // Y label
      g.append("text")
        .attr("class", "y axis-label axisLCy")
        .attr("x", -left)
        .attr("y", left - 40)
        .attr("opacity", 0.5)
        //.attr("color", "orange")
        .attr("font-size", "20px")
        .attr("text-anchor", "middle")
        .attr("transform", "rotate(-90)")
        //.text("mg/DL")
        .text(streamdata?.ylabel);

      const lineFn = line()
        .x((d) => getX(d?.date))
        .y((d) => getY(d?.value));

      const linePath = d3
        .line()
        .x((d) => getX(d?.date))
        .y((d) => getY(d?.value))
        .curve(d3?.curveMonotoneX)(chartData?.data);

      const areaPath = d3
        .area()
        .x((d) => getX(d?.date))
        .y0((d) => getY(d?.value))
        .y1(() => getY(chartData?.yMinValue - 1))
        .curve(d3?.curveMonotoneX)(chartData?.data);

      isArea
        ? g
            .append("path")
            .attr("fill", streamdata?.color)
            .attr("opacity", 0.2)
            .attr("d", areaPath)
        : g
            .append("path")
            .attr("fill", "none")
            .attr("stroke", () => streamdata?.color)
            .attr("stroke-width", 1)
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round")
            .attr("d", linePath);

      chartData?.data.map((item, index) => {
        const tooltip = g.append("g").attr("key", index);

        tooltip
          .append("circle")
          .attr("fill", color(0))
          .attr("cx", getX(item.date))
          .attr("cy", getY(item.value))
          .attr("r", index === activeIndex ? 5 : 2)
          .attr("stroke", () => streamdata?.color)
          .attr("stroke-width", 1.5);

        tooltip
          .append("text")
          .attr("fill", "#666")
          .attr("x", getX(item?.date))
          .attr("y", getY(item?.value) - 20)
          .text(index == activeIndex ? item?.value : "");
      });
    }
  }, [height, width, period, chartData, activeIndex]);

  const handleMouseMove = (e) => {
    const bisect = d3?.bisector((d) => d?.date).left,
      x0 = getX?.invert(d3?.pointer(e, this)[0]),
      index = bisect(data, x0, 1);
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  // // d3.interval(() => {
  // //     console.log("test")
  // // }, 500)
  //

  const update = () => {
    const t = d3?.transition().duration(1000);
    console.log("in update");
  };

  // log.info("rendering chart context", chartData);

  return (
    <Grid container flex={"auto"}>
      <Grid
        item
        ref={currentRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
    </Grid>
  );
};

export default LineAreaZoomChart;
