import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";
import {
  HeadUrl,
  PatientListPath,
  minWidthMDCR,
  tableParam,
} from "../../common/ConstantsAndValues";
import { Title } from "../../common/Title";

import { getClinicNotes, postClinicNote } from "../../../redux/actions/message";
import DocumentMap from "../../docs/DocumentMap";

import { specialistSchema } from "../../schema/specialistSchema";

import { clinicalNotesSchema } from "../../schema/clinicalNotes";

import { ClinicalNotesView } from "../../CustomComponents/ClinicalNotesView";
import { ClinicalNotes } from "../../CustomComponents/ClinicalNotes";
import { MyHelpButtons } from "../../CustomComponents/MyButtons";

import log from "../../../services/log";
import { buildClinicNotesQuery } from "../../dbcq/clinicnotesdb";

export function ClinicalNotesMD(props) {
  const user = useSelector((state) => state?.auth?.user?.user);
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const selectedpatient = useSelector((state) => state?.user?.selectedPatient);
  const patientprofile = useSelector((state) => state?.user?.patientprofile);
  const clinicalnotes = useSelector((state) => state?.messages?.clinicnotes);
  const newclinicalnotes = useSelector(
    (state) => state?.messages?.newclinicnotes
  );

  const patient = !!selectedpatient ? selectedpatient?.name : "";
  const doc = !!user ? user?.name : "";

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const getStampCN =
    new Date().toLocaleString() + ": " + patient + ": " + doc + "\n";

  const getFreshCN = [
    {
      type: "paragraph",
      children: [
        { text: getStampCN },
        { text: "COMPLAIN:" + "\n\n\n " },
        { text: "OBSERVATIONS/ DIAGNOSTICS:" + "\n\n\n " },

        { text: "NOTES:" + "\n\n\n " },
      ],
    },
  ];

  const [eventNotes, setEventNotes] = useState(
    newclinicalnotes ? JSON.parse(newclinicalnotes) : getFreshCN
  );
  const [actionState, setActionState] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (patientprofile) {
      const resetpage = { ...pagedata, page: 0 };
      const jsonData = buildClinicNotesQuery(
        resetpage,
        searchcontext,
        selectedpatient
      );
      // log.info("patient notes ", jsonData);
      dispatch(getClinicNotes(jsonData));
    }
  }, [selectedpatient, dispatch]);

  const addNewNote = () => {
    setActionState("newnote");
  };

  const handleClose = () => {
    setActionState("");
  };

  log.info("clinicnotes md=", clinicalnotes);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginBottom: "8px" }} className="clear">
        <div className="colfr0">
          {actionState === "" && (
            <MyHelpButtons
              onClick={addNewNote}
              btnKey={"new"}
              btnLabel={"New Note"}
              enState={false}
              btnTooltip={"New"}
            />
          )}
        </div>
        <div className="clear" />
      </div>
      <div>
        {actionState === "newnote" ? (
          <>
            <ClinicalNotes
              key={"cn01"}
              getCN={eventNotes}
              title={"New Notes"}
              handleClose={handleClose}
            />
          </>
        ) : (
          <ClinicalNotesView
            key={"cn02"}
            clinicalnotes={clinicalnotes}
            patient={patient}
            title={"Previous Notes"}
          />
        )}
      </div>
    </div>
  );
}
