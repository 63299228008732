/**
 * multi field queries
 * from docontology index
 * this index maps services document to loinc
 *
 * @param field
 * @param value
 * @returns {{}}
 */
export const docDomainQueries = (field, value) => {
  let jsonData = {};
  let wildcard = {};
  let wildcard2 = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "docontology";
  jsonData.params["size"] = 250;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool = {};
  jsonData.params.query.bool["must"] = [];
  wildcard["partTypeName"] = "*subjectmatterdomain*";
  jsonData.params.query.bool.must.push({ wildcard: wildcard });
  wildcard2[field] = value;
  jsonData.params.query.bool.must.push({ wildcard: wildcard2 });
  return jsonData;
};

/**
 * single field queries
 * from docontology index
 * this index maps services document to loinc
 *
 * @param field
 * @param value
 * @returns {{}}
 */

export const docQueries = (field, value) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "docontology";
  jsonData.params["size"] = 250;
  jsonData.params["query"] = {};
  jsonData.params.query["bool"] = {};
  jsonData.params.query.bool = {};
  jsonData.params.query.bool["must"] = [];
  let wildcard = {};
  wildcard[field] = value;
  jsonData.params.query.bool.must.push({ wildcard: wildcard });
  return jsonData;
};
