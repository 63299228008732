export const Medicine_PriceList = [
  {
    id: "PL1",
    drugid: "R001",
    druggrpid: "GR001",
    drug: "a tab",
    uom: "nos",
    stock: "100",
    moq: "20",
    mrp: "100.00",
    discountpc: "5",
    rate: "95.00",
    taxpc: "",
    dose: "1 daily after food",
    duration: "3weeks",
    remarks: "",
  },
  {
    id: "PL21",
    drugid: "R002",
    druggrpid: "GR001",
    drug: "vit c tab",
    uom: "nos",
    stock: "100",
    moq: "20",
    mrp: "10",
    discountpc: "5",
    rate: "9.5",
    taxpc: "",
    dose: "1 daily after food",
    duration: "3weeks",
    remarks: "",
  },
  {
    id: "PL22",
    drugid: "R003",
    druggrpid: "GR001",
    drug: "vit B tab",
    uom: "nos",
    stock: "10",
    moq: "20",
    mrp: "50",
    discountpc: "5",
    rate: "47.50",
    taxpc: "",
    dose: "1 daily after food",
    duration: "3weeks",
    remarks: "",
  },
  {
    id: "PL31",
    drugid: "R004",
    druggrpid: "GR002",
    drug: "cough syrup ",
    uom: "bottle",
    stock: "100",
    moq: "20",
    mrp: "20",
    discountpc: "5",
    rate: "19.00",
    taxpc: "",
    dose: "thrice daily",
    duration: "3weeks",
    remarks: "",
  },
  {
    id: "PL34",
    drugid: "R005",
    druggrpid: "GR002",
    drug: "cough syrup ",
    uom: "bottle",
    stock: "100",
    moq: "20",
    mrp: "100",
    discountpc: "10",
    rate: "90.00",
    taxpc: "",
    dose: "thrice daily",
    duration: "3weeks",
    remarks: "",
  },
];

export const Medicine_TaxPCList = [
  {
    id: "GPL1",
    grpid: "GR001",
    taxpc: "18",
    value: "GR001",
    label: "Group 1",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL2",
    grpid: "GR002",
    taxpc: "12",
    value: "GR002",
    label: "Group 2",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL3",
    grpid: "GR003",
    taxpc: "18",
    value: "GR003",
    label: "Group 3",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL4",
    grpid: "GR004",
    taxpc: "18",
    value: "GR004",
    label: "Group 4",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL5",
    grpid: "GR005",
    taxpc: "12",
    value: "GR005",
    label: "Group 5",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL6",
    grpid: "GR006",
    taxpc: "18",
    value: "GR006",
    label: "Group 6",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL7",
    grpid: "GR007",
    taxpc: "12",
    value: "GR007",
    label: "Group 7",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL8",
    grpid: "GR008",
    taxpc: "18",
    value: "GR008",
    label: "Group 8",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL9",
    grpid: "GR009",
    taxpc: "12",
    value: "GR009",
    label: "Group 9",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL10",
    grpid: "GR0010",
    taxpc: "12",
    value: "GR0010",
    label: "Group 10",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL11",
    grpid: "GR0011",
    taxpc: "18",
    value: "GR0011",
    label: "Group 11",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL12",
    grpid: "GR0012",
    taxpc: "12",
    value: "GR0012",
    label: "Group 12",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL13",
    grpid: "GR0013",
    taxpc: "18",
    value: "GR0013",
    label: "Group 13",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL14",
    grpid: "GR0014",
    taxpc: "18",
    value: "GR0014",
    label: "Group 14",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL15",
    grpid: "GR0015",
    taxpc: "12",
    value: "GR0015",
    label: "Group 15",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL16",
    grpid: "GR0016",
    taxpc: "18",
    value: "GR0016",
    label: "Group 16",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL17",
    grpid: "GR0017",
    taxpc: "12",
    value: "GR0017",
    label: "Group 17",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL18",
    grpid: "GR0018",
    taxpc: "18",
    value: "GR0018",
    label: "Group 18",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL19",
    grpid: "GR0019",
    taxpc: "12",
    value: "GR0019",
    label: "Group 19",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL20",
    grpid: "GR0020",
    taxpc: "12",
    value: "GR0020",
    label: "Group 20",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL21",
    grpid: "GR0021",
    taxpc: "18",
    value: "GR0021",
    label: "Group 21",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL22",
    grpid: "GR0022",
    taxpc: "12",
    value: "GR0022",
    label: "Group 22",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL23",
    grpid: "GR0023",
    taxpc: "18",
    value: "GR0023",
    label: "Group 23",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL24",
    grpid: "GR0024",
    taxpc: "18",
    value: "GR0024",
    label: "Group 24",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL25",
    grpid: "GR0025",
    taxpc: "12",
    value: "GR0025",
    label: "Group 25",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL26",
    grpid: "GR0026",
    taxpc: "18",
    value: "GR0026",
    label: "Group 26",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL27",
    grpid: "GR0027",
    taxpc: "12",
    value: "GR0027",
    label: "Group 27",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL28",
    grpid: "GR0028",
    taxpc: "18",
    value: "GR0028",
    label: "Group 28",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL29",
    grpid: "GR0029",
    taxpc: "12",
    value: "GR0029",
    label: "Group 29",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL30",
    grpid: "GR0030",
    taxpc: "12",
    value: "GR0030",
    label: "Group 30",
    remarks: "",
    region: "IND",
  },
];

export const Medicine_MasterGroup = [
  {
    id: "GPL1",
    grpid: "GR001",
    taxpc: "18",
    value: "GR001",
    label: "Group 1",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL2",
    grpid: "GR002",
    taxpc: "12",
    value: "GR002",
    label: "Group 2",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL3",
    grpid: "GR003",
    taxpc: "18",
    value: "GR003",
    label: "Group 3",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL4",
    grpid: "GR004",
    taxpc: "18",
    value: "GR004",
    label: "Group 4",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL5",
    grpid: "GR005",
    taxpc: "12",
    value: "GR005",
    label: "Group 5",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL6",
    grpid: "GR006",
    taxpc: "18",
    value: "GR006",
    label: "Group 6",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL7",
    grpid: "GR007",
    taxpc: "12",
    value: "GR007",
    label: "Group 7",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL8",
    grpid: "GR008",
    taxpc: "18",
    value: "GR008",
    label: "Group 8",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL9",
    grpid: "GR009",
    taxpc: "12",
    value: "GR009",
    label: "Group 9",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL10",
    grpid: "GR0010",
    taxpc: "12",
    value: "GR0010",
    label: "Group 10",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL11",
    grpid: "GR0011",
    taxpc: "18",
    value: "GR0011",
    label: "Group 11",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL12",
    grpid: "GR0012",
    taxpc: "12",
    value: "GR0012",
    label: "Group 12",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL13",
    grpid: "GR0013",
    taxpc: "18",
    value: "GR0013",
    label: "Group 13",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL14",
    grpid: "GR0014",
    taxpc: "18",
    value: "GR0014",
    label: "Group 14",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL15",
    grpid: "GR0015",
    taxpc: "12",
    value: "GR0015",
    label: "Group 15",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL16",
    grpid: "GR0016",
    taxpc: "18",
    value: "GR0016",
    label: "Group 16",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL17",
    grpid: "GR0017",
    taxpc: "12",
    value: "GR0017",
    label: "Group 17",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL18",
    grpid: "GR0018",
    taxpc: "18",
    value: "GR0018",
    label: "Group 18",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL19",
    grpid: "GR0019",
    taxpc: "12",
    value: "GR0019",
    label: "Group 19",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL20",
    grpid: "GR0020",
    taxpc: "12",
    value: "GR0020",
    label: "Group 20",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL21",
    grpid: "GR0021",
    taxpc: "18",
    value: "GR0021",
    label: "Group 21",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL22",
    grpid: "GR0022",
    taxpc: "12",
    value: "GR0022",
    label: "Group 22",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL23",
    grpid: "GR0023",
    taxpc: "18",
    value: "GR0023",
    label: "Group 23",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL24",
    grpid: "GR0024",
    taxpc: "18",
    value: "GR0024",
    label: "Group 24",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL25",
    grpid: "GR0025",
    taxpc: "12",
    value: "GR0025",
    label: "Group 25",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL26",
    grpid: "GR0026",
    taxpc: "18",
    value: "GR0026",
    label: "Group 26",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL27",
    grpid: "GR0027",
    taxpc: "12",
    value: "GR0027",
    label: "Group 27",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL28",
    grpid: "GR0028",
    taxpc: "18",
    value: "GR0028",
    label: "Group 28",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL29",
    grpid: "GR0029",
    taxpc: "12",
    value: "GR0029",
    label: "Group 29",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL30",
    grpid: "GR0030",
    taxpc: "12",
    value: "GR0030",
    label: "Group 30",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL31",
    grpid: "GR0031",
    taxpc: "18",
    value: "GR0031",
    label: "Group 31",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL32",
    grpid: "GR0032",
    taxpc: "12",
    value: "GR0032",
    label: "Group 32",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL33",
    grpid: "GR0033",
    taxpc: "18",
    value: "GR0033",
    label: "Group 33",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL34",
    grpid: "GR0034",
    taxpc: "18",
    value: "GR0034",
    label: "Group 34",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL35",
    grpid: "GR0035",
    taxpc: "12",
    value: "GR0035",
    label: "Group 35",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL36",
    grpid: "GR0036",
    taxpc: "18",
    value: "GR0036",
    label: "Group 36",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL37",
    grpid: "GR0037",
    taxpc: "12",
    value: "GR0037",
    label: "Group 37",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL38",
    grpid: "GR0038",
    taxpc: "18",
    value: "GR0038",
    label: "Group 38",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL39",
    grpid: "GR0039",
    taxpc: "12",
    value: "GR0039",
    label: "Group 39",
    remarks: "",
    region: "IND",
  },
  {
    id: "GPL40",
    grpid: "GR0040",
    taxpc: "12",
    value: "GR0040",
    label: "Group 40",
    remarks: "",
    region: "IND",
  },
];

export const Medicine_PriceListWT = [
  //abandoned
  {
    id: "PL1",
    drugid: "R001",
    druggrpid: "GR001",
    drug: "a tab",
    uom: "nos",
    rate: "15.25",
    taxpc: "18",
    dose: "1 daily after food",
    duration: "3weeks",
    remarks: "",
  },
  {
    id: "PL21",
    drugid: "R002",
    druggrpid: "GR001",
    drug: "vit c tab",
    uom: "nos",
    rate: "10.25",
    taxpc: "18",
    dose: "1 daily after food",
    duration: "3weeks",
    remarks: "",
  },
  {
    id: "PL22",
    drugid: "R003",
    druggrpid: "GR001",
    drug: "vit B tab",
    uom: "nos",
    rate: "7.50",
    taxpc: "18",
    dose: "1 daily after food",
    duration: "3weeks",
    remarks: "",
  },
  {
    id: "PL31",
    drugid: "R004",
    druggrpid: "GR002",
    drug: "cough syrup ",
    uom: "bottle",
    rate: "100.90",
    taxpc: "12",
    dose: "thrice daily",
    duration: "3weeks",
    remarks: "",
  },
  {
    id: "PL34",
    drugid: "R005",
    druggrpid: "GR002",
    drug: "cough syrup ",
    uom: "bottle",
    rate: "165.00",
    taxpc: "12",
    dose: "thrice daily",
    duration: "3weeks",
    remarks: "",
  },
];
