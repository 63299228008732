import React from "react";
import ToolbarButton from "./ToolbarButton";
import FormatBoldIcon from "@mui/icons-material/FormatBold";

/**
 * Toolbar button for bold text mark
 *
 * @see ToolbarButton
 */

const BoldButton = React.forwardRef((props, ref) => (
  <ToolbarButton
    icon={<span className="text-color">{<FormatBoldIcon />}</span>}
    type="mark"
    format="bold"
    ref={ref}
    {...props}
  />
));
export default BoldButton;
