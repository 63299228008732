import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import ApprovePolicyList from "../../insurance/ApprovePolicyList";

export default function InsuManageORG() {
  const handleRowsDetail = (policy) => {
    console.log("Approve this ", policy);
  };

  return (
    <Grid container>
      <ApprovePolicyList handleRowsDetail={handleRowsDetail} />
    </Grid>
  );
}
