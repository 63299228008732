import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Link, Typography } from "@material-ui/core";
import { MyFullButtonsColor } from "../CustomComponents/MyButtons";
import Password from "../CustomComponents/Password";
import InputComponent from "../CustomComponents/InputComponent";
import InputActivityRole from "../CustomComponents/InputActivityRole";
import { getSixDigitRandom } from "../utils/random";
import { userIndexQueries } from "../utils/userqueries";
import { userIndex } from "../../redux/user/user.actions";
import { register, verifyEmail } from "../../redux/actions/auth";
import { useFormik } from "formik";
import { userAccountSchema } from "../schema/userSchema";
import log from "../../services/log";

function LoginPage(props) {
  // const auth = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [values, setValues] = useState({});

  const signupform = useFormik({
    initialValues: {
      email: "",
      role: "metauser",
      name: "",
      accountId: "",
      login: "",
      phone: "",
      isEmailVerified: false,
    },
    validationSchema: userAccountSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      log.info("signup", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["email"] = values.email;
      jsonData.metadata["role"] = values.role;
      jsonData["name"] = values.name;
      jsonData["password"] = values.password;

      dispatch(register(jsonData)).then(
        (r) => {
          console.log("signup user", r);
          const newcode = getSixDigitRandom();
          dispatch(
            verifyEmail(
              {
                code: newcode,
                user: {
                  email: r.user.metadata.email,
                  name: r.user.name,
                },
              },
              {
                code: newcode,
                user: r.user,
                action: "verifyemail",
              }
            )
          );

          dispatch(
            userIndex(
              userIndexQueries({
                email: r.user.metadata.email,
                name: r.user.name,
                role: r.user.metadata.role,
                accountId: r.user.metadata.accountId,
              })
            )
          );

          navigate("/signcode");
        },
        (error) => {
          console.log(error);
          setValues({
            ...values,
            error: true,
          });
        }
      );
    },
    onReset: (values) => {},
  });

  const handleClickShowPassword = (e) => {
    signupform.setFieldValue("showpassword", !signupform.values.showpassword);
  };

  const handleClickShowConfirmPassword = (e) => {
    signupform.setFieldValue(
      "showconfirmpassword",
      !signupform.values.showconfirmpassword
    );
  };

  const handlChangeRole = (role) => {
    // log.info('change role ', role);
    signupform.setFieldValue("role", role);
  };

  // log.info("signup form", signupform.values);

  return (
    <>
      <Box display={{ xs: "block", md: "none" }}>
        <h4 className="text-color-primary text-center">Register now</h4>
        <label className="meta__input__label">First name</label>
        <input
          placeholder="Enter your first name"
          className="meta__input__mobile"
        />
        <label className="meta__input__label">Surname</label>
        <input
          placeholder="Enter your surname"
          className="meta__input__mobile"
        />
        <label className="meta__input__label">Mobile Number</label>
        <input
          placeholder="Enter your email address"
          className="meta__input__mobile"
        />
        <label className="meta__input__label">Registered email address</label>
        <input
          placeholder="Enter your email address"
          className="meta__input__mobile"
        />
        <label className="meta__input__label">Password</label>
        <input
          type="password"
          placeholder="Enter your password"
          className="meta__input__mobile"
        />
        <button className="meta__button_green w-full mt-4">Next</button>

        <Typography className="fs-13 text-center mt-3" color="#8F8F8F">
          Already have an account ?
        </Typography>
        <Link
          color="#2A8AA7"
          href="/sign"
          underline="always"
          className="d-block fs-13 text-center text-decoration-underline"
        >
          Sign in
        </Link>
      </Box>
      <Box
        display={{ xs: "none", md: "block" }}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          marginBottom: "0px !important",
        }}
      >
        <center>
          <h3 className="text-color-primary">Sign Up</h3>
        </center>
        <InputComponent
          id="displayName"
          name="name"
          label="Legal Name"
          errors={signupform.errors.name ? true : false}
          variant="standard"
          disabled={false}
          defaultValue={signupform.values.name}
          handleChange={signupform.handleChange}
          errorText={signupform.errors.name}
        />
        {/*<InputComponent*/}
        {/*    id="loginName"*/}
        {/*    name="login"*/}
        {/*    label="Login Name"*/}
        {/*    errors={signupform.errors.login? true: false}*/}
        {/*    variant="standard"*/}
        {/*    handleChange={signupform.handleChange}*/}
        {/*/>*/}
        &nbsp; &nbsp;
        <InputActivityRole
          id="role"
          name="role"
          label="Activity Types"
          values={signupform.values.role}
          errors={signupform.errors.role ? true : false}
          handleChange={handlChangeRole}
        />
        &nbsp; &nbsp;
        <InputComponent
          id="email"
          name="email"
          label="Email"
          errors={signupform.errors.email ? true : false}
          errorText={signupform.errors.email}
          variant="standard"
          disabled={false}
          defaultValue={signupform.values.email}
          handleChange={signupform.handleChange}
        />
        &nbsp; &nbsp;
        <Password
          id="password"
          name="password"
          label="Password"
          errors={signupform.errors.passowrd ? true : false}
          showPassword={signupform.values.showpassword}
          handleClickShowPassword={handleClickShowPassword}
          handleChange={signupform.handleChange}
        />
        &nbsp; &nbsp;
        <Password
          id="confirmpassword"
          name="confirmpassword"
          label="Confirm Password"
          errors={signupform.errors.confirmpassowrd ? true : false}
          errorText={signupform.errors.confirmpassowrd}
          showPassword={signupform.values.showconfirmpassword}
          handleClickShowPassword={handleClickShowConfirmPassword}
          handleChange={signupform.handleChange}
        />
        <Box className="clear">&nbsp; </Box>
        <MyFullButtonsColor
          onClick={signupform.handleSubmit}
          btnKey={"x"}
          btnLabel={"Register"}
          enState={false}
        />
        <Box className="clear">&nbsp; </Box>
        <center>
          Already have an account?
          <Link href="/sign" underline="always" className="text-color">
            SIGN IN
          </Link>
        </center>
      </Box>
    </>
  );
}

export default LoginPage;
