// import { AppointmentIcon, FindCareIcon } from "../components/customIcons";

import {
  AppointmentIcon,
  AuthorizeSharingIcon,
  FindCareIcon,
  HealthInsuranceIcon,
  ImmunizationIcon,
  MedicalRecordsIcon,
  MedicationsIcon,
  MessagesIcon,
  PaymentsIcon,
  TestResultsIcon,
} from "../components/customIcons/PatientDashboard";

export const patientMenuList = [
  {
    _id: 2000,
    title: "Find Care",
    icon: <FindCareIcon size={0.8} />,
    link: "/metauser/find-care",
    active: true,
  },
  {
    _id: 2010,
    title: "Appointments",
    icon: <AppointmentIcon size={0.8} />,
    link: "/metauser/appointments",
    active: true,
  },
  {
    _id: 2020,
    title: "Messages",
    icon: <MessagesIcon size={0.8} />,
    link: "/metauser/messages",
    active: true,
  },
  {
    _id: 2030,
    title: "Test Results",
    icon: <TestResultsIcon size={0.8} />,
    link: "/metauser/test-results",
    active: true,
  },
  {
    _id: 2040,
    title: "Medications",
    icon: <MedicationsIcon size={0.8} />,
    link: "/metauser/medications",
    active: true,
  },
  {
    _id: 2050,
    title: "Immunizations",
    icon: <ImmunizationIcon size={0.8} />,
    link: "/metauser/immunizations",
    active: true,
  },
  {
    _id: 2060,
    title: "Health Insurance",
    icon: <HealthInsuranceIcon size={0.8} />,
    link: "/metauser/health-insurance",
    active: true,
  },
  {
    _id: 2070,
    title: "Medical Records",
    icon: <MedicalRecordsIcon size={0.8} />,
    link: "/metauser/medical-records",
    active: true,
  },
  {
    _id: 2080,
    title: "Payments",
    icon: <PaymentsIcon size={0.8} />,
    link: "/metauser/payments",
    active: true,
  },
  {
    _id: 2090,
    title: "Authorize sharing",
    icon: <AuthorizeSharingIcon size={0.8} />,
    link: "/metauser/authorize-sharing",
    active: true,
  },
];
