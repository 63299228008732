import React from "react";
import { Page, Text, Document } from "@react-pdf/renderer";
import { CommonHeader } from "./CommonHeader";
import { PDFViewer } from "@react-pdf/renderer";

import { CommonAddrAddr } from "./CommonAddrAddr";
import { CommonSerialNo } from "./CommonSerialNo";
import { DrugsItemsTable } from "./DrugsItemsTable";
import { CTestsItemsTable } from "./CTestsItemsTable";
import { RTestsItemsTable } from "./RTestsItemsTable";

import { PrescriptionTitle } from "./PrescriptionTitle";
import { PrescriptionSign } from "./PrescriptionSign";
import { PrescriptionDeclaration } from "./PrescriptionDeclaration";

import { stylesLogo } from "../styles/CommonStyles";

import { tableParam } from "../../common/ConstantsAndValues";
import logo from "../../../assets/images/logo.png";
import logoSm from "../../../assets/images/logoSm.png";

const styles = stylesLogo;
const countTR = tableParam.tableRowsCount ? tableParam.tableRowsCount : 15;

const onRenderDocument = ({ blob, filename }) => {
  var blobUrl = URL.createObjectURL(blob);
  //saveDocument(blobUrl, filename);
};

const Prescription = ({ title, content, eventsmask, getCN }) => (
  <Document
    title="Prescription"
    author="Metalane"
    subject="Online generated document"
    keywords="Metalane online pdf document"
    creator="Metalane  webapp"
    file="precription"
  >
    <Page size="A4" style={styles.page} wrap>
      <CommonHeader logo={logo} invoice={content.parent[0]} />
      <PrescriptionTitle title={title} />
      <CommonSerialNo invoice={content} label1={"Sl No"} label2={"Date"} />
      <CommonAddrAddr
        invoice={content}
        parentAddress={content.doctor[0]}
        label1={""}
        label2={""}
      />
      {(eventsmask?.filter((item) => item.includes("presAll")).length !== 0 ||
        eventsmask?.filter((item) => item.includes("presCN")).length !== 0) && (
        <>
          <Text style={styles.header}>DIAGNOSIS/ CLINICAL NOTES</Text>

          {getCN.children?.map((text, index) => (
            <Text style={styles.paragraph} key={"cn" + index}>
              {text.text.trim()}
            </Text>
          ))}
        </>
      )}
      {(eventsmask?.filter((item) => item.includes("presAll")).length !== 0 ||
        eventsmask?.filter((item) => item.includes("presPD")).length !== 0) && (
        <>
          <Text style={styles.headerNB}>PRESCRIBED MEDICINES</Text>
          <DrugsItemsTable tableRowsCount={0} diags={content.drugs} />
        </>
      )}
      {(eventsmask?.filter((item) => item.includes("presAll")).length !== 0 ||
        eventsmask?.filter((item) => item.includes("presCT")).length !== 0) && (
        <>
          <Text style={styles.headerNB}>CLINICAL TESTS</Text>
          <CTestsItemsTable tableRowsCount={0} diags={content.items} />
        </>
      )}
      {(eventsmask?.filter((item) => item.includes("presAll")).length !== 0 ||
        eventsmask?.filter((item) => item.includes("presRT")).length !== 0) && (
        <>
          <Text style={styles.headerNB}>RADIOLOGICAL INVESTIGATIONS</Text>
          <RTestsItemsTable tableRowsCount={0} diags={content.items} />
        </>
      )}

      {(eventsmask?.filter((item) => item.includes("presAll")).length !== 0 ||
        eventsmask?.filter((item) => item.includes("presCN")).length !== 0 ||
        eventsmask?.filter((item) => item.includes("presPD")).length !== 0 ||
        eventsmask?.filter((item) => item.includes("presCT")).length !== 0 ||
        eventsmask?.filter((item) => item.includes("presRT")).length !== 0) && (
        <>
          <PrescriptionSign
            txtDeclare={content.doctor_declare}
            txt={content.doctor_sign}
          />
          <PrescriptionDeclaration
            txtDeclare={
              "No signature needed being computer generated suggestion for Investigation"
            }
          />
        </>
      )}

      <Text
        style={styles.pageNos}
        render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default Prescription;
