import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTable } from "../styles/InsuranceStyles";
import { InsuranceDetail } from "./InsuranceDetail";
import { InsuranceDetailTableHeader } from "./InsuranceDetailTableHeader";
import {
  InsuranceDetailTableRows,
  InsuranceDetailTableRowsBlank,
} from "./InsuranceDetailTableRows";

const styles = stylesTable;

export const InsuranceDetailTable = ({ diags, tableRowsCount }) => (
  <>
    <View style={styles.tableContainerNoBorder}>
      <InsuranceDetail items={diags} />
    </View>
    <View style={styles.tableContainer}>
      <InsuranceDetailTableHeader />
      <InsuranceDetailTableRows items={diags[0].members} />
      {tableRowsCount !== 0 && (
        <InsuranceDetailTableRowsBlank
          rowsCount={tableRowsCount - diags.length}
        />
      )}
    </View>
  </>
);
