import { useLocation } from "react-router-dom";
import ViewerRouting from "./viewer/routes/ViewerRouting";
import { useContext } from "react";
import { utils } from "./core";
import { DicomContext } from "./viewer/context/DicomContext";
import DicomFile from "./dragdrop/DicomFile";
const { urlUtil: UrlUtil } = utils;

export const DicomImageView = () => {
  const { state } = useLocation();
  console.log("DicomImageViewer", state);
  // const useDicomContext = () => useContext(DicomContext);
  // const { appConfig, activeContexts } = useDicomContext();
  // state.seriesInstanceUIDs = [];

  return (
    <DicomFile>
      <ViewerRouting match={state} location={null} />
    </DicomFile>
  );
};
