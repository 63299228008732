import React, { useEffect, useState, Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Box, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MyButtons } from "../CustomComponents/MyButtons";
import { checkListPrescription } from "../common/ConstantsAndValues";

import { SelectCheckReports } from "../common/SelectCheckReports";

//ASSUMED
import { setReportEventFetchMask } from "./redux/actions";

import log from "../../services/log";

//need////
//set11EventFetchMask    setReportEventFetchMask
//state?.events?.mask   state.reportevents

export const DiagnosticsChoice = (props) => {
  const { handleProceed } = props;

  //ASSUMED
  const reporteventsmask = useSelector((state) => state?.reportevents?.mask);

  const dispatch = useDispatch();

  useEffect(() => {
    // if (checkListPrescription) {
    //     !eventsmask && dispatch(setEventFetchMask({mask:checkListPrescription}));
    // }
  }, []);

  const handleProceedLocal = () => {
    handleProceed();
  };

  return (
    <Fragment>
      <Box>
        <SelectCheckReports
          //eventsmask={reporteventsmask}
          //set11EventFetchMask={setReportEventFetchMask}

          checkList={checkListPrescription}
          title={"My Choice"}
        />
      </Box>

      <Box mt={2} className="colfr">
        <MyButtons
          onClick={handleProceedLocal}
          btnKey={"next"}
          btnLabel={"Proceed"}
          enState={false}
        />
      </Box>
    </Fragment>
  );
};
