import { UserActionTypes } from "./user.types";
import {
  getUserGroupService,
  setUserGroupService,
  updateUserGroupService,
  setPatientListService,
  getUsersService,
  getClientSessionService,
  setClientSessionService,
  updateClientSessionService,
  getMDGroupService,
  setMDGroupService,
  updateMDGroupService,
  getPatientListService,
  getUserAccessList,
  setUserAccessList,
} from "../../services/user-service";

import {
  searchService,
  docSearchService,
  docIndexService,
} from "../../services/location-service";
import log from "../../services/log";
import { getProfileService } from "../../services/auth-service";
import { failure, success } from "../../utils/redux";

/**
 * blockchain
 * @param wallet
 * @returns {{payload, type: string}}
 */
const loadWallet = (wallet) => ({
  type: UserActionTypes.USERWALLET,
  payload: wallet,
});

/**
 * setting states of mainbar menus
 * reducer field selectedPatient
 * @param payload
 * @returns {function(*): *}
 */
const setMainbarStates = (payload) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.MAINBAR_STATES,
    payload: payload,
  });
};

/**
 * selected for action
 * reducer field selectedPatient
 * @param payload
 * @returns {function(*): *}
 */
const selectedPatient = (payload) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.SELECT_PATIENT,
    payload: payload,
  });
};

/**
 * activer user
 * rducer field curretuser
 * @param user
 * @returns {{payload, type: string}}
 */
const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

/**
 * not used yet
 * reducer field userAccount
 * @param account
 * @returns {function(*): *}
 */
const setUserAccount = (account) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.SET_USER_ACCOUNT,
    payload: account,
  });
};

/**
 * no reducer field
 * @param user
 * @returns {function(*): *}
 */

const selectUser = (user) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.SELECT_USER,
    payload: user,
  });
};

/**
 * reducer field -selecteduser
 * @param user
 * @returns {function(*): *}
 */
const selectUserGroup = (user) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.SELECT_USERGROUP,
    payload: user,
  });
};

/**
 * reducer sendtouser
 * @param user
 * @returns {function(*): *}
 */
const sendtoUser = (user) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.SENDTO_USER,
    payload: user,
  });
};

/**
 * reducer field usergroup
 * @param payload
 * @returns {function(*): Promise<unknown>}
 */

const getUserGroup = (payload) => (dispatch) => {
  return getUserGroupService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.USERGROUP,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

/**
 *
 * @param payload
 * @returns {function(*): Promise<unknown>}
 */
const setUserGroup = (payload) => (dispatch) => {
  return setUserGroupService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

/**
 *
 * @param payload
 * @returns {function(*): Promise<unknown>}
 */
const updateUserGroup = (payload) => (dispatch) => {
  return updateUserGroupService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

/**
 *
 * @param payload
 * @returns {function(*): Promise<unknown>}
 */
const setPatientList = (payload) => (dispatch) => {
  return setPatientListService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

/**
 * reducer field - users
 * @param payload
 * @returns {function(*): Promise<unknown>}
 */

const getUsers = (payload) => (dispatch) => {
  // log.info("getting users", payload);
  return getUsersService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.GET_USERS,
        payload: response.data.results,
      });
      // log.info("getting users", response.data);
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

/**
 * reducer fielt puser
 * @param payload
 * @returns {function(*): Promise<unknown>}
 */
const getMDUser = (payload) => (dispatch) => {
  return getUsersService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.GET_MDUSER,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

/**
 * get a patient inforation
 * reducer field - user
 * @param payload
 * @returns {function(*): Promise<unknown>}
 */
const getPatientUser = (payload) => (dispatch) => {
  return getUsersService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.GET_USER,
        payload: response.data.results[0],
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

/**
 * duplicate call
 * @param payload
 * @returns {function(*): Promise<unknown>}
 */

const getPatientList = (payload) => (dispatch) => {
  return getUserGroupService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.GET_USERS,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

const getPatientsList = (payload) => (dispatch) => {
  return getPatientListService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.PATIENTLIST,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

const setClientSession = (payload) => (dispatch) => {
  return setClientSessionService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.CLIENTSESSION,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

const updateClientSession = (payload) => (dispatch) => {
  return updateClientSessionService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.CLIENTSESSION,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

const getClientSession = (payload) => (dispatch) => {
  return getClientSessionService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.CLIENTSESSION,
        payload: response.data.results,
      });
      return Promise.resolve(response.data.results);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

/**
 * reducer field pgrouplist
 * @param payload
 * @returns {function(*): Promise<unknown>}
 */

const getMDGroupList = (payload) => (dispatch) => {
  return getMDGroupService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.MDUSERGROUPLIST,
        payload: response?.data?.results,
      });
      return Promise.resolve(response?.data);
    },
    (error) => {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.message) ||
        error?.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

/**
 * reucer field selectedmdgroup
 * @param data
 * @returns {function(*): *}
 */
const selectMDGroup = (data) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.SELECT_MDUSERGROUP,
    payload: data,
  });
};

const setMDGroup = (payload) => (dispatch) => {
  return setMDGroupService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.MDUSERGROUP,
        payload: response.data.results,
      });
      return Promise.resolve(response.data.results);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

const getMDGroup = (payload) => (dispatch) => {
  return getMDGroupService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.MDUSERGROUP,
        payload: response.data.results,
      });
      return Promise.resolve(response.data.results);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

const updateMDGroup = (payload) => (dispatch) => {
  return updateMDGroupService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.MDUSERGROUP,
        payload: response.data.results,
      });
      return Promise.resolve(response.data.results);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

const getDocDomains = (payload) => (dispatch) => {
  return docSearchService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.DOCDOMAINS,
        payload: response.data.hits,
      });
      return Promise.resolve(response.data.hits);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

const getDocDomain = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.DOCDOMAIN,
        payload: response.data.hits,
      });
      return Promise.resolve(response.data.hits);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

const getDocTypes = (payload) => (dispatch) => {
  return docSearchService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.DOCTYPES,
        payload: response.data.hits,
      });
      return Promise.resolve(response.data.hits);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

/**
 * search list
 * @param payload
 * @returns {function(*): Promise<unknown>}
 */

const getUsersList = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        dispatch({
          type: UserActionTypes.USERS_LIST,
          payload: response?.data?.data.hits,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

export const userIndex = (payload) => (dispatch) => {
  return docIndexService(payload).then(
    (response) => {
      return Promise.resolve(response.data.hits);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

const selectUserProfile = (data) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.USER_PROFILE,
    payload: data,
  });
};

const setDocdomainView = (data) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.DOCDOMAINVIEW,
    payload: data,
  });
};

const setDocOrder = (data) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.DOCORDER,
    payload: data,
  });
};

const selectSpecialist = (data) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.ORGSPECIALIST,
    payload: data,
  });
};

const getPatientProfile = (payload) => (dispatch) => {
  return getProfileService(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.PATIENT_PROFILE,
        payload: response.data.results[0],
      });
      return Promise.resolve(response.data.results);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

const searchUserText = (data) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.SEARCH_TEXT,
    payload: data,
  });
};

const searchUserContext = (data) => (dispatch) => {
  return dispatch({
    type: UserActionTypes.SEARCH_CONTEXT,
    payload: data,
  });
};

/**
for getting the family users list 
*/

const getUserAccessFamilyList = (payload) => (dispatch) => {
  dispatch({
    type: UserActionTypes.GET_USER_ACCESS_LIST,
  });
  return getUserAccessList(payload).then(
    (response) => {
      dispatch({
        type: success(UserActionTypes.GET_USER_ACCESS_LIST),
        payload: response?.data?.data,
      });
      return Promise.resolve(response?.data?.data);
    },
    (error) => {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.message) ||
        error?.message ||
        error.toString();
      log.info("Error user api ", message);
      dispatch({
        type: failure(UserActionTypes.GET_USER_ACCESS_LIST),
      });
      return Promise.reject();
    }
  );
};

/**
for setting the family users list 
*/

const setUserAccessFamilyList = (payload) => (dispatch) => {
  return setUserAccessList(payload).then(
    (response) => {
      dispatch({
        type: UserActionTypes.SET_USER_ACCESS_LIST,
        payload: response?.data?.results,
      });
      return Promise.resolve(response?.data);
    },
    (error) => {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.message) ||
        error?.message ||
        error.toString();
      log.info("Error user api ", message);
      return Promise.reject();
    }
  );
};

export {
  getUsers,
  getUsersList,
  searchUserText,
  setCurrentUser,
  setUserAccount,
  selectUserGroup,
  getUserGroup,
  setUserGroup,
  updateUserGroup,
  setPatientList,
  getPatientList,
  getPatientsList,
  selectedPatient,
  loadWallet,
  setClientSession,
  updateClientSession,
  getClientSession,
  getMDUser,
  selectMDGroup,
  getMDGroup,
  setMDGroup,
  getMDGroupList,
  updateMDGroup,
  getPatientUser,
  sendtoUser,
  getDocDomains,
  getDocDomain,
  setDocdomainView,
  getDocTypes,
  selectUserProfile,
  getPatientProfile,
  setDocOrder,
  searchUserContext,
  selectSpecialist,
  getUserAccessFamilyList,
  setUserAccessFamilyList,
};
