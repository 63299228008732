import React from "react";
import { Box } from "@material-ui/core";

import SpecialistStepper from "./SpecialistStepper";
import { colChart } from "../common/ConstantsAndValues";

export default function DeptAddSpecialists(props) {
  const { handleCancel } = props;

  return (
    <Box
      display="flex"
      width={"100%"}
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" width={colChart.width} justifyContent="left">
        <SpecialistStepper handleDone={handleCancel} />
      </Box>
    </Box>
  );
}
