import * as React from "react";
import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import {
  pageOptions,
  tableParam,
  colWidthSign,
  SpanAnchor,
  SearchBoxStandard,
} from "../common/ConstantsAndValues";

import { labsMetaQueries } from "../utils/labsqueries";
import { getLabsList, selectLabsRow } from "../../redux/labs/labs.actions";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";

import { RListRow } from "./RListUSRRow";
import { RListHead } from "./RListUSRHead";
import log from "../../services/log";
import { getResourceList } from "../../redux/resources/resource.actions";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export function RListUSR(props) {
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const resourcelist = useSelector((state) => state?.resources?.resourcelist);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    // log.info("drug serarch query", searchText);
    const resetpage = { ...pagedata, page: 0 };
  }, [searchcontext, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    const newlist = keyword
      ? resourcelist?.filter((v) =>
          v._source.name.toLowerCase().startsWith(keyword.toLowerCase())
        )
      : resourcelist;
    setRows(newlist);
  };

  const handleChangePage = (event, value) => {
    // log.info("dlist", value);
    // log.info("dlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  // log.info("resource dlist", rows);

  return (
    <Grid container>
      <Grid item xs>
        <div>
          <div
            className="flex-grow-1"
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "36px",
              width: "100%",
            }}
          >
            <div>
              <div className="colfl0"></div>

              <div className="colfr0" width="100%">
                <SearchBoxStandard
                  onChange={handleFilter}
                  placeholder="Search"
                  sx={{ marginRight: 1 }}
                  className="text-color-primary text-color-2-border text-color-2-bg"
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "16px" }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="collapsible table">
                <TableHead className="text-color-primary">
                  <RListHead />
                </TableHead>

                <TableBody className="text-color-primary text text-md">
                  {resourcelist &&
                    resourcelist.map((row) => (
                      <RListRow key={row._source.Id} row={row} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div style={{ width: "100%" }}>
            <TablePaginationStandard
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              pagedata={pagedata}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
