import React from "react";

function VerticalLine(props) {
  const { width, height, color } = props;
  return (
    <div
      style={{
        alignItems: "center",
        padding: "1px",
        width: width,
        height: height,
        backgroundColor: color,
      }}
    />
  );
}

export default VerticalLine;
