//common to metaorg and metapharma
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getOTCDrugs,
  selectOTCDrugList,
} from "../../../redux/drugs/drugs.actions";
import { otcdrugsquery } from "../../utils/drugsqueries";
import {
  pageOptions,
  tableParam,
  colWidthSign,
  SpanAnchor,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import { DrugsListOTCRow } from "./DrugsListOTCRow";
import { DrugsListOTCHead } from "./DrugsListOTCHead";
import log from "../../../services/log";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export function DrugsOTCList(props) {
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const otcdruglist = useSelector((state) => state?.drugs?.otcdrugslist);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (otcdruglist) {
      setRows(otcdruglist);
    } else {
      setRows(null);
    }
  }, [otcdruglist]);

  const buildQuery = (paged) => {
    const searchquery = searchcontext
      ? otcdrugsquery(searchcontext.key, searchcontext.value, paged)
      : otcdrugsquery("name", "a", paged);
    return searchquery;
  };

  useEffect(() => {
    // log.info("drug serarch query", searchText);
    const resetpage = { ...pagedata, page: 0 };
    const searchquery = buildQuery(resetpage);
    dispatch(getOTCDrugs(searchquery)).then((r) => {
      // log.info("drugslist", r)
      if (r.total.value && r.total.value > 0) {
        setPage({
          ...resetpage,
          count: r.total.value,
          pages: r.total.value / pagedata.limit,
        });
      }
    });
  }, [searchcontext, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    const newlist = keyword
      ? otcdruglist.filter((v) =>
          v._source.name.toLowerCase().startsWith(keyword.toLowerCase())
        )
      : otcdruglist;
    setRows(newlist);
  };

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    // log.info("druglist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const searchquery = buildQuery(newpagedata);
    // log.info("druglist nextpage", searchquery);
    dispatch(getOTCDrugs(searchquery)).then((r) => {
      log.info("drugslist", r);
      if (r.total.value && r.total.value > 0) {
        setPage(newpagedata);
      }
    });
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (row, add) => {
    // log.info("Prescribe", row);
    // log.info("Prescribe", add);

    if (row && add === true) {
      // newlist.push(row);
      setCheckedRows(row);
      dispatch(selectOTCDrugList(row));
    } else {
      setCheckedRows(null);
      dispatch(selectOTCDrugList(null));
    }
  };

  // log.info("drugslist", searchText);

  return (
    <Grid container>
      <Grid item xs>
        <div>
          <div
            className="flex-grow-1"
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "36px",
              width: "100%",
            }}
          >
            <div>
              <div className="colfr0" width="100%">
                <SearchBoxStandard
                  onChange={handleFilter}
                  placeholder="drug name"
                  sx={{ marginRight: 1 }}
                  className="text-color-primary text-color-2-border text-color-2-bg"
                />
              </div>
            </div>
          </div>

          <div style={{ width: "100%", marginTop: "16px" }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="collapsible table">
                <TableHead>
                  <DrugsListOTCHead />
                </TableHead>

                <TableBody className="text-color-primary text text-md">
                  {rows &&
                    rows.map((row) => (
                      <DrugsListOTCRow
                        key={row._source["Id"]}
                        row={row}
                        handleRowClick={handleRowClick}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div style={{ width: "100%" }}>
            <TablePaginationStandard
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              pagedata={pagedata}
            />
            {/*
                    <TablePagination
                        className           = "bg-gray border-dbt"
                        width               = "auto"
                        rowsPerPageOptions  = {pageOptions}
                        component           = "div"
                        count               = {pagedata.count}
                        rowsPerPage         = {pagedata.limit}
                        page                = {pagedata.page}
                        onPageChange        = {handleChangePage}
                        onRowsPerPageChange = {handleChangeRows}
                        backIconButtonProps={{
                            'aria-label': '< Previous',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next >',
                        }}
                    />
                    */}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
