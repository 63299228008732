import { locsActionTypes } from "./locs.types";

import {
  getAddressService,
  getLocAutocompService,
  searchService,
  docIndexService,
  getAddressAutocompService,
  getLocByplaceAutocompService,
} from "../../services/location-service";

export const getLocAutocomp = (payload) => (dispatch) => {
  return getLocAutocompService(payload).then(
    (response) => {
      dispatch({
        type: locsActionTypes.ADDRESS_AUTO,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const getLocByplaceAutocomp = (payload) => (dispatch) => {
  return getLocByplaceAutocompService(payload).then(
    (response) => {
      dispatch({
        type: locsActionTypes.ADDRESSBYID_AUTO,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const getAddressAutocomp = (payload) => (dispatch) => {
  return getAddressAutocompService(payload).then(
    (response) => {
      dispatch({
        type: locsActionTypes.ADDRESS_AUTOCOMPLETE,
        payload: response.data.predictions,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const getAddress = (payload) => (dispatch) => {
  return getAddressService(payload).then(
    (response) => {
      console.log(response.data);
      dispatch({
        type: locsActionTypes.ADDRESS_DATA,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const searchRegions = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        dispatch({
          type: locsActionTypes.SEARCH_REGIONS,
          payload: response?.data?.data.hits,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const searchAreas = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response.data.code == 200) {
        dispatch({
          type: locsActionTypes.SEARCH_AREAS,
          payload: response.data.data.hits,
        });
        return Promise.resolve(response.data.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const searchSubregions = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        dispatch({
          type: locsActionTypes.SEARCH_REGIONDATA,
          payload: response?.data?.data.hits,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const locDocIndex = (payload) => (dispatch) => {
  return docIndexService(payload).then(
    (response) => {
      console.log(response.data);
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const updateSubregion = (data) => (dispatch) => {
  dispatch({
    type: locsActionTypes.SEARCH_SUBREGIONS,
    payload: data,
  });
};

export const updateCountry = (data) => (dispatch) => {
  dispatch({
    type: locsActionTypes.SEARCH_COUNTRIES,
    payload: data,
  });
};

export const SelectRegion = (data) => (dispatch) => {
  dispatch({
    type: locsActionTypes.SELECTED_REGION,
    payload: data,
  });
};

export const setNewLocAddress = (data) => (dispatch) => {
  return dispatch({
    type: locsActionTypes.ADDRESS_NEW,
    payload: data,
  });
};

export const setAnchorLocAddress = (data) => (dispatch) => {
  return dispatch({
    type: locsActionTypes.ADDRESS_ANCHOR,
    payload: data,
  });
};

export const SelectSubregion = (data) => (dispatch) => {
  dispatch({
    type: locsActionTypes.SELECTED_SUBREGION,
    payload: data,
  });
};

export const SelectCountry = (data) => (dispatch) => {
  dispatch({
    type: locsActionTypes.SELECTED_COUNTRY,
    payload: data,
  });
};

export const selectArea = (data) => (dispatch) => {
  dispatch({
    type: locsActionTypes.SELECTED_AREA,
    payload: data,
  });
};

export const setDeviceInfo = (data) => (dispatch) => {
  dispatch({
    type: locsActionTypes.DEVICEINFO,
    payload: data,
  });
};
