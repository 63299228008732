import { useDispatch, useSelector } from "react-redux";
import * as React from "react";
import { useEffect, useState } from "react";
import { searchAreas, selectArea } from "../../redux/actions/locs";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@mui/material";
import Select from "react-select";
import { LocMSStyles } from "./LocMSStyles";
import { subregionquery } from "../utils/elasticqueries";

export function AreaSelectLoc(props) {
  const { title } = props;
  const areas = useSelector((state) => state.locs.areas);
  const area = useSelector((state) => state.locs.area);
  const country = useSelector((state) => state.locs.country);

  const [selOption, setSelOption] = useState(
    area ? { label: area._source["name"], value: area } : null
  );

  const [options, setOptions] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (areas) {
      const toptions = [];
      areas.forEach((a) => {
        toptions.push({ label: a._source["name"], value: a });
      });
      setOptions(toptions);
    }
  }, [areas]);

  useEffect(() => {
    if (country) {
      dispatch(
        searchAreas(subregionquery("country", country._source["alpha-2"]))
      );
    }
  }, [country]);

  const handleSelect = (event) => {
    // console.log('selectedarea', event);
    setSelOption(event);
    dispatch(selectArea(event.value));
  };

  return (
    <FormControl
      fullWidth
      className="text-color-primary"
      variant="outlined"
      size="small"
    >
      &nbsp;
      <InputLabel
        sx={{ minWidth: 20 }}
        variant="standard"
        htmlFor="uncontrolled-native"
      >
        {title}
      </InputLabel>
      <Box className="text-color">
        <Select
          isDisabled={country ? false : true}
          options={options}
          value={selOption}
          placeholder={"Select " + title}
          onChange={handleSelect}
          isSearchable={true}
          styles={LocMSStyles}
          menuPlacement="auto"
          menuPosition="fixed"
          isMulti={false}
        />
      </Box>
    </FormControl>
  );
}
