import React from "react";
import { useMetaTheme } from "../theme/ThemeContext";
import log from "../../services/log";
import { Button, Link, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { styled } from "@material-ui/core";
import { metaThemeConstants } from "../theme/metaPalletes";

const HoverBoundaryButton = styled(Button)((props) => ({
  padding: "10px 10px",
  borderColor: props.theme?.pallete?.gray?.dark,
  "&:hover": {
    // backgroundColor: props.theme.palette.primary.light,
    borderColor: "rgba(0, 0, 0, 0.8)",
    backgroundColor: "primary",
    borderWidth: "1px",
    borderStyle: "solid",
  },
  "&:focus": { border: 0 },
}));

const ThemeToggleButton = () => {
  const [selected, setSelected] = React.useState(false);
  const { theme } = useMetaTheme();
  // log.info("theme ", muitheme.palette.primary.light);

  const handleClick = () => {
    setSelected(!selected);
  };

  log.info("color: ", theme.palette.primary.main);

  return (
    <ThemeProvider theme={theme}>
      <HoverBoundaryButton
        props={theme}
        size={"large"}
        variant="contained"
        disableRipple
        color={selected ? "secondary" : "gray"}
        onClick={handleClick}
      >
        Switch to this button
      </HoverBoundaryButton>
    </ThemeProvider>
  );
};

export default ThemeToggleButton;
