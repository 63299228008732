export const config_profileAnalytics = {
  systolic: {
    title: "BP Systolic",
    avatar: "BPS",
    innerLabel: [
      {
        text: "normal",
        position: "INSIDE",
        color: "#555",
        fontSize: "12px",
      },
      {
        text: "elevated",
        position: "INSIDE",
        color: "#555",
        fontSize: "12px",
      },
      {
        text: "PHT",
        position: "INSIDE",
        color: "#555",
        fontSize: "12px",
      },
      {
        text: "HT1",
        position: "INSIDE",
        color: "#555",
        fontSize: "12px",
      },
      {
        text: "HT2",
        position: "INSIDE",
        color: "#555",
        fontSize: "12px",
      },
    ],
    outerLabel: [
      {
        text: "< 120",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "121-129",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "130-139",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "140-180",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "> 180",
        position: "OUTSIDE",
        color: "#555",
      },
    ],

    segmentStops: [0, 120, 129, 139, 180, 200],
    segmentColors: [
      "#3ce30e",
      "#d6d440",
      "#FF9933",
      "#e36e0e",
      "#FF5733",
      "#FF5733",
    ],

    values: {
      toggleStatus: false,
      value: 20,
      segments: 5,
      width: 360,
      height: 220,
      ringWidth: 90,
    },
  },

  diastolic: {
    title: "BP Distolic",
    avatar: "BPD",
    innerLabel: [
      {
        text: "normal",
        position: "INSIDE",
        color: "#555",
        fontSize: "12px",
      },
      {
        text: "elevated",
        position: "INSIDE",
        color: "#555",
        fontSize: "12px",
      },
      {
        text: "Pre-HT",
        position: "INSIDE",
        color: "#555",
        fontSize: "12px",
      },
      {
        text: "HT1",
        position: "INSIDE",
        color: "#555",
        fontSize: "12px",
      },
      {
        text: "HT2",
        position: "INSIDE",
        color: "#555",
        fontSize: "12px",
      },
    ],
    outerLabel: [
      {
        text: "< 80",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "< 80",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "80-89",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "90-120",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "> 120",
        position: "OUTSIDE",
        color: "#555",
      },
    ],

    segmentStops: [0, 80, 89, 90, 120, 140],
    segmentColors: [
      "#3ce30e",
      "#d6d440",
      "#FF9933",
      "#e36e0e",
      "#FF5733",
      "#FF5733",
    ],
    values: {
      toggleStatus: false,
      value: 20,
      segments: 5,
      width: 360,
      height: 220,
      ringWidth: 90,
    },
  },
  bmi: {
    title: "Body Mass Index",
    avatar: "BMI",
    innerLabel: [
      {
        text: "UW",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "UW",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "UW",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "N",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "OW",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "O",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "EO",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "EO",
        position: "INSIDE",
        color: "#555",
      },
    ],

    outerLabel: [
      {
        text: "0-16",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "16-17",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "17-18.5",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "18.5-25",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "25-30",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "30-35",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: "35-40",
        position: "OUTSIDE",
        color: "#555",
      },
      {
        text: ">40",
        position: "OUTSIDE",
        color: "#555",
      },
    ],

    values: {
      toggleStatus: false,
      value: 40,
      segments: 8,
      width: 360,
      height: 220,
      ringWidth: 80,
    },

    segmentStops: [0, 16, 17, 18.5, 25, 30, 35, 40, 50],
    segmentColors: [
      "#e64d00",
      "#ffc299",
      "#ffd633",
      "#39ac39",
      "#ffd633",
      "#ffc299",
      "#ff5500",
      "#e64d00",
    ],
  },
};

export default config_profileAnalytics;
