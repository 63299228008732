import React, { useEffect, useState } from "react";

const MedicalRecordsIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
    >
      <rect x="0.242188" y="18.7188" width="17.1343" height="2" rx="1" />
      <rect x="0.242188" y="0.71875" width="17.1343" height="2" rx="1" />
      <rect
        x="2.24219"
        y="0.71875"
        width="20"
        height="2"
        rx="1"
        transform="rotate(90 2.24219 0.71875)"
      />
      <rect
        x="17.375"
        y="0.71875"
        width="20"
        height="2"
        rx="1"
        transform="rotate(90 17.375 0.71875)"
      />
      <rect x="5.8125" y="14.4297" width="6" height="1.16013" rx="0.580067" />
      <rect x="5.8125" y="10.1406" width="6" height="1.16013" rx="0.580067" />
      <rect x="5.8125" y="5.85156" width="6" height="1.16013" rx="0.580067" />
    </svg>
  );
};
export default MedicalRecordsIcon;
