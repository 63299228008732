import React, { useEffect, useState } from "react";
import { Grid, Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import { departmentIndexSchema } from "../schema/departmentSchema";
import { locDocIndex } from "../../redux/actions/locs";
import { locIndexQueries } from "../utils/locationmap";
import { colChart } from "../common/ConstantsAndValues";
import { useDispatch, useSelector } from "react-redux";
import { Title } from "../common/Title";
import { MyTooltipButtons, MyHelpButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";

export default function DepartmentIndex(props) {
  const { cancelEventPage, title } = props;

  const departments = useSelector((state) => state.loinc.departments);
  const orgroup = useSelector((state) => state.orgs.selectorggroup);
  const location = useSelector((state) => state.addresses.selectaddress);
  const orgdepartment = useSelector((state) => state.orgs.selectdepartment);

  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );

  const [selectedOptions, setSelectedOptions] = useState(null);
  const [options, setOptions] = useState(null);

  const deptindexform = useFormik({
    initialValues: {
      orgId: orgroup ? orgroup.metadata.orgId : null,
      groupId: orgroup ? orgroup.metadata.accountId : null,
      locId: location ? location.metadata.accountId : null,
      departmentId: orgdepartment ? orgdepartment.accountId : null,
      providerId: user ? user.metadata.accountId : null,
      name: orgdepartment ? orgdepartment.departmentName : null,
      role: user ? user.metadata.role : null,
      address: location ? location.formatted_address : null,
      services: orgdepartment ? orgdepartment.departmentDescp : null,
      location: location ? location.location : null,
    },
    validationSchema: departmentIndexSchema,
    validateOnChange: true,
    validateOnMount: true,

    onSubmit: (values) => {
      // log.info("medicineinput", JSON.stringify(values, null, 2));

      const data = {};
      data.providerId = values.providerId;
      data.departmentId = values.departmentId;
      data.name = values.name;
      data.role = values.role;
      data.location = values.location;
      data.address = values.address;
      data.services = values.services;

      // log.info("department info", locIndexQueries(data));

      dispatch(locDocIndex(locIndexQueries(data)));
      cancelEventPage();
    },
    onReset: (values) => {},
  });

  const dispatch = useDispatch();

  const MSStyles = {
    control: (styles) => ({ ...styles }),
    container: (styles) => ({ ...styles, width: colChart.width }),
    option: (styles) => ({ ...styles }),
    // menu:      styles => ({ ...styles,  width: colChart.width }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: colChart.width,
    }),
  };

  // log.info("department input", location);
  // log.info("deptindexform", deptindexform.values);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%", md: "60%", lg: "50%" },
        }}
      >
        <Box mb={1}>
          <div className="colfl0">
            <Title>{title ? title : ""} </Title>
          </div>
          <div className="colfr0">
            <MyTooltipButtons
              onClick={cancelEventPage}
              btnKey={"cancel"}
              btnLabel={"Cancel"}
              enState={false}
            />
          </div>
        </Box>
        <Box className="clear"> </Box>
        <Box className="text-color">
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Department"
            defaultValue={deptindexform.values.name}
            error={deptindexform.errors.name ? true : false}
            fullWidth
            variant="standard"
            // onChange={appointmentForm.handleChange}
          />
        </Box>
        <Box className="clear" />
        <Box mt={2} pt={2} className="border-dbt">
          <Box className="colfr0">
            <MyHelpButtons
              onClick={deptindexform.handleSubmit}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
              btnTooltip={"Add"}
            />
          </Box>
          <Box className="clear" />
        </Box>
        <Box className="clear" />
      </Grid>
    </Grid>
  );
}
