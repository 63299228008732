import React, { useEffect, useState } from "react";

const CalendarIcon = (props) => {
  const { height = "40", width = "36", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.273438"
        y="18"
        width="19.6356"
        height="2"
        rx="1"
      />
      <rect
        x="0.273438"
        y="6"
        width="19.6356"
        height="2"
        rx="1"
      />
      <rect
        x="0.273438"
        y="2.32812"
        width="19.6356"
        height="2"
        rx="1"
        fill="#404040"
      />
      <rect
        x="2.09375"
        y="2.32812"
        width="17.6758"
        height="2"
        rx="1"
        transform="rotate(90 2.09375 2.32812)"
      />
      <rect
        x="6.5"
        y="0.265625"
        width="5.15332"
        height="2"
        rx="1"
        transform="rotate(90 6.5 0.265625)"
      />
      <rect
        x="15.5078"
        y="0.265625"
        width="5.15332"
        height="2"
        rx="1"
        transform="rotate(90 15.5078 0.265625)"
      />
      <rect
        x="19.9141"
        y="2.32812"
        width="17.6758"
        height="2"
        rx="1"
        transform="rotate(90 19.9141 2.32812)"

      />
      <path
        d="M6.29779 13.0772C6.59069 12.7843 7.06556 12.7843 7.35846 13.0772L8.92283 14.6416C9.21573 14.9345 9.21573 15.4094 8.92283 15.7022C8.62994 15.9951 8.15507 15.9951 7.86217 15.7022L6.2978 14.1379C6.0049 13.845 6.0049 13.3701 6.29779 13.0772Z"
        fill="#404040"
      />
      <rect
        x="7.35156"
        y="15.1719"
        width="8.23769"
        height="1.5"
        rx="0.75"
        transform="rotate(-45 7.35156 15.1719)"
      />
    </svg>
  );
};
export default CalendarIcon;
