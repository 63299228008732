import React from "react";
import ToolbarButton from "./ToolbarButton";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";

/**
 * Toolbar button for italic text mark
 *
 * @see ToolbarButton
 */

const ItalicButton = React.forwardRef((props, ref) => (
  <ToolbarButton
    icon=<span className="text-color">{<FormatItalicIcon />}</span>
    type="mark"
    format="italic"
    ref={ref}
    {...props}
  />
));
export default ItalicButton;
