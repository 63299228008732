import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

export default function InfoManagePHA() {
  return {
    /*<DeptManagePHA />*/
  };
}
