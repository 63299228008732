import * as React from "react";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
} from "@mui/material";
import { Button, Box, Paper, Typography } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../common/ConstantsAndValues";

import { getReportData } from "../../redux/actions/reports";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightTwoToneIcon from "@mui/icons-material/KeyboardArrowRightTwoTone";

export const pTable = {
  title: {
    label: "Title",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  descrip: {
    label: "Description",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  orderby: {
    label: "Approved by",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  completedAt: {
    label: "Recorded on",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
};

export function DiaglabListHead() {
  const table = pTable;
  return (
    <React.Fragment>
      <StyledTableRow style={{ fontWeight: 700 }}>
        <StyledTableCell />
        <StyledTableCell>{table.title.label}</StyledTableCell>
        <StyledTableCell align="right">{table.descrip.label}</StyledTableCell>
        <StyledTableCell align="right">{table.orderby.label}</StyledTableCell>
        <StyledTableCell align="right">
          {table.completedAt.label}
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export function Rowxxxxxx(props) {
  const { row, handleRowClick } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(false);

  const handleRowClickHere = (e) => {
    setSelectedRow(!selectedRow);
    handleRowClick(e);
  };

  return (
    <React.Fragment>
      <StyledTableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
        onClick={() => handleRowClickHere(row.id)}
      >
        <StyledTableCell width="24px">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <b>{row.medicineName}</b>
        </StyledTableCell>
        <StyledTableCell align="right">
          <Typography variant={"body2"}>{}</Typography>
        </StyledTableCell>
        <StyledTableCell align="right">{row.doctorName}</StyledTableCell>
        <StyledTableCell align="right">{row.updated_at}</StyledTableCell>
      </StyledTableRow>

      <TableRow className="text-color-gray bg-standard-offwhite">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <Box itemID={"refill"} pt={2}>
                <Button
                  variant="contained"
                  endIcon={<KeyboardArrowRightTwoToneIcon />}
                >
                  {" Refill "}
                </Button>
              </Box>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                className="text-color-primary"
              >
                Historical data
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow className="bg-gray">
                    <TableCell>Date</TableCell>
                    <TableCell>Approved by</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history &&
                    row.history.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.date}
                        </TableCell>
                        <TableCell>{historyRow.orderby}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// Row.propTypes = {
//     row: PropTypes.shape({
//         title: PropTypes.string.isRequired,
//         history: PropTypes.arrayOf(
//             PropTypes.shape({
//                 date:    PropTypes.string.isRequired,
//                 orderby: PropTypes.string.isRequired,
//             }),
//         ).isRequired,
//     }).isRequired,
// };

export function createMedicineData(
  title,
  descrip,
  orderby,
  recordedAt,
  history
) {
  return {
    title,
    descrip,
    orderby,
    recordedAt,
    history: history,
  };
}

export function getDateString(d) {
  return new Date(d * 1000).toLocaleString();
}
