import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getLocByplaceAutocomp } from "../../redux/actions/locs";
import AnchorAutocomplete from "../location/AnchorAutoComplete";
import InputComponent from "../CustomComponents/InputComponent";
import { ProviderListAppt } from "./ProviderListAppt";
import log from "../../services/log";

export const ProviderApptSearch = (props) => {
  const { cancel, department, handleStepDone } = props;
  const [distance, setDistance] = useState(2);

  const newaddress = useSelector((state) => state?.locs?.anchoraddress);
  const address = useSelector((state) => state?.addresses?.selectaddress);
  const country = useSelector((state) => state?.locs?.country);
  const searchtext = useSelector((state) => state?.user?.searchtext);

  const dispatch = useDispatch();

  const [searchCenter, setSearchCenter] = useState("");

  useEffect(() => {
    // log.info("neworgaddress", newaddress);
    if (newaddress) {
      const jsonData = {};
      jsonData["placeId"] = newaddress?.value.place_id;
      jsonData["country"] = country?._source["alpha-2"];
      dispatch(getLocByplaceAutocomp(jsonData));
    }
  }, [newaddress]);

  useEffect(() => {
    if (newaddress) {
      setSearchCenter(newaddress?.label);
    }
  }, [newaddress]);

  useEffect(() => {
    if (address) {
      setSearchCenter(address?.formatted_address);
    }
  }, [address]);

  const handleCancel = () => {
    cancel();
  };

  const handleChange = (e) => {
    setDistance(e.target.value);
  };

  // log.info("providerssearch", searchtext);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        className="flex-grow-1"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
        }}
      >
        <Box
          mr={2}
          className="flex-grow-1"
          sx={{ width: { xs: "100%", md: "50%", lg: "50%" } }}
        >
          <AnchorAutocomplete title={"Location (locality, city)"} />
        </Box>
        <Box
          mr={2}
          className="flex-grow-1"
          sx={{ width: { xs: "100%", md: "50%", lg: "50%" } }}
        >
          <InputComponent
            id="distance"
            name="distance"
            label="Distance (Radius in Km.)"
            errors={false}
            errorText={""}
            defaultValue={distance}
            variant="standard"
            disabled={false}
            handleChange={handleChange}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <div className={"border-db btn-round"} style={{ padding: "16px" }}>
          Search location center -
          <span className="text-color-primary">{searchCenter}</span>
        </div>
      </Grid>
      <Grid item xs={12}>
        <ProviderListAppt
          cancel={handleCancel}
          searchText={searchtext}
          distance={distance}
          department={department}
          handleStepDone={handleStepDone}
        />
      </Grid>
    </Grid>
  );
};
