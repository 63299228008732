import React from "react";
import { Grid, Box, Typography, Container, Stack } from "@mui/material";

import { Gen400Container } from "../components/common/ConstantsAndValues";
import SignIn from "../components/sign/sign-in";
import { Navigate } from "react-router-dom";

export const SignInPage = ({ auth }) => {
  const isAuthorized =
    auth?.user?.user?.metadata?.role === "metasys" ||
    auth?.user?.user?.metadata?.role === "metauser";
  // console.log(props, "props");
  return isAuthorized ? (
    <Navigate to="/metauser/home" />
  ) : (
    <Gen400Container className="py-5 px-3 px-md-0">
      <SignIn />
    </Gen400Container>
  );
};
