import { Box, Typography } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";

export default function PrivacyPolicy() {
  return (
    <Box sx={{ p: { xs: 4, md: 8 } }}>
      <Typography sx={{ fontSize: "42px", color: "#404040" }}>
        Privacy Policy
      </Typography>

      <Typography sx={{ my: 2 }}>
        {" "}
        This privacy notice for Metalane Inc. ("<b>we</b>," "<b>us</b>," or "
        <b>our</b>"), describes how and why we might collect, store, use, and/or
        share ("<b>process</b>") your information when you use our services ("
        <b>Services</b>"), such as when you:
        <ul className="mt-2">
          <li>
            Visit our website at{" "}
            <Link to="/" className="link-blue">
              https://www.metalane.ai
            </Link>
            , or any website of ours that links to this privacy notice
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>
        <b>Questions or concerns?</b> Reading this privacy notice will help you
        understand your privacy rights and choices. If you do not agree with our
        policies and practices, please do not use our Services. If you still
        have any questions or concerns, please contact us at
        privacy@metalane.ai.
      </Typography>
      <Typography variant="h6">SUMMARY OF KEY POINTS</Typography>
      <Typography sx={{ my: 2 }}>
        <b>
          <i>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our{" "}
            <Link className="link-blue" to="/privacypolicy#tablecontents">
              {"table of contents "}
            </Link>
            below to find the section you are looking for.
          </i>
        </b>
        <br />
        <br />
        <b>What personal information do we process?</b> When you visit, use, or
        navigate our Services, we may process personal information depending on
        how you interact with us and the Services, the choices you make, and the
        products and features you use. Learn more about{" "}
        <Link className="link-blue" to="/privacypolicy#personalinfo">
          personal information you disclose to us.
        </Link>
        <br />
        <br />
        <b>Do we process any sensitive personal information?</b> We may process
        sensitive personal information when necessary with your consent or as
        otherwise permitted by applicable law. Learn more about{" "}
        <Link className="link-blue" to="/privacypolicy#sensitiveinfo">
          sensitive information we process.
        </Link>
        <br />
        <br />
        <b>Do we receive any information from third parties?</b> We do not
        receive any information from third parties.
        <br />
        <br />
        <b>How do we process your information?</b> We process your information
        to provide, improve, and administer our Services, communicate with you,
        for security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent. We
        process your information only when we have a valid legal reason to do
        so. Learn more about{" "}
        <Link className="link-blue" to="/privacypolicy#processinfo">
          how we process your information.
        </Link>
        <br /> <br />
        <b>
          In what situations and with which types of parties do we share
          personal information?
        </b>{" "}
        We may share information in specific situations and with specific
        categories of third parties. Learn more about{" "}
        <Link className="link-blue" to="/privacypolicy#whenwhom">
          when and with whom we share your personal information.
        </Link>
        <br /> <br />
        <b>How do we keep your information safe?</b> We have organizational and
        technical processes and procedures in place to protect your personal
        information. However, no electronic transmission over the internet or
        information storage technology can be guaranteed to be 100% secure, so
        we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorized third parties will not be able to defeat our security and
        improperly collect, access, steal, or modify your information. Learn
        more about{" "}
        <Link className="link-blue" to="/privacypolicy#safeinfo">
          how we keep your information safe.
        </Link>
        <br /> <br />
        <b>What are your rights?</b> Depending on where you are located
        geographically, the applicable privacy law may mean you have certain
        rights regarding your personal information. Learn more about{" "}
        <Link className="link-blue" to="/privacypolicy#privacyrights">
          your privacy rights.
        </Link>
        <br /> <br />
        <b>How do you exercise your rights?</b> The easiest way to exercise your
        rights is by submitting a data subject access request, or by contacting
        us. We will consider and act upon any request in accordance with
        applicable data protection laws.
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="tablecontents">
        TABLE OF CONTENTS
      </Typography>
      <Typography>
        <ol>
          <li>
            <Link to="/privacypolicy#sectionOne" className="link-blue">
              WHAT INFORMATION DO WE COLLECT?
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy#sectionTwo" className="link-blue">
              HOW DO WE PROCESS YOUR INFORMATION?
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy#sectionThree" className="link-blue">
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy#sectionFour" className="link-blue">
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy#sectionFive" className="link-blue">
              HOW LONG DO WE KEEP YOUR INFORMATION?
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy#sectionSix" className="link-blue">
              HOW DO WE KEEP YOUR INFORMATION SAFE?
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy#sectionSeven" className="link-blue">
              DO WE COLLECT INFORMATION FROM MINORS?
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy#sectionEight" className="link-blue">
              WHAT ARE YOUR PRIVACY RIGHTS?
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy#sectionNine" className="link-blue">
              CONTROLS FOR DO-NOT-TRACK FEATURES
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy#sectionTen" className="link-blue">
              DO WE MAKE UPDATES TO THIS NOTICE?
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy#sectionEleven" className="link-blue">
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy#sectionTwelve" className="link-blue">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </Link>
          </li>
        </ol>
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="sectionOne">
        1. WHAT INFORMATION DO WE COLLECT?
      </Typography>
      <Typography sx={{ my: 2 }}>
        <b>
          <i>In Short: </i>
        </b>{" "}
        We collect personal information that you provide to us.
      </Typography>
      <Typography sx={{ my: 2 }}>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </Typography>
      <Typography>
        <b id="personalinfo">Personal Information Provided by You.</b> The
        personal information that we collect depends on the context of your
        interactions with us and the Services, the choices you make, and the
        products and features you use. The personal information we collect may
        include the following:
        <br />
        <ul>
          <li>names</li>
          <li>phone numbers</li>
          <li>email addresses</li>
          <li>usernames</li>
          <li>passwords</li>
          <li>contact preferences</li>
          <li>contact or authentication data</li>
          <li>billing addresses</li>
          <li>debit/credit card numbers</li>
        </ul>
        Sensitive Information. When necessary, with your consent or as otherwise
        permitted by applicable law, we process the following categories of
        sensitive information:
        <br />
        <ul>
          <li>health data</li>
          <li>financial data</li>
          <li>social security numbers or other government identifiers</li>
        </ul>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="sensitiveinfo">
        Information automatically collected
      </Typography>
      <Typography>
        <b>
          <i>In Short: </i>
        </b>{" "}
        Some information - such as your Internet Protocol (IP) address and/or
        browser and device characteristics - is collected automatically when you
        visit our Services.
        <br />
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.
        <br />
        Like many businesses, we also collect information through cookies and
        similar technologies.
        <br />
        The information we collect includes:
        <br />
        <ul>
          <li>
            <b>Log and Usage Data.</b> Log and usage data is service-related,
            diagnostic, usage, and performance information our servers
            automatically collect when you access or use our Services and which
            we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser
            type, and settings and information about your activity in the
            Services (such as the date/time stamps associated with your usage,
            pages and files viewed, searches, and other actions you take such as
            which features you use), device event information (such as system
            activity, error reports (sometimes called "crash dumps"), and
            hardware settings).
          </li>
          <li>
            <b>Device Data.</b> We collect device data such as information about
            your computer, phone, tablet, or other device you use to access the
            Services. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </li>
          <li>
            <b>Location Data.</b> We collect location data such as information
            about your device's location, which can be either precise or
            imprecise. How much information we collect depends on the type and
            settings of the device you use to access the Services. For example,
            we may use GPS and other technologies to collect geolocation data
            that tells us your current location (based on your IP address). You
            can opt out of allowing us to collect this information either by
            refusing access to the information or by disabling your Location
            setting on your device. However, if you choose to opt out, you may
            not be able to use certain aspects of the Services.
          </li>
        </ul>
      </Typography>
      <Typography variant="h6" id="sectionTwo">
        2. HOW DO WE PROCESS YOUR INFORMATION?
      </Typography>
      <Typography>
        <b>
          <i>In Short: </i>
        </b>{" "}
        We process your information to provide, improve, and administer our
        Services, communicate with you, for security and fraud prevention, and
        to comply with law. We may also process your information for other
        purposes with your consent.
        <br />
        <br />
        <b id="processinfo">
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </b>
        <ul>
          {" "}
          <li>
            <b>
              To facilitate account creation and authentication and otherwise
              manage user accounts.
            </b>{" "}
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </li>
          <li>
            <b>To deliver and facilitate delivery of services to the user.</b>{" "}
            We may process your information to provide you with the requested
            service.
          </li>
          <li>
            <b>To respond to user inquiries/offer support to users.</b> We may
            process your information to respond to your inquiries and solve any
            potential issues you might have with the requested service.
          </li>
          <li>
            <b>To fulfill and manage your orders.</b> We may process your
            information to fulfill and manage your orders, payments, returns,
            and exchanges made through the Services.
          </li>
          <li>
            <b>To enable user-to-user communications.</b> We may process your
            information if you choose to use any of our offerings that allow for
            communication with another user.
          </li>
          <li>
            <b>To request feedback.</b> We may process your information when
            necessary to request feedback and to contact you about your use of
            our Services.
          </li>
          <li>
            <b>To send you marketing and promotional communications.</b> We may
            process the personal information you send to us for our marketing
            purposes, if this is in accordance with your marketing preferences.
            You can opt out of our marketing emails at any time. For more
            information, see "
            <Link className="link-blue" to="/privacyPolicy#sectionEight">
              WHAT ARE YOUR PRIVACY RIGHTS?
            </Link>
            " below.
          </li>
          <li>
            <b>To protect our Services.</b> We may process your information as
            part of our efforts to keep our Services safe and secure, including
            fraud monitoring and prevention.
          </li>
          <li>
            <b>
              To evaluate and improve our Services, products, marketing, and
              your experience.
            </b>{" "}
            We may process your information when we believe it is necessary to
            identify usage trends, determine the effectiveness of our
            promotional campaigns, and to evaluate and improve our Services,
            products, marketing, and your experience.
          </li>
          <li>
            <b>To identify usage trends.</b> We may process information about
            how you use our Services to better understand how they are being
            used so we can improve them.
          </li>
          <li>
            <b>To comply with our legal obligations.</b> We may process your
            information to comply with our legal obligations, respond to legal
            requests, and exercise, establish, or defend our legal rights.
          </li>
        </ul>{" "}
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="sectionThree">
        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </Typography>
      <Typography>
        <b>
          <i>In Short: </i>
        </b>{" "}
        We may share information in specific situations described in this
        section and/or with the following categories of third parties.
        <br />
        <br />
        <b id="whenwhom">
          Vendors, Consultants, and Other Third-Party Service Providers.
        </b>{" "}
        We may share your data with third-party vendors, service providers,
        contractors, or agents ("<b>third parties</b>") who perform services for
        us or on our behalf and require access to such information to do that
        work. The categories of third parties we may share personal information
        with are as follows:
        <br />
        <ul>
          <li>Data Analytics Services</li>
          <li>Order Fulfillment Service Providers</li>
          <li>Payment Processors</li>
          <li>Sales & Marketing Tools</li>
          <li>Testing Tools</li>
          <li>User Account Registration & Authentication Services</li>
          <li>Website Hosting Service Providers</li>
        </ul>
        <br />
        We also may need to share your personal information in the following
        situations:
        <ul>
          <li>
            <b>Business Transfers.</b> We may share or transfer your information
            in connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company.
          </li>
          <li>
            <b>When we use Google Maps Platform APIs.</b> We may share your
            information with certain Google Maps Platform APIs (e.g., Google
            Maps API, Places API). We obtain and store on your device ("cache")
            your location. You may revoke your consent anytime by contacting us
            at the contact details provided at the end of this document.
          </li>
          <li>
            <b>Business Partners.</b> We may share your information with our
            business partners to offer you certain products, services, or
            promotions.
          </li>
        </ul>{" "}
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="sectionFour">
        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </Typography>
      <Typography>
        <b>
          <i>In Short: </i>
        </b>{" "}
        We may use cookies and other tracking technologies to collect and store
        your information.
        <br />
        <br />
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="sectionFive">
        5. HOW LONG DO WE KEEP YOUR INFORMATION?
      </Typography>
      <Typography>
        <b>
          <i>In Short: </i>
        </b>{" "}
        We keep your information for as long as necessary to fulfill the
        purposes outlined in this privacy notice unless otherwise required by
        law.
        <br />
        <br />
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than the period
        of time in which users have an account with us.
        <br />
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
        <br />
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="sectionSix">
        6. HOW DO WE KEEP YOUR INFORMATION SAFE?
      </Typography>
      <Typography id="safeinfo">
        <b>
          <i>In Short: </i>
        </b>{" "}
        We aim to protect your personal information through a system of
        organizational and technical security measures.
        <br />
        <br />
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="sectionSeven">
        7. DO WE COLLECT INFORMATION FROM MINORS?
      </Typography>
      <Typography>
        <b>
          <i>In Short: </i>
        </b>{" "}
        We do not knowingly collect data from or market to children under 18
        years of age. <br /> <br />
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Services, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent's use of the Services. If we learn that personal
        information from users less than 18 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
        may have collected from children under age 18, please contact us at
        privacy@metalane.ai.
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="sectionEight">
        8. WHAT ARE YOUR PRIVACY RIGHTS?
      </Typography>
      <Typography id="privacyrights">
        <b>
          <i>In Short: </i>
        </b>{" "}
        You may review, change, or terminate your account at any time. <br />
        <br />
        Withdrawing your consent: If we are relying on your consent to process
        your personal information, which may be express and/or implied consent
        depending on the applicable law, you have the right to withdraw your
        consent at any time. You can withdraw your consent at any time by
        contacting us by using the contact details provided in the section "
        <Link to="/privacypolicy#sectionEleven">
          HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Link>
        " below. <br />
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent. <br />
        Opting out of marketing and promotional communications: You can
        unsubscribe from our marketing and promotional communications at any
        time by clicking on the unsubscribe link in the emails that we send, or
        by contacting us using the details provided in the section "
        <Link to="/privacypolicy#sectionEleven">
          HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Link>
        " below. You will then be removed from the marketing lists. However, we
        may still communicate with you - for example, to send you
        service-related messages that are necessary for the administration and
        use of your account, to respond to service requests, or for other
        non-marketing purposes.
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }}>
        Account Information
      </Typography>
      <Typography>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
        <br />
        <ul>
          <li>Log in to your account settings and update your user account.</li>
        </ul>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.
        <br />
        <b>
          <u>Cookies and similar technologies:</u>
        </b>{" "}
        Most Web browsers are set to accept cookies by default. If you prefer,
        you can usually choose to set your browser to remove cookies and to
        reject cookies. If you choose to remove cookies or reject cookies, this
        could affect certain features or services of our Services.
        <br />
        If you have questions or comments about your privacy rights, you may
        email us at privacy@metalane.ai.
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="sectionNine">
        9. CONTROLS FOR DO-NOT-TRACK FEATURES
      </Typography>
      <Typography>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT) feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="sectionTen">
        10. DO WE MAKE UPDATES TO THIS NOTICE?
      </Typography>
      <Typography>
        <b>
          <i>In Short: </i>
        </b>
        Yes, we will update this notice as necessary to stay compliant with
        relevant laws.
        <br />
        <br />
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="sectionEleven">
        11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </Typography>
      <Typography sx={{ my: 2 }}>
        If you have questions or comments about this notice, you may email us at
        privacy@metalane.ai or contact us by post at:
        <br />
        <br />
        Metalane InC.
        <br />
        336 Bryant Street
        <br />
        Palo Alto, CA 94301
        <br />
        United States
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }} id="sectionTwelve">
        12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </Typography>
      <Typography>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please fill out and submit a data subject
        access request.
      </Typography>
    </Box>
  );
}
