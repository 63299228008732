import React, { useState, useEffect, useLayoutEffect } from "react";
import { Grid, Box } from "@mui/material";

import {
  HeadUrl,
  PatientListPath,
  minWidthMDCR,
  tableParam,
} from "../common/ConstantsAndValues";
import { MyTooltipButtons } from "../CustomComponents/MyButtons";
import { NotesEditor } from "../common/NotesEditor";
import initialValue from "../../tests/initialValueClinicalNotes";
import { Title } from "../common/Title";

import {
  getClinicNotes,
  postClinicNote,
  setNewClinicalNote,
} from "../../redux/actions/message";
import { useDispatch, useSelector } from "react-redux";
import { TextEditorView } from "../editor/TextEditorView";
import DocumentMap from "../docs/DocumentMap";
import log from "../../services/log";
import { useFormik } from "formik";
import { clinicalNotesSchema } from "../schema/clinicalNotes";

export function ClinicalNotes(props) {
  const { getCN, title, handleClose } = props;

  const user = useSelector((state) => state?.auth?.user?.user);
  const selectedpatient = useSelector((state) => state?.user?.selectedPatient);
  const selectmdgroup = useSelector((state) => state?.user?.selectmdgroup);
  const [eventNotes, setEventNotes] = useState(getCN);
  const dispatch = useDispatch();

  const noteform = useFormik({
    initialValues: {
      groupId: selectmdgroup?.metadata?.accountId ?? "",
      orgId: selectmdgroup?.metadata?.orgId ?? "",
      departmentId: selectmdgroup?.metadata?.accountId ?? "",
      providerId: user?.metadata?.accountId ?? "",
      role: user?.metadata?.role ?? null,
      patientId: selectedpatient?.metadata?.patientId ?? "",
      patientName: selectedpatient?.name ?? "",
      doctorName: user?.name ?? null,
      msgType: "",
      msgSubject: "consultation",
      msgBody: "",
      seqno: 0,
    },

    validationSchema: clinicalNotesSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("clinicaltestinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["patientId"] = values.patientId;
      jsonData.metadata["orgId"] = values.orgId;
      jsonData.metadata["groupId"] = values.groupId;
      jsonData.metadata["departmentId"] = values.departmentId;
      jsonData.metadata["providerId"] = values.providerId;
      jsonData.metadata["role"] = values.role;
      jsonData["patientName"] = values.patientName;
      jsonData["doctorName"] = values.doctorName;
      jsonData["msg"] = values.msg;
      jsonData["msgSubject"] = values.msgSubject;
      jsonData["msgBody"] = values.msgBody;
      jsonData["seqno"] = values.seqno;

      // log.info("specialist input", jsonData);

      dispatch(postClinicNote(jsonData))
        .then
        // (r) => log.info("clinicnote", r)
        ();
      handleClose();
    },
    onReset: (values) => {},
  });

  useEffect(() => {}, [user, selectedpatient]);

  const addNotesHere = (e) => {
    // log.info("clinicnotes", e);
    setEventNotes(e);
  };

  const handleSave = () => {
    // log.info("clinical notes", JSON.stringify(eventNotes));
    dispatch(setNewClinicalNote(JSON.stringify(eventNotes)));
    handleClose();
  };

  const handleClear = () => {
    // log.info("clinical notes", JSON.stringify(eventNotes));
    dispatch(setNewClinicalNote(null));
    handleClose();
  };

  const handleAdd = () => {
    // log.info("clinical notes", JSON.stringify(eventNotes));
    noteform.setFieldValue("msgBody", JSON.stringify(eventNotes));
    noteform.handleSubmit();
  };

  const isColm = true;

  log.info("clinical notes", noteform.errors);

  return (
    <Grid
      item
      xs
      zeroMinWidth
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "column" },
        justifyContent: "space-between",
      }}
      className="clear"
    >
      <Box
        width="100%"
        //maxWidth={minWidthMDCR.width}
      >
        <Box className="colfl">
          <Title>{title}</Title>
        </Box>
        <Box className="colfr">
          <MyTooltipButtons
            onClick={handleAdd}
            btnKey={"add"}
            btnLabel={"Post"}
            enState={false}
          />
          &nbsp; &nbsp;
          <MyTooltipButtons
            onClick={handleSave}
            btnKey={"save"}
            btnLabel={"Save"}
            enState={false}
          />
          &nbsp; &nbsp;
          <MyTooltipButtons
            onClick={handleClear}
            btnKey={"clear"}
            btnLabel={"Clear"}
            enState={false}
          />
        </Box>
        <Box className="clear" />
      </Box>
      <Box mt={0} mb={2}>
        <NotesEditor
          autoFocus
          id="notes2"
          addNotes={addNotesHere}
          addToMsg={eventNotes}
          isProtect={false}
          isColm={isColm}
          isFullHeight={true}
        />
      </Box>
    </Grid>
  );
}
