export const DrugsActionTypes = {
  DRUGS_LIST: "DRUGS_LIST",
  DRUGS_SELECT: "DRUGS_SELECT",
  DRUGS_TEXT: "DRUGS_TEXT",
  DRUGS_PRICELIST: "DRUGS_PRICELIST",
  DRUGS_PRESLIST: "DRUGS_PRESLIST",
  DRUGS_PATIENTLIST: "DRUGS_PATIENTLIST",
  MEDICINE_SELECT: "MEDICINE_SELECT",
  DRUGS_ORDERLIST: "DRUGS_ORDERLIST",
  DRUGSORDERS: "DRUGSORDERS",
  DRUGS_ORDERSELECT: "DRUGS_ORDERSELECT",
  OTCDRUGS_PRICELIST: "OTCDRUGS_PRICELIST",
  OTCDRUGS_LIST: "OTCDRUGS_LIST",
  OTCDRUGS_SELECT: "OTCDRUGS_SELECT",
  PHARMAGOUP_SELECT: "PHARMAGOUP_SELECT",
};
