const ReconstructionIssues = {
  DATASET_4D: "datasetis4D",
  VARYING_IMAGESDIMENSIONS: "imagesdimensionsvarying",
  VARYING_IMAGESCOMPONENTS: "imagescomponentsvarying",
  VARYING_IMAGESORIENTATION: "imagesorientationvarying",
  MISSING_FRAMES: "missingframes",
  IRREGULAR_SPACING: "irregularspacing",
  MULTIFFRAMES: "multiframe",
};

export { ReconstructionIssues };
