import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTableHead } from "../styles/InvoiceStyles";

const styles = stylesTableHead;

export const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.description}>Item Description</Text>
    <Text style={styles.qty}>Qty</Text>
    <Text style={styles.rate}>@</Text>
    <Text style={styles.amount}>Amount</Text>
  </View>
);
