import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useOutletContext } from "react-router-dom";
import {
  pageOptions,
  tableParam,
  colWidthSign,
  SpanAnchor,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../../common/ConstantsAndValues";
import { MyButtons } from "../../CustomComponents/MyButtons";
import { PrescMedListPHA } from "../../medicine/PrescMedListPHA";
import { PrescMedProcListPHA } from "../../medicine/PrescMedProcListPHA";

import { getPatientDrugs } from "../../../redux/drugs/drugs.actions";
import { selectedPatient } from "../../../redux/user/user.actions";

import log from "../../../services/log";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export default function PresListPHA() {
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const selectedPatient = useSelector((state) => state.user.selectedPatient);
  const medicines = useSelector((state) => state.drugs.patientlist);

  //const medicines  = [{id:"11", medicineName:"ffff", patientName:"x1", doctorName:"doc",updated_at:"22222",title:"this" ,},{id:"12", medicineName:"ftttttttfff", patientName:"x2", doctorName:"1111doc",updated_at:"22222",title:"this" ,},]

  const [markDates, dateTest, auth, eventType] = useOutletContext();
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState(medicines);
  const [checkedRows, setCheckedRows] = useState([]);
  const [action, setAction] = useState("");

  const classes = useStyles();

  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);

  useEffect(() => {
    if (selectedPatient) {
      // dispatch(getPatientDrugs(
      //     {
      //         metadata: {
      //             patientId: selectedPatient.accountId,
      //         }
      //     }));
    }
  }, [selectedPatient]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = medicines.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(medicines);
    }
  };

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: value };
    //log.info("patient druglist", drugsquery("name", query.name, newpagedata));
    //dispatch(getDrugs(drugsquery("name", query.name, newpagedata))).then((r) => {
    // log.info("drugslist", r)
    // if (r.total.value && r.total.value > 0) {
    //     setPage({... pagedata,
    //         count: r.total.value,
    //         pages: r.total.value/pagedata.limit,
    //     });
    // }
    //});
    setPage(newpagedata);
  };
  const handleChangeRows = (event, value) => {
    //pagination part
    setPage({ ...pagedata, limit: value });
  };

  const handleRowClick = (e) => {
    var updatedList = [...checkedRows];

    if (findArrayElement(updatedList, e)) {
      updatedList.splice(checkedRows.indexOf(e), 1);
    } else {
      updatedList = [...checkedRows, e];
    }

    setCheckedRows(updatedList);
    //console.log("ProvideDrugs", updatedList);
  };

  const handleProcessPrescription = () => {
    setAction("ProvideDrugs");
    console.log("ProvideDrugs/Repeat", checkedRows);
  };

  const handleIssueDrug = () => {
    //setAction("IssueDrug");
    console.log("ProvideDrugs/Repeat", checkedRows);
  };
  const handleBackIssueDrug = () => {
    setAction("");
  };

  const findArrayElement = function (array, title) {
    return array.find((item) => {
      return item === title;
    });
  };

  //log.info("medicinelist rows 111111", rows);

  return (
    <Grid container>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0"></div>

          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Prescription1"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div>
          {action === "" && (
            <MyButtons
              onClick={handleProcessPrescription}
              btnKey={"process"}
              btnLabel={"Process"}
              enState={false}
            />
          )}
          {action === "ProvideDrugs" && (
            <>
              <MyButtons
                onClick={handleBackIssueDrug}
                btnKey={"back"}
                btnLabel={"Back"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyButtons
                onClick={handleIssueDrug}
                btnKey={"prescribe"}
                btnLabel={"Issue"}
                enState={false}
              />
            </>
          )}
        </div>
      </div>

      {action === "ProvideDrugs" && (
        <PrescMedProcListPHA
          medicinelist={rows}
          //seletedRows  = {checkedRows}
          //selectRows   = {setCheckedRows}
        />
      )}
      {action === "IssueDrug" && <div>issue</div>}
      {action === "" && (
        <PrescMedListPHA
          medicinelist={rows}
          //seletedRows  = {checkedRows}
          //selectRows   = {setCheckedRows}
        />
      )}
    </Grid>
  );
}
