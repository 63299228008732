import * as React from "react";
import {
  StyledTableRowHead,
  StyledTableCellHead,
} from "../../common/ConstantsAndValues";

export const pOMPTable = {
  title: {
    label: "Title",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  descrip: {
    label: "Description",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  orderby: {
    label: "Approved by",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  completedAt: {
    label: "Recorded on",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
};

export function OtherTestsPresListHead() {
  const table = pOMPTable;
  return (
    <React.Fragment>
      <StyledTableRowHead style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead>{table.title.label}</StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.descrip.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.orderby.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.completedAt.label}
        </StyledTableCellHead>
      </StyledTableRowHead>
    </React.Fragment>
  );
}
