import React, { useState } from "react";

import { Grid, Box } from "@mui/material";
import log from "../../services/log";
import Select from "react-select";

export function SelectOptions(props) {
  const { openPage, data, multi, title, wide } = props;
  const [selectedOption, setSelectedOption] = useState();

  function handleSelect(event) {
    // log.info("select handle ", event);
    setSelectedOption(event);
    openPage(event);
  }

  const MSStyles = {
    // control:   styles => ({ ...styles,}),
    container: (styles) => ({ ...styles, minWidth: wide }),
    option: (styles) => ({ ...styles }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: wide,
    }),
    control: (base) => ({
      ...base,
      "&:hover": { borderColor: "gray" },
      border: "1px solid lightgray",
      boxShadow: "none",
    }),
  };

  // log.info("options data", data);

  return (
    <Box width="100%" className="text-color-primary">
      <Select
        options={data}
        placeholder={"Select " + title}
        defaultValue={selectedOption}
        onChange={handleSelect}
        isSearchable={true}
        styles={MSStyles}
        menuPlacement="auto"
        menuPosition="fixed"
        isMulti={multi}
      />
    </Box>
  );
}

export function SelectOptionsAuto(props) {
  const { openPage, data, multi, title, wide } = props;
  const [selectedOption, setSelectedOption] = useState();

  function handleSelect(event) {
    log.info("sajal activity", event);
    setSelectedOption(event);
    openPage(event);
  }

  const MSStyleNob = {
    //control:   styles => ({ ...styles,}),
    container: (styles) => ({ ...styles }),
    option: (styles) => ({ ...styles }),
    // menu:      styles => ({ ...styles, }),
    menu: (base) => ({
      ...base,
    }),
    control: (base) => ({
      ...base,
      "&:hover": { borderColor: "gray" },
      border: "1px solid lightgray",
      boxShadow: "none",
    }),
  };

  return (
    <Box width="100%" className="text-color-primary">
      <Select
        options={data}
        placeholder={"Select " + title}
        defaultValue={selectedOption}
        onChange={handleSelect}
        isSearchable={true}
        styles={MSStyleNob}
        menuPlacement="auto"
        menuPosition="fixed"
        isMulti={multi}
      />
    </Box>
  );
}
