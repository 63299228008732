import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import {
  pageOptions,
  tableParam,
  colWidthSign,
  SpanAnchor,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import {
  MyTooltipButtons,
  MyHelpButtons,
} from "../../CustomComponents/MyButtons";

import { MedicineOrderInput } from "../../medicine/MedicineOrderInput";
import { MedicineOrderProcList } from "../../medicine/MedicineOrderProcList";
import { MedicineOrderList2 } from "../../medicine/MedicineOrderList2";

import {
  setPatientDrugs,
  setDrugPrecriptionList,
} from "../../../redux/drugs/drugs.actions";
import { selectedPatient } from "../../../redux/user/user.actions";

import log from "../../../services/log";
import { validateUserAccess } from "../../common/validateComponents";
import { buildMedicineListQuery } from "../../dbcq/medicinelistdb";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

/**
 * used by pharmacist
 * filter order by medicine name
 * filter order by patient name
 * filter order by doctor name
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function OrderListPHA(props) {
  const user = useSelector((state) => state?.auth?.user?.user);
  const department = useSelector((state) => state?.orgs?.selectdepartment);
  const selectedPatient = useSelector((state) => state?.user?.selectedPatient);

  const searchtext = useSelector((state) => state?.user?.searchtext);
  const preslist = useSelector((state) => state?.drugs?.preslist);
  const orderdruglist = useSelector((state) => state?.drugs?.patientlist);
  const selectmedrows = useSelector((state) => state?.drugs?.selectmedrows);

  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState(null);
  const [action, setAction] = useState("");
  const [checkedRows, setCheckedRows] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reports = useSelector((state) => state.reports);

  useEffect(() => {
    // if (validateUserAccess(user) == false)
    //     dipatch('/sign');
  }, [user]);

  useEffect(() => {
    if (selectedPatient) {
      // dispatch(getPatientDrugs({
      //         metadata: {
      //             patientId: selectedPatient.accountId,
      //         }
      //     }));
    }
  }, [selectedPatient]);

  useEffect(() => {
    orderdruglist ? setRows(orderdruglist) : setRows([]);
  }, [orderdruglist]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("druglist filter", rows);
    if (keyword !== "") {
      const results = orderdruglist.results.filter((v) =>
        v.medicineName.name.toLowerCase().startsWith(keyword.toLowerCase())
      );
      setRows({ ...rows, results: results });
    } else {
      setRows(orderdruglist);
    }
  };

  const handleProcess = () => {
    // log.info("process", checkedRows);
    setAction("process");
  };

  const handleHoldList = () => {
    //setAction('holdlist')
    //todo filter with hold parameter
    setRows([]); ///temp set to null
  };

  const handlePresList = () => {
    setAction("viewlist");
  };

  const handleCancel = () => {
    setAction("");
  };

  const handleReset = () => {
    // log.info("processlist reset");
    dispatch(setDrugPrecriptionList([]));
  };

  const handleRemove = () => {
    log.info("processlist remove");
    // dispatch(setDrugPrecriptionList([]));
  };

  const handlePost = () => {
    // log.info("processlist post");
    preslist.forEach((d) => {
      dispatch(setPatientDrugs(d));
    });
    // reset list after post
    dispatch(setDrugPrecriptionList([]));
  };

  const handleRaiseInvoice = () => {};

  const handleSelectRows = (row) => {
    log.info("ODList md", row);
    setCheckedRows(row);
  };

  log.info("ODList md", checkedRows);

  return (
    <Grid container>
      <Grid item xs>
        {action === "process" ? (
          <div>
            <MedicineOrderInput
              cancel={handleCancel}
              doctor={user}
              patient={selectedPatient}
              medicine={checkedRows}
            />
          </div>
        ) : action === "viewlist" ? (
          <div>
            <div className="colfl0">
              <FilterBox handleFilter={handleFilter} />
            </div>
            <div className="colfr0">
              <MyHelpButtons
                onClick={handleRaiseInvoice}
                btnKey={"receipt"}
                btnLabel={"Raise Invoice"}
                enState={false}
                btnTooltip={"Raise Invoice"}
              />
              &nbsp; &nbsp;
              <MyHelpButtons
                onClick={handlePost}
                btnKey={"post"}
                btnLabel={"Post"}
                enState={false}
                btnTooltip={"Post"}
              />
              &nbsp; &nbsp;
              <MyHelpButtons
                onClick={handleReset}
                btnKey={"reset"}
                btnLabel={"Reset"}
                enState={false}
                btnTooltip={"Reset"}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleRemove}
                btnKey={"remove"}
                btnLabel={"Remove selected"}
                enState={false}
                //btnTooltip = {"Remove selected"}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleCancel}
                btnKey={"cancel"}
                btnLabel={"Cancel"}
                enState={false}
              />
            </div>
            <div className="clear"></div>
            <MedicineOrderProcList
              handleClose={handleCancel}
              medicinelist={preslist}
            />
          </div>
        ) : (
          <div>
            <div className="colfl0">
              <FilterBox handleFilter={handleFilter} />
            </div>
            <div className="colfr0">
              <MyHelpButtons
                onClick={handleHoldList}
                btnKey={"hold"}
                btnLabel={"Hold"}
                enState={false}
                btnTooltip={"Hold list"}
              />
              &nbsp; &nbsp;
              <MyHelpButtons
                onClick={handlePresList}
                btnKey={"view"}
                btnLabel={"View"}
                enState={false}
                btnTooltip={"View list"}
              />
              &nbsp; &nbsp;
              <MyHelpButtons
                onClick={handlePresList}
                btnKey={"process"}
                btnLabel={"Process"}
                enState={false}
                btnTooltip={"Process"}
              />
            </div>

            <div className="clear"></div>
            <MedicineOrderList2
              medicineorderlist={rows}
              searchText={searchtext?.medications}
              seletedRows={checkedRows}
              selectRows={setCheckedRows}
              selectRows={handleSelectRows}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
}

const FilterBox = (props) => {
  const { handleFilter } = props;

  return (
    <>
      <div className="colfl0"></div>

      <div className="colfr0" width="100%">
        <SearchBoxStandard
          onChange={handleFilter}
          placeholder="Drug Name"
          sx={{ marginRight: 1 }}
          className="text-color-primary text-color-2-border text-color-2-bg"
        />
      </div>
    </>
  );
};
