//CT ALL
import * as React from "react";
import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import { getDrugs, selectDrugList } from "../../redux/drugs/drugs.actions";
import { drugsquery } from "../utils/drugsqueries";

import {
  pageOptions,
  tableParam,
  colWidthSign,
  SpanAnchor,
  SearchBoxStandard,
} from "../common/ConstantsAndValues";

import { labsMetaQueries } from "../utils/labsqueries";
import { getLabsList, selectLabsRow } from "../../redux/labs/labs.actions";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";

import { QListRow } from "./QListMDRow";
import { QListHead } from "./QListMDHead";
import log from "../../services/log";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export function QListMD(props) {
  const { searchText, userType } = props;

  const ctlist = null;
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ctlist) {
      setRows(ctlist);
    }
  }, [ctlist]);

  const buildQuery = (searchtext, paged) => {
    const searchquery = {};
    // ? searchtext.name
    //     ? labsMetaQueries("name", searchtext.name, paged)
    //     : labsMetaQueries("name", query.name, paged)
    // : labsMetaQueries("name", query.name, paged);
    return searchquery;
  };

  useEffect(() => {
    // log.info("drug serarch query", searchText);
    const resetpage = { ...pagedata, page: 0 };
    const searchquery = buildQuery(searchText, resetpage);
    // log.info("ctlist serarch query", searchquery);

    // dispatch(getLabsList(searchquery)).then((r) => {
    //     log.info("ctlist", r)
    //     if (r.total.value && r.total.value > 0) {
    //         setPage({... resetpage,
    //             count: r.total.value,
    //             pages: r.total.value/pagedata.limit,
    //         });
    //     }
    // });
  }, [searchText, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    const newlist = keyword
      ? ctlist.filter((v) =>
          v._source.name.toLowerCase().startsWith(keyword.toLowerCase())
        )
      : ctlist;
    setRows(newlist);
  };

  const handleChangePage = (event, value) => {
    // log.info("ctlist", value);
    // log.info("ctlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const searchquery = buildQuery(searchText, newpagedata);
    // log.info("ctlist nextpage", searchquery);

    // dispatch(getLabsList(searchquery)).then((r) => {
    //     log.info("ctlist", r)
    //     if (r.total.value && r.total.value > 0) {
    setPage(newpagedata);
    //     }
    // });
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (row, add) => {
    // log.info("Prescribe", row);
    // log.info("Prescribe", add);

    if (row && add === true) {
      // newlist.push(row);
      setCheckedRows(row);
      // selectRows(row)
      // dispatch(selectLabsRow(row));
    }

    if (row && add === false) {
      // const flist = newlist.filter((v) => v._source.Id !== row._source.Id)
      setCheckedRows(null);
      // selectRows(null)
      // dispatch(selectLabsRow(null));
    }
  };

  // log.info("ctlist", rows);

  return (
    <div>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Search"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead>
              <QListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {rows &&
                rows.map((row) => (
                  <QListRow
                    key={row._source.Id}
                    row={row}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </div>
  );
}
