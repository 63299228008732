import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { Button, Tooltip } from "@mui/material";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import { colChart } from "../common/ConstantsAndValues";
import { MyTooltipButtons } from "../CustomComponents/MyButtons";
import { Title } from "../common/Title";
import { AffirmationDialog } from "../common/AffirmationDialog";
import { useEffect, useState } from "react";
import log from "../../services/log";
import {
  getAppointmentGuestList,
  removeAppointment,
  updateAppointment,
} from "../../redux/appointment/appointment.actions";
import { useFormik } from "formik";
import { appointmentUpdateSchema } from "../schema/appointmentSchema";

const affirmOptions = [
  { id: "accept", label: "accept", value: "accept" },
  { id: "accept", label: "reject", value: "reject" },
];

export const EventView = (props) => {
  const { closeForm, eventRemove } = props;
  const appt = useSelector((state) => state.appointment.appt);
  const [affirmopen, setAffirmOpen] = useState({
    state: "affirm",
    open: false,
  });

  const dispatch = useDispatch();

  const apptupdateForm = useFormik({
    initialValues: {
      id: "",
      orgId: "",
      groupId: "",
      departmentId: "",
      providerId: "",
      guestId: appt ? appt.metadata.guestId : "",
      userId: appt ? appt.metadata.userId : "",
      email: appt ? appt.metadata.email : "",
      eventId: appt ? appt.metadata.eventId : "",
      eventType: appt ? appt.metadata.eventType : "",
      eventTitle: appt ? appt.eventTitle : "",
      eventDescp: appt ? appt.eventDescp : "",
      locId: appt ? appt.locId : "virtual",
      address: appt ? appt.address : null,
      dateStart: appt ? appt.dateStart : null,
      dateEnd: appt ? appt.dateEnd : null,
    },
    validationSchema: appointmentUpdateSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("clinicaltestinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData.id = values.id;
      jsonData.metadata = {};
      jsonData.metadata.userId = values.userId;
      jsonData.metadata.eventId = values.eventId;
      jsonData.metadata.eventType = values.eventType;
      jsonData.metadata.email = values.email;
      jsonData.metadata.guestId = values.guestId;
      jsonData.eventTitle = values.eventTitle;
      jsonData.eventDescp = values.eventDescp;
      jsonData.dateStart = values.dateStart;
      jsonData.dateEnd = values.dateEnd;
      jsonData["eventAddress"] = {};
      jsonData.eventAddress["locId"] = values.locId;
      jsonData.eventAddress["address"] = values.address;

      // log.info("metacal", jsonData);

      dispatch(updateAppointment(jsonData));
      closeForm();
    },
    onReset: (values) => {},
  });

  useEffect(() => {
    /**
     * updating event
     */
    if (appt) {
      apptupdateForm.setFieldValue("id", appt.id);
      apptupdateForm.setFieldValue("eventId", appt.metadata.eventId);
      apptupdateForm.setFieldValue("eventType", appt.metadata.eventType);
      apptupdateForm.setFieldValue("guestId", appt.metadata.guestId);
      apptupdateForm.setFieldValue("userId", appt.metadata.userId);
      apptupdateForm.setFieldValue("email", appt.metadata.email);
      apptupdateForm.setFieldValue("eventTitle", appt.eventTitle);
      apptupdateForm.setFieldValue("eventDescp", appt.eventDescp);
      apptupdateForm.setFieldValue("dateStart", appt.dateStart);
      apptupdateForm.setFieldValue("dateEnd", appt.dateEnd);
      apptupdateForm.setFieldValue("locId", appt.eventAddress.locId);
      apptupdateForm.setFieldValue("address", appt.eventAddress.address);
    }
  }, [appt]);

  const removeEvent = () => {
    /**
     * clean up all instances
     * including guest intance of the event
     */
    dispatch(
      getAppointmentGuestList({ metadata: { eventId: appt.metadata.eventId } })
    ).then((r) => {
      // log.info("eventview", r);
      r.results &&
        r.results.forEach((e) => {
          dispatch(
            removeAppointment({
              id: e.id,
            })
          );
        });
    });
  };

  const handleRemove = () => {
    setAffirmOpen({ state: "affirm", open: true });
  };

  const handleClose = (value) => {
    // log.info("affirmation", value)
    setAffirmOpen({ state: "affirm", open: false });
    if (value === "accept") {
      removeEvent();
      eventRemove();
    }
  };

  log.info("eventview", apptupdateForm.errors);

  return (
    <Box sx={{ width: colChart.width }}>
      <Box
        mb={4}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Title>{"Event Information"}</Title>
        </Box>
        <Box>
          <MyTooltipButtons
            onClick={apptupdateForm.handleSubmit}
            btnKey={"update"}
            btnLabel={"Update"}
            enState={false}
          />
          &nbsp; &nbsp;
          <MyTooltipButtons
            onClick={handleRemove}
            btnKey={"remove"}
            btnLabel={"Remove"}
            enState={false}
          />
          &nbsp; &nbsp;
          <MyTooltipButtons
            onClick={closeForm}
            btnKey={"cancel"}
            btnLabel={"Cancel"}
            enState={false}
          />
        </Box>
      </Box>

      {affirmopen && affirmopen.state === "affirm" && (
        <AffirmationDialog
          data={affirmOptions}
          open={affirmopen.open}
          title={"deleting event ..."}
          onClose={handleClose}
        />
      )}

      <TextField
        autoFocus
        margin="dense"
        name="eventTitle"
        id="eventTitle"
        label="Title"
        defaultValue={apptupdateForm.values.eventTitle}
        error={apptupdateForm.errors.eventTitle ? true : false}
        fullWidth
        variant="standard"
        onChange={apptupdateForm.handleChange}
      />

      <TextField
        autoFocus
        margin="dense"
        name="eventDescp"
        id="eventDescp"
        label="Description"
        defaultValue={apptupdateForm.values.eventDescp}
        error={apptupdateForm.errors.eventDescp ? true : false}
        fullWidth
        variant="standard"
        onChange={apptupdateForm.handleChange}
      />
    </Box>
  );
};
