import { AppStateActionTypes } from "./appstate.types";

const INITIAL_STATE = {
  mbarstate: null,
  lsmstate: null,
  topnavstate: null,
};

const appStateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppStateActionTypes.MBAR_STATE:
      return {
        ...state,
        mbarstate: action.payload,
      };
    case AppStateActionTypes.LSM_STATE:
      return {
        ...state,
        lsmstate: action.payload,
      };
    case AppStateActionTypes.TOPNAV_STATE:
      return {
        ...state,
        lsmstate: action.payload,
      };
    case AppStateActionTypes.OPENSIDEBAR:
      return {
        ...state,
        opensidebar: action.payload,
      };

    case AppStateActionTypes.NAVX_STATE:
      return {
        ...state,
        navxstate: action.payload,
      };
    case AppStateActionTypes.DASH_URL:
      return {
        ...state,
        dashurl: action.payload,
      };

    default:
      return state;
  }
};

export default appStateReducer;
