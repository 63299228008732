import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user/user.reducer";
import directoryReducer from "./directory/directory.reducer";
import auth from "./reducers/auth";
import appointmentReducer from "./appointment/appointment.reducer";
import reportReducer from "./reducers/reports";
import messageReducer from "./reducers/messages";
import locationReducer from "./reducers/locs";
import {
  eventsReducer,
  weekendsVisible,
} from "../components/appointment/redux/reducer";
import reduxdicom from "../components/dicom/core/redux";
import loincReducer from "./loinc/loinc.reducer";
import orgsReducer from "./reducers/orgs";
import addressReducer from "./reducers/addresses";
import drugsReducer from "./drugs/drugs.reducer";
import labsReducer from "./labs/labs.reducer";
import policyReducer from "./policy/policy.reducer";
import resourceReducer from "./resources/resource.reducer";
import appStateReducer from "./appstate/appstate.reducer";
import paymentReducer from "./payment/payment.reducer";
import pcareReducer from "./pcare/pcare.reducer";
import qnsReducer from "./qns/qns.reducer";
import subscribeReducer from "./subscribe/subscribe.reducer";
import feedbackReducer from "./feedback/feedback.reducer";
const { dicomreducers } = reduxdicom;

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth,
  servers: dicomreducers.servers,
  viewports: dicomreducers.viewports,
  extensions: dicomreducers.extensions,
  timepointManager: dicomreducers.timepointManager,
  studies: dicomreducers.studies,
  preferences: dicomreducers.preferences,
  user: userReducer,
  directory: directoryReducer,
  appointment: appointmentReducer,
  addresses: addressReducer,
  reports: reportReducer,
  messages: messageReducer,
  events: eventsReducer,
  weekend: weekendsVisible,
  locs: locationReducer,
  loinc: loincReducer,
  orgs: orgsReducer,
  drugs: drugsReducer,
  labs: labsReducer,
  policies: policyReducer,
  resources: resourceReducer,
  appstate: appStateReducer,
  payment: paymentReducer,
  pcare: pcareReducer,
  qns: qnsReducer,
  subscribes: subscribeReducer,
  feedback: feedbackReducer,
});

export default persistReducer(persistConfig, rootReducer);
