import React, { useState, useEffect, useLayoutEffect } from "react";
import { Grid, Box, Link, Paper, TableContainer } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { PatientListPath } from "../../common/ConstantsAndValues";
import * as componentsMap from "../../healthmonitor/conditions/";

export function HmoniCondUSR() {
  const navigate = useNavigate();
  const [markDates, dateTest, auth, eventType, page] = useOutletContext();

  useEffect(() => {}, []);

  const MyPage = componentsMap[page];

  return (
    <TableContainer component={Paper}>
      {MyPage && (
        <MyPage
          page={page}
          markDates={markDates}
          // patient={patient.id}
        />
      )}
    </TableContainer>
  );
}
