import * as React from "react";
import { useState } from "react";

import { useSelector } from "react-redux";

import { RadiologyTestsList4MD } from "../../laboratory/components/RadiologyTestsList4MD";
import { RadiologyMoreTestList4MD } from "../../laboratory/components/RadiologyMoreTestList4MD";

import log from "../../../services/log";

export const RadiologyTestsListMD = (props) => {
  const searchtext = useSelector((state) =>
    state?.user?.searchtext ? state.user.searchtext : null
  );
  const searchcontext = useSelector((state) =>
    state?.user?.searchtext ? state.user.searchcontext : null
  );
  const [actionState, setActionState] = useState("");

  // log.info("rtlist searchtext", searchtext);
  // log.info("rtlist searchcontext", searchcontext);
  const msearchtext = { ...searchcontext, value: searchtext };

  const handleCancel = () => {
    setActionState("");
  };

  log.info("rtlist searchtext", actionState);

  return (
    <div>
      {actionState === "moretests" ? (
        <RadiologyMoreTestList4MD handleBack={handleCancel} />
      ) : (
        <RadiologyTestsList4MD
          searchText={msearchtext}
          setActionState={setActionState}
        />
      )}
    </div>
  );
};
