import { DrugsList } from "./DrugsList";
import log from "../../../services/log";

export default function DrugsListPHA(props) {
  // log.info("drugslist org", drugtext);
  return (
    <div>
      <DrugsList />
    </div>
  );
}
