import React, { useState } from "react";
import moment from "moment";
import { Typography } from "@mui/material";
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceArea,
} from "recharts";

import { CustomTooltip } from "./MetaGraphs";
import { MetaAxisTick } from "./MetaGraphs";

function getIntro(label) {
  return label;
}

export default class MetaLineCharts extends React.Component {
  state = {
    data: this.props.data,
    chatType: this.props.chartType,
    testType: this.props.testType,
    title: "this test lable",
    xlable: this.props.xlable,
    ylable: this.props.ylable,
    left: this.props.left,
    right: this.props.right,
    refAreaLeft: this.props.refAreaLeft,
    refAreaRight: this.props.refAreaRight,
    top: this.props.top,
    bottom: this.props.bottom,
    top2: this.props.top2,
    bottom2: this.props.bottom2,
    animation: true,
    leftIndex: 0,
    rightIndex: this.props.data,
  };

  color = ["#82ca9d", "#FFCC80", "#8884d8", "#82ca9d", "orange", "pink"];
  getLineChart = () => {
    console.log(this.props);
    const lineArr = [];
    const dArray = Object.keys(this.state.data[0]).slice(1);
    dArray.forEach((item, index) => {
      lineArr.push(
        <Line
          type="natural"
          dataKey={item}
          stroke={this.color[index]}
          animationDuration={300}
        />
      );
    });
    return lineArr;
  };

  modifyFormatter = (value, name, props) => {
    const nameJSX = (
      <span>
        <span
          style={{
            display: "inline-block",
            marginRight: "5px",
            borderRadius: "10px",
            width: "10px",
            height: "10px",
            backgroundColor: props.color,
          }}
        ></span>
        {name} : {value}
      </span>
    );
    return [nameJSX];
  };

  getAxisYDomain = (from, to, ref, offset) => {
    let leftIndex = 0;
    let rightIndex = 0;
    let data = this.state.data;

    data.forEach((d) => {
      if (d.timestamp < from) leftIndex = leftIndex + 1;
      if (d.timestamp < to) rightIndex = rightIndex + 1;
    });

    this.setState(() => ({
      leftIndex: leftIndex,
      rightIndex: rightIndex,
    }));

    // let refData = [data.slice(leftIndex, rightIndex)];

    // let [bottom, top] = [refData[0][ref], refData[0][ref]];
    //
    // refData.forEach((d) => {
    //     if (d[ref] > top) top = d[ref];
    //     if (d[ref] < bottom) bottom = d[ref];
    // });
    //
    // return [(bottom | 0) - offset, (top | 0) + offset];
    return;
  };

  zoom = (e) => {
    const { data } = this.state;
    this.setState({ refAreaRight: e.activeLabel });
    let { refAreaLeft, refAreaRight } = this.state;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      this.setState(() => ({
        refAreaLeft: "",
        refAreaRight: "",
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // yAxis domain
    let refData = this.getAxisYDomain(refAreaLeft, refAreaRight, "timstamp", 1);
    // const [bottom, top] = this.getAxisYDomain(refAreaLeft, refAreaRight, "cost", 1);
    // const [bottom2, top2] = this.getAxisYDomain(
    //     refAreaLeft,
    //     refAreaRight,
    //     "impression",
    //     50
    // );

    this.setState(() => ({
      refAreaLeft: "",
      refAreaRight: "",
      data: data.slice(this.state.leftIndex, this.rightIndex),
      left: refAreaLeft,
      right: refAreaRight,
    }));
    console.log(this.state);
  };

  handleMouseDown = (e) => {
    this.setState({ refAreaLeft: e.activeLabel });
  };

  handleMouseMove = (e) => {
    this.setState({ refAreaRight: e.activeLabel });
  };

  render() {
    const {
      data,
      title,
      refAreaLeft,
      refAreaRight,
      xlable,
      ylable,
      ResponsiveContainer,
    } = this.state;

    return (
      <LineChart
        label={title}
        width={600}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 40,
          left: 20,
          bottom: 60,
        }}
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.zoom}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <CartesianGrid
          strokeDasharray="3 3"
          horizontal={false}
          vertical={false}
        />
        <XAxis
          dataKey={Object.keys(data[0])[0]}
          allowDataOverflow={true}
          tick={<MetaAxisTick />}
        >
          <Label value={xlable} offset={20} position="left" />
        </XAxis>
        <YAxis
          label={{ value: ylable, angle: -90, position: "insideLeft" }}
          allowDataOverflow={true}
        />

        <Tooltip formatter={this.modifyFormatter} content={<CustomTooltip />} />
        <Legend verticalAlign="top" height={36} />
        {this.getLineChart()}
        {refAreaLeft && refAreaRight ? (
          <ReferenceArea
            x1={refAreaLeft}
            x2={refAreaRight}
            strokeOpacity={0.3}
          />
        ) : null}
      </LineChart>
    );
  }
}

export const MetaLineCharts2 = (props) => {
  const { data, title, chartType, xlabel, ylabel, labName } = props;

  const [dataSlice, setDataSlice] = useState(data);
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(data.length);
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(data.length);
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const [animation, setAnimation] = useState(true);
  const [top, setTop] = useState(data.upper + 1);
  const [bottom, setBottom] = useState(data.upper - 1);
  const [top2, setTop2] = useState(data.upper + 20);
  const [bottom2, setBottom2] = useState(data.upper - 20);

  const color = ["#82ca9d", "#FFCC80", "#8884d8", "#82ca9d", "orange", "pink"];

  const getLineChart = () => {
    const lineArr = [];
    const dArray = Object.keys(data[0]).slice(1);
    dArray.forEach((item, index) => {
      lineArr.push(
        <Line
          type="natural"
          dataKey={item}
          stroke={this.color[index]}
          animationDuration={300}
        />
      );
    });
    return lineArr;
  };

  const modifyFormatter = (value, name, props) => {
    const nameJSX = (
      <span>
        <span
          style={{
            display: "inline-block",
            marginRight: "5px",
            borderRadius: "10px",
            width: "10px",
            height: "10px",
            backgroundColor: props.color,
          }}
        ></span>
        {name} : {value}
      </span>
    );
    return [nameJSX];
  };

  const getAxisYDomain = (from, to, ref, offset) => {
    let leftIndex = 0;
    let rightIndex = 0;

    data.forEach((d) => {
      if (d.timestamp < from) leftIndex = leftIndex + 1;
      if (d.timestamp < to) rightIndex = rightIndex + 1;
    });

    setLeftIndex(leftIndex);
    setRightIndex(rightIndex);

    return;
  };

  const zoom = (e) => {
    setRefAreaRight(e.activeLabel);

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setRefAreaRight("");
      setRefAreaLeft("");
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) {
      let trefAreaLeft = refAreaLeft;
      let trefAreaRight = refAreaRight;
      setRefAreaRight(trefAreaLeft);
      setRefAreaLeft(trefAreaRight);
    }

    // yAxis domain
    let refData = this.getAxisYDomain(refAreaLeft, refAreaRight, "timstamp", 1);
    setRefAreaRight("");
    setRefAreaLeft("");
    setDataSlice(data.slice(leftIndex, rightIndex));
    setLeft(refAreaLeft);
    setRight(refAreaRight);
  };

  const handleMouseDown = (e) => {
    this.setState({ refAreaLeft: e.activeLabel });
  };

  const handleMouseMove = (e) => {
    this.setState({ refAreaRight: e.activeLabel });
  };
  return (
    <LineChart
      label={title}
      width={600}
      height={400}
      data={data}
      margin={{
        top: 20,
        right: 40,
        left: 20,
        bottom: 60,
      }}
      onMouseDown={this.handleMouseDown}
      onMouseMove={this.handleMouseMove}
      onMouseUp={this.zoom}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <CartesianGrid
        strokeDasharray="3 3"
        horizontal={false}
        vertical={false}
      />
      <XAxis
        dataKey={Object.keys(data[0])[0]}
        allowDataOverflow={true}
        tick={<MetaAxisTick />}
      >
        <Label value={xlabel} offset={20} position="left" />
      </XAxis>
      <YAxis
        label={{ value: ylabel, angle: -90, position: "insideLeft" }}
        allowDataOverflow={true}
      />

      <Tooltip formatter={this.modifyFormatter} content={<CustomTooltip />} />
      <Legend verticalAlign="top" height={36} />
      {this.getLineChart()}
      {refAreaLeft && refAreaRight ? (
        <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
      ) : null}
    </LineChart>
  );
};
