import * as React from "react";
import { Grid, Box, Link, Typography } from "@material-ui/core";

export const SelectButton = (props) => {
  const { containerType, btnHandleChange } = props;
  const [value, setValue] = React.useState("/");

  {
    /*OUTPUT TO PARENT FUNCTION*/
  }

  const handleChange = (e) => {
    //console.log('sb btn o/p:', props, e.target.text)
    btnHandleChange(e.target.text);
    setValue(e.target.text);
  };

  return (
    <Grid container>
      <Grid item mt={2} ml={2}>
        <Box
          ml={1}
          spacing={2}
          sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
        >
          {containerType.comboList.map((menu, i) => (
            <Box mr={2} key={"sbb" + menu._id}>
              <Link
                key={"sb" + menu._id}
                onClick={handleChange}
                className="btn btn-transparent btn-icon btn-round-full text-color"
              >
                {menu.label}
              </Link>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
