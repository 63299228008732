import { DrugsActionTypes } from "./drugs.types";
import { searchService } from "../../services/location-service";
import log from "../../services/log";
import {
  getMedicinelistService,
  getPatientDrugsService,
  setPatientDrugsService,
  updatePatientDrugsService,
  updateMedicinelistService,
  setMedicinelistService,
} from "../../services/drugs-service";

export const setDrugList = (data) => (dispatch) => {
  return dispatch({
    type: DrugsActionTypes.DRUGS_LIST,
    payload: data,
  });
};

export const setPharmacyGroup = (data) => (dispatch) => {
  return dispatch({
    type: DrugsActionTypes.PHARMAGOUP_SELECT,
    payload: data,
  });
};

export const selectDrugList = (data) => (dispatch) => {
  return dispatch({
    type: DrugsActionTypes.DRUGS_SELECT,
    payload: data,
  });
};

export const selectOTCDrugList = (data) => (dispatch) => {
  return dispatch({
    type: DrugsActionTypes.OTCDRUGS_SELECT,
    payload: data,
  });
};

export const setDrugSearchText = (data) => (dispatch) => {
  return dispatch({
    type: DrugsActionTypes.DRUGS_TEXT,
    payload: data,
  });
};

export const selectPatientMedcine = (data) => (dispatch) => {
  return dispatch({
    type: DrugsActionTypes.MEDICINE_SELECT,
    payload: data,
  });
};

export const selectDrugPresListRow = (data) => (dispatch) => {
  return dispatch({
    type: DrugsActionTypes.DRUGS_ORDERSELECT,
    payload: data,
  });
};

export const setDrugPrecriptionList = (data) => (dispatch) => {
  return dispatch({
    type: DrugsActionTypes.DRUGS_PRESLIST,
    payload: data,
  });
};

export const addPrecriptionOrder = (data) => (dispatch) => {
  return dispatch({
    type: DrugsActionTypes.DRUGS_ORDERLIST,
    payload: data,
  });
};

export const getDrugs = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        const drugkey = new Set();
        const dlist = [];
        response?.data?.data.hits.forEach((d) => {
          if (drugkey.has(d._source.Id) === false) {
            drugkey.add(d._source.Id);
            dlist.push(d);
          }
        });
        dispatch({
          type: DrugsActionTypes.DRUGS_LIST,
          payload: dlist,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      log.info("drugs fetch error: ", message);
      dispatch({
        type: DrugsActionTypes.DRUGS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getDrugPrice = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        const datakey = new Set();
        const dlist = [];
        response.data.data.hits.forEach((d) => {
          if (datakey.has(d._source.Id) === false) {
            datakey.add(d._source.Id);
            dlist.push(d);
          }
        });
        dispatch({
          type: DrugsActionTypes.DRUGS_PRICELIST,
          payload: dlist,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DrugsActionTypes.DRUGS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getOTCDrugs = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        const datakey = new Set();
        const dlist = [];
        response?.data?.data.hits.forEach((d) => {
          if (datakey.has(d._source.Id) === false) {
            datakey.add(d._source.Id);
            dlist.push(d);
          }
        });
        dispatch({
          type: DrugsActionTypes.OTCDRUGS_LIST,
          payload: dlist,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DrugsActionTypes.DRUGS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getDrugotcPrice = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        const datakey = new Set();
        const dlist = [];
        response.data.data.hits.forEach((d) => {
          if (datakey.has(d._source.Id) === false) {
            datakey.add(d._source.Id);
            dlist.push(d);
          }
        });
        dispatch({
          type: DrugsActionTypes.OTCDRUGS_PRICELIST,
          payload: dlist,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DrugsActionTypes.DRUGS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const setPatientDrugs = (payload) => (dispatch) => {
  return setPatientDrugsService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // log.info('drugs fetch error: ', message)
      dispatch({
        type: DrugsActionTypes.DRUGS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const updatePatientDrugs = (payload) => (dispatch) => {
  return updatePatientDrugsService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // log.info('drugs fetch error: ', message)
      dispatch({
        type: DrugsActionTypes.DRUGS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getPatientDrugs = (payload) => (dispatch) => {
  return getPatientDrugsService(payload).then(
    (response) => {
      dispatch({
        type: DrugsActionTypes.DRUGS_PATIENTLIST,
        payload: response?.data?.results,
      });
      return Promise.resolve(response?.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response?.data &&
          error.response?.data?.message) ||
        error.message ||
        error.toString();
      // log.info('drugs fetch error: ', message)
      dispatch({
        type: DrugsActionTypes.DRUGS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getDrugsOrderlist = (payload) => (dispatch) => {
  return getMedicinelistService(payload).then(
    (response) => {
      dispatch({
        type: DrugsActionTypes.DRUGSORDERS,
        payload: response.data,
      });
      return Promise.resolve(response?.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response?.data &&
          error.response?.data?.message) ||
        error.message ||
        error.toString();
      // log.info('drugs fetch error: ', message)
      dispatch({
        type: DrugsActionTypes.DRUGS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const setDrugsOrderlist = (payload) => (dispatch) => {
  return setMedicinelistService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // log.info('drugs fetch error: ', message)
      dispatch({
        type: DrugsActionTypes.DRUGS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const updateDrugsOrderlist = (payload) => (dispatch) => {
  return updateMedicinelistService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // log.info('drugs fetch error: ', message)
      dispatch({
        type: DrugsActionTypes.DRUGS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};
