import * as React from "react";
import {
  StyledTableRowHead,
  StyledTableCellHead,
} from "../common/ConstantsAndValues";

export const pTable = {
  title: {
    label: "Invoice",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  descrip: {
    label: "Detail",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  colParty: {
    label: "Collected from",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  amount: {
    label: "Amount",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  payMethod: {
    label: "Method",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  payType: {
    label: "Type",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  payNote: {
    label: "Note",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  transactionNo: {
    label: "Transaction",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  completedAt: {
    label: "Paid on",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
};

export function PartyLedgerORGHead() {
  const table = pTable;
  return (
    <React.Fragment>
      <StyledTableRowHead style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead>{table.title.label}</StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.amount.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.completedAt.label}
        </StyledTableCellHead>
      </StyledTableRowHead>
    </React.Fragment>
  );
}
