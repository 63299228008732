import React, { useEffect, useState, Component, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Tab, Tabs, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { PDFViewer } from "@react-pdf/renderer";
import { themeTABS, StyledTab } from "../common/ConstantsAndValues";
import { DiagnosticsChoice } from "./DiagnosticsChoice";
import Prescription from "./templates/Prescription";
import Insurance from "./templates/Insurance";
import { ClinicalRadTestsData } from "../../tests/ClinicalRadTestsData";

import { stylesLogo } from "./styles/CommonStyles";

const styles = stylesLogo;

const getCN = [
  {
    type: "paragraph",
    children: [
      { text: "8/12/2023, 6:38:07 PM: : Metalane system user" + "\n " },
      { text: "COMPLAIN:" + "\n\n " },
      { text: "Severe chest pain and vomiting " + "\n " },
      { text: "OBSERVATIONS/ DIAGNOSTICS:" + "\n " },
      {
        text:
          "speciaists' observation and diagnostics here speciaists' observation and diagnostics here specialists' observation and diagnostics here speciaists' \n newline newline \n\n newline newline newline \n\n\n observation and diagnostics here specialists' observation and diagnostics here speciaists' observation and diagnostics here speciaists' observation and diagnostics here" +
          "\n ",
      },
      { text: "NOTES:" + "\n " },
      { text: "some notes" + "\n " },
    ],
  },
];

//console.log('cn', getCN);
//console.log('cn', getCN[0].children);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={0} mt={2}>
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

export const MetaPdfCreateViewer = () => {
  const sampleTitle = "Prescription";
  const sampleTitle2 = "Insurance Policy";
  const sampleContent = ClinicalRadTestsData;

  //ASSUMED
  const reporteventsmask = useSelector((state) => state?.reportevents?.mask);

  const dispatch = useDispatch();

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const CHOICE_TAB = 0;
  const PDF_TAB = 1;
  const PDF_TAB2 = 2;

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  const handleProceed = () => {
    setValue(PDF_TAB);
  };

  //ASSUMED
  let newmask = reporteventsmask
    ?.filter((item) => item.isChecked)
    .map((item) => item._id);

  //console.log('ttt', newmask.filter(item => item.includes('presAll')).length );
  //console.log('ttt1', newmask.filter(item => item.includes('presRT' )).length );

  return (
    <div className={classes.root}>
      <ThemeProvider theme={themeTABS}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label=""
            selectionFollowsFocus={true}
          >
            <StyledTab
              label="My Choice"
              {...a11yProps(CHOICE_TAB)}
              className="text-color"
              disableFocusRipple={true}
              disableRipple={true}
              sx={{
                boxShadow: "0 !important",
                outline: "none",
                "&:focus": {
                  border: "0 !important",
                  outline: "none",
                  boxShadow: "none",
                },
              }}
            />
            <StyledTab
              label="Prescription PDF"
              {...a11yProps(PDF_TAB)}
              className="text-color"
              disableFocusRipple={true}
              disableRipple={true}
              sx={{
                boxShadow: "0 !important",
                outline: "none",
                "&:focus": {
                  border: "0 !important",
                  outline: "none",
                  boxShadow: "none",
                },
              }}
            />
            <StyledTab
              label="Insurance PDF"
              {...a11yProps(PDF_TAB2)}
              className="text-color"
              disableFocusRipple={true}
              disableRipple={true}
              sx={{
                boxShadow: "0 !important",
                outline: "none",
                "&:focus": {
                  border: "0 !important",
                  outline: "none",
                  boxShadow: "none",
                },
              }}
            />
          </Tabs>
        </AppBar>
      </ThemeProvider>

      <TabPanel value={value} index={CHOICE_TAB}>
        <DiagnosticsChoice handleProceed={handleProceed} />
      </TabPanel>

      <TabPanel value={value} index={PDF_TAB}>
        <PDFViewer width="100%" height="600px" style={styles.viewer}>
          {(newmask?.filter((item) => item.includes("presAll")).length != 0 ||
            newmask?.filter((item) => item.includes("presCN")).length != 0 ||
            newmask?.filter((item) => item.includes("presPD")).length != 0 ||
            newmask?.filter((item) => item.includes("presCT")).length != 0 ||
            newmask?.filter((item) => item.includes("presRT")).length != 0) && (
            <Prescription
              eventsmask={newmask}
              title={sampleTitle}
              content={sampleContent}
              getCN={getCN[0]}
            />
          )}
        </PDFViewer>
      </TabPanel>

      <TabPanel value={value} index={PDF_TAB2}>
        <PDFViewer width="100%" height="600px" style={styles.viewer}>
          {(newmask?.filter((item) => item.includes("presAll")).length != 0 ||
            newmask?.filter((item) => item.includes("presIN")).length != 0) && (
            <Insurance
              eventsmask={newmask}
              title={sampleTitle2}
              content={sampleContent}
              getCN={getCN[0]}
            />
          )}
        </PDFViewer>
      </TabPanel>
    </div>
  );
};
