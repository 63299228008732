import React, { useEffect, useState } from "react";

export const AttachmentIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8958 8.86659L16.9038 8.87124L12.4804 16.5328L12.4762 16.5303C12.1368 17.0972 11.5899 17.5104 10.9507 17.6817C10.2984 17.8564 9.60348 17.7649 9.01868 17.4273C8.43389 17.0897 8.00717 16.5336 7.8324 15.8813C7.66397 15.2527 7.74284 14.5845 8.05098 14.0134C8.06881 13.9686 8.09009 13.9245 8.11491 13.8816L12.1589 6.87716C12.4364 6.39648 13.0511 6.23179 13.5318 6.50931C14.0124 6.78683 14.1771 7.40147 13.8996 7.88215L9.85561 14.8866C9.84338 14.9077 9.83049 14.9283 9.81699 14.9483L9.81718 14.9484C9.74452 15.0742 9.72483 15.2238 9.76244 15.3642C9.80006 15.5045 9.89189 15.6242 10.0177 15.6969C10.1436 15.7696 10.2932 15.7892 10.4335 15.7516C10.5739 15.714 10.6936 15.6222 10.7663 15.4963L10.7686 15.4977L15.0634 8.05891L15.0622 8.05819C15.3431 7.57166 15.4192 6.99347 15.2738 6.45082C15.1284 5.90816 14.7734 5.4455 14.2869 5.1646C13.8003 4.8837 13.2221 4.80758 12.6795 4.95298C12.1739 5.08846 11.7377 5.4059 11.4533 5.84214L7.00722 13.543C6.65202 14.3088 6.57455 15.1785 6.79479 16.0004C7.04302 16.9268 7.64908 17.7167 8.47966 18.1962C9.31024 18.6757 10.2973 18.8057 11.2237 18.5575C12.1501 18.3092 12.9399 17.7032 13.4195 16.8726L13.4205 16.8732L16.3352 11.8248C16.6114 11.3465 17.223 11.1826 17.7012 11.4588C18.1795 11.7349 18.3434 12.3465 18.0673 12.8248L15.0367 18.0739L15.0222 18.0655C14.2708 19.2511 13.0998 20.1142 11.7386 20.479C10.3025 20.8637 8.77251 20.6623 7.48502 19.919C6.19753 19.1756 5.25806 17.9513 4.87329 16.5153C4.51817 15.19 4.66238 13.7845 5.27109 12.5636L5.26523 12.5602L5.33463 12.44C5.36637 12.3801 5.39926 12.3207 5.43328 12.2618L5.43648 12.2636L9.66391 4.94149L9.6631 4.94102C9.67776 4.91562 9.69268 4.89041 9.70785 4.86538L9.77485 4.74933L9.77922 4.75185C10.3318 3.90373 11.1796 3.28657 12.1624 3.02323C13.2169 2.74069 14.3404 2.8886 15.2858 3.43443C16.2312 3.98025 16.921 4.87928 17.2036 5.93374C17.4674 6.91822 17.3559 7.96288 16.8958 8.86659Z"
        fill="#585858"
      />
    </svg>
  );
};
