import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";
import styled from "styled-components";
import { AdminMenu } from "../common/menuSideBar";
import { PageContent, menuListMetaFin } from "../common/ConstantsAndValues";

export default function FinanceDashboard(props) {
  const { auth } = props;
  const isAuthorized =
    auth?.user?.user?.metadata?.role === "metasys" ||
    auth?.user?.user?.metadata?.role === "metafin";

  return isAuthorized ? (
    <div className="wrapper d-flex h-100">
      <AdminMenu menuList={menuListMetaFin}> </AdminMenu>
      <PageContent className="bg-gradient-menu">
        <Outlet />
      </PageContent>
    </div>
  ) : (
    <Navigate to="/sign" />
  );
}
