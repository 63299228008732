import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Title from "../common/Title";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../common/ConstantsAndValues";

//
function createData(id, date, details, source, readFlag, replyFlag) {
  return { id, date, details, source, readFlag, replyFlag };
}

const TopHeading = (props) => (
  <TableCell
    className="text-color-primary"
    display="flex"
    sx={{
      //fontSize:  '18px',
      fontWeight: 200,
    }}
  >
    {props.children}
  </TableCell>
);
const rows = [
  createData(0, "16 Mar, 2022", "please read appt process well", "rk", 1, 1),
  createData(1, "17 Mar, 2022", "appointment request", "me", 1, 0),
  createData(2, "18 Mar, 2022", "reschedule appointment ", "Boston", 1, 1),
  createData(3, "19 Mar, 2022", "do take appt", "rk", 1, 0),
  createData(4, "20 Mar, 2022", "please collect reports", "labs", 0, 0),
];

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function AppointmentList() {
  const classes = useStyles();

  return (
    <React.Fragment>
      {/*<Title>Recent Appointments</Title>*/}
      <Table size="small" className="text-color" width="100%">
        <TableHead>
          <StyledTableRowHead>
            <StyledTableCellHead>Date</StyledTableCellHead>
            <StyledTableCellHead>Appointment</StyledTableCellHead>
            <StyledTableCellHead>Source</StyledTableCellHead>
          </StyledTableRowHead>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell>{row.date}</StyledTableCell>
              <StyledTableCell>{row.details}</StyledTableCell>
              <StyledTableCell>{row.source}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <div className="text-color">
        <Link className="text-color" href="#" onClick={preventDefault}>
          {/*    more...*/}
        </Link>
      </div>
    </React.Fragment>
  );
}
