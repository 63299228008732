import React, { useEffect, useState } from "react";

const LinkedinIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <path
        d="M0.820312 1.99134C0.820312 1.41413 1.02302 0.937939 1.42842 0.56277C1.83382 0.187584 2.36086 0 3.0095 0C3.64657 0 4.16201 0.184693 4.55583 0.554112C4.96123 0.935065 5.16394 1.43145 5.16394 2.04329C5.16394 2.5974 4.96704 3.05915 4.57321 3.42857C4.16781 3.80952 3.63498 4 2.97475 4H2.95738C2.32031 4 1.80487 3.80952 1.41105 3.42857C1.01722 3.04762 0.820312 2.56854 0.820312 1.99134ZM2.97475 17.1429C1.90963 17.1429 1.04618 16.2794 1.04618 15.2143V7.50432C1.04618 6.4392 1.90963 5.57576 2.97475 5.57576C4.03987 5.57576 4.90332 6.43921 4.90332 7.50433V15.2143C4.90332 16.2794 4.03987 17.1429 2.97475 17.1429ZM7.05253 15.2076C7.04627 16.2741 7.90879 17.1429 8.97537 17.1429C10.0369 17.1429 10.8975 16.2823 10.8975 15.2207V10.684C10.8975 10.2799 10.9439 9.96824 11.0365 9.74891C11.1987 9.35641 11.4448 9.02452 11.7749 8.75324C12.1051 8.48195 12.5191 8.34632 13.0172 8.34632C14.3145 8.34632 14.9632 9.21788 14.9632 10.961V15.2143C14.9632 16.2794 15.8266 17.1429 16.8917 17.1429C17.9569 17.1429 18.8203 16.2794 18.8203 15.2143V10.5108C18.8203 8.8023 18.4149 7.50649 17.6041 6.62337C16.7933 5.74026 15.7219 5.2987 14.3898 5.2987C12.8982 5.2987 11.7356 5.93716 10.9019 7.21409C10.8991 7.21842 10.8975 7.22353 10.8975 7.2287V7.24878C10.8975 7.25244 10.8946 7.25541 10.8909 7.25541C10.886 7.25541 10.8828 7.25021 10.885 7.2458L10.8953 7.22519C10.8968 7.22229 10.8975 7.21908 10.8975 7.21584V6.90583C10.8975 6.17125 10.302 5.57576 9.56745 5.57576H9.02936C7.95161 5.57576 7.06753 6.48635 7.07232 7.56409C7.0742 7.98674 7.07514 8.47259 7.07514 9.02164C7.07514 10.5766 7.0676 12.6386 7.05253 15.2076Z"
        fill="#19445E"
      />
    </svg>
  );
};
export default LinkedinIcon;
