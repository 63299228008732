import * as React from "react";
import { Grid, Paper, Box, Container, Stack, Typography } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
//import Typography                                          from "@material-ui/core/Typography";

import { colWidth4, colWidthAccordion } from "../common/ConstantsAndValues";

const widthRight = colWidth4.width || "10%";
const widthLeft = "33%";

const MyAccordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const MyAccordionSummary = withStyles({
  root: {
    backgroundColor: "var(--fc-neutral-bg-color)",
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    flexGrow: 12,
    "&$expanded": {
      flexGrow: 1,
      margin: "12px 0",
    },
  },
  expanded: {},
  expandIcon: {
    order: 0,
  },
})(AccordionSummary);

const MyAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

export function HealthAccordion(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid p={2} item xs component="div" className="bg-gray">
      {props.data.action.map((aco, i) => (
        <MyAccordion
          key={i}
          square
          expanded={expanded === "panel" + i}
          onChange={handleChange("panel" + i)}
        >
          <MyAccordionSummary
            className="border-db"
            aria-controls={"panel" + i + "d-content"}
            expandIcon={<ExpandMoreIcon />}
            id={"panel" + i + "d-header"}
          >
            <Stack
              sx={{
                pl: 0,
                width: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
              }}
              className="flex-grow-1"
            >
              <Box component="div" width={150} key={aco._id + "asl"}>
                <Typography>
                  <span className="text-color-dark">
                    <b>{aco._id}</b>:<br />
                  </span>
                  {aco.title}
                </Typography>
              </Box>
              <Box
                component="div"
                className="border-db"
                width={"auto"}
                key={aco._id + "assl"}
              >
                <Typography className="text-color ">
                  <b>{aco.label}</b>
                </Typography>
              </Box>
            </Stack>
          </MyAccordionSummary>

          <MyAccordionDetails className="bg-standard-offwhite ">
            <Stack
              sx={{
                pl: 0,
                width: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                //justifyContent: "space-between",
              }}
              className="flex-grow-1"
            >
              <Box component="div" width={220} key={aco._id + "adl"}>
                <Typography> </Typography>
              </Box>
              <Box component="div" key={aco._id + "adsl"} className="notes2">
                <Typography>{aco.detail}</Typography>
              </Box>
            </Stack>
          </MyAccordionDetails>
        </MyAccordion>
      ))}
    </Grid>
  );
}
