import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone";
import { useDispatch } from "react-redux";
import { setEventSelectDate } from "./redux/actions";
import { MyButtons, MyFullButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";

export const AppointmentStepper = (props) => {
  const {
    steps,
    setActiveNextStep,
    setActiveBackStep,
    setDisableContinue,
    disableContinue,
    dataFields,
    handleSubmit,
  } = props;

  const [selectedReason, setSelectedReason] = useState({});
  const [alertMsg, setAlertMsg] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const dispatch = useDispatch();

  const handleNext = () => {
    const nextstep = (prevActiveStep) => prevActiveStep + 1;
    setActiveStep(nextstep);
    setDisableContinue(true);
    setActiveNextStep(nextstep);
  };

  const handleBack = () => {
    const backstep = (prevActiveStep) => prevActiveStep - 1;
    setActiveStep(backstep);
    setActiveBackStep(backstep);
    setDisableContinue(true);
  };

  const onContinue = () => {
    log.info("appointment continue");
    // if (!checkFormValid()) {
    //     log.info("form validation failed");
    //     return false;
    // }
    if (activeStep === 0) {
      /**
       * reset for following states
       * reset date to pick it up again
       */
      dispatch(setEventSelectDate(false));
    } else if (activeStep === 1) {
      // setPayload({...payload, servicetype: selectService?.label})
    } else if (activeStep === 2) {
      // setPayload({...payload, services:services})
    } else if (activeStep === 3) {
      // setPayload({...payload, testtype: selectedReason?.name, testId: selectedReason?.id})
    } else if (activeStep === 4) {
    }

    setAlertMsg("");
    handleNext();
  };

  return (
    <>
      <Typography fontWeight="600" className="mb-4 text-color-primary">
        <ListAltTwoToneIcon />
        &nbsp; Appointment Details
      </Typography>
      <Stepper connector={<></>} activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <span className="text-color-dark">{step.label}</span>
            </StepLabel>
            <StepContent
              style={{ minHeight: "20px" }}
              TransitionProps={{ in: true }}
            >
              {index === 0 ? (
                <Typography
                  className="text-color-primary"
                  fontWeight="600"
                  variant="caption"
                  display="block"
                >
                  {dataFields?.userName}
                </Typography>
              ) : (
                <></>
              )}

              {index === 1 && (
                <Typography
                  className="text-color-primary"
                  fontWeight="600"
                  variant="caption"
                  display="block"
                >
                  {dataFields?.eventTitle}
                </Typography>
              )}

              {index === 2 && (
                <Typography
                  key={"srvno" + index}
                  className="text-color-primary"
                  fontWeight="600"
                  variant="caption"
                  display="block"
                >
                  {dataFields?.providerName}, {dataFields?.address}
                </Typography>
              )}

              {index === 3 && (
                <Typography
                  key={"srvno" + index}
                  className="text-color-primary"
                  fontWeight="600"
                  variant="caption"
                  display="block"
                >
                  {dataFields?.dateStart}
                </Typography>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length - 1 ? (
        <MyFullButtons
          onClick={handleSubmit}
          btnKey={"next"}
          btnLabel={"Submit"}
          enState={disableContinue}
        />
      ) : (
        <MyFullButtons
          onClick={onContinue}
          btnKey={"next"}
          btnLabel={"Continue"}
          enState={disableContinue}
        />
      )}

      <div className="clear">&nbsp;</div>

      <MyFullButtons
        onClick={() => {
          if (activeStep > 0) {
            handleBack();
          }
          setAlertMsg("");
        }}
        btnKey={"back"}
        btnLabel={"Previous"}
        enState={false}
      />
    </>
  );
};
