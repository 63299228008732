import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import React from "react";
import MyAccountSetup from "../../payments/MyAccountSetup";

export function SetProfileListMD() {
  return (
    <TableContainer component={Paper}>
      <MyAccountSetup />
    </TableContainer>
  );
}
