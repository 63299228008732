import * as React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Link, TableCell } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getAlertList, getAlerts } from "../../redux/actions/alerts";

import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";

import { SubContentStyle } from "../common/ConstantsAndValues";
import { Title } from "../common/Title";
import MetaAlertTable from "./MetaAlertTable";
import SearchDialog from "./SearchDialog";
import log from "../../services/log";
import InsertCommentTwoToneIcon from "@mui/icons-material/InsertCommentTwoTone";
import AlertDialog from "./AlertDialog";
import { postMessage, postMessageList } from "../../redux/actions/message";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function AlertItemsList() {
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const profile = useSelector((state) => state.auth);
  const sendto = useSelector((state) => state.user.selectedUser);
  const [sendTo, setSendTo] = useState(
    sendto
      ? {
          sourceName: sendto.userName,
          groupId: sendto.groupId,
          sourceId: sendto.accountId,
        }
      : null
  );

  const [postType, setPostType] = useState("");
  const [open, setDialogOpen] = useState(false);
  const [dialog, setDialog] = useState("post");
  const [alertlist, setAlertList] = useState([]);
  const [addTo, setAddTo] = useState(user.name);
  const [addToTitle, setAddToTitle] = useState("");
  const [addToMsg, setAddToMsg] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getAlerts({
        metadata: {
          receiverId: user.id,
        },
      })
    );
  }, [user]);

  const handleSearchClick = () => {
    setDialogOpen(true);
    setPostType("Search Message");
    setDialog("search");
  };

  const handlePostClick = () => {
    setDialogOpen(user.role === "metaorg" ? true : false);
    setPostType("Notification Message");
    setDialog("post");
  };

  const postMsg = (eventData) => {
    log.info("messagelist", sendTo);
    dispatch(postMessage(eventData)).then((r) => {
      r.map((m) => {
        const listdata = {};
        listdata.metadata = {};
        listdata.messageId = m.id;
        listdata.isRead = false;
        listdata.metadata.accountId = sendTo.sourceId;
        listdata.metadata.groupId = sendTo.groupId;
        listdata.sourceId = user.metadata.accountId;
        listdata.sourceName = user.name;
        dispatch(postMessageList(listdata));
      });
    });
  };

  // const addToTitle = (msg) => {
  // }
  //
  // const addToMsg = (msg) => {
  // }
  //
  // const addTo = (msg) => {
  // }

  const doSearch = (strSearch) => {
    console.log("srch str:", strSearch); //got the search string here
  };

  //log.info("alerts", open);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item width="100%" p={0}>
          <SubContentStyle>
            <Box className="colfl" pt={1}>
              <Title>
                <CircleNotificationsIcon /> Notifications
              </Title>
            </Box>

            <Box
              pt={1}
              href="#"
              onClick={handleSearchClick}
              title="Search"
              className="btn border-db btn-icon btn-round text-color colfr"
            >
              <SearchTwoToneIcon />
            </Box>

            {user.role == "metaorg" && (
              <Box
                pt={1}
                mr={1}
                href="#"
                onClick={handlePostClick}
                title="Add Message"
                className="btn border-db btn-icon btn-round text-color colfr"
              >
                <InsertCommentTwoToneIcon />
              </Box>
            )}

            {open && dialog === "search" && (
              <SearchDialog
                setOpen={setDialogOpen}
                open={open}
                doSearch={doSearch}
              />
            )}

            {open && dialog === "post" && (
              <AlertDialog
                setDialogOpen={setDialogOpen}
                postType={postType}
                open={open}
                post={postMsg}
                addTo={addTo}
                addToTitle={addToTitle}
                addToMsg={addToMsg}
              />
            )}
          </SubContentStyle>
        </Grid>
        <Grid item p={0} width="100%" className="clear">
          <MetaAlertTable reply={false} mrows={alertlist} />

          <div className="text-color">
            <Link className="text-color" href="#" onClick={preventDefault}>
              {/*    more...*/}
            </Link>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
