import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useOutletContext } from "react-router-dom";
import {
  pageOptions,
  tableParam,
  colWidthSign,
  SpanAnchor,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../../common/ConstantsAndValues";

import { RadiologyReportsMDD } from "../../othertests/RadiologyReportsMDD";
import { selectedPatient } from "../../../redux/user/user.actions";

import log from "../../../services/log";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export function RadiologyListMD(props) {
  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );
  const selectedPatient = useSelector((state) => state?.user?.selectedPatient);
  const medicines = useSelector((state) => state?.drugs?.patientlist);

  const [markDates, dateTest, auth, eventType] = useOutletContext();
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState(medicines);
  const [checkedRows, setCheckedRows] = useState([]);
  const classes = useStyles();

  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);

  useEffect(() => {
    if (selectedPatient) {
      // dispatch(getPatientDrugs(
      //     {
      //         metadata: {
      //             patientId: selectedPatient.accountId,
      //         }
      //     }));
    }
  }, [selectedPatient]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = medicines.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(medicines);
    }
  };

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: value };
    //log.info("patient druglist", drugsquery("name", query.name, newpagedata));
    //dispatch(getDrugs(drugsquery("name", query.name, newpagedata))).then((r) => {
    // log.info("drugslist", r)
    // if (r.total.value && r.total.value > 0) {
    //     setPage({... pagedata,
    //         count: r.total.value,
    //         pages: r.total.value/pagedata.limit,
    //     });
    // }
    //});
    setPage(newpagedata);
  };
  const handleChangeRows = (event, value) => {
    //pagination part
    setPage({ ...pagedata, limit: value });
  };

  const handleRowClick = (e) => {
    var updatedList = [...checkedRows];

    if (findArrayElement(updatedList, e)) {
      updatedList.splice(checkedRows.indexOf(e), 1);
    } else {
      updatedList = [...checkedRows, e];
    }

    setCheckedRows(updatedList);
    //console.log("Prescribe", updatedList);
  };

  const handlePrescribe = () => {
    console.log("Prescribe/Repeat", checkedRows);
  };

  const findArrayElement = function (array, title) {
    return array.find((item) => {
      return item === title;
    });
  };

  //log.info("medicinelist rows 111111", rows);

  return (
    <Grid container>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Radiology Test"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div></div>
      </div>

      <RadiologyReportsMDD reportslist={rows} />
    </Grid>
  );
}
