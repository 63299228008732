import OHIF from "../../core";
import ViewerMain from "./ViewerMain";
import { connect } from "react-redux";

const { setViewportSpecificData, clearViewportSpecificData } =
  OHIF.reduxdicom.actions;

const mapStateToProps = (state) => {
  const { activeViewportIndex, layout, viewportSpecificData } = state.viewports;

  return {
    activeViewportIndex,
    layout,
    viewportSpecificData,
    viewports: state.viewports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setViewportSpecificData: (viewportIndex, data) => {
      console.log("viewportdata", data);
      dispatch(setViewportSpecificData(viewportIndex, data));
    },
    clearViewportSpecificData: () => {
      dispatch(clearViewportSpecificData());
    },
  };
};

const ConnectedViewerMain = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewerMain);

export default ConnectedViewerMain;
