import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OtherTestsPresList } from "../../othertests/OtherTestsPresList";
import {
  setRadlabsOrder,
  setRadlabsOrderPresList,
} from "../../../redux/labs/labs.actions";
import { MyTooltipButtons } from "../../CustomComponents/MyButtons";

import log from "../../../services/log";

export const DicomNewPresView = (props) => {
  const { cancel } = props;

  const preslist = useSelector((state) => state?.labs?.radlabpreslist);
  const [checkedRows, setCheckedRows] = useState(null);
  const [action, setAction] = useState("");

  const dispatch = useDispatch();

  const handleRemove = () => {
    log.info("prescribelist remove");
    dispatch(setRadlabsOrderPresList([]));
  };

  const handlePost = () => {
    // log.info("prescribelist post");
    preslist.forEach((d) => {
      dispatch(setRadlabsOrder(d));
    });
    // reset list after post
    dispatch(setRadlabsOrderPresList([]));
  };

  const handleSelectRows = (row) => {
    log.info("drugslist md", row);
    setCheckedRows(row);
  };

  const handleCancel = () => {
    setAction("");
    cancel();
  };

  const handleReset = () => {
    // log.info("prescribelist reset");
    dispatch(setRadlabsOrderPresList([]));
  };

  log.info("dicom pres list", preslist);

  return (
    <div>
      <div className="colfr0">
        <MyTooltipButtons
          onClick={handlePost}
          btnKey={"post"}
          btnLabel={"Post"}
          enState={false}
        />
        &nbsp; &nbsp;
        <MyTooltipButtons
          onClick={handleReset}
          btnKey={"reset"}
          btnLabel={"Reset"}
          enState={false}
        />
        &nbsp; &nbsp;
        <MyTooltipButtons
          onClick={handleRemove}
          btnKey={"remove"}
          btnLabel={"Remove"}
          enState={false}
        />
        &nbsp; &nbsp;
        <MyTooltipButtons
          onClick={handleCancel}
          btnKey={"cancel"}
          btnLabel={"Cancel"}
          enState={false}
        />
      </div>

      <OtherTestsPresList handleClose={handleCancel} othertestlist={preslist} />
    </div>
  );
};
