import React from "react";
import MainBar from "./MainBar";
import PatientNavbar from "./PatientNavbar";
// import MainBarPatient from "./MainbarPatient";
import { rswitch } from "../../utils/helpers";
import LandingNavbar from "./LandingNavbar";
import { useLocation } from "react-router-dom";

const Header = ({ auth }) => {
  const role = auth?.user?.user?.metadata?.role;
  const isAuthorized =
    auth?.user?.user?.metadata?.role === "metasys" ||
    auth?.user?.user?.metadata?.role === "metauser";

  const location = useLocation();

  return (
    <div>
      {isAuthorized ? (
        <>
          {rswitch(role, {
            metauser: <PatientNavbar />,
            default: <MainBar />,
          })}
        </>
      ) : location.pathname === "/" ||
        location.pathname === "/terms" ||
        location.pathname === "/privacypolicy" ? (
        <LandingNavbar />
      ) : (
        <MainBar />
      )}
    </div>
  );
};

export default Header;
