import { Box, Grid, Typography } from "@mui/material";
import illustrationImg from "../../assets/images/homepage/HomeEllipseIllustration.svg";
import illustrationSmallImg from "../../assets/images/homepage/HomeEllipseIllustrationSmall.svg";
import illustrationMobileImg from "../../assets/images/homepage/HomeEllipseIllustrationMobile.svg";
import { useWindowSize } from "../common/ConstantsAndValues";

export default function HeroSection() {
  const size = useWindowSize();

  // // for big screens
  // let bgHeightBigScreen =
  //   size.width >= 900 ? 140 + ((900 - size.width) / 1000) * 35 : 140;

  // // for small screeens
  // let bgHeightSmallScreen =
  //   size.width <= 900 ? 100 + ((900 - size.width) / 1000) * 125 : 100;

  // // final screen size check
  // let finalHeight = size.width > 900 ? bgHeightBigScreen : bgHeightSmallScreen;

  return (
    <Box
      sx={{
        position: "relative",
        height: { xs: "65vh", md: "80vh" },
      }}
    >
      <Box sx={{ position: "absolute", bottom: "0", width: "100%" }}>
        <img
          className="newImageContainer"
          src={
            size.width && size.width < 400
              ? illustrationMobileImg
              : size.width < 767
              ? illustrationSmallImg
              : illustrationImg
          }
          alt="illustration"
        />
      </Box>
      <Box sx={{ position: "absolute", top: { xs: "35%", md: "45%" } }}>
        <Grid container>
          <Grid item xs={12} sm={10} md={8} lg={6} px={{ xs: 6, md: 8 }}>
            <Typography
              sx={{
                fontSize: { xs: "35px", md: "52px" },
                lineHeight: { xs: 1.3, md: 1.5 },
                color: "#404040",
              }}
            >
              Ease into Healthcare
            </Typography>
            <Typography sx={{ fontSize: "18px", mt: { xs: 1, md: 0 } }}>
              Metalane aims to revolutionize corporate wellness in India by
              providing comprehensive and integrated wellness solutions as a
              core component of employee benefits packages.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
    // <Box
    //   id="sectionOne"
    //   className="imgContainer"
    //   sx={{
    //     backgroundImage: {
    //       xs: `url(${illustrationMobileImg})`,
    //       sm: `url(${illustrationSmallImg})`,
    //       md: `url(${illustrationImg})`,
    //     },
    //     backgroundRepeat: "no-repeat",
    //     height: { xs: "65vh", md: "80vh" },
    //     paddingTop: { xs: "45%", sm: "25%", lg: "15%" },
    //   }}
    // >
    //   <Grid container>
    //     <Grid
    //       item
    //       xs={12}
    //       sm={10}
    //       md={8}
    //       lg={6}
    //       px={{ xs: 6, md: 8 }}
    //       // py={{ xs: 8, md: 16 }}
    //     >
    //       <Typography
    //         sx={{
    //           fontSize: { xs: "35px", md: "52px" },
    //           lineHeight: { xs: 1.3, md: 1.5 },
    //           color: "#404040",
    //         }}
    //       >
    //         Ease into Healthcare
    //       </Typography>
    //       <Typography sx={{ fontSize: "18px", mt: { xs: 1, md: 0 } }}>
    //         Metalane aims to revolutionize corporate wellness in India by
    //         providing comprehensive and integrated wellness solutions as a core
    //         component of employee benefits packages.
    //       </Typography>
    //     </Grid>
    //   </Grid>
    // </Box>
  );
}
