import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesAddress } from "../styles/CommonStyles";

const styles = stylesAddress;

export const InsuranceDetail = ({ items }) => {
  return (
    <>
      {items &&
        items.map((item, index) => (
          <>
            <View
              style={styles.clientContainer}
              key={"dd0" + index + item.id.toString()}
            >
              <View
                style={styles.detContainer}
                key={"d0" + index + item.id.toString()}
              >
                <Text style={styles.detaillabel}>Policy No:</Text>
                <Text style={styles.detail}>{item.policy_no}</Text>
              </View>
              <View
                style={styles.detContainer}
                key={"d1" + index + item.id.toString()}
              >
                <Text style={styles.detaillabel}>Policy Date:</Text>
                <Text style={styles.detail}>{item.policy_date}</Text>
              </View>
              <View
                style={styles.detContainer}
                key={"d2" + index + item.id.toString()}
              >
                <Text style={styles.detaillabel}>Plan:</Text>
                <Text style={styles.detail}>{item.plan}</Text>
              </View>
              <View
                style={styles.detContainer}
                key={"d3" + index + item.id.toString()}
              >
                <Text style={styles.detaillabel}>Provider:</Text>
                <Text style={styles.detail}>{item.provider}</Text>
              </View>
              <View
                style={styles.detContainer}
                key={"d4" + index + item.id.toString()}
              >
                <Text style={styles.detaillabel}>Period:</Text>
                <Text style={styles.detail}>
                  {item.policy_start_date} - {item.policy_end_date}
                </Text>
              </View>
              <View
                style={styles.detContainer}
                key={"d5" + index + item.id.toString()}
              >
                <Text style={styles.detaillabel}>Coverage:</Text>
                <Text style={styles.detail}>{item.cover}</Text>
              </View>
              <View
                style={styles.detContainer}
                key={"d6" + index + item.id.toString()}
              >
                <Text style={styles.detaillabel}>Subscriber:</Text>
                <Text style={styles.detail}>{item.policy_holder_name}</Text>
              </View>
            </View>
          </>
        ))}
    </>
  );
};
