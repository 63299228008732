import { ThemeTabs } from "../../tabs/ThemeTabs";

function MedicalRecords() {
  const tabs = [];
  return (
    <>
      <ThemeTabs tabs={tabs} />
    </>
  );
}
export default MedicalRecords;
