import React, { useState, useEffect, useLayoutEffect } from "react";
import { Grid, Box, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

import { PatientListPath, HeadUrl } from "../../common/ConstantsAndValues";
import * as componentsMap from "../../healthmonitor/conditions/";

export function HmoniCondMD() {
  const navigate = useNavigate();
  const [markDates, dateTest, auth, eventType, page] = useOutletContext();

  //const saved = localStorage.getItem("patient");
  //const patient = JSON.parse(saved);
  //console.log("Patient:", patient);
  //localStorage.setItem("prevURL", JSON.stringify(HeadUrl));
  //useEffect(() => {
  //    if (patient){
  //        if (patient.id === null){
  //            navigate(PatientListPath);
  //        }
  //    }else {
  //        navigate(PatientListPath);
  //    }
  //    console.log("Condition Page md", page);
  //},[patient.id, page])

  const MyPage = componentsMap[page];

  return (
    <TableContainer component={Paper}>
      {MyPage && (
        <MyPage
          page={page}
          markDates={markDates}
          //patient={patient.id}
        />
      )}
    </TableContainer>
  );
}
