import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Collapse,
  IconButton,
  TextField,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { TitleProper } from "../../common/Title";
import {
  StyledTableCell,
  StyledTableRow,
  tableParam,
} from "../../common/ConstantsAndValues";
import { MyIconButtons } from "../../CustomComponents/MyButtons";

import { getAddress } from "../../../redux/actions/locs";

import log from "../../../services/log";

function Row(props) {
  const { rowkey } = props;

  const locdata = useSelector((state) => state.locs.address);
  const [open, setOpen] = useState(false);

  const dateFormat = (v) => {
    return new Date(v).toLocaleDateString();
  };

  // console.log("profilelist", locdata)

  return (
    <React.Fragment>
      <StyledTableRow
        key={rowkey + "m"}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <StyledTableCell width="11px" key={rowkey + "test"}>
          <MyIconButtons onClick={() => setOpen(!open)} enState={open} />
        </StyledTableCell>

        <StyledTableCell component="th" scope="row" key={rowkey + "u1"}>
          {/*{row.type}*/}
        </StyledTableCell>

        <StyledTableCell align="right" key={rowkey + "u2"}>
          {locdata && (
            <Box key={locdata[0]?.id}>
              <Typography paragraph>{locdata[0]?.formatted_address}</Typography>
            </Box>
          )}
        </StyledTableCell>
      </StyledTableRow>

      <TableRow key={rowkey + "d"}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mt={2} ml={7} mb={2} className="colfl">
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                px={1}
                className="bg-gray"
              >
                Address update history
              </Typography>
              <Table
                size="small"
                aria-label="purchases"
                className="border-db"
                key={rowkey + "t"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {locdata &&
                    locdata.map((d) => (
                      <TableRow key={d.id}>
                        <TableCell component="th" scope="row" key={"date"}>
                          {dateFormat(d.updated_at)}
                        </TableCell>
                        <TableCell key={"data"}>
                          {d.street}, {d.formatted_address}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ProfileListData(props) {
  const { label, updateCallback } = props;
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const dispatch = useDispatch();

  // fetch latest address
  useEffect(() => {
    if (user) {
      const jsonData = {};
      jsonData.metadata = {};
      jsonData.metadata.userId = user?.metadata?.accountId;
      dispatch(getAddress(jsonData));
    }
  }, [user, dispatch]);

  const handleGroupClick = () => {
    console.log("clicked");
    updateCallback();
    // let title = aData.length ? aData[0].group : ""
    // //console.log('group',aData)
    // setOpen(true)
    // setAddTitle(title + ( (props.privacy) ? " Privacy " : " Group "  ) + " Update");
    // setAddEditable(aData);
  };

  // log.info("patientprofile", label);

  return (
    <Grid container>
      <Grid item xs>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table" width={"100%"}>
            <TableBody className="text-color-primary text text-md">
              <TableRow key={"h" + 0}>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  className="bg-gray"
                ></TableCell>

                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 4 }}
                  className="bg-gray"
                  key={"ht"}
                >
                  <TitleProper>{label}</TitleProper>
                </TableCell>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  className="bg-gray"
                  align="right"
                  colSpan={1}
                ></TableCell>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  className="bg-gray"
                ></TableCell>
                <TableCell
                  align="right"
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  className="bg-gray"
                  colSpan={2}
                >
                  <IconButton
                    className="text-color"
                    aria-label="update"
                    size="small"
                    onClick={handleGroupClick}
                  ></IconButton>
                </TableCell>
              </TableRow>

              <Row rowkey={label} dataKey={label} privacy={true} />
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
