import { orgsActionTypes } from "../actions/orgs.types";

const INITIAL_STATE = {
  orgs: null,
  orggroups: null,
  departments: null,
  selectdepartment: null,
  selectorg: null,
  selectorggorup: null,
  profile: null,
};

const orgsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case orgsActionTypes.ORGS:
      return {
        ...state,
        orgs: action.payload,
      };
    case orgsActionTypes.ORGGROUPS:
      return {
        ...state,
        orggroups: action.payload,
      };

    case orgsActionTypes.ORGDEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
    case orgsActionTypes.ORGDEPARTMENTADD:
      return {
        ...state,
        departmentadd: action.payload,
      };
    case orgsActionTypes.SELECTEDDEPARTMENT:
      return {
        ...state,
        selectdepartment: action.payload,
      };

    case orgsActionTypes.SELECTEDORG:
      return {
        ...state,
        selectorg: action.payload,
      };

    case orgsActionTypes.SELECTEDORGGROUP:
      return {
        ...state,
        selectorggroup: action.payload,
      };
    case orgsActionTypes.ORGABOUT:
      return {
        ...state,
        profile: action.payload,
      };
    case orgsActionTypes.ORG_PROFILE:
      return {
        ...state,
        orgprofile: action.payload,
      };

    default:
      return state;
  }
};

export default orgsReducer;
