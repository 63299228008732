import { MetaBmiDial } from "../../charts/BmiDial";
import { Grid } from "@mui/material";
import { BmiCard } from "./BmiCard";
import config_profileAnalytics from "../../charts/commons/speedoConstants";
import {
  SubContentStyle,
  ContentStyle,
  colChart,
} from "../../common/ConstantsAndValues";
import { Title } from "../../common/Title";

export function ProfileAnalytics() {
  const config = config_profileAnalytics;

  console.log(config.bmi);

  return (
    <ContentStyle>
      <Grid container mb={2}>
        <Grid item className="">
          <BmiCard
            currentValueText={"22"}
            title={config.bmi.title}
            avatar={config.bmi.avatar}
            values={config.bmi.values}
            custmSegmentStops={config.bmi.segmentStops}
            segmentColors={config.bmi.segmentColors}
            innerLabels={config.bmi.innerLabel}
            outerLabels={config.bmi.outerLabel}
            data={[]}
          />
        </Grid>
        <Grid item xs zeroMinWidth m={2}>
          <Title>Description</Title>
        </Grid>
      </Grid>
      <Grid container mb={2}>
        <Grid item className="">
          <BmiCard
            currentValueText={"120"}
            title={config.systolic.title}
            title={config.systolic.title}
            avatar={config.systolic.avatar}
            values={config.systolic.values}
            custmSegmentStops={config.systolic.segmentStops}
            segmentColors={config.systolic.segmentColors}
            innerLabels={config.systolic.innerLabel}
            outerLabels={config.systolic.outerLabel}
            data={[]}
          />
        </Grid>
        <Grid item xs zeroMinWidth m={2}>
          <Title>Description</Title>
        </Grid>
      </Grid>
      <Grid container mb={2}>
        <Grid item className="">
          <BmiCard
            currentValueText={"80"}
            title={config.diastolic.title}
            avatar={config.diastolic.avatar}
            values={config.diastolic.values}
            custmSegmentStops={config.diastolic.segmentStops}
            segmentColors={config.diastolic.segmentColors}
            innerLabels={config.diastolic.innerLabel}
            outerLabels={config.diastolic.outerLabel}
            data={[]}
          />
        </Grid>
        <Grid item xs zeroMinWidth m={2}>
          <Title>Description</Title>
        </Grid>
      </Grid>

      <Grid container mb={2}>
        <Grid item xs className="colfr">
          {/*<Grid container>*/}
          {/*    <Grid item>*/}
          {/*        <MetaBmiDial*/}
          {/*            currentValueText={"Systolic-"+config.systolic.values.value}*/}
          {/*            values={config.systolic.values}*/}
          {/*            custmSegmentStops={config.systolic.segmentStops}*/}
          {/*            segmentColors={config.systolic.segmentColors}*/}
          {/*            innerLabels={config.systolic.innerLabel}*/}
          {/*            outerLabels={config.systolic.outerLabel}*/}
          {/*        />*/}
          {/*    </Grid>*/}
          {/*    <Grid item>*/}
          {/*        <MetaBmiDial*/}
          {/*            currentValueText={"Diastolic-"+config.diastolic.values.value}*/}
          {/*            values={config.diastolic.values}*/}
          {/*            custmSegmentStops={config.diastolic.segmentStops}*/}
          {/*            segmentColors={config.diastolic.segmentColors}*/}
          {/*            innerLabels={config.diastolic.innerLabel}*/}
          {/*            outerLabels={config.diastolic.outerLabel}*/}
          {/*        />*/}
          {/*    </Grid>*/}
          {/*</Grid>*/}
        </Grid>
      </Grid>
    </ContentStyle>
  );
}
