import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTable } from "../styles/DiagnosticsStyles";

import { DrugsTableHeader } from "./DrugsTableHeader";
import { DrugsTableRows, DrugsTableRowsBlank } from "./DrugsTableRows";

const styles = stylesTable;

export const DrugsItemsTable = ({ diags, tableRowsCount }) => (
  <View style={styles.tableContainer}>
    <DrugsTableHeader />
    <DrugsTableRows items={diags} />
    {tableRowsCount !== 0 && (
      <DrugsTableRowsBlank rowsCount={tableRowsCount - diags.length} />
    )}
  </View>
);
