import React, { useEffect, useState } from "react";

const PreviousPageIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrow-left">
        <path
          id="Vector 9"
          d="M15.8594 16.7891L8.13281 12.3906L15.8594 8.21555V16.7891Z"
          fill="#585858"
          stroke="#585858"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export default PreviousPageIcon;
