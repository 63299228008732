// import node modules
import * as React from "react";

import { Box, Tabs, Tab, Typography } from "@mui/material";

import Select from "react-select";

// import other modules
import { deleteAllLocalStorage, rswitch } from "../../utils/helpers";
import {
  customStyles,
  // formattedArray,
  formattedArrayFamilyList,
} from "./TabsStaticData";

import { FindCareBody } from "../patient/components/FindCare";
import { LabResultsBody } from "../patient/components/TestResults";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAccessFamilyList } from "../../redux/user/user.actions";
import { LabResultsDetails } from "../patient/components/TestResultsDetails";
import {
  AppointmentsListBody,
  AppointmentsScheduleBody,
} from "../patient/components/Appointments";
import { Compose, Inbox, Sent } from "../patient/components/Messages";
import { logout } from "../../redux/actions/auth";

export function ThemeTabs({ tabs, history }) {
  const [isLoading, setLoading] = React.useState(false);
  const [familyData, setFamilyData] = React.useState([]);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const userList = useSelector((state) => state?.user?.userfamilylist?.results);
  const profile = useSelector((state) => state.auth.user.user);

  const isLoadingFamilyList = useSelector(
    (state) => state?.user?.userfamilylistloading
  );

  const handleChange = (event, newValue) => {
    // console.log(event, newValue);
    setValue(newValue);
  };

  const getFamilyList = async () => {
    setLoading(true);
    let params = { metadata: { accessId: profile?.metadata?.accountId } };
    dispatch(getUserAccessFamilyList(params));
    let res = userList;
    let dummyData = {
      created_at: profile.created_at,
      dob: "2010-06-14T21:06:06.853Z",
      id: profile.id,
      metadata: {
        // accessId: "b8ac5cc6-f720-46b6-9345-1ab2ecf58aad",
        role: profile.metadata.role,
        email: profile.metadata.email,
        accountId: profile.metadata.accountId,
      },
      name: "Self",
      status: "active",
      updated_at: profile.updated_at,
    };
    res.unshift(dummyData);
    setFamilyData(res);
    setLoading(false);
  };

  useEffect(() => {
    getFamilyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutfunction = () => {
    dispatch(logout());
    deleteAllLocalStorage();
    // toast.success("Logged Out Successfully");
    if (history) {
      history.push("/");
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderColor: "divider",
          backgroundColor: "rgba(233, 233, 233, 0.25)",
          borderBottom: "1px solid #8F8F8F",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            paddingTop: "0.5rem",
          }}
        >
          {tabs.length > 0 && (
            <Tabs
              TabIndicatorProps={{ style: { backgroundColor: "#2A8AA7" } }}
              value={value}
              sx={{ width: "100%" }}
              indicatorColor="inherit"
              textColor="inherit"
              onChange={handleChange}
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  sx={{
                    "&.Mui-selected": {
                      outline: "none",
                    },
                    borderBottom: "2px solid white",
                    "&:hover": {
                      transition: "0.2s ease",
                      borderBottom: "2px solid rgba(148, 196, 210, 0.50)",
                    },
                    // fontFamily: "DM SANS",
                    // fontSize: "15px",
                    // fontWeight: value === index && "600",
                  }}
                  className="text-capitalize"
                  label={
                    <Typography
                      sx={{
                        fontFamily: "DM SANS",
                        fontSize: "15px",
                        fontWeight: value === index && "500",
                      }}
                    >
                      {tab.notify ? <span className="notify_dot"></span> : null}
                      {tab.title}
                    </Typography>
                  }
                  {...a11yProps({ index })}
                />
              ))}
            </Tabs>
          )}
        </Box>
        {!isLoading && !isLoadingFamilyList && (
          <Box display={{ xs: "none", md: "block" }}>
            <label
              className="text-12 mb-0 d-block mt-1"
              sx={{ width: "100px" }}
            >
              Viewing Profile
            </label>
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              className=""
              isSearchable={false}
              placeholder={"Choose User"}
              onChange={(value) => {}}
              styles={customStyles}
              isLoading={isLoading}
              defaultValue={{
                label: familyData[0]?.name,
                value: familyData[0]?.name,
              }}
              options={formattedArrayFamilyList(familyData)}
            />
          </Box>
        )}
      </Box>
      {tabs.length > 0 &&
        tabs.map((tab, index) => {
          let tabId = tab.id;
          return (
            <CustomTabPanel value={value} key={index} index={index}>
              <Box sx={{ height: "100vh" }}>
                {rswitch(tabId, {
                  1000: <FindCareBody />,
                  1001: <div>My Providers</div>,
                  1002: <LabResultsBody />,
                  1006: <LabResultsDetails />,
                  1007: <AppointmentsListBody handleChange={handleChange} />,
                  1008: <AppointmentsScheduleBody />,
                  1009: <Inbox />,
                  1010: <Sent />,
                  1011: <Compose />,
                  default: (
                    <div>
                      <Box
                        display={{ xs: "block", md: "none" }}
                        onClick={logoutfunction}
                      >
                        Logout
                      </Box>
                      Not Found
                    </div>
                  ),
                })}
              </Box>

              {/* <Box sx={{ height: "100vh" }}>{tab.title}</Box> */}
            </CustomTabPanel>
          );
        })}
    </Box>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      key={index}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, minHeight: "125vh" }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
