export const messageActionTypes = {
  LIST_CLINICNOTES: "LIST_CLINICNOTES",
  NEW_CLINICNOTES: "NEW_CLINICNOTES",
  SELECT_MESSAGE: "SELECT_MESSAGE",
  LIST_MESSAGES: "LIST_MESSAGES",
  LIST_ALERTS: "LIST_ALERTS",
  SET_MESSAGES: "SET_MESSAGES",
  CLEAR_MESSAGES: "CLEAR_MESSAGES",
  RECEIVERS_LIST: "RECEIVERS_LIST",
};
