import React, { useEffect, useState } from "react";
import { Box, InputAdornment } from "@mui/material";
import { usersquery } from "../utils/userqueries";
import { useDispatch, useSelector } from "react-redux";
import { getUsersList } from "../../redux/user/user.actions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import log from "../../services/log";
import { setReceiversList } from "../../redux/actions/message";

/**
 * receiverlist -> messages-> receiverslist
 * build using userdb indexed data
 * also can be built from reply list
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const MessageReceivers = (props) => {
  const { title, defaultValue, addsearch } = props;

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const userslist = useSelector((state) => state?.user?.userslist);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (!open || inputValue === "") {
      setOptions([]);
    } else if (userslist) {
      const newoptions = [];
      userslist?.forEach((u) =>
        newoptions.push({
          label: u?._source?.name,
          id: u?._source?.accountId,
          value: u?._source,
        })
      );
      setOptions(newoptions);
    }
  }, [open, userslist]);

  const handleChange = (event, newValue) => {
    log.info("autocomplete new", newValue);
    dispatch(setReceiversList(newValue));
    setValue(newValue);
  };

  const handleInputValue = (event, newInputValue) => {
    // log.info("autocomplete", newInputValue);
    setInputValue(newInputValue);
    dispatch(getUsersList(usersquery("name", newInputValue, pagedata)));
  };

  const renderInput = (params) => (
    <TextField {...params} label={"To"} variant="standard" />
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      &nbsp;
      <Autocomplete
        size={"small"}
        multiple
        fullWidth={true}
        sx={{ height: 40, borderColor: "transparent" }}
        popupIcon={""}
        id="useremails"
        defaultValue={defaultValue}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputValue}
        options={options}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => (option ? option?.label : "")}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props} key={option?.id}>
            {option?.label}
          </li>
        )}
        renderInput={renderInput}
      />
      <Box className="clear"> </Box>
    </Box>
  );
};
