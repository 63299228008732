import * as React from "react";
import { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box, Link, TextField, Typography, Avatar } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import { Title } from "../common/Title";
import {
  SubContentStyle,
  ContentStyle,
  colWidthRBarUSR,
} from "../common/ConstantsAndValues";
import MetaMessageTable from "./MetaMessageTable";

import MessageIcon from "@mui/icons-material/Message";
import {
  getMessage,
  getMessageList,
  postMessage,
  postMessageList,
} from "../../redux/actions/message";
import log from "../../services/log";
import { MyTooltipButtons } from "../CustomComponents/MyButtons";
import { MessageInput } from "./MessageInput";

function preventDefault(event) {
  event.preventDefault();
}

/**
 * invoking messaging service
 * list,
 * post new message
 * reply to a message
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function MessageItemsList() {
  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );
  const sendto = useSelector((state) => state?.user?.sendtoUser);

  const [open, setDialogOpen] = useState(false);
  const [openSw, setOpenSw] = useState(false);
  const [sendTo, setSendTo] = useState(null);

  const [message, setMessage] = useState({});
  const [messagelist, setMessageList] = useState([]);
  const [postType, setPostType] = useState("new");
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [messageState, setMessgeState] = useState("");

  const dispatch = useDispatch();
  const buildQuery = () => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata["destId"] = user?.metadata?.accountId;
    jsonData["page"] = pagedata.page;
    jsonData["limit"] = pagedata.limit;
    jsonData["sortBy"] = "updated_at";
    return jsonData;
  };

  useEffect(() => {
    dispatch(getMessageList(buildQuery())).then((r) => {
      if (r.results) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  }, [user, dispatch]);

  useEffect(() => {
    dispatch(getMessage({ id: message.messageId }));
  }, [message]);

  const handlePostMsgClick = (a) => {
    setMessgeState("newmessage");
  };

  const postMsg = (eventData) => {
    log.info("messagelist", sendTo);
    dispatch(postMessage(eventData)).then((r) => {
      r.map((m) => {
        const listdata = {};
        listdata.metadata = {};
        listdata.messageId = m.id;
        listdata.isRead = false;
        listdata.metadata.destId = sendto.metadata && sendto.metadata.userId;
        listdata.metadata.sourceId =
          user && user.metadata && user.metadata.accountId;
        listdata.sourceName = user && user.name;
        dispatch(postMessageList(listdata));
      });
    });
  };

  const doSearch = (strSearch) => {
    // filter alertmsg/notification based on search string pending
    console.log("srch str:", strSearch);
  };

  const addReplyMsg = (message) => {
    log.info("message", message);
    setDialogOpen(true);
    setOpenSw(true);
    setPostType("reply");
    setMessage(message);
  };

  const addForwMsg = (message) => {
    // log.info("message", message)
    setDialogOpen(true);
    setOpenSw(true);
    setPostType("forward");
    setMessage(message);
  };

  const handleClose = () => {
    setMessgeState("");
  };

  return (
    <Grid container>
      {messageState === "" ? (
        <>
          <Grid item width="100%" p={0}>
            <SubContentStyle>
              <Box className="colfl" pt={1}>
                <Title>
                  <MessageIcon /> Messages
                </Title>
              </Box>

              <Box className="colfr" pt={1}>
                <MyTooltipButtons
                  onClick={handlePostMsgClick}
                  btnKey={"post"}
                  btnLabel={"New message"}
                  enState={false}
                />
              </Box>
            </SubContentStyle>
          </Grid>

          <Grid item p={0} width="100%" className="clear">
            <MetaMessageTable
              reply={true}
              mrows={messagelist}
              replyMsg={addReplyMsg}
              forwardMsg={addForwMsg}
            />

            <div className="text-color">
              <Link className="text-color" href="#" onClick={preventDefault}>
                {/*    more...*/}
              </Link>
            </div>
          </Grid>
        </>
      ) : (
        <MessageInput handleClose={handleClose} />
      )}
    </Grid>
  );
}
