import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Link } from "@material-ui/core";
import Select from "react-select";

import Password from "../../CustomComponents/Password";
import InputComponent from "../../CustomComponents/InputComponent";
import InputFeedbackSubject from "../../CustomComponents/InputFeedbackSubject";
import { getSixDigitRandom } from "../../utils/random";
import { userIndexQueries } from "../../utils/userqueries";
import { userIndex } from "../../../redux/user/user.actions";
import { register, verifyEmail } from "../../../redux/actions/auth";
import { useFormik } from "formik";
import { userAccountSchema } from "../../schema/userSchema";
import { DialogMSStyles, LocMSStyles } from "../../location/LocMSStyles";
import { NotesEditor } from "../../common/NotesEditor";

import log from "../../../services/log";

function Feedback(props) {
  //const auth = useSelector((store) => store.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [values, setValues] = useState({});
  const [options, setOptions] = useState([]);
  const [selOption, setSelOption] = useState([]);
  const [eventNotes, setEventNotes] = useState([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);

  const apptglist = useSelector((state) => state.appointment.apptglist);

  useEffect(() => {
    if (apptglist) {
      const newoptions = [];
      apptglist.forEach((e) => {
        newoptions.push({ label: e.guestName, value: e });
      });
      setOptions(newoptions);
    }
  }, [apptglist]);

  const signupform = useFormik({
    initialValues: {
      email: "",
      role: "metauser",
      name: "",
      accountId: "",
      login: "",
      phone: "",
      isEmailVerified: false,
    },
    validationSchema: userAccountSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      log.info("signup", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["email"] = values.email;
      jsonData.metadata["role"] = values.role;
      jsonData["name"] = values.name;
      jsonData["password"] = values.password;

      dispatch(register(jsonData)).then(
        (r) => {
          console.log("signup user", r);
          const newcode = getSixDigitRandom();
          dispatch(
            verifyEmail(
              {
                code: newcode,
                user: {
                  email: r.user.metadata.email,
                  name: r.user.name,
                },
              },
              {
                code: newcode,
                user: r.user,
                action: "verifyemail",
              }
            )
          );

          dispatch(
            userIndex(
              userIndexQueries({
                email: r.user.metadata.email,
                name: r.user.name,
                role: r.user.metadata.role,
                accountId: r.user.metadata.accountId,
              })
            )
          );

          navigate("/signcode");
        },
        (error) => {
          console.log(error);
          setValues({
            ...values,
            error: true,
          });
        }
      );
    },
    onReset: (values) => {},
  });

  const handleClickShowPassword = (e) => {
    signupform.setFieldValue("password", !signupform.vavlues.password);
  };

  const handleClickShowConfirmPassword = (e) => {
    signupform.setFieldValue(
      "showconfirmpassword",
      !signupform.vavlues.showconfirmpassword
    );
  };

  const handleSelect = (event) => {
    setSelOption(event);
  };

  const addNotes = (e) => {
    log.info("messagedialog", e);
    setEventNotes(JSON.stringify(e));
  };

  log.info("signup form", signupform.errors);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          marginBottom: "0px !important",
        }}
      >
        <span className="text-color-primary">
          Your feedback is important to us.
        </span>

        <InputComponent
          id="displayName"
          name="name"
          label="Full Name"
          errors={signupform.errors.name ? true : false}
          variant="standard"
          disabled={false}
          defaultValue={signupform.values.name}
          handleChange={signupform.handleChange}
        />

        <InputFeedbackSubject
          id="role"
          name="role"
          label="Subject Matter"
          values={signupform.values.role}
          errors={signupform.errors.role ? true : false}
          handleChange={signupform.handleChange}
        />
        {/*

                <Box width="100%" mt={2}>
                    <Select
                        varialnt="standard"
                        options={options}
                        value={selOption}
                        placeholder="Subject matter"
                        defaultValue={selOption}
                        onChange={handleSelect}
                        isSearchable={true}
                        styles={DialogMSStyles}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        isMulti={false}
                    />
                </Box>
                */}
        <InputComponent
          id="email"
          name="email"
          label="Email"
          errors={signupform.errors.email ? true : false}
          errorText={signupform.errors.email}
          variant="standard"
          disabled={false}
          defaultValue={""}
          handleChange={signupform.handleChange}
        />

        <Box width="100%" mt={2}>
          Message
          <br />
          <NotesEditor
            setEventNotes={setEventNotes}
            addNotes={addNotes}
            addToMsg={eventNotes}
            isProtect={false}
            isColm={false}
          />
        </Box>

        {/*
                <Password
                    id                      = "password"
                    name                    = "password"
                    label                   = "Password"
                    errors                  = {signupform.errors.passowrd? true : false}
                    showPassword            = {values.showPassword}
                    handleClickShowPassword = {handleClickShowPassword}
                    handleChange            = {signupform.handleChange}
                />

                <Password
                    id                      = "confirmpassword"
                    name                    = "confirmpassword"
                    label                   = "Confirm Password"
                    errors                  = {signupform.errors.confirmpassowrd? true : false}
                    errorText               = {signupform.errors.confirmpassowrd}
                    showPassword            = {signupform.values.showconfirmpassword}
                    handleClickShowPassword = {handleClickShowConfirmPassword}
                    handleChange            = {signupform.handleChange}
                />
                */}
        <Box mt={2} className="btn-container text-color-white" component="span">
          <Link
            onClick={signupform.handleSubmit}
            target=""
            className="btn btn-main-2-inv btn-icon btn-round text-color-white"
          >
            Post
          </Link>
        </Box>
        {/*
                <Box mt = {1} className="text-color-read" component="span">
                    Already have an account?
                    <Link href="/sign" underline="always" className="text-color">
                        SIGN IN
                    </Link>
                </Box>
                */}
      </Box>
    </>
  );
}

export default Feedback;
