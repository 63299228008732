export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const EMAILVERIFY = "EMAILVERIFY";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const ADDRESS_LIST = "ADDRESS_LIST";
export const REPORT_DATA = "REPORT_DATA";
export const REPORTS = "REPORTS";
export const ALERTS = "ALERTS";
export const LIST_ALERTS = "LIST_ALERTS";
export const LABGROUPS = "LABGROUPS";
export const ADDRESS_DATA = "ADDRESS_DATA";
export const PROFILE_DATA = "PROFILE_DATA";
export const ORGS = "ORGS";
export const ORGGROUPS = "ORGGROUPS";
export const MEDICINES = "MEDICINES";
export const DEVICEINFO = "DEVICEINFO";
export const ADDRESS_AUTO = "ADDRESS_AUTO";
