import metauser from "../../assets/images/user/metauser.jpg";
import metamd from "../../assets/images/user/metamd.jpg";
import metaorg from "../../assets/images/user/metaorg.jpg";

import metalab from "../../assets/images/user/metalab.jpg";
import metapharma from "../../assets/images/user/metapharma.jpg";
import metafin from "../../assets/images/user/metafin.jpg";
import metaos from "../../assets/images/user/metaos.jpg";

import metainsu from "../../assets/images/user/metainsu.jpg";
import metaothers from "../../assets/images/user/metaothers.jpg";
import metacon from "../../assets/images/user/metacon.jpg";

import aboutus from "../../assets/images/about/aboutus.jpg";
import services from "../../assets/images/service/services.jpg";
import terms from "../../assets/images/terms/terms.jpg";
import resources from "../../assets/images/resources/resources.jpg";
import metadmin from "../../assets/images/user/metadmin.jpg";

export default {
  metauser,
  metamd,
  metaorg,

  metapharma,
  metalab,
  metafin,
  metaos,

  metainsu,
  metaothers,
  metacon,
  aboutus,
  services,
  terms,
  resources,
  metadmin,
};
