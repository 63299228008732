import { useSelector } from "react-redux";
import log from "../../../services/log";
import DrugTaxOTC from "./DrugTaxOTC";

export default function DrugsTaxOTCOrg(props) {
  const searchtext = useSelector((state) =>
    state.user.searchtext ? state.user.searchtext : null
  );

  return (
    <div>
      <DrugTaxOTC searchText={searchtext && searchtext.drugs} />
    </div>
  );
}
