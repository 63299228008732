import { resourceActionTypes } from "./resource.types";

const INITIAL_STATE = {
  resourcelist: null,
};

const resourceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case resourceActionTypes.RESOURCE_LIST:
      return {
        ...state,
        resourcelist: action.payload,
      };
    default:
      return state;
  }
};

export default resourceReducer;
