import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTitle } from "../styles/CommonStyles";

const styles = stylesTitle;

export const InvoiceTitle = ({ title }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>{title}</Text>
  </View>
);
