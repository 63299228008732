import { addressActionTypes } from "../actions/address.types";

const initialState = {
  addresslist: null,
  deptaddress: null,
  address: null,
  selectaddress: null,
};

export default function addressReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case addressActionTypes.ADDRESS_LIST:
      return {
        ...state,
        addresslist: payload,
      };
    case addressActionTypes.DEPT_ADDRESS:
      return {
        ...state,
        deptaddress: payload,
      };
    case addressActionTypes.ADDRESS_SELECT:
      return {
        ...state,
        selectaddress: payload,
      };
    case addressActionTypes.ADD_ADDRESS:
      return {
        ...state,
        address: payload,
      };

    case addressActionTypes.ADDRESS_GROUP:
      return {
        ...state,
        groupaddress: payload,
      };

    default:
      return state;
  }
}
