export const deptGroupData = [
  {
    _id: "CD",
    groupid: "GROUPS",
    label: "Clinical Departments",
    alias: "CLINICAL",
    detail: "more...",
    icon: "",
    link: "",
    org: ["H001", "H002", "H003"],
  },
  {
    _id: "ND",
    groupid: "GROUPS",
    label: "Nursing Department",
    alias: "NURSING",
    detail: "more...",
    icon: "",
    link: "",
    org: ["H001", "H002", "H003"],
  },
  {
    _id: "SD",
    groupid: "GROUPS",
    label: "Supportive Departments",
    alias: "SUPPORT",
    detail: "more...",
    icon: "",
    link: "",
    org: ["H001", "H002", "H003"],
  },
  {
    _id: "TD",
    groupid: "GROUPS",
    label: "Technical Departments",
    alias: "TECH",
    detail: "more...",
    icon: "",
    link: "",
    org: ["H001", "H002", "H003"],
  },
  {
    _id: "AD",
    groupid: "GROUPS",
    label: "Administrative Departments",
    alias: "ADMIN",
    detail: "more...",
    icon: "",
    link: "",
    org: ["H001", "H002", "H003"],
  },
];

{
  /*
test
deptgroupData
    { _id: "CD", groupid: "GROUPS" , label: "Clinical Departments",       alias:"CLINICAL",   detail: "more..." ,   icon: "", link:"" ,
        org: ["H001", "H002","H003", ],},
deptData
    { _id: "CD002", groupid: "CD" , label: "Inpatient Service",           alias:"IPD",
        detail: "more..." ,   icon: "", link:"" ,
        org: ["H001", "H002","H003", ],},



teamData
  {h_id: "H001", dept_id: "CD001", team: [ "D001", "D0011", "D0012", "D0013"],},

RELATION
 [1] for departments under group
 deptgroupData._id === deptData.groupid   
 [2] for team under hosp under dept
 deptData._id === teamData.dept_id   &&  deptData.org[i] === teamData.h_id

*/
}

export const deptData = [
  {
    _id: "CD001",
    groupid: "CD",
    label: "Outpatient Department",
    alias: "OPD",
    detail:
      "Outpatient Department is the part of a hospital designed for the treatment of outpatients, people with health problems who visit the hospital for diagnosis or treatment, but do not at this time require a bed or to be admitted for overnight care.",
    icon: "",
    link: "",
    instruction: [
      "Please be in section marked OPD ",
      "Payment section is common ",
      "First you need to get token for Doctor visit",
    ],
    org: ["H001", "H002", "H003"],
  },

  {
    _id: "CD002",
    groupid: "CD",
    label: "Inpatient Service",
    alias: "IPD",
    detail: "more...",
    icon: "",
    link: "",
    instruction: [
      "Please be in section marked IPD ",
      "Payment section is common ",
    ],
    org: ["H001", "H003"],
  },

  {
    _id: "ND001",
    groupid: "ND",
    label: "Nursing Department",
    alias: "PMRD",
    detail:
      "A nursing department, led by a director of nursing or a chief nursing officer, might exist in a hospital. Such a department has the responsibility of overseeing the hospital's clinical nursing practice, research, and regulation.",
    icon: "",
    link: "",
    instruction: [
      "Please be in section marked IPD ",
      "Payment section is common ",
    ],
    org: ["H001"],
  },

  {
    _id: "SD001",
    groupid: "SD",
    label: "Pharmacy Department",
    alias: "PHARMACY",
    detail: "more...",
    icon: "",
    link: "",
    instruction: [
      "Please be in section marked IPD ",
      "Payment section is common ",
    ],
    org: ["H001", "H003"],
  },

  {
    _id: "TD001",
    groupid: "TD",
    label: "Clinical Engineering Department",
    alias: "CED",
    detail: "more...",
    icon: "",
    link: "",
    instruction: [
      "Please be in section marked IPD ",
      "Payment section is common ",
    ],
    org: ["H001", "H003"],
  },

  {
    _id: "AD004",
    groupid: "AD",
    label: "Administrative Department",
    alias: "ADMIN",
    detail: "more...",
    icon: "",
    link: "",
    instruction: [
      "Please be in section marked IPD ",
      "Payment section is common ",
    ],
    org: ["H001", "H002", "H003"],
  },
];

export const teamData = [
  {
    id: 1,
    h_id: "H001",
    dept_id: "CD001",
    team: ["D001", "D0011", "D0012", "D0013"],
    location_id: "NY",
  },
  {
    id: 2,
    h_id: "H002",
    dept_id: "CD001",
    team: ["D0021", "D0022"],
    location_id: "NY",
  },
  {
    id: 3,
    h_id: "H002",
    dept_id: "CD001",
    team: ["D002", "D0021", "D0022", "D0023"],
    location_id: "NJ",
  },
  {
    id: 4,
    h_id: "H002",
    dept_id: "CD001",
    team: ["D002", "D0021", "D0022"],
    location_id: "CA",
  },
  {
    id: 5,
    h_id: "H003",
    dept_id: "CD001",
    team: ["D003", "D0031", "D0032", "D0033", "D0034"],
    location_id: "CA",
  },

  {
    id: 4,
    h_id: "H001",
    dept_id: "CD002",
    team: ["D0011", "D0012"],
    location_id: "CA",
  },
  {
    id: 5,
    h_id: "H002",
    dept_id: "CD002",
    team: ["D002", "D0021", "D0022", "D0023"],
    location_id: "CA",
  },

  {
    id: 6,
    h_id: "H001",
    dept_id: "ND001",
    team: ["D001", "D0011", "D0012", "D0013"],
    location_id: "CA",
  },
  {
    id: 7,
    h_id: "H002",
    dept_id: "ND001",
    team: ["D002", "D0021", "D0022"],
    location_id: "CA",
  },
  {
    id: 8,
    h_id: "H003",
    dept_id: "ND001",
    team: ["D003", "D0031", "D0032", "D0033", "D0034"],
    location_id: "CA",
  },

  {
    id: 9,
    h_id: "H001",
    dept_id: "SD001",
    team: ["D001", "D0011", "D0012", "D0013"],
    location_id: "CA",
  },
  {
    id: 10,
    h_id: "H002",
    dept_id: "SD001",
    team: ["D002", "D0021", "D0022"],
    location_id: "CA",
  },
  {
    id: 11,
    h_id: "H003",
    dept_id: "SD001",
    team: ["D003", "D0031", "D0032", "D0033", "D0034"],
    location_id: "CA",
  },

  { id: 12, h_id: "H001", dept_id: "TD001", team: ["D001"], location_id: "CA" },
  { id: 13, h_id: "H002", dept_id: "TD001", team: ["D002"], location_id: "CA" },
  { id: 14, h_id: "H003", dept_id: "TD001", team: ["D003"], location_id: "CA" },

  {
    id: 15,
    h_id: "H001",
    dept_id: "AD004",
    team: ["D0014"],
    location_id: "NJ",
  },
  {
    id: 16,
    h_id: "H002",
    dept_id: "AD004",
    team: ["D0024"],
    location_id: "NJ",
  },
  {
    id: 17,
    h_id: "H003",
    dept_id: "AD004",
    team: ["D0034"],
    location_id: "CA",
  },
];
export const hospitalProfileData = [
  {
    id: "H0010",
    alias: "H001",
    label: "Good HealthCare Unit",
    photo: "",
    specialization: ["General", "Medicine"],
    rating: 5,
    about: "more...",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    location_id: "NY",
    address: "45/201, Lenin St, NY",
    email: "email id1",
    phone: "111111",
  },
  {
    id: "H0011",
    alias: "H001",
    label: "Good HealthCare Unit",
    photo: "",
    specialization: ["General", "Medicine"],
    rating: 5,
    about: "more...",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    location_id: "NJ",
    address: "Saint St,NJ",
    email: "email id2",
    phone: "222222",
  },
  {
    id: "H0012",
    alias: "H001",
    label: "Good HealthCare Unit",
    photo: "",
    specialization: ["General", "Medicine"],
    rating: 5,
    about: "more...",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    location_id: "CA",
    address: "Cavern Point, CA",
    email: "email id3",
    phone: "3333",
  },

  {
    id: "H0020",
    alias: "H002",
    label: "Central Health Hospital",
    photo: "",
    specialization: ["Neprology", "Medicine"],
    rating: 5,
    about: "more...ny",
    details: ["Method: AI, clinical pathway", "Other info summary ...", "..."],
    location_id: "NY",
    address: "45/201, Lenin St, NY",
    email: "email id1",
    phone: "111111",
  },
  {
    id: "H0021",
    alias: "H002",
    label: "Central Health Hospital",
    photo: "",
    specialization: ["Neprology", "Medicine"],
    rating: 5,
    about: "more... njjjjjj",
    details: ["Method: AI, clinical pathway", "Other info summary ...", "..."],
    location_id: "NJ",
    address: "Saint St,NJ",
    email: "email id2",
    phone: "222222",
  },
  {
    id: "H0022",
    alias: "H002",
    label: "Central Health Hospital",
    photo: "",
    specialization: ["Neprology", "Medicine"],
    rating: 5,
    about: "more... ca",
    details: ["Method: AI, clinical pathway", "Other info summary ...", "..."],
    location_id: "CA",
    address: "Cavern Point, CA",
    email: "email id3",
    phone: "3333333",
  },

  {
    id: "H0030",
    alias: "H003",
    label: "Welling Hospital",
    photo: "",
    specialization: ["Surgical", "Medicine"],
    rating: 5,
    about: "more...",
    details: ["Method: Automation, Robotic Surgery", "Other info summary ..."],
    location_id: "NY",
    address: "45/201, Lenin St, NY",
    email: "email id1",
    phone: "111111",
  },
  {
    id: "H0031",
    alias: "H003",
    label: "Welling Hospital",
    photo: "",
    specialization: ["Surgical", "Medicine"],
    rating: 5,
    about: "more...",
    details: ["Method: Automation, Robotic Surgery", "Other info summary ..."],
    location_id: "NJ",
    address: "Saint St,NJ",
    email: "email id2",
    phone: "222222",
  },
  {
    id: "H0032",
    alias: "H003",
    label: "Welling Hospital",
    photo: "",
    specialization: ["Surgical", "Medicine"],
    rating: 5,
    about: "more...",
    details: ["Method: Automation, Robotic Surgery", "Other info summary ..."],
    location_id: "CA",
    address: "Cavern Point, CA",
    email: "email id3",
    phone: "3333333",
  },
];

export const teamProfileData = [
  {
    id: "D001",
    alias: "D001",
    h_id: "H001",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "1 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },
  {
    id: "D0011",
    alias: "D0011",
    h_id: "H001",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "11 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },
  {
    id: "D0012",
    alias: "D0012",
    h_id: "H001",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "12 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },
  {
    id: "D0013",
    alias: "D0013",
    h_id: "H001",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "13 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },
  {
    id: "D0014",
    alias: "D0014",
    h_id: "H001",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "14 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },

  {
    id: "D002",
    alias: "D002",
    h_id: "H002",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "2 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 0,
  },
  {
    id: "D0021",
    alias: "D0021",
    h_id: "H002",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "21 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },
  {
    id: "D0022",
    alias: "D0022",
    h_id: "H002",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "2222 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },
  {
    id: "D0023",
    alias: "D0023",
    h_id: "H002",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "23 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },
  {
    id: "D0024",
    alias: "D0024",
    h_id: "H002",
    photo: "",
    specialization: ["General"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "2411 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },

  {
    id: "D003",
    alias: "D003",
    h_id: "H003",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "3 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },
  {
    id: "D0031",
    alias: "D0031",
    h_id: "H003",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "31 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },
  {
    id: "D0032",
    alias: "D0032",
    h_id: "H003",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "32 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },
  {
    id: "D0033",
    alias: "D0033",
    h_id: "H003",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "33 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },
  {
    id: "D0034",
    alias: "D0034",
    h_id: "H003",
    photo: "",
    specialization: ["General", "Medicine"],
    address: "45/201, Lenin St, NY",
    email: "email id",
    phone: "numbers",
    rating: 5,
    firstname: "34 Hans",
    lastname: "Jones",
    sex: "Male",
    title: "Dr",
    date: "2023-01-10",
    biomarkers: "",
    qualification: "MBBS",
    details: [
      "Method: Modern Tools and AI, clinical pathway",
      "Other info summary ...",
      "...",
    ],
    status: 1,
  },
];

export const deptDataAll = [
  //abandoned
  {
    _id: "CD001",
    groupid: "CD",
    label: "Outpatient Department",
    alias: "OPD",
    detail:
      "Outpatient Department is the part of a hospital designed for the treatment of outpatients, people with health problems who visit the hospital for diagnosis or treatment, but do not at this time require a bed or to be admitted for overnight care.",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD002",
    groupid: "CD",
    label: "Inpatient Service",
    alias: "IPD",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },

  {
    _id: "CD003",
    groupid: "CD",
    label: "Operation Theatre Complex",
    alias: "OT",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD004",
    groupid: "CD",
    label: "Casualty Department",
    alias: "CASUALITY",
    detail:
      "The casualty department is also known as the Accident and Emergency Department which deals with emergency conditions or provides immediate treatment. In this department, patients are assessed carefully and provided immediate treatment and care before sending for further treatment in a specialised development. This department is equipped to handle all kinds of emergencies and provides service 24/7 in the hospital.",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD005",
    groupid: "CD",
    label: "Intensive Care Unit",
    alias: "ICY",
    detail:
      "(Intensive Therapy Unit, Intensive Treatment Unit (ITU), Critical Care Unit (CCU) - A special department of a hospital or health care facility that provides intensive treatment medicine and caters to patients with severe and life-threatening illnesses and injuries, which require constant, close monitoring and support from specialist equipment and medications.",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD006",
    groupid: "CD",
    label: "Anesthesiology Department",
    alias: "AD",
    detail:
      "Doctors in this department give anaesthetic for operations. They are responsible for the provision of the following: [1] Acute pain services (pain relief after an operation), [2] Chronic pain services (pain relief in long-term conditions such as arthritis), [3] Critical care services (pain relief for those who have had a serious accident or trauma), [4] Obstetric anaesthesia and analgesia (epidurals in childbirth and anaesthetic for Caesarean sections).",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD007",
    groupid: "CD",
    label: "Cardiology Department",
    alias: "CARDIOLOGY",
    detail:
      "This department provides medical care to patients who have problems with their heart or circulation. It treats people on an inpatient and outpatient basis.",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD008",
    groupid: "CD",
    label: "ENT Department",
    alias: "ENT",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD009",
    groupid: "CD",
    label: "Geriatric Department",
    alias: "GD",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },

  {
    _id: "CD010",
    groupid: "CD",
    label: "Gastroenterology Department",
    alias: "GASTRO",
    detail:
      "This department investigates and treats digestive and upper and lower gastrointestinal diseases",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD011",
    groupid: "CD",
    label: "General Surgery Department",
    alias: "GSD",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD012",
    groupid: "CD",
    label: "Gynaecology Department",
    alias: "GYNAE",
    detail:
      "Investigates and treats problems relating to the female urinary tract and reproductive organs, such as Endometriosis, infertility and incontinence.",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD013",
    groupid: "CD",
    label: "Haematology Department",
    alias: "HAEMOTOLOGY",
    detail:
      "These hospital services work with the laboratory. In addition doctors treat blood diseases and malignancies related to the blood.",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },

  {
    _id: "CD014",
    groupid: "CD",
    label: "Pediatrics  Department",
    alias: "PEDIATRIC",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD015",
    groupid: "CD",
    label: "Neurology Department",
    alias: "NEURO",
    detail:
      "A medical specialty dealing with disorders of the nervous system. Specifically, it deals with the diagnosis and treatment of all categories of disease involving the central, peripheral, and autonomic nervous systems, including their coverings, blood vessels, and all effector tissue, such as muscle. Includes the brain, spinal cord, and spinal cord injuries (SCI).",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD016",
    groupid: "CD",
    label: "Oncology Department",
    alias: "ONCOLOGY",
    detail:
      "A branch of medicine that deals with cancer and tumors. A medical professional who practices oncology is an oncologist. The Oncology department provides treatments, including radiotherapy and chemotherapy, for cancerous tumors and blood disorders.",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD017",
    groupid: "CD",
    label: "Opthalmology Department",
    alias: "OPTHALMOLOGY",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD018",
    groupid: "CD",
    label: "Orthopaedic Department",
    alias: "ORTHO",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD019",
    groupid: "CD",
    label: "Urology Department",
    alias: "UROLOGY",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD020",
    groupid: "CD",
    label: "Nephrology Department",
    alias: "NEPHROLOGY",
    detail:
      "Includes a variety of types of notes entered over time by health care professionals, recording observations and administration of drugs and therapies, orders for the administration of drugs and therapies, test results, x-rays, reports, etc.",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "CD021",
    groupid: "CD",
    label: "Psychiatry Department",
    alias: "PSYCHIATRY",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },

  {
    _id: "ND001",
    groupid: "ND",
    label: "Nursing Department",
    alias: "PMRD",
    detail:
      "A nursing department, led by a director of nursing or a chief nursing officer, might exist in a hospital. Such a department has the responsibility of overseeing the hospital's clinical nursing practice, research, and regulation.",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },

  {
    _id: "SD001",
    groupid: "SD",
    label: "Pharmacy Department",
    alias: "PHARMACY",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "SD002",
    groupid: "SD",
    label: "Radiology (X-ray) Department",
    alias: "RD",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "SD003",
    groupid: "SD",
    label: "Clincal Pathology Department",
    alias: "CPD",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "SD004",
    groupid: "SD",
    label: "Nutrition & Dietary Department",
    alias: "DIET",
    detail:
      "Dietitians and nutritionists provide specialist advice on diet for hospital wards and outpatient clinics.",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "SD005",
    groupid: "SD",
    label: "Catering and food services",
    alias: "CATERING",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "SD006",
    groupid: "SD",
    label: "Central sterilization unit",
    alias: "STERILIZE",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "SD007",
    groupid: "SD",
    label: "Housekeeping",
    alias: "HOUSEKEEPING",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },

  {
    _id: "TD001",
    groupid: "TD",
    label: "Clinical Engineering Department",
    alias: "CED",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "TD002",
    groupid: "TD",
    label: "Information Technology and Communication",
    alias: "IT",
    detail:
      "Meaningful information can be used in quality management, continuous quality improvement and peer review. By improving the quality of information, core data can be provided for randomized clinical trials, outcomes research and many studies",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "TD003",
    groupid: "TD",
    label: "Engineering Services",
    alias: "ENGG",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },

  {
    _id: "AD001",
    groupid: "AD",
    label: "Medical Records Department",
    alias: "MRD",
    detail:
      'Includes a variety of types of "notes" entered over time by health care professionals, recording observations and administration of drugs and therapies, orders for the administration of drugs and therapies, test results, x-rays, reports, etc.',
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "AD002",
    groupid: "AD",
    label: "Human Resources Department",
    alias: "HRD",
    detail:
      "Role is to provide a professional, efficient and customer focused service to managers and staff and in turn facilitate the delivery of a professional, efficient and customer focused service to patients.",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "AD003",
    groupid: "AD",
    label: "Finance Department",
    alias: "FD",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
  {
    _id: "AD004",
    groupid: "AD",
    label: "Administrative Department",
    alias: "ADMIN",
    detail: "more...",
    icon: "",
    link: "",
    org: [
      { org_id: "H001", team: ["D001", "D0011", "D0012", "D0013"] },
      { org_id: "H002", team: ["D002", "D0021", "D0022", "D0023", "D0024"] },
      { org_id: "H003", team: ["D003", "D0031", "D0032"] },
    ],
  },
];

{
  /*

1. List Of Clinical Departments In A Hospital
    Casualty department
    Operating theatre (OT)
    Intensive care unit (ICU)
    Anesthesiology department
    Cardiology department
    ENT department
    Geriatric department
    Gastroenterology department
    General surgery
    Gynaecology department
    Haematology department
    Pediatrics department
    Neurology department
    Oncology department
    Opthalmology department
    Orthopaedic department
    Urology department
    Psychiatry department
    Inpatient Department (IPD)
    Outpatient Department (OPD)

2. Nursing Department In A Hospital

3. List Of Supportive Departments In A Hospital
    Pharmacy department 
    Radiology department 
    Clinical pathology department
    Nutrition and dietetics
    Catering and food services
    Central sterilization unit
    Housekeeping

4. List Of Technical Departments In A Hospital
    Clinical engineering department 
    Information technology and communication
    Engineering Services

5. List Of Administrative Departments In A Hospital
    Medical records department 
    Human resources department 
    Finance department 
    Administrative department 



Clinical Departments In A Hospital


Casualty Department -
    The casualty department is also known as the emergency department which deals with emergency conditions or provides immediate treatment.
    In this department, patients are assessed carefully and provided immediate treatment and care before sending for further treatment in a specialised development.
    This department is equipped to handle all kinds of emergencies and provides service 24/7 in the hospital.

Operating Theatre (OT) -
    Operating theatre is also known as operating room or operating suite or operation suite.
    OT deals with surgical operations where surgeons perform surgery in an aseptic environment.
    OT rooms have well lighting, controlled humidity and temperature.
    Operating rooms are generally supported by an anaesthetic room, preparation room, scrub room and dirty utility room.

Intensive Care Unit (ICU) -
    Intensive care unit is also known as critical care unit (CCU), intensive treatment unit (ITU) or intensive therapy unit.
    Intensive care unit deals with life-threatening or severe injuries and illnesses that require close monitoring from life support devices, constant care.
    In ICU, patients are monitored and staffed by highly trained physicians, nurses and respiratory therapists.
    Common equipment in an intensive care unit includes a mechanical ventilator, cardiac monitor, defibrillator, high flow nasal cannula, endotracheal tube, arterial line, infusion pumps, central line, nasogastric tube, nasogastric feed pump, urinary catheter.

Anesthesiology Department -
    In this department, doctors administer anaesthesia to the patients before various procedures and surgeries.
    The different types of anaesthesia provided are - general anaesthesia, nerve plexus blocks, central neuraxial anaesthesia, IV sedation, monitored anaesthesia care.

Cardiology Department -
    Cardiology department deals with the human heart and circulation problems.
    It provides services on an inpatient and outpatient basis.
    In this department, a cardiologist prescribes some tests and some procedures are performed such as angioplasty, inserting a pacemaker, atherectomy, heart catheterization, stent implantation etc.
    Cardiology department contains equipment are ECG machine, ventilator, bedside monitor, syringe pump, defibrillator.

ENT Department -
    ENT department deals with investigation and treatment of ear, nose and throat disorders.
    ENT department deals with various disorders such as:
   - General ear, nose and throat diseases
   - Cancers of the neck and head region
   - Neck lumps
   - ENT allergy problems
   - Balance and hearing disorders
   - Salivary gland diseases
   - Voice disorders
   - Surgical procedures of ENT

Geriatric Department -
    Geriatric department deals with elderly people, their illness and treatment by specialized health personnel.
    The aim of a geriatric department is to maintain the physical and mental well-being of older people.
    This department deal with various significant diseases such as:
   - Dementia
   - Arthritis
   - Osteoporosis
   - Heart dies
   - Parkinson's disease 
   - High blood pressure
   - High cholesterol 
   - Syncope
   - Diabetes
    This department also provides some special community services such as home visits, palliative care, mobile therapy unit etc.

Gastroenterology Department -
    This department deals with digestive system diseases and their management.
    In this department, gastroenterologists investigate, diagnose, treat and prevent all kinds of gastrointestinal problems such as peptic ulcer, gastritis, gallstones, indigestion, faecal incontinence, Hirschsprung disease etc. and perform various surgical procedures.
    Some special instruments are used in this department such as gastroscope, duodenoscope, enteroscope, colonoscope, video capsule, rigid sigmoidoscope etc.

General Surgery -
    In this department perform various surgical procedures.
    Some of the common surgical procedures performed are thyroid surgery, colon surgery, gall bladder surgery, breast surgery etc.

Gynaecology Department -
    Gynaecology department deals with investigation, treatment and prevention of female reproductive system and urinary tract problems.
    Some of the common problems which are investigated and treated are infertility, incontinence, endometritis etc.

Haematology Department -
    Haematology department deals with investigation, treatment and prevention of blood diseases. 
    This department closely work with the hospital laboratory.

Paediatrics Department -
    The paediatrics department deals with infants, children and adolescents.
    This department deals with some significant diseases such as infectious diseases, congenital diseases, mental disorders and childhood cancer.
    In this department health care personnel are specialized and also procedures and practices are different based on the child's age group.

Neurology Department -
    Neurology department deals with the investigation, treatment and prevention of the human nervous system.

Oncology Department -
    Oncology department deals with the investigation and treatment of cancers.
    In this department, cancer patients are treated with chemotherapy, radiotherapy and surgery.
    Doctors and nurses of this department need to be highly qualified and experienced.

Ophthalmology Department -
    This department deals with eye problems and treatment.
    Common equipments used in this department are ophthalmoscope, tonometer, slit lamp, phoropter, Snellen chart.

Orthopaedic Department -
    This department deals with problems and treatments of the musculoskeletal system.
    Services include bone setting, repair of damaged bone, ligaments, tendons and replacements of bones.
    This department provides both inpatient and outpatient services.
    Outpatient services include fracture treatment, dislocated joints, musculoskeletal injuries.

Urology Department -
    This department is generally a surgical department that deals with diseases of the female urinary tract, male urinary tract and male reproductive organs.
    Urologist diagnoses and treats problems of the female urinary system and male genitourinary system.

Psychiatry Department -
    This department deals with diagnosis, treatment and prevention of mental illness and disorders.
    Some of the services include:
   - Investigation, diagnosis and treatment of psychiatric illness
   - Providing psychological counselling
   - De-addiction services
   - Conducting IQ tests.

Inpatient Department (IPD) -
    This department admits patients in the hospital at least overnight for treatment purposes.
    In this department, patients are monitored by the doctors and nurses in a hospital setting and check patients conditions as frequently as per the hospital protocol.
    Patients may stay in this department for a long duration and it depends on the severity of the patient's illness.

Outpatient Department (OPD) -
    In this department patients visit the hospital for a consultation, diagnosis and treatment but don't get admission.
    Outpatient department provides services at a particular time of the day.
    Here patients get medical services from a specific department based on their problem and the doctor provide a prescription of medicine.

Nursing Department In A Hospital

    Nursing department is an important organizational structure of a hospital that provides nursing services to the clients.
    This department has two major services that are nursing education and nursing care service.
    Nursing department includes lots of duties such as:
   - Patient care
   - Management 
   - Education
   - Ward planning and staffing 
   - Maintenance of supplies
   - Registration and records
   - Monitoring and evaluation
   - Ward administration
    Nursing personnel includes:
   - Nursing superintendent
   - Assistant nursing superintendent
   - Head nurse
   - Staff nurses

Supportive Departments In A Hospital

Pharmacy Department -
    Pharmacy in a hospital is a dispensary that prepares, compounds, stores and dispense medications, especially for inpatient clients.
    Generally hospital pharmacy stocks a large number of medications.
    Pharmacy must be monitored and controlled by registered pharmacists.

Radiology Department -
    Radiology department provides medical imaging services to diagnose and treat diseases within the body.
    This department consists of X-rays, ultrasound, computed tomography (CT), magnetic resonance imaging (MRI), positron emission tomography (PET), fluoroscopy.
    In the radiology department staff may get affected by the radiation so, radiology staff must be protected from radiation hazards.

Clinical Pathology Department -
    Clinical pathology is also known as a medical laboratory.
    It is a medical speciality that deals with the diagnosis of diseases with the help of laboratory analysis of body fluids.
    Common tools used in this department are the microscope, strips, analyzers and centrifuges.

Nutrition and Dietetics -
    This department provides professional advice on diet for the inpatients as well as outpatients.
    This department collaborates with certain departments that require patients to be put on a diet such as diabetes, kidney diseases, elderly care, gastroenterology, surgery and critical care.
    This department suggests a dietary plan followed by the hospital canteen facilities.

Catering and Food Services -
    This department provides balanced meals to inpatient clients, their families and hospital staffs according to the advice of the nutrition department.

Central Sterilization Unit -
    This department is responsible to keep clean and sterile all the used instruments of the hospital to prevent infection from spreading.

Housekeeping -
    Housekeeping department is responsible to keep all the hospital neat and clean.
    Services include cleaning all rooms, disposing of waste and laundry.

Technical Departments In A Hospital

Clinical Engineering Department -
    Clinical engineering department is responsible for the advanced care in a hospital through active support, direction and application of medical equipments.
    Clinical engineers inspect and maintain the performance of medical equipments that prevents hazards and optimize the accuracy of results.

Information Technology and Communication -
    This department deals with computers to store and track the patient's records and other medical data of a hospital.
    This department is responsible for technical support and keep the system updated.
    This department also provides online services for clients.

Engineering Services -
    Engineering services deal with electricity supply, water supply, piped medical gases, clinical vacuum system, air conditioning, lifts, lighting etc of a hospital.
    It ensures the optimum efficiency of engineering systems.
    It prevents hazards and breakdowns of hospital engineering systems.

Administrative Department In A Hospital

Medical Records Department -
    This department deals with medical data recording and maintain all inpatients and outpatients client's files.
    Data from this department helps the hospital to get statistics that improve hospital service growth.

Human Resources Department -
    This department deals with recruiting employees in various positions of the hospital.
    This department also creates policies and regulations for the employees.
    This department is actively responsible for the hospital growth and optimum services.

Finance Department -
    This department is responsible for the financial growth of a hospital.
    It set up budgets, plans and arrange financial resources to maintain the smooth running of a hospital.
    This department is also responsible for the purchasing of medical supplies, staffs wage and other financial aspects.

Administrative Department -
    Administrative department deals with the overall management of a hospital.
    It set up protocol and procedures for all departments of a hospital.
    This department is responsible for the hospital growth and development. An ideal administrative department can run a hospital smoothly with huge financial benefits.



Most Common Hospital Departments
Accident and emergency (A&E):

Also called Casualty Department, where you're likely to be taken if you have arrived in an ambulance or emergency situation.

Admissions:

At the Admitting Department, the patient will be required to provide personal information and sign consent forms before being taken to the hospital unit or ward. If the individual is critically ill, then, this information is usually obtained from a family member.

Anesthetics:

Doctors in this department give anesthetic for operations and procedures. An anesthetic is a drug or agent that produces a complete or partial loss of feeling. There are three kinds of anesthetic: general, regional and local.

Breast Screening:

Screens women for breast cancer and is usually linked to the X-ray or radiology department.

Burn Center (Burn Unit or Burns Unit):

A hospital specializing in the treatment of burns. Burn centers are often used for the treatment and recovery of patients with more severe burns.

Cardiology:

Provides medical care to patients who have problems with their heart or circulation.

Central Sterile Services Department (CSSD):

(Sterile Processing Department (SPD) - Sterile Processing - Central Supply Department (CSD) - Central Supply) - A place in hospitals and other health care facilities that performs sterilization and other actions on medical equipment, devices, and consumables.

Chaplaincy:

Chaplains promote the spiritual and pastoral wellbeing of patients, relatives and staff.

Coronary Care Unit (CCU):

(Cardiac intensive care unit (CICU) - A hospital ward specialized in the care of patients with heart attacks, unstable angina, cardiac dysrhythmia and other cardiac conditions that require continuous monitoring and treatment.

Critical Care:

Also called intensive care, this department is for seriously ill patients.

Diagnostic Imaging:

Also known as X-Ray Department and/or Radiology Department.

Discharge Lounge:

Patients who don't need to stay in a ward are transferred to the lounge on the day of discharge. Many hospitals now have discharge lounges with facilities such as TV's, radio, puzzles, magazines, books and newspapers.

Elderly Services:

Covers and assists with a wide range of issues associated with seniors.

Finance Department:

Performs all works related to budget and ideal use of the items of such budget. Also, it prepares payrolls and monthly wages, and concludes contracts of operation and maintenance and purchases. In addition, it makes available all amounts of money required for procurement of all materials and equipment.

Gastroenterology:

This department investigates and treats digestive and upper and lower gastrointestinal diseases.

General Services:

Support Services include services provided by Departments such as Portering, Catering, Housekeeping, Security, Health & Safety, Switch, Laundry and the management of facilities such as parking, baby tagging, access control, CCTV etc.

General Surgery:

Covers a wide range of types of surgery and procedures on patients.

Gynecology:

Investigates and treats problems relating to the female urinary tract and reproductive organs, such as Endometriosis, infertility and incontinence.

Haematology:

These hospital services work with the laboratory. In addition doctors treat blood diseases and malignancies related to the blood.

Health & Safety:

The role of the occupational health and safety department is to promote and maintain the highest possible degree of health and safety for all employees, physicians, volunteers, students and contractors, and actively participates in quality, safety and risk initiatives. Numerous health and safety issues associated with healthcare facilities include blood-borne pathogens and biological hazards, potential chemical and drug exposures, waste anesthetic gas exposures, respiratory hazards, ergonomic hazards from lifting and repetitive tasks, laser hazards, hazards associated with laboratories, and radioactive material and x-ray hazards. In addition to the medical staff, large healthcare facilities employ a wide variety of trades that have health and safety hazards associated with them. These include mechanical maintenance, medical equipment maintenance, housekeeping, food service, building and grounds maintenance, laundry, and administrative staff.

Intensive Care Unit (ICU):

(Intensive Therapy Unit, Intensive Treatment Unit (ITU), Critical Care Unit (CCU) - A special department of a hospital or health care facility that provides intensive treatment medicine and caters to patients with severe and life-threatening illnesses and injuries, which require constant, close monitoring and support from specialist equipment and medications.

Human Resources:

Role is to provide a professional, efficient and customer focused service to managers and staff and in turn facilitate the delivery of a professional, efficient and customer focused service to patients.

Infection Control:

Primarily responsible for conducting surveillance of hospital-acquired infections and investigating and controlling outbreaks or infection clusters among patients and health care personnel. The department calculates rates of hospital-acquired infections, collates antibiotic susceptibility data, performs analysis of aggregated infection data and provides comparative data to national benchmarks over time.

Information Management:

Meaningful information can be used in quality management, continuous quality improvement and peer review. By improving the quality of information, core data can be provided for randomized clinical trials, outcomes research and many studies.

Maternity:

Maternity wards provide antenatal care, delivery of babies and care during childbirth, and postnatal support.

Medical Records:

Includes a variety of types of "notes" entered over time by health care professionals, recording observations and administration of drugs and therapies, orders for the administration of drugs and therapies, test results, x-rays, reports, etc.

Microbiology:

The microbiology department provides an extensive clinical service, including mycology, parasitology, mycobacteriology, a high security pathology unit, and a healthcare associated infection investigation unit, as well as routine bacteriology and an expanding molecular diagnostic repertoire.

Neonatal:

Closely linked with the hospital maternity department, provides care and support for babies and their families.

Nephrology:

Monitors and assesses patients with various kidney (renal) problems and conditions.

Neurology:

A medical specialty dealing with disorders of the nervous system. Specifically, it deals with the diagnosis and treatment of all categories of disease involving the central, peripheral, and autonomic nervous systems, including their coverings, blood vessels, and all effector tissue, such as muscle. Includes the brain, spinal cord, and spinal cord injuries (SCI).

Nutrition and Dietetics:

Dietitians and nutritionists provide specialist advice on diet for hospital wards and outpatient clinics.

Obstetrics/Gynecology:

Specialist nurses, midwives and imaging technicians provide maternity services such as: antenatal and postnatal care, maternal and foetal surveillance, and prenatal diagnosis.

Occupational Therapy:

Helps physically or mentally impaired people, including temporary disability, practices in the fields of both healthcare as well as social care. Often abbreviated as "OT", Occupational Therapy promotes health by enabling people to perform meaningful and purposeful occupations. These include (but are not limited to) work, leisure, self care, domestic and community activities. Occupational therapists work with individuals, families, groups and communities to facilitate health and well-being through engagement or re-engagement in occupation.

Oncology:

A branch of medicine that deals with cancer and tumors. A medical professional who practices oncology is an oncologist. The Oncology department provides treatments, including radiotherapy and chemotherapy, for cancerous tumors and blood disorders.

Ophthalmology:

Ophthalmology is a branch of medicine which deals with the diseases and surgery of the visual pathways, including the eye, hairs, and areas surrounding the eye, such as the lacrimal system and eyelids. The term ophthalmologist is an eye specialist for medical and surgical problems. The Ophthalmology department provides a range of ophthalmic eye related services for both in and outpatients.

Orthopaedics:

Treats conditions related to the musculoskeletal system, including joints, ligaments, bones, muscles, tendons and nerves.

Otolaryngology (Ear, Nose, and Throat):

The ENT Department provide comprehensive and specialized care covering both Medical and Surgical conditions related not just specifically to the Ear, Nose and Throat, but also other areas within the Head and Neck region. It is often divided into sub-specialties dealing with only one part of the traditional specialty (ontology, rhinology and laryngology).

Pain Management:

Helps treat patients with severe long-term pain. Alternative pain relief treatments such as acupuncture, nerve blocks and drug treatment, are also catered for.

Patient Accounts:

The Patient Accounts Department answers all billing questions and concerns, requests for itemized bills, and account balance inquiries. The patient accounts department also assists patients in their insurance benefits for services rendered.

Patient Services:

The Patient Services Manager is a source of information and can channel patient queries in relation to hospital services to the appropriate departments.

Pharmacy:

Responsible for drugs in a hospital, including purchasing, supply and distribution.

Physiotherapy:

Physiotherapists work through physical therapies such as exercise, massage, and manipulation of bones, joints and muscle tissues.

Purchasing & Supplies:

Purchasing & Supplies Department is responsible for the procurement function of the hospital.

Radiology:

The branch or specialty of medicine that deals with the study and application of imaging technology like x-ray and radiation to diagnosing and treating disease. The Department of Radiology is a highly specialized, full-service department which strives to meet all patient and clinician needs in diagnostic imaging and image-guided therapies.

Radiotherapy:

Also called radiation therapy, is the treatment of cancer and other diseases with ionizing radiation.

Renal:

Provides facilities for peritoneal dialysis and helps facilitate home Hemodialysis.

Rheumatology:

Rheumatologists care for and treat patients for musculoskeletal disorders such as: bones, joints, ligaments, tendons, muscles and nerves.

Sexual Health:

Also known as genitourinary medicine - Provides advice, testing and treatment for sexually transmitted infections, family planning care, pregnancy testing and advice, care and support for sexual and genital problems.

Social Work:

Clinical social workers help patients and their families deal with the broad range of psychosocial issues and stresses related to coping with illness and maintaining health. Social workers, resource specialists and advocates form a network that addresses the challenges families face, increases accessibility to health care and other human services, and serves as a bridge between the hospital setting and a patient's family life, home and community.

Urology:

The urology department is run by consultant urology surgeons and investigates areas linked to kidney and bladder conditions.
*/
}
