// importing node modules
import { ThemeProvider } from "@mui/material/styles";
import { useMetaTheme } from "../theme/ThemeContext";
import {
  Box,
  Toolbar,
  AppBar,
  Divider,
  Typography,
  ListItemButton,
  ListItemText,
  ListItem,
  List,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// importing other modules
import {
  useWindowSize,
  minWidthSize,
  StyledNHMenuItemNav,
  InnerNavigation,
} from "../common/ConstantsAndValues";
import { InnerNavIconButton } from "../buttons/InnerNavIconButton";

// importing images
import logo from "../../assets/images/logo.png";

import { PersonIcon } from "../customIcons/PersonIcon";
import useOnClickOutside from "../../utils/use-on-click-outside";
import { logout } from "../../redux/actions/auth";
// import toast from "react-hot-toast";
import { deleteAllLocalStorage } from "../../utils/helpers";

const PatientNavbar = ({ history }) => {
  const dispatch = useDispatch();

  // get theme
  const { theme } = useMetaTheme();
  const [menulist, setMenulist] = useState(InnerNavigation);

  const [displayProfileMenu, setDisplayProfileMenu] = useState(false);

  const profile = useSelector((state) => state.auth.user.user);

  // get sizing
  const size = useWindowSize();

  // outside click for profile dropdown
  const squareBoxRef = useRef(null);

  const clickOutsidehandler = (event) => {
    event.stopPropagation();
    setDisplayProfileMenu(false);
  };
  useOnClickOutside(squareBoxRef, clickOutsidehandler);

  const handleChange = (event) => {};

  const logoutFunction = () => {
    dispatch(logout());
    deleteAllLocalStorage();
    // toast.success("Logged Out Successfully");
    if (history) {
      history.push("/");
    }
  };

  const profileMenu = [
    { title: "Profile Information", profileFunction: null },
    { title: "Account settings", profileFunction: null },
    { title: "Security & privacy", profileFunction: null },
    { title: "Log out", profileFunction: logoutFunction },
  ];

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        color={"bgColor"}
        position="static"
        elevation={0}
        sx={{ boxShadow: "0 2px 15px 0 rgba(0,0,0,.1)" }}
      >
        {/*<Container >*/}
        <Toolbar sx={{ padding: "1rem 0" }}>
          {size.width > minWidthSize.width && (
            <Box
              onClick={() => handleChange("/")}
              className="enable-link"
              id="imgLogoMain"
              component="img"
              sx={{
                padding: (theme) => theme?.dashboardlogoSizes?.padding,
                maxHeight: (theme) => theme?.dashboardlogoSizes?.maxHeight,
                maxWidth: (theme) => theme?.dashboardlogoSizes?.maxWidth,
              }}
              alt="logo"
              src={logo}
            />
          )}
          <Box
            justifyContent="flex-end"
            gap={0}
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <StyledNHMenuItemNav
              disableGutters={false}
              m={0}
              p={0}
              key={menulist["home"]._id}
            >
              <InnerNavIconButton menu={menulist["home"]} />
            </StyledNHMenuItemNav>
            <StyledNHMenuItemNav
              disableGutters={false}
              m={0}
              p={0}
              key={menulist["search"]._id}
            >
              <InnerNavIconButton menu={menulist["search"]} />
            </StyledNHMenuItemNav>
            <StyledNHMenuItemNav
              disableGutters={false}
              m={0}
              p={0}
              key={menulist["notification"]._id}
            >
              <InnerNavIconButton menu={menulist["notification"]} />
            </StyledNHMenuItemNav>

            <Divider
              orientation="vertical"
              style={{ height: "30px" }}
              flexItem
            />
            <Box
              className="flex cursor-pointer"
              ml={3}
              onClick={() => setDisplayProfileMenu(!displayProfileMenu)}
              sx={{
                svg: {
                  fill: displayProfileMenu
                    ? (theme) => theme?.palette?.primary?.main
                    : "#404040",
                },
                "&:hover": {
                  svg: { fill: (theme) => theme?.palette?.primary?.main },
                },
              }}
            >
              <PersonIcon size={0.7} />
              <Typography
                ml={1}
                sx={{
                  color: displayProfileMenu
                    ? (theme) => theme?.palette?.primary?.main
                    : "",
                }}
              >
                {profile?.name !== undefined ? profile?.name : "Metalane User"}
              </Typography>
            </Box>
            {displayProfileMenu && (
              <Box
                ref={squareBoxRef}
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "80px",
                  width: "100%",
                  maxWidth: 180,
                  bgcolor: "background.paper",
                  zIndex: "99999",
                  border: "1px solid #585858",
                  borderRadius: "8px",
                  // fontSize: "14px",
                  boxShadow: "0px 4px 30px 0px rgba(202, 202, 202, 0.50)",
                }}
              >
                <nav aria-label="secondary mailbox folders">
                  <List>
                    {profileMenu.map((data, index) => (
                      <ListItem
                        disablePadding
                        onClick={() => data.profileFunction()}
                        key={index}
                        sx={{
                          color: "#585858",
                          height: "40px",
                          "&:hover": { background: "#FFF1E7" },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            padding: "0 1rem",
                            fontSize: "14px",
                            "&:hover": { background: "transparent" },
                          }}
                        >
                          <ListItemText primary={data.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </nav>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};
export default PatientNavbar;
