import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
//import Divider from '@mui/material/Divider';
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Grid, Box, Typographyn, ListItemButton } from "@mui/material";

import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import TextField from "@material-ui/core/TextField";
import Fab from "@mui/material/Fab";
import MessageSearch from "./MessageSearch";
import PersonTwoToneIcon from "@mui/icons-material/PersonTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";

import { Title } from "../common/Title";
import {
  SubContentStyle,
  ContentStyle,
  colWidthRBarUSR,
  picPath,
  tableParam,
} from "../common/ConstantsAndValues";
import {
  MsgMetauserOnlineRows,
  MsgMetamdOnlineRows,
} from "../../tests/MsgOnlineRows";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getUserGroup,
  sendtoUser,
  selectUserGroup,
} from "../../redux/user/user.actions";
import log from "../../services/log";

function preventDefault(event) {
  event.preventDefault();
}

export default function MessageItemsList_OnlineNow(props) {
  const { sourcekey } = props;

  const dispatch = useDispatch();

  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );
  const pgrouplist = useSelector((state) => state?.user?.pgrouplist);

  const [userstatus, setUserStatus] = useState([]);
  const [selected, setSelected] = useState({});
  const [members, setMembers] = useState(null);
  const [actionState, setActionState] = useState("");
  const [dialogState, setDialogState] = useState(false);

  const buildQuery = () => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata[""] = user.metadata.accountId;
    return jsonData;
  };
  useEffect(() => {
    log.info("messagelist", pgrouplist);
    if (pgrouplist) {
      const newmembers = [];
      pgrouplist.results.map((m) =>
        newmembers.push({
          id: m.id,
          name: m.name,
          memberId: m.metadata.providerId,
          status: m.status,
        })
      );
    }
  }, [pgrouplist]);

  useEffect(() => {
    if (user) {
      dispatch(getUserGroup(buildQuery()));
    }
  }, [user, dispatch]);

  useEffect(() => {
    dispatch(sendtoUser(selected));
  }, [selected]);

  const handleSelected = (e, row) => {
    // log.info("online", row)
    setSelected(row);
  };

  const handleCancel = (event) => {
    // setDialogState(true);
    setActionState("");
  };

  const handleOpen = (event) => {
    // setDialogState(true);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item width="100%" p={0}>
          <Box className="colfl" pt={1} ml={2} mr={0}>
            <Title>
              <GroupRoundedIcon /> Members
            </Title>
          </Box>

          <Box
            className="colfl list border-db"
            pt={0}
            ml={2}
            mr={0}
            mb={1}
            height={tableParam.minHeight}
            width="90%"
          >
            <List>
              {members && members.length ? (
                members.map((row, index) => (
                  <ListItem
                    button
                    key={row.id}
                    onClick={(event) => handleSelected(event, row)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{ fontSize: "small" }}
                        alt={row.name}
                        src={"../../.." + picPath.path + 1 + picPath.ext}
                      />
                    </ListItemAvatar>
                    &nbsp;
                    <ListItemText
                      primary={row.name}
                      className={row.id === selected.id ? "text-color-inv" : ""}
                    />
                    <ListItemText
                      secondary={
                        row.status === "active" ? (
                          <span className={"online"}></span>
                        ) : (
                          <span className={"offline"}></span>
                        )
                      }
                      align="right"
                      sx={{ paddingRight: 1 }}
                    ></ListItemText>
                  </ListItem>
                ))
              ) : (
                <ListItem button key={"nodata"}>
                  <ListItemAvatar>
                    <Avatar sx={{ fontSize: "small" }} alt={"x"} />
                  </ListItemAvatar>
                  &nbsp;
                  <ListItemText
                    primary={"No member in network"}
                    className={""}
                  />
                </ListItem>
              )}
            </List>
          </Box>

          <Box className="bg-gray">
            <Box
              className="colfl0 text-color-gray text-vsm"
              pt={1}
              ml={2}
              mr={0}
              mb={0}
            >
              {"Legend: "}
            </Box>
            <Box
              className="colfr0 text-color-dark-gray text-vvsm"
              pt={1}
              ml={2}
              mr={4}
              mb={2}
            >
              <span className="text-vsm">+</span> Doctor, H Hospital, P
              Pharmacy, U User
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
