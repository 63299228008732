import {
  Grid,
  Box,
  Link,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import log from "../../services/log";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { NotesEditor } from "../common/NotesEditor";

export default function AlertDialog(props) {
  const {
    open,
    setDialogOpen,
    postType,
    dialog,
    post,
    addTo,
    addToTitle,
    addToMsg,
  } = props;
  const [eventTo, setEventTo] = useState(addTo);
  const [eventTitle, setEventTitle] = useState(addToTitle);
  const [eventNotes, setEventNotes] = useState(addToMsg);

  const isColm = false;

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handlePost = () => {
    setDialogOpen(false);
    let eventData = {};
    eventData.alertSubject = eventTitle;
    eventData.alertBody = eventNotes;
    eventData.metadata = {};
    eventData.metadata.sourceId = eventTo.sourceId;
    eventData.metadata.groupId = eventTo.groupId;
    eventData.sourceName = eventTo.sourceName;
    log.info("alertdialog", eventData);
    // post(eventData)
  };

  const addNotes = (e) => {
    setEventNotes(JSON.stringify(e));
  };

  const handleChangeTitle = (e) => {
    setEventTitle(e.target.value);
  };

  log.info("messagedialog", props);

  return (
    <Box component="div">
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle className="bg-gray text-color-primary" width="100%">
          <Box className="colfl">{postType}</Box>
          <Box onClick={handleClose} className="colfr text-color">
            <CloseIcon />
          </Box>
        </DialogTitle>

        <DialogContent
          className="text-color-primary"
          sx={{ marginX: 0, marginTop: 2, paddingTop: 2 }}
        >
          <DialogContentText> </DialogContentText>
          <TextField
            margin="dense"
            id="msgTitleE"
            label="Subject"
            fullWidth
            variant="standard"
            defaultValue={eventTitle}
            onChange={handleChangeTitle}
          />
          Message
          <br />
          <NotesEditor
            setEventNotes={setEventNotes}
            addNotes={addNotes}
            addToMsg={addToMsg}
            isProtect={false}
            isColm={isColm}
          />
        </DialogContent>
        <DialogActions sx={{ margin: 0, marginRight: 2, marginBottom: 2 }}>
          <Link
            onClick={handleClose}
            className="btn btn-transparent btn-icon btn-round text-color"
          >
            Cancel
          </Link>
          <Link
            onClick={handlePost}
            className="btn btn-main-2-inv  btn-icon btn-round text-color"
          >
            Send
          </Link>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
