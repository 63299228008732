import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import log from "../../services/log";
import { getUserGroup, sendtoUser } from "../../redux/user/user.actions";
import * as React from "react";
import { Box, Grid } from "@mui/material";
import { Title } from "../common/Title";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import { picPath, tableParam } from "../common/ConstantsAndValues";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import { setReceiversList } from "../../redux/actions/message";

export const MemberMDOnlineNow = (props) => {
  const { sourcekey } = props;

  const dispatch = useDispatch();

  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );
  const selectmdgroup = useSelector((state) => state?.user?.selectmdgroup);
  const pgrouplist = useSelector((state) => state?.user?.usergroup);
  const receiverslist = useSelector((state) => state?.messages?.receiverslist);

  const [userstatus, setUserStatus] = useState([]);
  const [selected, setSelected] = useState(null);
  const [members, setMembers] = useState(null);
  const [actionState, setActionState] = useState("");
  const [dialogState, setDialogState] = useState(false);

  const buildQuery = () => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata["groupId"] = selectmdgroup.metadata.groupId;
    return jsonData;
  };
  useEffect(() => {
    log.info("messagelist", pgrouplist);
    if (pgrouplist) {
      const newmembers = [];
      pgrouplist?.map((m) =>
        newmembers.push({
          id: m?.id,
          name: m?.name,
          memberId: m?.metadata?.providerId,
          status: m?.status,
        })
      );
      setMembers(newmembers);
    }
  }, [pgrouplist]);

  useEffect(() => {
    if (user) {
      dispatch(getUserGroup(buildQuery()));
    }
  }, [user, dispatch]);

  const handleSelected = (e, row) => {
    log.info("online row", row);
    setSelected(row);
    dispatch(sendtoUser(row));
  };

  const handleCancel = (event) => {
    // setDialogState(true);
    setActionState("");
  };

  const handleOpen = (event) => {
    // setDialogState(true);
  };

  log.info("usergrouplist", pgrouplist);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item width="100%" p={0}>
          <Box className="colfl" pt={1} ml={2} mr={0}>
            <Title>
              <GroupRoundedIcon /> Members
            </Title>
          </Box>

          <Box
            className="colfl list border-db"
            pt={0}
            ml={2}
            mr={0}
            mb={1}
            height={tableParam.minHeight}
            width="90%"
          >
            <List>
              {members && members.length ? (
                members.map((row, index) => (
                  <ListItem
                    button
                    key={row.id}
                    onClick={(event) => handleSelected(event, row)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{ fontSize: "small" }}
                        alt={row.name}
                        src={"../../.." + picPath.path + 1 + picPath.ext}
                      />
                    </ListItemAvatar>
                    &nbsp;
                    <ListItemText
                      primary={row?.name}
                      className={
                        row?.id === selected?.id ? "text-color-inv" : ""
                      }
                    />
                    <ListItemText
                      secondary={
                        row?.status === "active" ? (
                          <span className={"online"}></span>
                        ) : (
                          <span className={"offline"}></span>
                        )
                      }
                      align="right"
                      sx={{ paddingRight: 1 }}
                    ></ListItemText>
                  </ListItem>
                ))
              ) : (
                <ListItem button key={"nodata"}>
                  <ListItemAvatar>
                    <Avatar sx={{ fontSize: "small" }} alt={"x"} />
                  </ListItemAvatar>
                  &nbsp;
                  <ListItemText
                    primary={"No member in network"}
                    className={""}
                  />
                </ListItem>
              )}
            </List>
          </Box>

          <Box className="bg-gray">
            <Box
              className="colfl0 text-color-gray text-vsm"
              pt={1}
              ml={2}
              mr={0}
              mb={0}
            >
              {"Legend: "}
            </Box>
            <Box
              className="colfr0 text-color-dark-gray text-vvsm"
              pt={1}
              ml={2}
              mr={4}
              mb={2}
            >
              <span className="text-vsm">+</span> Doctor, H Hospital, P
              Pharmacy, U User
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
