import {
  getAddressListService,
  setAddressService,
} from "../../services/appointment-service";

import { addressActionTypes } from "./address.types";
import { getAddressService } from "../../services/location-service";

export const getAddressList = (payload) => (dispatch) => {
  return getAddressService(payload).then(
    (response) => {
      dispatch({
        type: addressActionTypes.ADDRESS_LIST,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const setDeptAddress = (payload) => (dispatch) => {
  return setAddressService(payload).then(
    (response) => {
      dispatch({
        type: addressActionTypes.ADD_ADDRESS,
        payload: response.results,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const resetAddressList = () => (dispatch) => {
  dispatch({
    type: addressActionTypes.ADDRESS_LIST,
    payload: { data: null },
  });
};

export const selectOrgAddress = (payload) => (dispatch) => {
  dispatch({
    type: addressActionTypes.ADDRESS_SELECT,
    payload: payload,
  });
};

export const selectDeptAddress = (payload) => (dispatch) => {
  dispatch({
    type: addressActionTypes.DEPT_ADDRESS,
    payload: payload,
  });
};

export const resetDeptAddress = () => (dispatch) => {
  dispatch({
    type: addressActionTypes.DEPT_ADDRESS,
    payload: null,
  });
};

export const getGroupAddressList = (payload) => (dispatch) => {
  return getAddressService(payload).then(
    (response) => {
      dispatch({
        type: addressActionTypes.ADDRESS_GROUP,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};
