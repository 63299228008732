import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import { NotesEditor } from "../common/NotesEditor";
import { postMessage, postMessageList } from "../../redux/actions/message";
import { useFormik } from "formik";
import { messageSchema } from "../schema/messageSchema";
import { useSelector } from "react-redux";
import { MessageReceivers } from "./MessageReceivers";
import { MyTooltipButtons, MyHelpButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";

export const MessageInput = (props) => {
  const { handleClose } = props;

  const sendto = useSelector((state) => state?.user?.sendtoUser);
  const [receiverlist, setReceiverlist] = useState([]);

  useEffect(() => {
    setReceiverlist([]);
    if (sendto) {
      let newlist = receiverlist;
      receiverlist.push(sendto);
      setReceiverlist(newlist);
    }
  }, [sendto]);

  const messageForm = useFormik({
    initialValues: {
      id: "",
      sourceId: "",
      destId: "",
      threadId: "",
      seqno: 0,
      sourceName: "",
      destName: "",
      msgSubject: "",
      msgBody: "",
      nextReminder: null,
    },
    validationSchema: messageSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("clinicaltestinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData.id = values.id;
      jsonData.metadata = {};

      // log.info("metacal", jsonData);
      // dispatch(updateAppointment(jsonData));
      // closeForm();
    },
    onReset: (values) => {},
  });

  const [eventTo, setEventTo] = useState(null);
  const [eventTitle, setEventTitle] = useState("");
  const [eventNotes, setEventNotes] = useState([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);

  const addNotes = (e) => {
    log.info("messagedialog", e);
    setEventNotes(JSON.stringify(e));
  };

  const postMsg = (eventData) => {
    // log.info("messagelist", sendTo);
    // dispatch(postMessage(eventData)).then((r) =>{
    //     r.map ((m) => {
    //         const listdata             = {};
    //         listdata.metadata          = {};
    //         listdata.messageId         = m.id;
    //         listdata.isRead            = false;
    //         listdata.metadata.destId   = sendto.metadata && sendto.metadata.userId;
    //         listdata.metadata.sourceId = user && user.metadata && user.metadata.accountId;
    //         listdata.sourceName        = user && user.name;
    //         dispatch(postMessageList(listdata));
    //     });
    // });
  };

  // log.info("message", messageForm.values);
  receiverlist.forEach((a) => log.info("receiverlist", a));

  return (
    <Grid container spacing={2}>
      <Grid item width="100%" p={0}>
        <Box className="colfr" pt={1}>
          <MyTooltipButtons
            onClick={handleClose}
            btnKey={"cancel"}
            btnLabel={"close"}
            enState={false}
          />
        </Box>
      </Grid>
      {/*<TextField*/}
      {/*    autoFocus*/}
      {/*    style        = {{ width : "100%", }}*/}
      {/*    id           = "msgToE"*/}
      {/*    variant      = "standard"*/}
      {/*    defaultValue = {receiverlist? receiverlist.forEach((x) => x.name): []}*/}
      {/*    InputProps   = {{*/}
      {/*        startAdornment: (*/}
      {/*            <InputAdornment position="start">*/}
      {/*                <AccountCircleTwoToneIcon />*/}
      {/*            </InputAdornment>*/}
      {/*        ),*/}
      {/*    }}*/}
      {/*    // onChange     = {handleChangeTo}*/}
      {/*/>*/}

      <Grid item className="clear" xs={12}>
        <MessageReceivers />
        <Box className="clear"> &nbsp; </Box>
      </Grid>

      <Grid item xs={12}>
        <TextField
          margin="dense"
          name="msgSubject"
          id="msgSubject"
          label="Subject"
          fullWidth
          variant="standard"
          defaultValue={messageForm.values.msgSubject}
          onChange={messageForm.handleChange}
        />
      </Grid>

      <Grid item xs={12}>
        Message
        <br />
        <NotesEditor
          setEventNotes={setEventNotes}
          addNotes={addNotes}
          addToMsg={eventNotes}
          isProtect={false}
          isColm={false}
        />
      </Grid>
      <Grid item width="100%" p={0}>
        <Box className="colfr" pt={1}>
          <MyHelpButtons
            onClick={postMsg}
            btnKey={"post"}
            btnLabel={"post"}
            enState={false}
            btnTooltip={"Post"}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
