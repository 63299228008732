import * as React from "react";
import { useEffect, useState } from "react";
import useStyles from "../CustomComponents/useStyles";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import { useDispatch, useSelector } from "react-redux";
import {
  getDrugsOrderlist,
  selectPatientMedcine,
} from "../../redux/drugs/drugs.actions";
import { MedicineOrderListHead2 } from "./MedicineOrderListHead2";
import { MedicineOrderListRow2 } from "./MedicineOrderListRow2";
import { pageOptions } from "../common/ConstantsAndValues";
import { buildMedicineListQuery } from "../dbcq/medicinelistdb";
import log from "../../services/log";

export function MedicineOrderList2(props) {
  const { selectRows } = props;

  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const group = useSelector((state) => state?.drugs?.pharmagroup);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });

  const [rows, setRows] = useState(null);
  const [medicines, setMedicines] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const resetpage = { ...pagedata, page: 0 };
    const jsonData = buildMedicineListQuery(resetpage, searchcontext, group);
    dispatch(getDrugsOrderlist(jsonData)).then((r) => {
      log.info("medicineorder list: ", r);
      if (r.results) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  }, [dispatch, searchcontext]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = medicines.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(medicines);
    }
  };

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const jsonData = buildMedicineListQuery(newpagedata, searchcontext, group);
    dispatch(getDrugsOrderlist(jsonData)).then((r) => {
      log.info("medicineorder list: ", r);
      if (r.results) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (e, selectrow) => {
    // log.info("medicineorderlist", e)
    // let newlist = [... checkedRows];
    let newlist = [];
    if (selectrow) {
      newlist.push(e);
    }
    selectRows(e);
    setCheckedRows(newlist);
    dispatch(selectPatientMedcine(newlist));
  };

  // log.info("medicineorderlist", medicines);

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead>
              <MedicineOrderListHead2 />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {medicines &&
                medicines.map((row) => (
                  <MedicineOrderListRow2
                    key={row.id}
                    row={row}
                    isRowSelected={checkedRows}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
