// import axios from "axios";
import { API_URL } from "./Constants";
import { axiosApiInstance } from "./axiosmiddleware";
import log from './log';

const getLocAutocompService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "location/geocode", payload);
};

const getLocByplaceAutocompService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "location/geocodebyplace", payload);
};

const getAddressAutocompService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "location/list", payload);
};

const getAddressService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "addresses/list", payload);
};

const searchService = (payload) => {
  // console.log("indexsearch", payload)
  return axiosApiInstance.post(API_URL + "index/search", payload);
};

const docSearchService = (payload) => {
  // console.log("locservice", payload)
  return axiosApiInstance.post(API_URL + "index/docsearch", payload);
};

const docIndexService = (payload) => {
  // console.log("locservice", payload)
  return axiosApiInstance.post(API_URL + "index/docindex", payload);
};

export {
  getLocAutocompService,
  getAddressService,
  searchService,
  docSearchService,
  docIndexService,
  getAddressAutocompService,
  getLocByplaceAutocompService,
};
