import React, { useEffect, useState } from "react";

const FacebookIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#19445E" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <path
        d="M6.37296 18V9.78936H9.26765L9.70104 6.5895H6.37288V4.54653C6.37288 3.6201 6.64305 2.98879 8.0385 2.98879L9.81819 2.98799V0.126072C9.51038 0.0871459 8.45386 0 7.22488 0C4.65886 0 2.90212 1.49118 2.90212 4.22972V6.5895H0V9.78936H2.90212V17.9999H6.37296V18Z"
        fill="#19445E"
      />
    </svg>
  );
};
export default FacebookIcon;
