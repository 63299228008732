import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import ProfileMD from "../../profile/components/ProfileMD";

export function ProfileListMD() {
  return (
    <TableContainer component={Paper}>
      <ProfileMD />
    </TableContainer>
  );
}
