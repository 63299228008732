import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Paper,
  Typography,
  TextField,
  Collapse,
  IconButton,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
} from "@mui/material";
import PermPhoneMsgTwoToneIcon from "@mui/icons-material/PermPhoneMsgTwoTone";
import PhoneIphoneTwoToneIcon from "@mui/icons-material/PhoneIphoneTwoTone";
import { TitleProper } from "../../common/Title";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../common/ConstantsAndValues";
import { useSelector } from "react-redux";
import { MyIconButtons } from "../../CustomComponents/MyButtons";
import log from "../../../services/log";

function Row(props) {
  const { row, rowdata, rowkey, privacy, dgroup, address } = props;
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openID, setOpenID] = useState("");
  const [savedGroup, setSavedGroup] = useState("");

  const profiledata = useSelector((state) => state.auth.profile);

  log.info("profileuser", profiledata);

  const handleClick = (e) => {
    console.log("ind bef", openID, e);
    if (!openID) {
      setOpenEdit(!openEdit);
      setOpenID(e._id);
    }
  };

  const handleChange = (e) => {
    //setOpenEdit(!openEdit)
    setOpenID("");

    //console.log('ind change',openID, e)
  };

  const dateFormat = (v) => {
    return new Date(v).toLocaleDateString();
  };

  // log.info("profileuser", open);

  return (
    <React.Fragment>
      <StyledTableRow
        key={rowkey + "m"}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <StyledTableCell width="11px" key={rowkey + "test"}>
          <MyIconButtons onClick={() => setOpen(!open)} enState={open} />
        </StyledTableCell>

        <StyledTableCell component="th" scope="row" key={rowkey + "u1"}>
          {/*{row.type}*/}
        </StyledTableCell>

        <StyledTableCell align="right" key={rowkey + "u2"}>
          {profiledata &&
            profiledata.map((d) => (
              <Box>
                <Typography paragraph> {d.name} </Typography>
                <Typography paragraph> Gender {d.gender} </Typography>
                <Typography paragraph> DOB {dateFormat(d.dob)} </Typography>
                <Typography paragraph>
                  <PhoneIphoneTwoToneIcon fontSize={"small"} /> {d.mobile}
                </Typography>
                <Typography paragraph>
                  <PermPhoneMsgTwoToneIcon fontSize={"small"} /> {d.phone}
                </Typography>
                {/*<Typography paragraph> {d.status ? "Active" : "NotEnabled"} </Typography>*/}
                {/*<Typography paragraph> {d.secureId ? "Secure Block ID" : "No Block ID"} </Typography>*/}
              </Box>
            ))}

          {!openEdit &&
            rowdata &&
            rowdata.length > 0 &&
            rowdata[0].values.forEach((v) => <span>{v}</span>)}

          {openEdit && (
            <span>
              <TextField
                autoFocus
                margin="dense"
                id={row._id}
                //label        = {row.type}
                fullWidth
                variant="standard"
                defaultValue={row.value}
                onChange={handleChange}
                onFocus={handleChange}
              />
            </span>
          )}
        </StyledTableCell>
        <StyledTableCell align="center">
          {/*{row.privacy &&*/}
          {/*<Box className="text-color-primary">*/}
          {/*    <PrivacyTipTwoToneIcon />*/}
          {/*</Box>*/}
          {/*}*/}
        </StyledTableCell>

        <StyledTableCell align="right" key={rowkey + "u3"}>
          {/*{row.createdAt}*/}
        </StyledTableCell>
      </StyledTableRow>

      <TableRow key={rowkey + "d"}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mt={2} ml={7} mb={2} className="colfl">
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                px={1}
                className="bg-gray"
              >
                Update history
              </Typography>
              <Table
                size="small"
                aria-label="purchases"
                className="border-db"
                key={rowkey + "t"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {profiledata &&
                    profiledata.map((d) => (
                      <TableRow key={d.id}>
                        <TableCell component="th" scope="row" key={"date"}>
                          {dateFormat(d.updated_at)}
                        </TableCell>
                        <TableCell key={"data"}>
                          {d.name}, {d.gender}, {d.dob}, {d.mobile}, {d.phone}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ProfileUser(props) {
  const { label, updateCallback, privacy } = props;
  const [open, setOpen] = useState(false);
  const [addTitle, setAddTitle] = useState("");
  const [addEditable, setAddEditable] = useState("");

  const handleGroupClick = () => {
    // let title = aData.length ? aData[0].group : ""
    //console.log('group',aData)
    updateCallback();
    // setOpen(true)
    // setAddTitle(title + ( (props.privacy) ? " Privacy " : " Group "  ) + " Update");
    // setAddEditable(aData);
  };

  //Result
  const addEdited = (eventData) => {
    // collect output data
    console.log("add Profile edited data  :", eventData);

    //PENDING
    // to update db table
    // to rrfresh this page/ <==source data
  };

  // log.info("patientprofile user", label);

  return (
    <Grid container>
      <Grid item xs>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table" width={"100%"}>
            <TableBody className="text-color-primary text text-md">
              <TableRow key={"h" + 0}>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  className="bg-gray"
                ></TableCell>

                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 4 }}
                  className="bg-gray"
                  key={"ht"}
                >
                  <TitleProper>{label}</TitleProper>
                </TableCell>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  className="bg-gray"
                  align="right"
                  colSpan={1}
                >
                  {/*{isProtect[i]}*/}
                </TableCell>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  className="bg-gray"
                ></TableCell>
                <TableCell
                  align="right"
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  className="bg-gray"
                  colSpan={2}
                >
                  <IconButton
                    className="text-color"
                    aria-label="update"
                    size="small"
                    title={"Update " + "Personal" + " section"}
                    onClick={handleGroupClick}
                  >
                    {/*<span className="border-db btn-round btn-icon btn-main" >*/}
                    {/*    <UpgradeTwoToneIcon />*/}
                    {/*</span>*/}
                  </IconButton>
                </TableCell>
              </TableRow>

              <Row rowkey={label} privacy={true} />
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
