import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { Title } from "../../common/Title";
import {
  pageOptions,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import {
  getUserGroup,
  selectedPatient,
} from "../../../redux/user/user.actions";
import useStyles from "../../CustomComponents/useStyles";
import { PatientListORG2Row } from "./PatientListORG2Row";
import { PatientListHead } from "./PatientListORGORGHead";
import log from "../../../services/log";

export default function PatientListORG2(props) {
  const { userType } = props;

  const selectgroup = useSelector((state) => state?.user?.selectmdgroup);
  const usergroup = useSelector((state) => state?.user?.usergroup);
  const datalist = useSelector((state) => state?.user?.users);
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const searchtext = useSelector((state) => state?.user?.searchtext);
  const department = useSelector((state) => state?.orgs?.selectdepartment);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  const buildQuery = () => {
    if (searchcontext) {
      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata.orgId = department?.metadata?.orgId;
      jsonData.metadata.groupId = department?.metadata?.groupId;
      jsonData.metadata.departmentId = department?.metadata?.accountId;
      return jsonData;
    }
    return {};
  };

  useEffect(() => {
    usergroup ? setRows(usergroup) : setRows(null);
  }, [searchcontext, usergroup, datalist]);

  useEffect(() => {
    // log.info("serarch query", searchText);

    const resetpage = { ...pagedata, page: 0 };

    dispatch(getUserGroup(buildQuery())).then((r) => {
      log.info("datalist", r);
      setPage({
        ...pagedata,
        count: r.totalResults,
        pages: r.totalPages,
      });
    });
  }, [searchcontext, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    log.info("datalist", keyword);
    const newlist = keyword
      ? rows.filter((v) =>
          v.orgName.toLowerCase().startsWith(keyword.toLowerCase())
        )
      : datalist?.results;
    setRows(newlist);
  };

  const handleChangePage = (event, value) => {
    // log.info("datalist", value);
    // log.info("datalist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    // log.info("datalist nextpage", searchquery);
    dispatch(getUserGroup(buildQuery())).then((r) => {
      log.info("datalist", r);
      setPage({
        ...pagedata,
        count: r.totalResults,
        pages: r.totalPages,
      });
    });
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (row, add) => {
    // log.info("Prescribe", row);
    // log.info("Prescribe", add);

    if (row && add === true) {
      setCheckedRows(row);
      dispatch(selectedPatient(row));
    }

    if (row && add === false) {
      setCheckedRows(null);
      dispatch(selectedPatient(null));
    }
  };

  // log.info("patient serchcontext", searchcontext);
  // log.info("patient serchcontext", searchtext);

  return (
    <Grid container>
      <Grid item xs>
        <div>
          <div
            className="flex-grow-1"
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "36px",
              width: "100%",
            }}
          >
            <div>
              <div className="colfl0"></div>

              <div className="colfr0" width="100%">
                <SearchBoxStandard
                  onChange={handleFilter}
                  placeholder="Patient Name"
                  sx={{ marginRight: 1 }}
                  className="text-color-primary text-color-2-border text-color-2-bg"
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "16px" }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="collapsible table">
                <TableHead>
                  <PatientListHead />
                </TableHead>

                <TableBody className="text-color-primary text text-md">
                  {rows &&
                    rows.map((row) => (
                      <PatientListORG2Row
                        key={row.id}
                        row={row}
                        handleRowClick={handleRowClick}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div style={{ width: "100%" }}>
            <TablePaginationStandard
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              pagedata={pagedata}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
