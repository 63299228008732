import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTableHead } from "../styles/InsuranceStyles";

const styles = stylesTableHead;

export const InsuranceDetailTableHeader = () => (
  <View style={styles.container} fixed>
    <Text style={styles.memberid}>ID</Text>
    <Text style={styles.member}>Member</Text>
    <Text style={styles.relation}>Relation</Text>
    <Text style={styles.detail}>Detail</Text>
  </View>
);
