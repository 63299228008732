import { PolicyActionTypes } from "./policy.types";
import {
  getPoliciesService,
  getPolicySubsService,
  updatePolicyService,
  setPolicyService,
} from "../../services/policy-service";

export const getPolicies = (payload) => (dispatch) => {
  return getPoliciesService(payload).then(
    (response) => {
      dispatch({
        type: PolicyActionTypes.POLICY_LIST,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const setPolicy = (payload) => (dispatch) => {
  return setPolicyService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const updatePolicy = (payload) => (dispatch) => {
  return updatePolicyService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getPolicySubsList = (payload) => (dispatch) => {
  return getPolicySubsService(payload).then(
    (response) => {
      dispatch({
        type: PolicyActionTypes.POLICYSUBS_LIST,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};
