import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { getActiveContexts } from "../store/layout/selectors.js";

export const DicomContext = React.createContext({});

export const CONTEXTS = {
  CORNERSTONE: "ACTIVE_VIEWPORT::CORNERSTONE",
  VTK: "ACTIVE_VIEWPORT::VTK",
};

const useDicomContext = () => useContext(DicomContext);

export const DicomAppProvider = ({ children, config }) => {
  const activeContexts = useSelector((state) => getActiveContexts(state));

  return (
    <DicomContext.Provider value={{ appConfig: config, activeContexts }}>
      {children}
    </DicomContext.Provider>
  );
};

export const withDicomContext = (Component) => {
  return function WrappedComponent(props) {
    const { appConfig, activeContexts } = useDicomContext();
    return (
      <Component
        {...props}
        appConfig={appConfig}
        activeContexts={activeContexts}
      />
    );
  };
};
