import React, { useState } from "react";
import { TextField } from "@mui/material";
import useStyles from "./useStyles";
import FormControl from "@mui/material/FormControl";

function InputComponent(props) {
  const clases = useStyles();
  const {
    id,
    name,
    label,
    values,
    handleChange,
    errors,
    errorText,
    help,
    disabled,
    defaultValue,
  } = props;

  const [value, setValue] = useState();

  return (
    <>
      <FormControl variant="standard" fullWidth>
        <TextField
          key={props.id}
          style={{
            width: "100%",
            paddingBottom: "0px",
            marginTop: "0px",
            marginLeft: "auto",
            marginRight: "auto",
            background: "white",
          }}
          name={name}
          label={<span className="text-color">{label}</span>}
          disabled={disabled}
          defaultValue={defaultValue}
          className={errors ? "" : "text-color bg-white"}
          // error={errors}
          variant="standard"
          onChange={handleChange}
        />

        {/* {errors && !values && !errorText && (
          <span className={clases.errorMsg}>*</span>
        )}
        {errors && errorText && <span className={clases.errorMsg}>***</span>} */}
        {/*/!*{errors && errorText && <span className={clases.errorMsg}>{errorText}</span>}*!/*/}
        {/*{help   &&            <span className="text-color-primary">{help}</span>}*/}
      </FormControl>
    </>
  );
}

export default InputComponent;
