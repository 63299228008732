import { DrugsOTCList } from "./DrugsListOTC";
import log from "../../../services/log";

export default function DrugsListOTCPHA(props) {
  return (
    <div>
      <DrugsOTCList />
    </div>
  );
}
