import { createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import logger from "redux-logger";
import thunk from "redux-thunk";

import createSagaMiddleware from "redux-saga";
import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";
import reduxdicom from "../components/dicom/core/redux";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];
const { localStorage, sessionStorage } = reduxdicom;

// if (process.env.NODE_ENV === "development") {
//   middlewares.push(logger);
// }

const preloadedState = {
  ...localStorage.loadState(),
  ...sessionStorage.loadState(),
};

export const store = createStore(
  rootReducer,
  preloadedState,
  compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default { store, persistStore };
