import * as React from "react";
import { Grid, Box } from "@mui/material";
import { useFormik } from "formik";
import { specialistSchema } from "../../schema/specialistSchema";
import InputComponent from "../../CustomComponents/InputComponent";
import InputSpecializationList from "../../CustomComponents/InputSpecializationList";
import InputSpecialistStatusRole from "../../CustomComponents/InputSpecialistStatusRole";
import DataSaverOffTwoToneIcon from "@mui/icons-material/DataSaverOffTwoTone";

import { useDispatch, useSelector } from "react-redux";
import { colChart } from "../../common/ConstantsAndValues";
import { Title } from "../../common/Title";
import log from "../../../services/log";
import { updateMDGroup } from "../../../redux/user/user.actions";
import {
  MyTooltipButtons,
  MyHelpButtons,
} from "../../CustomComponents/MyButtons";

export const EditTeamSpecialist = (props) => {
  const { handleCancel, title } = props;

  const specialist = useSelector((state) => state.user.orgspecialist);
  const dispatch = useDispatch();
  const specialistform = useFormik({
    initialValues: {
      id: specialist ? specialist.id : null,
      orgId: specialist ? specialist.metadata.orgId : "",
      groupId: specialist ? specialist.metadata.groupId : "",
      departmentId: specialist ? specialist.metadata.departmentId : "",
      providerId: specialist ? specialist.metadata.providerId : "",
      providerEmail: specialist ? specialist.metadata.providerEmail : "",
      providerRole: specialist ? specialist.metadata.providerRole : "",
      orgName: specialist ? specialist.orgName : "",
      name: specialist ? specialist.name : "",
      specialization: specialist ? specialist.specialization : [],
      status: specialist ? specialist.status : "active",
    },
    validationSchema: specialistSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("clinicaltestinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["orgId"] = values.orgId;
      jsonData.metadata["groupId"] = values.groupId;
      jsonData.metadata["departmentId"] = values.departmentId;
      jsonData.metadata["providerId"] = values.providerId;
      jsonData.metadata["providerEmail"] = values.providerEmail;
      jsonData.metadata["providerRole"] = values.providerRole;
      jsonData["orgName"] = values.orgName;
      jsonData["name"] = values.name;
      jsonData["specialization"] = values.specialization;
      jsonData["status"] = values.status;
      jsonData["id"] = values.id;

      // log.info("specialist input", jsonData);
      dispatch(updateMDGroup(jsonData));
      handleCancel();
    },
    onReset: (values) => {},
  });

  const handleSpecialization = (data) => {
    const newdata = [...specialistform.values.specialization];
    data.forEach((d) => {
      newdata.push(d.label);
    });
    specialistform.setFieldValue("specialization", newdata);
  };

  log.info("specialistupdate", specialistform.errors);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%", md: "60%", lg: "50%" },
        }}
      >
        <div>
          <div className="colfl0">
            <Title>{title}</Title>
          </div>
          <div className="colfr0">
            <MyTooltipButtons
              onClick={handleCancel}
              btnKey={"cancel"}
              btnLabel={"Cancel"}
              enState={false}
            />
          </div>
        </div>
        <div className="clear" />
        <InputComponent
          id="name"
          name="name"
          label="Name"
          errorText={specialistform.errors.name}
          defaultValue={specialistform.values.name}
          errors={specialistform.errors.name ? true : false}
          variant="standard"
          handleChange={specialistform.handleChange}
        />
        <InputSpecializationList
          id="specialization"
          name="specialization"
          label="Specialization"
          errorText={specialistform.errors.specialization}
          defaultValue={specialistform.values.specialization}
          errors={specialistform.errors.specialization ? true : false}
          variant="standard"
          handleChange={handleSpecialization}
        />
        &nbsp;
        <InputSpecialistStatusRole
          id="status"
          name="status"
          label="Status"
          values={specialistform.values.status}
          errors={specialistform.errors.status}
          handleChange={specialistform.handleChange}
        />
        <Box className="clear" />
        <Box mt={2} pt={2} className="border-dbt">
          <Box className="colfr0">
            <MyHelpButtons
              onClick={specialistform.handleSubmit}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
              btnTooltip={"Add"}
            />
          </Box>
          <Box className="clear" />
        </Box>
        <Box className="clear" />
      </Grid>
    </Grid>
  );
};
