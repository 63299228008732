import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPatientProfile } from "../../../redux/user/user.actions";
import {
  SearchBoxStandard,
  StyledTableCell,
  StyledTableRow,
  tableParam,
} from "../../common/ConstantsAndValues";
import { MyIconButtons } from "../../CustomComponents/MyButtons";

import log from "../../../services/log";

export function PatientListORG2Row(props) {
  const { row, handleRowClick } = props;

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [maplist, setMaplist] = useState(false);

  const selectpatient = useSelector((state) => state?.user?.selectedPatient);
  const patientprofile = useSelector((state) => state?.user?.patientprofile);
  const dispatch = useDispatch();

  useEffect(() => {
    // log.info("CTlistrow", row);
    if (selectpatient) {
      const selectrow = row.id === selectpatient?.id;
      setSelectedRow(selectrow);
    }
  }, [selectpatient]);

  useEffect(() => {
    if (selectpatient) {
      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["accountId"] = selectpatient?.metadata?.accountId;
      dispatch(getPatientProfile(jsonData));
    }
  }, [selectpatient, dispatch]);

  const handleClick = () => {
    // log.info("profilelist");
    setOpen(!open);
  };

  const handleFilter = () => {};

  const handleRowClickLocal = (e) => {
    log.info("profilelist", e);
    const newstate = !selectedRow;
    setSelectedRow(newstate);
    handleRowClick(e, newstate);
  };

  log.info("profile md row", row);

  return (
    <React.Fragment>
      <StyledTableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
        onClick={() => handleRowClickLocal(row)}
      >
        <StyledTableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <b>{row.name}</b>
        </StyledTableCell>
        <StyledTableCell>{row.updated_at}</StyledTableCell>
      </StyledTableRow>

      <TableRow className="text-color-gray bg-standard-offwhite">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">
                  Patient Information
                </div>
                <div className="colfl">
                  <SearchBoxStandard
                    onChange={handleFilter}
                    placeholder="Property Name"
                    sx={{ marginRight: 1 }}
                    className="text-color-primary text-color-2-border text-color-2-bg"
                  />
                </div>
              </div>

              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow className="bg-gray">
                      <TableCell>More information</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={"gender" + patientprofile?.id}>
                      <TableCell>{"Gender"}</TableCell>
                      <TableCell>{patientprofile?.gender}</TableCell>
                    </TableRow>
                    <TableRow key={"dob" + patientprofile?.id}>
                      <TableCell>{"DOB"}</TableCell>
                      <TableCell>{patientprofile?.dob}</TableCell>
                    </TableRow>
                    <TableRow key={"eamil" + patientprofile?.id}>
                      <TableCell>{"Eamil"}</TableCell>
                      <TableCell>{patientprofile?.metadata?.email}</TableCell>
                    </TableRow>
                    <TableRow key={"status" + patientprofile?.id}>
                      <TableCell>{"Status"}</TableCell>
                      <TableCell>{patientprofile?.status}</TableCell>
                    </TableRow>
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
