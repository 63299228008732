import DicomMetadataStore from "./DicomMetadataStore";
const publicAPI = {
  DicomMetadataStore,
};

export default {
  name: "DicomMetadataStore",
  create: ({ configuration = {} }) => {
    return publicAPI;
  },
};
// export default DicomMetadataStore;
