import React from "react";
import { Text, Line, View, Image, StyleSheet } from "@react-pdf/renderer";
import { stylesLogo } from "../styles/CommonStyles";

import phone from "../../../assets/images/icons/phone.png";
import email from "../../../assets/images/icons/email.png";

const styles = stylesLogo;

export const CommonHeader = ({ logo, invoice }) => (
  <View style={styles.headContainer} fixed>
    <View style={styles.logoContainer} fixed>
      <Image style={styles.logo} src={logo} />
    </View>
    <View style={styles.addressContainer} fixed>
      <View style={styles.orgContainer}>
        <Text style={styles.org}>{invoice.company}</Text>
      </View>
    </View>
  </View>
);

export const CommonHeaderXXX = ({ logo, invoice }) => (
  <View style={styles.headContainer}>
    <View style={styles.logoContainer} fixed>
      <Image style={styles.logo} src={logo} />
    </View>
    <View style={styles.addressContainer}>
      <View style={styles.orgContainer}>
        <Text style={styles.org}>{invoice.company}</Text>
      </View>
      <View style={styles.addrContainer}>
        <Text style={styles.addr}>{invoice.address}</Text>
      </View>
      <View style={styles.connContainer}>
        <View style={styles.connInnerContainer}>
          <View style={styles.connDetContainer}>
            <Image style={styles.icon} src={phone} />
            <Text style={styles.connDet}>{invoice.phone}</Text>
          </View>
          <View style={styles.connDetContainer}>
            <Image style={styles.icon} src={email} />
            <Text style={styles.connDet}>{invoice.email}</Text>
          </View>
        </View>
      </View>
    </View>
  </View>
);
