import AboutModal from "./AboutModal.json";
import Buttons from "./Buttons.json";
import CineDialog from "./CineDialog.json";
import Common from "./Common.json";
import DatePicker from "./DatePicker.json";
import Header from "./Header.json";
import MeasurementTable from "./MeasurementTable.json";
import StudyList from "./StudyList.json";
import UserPreferencesModal from "./UserPreferencesModal.json";
import ViewportDownloadForm from "./ViewportDownloadForm.json";

export default {
  "en-US": {
    AboutModal,
    Buttons,
    CineDialog,
    Common,
    DatePicker,
    Header,
    MeasurementTable,
    StudyList,
    UserPreferencesModal,
    ViewportDownloadForm,
  },
};
