////////////////////////////////////////////////////
//                 common for metapharma          //
////////////////////////////////////////////////////
import * as React from "react";

import { ContainerPHA } from "../pharmacy/components/ContainerPHA";
import { commonSearchMenuOptions } from "./ConstantsAndValues";

import InventoryTwoToneIcon from "@mui/icons-material/InventoryTwoTone";
import PersonalVideoTwoToneIcon from "@mui/icons-material/PersonalVideoTwoTone";
import FormatListBulletedTwoToneIcon from "@mui/icons-material/FormatListBulletedTwoTone";

import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import TimelineTwoToneIcon from "@mui/icons-material/TimelineTwoTone";
import AddAlertTwoToneIcon from "@mui/icons-material/AddAlertTwoTone";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import CreateNewFolderTwoToneIcon from "@mui/icons-material/CreateNewFolderTwoTone";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import UpgradeTwoToneIcon from "@mui/icons-material/UpgradeTwoTone";
import SwitchAccountTwoToneIcon from "@mui/icons-material/SwitchAccountTwoTone";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";

export function ProfPHA() {
  const containerType = {
    type: "profile",
    label: "My Profile",
    menuList: [
      {
        _id: "pharmalist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/profile/list",
        more: false,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/profile/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/profile/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ProfGroupPHA() {
  const containerType = {
    type: "profilegroup",
    label: "My Group",
    menuList: [
      {
        _id: "pharmalist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/group/list",
        more: false,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/group/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/group/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function InfoPHA() {
  const containerType = {
    type: "info",
    label: "Information",
    menuList: [
      {
        _id: "pharmalist",
        label: "Info",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/info/info",
        more: false,
      },
      {
        _id: "pharmammanage",
        label: "Manage",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metapharma/info/manage",
        more: false,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/info/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/info/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ApptPHA() {
  const containerType = {
    type: "appointments",
    label: "Appointments",
    menuList: [
      {
        _id: "pharmalist",
        label: "Cal",
        icon: <CalendarMonthTwoToneIcon />,
        link: "/metapharma/appointments/cal",
        more: false,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/appointments/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/appointments/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function MoniPHA() {
  const containerType = {
    type: "monitor",
    label: "Monitor",
    menuList: [
      {
        _id: "pharmalist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/monitor/list",
        more: false,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/monitor/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/monitor/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function PresPHA() {
  const containerType = {
    eventType: ["event"],
    type: "prescription",
    label: "Prescription",
    menuList: [
      {
        _id: "pharmalist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/prescriptions/list",
        more: false,
      },
      {
        _id: "pharmamonitor",
        label: "Monitor",
        icon: <PersonalVideoTwoToneIcon />,
        link: "/metapharma/prescriptions/monitor",
        more: false,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/prescriptions/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/prescriptions/messages",
        more: true,
      },
    ],
    comboLabel: "Any more addl combo box",
    comboList: [],
  };
  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function PatnPHA() {
  const containerType = {
    type: "patients",
    label: "Patients",
    menuList: [
      {
        _id: "pharmalist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/patients/list",
        more: false,
      },
      {
        _id: "pharmamanage",
        label: "Manage",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metapharma/patients/manage",
        more: false,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/patients/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/patients/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function OrderPHA() {
  const containerType = {
    type: "orders",
    label: "Orders",
    menuList: [
      {
        _id: "pharmalist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/orders/list",
        more: false,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/orders/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/orders/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function DrugPHA() {
  const containerType = {
    type: "drugs",
    label: "Drugs",
    menuList: [
      {
        _id: "pharmalist",
        label: "Price List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/drugs/list",
        more: false,
      },
      {
        _id: "pharmatax",
        label: "Tax",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/drugs/tax",
        more: false,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/drugs/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/drugs/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function SupoPHA() {
  const containerType = {
    type: "support",
    label: "Support",
    menuList: [
      {
        _id: "pharmalist",
        label: "Info",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/support/info",
        more: false,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/support/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/support/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function AccPHA() {
  const containerType = {
    type: "accounts",
    label: "Accounts",
    menuList: [
      {
        _id: "plist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/accounts/list",
        more: false,
      },
      {
        _id: "pledger",
        label: "Ledger",
        icon: <FormatListBulletedTwoToneIcon />,
        link: "/metapharma/accounts/ledger",
        more: false,
      },
      {
        _id: "psettings",
        label: "Settings",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metapharma/accounts/settings",
        more: false,
      },
      {
        _id: "pnalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metapharma/accounts/analytics",
        more: true,
      },
      {
        _id: "palert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/accounts/notifications",
        more: true,
      },
      {
        _id: "pmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/accounts/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function SetPHA() {
  const containerType = {
    eventType: ["setup"],
    type: "setup",
    label: "Settings",
    menuList: [
      {
        _id: "pharmalist",
        label: "Accounts",
        icon: <PrivacyTipTwoToneIcon />,
        link: "/metapharma/setup/privacy",
        more: false,
      },
      {
        _id: "pharmasettings",
        label: "Settings",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metapharma/setup/settings",
        more: false,
      },
      {
        _id: "pharmauploads",
        label: "Uploads",
        icon: <UpgradeTwoToneIcon />,
        link: "/metapharma/setup/uploads",
        more: false,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/setup/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/setup/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function TodoPHA() {
  const containerType = {
    type: "todo",
    label: "Management",
    menuList: [
      {
        _id: "pharmalist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/todo/list",
        more: false,
      },
      {
        _id: "pharmanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metapharma/todo/analytics",
        more: true,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/todo/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/todo/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function MetapharmaDoThisNowOrig() {
  const containerType = {
    type: "do",
    label: "Broadcast Now",
    menuList: [
      {
        _id: "pharmalist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metapharma/do/list",
        more: false,
      },
      {
        _id: "pharmanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metapharma/do/analytics",
        more: true,
      },
      {
        _id: "pharmaalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metapharma/do/notifications",
        more: true,
      },
      {
        _id: "pharmamsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metapharma/do/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerPHA
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}
