import * as React from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import {
  Box,
  Paper,
  Collapse,
  IconButton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import log from "../../services/log";

import {
  PatientListPath,
  PatientListPathS,
} from "../common/ConstantsAndValues";
import ThreeDotPatientAddEdit from "./ThreeDotPatientAddEdit";
import { Title } from "../common/Title";
import {
  getUsers,
  getUsersList,
  selectedPatient,
} from "../../redux/user/user.actions";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../common/ConstantsAndValues";

export function TableList(props) {
  const patients = useSelector((state) => state?.user?.users);
  const usergroup = useSelector((state) => state?.user?.usergroup);
  const [selectedRow, setSelectedRow] = useState({});
  const [actionState, setActionState] = useState(""); //callback

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUsers({
        query: {
          role: "metauser",
        },
      })
    );
  }, []);

  // getUsersList search by name or email

  const handleClick = (row) => {
    console.log("Selected", row, PatientListPathS);
    setSelectedRow(row);
    localStorage.setItem("patient", JSON.stringify(row));
    dispatch(selectedPatient(row));

    {
      /*REMEMBERED LAST PATH URL*/
    }
    // const savedUrl = localStorage.getItem("prevURL");
    // let url      = JSON.parse(savedUrl);
    // url          = (url!==PatientListPath) ?  url || PatientListPathS : PatientListPathS ;
    //
    // console.log('p list urll', JSON.parse(savedUrl), url);
    //
    // navigate(url)
  };

  log.info("patienttable", patients);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "16px",
        overflowX: "hidden",
      }}
    >
      <div className="colfl0 flex-grow-1">
        {actionState === "addPatient" && (
          <div>
            <Title>Add Patient</Title>
          </div>
        )}
        {actionState === "editPatient" && (
          <div>
            <Title>Edit Patient</Title>
          </div>
        )}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="caption table">
            <TableHead>
              <StyledTableRowHead>
                {props.columns.map((col, i) => (
                  <StyledTableCellHead key={"tc" + i}>
                    {col.label}
                  </StyledTableCellHead>
                ))}
              </StyledTableRowHead>
            </TableHead>
            <TableBody>
              {patients &&
                patients.map((row) => (
                  <StyledTableRow
                    hover={true}
                    key={row.id}
                    onClick={() => handleClick(row)}
                  >
                    <StyledTableCell align="left" key={row.id}>
                      {row.name}
                    </StyledTableCell>
                    {/*<TableCell align="left" key={"2"+row.id} >{row.sex}</TableCell>*/}
                    {/*<TableCell align="left" key={"3"+row.id} >{row.dob}</TableCell>*/}
                    {/*<TableCell align="left" key={"4"+row.id} >{row.address}</TableCell>*/}
                    {/*<TableCell align="left" key={"5"+row.id} >{row.city}</TableCell>*/}
                    {/*<TableCell align="left" key={"6"+row.id} >{row.state}</TableCell>*/}
                    {/*<TableCell align="left" key={"7"+row.id} >{row.zipcode}</TableCell>*/}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export const PatientListFields = [
  {
    label: "Name",
    id: "name",
  },
  // {
  //     label: "Sex",
  //     id: 'sex',
  // }, {
  //     label: "DOB",
  //     id: 'dob',
  // }, {
  //     label: "Address",
  //     id: 'address'
  // }, {
  //     label: "City",
  //     id: 'city',
  // }, {
  //     label: "State",
  //     id: 'state',
  // }, {
  //     label: "Zipcode",
  //     id: 'zipcode'
  // }
];

export const PatientListNullRow = {
  id: null,
  name: null,
  sex: null,
  dob: null,
  address: null,
  city: null,
  state: null,
  zipcode: null,
};
