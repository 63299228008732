import { DirectoryActionTypes } from "../directory/directory.types";

const INITIAL_STATE = {
  providerlist: null,
  selectproviderlistrow: null,
};

const directoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DirectoryActionTypes.PROVIDER_LIST:
      return {
        ...state,
        providerlist: action.payload,
      };

    case DirectoryActionTypes.PROVIDERLIST_SELECT:
      return {
        ...state,
        selectproviderlistrow: action.payload,
      };
    default:
      return state;
  }
};

export default directoryReducer;
