import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useStyles from "../CustomComponents/useStyles";
import log from "../../services/log";
import { locByDistanceSearchQueries } from "../utils/locationmap";
import {
  getProviderList,
  selectProviderListRow,
} from "../../redux/directory/directory.actions";
import { Grid } from "@material-ui/core";
import { SearchBoxStandard } from "../common/ConstantsAndValues";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import * as React from "react";
import { ProviderListApptRow } from "./ProviderListApptRow";
import { ProviderListApptHead } from "./ProviderListApptHead";

export function ProviderListAppt(props) {
  const {
    searchText,
    selectRows,
    cancel,
    distance,
    department,
    handleStepDone,
  } = props;

  const selectService = useSelector((state) => state?.appointment?.apptstype);
  const addressgeo = useSelector((state) => state.locs.addressgeo);
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const providers = useSelector((state) => state.directory.providerlist);
  const searchcontext = useSelector((state) => state.user.searchcontext);

  const selectaddress = useSelector((state) => state.addresses.selectaddress);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);
  const [geoloc, setGeoloc] = useState({ lat: 0, lon: 0 });

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (providers) {
      setRows(providers.hits);
    }
  }, [providers]);

  useEffect(() => {
    if (addressgeo) {
      const newloc = {
        lat: addressgeo[0]?.geometry?.location?.lat,
        lon: addressgeo[0]?.geometry?.location?.lng,
      };
      setGeoloc(newloc);
    }
  }, [addressgeo]);

  useEffect(() => {
    if (selectaddress) {
      setGeoloc(selectaddress?.location);
    }
  }, [selectaddress]);

  const buildQuery = (paged, d, loc) => {
    log.info("providerlist loc", loc);
    const searchquery = locByDistanceSearchQueries(
      "role",
      selectService?.role,
      paged,
      d,
      loc
    );
    return searchquery;
  };

  useEffect(() => {
    log.info("providerlist", addressgeo);
    if (selectService && geoloc) {
      const resetpage = { ...pagedata, page: 0 };
      const query = buildQuery(pagedata, distance, geoloc);
      log.info("providerlist", query);
      dispatch(getProviderList(query)).then((r) => {
        log.info("providers list", r);
        if (r.total.value && r.total.value > 0) {
          setPage({
            ...resetpage,
            count: r.total.value,
            pages: r.total.value / pagedata.limit,
          });
        }
      });
    }
  }, [geoloc, selectService, distance]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      // const results = addresses.results.filter((v) =>
      //     v.labTitle.startsWith(keyword.toLowerCase()));
      // setRows(results);
    } else {
      // setRows(addresses.results)
    }
  };

  const handleChangePage = (event, value) => {
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const query = buildQuery(newpagedata, distance, geoloc);
    log.info("providerlist", query);
    dispatch(getProviderList(query)).then((r) => {
      log.info("providers list", r);
      if (r.total.value && r.total.value > 0) {
        setPage({
          ...newpagedata,
          count: r.total.value,
          pages: r.total.value / pagedata.limit,
        });
      }
    });
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };
  const handleRowClick = (row, add) => {
    // log.info("address", add);
    if (row && add === true) {
      setCheckedRows(row);
      dispatch(selectProviderListRow(row));
      handleStepDone();
    }
    if (row && add === false) {
      setCheckedRows(null);
      dispatch(selectProviderListRow(null));
    }
  };

  const handleDone = () => {
    cancel();
  };

  const handleCancel = () => {
    cancel();
  };

  log.info("providers", searchText);

  return (
    <Grid container>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div className="flex-grow-1">
          <div className="colfl0"></div>

          <div className="colfl0">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Search"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
      </div>

      <div className="flex-grow-1">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead>
              <ProviderListApptHead />
            </TableHead>
            <TableBody className="text-color-primary text text-md">
              {rows &&
                rows.map((row) => (
                  <ProviderListApptRow
                    key={row._id}
                    row={row}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ width: "100%" }}>
          <TablePaginationStandard
            handleChangePage={handleChangePage}
            handleChangeRows={handleChangeRows}
            pagedata={pagedata}
          />
        </div>
      </div>
    </Grid>
  );
}
