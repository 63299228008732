// import axios from "axios";
import { API_URL } from "./Constants";
import { axiosApiInstance } from "./axiosmiddleware";

const getOrgService = (payload) => {
  // console.log("orgservice", API_URL);
  return axiosApiInstance.post(API_URL + "orgs/list", payload);
};

const setOrgService = (payload) => {
  // console.log(API_URL)
  return axiosApiInstance.post(API_URL + "orgs/set", payload);
};

const updateOrgService = (payload) => {
  // console.log("orgservice", API_URL)
  return axiosApiInstance.post(API_URL + "orgs/update", payload);
};

const listOrgDepartmentService = (payload) => {
  // console.log("orgservice", API_URL)
  return axiosApiInstance.post(API_URL + "departments/list", payload);
};

const addOrgDepartmentService = (payload) => {
  // console.log("orgservice", API_URL)
  return axiosApiInstance.post(API_URL + "departments/set", payload);
};

const updateOrgDepartmentService = (payload) => {
  // console.log("orgservice", API_URL)
  return axiosApiInstance.post(API_URL + "departments/update", payload);
};

const listOrgGroupService = (payload) => {
  // console.log("orgservice", API_URL)
  return axiosApiInstance.post(API_URL + "groups/list", payload);
};

const setOrgGroupService = (payload) => {
  // console.log("orgservice", API_URL)
  return axiosApiInstance.post(API_URL + "groups/set", payload);
};

const updateOrgGroupService = (payload) => {
  // console.log("orgservice", API_URL)
  return axiosApiInstance.post(API_URL + "groups/update", payload);
};

const updateOrgProfileService = (payload) => {
  // console.log("orgservice", API_URL)
  return axiosApiInstance.post(API_URL + "orgprofile/update", payload);
};
const setOrgProfileService = (payload) => {
  // console.log("orgservice", API_URL)
  return axiosApiInstance.post(API_URL + "orgprofile/set", payload);
};
const getOrgProfileService = (payload) => {
  // console.log("orgservice", API_URL)
  return axiosApiInstance.post(API_URL + "orgprofile/list", payload);
};

export {
  getOrgService,
  updateOrgService,
  setOrgService,
  listOrgDepartmentService,
  addOrgDepartmentService,
  updateOrgDepartmentService,
  updateOrgGroupService,
  setOrgGroupService,
  listOrgGroupService,
  getOrgProfileService,
  setOrgProfileService,
  updateOrgProfileService,
};
