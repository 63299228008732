import * as React from "react";
import { TablePagination } from "@mui/material";
import { pageOptions } from "../common/ConstantsAndValues";
import log from "../../services/log";

export function TablePaginationStandard(props) {
  const { handleChangePage, handleChangeRows, pagedata } = props;

  return (
    <TablePagination
      component="div"
      className="bg-gray border-dbt"
      width="auto"
      rowsPerPageOptions={pageOptions}
      count={pagedata.count}
      rowsPerPage={pagedata.limit}
      page={pagedata.page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRows}
      backIconButtonProps={{
        "aria-label": "< Previous",
      }}
      nextIconButtonProps={{
        "aria-label": "Next >",
      }}
    />
  );
}
