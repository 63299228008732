import React, { useEffect, useState } from "react";

const PaymentsIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})`, marginTop: "5px" }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
    >
      <rect x="0.992188" y="12.4609" width="19.6356" height="2" rx="1" />
      <rect x="0.992188" y="4.65625" width="19.6356" height="2" rx="1" />
      <rect x="0.992188" y="0.984375" width="19.6356" height="2" rx="1" />
      <rect
        x="2.8125"
        y="0.984375"
        width="13.4736"
        height="2"
        rx="1"
        transform="rotate(90 2.8125 0.984375)"
      />
      <rect
        x="20.6328"
        y="0.984375"
        width="13.4736"
        height="2"
        rx="1"
        transform="rotate(90 20.6328 0.984375)"
      />
      <rect x="6.60156" y="8.8125" width="8.23769" height="1.5" rx="0.75" />
    </svg>
  );
};
export default PaymentsIcon;
