import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableFooter,
} from "@mui/material";
import { Box, Typography, Collapse, IconButton } from "@mui/material";
import { SearchBoxStandard, tableParam } from "../../common/ConstantsAndValues";
import {
  StyledTableRow,
  StyledTableCell,
} from "../../common/ConstantsAndValues";
import { MyIconButtons } from "../../CustomComponents/MyButtons";
import log from "../../../services/log";

export function DrugsListOTCRow(props) {
  const { row, handleRowClick } = props;
  const selectdrugs = useSelector((state) => state?.drugs?.otcdrugselect);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);

  useEffect(() => {
    // log.info("druglistrow", row);
    if (selectdrugs) {
      const selectrow = row?._source?.Id === selectdrugs?._source?.Id;
      setSelectedRow(selectrow);
    }
  }, [selectdrugs]);

  const handleClick = () => {
    // log.info("druglist");
    setOpen(!open);
  };

  const handleFilter = () => {};

  const handleRowClickLocal = (e) => {
    log.info("druglist", e);
    const newstate = !selectedRow;
    setSelectedRow(newstate);
    handleRowClick(e, newstate);
  };

  return (
    <React.Fragment>
      <StyledTableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
        onClick={() => handleRowClickLocal(row)}
      >
        <StyledTableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <b>{row._source["name"]}</b>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant={"body2"}>{row._source.category}</Typography>
        </StyledTableCell>
        {/*<StyledTableCell align="right">{row._source['ingredients']}</StyledTableCell>*/}
      </StyledTableRow>

      <TableRow className="text-color-gray bg-standard-offwhite">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">Drug Information</div>
                <div className="colfl">
                  <SearchBoxStandard
                    onChange={handleFilter}
                    placeholder="property name"
                    sx={{ marginRight: 1 }}
                    className="text-color-primary text-color-2-border text-color-2-bg"
                  />
                </div>
              </div>

              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow className="bg-gray">
                      <TableCell>Properties</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={"descp" + row._source["Id"]}>
                      <TableCell>{"Description"}</TableCell>
                      <TableCell>{row._source["introduction"]}</TableCell>
                    </TableRow>
                    <TableRow key={"benef" + row._source["Id"]}>
                      <TableCell>{"Benefits"}</TableCell>
                      <TableCell>{row._source["benefits"]}</TableCell>
                    </TableRow>
                    <TableRow key={"saf" + row._source["Id"]}>
                      <TableCell>{"Safety"}</TableCell>
                      <TableCell>{row._source["safety"]}</TableCell>
                    </TableRow>
                    <TableRow key={"ingred" + row._source["Id"]}>
                      <TableCell>{"Ingredients"}</TableCell>
                      <TableCell>{row._source["ingredients"]}</TableCell>
                    </TableRow>
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
