import React, { useEffect, useState } from "react";

export const MetaSearchIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_309_1992)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0623 10.5536C19.0623 15.2777 15.2327 19.1073 10.5087 19.1073C5.78467 19.1073 1.95508 15.2777 1.95508 10.5536C1.95508 5.82959 5.78467 2 10.5087 2C15.2327 2 19.0623 5.82959 19.0623 10.5536ZM17.5725 18.3948C15.7021 20.0809 13.2252 21.1073 10.5087 21.1073C4.6801 21.1073 -0.0449219 16.3822 -0.0449219 10.5536C-0.0449219 4.72502 4.6801 0 10.5087 0C16.3373 0 21.0623 4.72502 21.0623 10.5536C21.0623 12.945 20.267 15.1505 18.9264 16.9202L23.6184 21.6122C24.0089 22.0027 24.0089 22.6359 23.6184 23.0264C23.2278 23.4169 22.5947 23.4169 22.2042 23.0264L17.5725 18.3948Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_1992">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
