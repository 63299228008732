import React, { useState, useEffect, useLayoutEffect } from "react";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
//import Select from 'react-select';
import { tableParam, colChart } from "../common/ConstantsAndValues";
import { SelectOptionsAuto } from "../common/SelectOptions";
const MSStyles = {
  control: (styles) => ({ ...styles }),
  container: (styles) => ({ ...styles, width: colChart.width }),
  option: (styles) => ({ ...styles }),
  // menu:      styles => ({ ...styles,  width: colChart.width }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: colChart.width,
  }),
};

export function ReportSelect(props) {
  const { reportCallback, tests, page } = props;
  const [selectedOption, setSelectedOption] = useState([]);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (tests.labs) {
      let newoptions = [];
      tests.labs.forEach((d) =>
        newoptions.push({ label: d.label, value: d.link })
      );
      setOptions(newoptions);
    } else {
      setOptions([]);
    }
  }, [tests.labs]);

  const handleChange = (event) => {
    //abandoned
    setSelectedOption(event);
    props.reportCallback(event.target.value);
  };

  function handleChangeSelect(event) {
    setSelectedOption(event);
    props.reportCallback(event);

    //handleDone();
  }
  const handleDone = () => {
    //dispatch(selectOrgGroup(selectedOption.value));
    //console.log('LCC', selectedOption, tests.labs);
    props.reportCallback(selectedOption);

    //openPage(selectedOption)
  };

  // console.log('LCC', tests.labs);

  return (
    <SelectOptionsAuto
      openPage={handleChangeSelect}
      data={options}
      multi={true}
      title={page + " report"}
      wide={0}
    />
  );
}

{
  /*
        <Select
            options={options}
            placeholder={"Select " + page + " report"}
            defaultValue={selectedOption}
            onChange={handleChangeSelect}
            onBlurr = {handleDone}
            isSearchable={true}
            //styles={MSStyles}
            menuPlacement="auto"
            menuPosition="fixed"
            isMulti={true}
        />

*/
}
