import * as d3 from "d3";
import React, { useEffect, useRef, useState } from "react";
import log from "../../services/log";

export function HomeRectArea({ width, height, isFullWidth }) {
  // log.info("height: ", height);

  const rectHeight = parseInt(height * 0.88);
  const rectWidth = isFullWidth ? parseInt(width) : parseInt(width * 0.373);

  const svgRef = useRef(null);
  useEffect(() => {
    drawReactange();
  }, [width, height]);

  const drawReactange = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous contents

    const xPos = width - rectWidth;
    const yPos = height - rectHeight;

    const gradientx = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "gradientx")
      .attr("x1", "0%")
      .attr("x2", "0%")
      .attr("y1", "0%")
      .attr("y2", "100%");

    gradientx
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#EAF4F7")
      .attr("stop-opacity", 1);

    gradientx
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#EAF4F7")
      .attr("stop-opacity", 0.5);

    const radius = isFullWidth
      ? parseInt((width * 60) / 900)
      : parseInt((width * 60) / 1920);

    const pathData = `
        M 0,${radius}
        Q 0,0 ${radius},0
        H ${rectWidth}
        V ${rectHeight}
        H 0
        Z
        `;

    // Draw the rectangle
    svg
      .append("path")
      .attr("x", xPos)
      .attr("y", yPos)
      .attr("d", pathData)
      .attr("transform", `translate(${xPos}, ${yPos})`)
      .attr("opacity", 0.7)
      .attr("fill", "url(#gradientx)");
  };

  return <svg ref={svgRef} width="100%" height="100%"></svg>;
}
