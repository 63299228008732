import { PaymentActionTypes } from "./payment.types";

const INITIAL_STATE = {
  ledgerlist: null,
};

const paymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PaymentActionTypes.LEDGER_LIST:
      return {
        ...state,
        ledgerlist: action.payload,
      };
    default:
      return state;
  }
};

export default paymentReducer;
