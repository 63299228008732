import * as React from "react";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";

import { pageOptions, SearchBoxStandard } from "../common/ConstantsAndValues";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import {
  MyButtons,
  MyTooltipButtons,
  MyHelpButtons,
} from "../CustomComponents/MyButtons";

import {
  getAddressList,
  selectOrgAddress,
} from "../../redux/actions/addresses";

import useStyles from "../CustomComponents/useStyles";
import { labsMetaQueries } from "../utils/labsqueries";

import { LocationListRow } from "./LocationListRow";
import { LocationListHead } from "./LocationListHead";
import log from "../../services/log";

export function LocationList(props) {
  const { searchText, selectRows, cancel, title } = props;
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const addresses = useSelector((state) => state.addresses.addresslist);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [querytext, setQuerytext] = useState("a");
  const [checkedRows, setCheckedRows] = useState(null);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (addresses) {
      setRows(addresses.results);
    }
  }, [addresses]);

  const buildQuery = (searchtext, paged) => {
    const searchquery = searchtext
      ? searchtext.name
        ? labsMetaQueries("name", searchtext.name, paged)
        : searchtext.group
        ? labsMetaQueries("groupName", searchtext.group, paged)
        : labsMetaQueries("name", querytext, paged)
      : labsMetaQueries("name", querytext, paged);
    return searchquery;
  };

  useEffect(() => {
    dispatch(getAddressList({})).then((r) => {
      // log.info("addresslist", r);
      if (r) {
        setPage({
          ...pagedata,
          count: r.totalResults,
          pages: r.totalPages,
        });
      }
    });
  }, [searchText]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      // const results = addresses.results.filter((v) =>
      //     v.labTitle.startsWith(keyword.toLowerCase()));
      // setRows(results);
    } else {
      setRows(addresses.results);
    }
  };

  const handleChangePage = (event, value) => {
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const searchquery = buildQuery(querytext, newpagedata);
    // dispatch(getMetaLabsSearch(searchquery)).then((r) => {
    //     // log.info("labslist", r)
    //     if (r.total.value && r.total.value > 0) {
    //         setPage(newpagedata);
    //     }
    // });
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (row, add) => {
    log.info("address", add);
    if (row && add === true) {
      setCheckedRows(row);
      dispatch(selectOrgAddress(row));
    }
    if (row && add === false) {
      // const flist = newlist.filter((v) => v._source.Id !== row._source.Id)
      setCheckedRows(null);
      dispatch(selectOrgAddress(null));
    }
  };

  const handleDone = () => {
    // log.info("checked address", checkedRows);
    dispatch(selectOrgAddress(checkedRows));
    cancel();
  };

  const handleCancel = () => {
    cancel();
  };

  return (
    <Grid container>
      <div className="flex-grow-1">
        <div className="colfl0"></div>
        <div className="colfl0">
          <SearchBoxStandard
            onChange={handleFilter}
            placeholder="address prefix text"
            sx={{ marginRight: 1 }}
            className="text-color-primary text-color-2-border text-color-2-bg"
          />
        </div>
        <div className="colfr0">
          <MyHelpButtons
            onClick={handleDone}
            btnKey={"add"}
            btnLabel={"Done"}
            enState={false}
            btnTooltip={"Close on done"}
          />
          &nbsp; &nbsp;
          <MyTooltipButtons
            onClick={handleCancel}
            btnKey={"cancel"}
            btnLabel={"Cancel"}
            enState={false}
          />
        </div>
        <div className="clear" />
      </div>
      <div className="clear" />

      <div style={{ marginTop: "16px", width: "100%" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead>
              <LocationListHead />
            </TableHead>
            <TableBody className="text-color-primary text text-md">
              {rows &&
                rows.map((row) => (
                  <LocationListRow
                    key={row.id}
                    row={row}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
