import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Checkbox, Link } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FilterAltTwoToneIcon from "@mui/icons-material/FilterAltTwoTone";
import { setEventFetchMask } from "../appointment/redux/actions";
import log from "../../services/log";

export default function InputCheckBoxType(props) {
  const { name, isChecked, onChange, index } = props;

  return (
    <>
      <input
        className="text-color-primary"
        id={name}
        type="checkbox"
        name={name}
        defaultChecked={isChecked}
        onChange={onChange}
        data-action={index}
      />
      <label htmlFor={name} className="text-color">
        &nbsp; {name}
      </label>
    </>
  );
}

export function InputCheckBoxType2(props) {
  const { name, label, isChecked, onChange, index } = props;
  return (
    <>
      <input
        className="text-color-primary"
        id={name}
        type="checkbox"
        name={name}
        defaultChecked={isChecked}
        onChange={onChange}
        data-action={index}
      />
      <label htmlFor={name} className="text-color">
        &nbsp; {label}
      </label>
    </>
  );
}

export function SelectCheck(props) {
  const { title, checkList } = props;

  const eventsmask = useSelector((state) => state.events.mask);

  const [checkedItems, setCheckedItems] = useState(
    eventsmask ? eventsmask : props.checkList
  );

  log.info("select checklist", checkList);
  log.info("selected checkedItems", checkedItems);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    log.info("eventmask", event.target.dataset);
    log.info("eventmask value", event.target.checked);

    const newCheckboxes = [...checkedItems];
    newCheckboxes[event.target.dataset.action].isChecked = event.target.checked;
    setCheckedItems(newCheckboxes);
    dispatch(setEventFetchMask({ mask: newCheckboxes }));
  };

  return (
    <>
      <Box
        width="auto"
        className="text-color-primary bg-gray border-dbb left"
        p={1}
      >
        <FilterAltTwoToneIcon /> {"Filter " + title}
      </Box>

      <Box width="auto" className="colfl">
        <FormControl
          fullWidth
          className="text-color-primary"
          variant="outlined"
        >
          {checkedItems.map((checkbox, index) => (
            <FormGroup
              row
              className="popup-cookie-checkbox-list__item"
              key={checkbox._id}
            >
              <InputCheckBoxType
                id={checkbox._id}
                name={checkbox.title}
                isChecked={checkbox.isChecked}
                onChange={handleChange}
                index={index}
              />
            </FormGroup>
          ))}
        </FormControl>
      </Box>
    </>
  );
}
