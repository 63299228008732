import { Box, Card, Grid, Typography } from "@mui/material";

import heartPulseIcon from "../../assets/images/homepage/HeartPulse.svg";
import { useWindowSize } from "../common/ConstantsAndValues";

export default function AboutUs() {
  const size = useWindowSize();
  return (
    <Box sx={{ background: "#EAF4F7" }} id="sectionTwo">
      <Grid container>
        <Grid item xs={12} px={8} py={4}>
          <Typography
            sx={{ fontSize: "28px", color: "#404040", fontWeight: "600" }}
          >
            About us
          </Typography>
        </Grid>
        <Grid
          container
          mx={{ xs: 3, md: 7 }}
          mb={8}
          // justify="space-around"
          // spacing={8}
          // rowSpacing={1}
          // columnSpacing={1}
          sx={{ justifyContent: "center" }}
        >
          <Grid item xs={12} sm={6} lg={4}>
            <Card
              elevation={0}
              sx={{
                display: "flex",
                px: 3,
                py: 4,
                m: 1,
                minHeight: {
                  xs: "210px",
                  sm: "275px",
                  md: "230px",
                  lg: "220px",
                  xl: "220px",
                },
              }}
            >
              <Box>
                {" "}
                <img
                  src={heartPulseIcon}
                  alt="heartPulseIcon"
                  width={size.width < 500 ? 30 : 35}
                  height={size.width < 500 ? 30 : 35}
                />
              </Box>

              <Box ml={2}>
                {" "}
                <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>
                  Future driven
                </Typography>
                <Typography sx={{ fontSize: "15px" }}>
                  {" "}
                  At Metalane, our team is driven by more than just building
                  great products; we are committed to pioneering a future where
                  technology elevates every facet of our lives.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card
              elevation={0}
              sx={{
                display: "flex",
                px: 3,
                py: 4,
                m: 1,
                minHeight: {
                  xs: "210px",
                  sm: "275px",
                  md: "230px",
                  lg: "220px",
                  xl: "220px",
                },
              }}
            >
              <Box>
                {" "}
                <img
                  src={heartPulseIcon}
                  alt="heartPulseIcon"
                  width={size.width < 500 ? 30 : 35}
                  height={size.width < 500 ? 30 : 35}
                />
              </Box>

              <Box ml={2}>
                {" "}
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "700",
                  }}
                >
                  Innovative
                </Typography>
                <Typography sx={{ fontSize: "15px" }}>
                  We recognize that health and wellness are the cornerstone of a
                  fulfilling and productive life, and this conviction fuels our
                  dedication in creating innovative solutions that uplift the
                  well-being of all.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card
              elevation={0}
              sx={{
                display: "flex",
                px: 3,
                py: 4,
                m: 1,
                minHeight: {
                  xs: "210px",
                  sm: "275px",
                  md: "230px",
                  lg: "220px",
                  xl: "220px",
                },
              }}
            >
              <Box>
                {" "}
                <img
                  src={heartPulseIcon}
                  alt="heartPulseIcon"
                  width={size.width < 500 ? 30 : 35}
                  height={size.width < 500 ? 30 : 35}
                />
              </Box>

              <Box ml={2}>
                {" "}
                <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>
                  Leadership
                </Typography>
                <Typography sx={{ fontSize: "15px" }}>
                  Metalane is led by entrepreneurial leaders who have
                  successfully launched several startups and contributed
                  significantly to Fortune 500 companies like Oracle, Cisco,
                  Intel, Comcast, Sun Micro, and Persistent.
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
