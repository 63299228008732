import * as React from "react";
import { useState } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { MyTooltipButtons } from "../../CustomComponents/MyButtons";
import { MedicineInput } from "../../medicine/MedicineInput";
import {
  setPatientDrugs,
  setDrugPrecriptionList,
} from "../../../redux/drugs/drugs.actions";

import DrugsListTabsMD from "./DrugsListTabsMD";
import { DrugPresListView } from "./DrugPresListView";
import Button from "@mui/material/Button";
import log from "../../../services/log";

export const DrugsListMD = (props) => {
  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );
  const patient = useSelector((state) => state?.user?.selectedPatient);
  const searchtext = useSelector((state) => state?.user?.searchtext);
  const preslist = useSelector((state) => state?.drugs?.preslist);

  const [action, setAction] = useState("");
  const [checkedRows, setCheckedRows] = useState(null);

  const dispatch = useDispatch();
  const handlePrescribe = () => {
    // log.info("Prescribe", checkedRows);
    setAction("prescribe");
  };

  const handlePresList = () => {
    setAction("viewlist");
  };

  const handleCancel = () => {
    setAction("");
  };

  const handleReset = () => {
    // log.info("prescribelist reset");
    dispatch(setDrugPrecriptionList([]));
  };

  const handleRemove = () => {
    log.info("prescribelist remove");
    // dispatch(setDrugPrecriptionList([]));
  };

  const handlePost = () => {
    // log.info("prescribelist post");
    preslist.forEach((d) => {
      dispatch(setPatientDrugs(d));
    });
    // reset list after post
    dispatch(setDrugPrecriptionList([]));
  };

  // log.info("drugslist md", checkedRows);

  return (
    <Grid container>
      <Grid item xs>
        <div className="clear"></div>
        {action === "prescribe" ? (
          <div>
            <MedicineInput
              cancel={handleCancel}
              doctor={user}
              patient={patient}
              medicine={checkedRows}
            />
          </div>
        ) : action === "viewlist" ? (
          <DrugPresListView handleCancel={handleCancel} />
        ) : (
          <div>
            <div className="colfr0">
              <MyTooltipButtons
                onClick={handlePresList}
                btnKey={"viewlist"}
                btnLabel={"View list"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handlePrescribe}
                btnKey={"prescribe"}
                btnLabel={"Prescribe"}
                enState={false}
              />
            </div>

            <DrugsListTabsMD />
          </div>
        )}
      </Grid>
    </Grid>
  );
};
