import { Box, Divider, Grid, Link, Typography } from "@mui/material";
import { ArrowUpBlueIcon } from "../customIcons/ArrowUpBlueIcon";
import * as React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { ChevronRight } from "../customIcons/ChevronRight";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { setSubscribe } from "../../redux/subscribe/subscribe.actions";
import Spinner from "../Loader/index";
import linkedinIcon from "../../assets/images/homepage/LinkedIn.svg";

const FooterLanding = () => {
  const [successValue, setSuccessValue] = React.useState("");
  const dispatch = useDispatch();

  // subsribe validation
  const subscribeValidateSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email")
      .required("Please enter a valid email"),
  });

  // subsribe inital values
  const subscribeValueSchema = () => {
    return {
      email: "",
    };
  };

  // subsribe submit function
  const subscribeNow = async (values, { resetForm }) => {
    var params = {
      email: values.email,
    };
    let response = await dispatch(setSubscribe(params));
    if (response.code === 201) {
      resetForm(subscribeValueSchema);
      setSuccessValue("Thank you for subscribing!");
      setTimeout(() => {
        setSuccessValue("");
      }, 2000);
    }
  };

  // scroll to top
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Box>
      <Box
        sx={{
          p: 3,
          background: "#f5f9fb",
          borderTop: "2px solid #94C4D2",
        }}
      >
        <Grid container>
          <Grid item xs={6} md={4} lg={4}>
            <Typography sx={{ color: "#19445E", fontWeight: "600" }}>
              Metalane
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Box
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/metalaneinc/about/?viewAsMember=true",
                    "_blank"
                  )
                }
                sx={{
                  cursor: "pointer",
                  background: "#C9E1E9",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  padding: "4px 7px",
                  marginTop: "10px",
                  marginRight: "10px",
                }}
              >
                <img
                  src={linkedinIcon}
                  alt="linkedinIcon"
                  width={16}
                  height={16}
                />
              </Box>
              <Link
                sx={{
                  color: "#404040",
                  mt: 2,
                  textDecoration: "none",
                  fontSize: "16px",
                  // top: "5px",
                  position: "relative",
                }}
                href="https://www.linkedin.com/company/metalaneinc/about/?viewAsMember=true"
                target="_blank"
              >
                Follow us on LinkedIn
              </Link>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            lg={4}
            sx={{
              display: "flex",
              justifyContent: { xs: "left", md: "center" },
            }}
          >
            <Box sx={{ mt: { xs: 0, md: 0 } }}>
              <Typography sx={{ color: "#404040", fontWeight: "600" }}>
                Support
              </Typography>
              <Typography sx={{ color: "#404040", mt: 2 }}>
                Have a question?
              </Typography>
              <Typography sx={{ color: "#404040" }}>
                Send us an email at contact@metalane.ai
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              sx={{ color: "#404040", fontWeight: "600", mt: { xs: 2, md: 0 } }}
            >
              We are almost ready to launch!
            </Typography>
            <Formik
              initialValues={subscribeValueSchema()}
              validationSchema={subscribeValidateSchema}
              onSubmit={subscribeNow}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Box className="flex">
                    <Box>
                      <Field
                        name="email"
                        className="subscribe_input border_subscribe"
                        placeholder="Enter your email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-msg-blue pl-1"
                      />
                      {successValue !== "" && (
                        <Box className="error-msg-white pl-1">
                          {successValue}
                        </Box>
                      )}
                    </Box>
                    <button
                      className="subscribe_button"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {!isSubmitting ? (
                        <>
                          Subscribe
                          <ChevronRight size={0.8} />
                        </>
                      ) : (
                        <Spinner />
                      )}
                    </button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: "#19445E",
          p: 2,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={10}
            md={5}
            lg={5}
            sx={
              {
                // order: { xs: 1, md: 0 },
              }
            }
          >
            <Typography
              sx={{
                color: "#C7C7C7",
                fontSize: { xs: "12px", md: "16px" },
                // textAlign: { xs: "center", md: "left" },
              }}
            >
              Copyright © 2023 Metalane.ai. All rights reserved.
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            md={5}
            lg={5}
            sx={{
              display: "flex",
              justifyContent: { xs: "start", lg: "start" },
            }}
          >
            {" "}
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="subtitle1"
                className="text-white"
                sx={{
                  fontFamily: "DM SANS",
                  cursor: "pointer",
                }}
              >
                <Link
                  href="/privacypolicy"
                  sx={{
                    textDecoration: "none !important",
                    color: "white !important",
                  }}
                >
                  Privacy policy
                </Link>
              </Typography>
              <Divider
                orientation="vertical"
                sx={{
                  height: "24px",
                  marginTop: "2px",
                  background: "white",
                  mx: 1,
                }}
                flexItem
              />
              <Typography
                variant="subtitle1"
                className="text-white"
                sx={{ fontFamily: "DM SANS", cursor: "pointer" }}
              >
                <Link
                  href="/terms"
                  sx={{
                    textDecoration: "none !important",
                    color: "white !important",
                  }}
                >
                  {" "}
                  Website terms of use
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            lg={2}
            sx={{
              display: "flex",
              justifyContent: "end",
              order: { xs: 1, md: 0 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                // justifyContent: "end",
                cursor: "pointer",
                width: "fit-content",
                // marginLeft: "auto",
                svg: {
                  fill: "#94C4D2",
                },
                "&:hover": {
                  svg: { fill: "#FF6D0D" },
                },
              }}
              onClick={scrollToTop}
            >
              <Typography
                variant="subtitle1"
                align="right"
                sx={{
                  display: { xs: "none", lg: "block" },
                  color: "#94C4D2",
                  fontWeight: 600,
                  fontFamily: "DM SANS",
                }}
              >
                Back to top
              </Typography>
              <Box>
                <ArrowUpBlueIcon size={0.8} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default FooterLanding;
