////////////////////////////////////////////////////
//                 common to all                  //
////////////////////////////////////////////////////
import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Button,
  IconButton,
  Fade,
  Tab,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  InputBase,
  TextField,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import AdjustTwoToneIcon from "@mui/icons-material/AdjustTwoTone";
import AssistantPhotoTwoToneIcon from "@mui/icons-material/AssistantPhotoTwoTone";
import AddAlertTwoToneIcon from "@mui/icons-material/AddAlertTwoTone";
import AlternateEmailTwoToneIcon from "@mui/icons-material/AlternateEmailTwoTone";
import AttachMoneyTwoToneIcon from "@mui/icons-material/AttachMoneyTwoTone";
import AssignmentIndTwoToneIcon from "@mui/icons-material/AssignmentIndTwoTone";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";

import BalanceTwoToneIcon from "@mui/icons-material/BalanceTwoTone";
import BookOnlineTwoToneIcon from "@mui/icons-material/BookOnlineTwoTone";
import BookTwoToneIcon from "@mui/icons-material/BookTwoTone";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import MedicalServicesTwoToneIcon from "@mui/icons-material/MedicalServicesTwoTone";

import CastForEducationTwoToneIcon from "@mui/icons-material/CastForEducationTwoTone";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import CoPresentTwoToneIcon from "@mui/icons-material/CoPresentTwoTone";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DashboardCustomizeTwoToneIcon from "@mui/icons-material/DashboardCustomizeTwoTone";

import FollowTheSignsTwoToneIcon from "@mui/icons-material/FollowTheSignsTwoTone";
import FilterDramaTwoToneIcon from "@mui/icons-material/FilterDramaTwoTone";
import FoundationTwoToneIcon from "@mui/icons-material/FoundationTwoTone";

import GavelTwoToneIcon from "@mui/icons-material/GavelTwoTone";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import GroupTwoToneIcon from "@mui/icons-material/GroupTwoTone";

import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import HealthAndSafetyTwoToneIcon from "@mui/icons-material/HealthAndSafetyTwoTone";

import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import InputTwoToneIcon from "@mui/icons-material/InputTwoTone";
import InventoryTwoToneIcon from "@mui/icons-material/InventoryTwoTone";
import ImageSearchTwoToneIcon from "@mui/icons-material/ImageSearchTwoTone";

import LocalPharmacyTwoToneIcon from "@mui/icons-material/LocalPharmacyTwoTone";
import LocalLibraryTwoToneIcon from "@mui/icons-material/LocalLibraryTwoTone";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";
import LocalHospitalTwoToneIcon from "@mui/icons-material/LocalHospitalTwoTone";

import MedicationTwoToneIcon from "@mui/icons-material/MedicationTwoTone";
import MonitorHeartTwoToneIcon from "@mui/icons-material/MonitorHeartTwoTone";
import MonitorWeightTwoToneIcon from "@mui/icons-material/MonitorWeightTwoTone";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import MedicationLiquidTwoToneIcon from "@mui/icons-material/MedicationLiquidTwoTone";

import NoteAddTwoToneIcon from "@mui/icons-material/NoteAddTwoTone";

import PaymentTwoToneIcon from "@mui/icons-material/PaymentTwoTone";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import PersonAddAltTwoToneIcon from "@mui/icons-material/PersonAddAltTwoTone";
import PersonTwoToneIcon from "@mui/icons-material/PersonTwoTone";
import PersonalVideoTwoToneIcon from "@mui/icons-material/PersonalVideoTwoTone";
import PersonSearchTwoToneIcon from "@mui/icons-material/PersonSearchTwoTone";

import QuizTwoToneIcon from "@mui/icons-material/QuizTwoTone";

import RecentActorsTwoToneIcon from "@mui/icons-material/RecentActorsTwoTone";

import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import SearchIcon from "@mui/icons-material/Search";

import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import TimelineTwoToneIcon from "@mui/icons-material/TimelineTwoTone";
import TaskTwoToneIcon from "@mui/icons-material/TaskTwoTone";

import I1 from "../../assets/images/i1.png";
import I2 from "../../assets/images/i2.svg";
import I3 from "../../assets/images/dna.svg";
import I4 from "../../assets/images/contact.svg";
import Dna from "../../assets/images/dna.svg";
// import Contact from "../../assets/contact.svg";

import { SelectCombo } from "./SelectCombo";

import { useMetaTheme } from "../theme/ThemeContext";
import { ThemeProvider } from "@mui/material/styles";
import { DashIcon } from "../customIcons/DashIcon";
import { AlertIcon } from "../customIcons/AlertIcon";
import { PersonIcon } from "../customIcons/PersonIcon";
import { HomeArrowIcon } from "../customIcons/HomeArrowIcon";
import { MetaSearchIcon } from "../customIcons/MetaSearchIcon";
import { BurgerIcon } from "../customIcons/BurgerIcon";
import { HomeArrowIconDashboard } from "../customIcons/HomeArrowIconDashboard";
import { NotificationBellIcon } from "../customIcons/NotificationBellIcon";

//const { theme }  = useMetaTheme();

export const metaID = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

//---------------------------CONSTANTS ----------------------------//
const mw = 330;
const mxw = 360;
export const colWidthRBarMD = { width: mw, extrawide: mxw };
export const colWidthRBarUSR = { width: mw, extrawide: mxw };
export const colWidthRBarORG = { width: mw, extrawide: mxw };
export const colWidthRBarLAB = { width: mw, extrawide: mxw };
export const colWidthRBarPHA = { width: mw, extrawide: mxw };
export const colWidthRBarFIN = { width: mw, extrawide: mxw };
export const colWidthRBarOS = { width: mw, extrawide: mxw };
export const colWidthRBarCON = { width: mw, extrawide: mxw };
export const colWidthRBarMSC = { width: mw, extrawide: mxw };

export const colWidth3 = { width: { xs: 240, md: 350 } };
export const colWidth4 = { width: 260 };
export const minWidthSize = { width: 380 };
export const minWidthMDCR = { width: 400 };

export const signContainer = { width: "380px" };
export const defDataPeriod = {
  minYear: 4,
  minMonth: 0,
  maxYear: 5,
  maxMonth: 0,
};
export const colWidthSign = { width: { xs: 400, md: 500 } }; //abandoned
export const colWidthCard = { width: 300 };
export const colChart = { width: 500, height: 400, groupHeight: 600 };
export const colWideChart = { width: 900, height: 500 };
export const colHeightChart = { height: { xs: 400, md: 400 } };
export const colSpeedoChart = {
  height: 300,
  width: 300,
  defaultValue: 0,
  maxValue: 100,
};
export const colWidthAccordion = {
  width: { xs: 200, sm: 300, md: 600, lg: 900 },
  colm1: 100,
  colm2: 150,
  colm3: 200,
};

export const tableParam = {
  minHeight: 440,
  defLinesPerPage: 10,
  minWidth: 200,
  width: 300,
  hoverWidth: 200,
  tableRowsCount: 15,
};
export const CnParam = { minHeight: 300, maxHeight: 600 };

export const HeadTitle = window.location.pathname
  .split("/")
  .join(" ")
  .toLowerCase()
  .split(" ")
  .map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  })
  .join(" ");

export const HeadUrl = window.location.pathname;

export const commonLinkData = [
  {
    _id: "comreg",
    label: "My Region Settings",
    icon: <LanguageTwoToneIcon />,
    link: "/region",
    more: true,
  },
];

export const CauseRSM =
  "analytics cal notifications messages conditions info monitor privacy settings resultXs chartXsX new";
export const MD_Patn_Disp =
  "clinical-notes reports rt medical-images medicine medications health-monitor preventive-care questionnaire resources payment insurance";
//export const WideRSM           = "setup " ;
export const WideRSM = " ";

export const TriggerRSM = 800;
export const TriggerCAL = { a: 600, b: 900 };
//export const btnTextCALshort   = { today: 'T', month: 'M', week: 'W', day: 'D', list: 'L'};
//export const btnTextCALdefault = { today: 'today', month: 'month', week: 'week', day: 'day', list: 'list'};

export const calInstruction = {
  default: "",
  admAppt: "",
  admTodo: "",
  finAppt: "",
  finTodo: "",
  labAppt: "",
  labTodo: "",
  mdAppt: "",
  mdTodo: "",
  orgAppt: "",
  orgTodo: "",
  osAppt: "",
  osTodo: "",
  phaAppt: "",
  phaTodo: "",
  usrAppt: "",
  usrTodo: "",
};

export const InstructionCN =
  "Clinical Note will appear here once the patient visits a doctor." + "\n";

export const picPath = { path: "/assets/images/sysusers/", ext: ".jpg" };

export const PatientListPath = "/metamd/patients/list";
export const PatientListPathS = "/metamd/monitor/conditions";

export const myDateFormat = "yyyy-MM-dd";
export const calDateTimeFormat = "yyyy-MM-dd:HH:mm";
export const pageOptions = [10, 25];
export const paymentAgingDays = [30, 45, 90];
export const CurrencyUser = "INR";
export const CurrencyPolicy = "INR";
export const IFSC_url = "https://ifsc.razorpay.com/";

//---------------------------CONTAINERS ----------------------------//
//
export const Gen400Container = styled.div`
  width: 380px;
  min-height: 85vh;
  margin: 0px auto;
  padding: 7rem 0;
`;

export const PageContent = styled("main")({
  width: "100%",
  //height: 'auto',
  padding: "0.1rem",
  minHeight: "calc(100vh - 100px)",
  overflowY: "auto", //'scroll',
  /*border:  '1px solid green',*/
});

export const ContentStyle = styled("div")({
  padding: "20px 10px 20px 20px",
  marginX: "1px",
  //border:  '1px solid orange',
  //background: 'red',
});

export const SubContentStyle = styled("div")({
  padding: "0px",
  marginX: "1px",
  //width:   'auto',
  overflow: "hidden",
  //border:  '1px solid black',
});

export const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      height: "30px",
    },
  })
)(TableRow);

export const StyledTableRowHead = withStyles((theme) =>
  createStyles({
    root: {
      height: "35px",
    },
  })
)(TableRow);

export const StyledTableCellHead = withStyles((theme) =>
  createStyles({
    root: {
      color: "gray",
      padding: "1px 5px 1px 5px",
      fontWeight: 600,
    },
  })
)(TableCell);

export const StyledTableCell = withStyles((theme) =>
  createStyles({
    root: {
      color: "var(--bg-color)",
      padding: "1px 5px 1px 5px",
    },
  })
)(TableCell);

export const GraphMenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`;

export const ReportContents = styled("div")({
  padding: 20,
  width: "100%",
});

export const CalendarContainer = styled.div`
  .react-calendar {
    width: 320px;
    max-width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    border: 0;
    //background: white;
  }
  button {
    margin: 0;
    border: 0;
    outline: none;
    //color: var(--bg-color);
    &:hover {
      cursor: pointer;
      //background-color: #ff8800;
    }
    &:active {
      //background-color: #8888ff;
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    color: var(--bg-color);
  }
  .react-calendar__tile--range {
    background-color: var(--bg-color);
  }
  .button.react-calendar__tile:disabled {
    background-color: gray;
  }
  .react-calendar__navigation {
    display: flex;
    color: var(--bg-color);
    .react-calendar__navigation__label {
      font-weight: bold;
      color: var(--bg-color);
    }
    .react-calendar__navigation__arrow {
      flex-grow: 0.333;
      color: var(--bg-color);
    }
  }
`;

export const themeTABS = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundcolor: "#ff8800",
          height: 3,
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#2a8aa7",
    },
    secondary: {
      main: "#FF8800",
    },
  },
});
export const StyledTab = styled(Tab)({
  "& .Mui-selected": {
    color: "#ff8800",
  },
});

//------------------------             MISC             ----------------------------//

export const SpanLink = styled("span")({
  marginLeft: "5px",
  marginRight: "5px",
});

export const SpanAnchor = styled("a")({
  fontSize: "18px",
  textAlign: "right",
});

//------------------------        SEARCH BAR             ----------------------------//

export const SearchBoxAdminBarXXX = styled("input")({
  //abandoned
  type: "search",
  boxShadow: "none",
  height: "24px",
  width: "100%",
  outline: "none",
  fontSize: "14px",
  borderRadius: "10px",
  padding: "3px 25px 5px 10px ",
  marginLeft: "15px",
  marginRight: "-15px",
});

export const SearchBoxXNaviBar = styled("input")({
  type: "search",
  boxShadow: "none",
  height: "40px",
  width: "200px",
  outline: "none",
  fontSize: "16px",
  borderRadius: "20px",
  padding: "0px 20px 1px 20px",
  marginLeft: "15px",
  marginRight: "5px",
  marginTop: "6px",
});

export const SearchBoxStandard = styled("input")({
  type: "search",
  boxShadow: "none",
  height: "24px",
  width: "100%",
  outline: "none",
  fontSize: "14px",
  borderRadius: "10px",
  padding: "3px 5px 5px 5px ",
  marginLeft: "0px",
  marginRight: "5px",
  letterSpacing: "1px",
});

export const SearchBoxStandardBig = styled("input")({
  type: "search",
  boxShadow: "none",
  height: "35px",
  width: "100%",
  outline: "none",
  fontSize: "16px",
  borderRadius: "10px",
  padding: "3px 5px 5px 5px",
  letterSpacing: "1px",
});

export const commonSearchHospitalOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "General",
    value: { departmentId: "metainp", name: "" },
  },
  {
    _id: 552,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "ICU",
    value: { departmentId: "metaicu", name: "" },
  },
  {
    _id: 553,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Operating",
    value: { departmentId: "metaopt", name: "" },
  },
  {
    _id: 554,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Pediatrics",
    value: { departmentId: "metaped", name: "" },
  },
  {
    _id: 555,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Gynecology",
    value: { departmentId: "metagyn", name: "" },
  },
  {
    _id: 556,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "General Surgery",
    value: { departmentId: " metagsd", name: "" },
  },
  {
    _id: 557,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Neurology",
    value: { departmentId: " metaneur", name: "" },
  },
  {
    _id: 558,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Cardiology",
    value: { departmentId: " metacvd", name: "" },
  },
];

export const commonSearchLabsOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Clinical labs",
    value: { departmentId: "metacpl", name: "" },
  },
  {
    _id: 552,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Radiology labs",
    value: { departmentId: "metarad", name: "" },
  },
];

export const commonSearchPharmacyOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <LocalPharmacyTwoToneIcon fontSize="small" />,
    title: "Pharmacy",
    value: { departmentId: "metaphar", name: "" },
  },
];

export const commonSearchSpecialistOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "All",
    value: null,
  },
  {
    _id: 552,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Surgeon",
    value: { departmentId: "metagsd", name: "" },
  },
  {
    _id: 553,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Neurologist",
    value: { departmentId: "metaneur", name: "" },
  },
  {
    _id: 554,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Cardiologist",
    value: { departmentId: "metacvd", name: "" },
  },
  {
    _id: 555,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Gynecologist",
    value: { departmentId: "metagyn", name: "" },
  },
  {
    _id: 556,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Pediatrist",
    value: { departmentId: "metaped", name: "" },
  },
  {
    _id: 557,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Haematologist",
    value: { departmentId: "metahem", name: "" },
  },
  {
    _id: 558,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Urologist",
    value: { departmentId: "metauro", name: "" },
  },
  {
    _id: 559,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "ENT",
    value: { departmentId: "metaent", name: "" },
  },
];

export const commonSearchClinicOptions = [
  {
    _id: 551,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 550,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "All",
    value: null,
  },
  {
    _id: 551,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Clinics",
    value: { departmentId: "metaopd", name: "" },
  },
];

export const ctlabMenuOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "lab name",
    value: { key: "name" },
  },
  {
    _id: 552,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "panel name",
    value: { key: "parentName" },
  },
];

export const rtlabMenuOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "name",
    value: { key: "common_name" },
  },
];

export const patientSearchMenuOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <AlternateEmailTwoToneIcon fontSize="small" />,
    title: "Email",
    value: { key: "email", role: "metauser", email: "" },
  },
  {
    _id: 552,
    icon: <GroupTwoToneIcon fontSize="small" />,
    title: "My association",
    value: { key: "doctorId", role: "metamd" },
  },
  {
    _id: 553,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Hospital association",
    value: { key: "orgId", role: "metaorg" },
  },
  {
    _id: 553,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Department association",
    value: { key: "departmentId", role: "metaorg" },
  },
];

export const mdProfileSearchMenuOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Organization name",
    value: { name: "" },
  },
];

export const reportsMDSearchMenuOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Lab name",
    value: { key: "name", value: "" },
  },
  {
    _id: 552,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Panel name",
    value: { key: "parentName", value: "" },
  },
];

export const drugSearchMenuOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <MedicationLiquidTwoToneIcon fontSize="small" />,
    title: "Medicine name",
    value: { key: "name" },
  },
  {
    _id: 552,
    icon: <MedicationLiquidTwoToneIcon fontSize="small" />,
    title: "Medicine salt",
    value: { key: "salt" },
  },
  {
    _id: 553,
    icon: <MedicationLiquidTwoToneIcon fontSize="small" />,
    title: "OTC class",
    value: { key: "category" },
  },
];

export const orgPatientSearchMenuOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <AlternateEmailTwoToneIcon fontSize="small" />,
    title: "email",
    value: { key: "email", role: "metaorg" },
  },
  {
    _id: 552,
    icon: <GroupTwoToneIcon fontSize="small" />,
    title: "organization",
    value: { key: "orgId", role: "metaorg" },
  },
  {
    _id: 553,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "department",
    value: { type: "departmentd", role: "metaorg" },
  },
];

export const mimageMDSearchMenuOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Lab name",
    value: { key: "common_name", value: "" },
  },
];

export const labPatientSearchMenuOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <AlternateEmailTwoToneIcon fontSize="small" />,
    title: "Email",
    value: { key: "email", value: "" },
  },
];

export const rtmdSearchMenuOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <LocalHospitalTwoToneIcon fontSize="small" />,
    title: "Radiology lab name",
    value: { key: "common_name", value: "" },
  },
];

export const orgAccountSearchMenuOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
  {
    _id: 551,
    icon: <PersonOutlineTwoToneIcon fontSize="small" />,
    title: "selected patient",
    value: { key: "patient", value: "" },
  },
];

export const commonSearchMenuOptions = [
  {
    _id: 550,
    icon: <></>,
    title: "Search options",
    value: null,
  },
];

//------------------------------ LISTS OF COLORS -------------------------------//

export const palateRed = ["#FFFCFC", "#FFFFF0", "#FFFDEA"];
export const palateGreen = [
  "#F8FFE2",
  "#F0FFD2",
  "#E6FFD0",
  "#CAFFCA",
  "#E6FFF0",
];
export const palateBlue = [
  "#FAFAFF",
  "#F5F5FF",
  "#E0F8FF",
  "#E1F2FF",
  "#E0E0FF",
];
//export const speedoColors       = [ "#79dd1b", "#93f322", "#cef830", "#fbf0a4", "#f8e965", "#f5b859", "#f1960c", "#f95f48", "#f54230", "#ee290d", ];
//export const speedoColorsDark   = [ "#2ea80b", "#3ce30e", "#82f013", "#d6d440", "#e3ae0e", "#e36e0e", "#ec200f", "#d90f04", "#b00e05", "#800f05", ];
export const speedoG = [
  "#E4FC85",
  "#C9F80E",
  "#93F322",
  "#79DD1B",
  "#6CC918",
  "#43BD1A",
];
export const speedoY = [
  "#FDF7CC",
  "#FBF0A4",
  "#FAEA7A",
  "#F9E34F",
  "#F8DC18",
  "#FBC053",
  "#FAB029",
  "#F8A305",
  "#CA854",
];
export const speedoR = [
  "#FDBDA8",
  "#FCA487",
  "#F9876B",
  "#F37347",
  "#F7604F",
  "#F13829",
  "#D90F04",
  "#B00E05",
];
export const eventColorBG = {
  metamd: "#FDBDA8",
  metalabs: "#85E4FC",
  metaplus: "#E4FC85",
  todo: "#FDF7CC",
  mask: "#E3D3D3",
  full: "#D3D3D3",
};

//THIS
export const StyledNHMenuItem = styled(MenuItem)(({ theme }) => ({
  "&&&": {
    padding: "0 !important",
    //color:          theme.palette.secondary.main,
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      outline: "none",
      backgroundColor: "transparent",
      //color:           theme.palette.primary.main,
    },
  },
}));
export const StyledNHMenuItemNav = styled(MenuItem)(({ theme }) => ({
  "&&&": {
    padding: "8px !important",
    //color:          theme.palette.secondary.main,
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      outline: "none",
      backgroundColor: "transparent",
      //color:           theme.palette.primary.main,
    },
  },
}));

export const StyledDDMainMenuContainer = styled(Menu)(({ theme }) => ({
  "&&&": {
    position: "absolute",
    top: "40px",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const StyledDDMainMenuItemHead = styled(MenuItem)(({ theme }) => ({
  "&&&": {
    color: theme.palette.secondary.main,
    padding: "4px 4px 4px 32px",
    display: "flex",
    justifyContent: "space-between",
    //pointerEvents:  "none",
    cursor: "default",
    "&:hover": {
      outline: "none",
      backgroundColor: "transparent",
      //color:           theme.palette.primary.main,
    },
  },
}));

export const StyledButton = styled(Button)({
  "&&&": {
    margin: "0px",
    padding: "6px 8px 6px 16px",
    width: "100%",
    justifyContent: "flex-start",
    fontFamily: "DM SANS",
    textTransform: "capitalize",
    fontSize: "15px",
    borderBottom: "1px solid #C9E1E9",
    width: "300px",
    span: {
      svg: { fill: "#404040" },
    },
    "&:hover": {
      color: "#ff7700",
      span: {
        svg: {
          fill: "#ff7700",
        },
      },
    },
  },
});

export const StyledPillButton = styled(Button)(({ theme, pill }) => ({
  "&&&": {
    padding: "4px 20px",
    border: "none",
    fontSize: theme?.components.MetaButtonPrimay.sizeLarge.fontSize,

    [theme.breakpoints.down("md")]: {
      fontSize: theme?.components.MetaButtonPrimay.sizeSmall.fontSize,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: theme?.components.MetaButtonPrimay.sizeLarge.fontSize,
    },

    borderRadius: pill ? "30px" : "4px",
    color: theme?.palette?.gray?.contrastText,
    backgroundColor: theme?.palette?.primary?.main,

    "&:hover": {
      //outline:         "none",
      //backgroundColor: theme?.palette?.primary?.dark,
      border: "1px solid",
      borderColor: theme?.palette?.gray?.dark,
      color: theme?.palette?.gray?.light,
    },
    "&:focus": {
      //outline:         "none",
      //backgroundColor: theme?.palette?.primary?.main,
      border: "1px solid",
      borderColor: theme?.palette?.gray?.dark,
    },
    "&.Mui-selected": {
      backgroundColor: theme?.palette?.primary?.dark,
      "&:hover": {
        backgroundColor: theme?.palette?.primary?.dark,
      },
    },
  },
}));

//THIS
export const StyledMainMenuButton = styled(Button)(
  ({ theme, activestate }) => ({
    "&&&": {
      fontFamily: '"DM Sans"',
      lineHeight: "1.0",
      fontWeight: "400",
      height: "35px",
      //lineHeight: '31.25px',
      padding: "16px",
      border: "none",
      letterSpacing: "1.61px",
      fontSize: "24px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "20px",
      },
      color: theme?.palette?.gray?.dark,
      //color:        activestate ? theme?.palette?.primary?.main: theme?.palette?.secondary?.main,
      "&:hover": {
        outline: "none",
        backgroundColor: "transparent",
        //backgroundColor: theme?.palette?.secondary?.light,
        // color:           theme?.palette?.primary?.main,
      },
      "&:focus": {
        outline: "none",
        //backgroundColor: theme?.palette?.primary?.main,
      },
    },
  })
);

export const StyledDDMainMenuButton = styled(Button)(
  ({ theme, activestate }) => ({
    "&&&": {
      height: "25px",
      padding: "16px",
      border: "none",
      justifyContent: "flex-start",
      width: "200px",
      color: activestate
        ? theme?.palette?.primary?.main
        : theme?.palette?.secondary?.main,
      "&:hover": {
        outline: "none",
        backgroundColor: theme?.palette?.secondary?.light,
        color: theme?.palette?.primary?.main,
      },
      "&:focus": {
        outline: "none",
        backgroundColor: theme?.palette?.primary?.main,
      },
    },
  })
);

export const StyledDDMainHeadButton = styled(IconButton)(({ theme }) => ({
  "&&&": {
    height: "35px",
    padding: "4px 16px",
    border: "none",
    justifyContent: "flex-start",
    color: theme?.palette?.secondary?.main,
    "&:hover": {
      outline: "none",
      backgroundColor: theme?.palette?.secondary?.light,
      color: theme?.palette?.primary?.main,
    },
    "&:focus": {
      outline: "none",
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
}));

export const StyledButtonColor = styled(Button)({
  "&&&": {
    color: "#F8F8F8",
    background: "#2A8AA7",
    "&:hover": {
      color: "#FFFFFF",
      background: "#FF7700",
    },
  },
});

export const StyledLink = styled(Link)({
  "&&&": {
    color: "#2A8AA7",
    "&:hover": { color: "#ff7700" },
  },
});

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "&&&": {
    fontFamily: '"DM Sans"',
    backgroundColor: theme?.palette?.gray?.white,
    width: "50%",
    height: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 30,
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: 30,
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: 35,
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      height: 40,
    },

    "&::placeholder": {
      color: "green",
    },

    "& .MuiInputBase-root": {
      height: "100%",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme?.palette?.gray?.dark,
    },
  },
}));

//------------------------------ LISTS  Process ------------------------------------//

//------------------------------ LISTS  Dic ------------------------------------//

export const MetaSearchOptions = [
  { _id: 550, title: "Name", icon: <HomeTwoToneIcon />, href: "/name" },
  {
    _id: 551,
    title: "Description",
    icon: <PersonOutlineTwoToneIcon />,
    href: "/desc",
  },
];

export const MetaUnAuthUrl = ["/", "/metacon"];

export const MetaUserUrl = {
  metauser: "/metauser",
  metaorg: "/metaorg",
  metamd: "/metamd",
  metalab: "/metalab",
  metapharma: "/metapharma",
  metafin: "/metafin",
  metasrv: "/metaos",
};

export const InnerNavigation = {
  search: {
    _id: 10030,
    title: "Search",
    label: null,
    icon: <MetaSearchIcon size={0.7} />,
    href: "/",
    isOpen: false,
  },
  home: {
    _id: 10029,
    title: "Home",
    label: null,
    icon: <HomeArrowIcon size={0.7} />,
    href: "/",
    isOpen: false,
  },
  notification: {
    _id: 10028,
    title: "Notification",
    label: null,
    icon: <NotificationBellIcon size={0.7} />,
    href: "/",
    isOpen: false,
  },
};
export const MainbarNavigation = {
  login: {
    _id: 10010,
    title: "",
    label: "Login/Register",
    icon: <PersonIcon size={0.7} />,
    href: "/",
    isOpen: false,
  },
  language: {
    _id: 10020,
    title: "Language",
    label: "EN",
    icon: null,
    href: "/",
    isOpen: true,
  },
  search: {
    _id: 10030,
    title: "Search",
    label: null,
    icon: <MetaSearchIcon size={0.7} />,
    href: "/",
    isOpen: false,
  },
  menu: {
    _id: 10040,
    title: "",
    label: null,
    icon: <BurgerIcon size={0.7} />,
    href: "/",
    isOpen: false,
  },
};

export const MainbarPatientNavigation = {
  user: {
    _id: 10110,
    title: "",
    label: "",
    icon: <PersonIcon size={0.7} />,
    href: "/",
    isOpen: false,
  },
  alerts: {
    _id: 10120,
    title: "Alerts",
    label: null,
    icon: <AlertIcon size={0.7} />,
    href: "/",
    isOpen: false,
  },
  search: {
    _id: 10130,
    title: "Search",
    label: null,
    icon: <MetaSearchIcon size={0.7} />,
    href: "/",
    isOpen: false,
  },
  home: {
    _id: 10140,
    title: "Home",
    label: null,
    icon: <HomeArrowIcon size={0.7} />,
    href: "/metauser",
    isOpen: false,
  },
};

export const MetaPages = [
  {
    _id: 10030,
    title: "Home",
    icon: <HomeArrowIcon size={0.4} />,
    href: "/",
    more: false,
  },
  {
    _id: 10020,
    title: "Search",
    icon: <MetaSearchIcon size={0.4} />,
    href: "/",
    more: false,
  },
  {
    _id: 10010,
    title: "Alerts",
    icon: <AlertIcon size={0.4} />,
    href: "/",
    more: false,
  },
  {
    _id: 10000,
    title: "User",
    icon: <PersonIcon size={0.4} />,
    href: "/",
    more: false,
  },
  //{ _id:10010, title: "HOME",      icon: <HomeTwoToneIcon/>,                href: "/",             more:false,},
  //{ _id:10011, title: "DASHBOARD", icon: <DashboardCustomizeTwoToneIcon />, href: "/dashboard", more:false,},
  // { _id:10012, title: "CONNECT",   icon: <PeopleAltTwoToneIcon />,          href: "/metacon",   more:false,},
];

export const menuListMetaAdmin = [
  {
    _id: 1000,
    title: "Appointments",
    icon: <PersonTwoToneIcon />,
    link: "/metasys/appointments",
  },
  {
    _id: 1001,
    title: "PATIENT",
    icon: <PersonOutlineTwoToneIcon />,
    link: "/metauser",
  },
  {
    _id: 1002,
    title: "ORGANIZATION",
    icon: <AccountTreeTwoToneIcon />,
    link: "/metaorg",
  },
  {
    _id: 1003,
    title: "DOCTOR",
    icon: <MedicationTwoToneIcon />,
    link: "/metamd",
  },
  {
    _id: 1004,
    title: "LABORATORY",
    icon: <MedicalServicesTwoToneIcon />,
    link: "/metalab",
  },
  {
    _id: 1005,
    title: "PHARMACY",
    icon: <LocalPharmacyTwoToneIcon />,
    link: "/metapharma",
  },
  {
    _id: 1006,
    title: "FINANCE",
    icon: <PaymentTwoToneIcon />,
    link: "/metafin",
  },
  {
    _id: 1007,
    title: "OTHER SERVICES",
    icon: <MedicationTwoToneIcon />,
    link: "/metasrv",
  },
  {
    _id: 1100,
    title: "Settings",
    icon: <SettingsTwoToneIcon />,
    link: "/metasys/setup",
  },
];
export const menuListMetaAdmin_RS = [
  {
    _id: 1001,
    title: "My Profile",
    icon: <PersonTwoToneIcon />,
    link: "/metauser/profile",
  },
  {
    _id: 1011,
    title: "To do",
    icon: <TaskTwoToneIcon />,
    link: "/metauser/todo",
  },
];

// export const menuListMetauser = [
//   {
//     _id: 2000,
//     title: "My Profile",
//     icon: <PersonTwoToneIcon />,
//     link: "/metauser/profile/list",
//     active: true,
//   },
//   {
//     _id: 2010,
//     title: "To-do",
//     icon: <TaskTwoToneIcon />,
//     link: "/metauser/todo/cal",
//     active: true,
//   },
//   {
//     _id: 2020,
//     title: "Appointments",
//     icon: <BookOnlineTwoToneIcon />,
//     link: "/metauser/appointments/cal",
//     active: true,
//   },
//   {
//     _id: 2030,
//     title: "Clinical Notes",
//     icon: <NoteAddTwoToneIcon />,
//     link: "/metauser/notes/notes",
//     active: true,
//   },
//   {
//     _id: 2040,
//     title: "Laboratory",
//     icon: <SummarizeTwoToneIcon />,
//     link: "/metauser/reports/reports",
//     active: true,
//   },
//   {
//     _id: 2050,
//     title: "Radiology",
//     icon: <SummarizeTwoToneIcon />,
//     link: "/metauser/radreports/requests",
//     active: true,
//   },
//   // { _id: 2060,        title: 'Medical Images',    icon: <ImageSearchTwoToneIcon />,   link: '/metauser/mimages/dicom/files', active: true, },
//   {
//     _id: 2070,
//     title: "medicines",
//     icon: <MedicationTwoToneIcon />,
//     link: "/metauser/medicine/list",
//     active: true,
//   },
//   {
//     _id: 2080,
//     title: "Health Monitor",
//     icon: <MonitorHeartTwoToneIcon />,
//     link: "/metauser/monitor/info",
//     active: true,
//   },
//   {
//     _id: 2090,
//     title: "Preventive Care",
//     icon: <MonitorWeightTwoToneIcon />,
//     link: "/metauser/care/list",
//     active: true,
//   },
//   {
//     _id: 2100,
//     title: "questionnaire",
//     icon: <QuizTwoToneIcon />,
//     link: "/metauser/qns/list",
//     active: true,
//   },
//   {
//     _id: 2110,
//     title: "Resources",
//     icon: <LocalLibraryTwoToneIcon />,
//     link: "/metauser/resources/list",
//     active: true,
//   },
//   {
//     _id: 2120,
//     title: "Payments",
//     icon: <PaymentTwoToneIcon />,
//     link: "/metauser/payments/list",
//     active: true,
//   },
//   {
//     _id: 2130,
//     title: "Health Insurance",
//     icon: <PaymentTwoToneIcon />,
//     link: "/metauser/insurance/info",
//     active: true,
//   },
//   {
//     _id: 2140,
//     title: "Settings",
//     icon: <SettingsTwoToneIcon />,
//     link: "/metauser/setup/settings",
//     active: false,
//   },
// ];
export const menuListMetauser_RS = [
  {
    _id: 2001,
    title: "My Profile",
    icon: <PersonTwoToneIcon />,
    link: "/metauser/profile",
  },
  {
    _id: 2011,
    title: "To do",
    icon: <TaskTwoToneIcon />,
    link: "/metauser/todo",
  },
];

export const menuListMetaMD = [
  {
    _id: 3000,
    title: "My Profile",
    icon: <PersonTwoToneIcon />,
    link: "/metamd/profile/list",
    active: true,
  },
  {
    _id: 3010,
    title: "To-do",
    icon: <TaskTwoToneIcon />,
    link: "/metamd/todo/cal",
    active: true,
  },
  {
    _id: 3020,
    title: "Appointments",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metamd/appointments/cal",
    active: true,
  },
  {
    _id: 3030,
    title: "Patients",
    icon: <RecentActorsTwoToneIcon />,
    link: "/metamd/patients/list",
    active: true,
  },

  {
    _id: 3040,
    title: "Clinical Notes",
    icon: <NoteAddTwoToneIcon />,
    link: "/metamd/notes/notes",
    active: true,
  },
  {
    _id: 3050,
    title: "Laboratory",
    icon: <SummarizeTwoToneIcon />,
    link: "/metamd/reports/results",
    active: true,
  },
  {
    _id: 3060,
    title: "Radiology",
    icon: <MedicalServicesTwoToneIcon />,
    link: "/metamd/rt/requests",
    active: true,
  },
  //{ _id: 3070,        title: 'Medical Images',    icon: <ImageSearchTwoToneIcon />,   link: '/metamd/mimages/dicom/view', active: true, },
  {
    _id: 3080,
    title: "medicines",
    icon: <MedicationTwoToneIcon />,
    link: "/metamd/medicine/list",
    active: true,
  },
  {
    _id: 3090,
    title: "Health Monitor",
    icon: <MonitorHeartTwoToneIcon />,
    link: "/metamd/monitor/info",
    active: true,
  },
  {
    _id: 3100,
    title: "Preventive Care",
    icon: <MonitorWeightTwoToneIcon />,
    link: "/metamd/care/list",
    active: true,
  },
  {
    _id: 3110,
    title: "questionnaire",
    icon: <QuizTwoToneIcon />,
    link: "/metamd/qns/list",
    active: true,
  },

  {
    _id: 3120,
    title: "Resources",
    icon: <LocalLibraryTwoToneIcon />,
    link: "/metamd/resources/list",
    active: true,
  },
  {
    _id: 3130,
    title: "Payments",
    icon: <PaymentTwoToneIcon />,
    link: "/metamd/payments/list",
    active: true,
  },
  {
    _id: 3140,
    title: "Health Insurance",
    icon: <PaymentTwoToneIcon />,
    link: "/metamd/insurance/info",
    active: true,
  },
  {
    _id: 3150,
    title: "Settings",
    icon: <SettingsTwoToneIcon />,
    link: "/metamd/setup/region",
    active: false,
  },
];
export const menuListMetaMD_RS = [
  {
    _id: 3001,
    title: "My Profile",
    icon: <PersonTwoToneIcon />,
    link: "/metamd/profile",
  },
  {
    _id: 3011,
    title: "To do",
    icon: <TaskTwoToneIcon />,
    link: "/metamd/todo",
  },
];
export const menuListMetaOrg = [
  {
    _id: 4000,
    title: "Profile",
    icon: <PersonTwoToneIcon />,
    link: "/metaorg/info/info",
    active: true,
  },
  {
    _id: 4010,
    title: "To-do",
    icon: <TaskTwoToneIcon />,
    link: "/metaorg/todo/cal",
    active: true,
  },
  {
    _id: 4020,
    title: "Appointments",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metaorg/appointments/cal",
    active: true,
  },
  {
    _id: 4030,
    title: "Departments",
    icon: <FoundationTwoToneIcon />,
    link: "/metaorg/departments/info",
    active: true,
  },
  {
    _id: 4040,
    title: "Patients",
    icon: <PeopleAltTwoToneIcon />,
    link: "/metaorg/patients/list",
    active: true,
  },

  {
    _id: 4080,
    title: "Resources",
    icon: <LocalLibraryTwoToneIcon />,
    link: "/metaorg/resources/list",
    active: true,
  },
  {
    _id: 4110,
    title: "Accounts",
    icon: <AccountBalanceTwoToneIcon />,
    link: "/metaorg/accounts/list",
    active: true,
  },
  {
    _id: 4120,
    title: "Health Insurance",
    icon: <PaymentTwoToneIcon />,
    link: "/metaorg/insurance/info",
    active: true,
  },
  {
    _id: 4140,
    title: "Pharmacy",
    icon: <LocalPharmacyTwoToneIcon />,
    link: "/metaorg/drugs/list",
    active: true,
  },

  {
    _id: 4160,
    title: "Clinical Tests",
    icon: <MedicalServicesTwoToneIcon />,
    link: "/metaorg/ct/list",
    active: true,
  },
  {
    _id: 4180,
    title: "Radiology Tests",
    icon: <MedicalServicesTwoToneIcon />,
    link: "/metaorg/rt/list",
    active: true,
  },
  {
    _id: 4200,
    title: "Settings",
    icon: <SettingsTwoToneIcon />,
    link: "/metaorg/setup/settings",
    active: false,
  },
];
export const menuListMetaOrg_RS = [
  {
    _id: 4001,
    title: "Information",
    icon: <PersonTwoToneIcon />,
    link: "/metaorg/info",
  },
  {
    _id: 4011,
    title: "Appointments",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metaorg/appointments",
  },
];

export const menuListMetaLab = [
  {
    _id: 5000,
    title: "My Profile",
    icon: <PersonTwoToneIcon />,
    link: "/metalab/profile/list",
    active: true,
  },
  {
    _id: 5010,
    title: "My Group",
    icon: <GroupsTwoToneIcon />,
    link: "/metalab/group/list",
    active: true,
  },
  {
    _id: 5020,
    title: "Appointments",
    icon: <TaskTwoToneIcon />,
    link: "/metalab/appointments/cal",
    active: true,
  },
  {
    _id: 5060,
    title: "Patients",
    icon: <PeopleAltTwoToneIcon />,
    link: "/metalab/patients/list",
    active: true,
  },
  {
    _id: 5080,
    title: "Reports",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metalab/reports/list",
    active: true,
  },
  {
    _id: 5100,
    title: "Medical Images",
    icon: <ImageSearchTwoToneIcon />,
    link: "/metalab/mimages",
    active: true,
  },

  {
    _id: 5120,
    title: "Clinical Tests",
    icon: <MedicalServicesTwoToneIcon />,
    link: "/metalab/ct/list",
    active: true,
  },
  {
    _id: 5140,
    title: "Radiology Tests",
    icon: <MedicalServicesTwoToneIcon />,
    link: "/metalab/rt/list",
    active: true,
  },

  {
    _id: 5160,
    title: "Accounts",
    icon: <PaymentTwoToneIcon />,
    link: "/metalab/accounts/list",
    active: true,
  },
  {
    _id: 5180,
    title: "Resources",
    icon: <LocalLibraryTwoToneIcon />,
    link: "/metalab/resources/list",
    active: true,
  },
  {
    _id: 5200,
    title: "Settings",
    icon: <SettingsTwoToneIcon />,
    link: "/metalab/setup/privacy",
    active: false,
  },
];
export const menuListMetaLab_RS = [
  {
    _id: 5001,
    title: "Information",
    icon: <PersonTwoToneIcon />,
    link: "/metalab/info",
  },
  {
    _id: 5011,
    title: "Appointments",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metalab/appointments",
  },
];

export const menuListMetaPharma = [
  {
    _id: 6000,
    title: "My Profile",
    icon: <PersonTwoToneIcon />,
    link: "/metapharma/profile/list",
    active: true,
  },
  {
    _id: 6010,
    title: "My Group",
    icon: <GroupsTwoToneIcon />,
    link: "/metapharma/group/list",
    active: true,
  },
  {
    _id: 6020,
    title: "Appointments",
    icon: <TaskTwoToneIcon />,
    link: "/metapharma/appointments/cal",
    active: true,
  },
  {
    _id: 6060,
    title: "Patients",
    icon: <PeopleAltTwoToneIcon />,
    link: "/metapharma/patients/list",
    active: true,
  },

  {
    _id: 6080,
    title: "Orders",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metapharma/orders/list",
    active: true,
  },
  {
    _id: 6090,
    title: "Medicines",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metapharma/drugs/list",
    active: true,
  },
  {
    _id: 6100,
    title: "Prescriptions",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metapharma/prescriptions/list",
    active: true,
  },
  {
    _id: 6120,
    title: "Support",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metapharma/support/info",
    active: true,
  },
  {
    _id: 6140,
    title: "Accounts",
    icon: <PaymentTwoToneIcon />,
    link: "/metapharma/accounts/list",
    active: true,
  },
  {
    _id: 6160,
    title: "Settings",
    icon: <SettingsTwoToneIcon />,
    link: "/metapharma/setup/privacy",
    active: false,
  },
];
export const menuListMetaPharma_RS = [
  {
    _id: 6001,
    title: "Information",
    icon: <PersonTwoToneIcon />,
    link: "/metapharma/info",
  },
  {
    _id: 6011,
    title: "Appointments",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metapharma/appointments",
  },
];

export const menuListMetaFin = [
  {
    _id: 7000,
    title: "Profile",
    icon: <PersonTwoToneIcon />,
    link: "/metafin/info/info",
    active: true,
  },
  {
    _id: 7010,
    title: "Appointments",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metafin/appointments/cal",
    active: true,
  },
  {
    _id: 7080,
    title: "Resources",
    icon: <LocalLibraryTwoToneIcon />,
    link: "/metafin/resources/list",
    active: true,
  },
  {
    _id: 7100,
    title: "Accounts",
    icon: <PaymentTwoToneIcon />,
    link: "/metafin/accounts/list",
    active: true,
  },
  {
    _id: 7120,
    title: "Settings",
    icon: <SettingsTwoToneIcon />,
    link: "/metafin/setup/privacy",
    active: false,
  },
];
export const menuListMetaFin_RS = [
  {
    _id: 7001,
    title: "Information",
    icon: <PersonTwoToneIcon />,
    link: "/metafin/info",
  },
  {
    _id: 7011,
    title: "Appointments",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metafin/appointments",
  },
];

export const menuListMetaOS = [
  {
    _id: 8000,
    title: "Profile",
    icon: <PersonTwoToneIcon />,
    link: "/metasrv/info/info",
    active: true,
  },
  {
    _id: 8010,
    title: "Appointments",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metasrv/appointments/cal",
    active: true,
  },
  {
    _id: 8080,
    title: "Resources",
    icon: <LocalLibraryTwoToneIcon />,
    link: "/metasrv/resources/list",
    active: true,
  },
  {
    _id: 8100,
    title: "Accounts",
    icon: <PaymentTwoToneIcon />,
    link: "/metasrv/accounts/list",
    active: true,
  },
  {
    _id: 8120,
    title: "Settings",
    icon: <SettingsTwoToneIcon />,
    link: "/metasrv/setup/privacy",
    active: false,
  },
];
export const menuListMetaOS_RS = [
  {
    _id: 8001,
    title: "Information",
    icon: <PersonTwoToneIcon />,
    link: "/metaos/info",
  },
  {
    _id: 8011,
    title: "Appointments",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metaos/appointments",
  },
];

export const menuListMetaCon = [
  {
    _id: 9000,
    title: "Information",
    icon: <PersonTwoToneIcon />,
    link: "/metacon/info",
    active: true,
  },
  {
    _id: 9010,
    title: "Appointments",
    icon: <TaskTwoToneIcon />,
    link: "/metacon/appointments/list",
    active: true,
  },
  {
    _id: 9020,
    title: "Healthcare",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metacon/healthcare/list",
    active: true,
  },
  {
    _id: 9030,
    title: "Departments",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metacon/departments/list",
    active: true,
  },
  {
    _id: 9040,
    title: "Specialists",
    icon: <PersonSearchTwoToneIcon />,
    link: "/metacon/specialists/list",
    active: true,
  },
  {
    _id: 9050,
    title: "Facilities",
    icon: <BookOnlineTwoToneIcon />,
    link: "/metacon/facilities/list",
    active: true,
  },
];
export const menuListMetaCon_RS = [
  {
    _id: 9001,
    title: "Information",
    icon: <PersonTwoToneIcon />,
    link: "/metacon/info",
  },
  {
    _id: 9011,
    title: "Appointments",
    icon: <TaskTwoToneIcon />,
    link: "/metacon/appointments",
  },
];

//---------------------------FUNCTIONS OF LISTS ----------------------------//
export function Lang() {
  //INCORPORATED IN ADMINBAR HEADER = YET TO BEUSED
  const [language, setLanguage] = React.useState("en");

  return (
    <>
      <Box
        mr={1}
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "flex-end",
        }}
      >
        <select
          py={1}
          ml={1}
          px={2}
          className="text-color-primary text-color-2-border text-color-2-bg btn-round-full"
          onChange={(e) => setLanguage(e.target.value)}
          style={{
            float: "right",
            fontSize: "12px",
            textAlign: "right",
            padding: "2px 8px 3px 8px",
          }}
        >
          <option value="en">En </option>
          <option value="hi">हिंदी </option>
        </select>
      </Box>

      <Box
        mr={1}
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "flex-end",
        }}
      >
        <select
          py={1}
          ml={1}
          px={2}
          className="text-color-primary text-color-2-border text-color-2-bg btn-round-full"
          onChange={(e) => setLanguage(e.target.value)}
          style={{
            float: "right",
            fontSize: "12px",
            textAlign: "right",
            padding: "2px 8px 3px 8px",
          }}
        >
          <option value="en">English </option>
          <option value="hi">हिंदी </option>
        </select>
      </Box>
    </>
  );
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Event listener
      window.addEventListener("resize", handleResize);
      // Call handler
      handleResize();
      // Cleanup
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);
  return windowSize;
};

export function setBGColor(newColor) {
  document.documentElement.style.setProperty("--bg-color-cal", newColor);
}

//const bgcolor = getComputedStyle(document.documentElement).getPropertyValue('--bg-color');

export function setPageColor(page) {
  switch (page) {
    case "appointments":
      setBGColor("DodgerBlue");
      break;
    case "todo":
      setBGColor("green");
      break;
    default:
      setBGColor("#2a8aa7");
  }
}

export function getSpeedoColors(v1, v2) {
  let seg = 10;
  if (v1 > v2) {
    let v = v1;
    v1 = v2;
    v2 = v;
  }
  v1 = v1 > 10 ? parseInt(v1 / 10) : v1;
  v2 = v2 > 10 ? parseInt((v2 + 5) / 10) : v2; //to consider next higher number

  let clr = [10];
  for (let i = v1; i < v2; i++) {
    clr[i] = speedoY[i - v1];
  }
  for (let i = v2; i < seg; i++) {
    clr[i] = speedoR[i - v2];
  }
  for (let i = v1 - 1; i >= 0; i--) {
    clr[i] = speedoG[v1 - 1 - i];
  } //reverse

  return clr;
}

export const BlockAccList = [
  { _id: "ba001", value: 10, label: "Create", icon: "", link: "" },
  { _id: "ba003", value: 20, label: "Retrieve", icon: "", link: "" },
  { _id: "ba002", value: 30, label: "Import", icon: "", link: "" },
];

export const ActivityRoleOptions = [
  { _id: "a0010", value: "metauser", label: "User" },
  { _id: "a0020", value: "metamd", label: "Doctor" },
  { _id: "a0030", value: "metalab", label: "Laboratory" },
  { _id: "a0040", value: "metapharma", label: "Pharmacy" },
  { _id: "a0050", value: "metafin", label: "Finance" },
  { _id: "a0060", value: "metaorg", label: "Organization" },
  { _id: "a0070", value: "metasrv", label: "Other Service" },
  // { _id: "a0080", value: 'admin',   label: 'Admin' },
  { _id: "a0090", value: "metasys", label: "System" },
];

export const FeedbackSubject = [
  { _id: "a0010", value: "suggest", label: "Suggestion" },
];

export const SpecialistStatusRoleOptions = [
  { _id: "mds0010", value: "pending", label: "Pending" },
  { _id: "mds0020", value: "active", label: "Active" },
  { _id: "mds0030", value: "inactive", label: "Inactive" },
];

export const SpecializationTypeOptions = [
  { value: "General", label: "General" },
  { value: "Medicine", label: "Medicine" },
  { value: "E&T", label: "E&T" },
  { value: "Gyne", label: "Gyne" },
  { value: "Surgeon", label: "Surgeon" },
];

export const MedPackTypeOptions = [
  { value: "nos", label: "Nos" },
  { value: "bottle", label: "bottle" },
  { value: "strip", label: "Strip" },
  { value: "roll", label: "Roll" },
  { value: "tube", label: "Tube" },
];

export const MedProductTaxOptions = [
  { value: "0.00", label: "Exempted", region: "IND" },
  { value: "5.00", label: "Tax 5.00%", region: "IND" },
  { value: "12.00", label: "Tax 12.00%", region: "IND" },
  { value: "18.00", label: "Tax 18.00%", region: "IND" },
  { value: "28.00", label: "Tax 28.00%", region: "IND" },
];

export const MedTestsTaxOptions = [
  { value: "0.00", label: "Exempted", region: "IND" },
  { value: "5.00", label: "Tax 5.00%", region: "IND" },
  { value: "12.00", label: "Tax 12.00%", region: "IND" },
  { value: "18.00", label: "Tax 18.00%", region: "IND" },
  { value: "28.00", label: "Tax 28.00%", region: "IND" },
];
export function ChooseSystemRoles(props) {
  //YET TO BE USED

  const { roles } = props;
  const containerType = {
    type: "combo",
    label: "Activity Role",
    comboLabel: "Any more addl combo box",
    comboWidth: "100%",
    comboList: [
      {
        _id: "metauser",
        value: "metauser",
        label: "User",
        icon: <TableRowsTwoToneIcon />,
        link: "/metauser",
      },
      {
        _id: "metamd",
        value: "metamd",
        label: "Doctor",
        icon: <TimelineTwoToneIcon />,
        link: "/metamd",
      },
      {
        _id: "metalabs",
        value: "metalabs",
        label: "Labs",
        icon: <AddAlertTwoToneIcon />,
        link: "/metaothers",
      },
      {
        _id: "metapharma",
        value: "metapharma",
        label: "Pharma",
        icon: <MessageTwoToneIcon />,
        link: "/metaothers",
      },
      {
        _id: "metafins",
        value: "metafins",
        label: "Fins",
        icon: <TableRowsTwoToneIcon />,
        link: "/metaorg",
      },
      {
        _id: "metaorg",
        value: "metaorg",
        label: "Orgs",
        icon: <TableRowsTwoToneIcon />,
        link: "/metaorg",
      },
      {
        _id: "metasrvs",
        value: "metasrvs",
        label: "Services",
        icon: <TableRowsTwoToneIcon />,
        link: "/metaothers",
      },
      {
        _id: "admin",
        value: "admin",
        label: "Admin",
        icon: <TableRowsTwoToneIcon />,
        link: "/metauser",
      },
    ],
  };

  return <SelectCombo containerType={containerType} cboHandleChange={roles} />;
}

export function getAge(dob) {
  let age = "";
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  if (!!dob) {
  } else {
    return age;
  }
  if (dob.trim() === "") {
    return age;
  }
  const [year, month, day] = dob.split("-");
  if (year.trim() === "") {
    return age;
  }
  if (!isNaN(year)) {
    age = currentYear - year;
  }
  return age;
}

export const homedata = [
  {
    title: "Book appoinment",
    icon: "",
    msg: "",
    imgSrc: I1,
    link: "/metacon/appointments/list",
  },
];

export const homeservedata = [
  {
    _id: 1101,
    title: "Hospitals",
    icon: "",
    iconhome: "heart-beat-alt",
    msg: "Affordable cashless treatment.",
    imgSrc: I2,
    link: "/swbh/hospital",
  },
  {
    _id: 1102,
    title: "Pathology",
    icon: "",
    iconhome: "laboratory",
    msg: "Accredited laboratories to serve you.",
    imgSrc: I1,
    link: "/swbl/laboratory",
  },
  {
    _id: 1103,
    title: "Drug Store",
    icon: "",
    iconhome: "drug",
    msg: "Authentic and reasonably priced.",
    imgSrc: Dna,
    link: "/swbp/pharmacy",
  },
  {
    _id: 1104,
    title: "Health Insurance",
    icon: "",
    iconhome: "crutch",
    msg: "Assurance for the unforeseen.",
    imgSrc: I2,
    link: "/swbi/policy",
  },
  {
    _id: 1105,
    title: "Specialists",
    icon: "",
    iconhome: "doctor",
    msg: "Qualified doctors and specialists.",
    imgSrc: I1,
    link: "/swbs/specialist",
  },
  {
    _id: 1106,
    title: "Clinics",
    icon: "",
    iconhome: "dna-alt-1",
    msg: "Clinic facility near you.",
    imgSrc: Dna,
    link: "/swbc/clinics",
  },
];

export const whiteboxdata = [
  {
    _id: 1100,
    title: "On-Demand Demo",
    icon: "",
    msg: "Platform experience",
    imgSrc: I1,
    link: "/wbd/demo",
  },
  {
    _id: 1200,
    title: "Recorded Webinars",
    icon: "",
    msg: "Learn from experts.",
    imgSrc: I2,
    link: "/wbw/webinars",
  },
  {
    _id: 1300,
    title: "Analytics",
    icon: "",
    msg: "Platform analytics",
    imgSrc: I3,
    link: "/wba/analytics",
  },
  {
    _id: 1400,
    title: "JOIN-US",
    icon: "",
    msg: "Join us to serve.",
    imgSrc: I4,
    link: "/wbju/joinus",
  },
];

//---------------------------FOOTER LISTS ----------------------------//
export const CompBoxes = [
  {
    _id: 100,
    title: "About us",
    label: "About",
    icon: <InfoTwoToneIcon />,
    link: "/aboutus/about",
  },
  {
    _id: 101,
    title: "Vision",
    label: "Vision",
    icon: <AdjustTwoToneIcon />,
    link: "/aboutus/vision",
  },
  {
    _id: 102,
    title: "Mission",
    label: "Mission",
    icon: <AssistantPhotoTwoToneIcon />,
    link: "/aboutus/mission",
  },
  {
    _id: 103,
    title: "Careers",
    label: "Careers",
    icon: <BadgeTwoToneIcon />,
    link: "/aboutus/careers",
  },
  // { _id: 104, "title":"Roadmap",          "label": "Roadmap",   "icon": <FollowTheSignsTwoToneIcon />, "link":"/aboutus/roadmap"},
];
export const ServBoxes = [
  {
    _id: 200,
    title: "Business Profile",
    label: "Business",
    icon: <ApartmentTwoToneIcon />,
    link: "/serv/services",
  },
  {
    _id: 210,
    title: "Blog",
    label: "Blog",
    icon: <BookTwoToneIcon />,
    link: "/servblog/metablog",
  },
  // { _id: 220, "title":"Webinars",         "label": "Webinars",  "icon": <FilterDramaTwoToneIcon />,    "link":"/webinars"},
  // { _id: 230, "title":"Speciality",       "label": "Speciality","icon": <AssignmentIndTwoToneIcon />,  "link":"/speciality"},
  {
    _id: 240,
    title: "Health Insurance",
    label: "Insurance",
    icon: <HealthAndSafetyTwoToneIcon />,
    link: "/servinsu/policy",
  },
];
export const TermsBoxes = [
  {
    _id: 300,
    title: "Business Terms",
    label: "Business",
    icon: <AttachMoneyTwoToneIcon />,
    link: "/business/terms",
  },
  {
    _id: 301,
    title: "Privacy Policy",
    label: "Policy",
    icon: <BalanceTwoToneIcon />,
    link: "/businessprivacy/privacy",
  },
  {
    _id: 302,
    title: "Terms of Use",
    label: "Terms",
    icon: <GavelTwoToneIcon />,
    link: "/businesstou/termsofuse",
  },
];
export const ResoBoxes = [
  {
    _id: 400,
    title: "Documentation",
    label: "Doc",
    icon: <CastForEducationTwoToneIcon />,
    link: "/resources/doc",
  },
  {
    _id: 410,
    title: "FAQ",
    label: "FAQ",
    icon: <HelpTwoToneIcon />,
    link: "/resources/faq",
  },

  // { _id: 420, "title":"Hospitals",        "label": "Hospitals",  "icon": <HelpTwoToneIcon />,          "link":"/resources/hospital"},
  // { _id: 430, "title":"Specialists",      "label": "Specialists","icon": <HelpTwoToneIcon />,          "link":"/resources/specialist"},
  // { _id: 440, "title":"Pharmacies",       "label": "Pharmacies", "icon": <HelpTwoToneIcon />,          "link":"/resources/pharmacy"},
  // { _id: 450, "title":"Laboratories",     "label":"Laboratories","icon": <HelpTwoToneIcon />,          "link":"/resources/laboratory"},
];
export const ConnBoxes = [
  {
    _id: 500,
    title: "Help",
    label: "Help",
    icon: <HelpTwoToneIcon />,
    link: "/connecthelp/help",
  },
  {
    _id: 501,
    title: "Feedback",
    label: "Feedback",
    icon: <InputTwoToneIcon />,
    link: "/connectfb/feedback",
  },
  {
    _id: 502,
    title: "Contact Us",
    label: "Contact",
    icon: <AlternateEmailTwoToneIcon />,
    link: "/connectc/connect",
  },
  // { _id: 520, "title":"Sitemap",          "label": "Sitemap",    "icon": <AccountTreeTwoToneIcon />,      "link":"/connectsm/sitemap"},
];

export const SocialBoxes = [
  { _id: "fb", title: "facebook", icon: "facebook", link: "" },
  { _id: "tw", title: "twitter", icon: "twitter", link: "" },
  { _id: "ln", title: "linkedin", icon: "linkedin", link: "" },
];

//-----------------------------------------------------OTHERS-------------------------------------------------------------//
export const checkListCal = [
  { _id: "all", title: "All", isChecked: false },
  { _id: "metamd", title: "Doctor", isChecked: false },
  { _id: "metalab", title: "Laboratory", isChecked: false },
  { _id: "todo", title: "To Do", isChecked: false },
];

export const eventsListCal = [
  { _id: "metamd", title: "Doctor", value: "metamd" },
  { _id: "metalab", title: "Laboratory", value: "metalab" },
  { _id: "todo", title: "To Do", value: "todo" },
];

export const appointmentOptionList = [
  { _id: "metamd", title: "Doctor", value: "metamd" },
  { _id: "metalab", title: "Laboratory", value: "metalab" },
];

export const AppointmentTypes = [
  {
    _id: "metalabs",
    value: "metalabs",
    label: "Laboratory",
    icon: "",
    link: "/",
  },
  {
    _id: "metaplus",
    value: "metaplus",
    label: "Hospital",
    icon: "",
    link: "/",
  },
  { _id: "metamd", value: "metamd", label: "Doctor", icon: "", link: "/" },
];

export const BankAccountTypes = [
  {
    _id: "bac",
    value: "Current",
    label: "Current Account",
    icon: "",
    link: "/",
  },
  { _id: "bas", value: "Saving", label: "Saving Account", icon: "", link: "/" },
];

export const DocIdentityProof = [
  {
    _id: "dip1",
    value: "Current",
    label: "Current Account",
    icon: "",
    link: "/",
  },
  {
    _id: "dip2",
    value: "Saving",
    label: "Saving Account",
    icon: "",
    link: "/",
  },
];
export const DocAddressProof = [
  {
    _id: "dap1",
    value: "Current",
    label: "Current Account",
    icon: "",
    link: "/",
  },
  {
    _id: "dap2",
    value: "Saving",
    label: "Saving Account",
    icon: "",
    link: "/",
  },
];

export const checkListPrescription = [
  { _id: "presAll", title: "All", isChecked: false },
  { _id: "presCN", title: "Clinical Notes", isChecked: false },
  { _id: "presPD", title: "Prescribed Drugs", isChecked: false },
  { _id: "presCT", title: "Clinical Tests", isChecked: false },
  { _id: "presRT", title: "Radiological Tests", isChecked: false },
  { _id: "presIN", title: "Insurance", isChecked: false },
];

export const calQueue = true;
export const calSlots = "00:30:00";
export const calVorig = [
  { year: "numeric", month: "long" },
  { year: "numeric", month: "short", day: "numeric" },
  { year: "numeric", month: "long", day: "numeric" },
  { year: "numeric", month: "long", day: "numeric" },
  { month: "long" },
  { month: "short", day: "numeric" },
  { month: "short", day: "numeric" },
  { month: "long", day: "numeric" },
];

export const calV = [
  { year: "numeric", month: "long" },
  { year: "numeric", month: "long" },
  { year: "numeric", month: "long", day: "numeric" },
  { year: "numeric", month: "long", day: "numeric" },

  { year: "numeric", month: "long" },
  { year: "numeric", month: "short" },
  { month: "short", day: "numeric" },
  { month: "long", day: "numeric" },

  { year: "numeric", month: "long" },
  { year: "numeric", month: "long" },
  { year: "numeric", month: "long", day: "numeric" },
  { year: "numeric", month: "long", day: "numeric" },

  { month: "short" },
  { month: "short" },
  { day: "numeric" },
  { day: "numeric" },
];

{
  /*
FOR AUTOMATION 
export const MetaDefaultPagesXXX = [      
    { _id:100010,       default: "/todo",         href: "/metauser", },
    { _id:100020,       default: "/monitor",      href: "/metaorg",  }, 
    { _id:100030,       default: "/appointments", href: "/metamd",   },
    { _id:100040,       default: "/info",         href: "/metalab",  }, 
    { _id:100050,       default: "/info",         href: "/metapharma",  }, 
    { _id:100060,       default: "/info",         href: "/metafin",  }, 
    { _id:100070,       default: "/info",         href: "/metasrv",  },
    { _id:100080,       default: "/contactus",    href: "/metacon",  },


    { _id:200,          default: "/list",         href: menuListMetauser[0].link, },
    { _id:201,          default: "/cal",          href: menuListMetauser[1].link, },
    { _id:202,          default: "/cal",          href: menuListMetauser[2].link, },
    { _id:203,          default: "/notes",        href: menuListMetauser[3].link, },
    { _id:204,          default: "/reports",      href: menuListMetauser[4].link, },
    { _id:205,          default: "/reports",      href: menuListMetauser[5].link, },

    { _id:206,          default: "/view",         href: menuListMetauser[6].link, },
    { _id:207,          default: "/list",         href: menuListMetauser[7].link, },
    { _id:208,          default: "/conditions",   href: menuListMetauser[8].link, },
    { _id:209,          default: "/list",         href: menuListMetauser[9].link, },
    { _id:210,          default: "/list",         href: menuListMetauser[10].link, },
    { _id:211,          default: "/list",         href: menuListMetauser[11].link, },
    { _id:212,          default: "/list",         href: menuListMetauser[12].link, },
    { _id:213,          default: "/info",         href: menuListMetauser[13].link, },
    { _id:214,          default: "/privacy",      href: menuListMetauser[14].link, },

    { _id:300,          default: "/list",         href: menuListMetaMD[0].link, },
    { _id:301,          default: "/cal",          href: menuListMetaMD[1].link, },
    { _id:302,          default: "/cal",          href: menuListMetaMD[2].link, },
    { _id:303,          default: "/list",         href: menuListMetaMD[3].link, },
    { _id:304,          default: "/notes",        href: menuListMetaMD[4].link, },
    { _id:305,          default: "/results",      href: menuListMetaMD[5].link, },
    //{ _id:306,          default: "/results",      href: menuListMetaMD[6].link, },
    { _id:307,          default: "/view",         href: menuListMetaMD[6].link, },
    { _id:308,          default: "/list",         href: menuListMetaMD[7].link, },
    { _id:309,          default: "/info",         href: menuListMetaMD[8].link, },
    { _id:310,          default: "/list",         href: menuListMetaMD[9].link, },
    { _id:311,          default: "/list",         href: menuListMetaMD[10].link, },
    { _id:312,          default: "/list",         href: menuListMetaMD[11].link, },
    { _id:313,          default: "/list",         href: menuListMetaMD[12].link, },
    { _id:314,          default: "/info",         href: menuListMetaMD[13].link, },
    { _id:315,          default: "/privacy",      href: menuListMetaMD[14].link, },

    { _id:400,          default: "/info",         href: menuListMetaOrg[0].link, },
    { _id:401,          default: "/cal",          href: menuListMetaOrg[1].link, },
    { _id:404,          default: "/info",         href: menuListMetaOrg[2].link, },
    { _id:405,          default: "/list",         href: menuListMetaOrg[3].link, },

    { _id:407,          default: "/list",         href: menuListMetaOrg[4].link, },
    { _id:409,          default: "/list",         href: menuListMetaOrg[5].link, },
    { _id:410,          default: "/info",         href: menuListMetaOrg[6].link, },
    { _id:414,          default: "/list",         href: menuListMetaOrg[7].link, },

    { _id:416,          default: "/list",         href: menuListMetaOrg[8].link, },
    { _id:418,          default: "/list",         href: menuListMetaOrg[9].link, },
    { _id:420,          default: "/privacy",      href: menuListMetaOrg[10].link, },

    { _id:500,          default: "/info",         href: menuListMetaLab[0].link, },
    { _id:502,          default: "/cal",          href: menuListMetaLab[1].link, },
    { _id:506,          default: "/list",         href: menuListMetaLab[2].link, },
    { _id:508,          default: "/list",         href: menuListMetaLab[3].link, },

    { _id:510,          default: "/list",         href: menuListMetaLab[4].link, },
    { _id:512,          default: "/list",         href: menuListMetaLab[5].link, },
    { _id:514,          default: "/list",         href: menuListMetaLab[6].link, },
    { _id:516,          default: "/privacy",      href: menuListMetaLab[7].link, },


    { _id:600,          default: "/info",         href: menuListMetaPharma[0].link, },
    { _id:602,          default: "/cal",          href: menuListMetaPharma[1].link, },
    { _id:604,          default: "/list",         href: menuListMetaPharma[2].link, },
    { _id:606,          default: "/list",         href: menuListMetaPharma[3].link, },

    { _id:608,          default: "/list",         href: menuListMetaPharma[4].link, },
    { _id:612,          default: "/info",         href: menuListMetaPharma[5].link, },
    { _id:614,          default: "/list",         href: menuListMetaPharma[6].link, },
    { _id:616,          default: "/privacy",      href: menuListMetaPharma[7].link, },


    { _id:700,          default: "/info",         href: menuListMetaFin[0].link, },
    { _id:701,          default: "/cal",          href: menuListMetaFin[1].link, },
    { _id:708,          default: "/list",         href: menuListMetaFin[2].link, },
    { _id:710,          default: "/list",         href: menuListMetaFin[3].link, },
    { _id:712,          default: "/privacy",      href: menuListMetaFin[4].link, },


    { _id:800,          default: "/info",         href: menuListMetaOS[0].link, },
    { _id:801,          default: "/cal",          href: menuListMetaOS[1].link, },
    { _id:808,          default: "/list",         href: menuListMetaOS[2].link, },
    { _id:810,          default: "/list",         href: menuListMetaOS[3].link, },
    { _id:812,          default: "/privacy",      href: menuListMetaOS[4].link, },


    { _id:900,          default: "/info",         href: menuListMetaCon[0].link, },
    { _id:900,          default: "/list",         href: menuListMetaCon[1].link, },
    { _id:900,          default: "/list",         href: menuListMetaCon[2].link, },
    { _id:900,          default: "/list",         href: menuListMetaCon[3].link, },
    { _id:900,          default: "/list",         href: menuListMetaCon[4].link, },
    { _id:900,          default: "/list",         href: menuListMetaCon[5].link, },
]

    //fontSize:     {"xs":"12px", "sm":"14px", "md":"16px", "lg":"18px","xl":"20px" },
*/
}
