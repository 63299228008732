/**
 *
 *
 * @returns
 */
export default function EmptyViewport() {
  return (
    <div className="empty-viewport">
      <p>Please drag a stack here to view images.</p>
    </div>
  );
}
