import React, { useState } from "react";
import { Box } from "@material-ui/core";
import PatientStepper from "./PatientStepper";
import { colChart } from "../../common/ConstantsAndValues";

export default function OrgAddPatients(props) {
  const { handleCancel } = props;
  return (
    <Box
      display="flex"
      width={"100%"}
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" width={colChart.width} justifyContent="left">
        <PatientStepper handleDone={handleCancel} />
      </Box>
    </Box>
  );
}
