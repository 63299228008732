import React, { useEffect, useState } from "react";

export const AccordionCloseIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})`, marginTop: "4px" }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Menu">
        <rect
          id="Menu line"
          x="1.41406"
          y="0.0078125"
          width="13.0016"
          height="2"
          rx="1"
          transform="rotate(45 1.41406 0.0078125)"
          fill="#404040"
        />
        <rect
          id="Menu line "
          y="16.9766"
          width="13"
          height="2"
          rx="1"
          transform="rotate(-45 0 16.9766)"
          fill="#404040"
        />
      </g>
    </svg>
  );
};
