import { Box, Typography } from "@mui/material";
import teamImg from "../../assets/images/homepage/Team.svg";

export default function OurTeam() {
  return (
    <Box
      id="sectionThree"
      sx={{
        background: "#EAF4F780",
      }}
    >
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          justifyContent: "center",
          mx: { xs: 0, md: 8 },
          // py: { xs: 3, md: 6 },
        }}
      >
        <Box sx={{ mx: { xs: 4, md: 0 }, alignSelf: "center" }}>
          <Typography
            sx={{ fontSize: "30px", color: "#404040", fontWeight: "600" }}
          >
            Our team
          </Typography>
          <Typography
            sx={{
              mt: 2,
              width: { xs: "100%", md: "85%" },
              fontSize: { xs: "15px", lg: "18px" },
            }}
          >
            Team expertise spans a spectrum of cutting-edge domains, including
            health tech, AI, enterprise software, SaaS platforms, enterprise
            engagement, and startup accelerator programs.
          </Typography>
        </Box>
        <Box
          mt={{ xs: 4, md: 0 }}
          sx={{ display: { xs: "block", md: "contents" } }}
        >
          <img
            className="banner_landing_img"
            src={teamImg}
            alt="teamImg"
            width={500}
            height={250}
          />
        </Box>
      </Box>
    </Box>
  );
}
