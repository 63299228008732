import DrugTax from "../../pharmacy/components/DrugTax";
import { useSelector } from "react-redux";
import log from "../../../services/log";

export default function DrugsTaxOrg(props) {
  const searchtext = useSelector((state) =>
    state.user.searchtext ? state.user.searchtext : null
  );

  // log.info("drugprice", searchtext);

  return (
    <div>
      <DrugTax searchText={searchtext && searchtext.drugs} />
    </div>
  );
}
