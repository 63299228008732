import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Grid, Box, Typography } from "@mui/material";
import { labPrescriptionSchema } from "../schema/prescriptionSchema";
import { setLabsOrderPresList } from "../../redux/labs/labs.actions";
import InputFormComponent from "../CustomComponents/InputFormComponent";
import { MyTooltipButtons, MyHelpButtons } from "../CustomComponents/MyButtons";

export const ClinicalTestReorderInput = (props) => {
  const { cancel } = props;

  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const patient = useSelector((state) => state.user.selectedPatient);
  const preslist = useSelector((state) => state.labs.labsorder);
  const labsselect = useSelector((state) => state.labs.labsorderselect);
  const moreselect = useSelector((state) => state.labs.moreselect);

  const dispatch = useDispatch();

  const clinicaltestform = useFormik({
    initialValues: {
      doctorId: user ? user.metadata.accountId : "",
      doctorName: user ? user.name : "",
      patientId: patient ? patient.metadata.patientId : "",
      patientName: patient ? patient.name : "",
      labId: labsselect ? labsselect.metadata.labId : "",
      labName: labsselect ? labsselect.labName : "",
      comment: "",
      loincNumber: labsselect ? labsselect.metadata.loincNumber : "",
      commonName: labsselect ? labsselect.commonName : "",
      status: "inprocess",
      doctorApproval: true,
      currentlist: preslist ? [...preslist] : [],
    },
    validationSchema: labPrescriptionSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("clinicaltestinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["doctorId"] = values.doctorId;
      jsonData.metadata["patientId"] = values.patientId;
      jsonData.metadata["labId"] = values.labId;
      jsonData.metadata["loincNumber"] = values.loincNumber;
      jsonData["doctorName"] = values.doctorName;
      jsonData["patientName"] = values.patientName;
      jsonData["commonName"] = values.commonName;
      jsonData["labName"] = values.labName;
      jsonData["comment"] = values.comment;
      jsonData["doctorApproval"] = values.doctorApproval;
      jsonData["status"] = values.status;

      values.currentlist.push(jsonData);
      // log.info("clinicaltestinput", values.currentlist);
      dispatch(setLabsOrderPresList(values.currentlist));
      cancel();
    },
    onReset: (values) => {
      values.dose = 1;
    },
  });

  const cancelEvent = () => {
    cancel();
  };

  // log.info("clinicaltestinput", clinicaltestform.values);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%", md: "50%", lg: "40%" },
          minWidth: { xs: "100%", sm: "90%", md: "50%", lg: "40%" },
        }}
      >
        <Box sx={{ width: "100%" }}>
          <form>
            <Box className="colfr0 " mb={1}>
              <MyTooltipButtons
                onClick={cancelEvent}
                btnKey={"cancel"}
                btnLabel={"Cancel"}
                enState={false}
              />
            </Box>
            <Box className="clear"></Box>
            <Box width="100%" className="text-color-primary ">
              <Box>
                <Typography className="text-color-primary">
                  {clinicaltestform.values.labName}
                </Typography>
                &nbsp;
              </Box>
              <Box>
                <InputFormComponent
                  id="comment"
                  name="comment"
                  label="Comment"
                  errorText={clinicaltestform.errors.comment}
                  defaultValue={clinicaltestform.values.comment}
                  errors={clinicaltestform.errors.comment ? true : false}
                  variant="standard"
                  handleChange={clinicaltestform.handleChange}
                />
              </Box>
            </Box>
          </form>
        </Box>
        <Box className="clear" />
        <Box mt={2} pt={2} className="border-dbt">
          <Box className="colfr0">
            <MyHelpButtons
              onClick={clinicaltestform.handleSubmit}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
              btnTooltip={"Add"}
            />
          </Box>
          <Box className="clear" />
        </Box>
        <Box className="clear" />
        <Box mt={2} className="border-dbt p10 text-color-error">
          {clinicaltestform.errors.labName ? (
            <Typography className="text-color-primary-highlight">
              {clinicaltestform.errors.labName}
            </Typography>
          ) : (
            <div />
          )}
          {clinicaltestform.errors.loincNumber ? (
            <Typography className="text-color-primary-highlight">
              {clinicaltestform.errors.loincNumber}
            </Typography>
          ) : (
            <div />
          )}
          {clinicaltestform.errors.patientId ? (
            <Typography className="text-color-primary-highlight">
              {clinicaltestform.errors.patientId}
            </Typography>
          ) : (
            <div />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
