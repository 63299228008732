import { EventsActionTypes } from "./types";
import {
  requestEventsInRange,
  requestEventCreate,
  requestEventUpdate,
  requestEventDelete,
} from "./requests";
import log from "../../../services/log";

//export function toggleWeekends()
//{
//    return {
//        type: EventsActionTypes.TOGGLE_WEEKENDS
//    }
//}

export function requestEvents(startStr, endStr) {
  return (dispatch) => {
    return requestEventsInRange(startStr, endStr).then((plainEventObjects) => {
      dispatch({
        type: EventsActionTypes.RECEIVE_EVENTS,
        plainEventObjects,
      });
    });
    // getAppointmentList()
  };
}

export function createEvent(plainEventObject) {
  return (dispatch) => {
    return requestEventCreate(plainEventObject).then((newEventId) => {
      dispatch({
        type: EventsActionTypes.CREATE_EVENT,
        plainEventObject: {
          id: newEventId,
          ...plainEventObject,
        },
      });
    });
  };
}

export default function updateEvent(plainEventObject) {
  return (dispatch) => {
    return requestEventUpdate(plainEventObject).then(() => {
      dispatch({
        type: EventsActionTypes.UPDATE_EVENT,
        plainEventObject,
      });
    });
  };
}

export function deleteEvent(eventId) {
  return (dispatch) => {
    return requestEventDelete(eventId).then(() => {
      dispatch({
        type: EventsActionTypes.DELETE_EVENT,
        eventId,
      });
    });
  };
}

export const setReportEventFetchMask = (payload) => (dispatch) => {
  log.info("reporteventmask update", payload);
  return dispatch({
    type: EventsActionTypes.REPORTEVENT_FILTERS,
    payload,
  });
  return Promise.resolve();
};

//export const selectEventDate = (payload) => (dispatch) => {
//    return dispatch ({
//        type: EventsActionTypes.SELECT_DATE,
//        payload
//    });
//}

//export const selectRightDisable = (payload) => (dispatch) => {
//    return dispatch({
//        type: EventsActionTypes.SELECT_DISABLEDATE,
//        payload: payload
//    });
//}

//export const  updteDatesEventStates = (payload) => (dispatch) =>
//{
//    return dispatch({
//        type: EventsActionTypes.DATES_AVAILABLE,
//        payload: payload
//    });
//}

//export const  setEventSelectDate = (payload) => (dispatch) =>
//{
//    return dispatch({
//        type: EventsActionTypes.SETAPPT_DATE,
//        payload: payload
//    });
//}
