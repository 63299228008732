import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import log from "../../../services/log";
import { Grid } from "@material-ui/core";

import { SearchBoxStandard } from "../../common/ConstantsAndValues";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import useStyles from "../../CustomComponents/useStyles";
import { getMDGroupList } from "../../../redux/user/user.actions";
import { ProfileMDRow } from "./ProfileMDRow";
import { ProfileMDHead } from "./ProfileMDHead";
import { selectMDGroup } from "../../../redux/user/user.actions";
import { builddoctorGroupsQuery } from "../../dbcq/mdgroupdb";

/**
 * mdgroup list of associated groups
 * facilitates in securing and accessing group data
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export default function ProfileMD(props) {
  const user = useSelector((state) => state?.auth.users?.user);
  const searchcontext = useSelector((state) => state?.user.searchcontext);
  const datalist = useSelector((state) => state.user.pgrouplist);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setRows(datalist);
  }, [datalist]);

  useEffect(() => {
    // log.info("drug serarch query", searchText);
    const resetpage = { ...pagedata, page: 0 };
    const jsonData = builddoctorGroupsQuery(resetpage, searchcontext, user);
    // log.info("datalist serarch query", searchquery);
    dispatch(getMDGroupList(jsonData)).then((r) => {
      // log.info("datalist", r)
      setPage({
        ...pagedata,
        count: r.totalResults,
        pages: r.totalPages,
      });
    });
  }, [searchcontext, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    log.info("datalist", keyword);
    const newlist = keyword
      ? rows.filter((v) =>
          v.orgName.toLowerCase().startsWith(keyword.toLowerCase())
        )
      : datalist?.results;
    setRows(newlist);
  };

  const handleChangePage = (event, value) => {
    // log.info("datalist", value);
    // log.info("datalist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const jsonData = builddoctorGroupsQuery(newpagedata, searchcontext, user);
    // log.info("datalist serarch query", searchquery);
    dispatch(getMDGroupList(jsonData)).then((r) => {
      // log.info("datalist", r)
      setPage({
        ...pagedata,
        count: r.totalResults,
        pages: r.totalPages,
      });
    });
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (row, add) => {
    // log.info("Prescribe", row);
    // log.info("Prescribe", add);

    if (row && add === true) {
      setCheckedRows(row);
      dispatch(selectMDGroup(row));
    }

    if (row && add === false) {
      setCheckedRows(null);
      dispatch(selectMDGroup(null));
    }
  };

  // log.info("datalist", rows);

  return (
    <Grid container>
      <Grid item xs>
        <div>
          <div
            className="flex-grow-1"
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "36px",
              width: "100%",
            }}
          >
            <div>
              <div className="colfr0" width="100%">
                <SearchBoxStandard
                  onChange={handleFilter}
                  placeholder="organization name"
                  sx={{ marginRight: 1 }}
                  className="text-color-primary text-color-2-border text-color-2-bg"
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "16px" }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="collapsible table">
                <TableHead>
                  <ProfileMDHead />
                </TableHead>

                <TableBody className="text-color-primary text text-md">
                  {rows &&
                    rows.map((row) => <ProfileMDRow key={row.id} row={row} />)}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div style={{ width: "100%" }}>
            <TablePaginationStandard
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              pagedata={pagedata}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
