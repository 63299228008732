import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AttachFileTwoToneIcon from "@mui/icons-material/AttachFileTwoTone";

export const UploadFile = (props) => {
  const { addPrivateKey } = props;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        addPrivateKey(reader.result.slice(0, -1));
      };
      // reader.readAsArrayBuffer(file)
      reader.readAsText(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} className="border-db" />
      <p className="text-color-gray">
        Drag 'n' Drop or Click to Select Keyfile
      </p>
    </div>
  );
};
