export default {
  1: { description: "Soft tissue", window: "400", level: "40" },
  2: { description: "Lung", window: "1500", level: "-600" },
  3: { description: "Liver", window: "150", level: "90" },
  4: { description: "Bone", window: "2500", level: "480" },
  5: { description: "Brain", window: "80", level: "40" },
  6: { description: "Trest", window: "1", level: "1" },
  7: { description: "Empty1", window: "Empty1", level: "Empty1" },
  8: { description: "Empty2", window: "Empty2", level: "Empty2" },
  9: { description: "Empty3", window: "Empty3", level: "Empty3" },
  10: { description: "Empty4", window: "Empty4", level: "Empty4" },
};
