import React, { useEffect, useState } from "react";

const FindCareIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9001 11.8485C17.9001 15.3288 15.0788 18.1501 11.5985 18.1501C8.1182 18.1501 5.29688 15.3288 5.29688 11.8485C5.29688 8.3682 8.1182 5.54688 11.5985 5.54688C15.0788 5.54688 17.9001 8.3682 17.9001 11.8485ZM17.1551 18.0163C15.6837 19.3427 13.7354 20.1501 11.5985 20.1501C7.01363 20.1501 3.29688 16.4333 3.29688 11.8485C3.29688 7.26363 7.01363 3.54688 11.5985 3.54688C16.1833 3.54688 19.9001 7.26363 19.9001 11.8485C19.9001 13.7295 19.2745 15.4643 18.22 16.8564L21.9107 20.5471C22.2179 20.8542 22.2179 21.3523 21.9107 21.6595C21.6035 21.9667 21.1054 21.9667 20.7982 21.6595L17.1551 18.0163ZM8.03094 11.8643C8.03094 11.4299 8.38312 11.0777 8.81755 11.0777H10.7443V9.21927C10.7443 8.78483 11.0965 8.43265 11.5309 8.43265C11.9654 8.43265 12.3175 8.78483 12.3175 9.21927V11.0777H14.2443C14.6788 11.0777 15.0309 11.4299 15.0309 11.8643C15.0309 12.2987 14.6788 12.6509 14.2443 12.6509H12.3175V14.646C12.3175 15.0805 11.9654 15.4327 11.5309 15.4327C11.0965 15.4327 10.7443 15.0805 10.7443 14.646V12.6509H8.81755C8.38312 12.6509 8.03094 12.2987 8.03094 11.8643Z"
      />
    </svg>
  );
};
export default FindCareIcon;
