import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../CustomComponents/useStyles";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";

import { ReportReportsHead } from "./ReportReportsMDDHead";
import { ReportReportsRow } from "./ReportReportsMDDRow";

import log from "../../services/log";
import { getLabsList } from "../../redux/labs/labs.actions";

export function ReportReportsMDD(props) {
  const { reportslist } = props;

  const selectedPatient = useSelector((state) => state?.user?.selectedPatient);
  const reports = useSelector((state) => state?.reports);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState(null);
  const [medicines, setMedicines] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  const buildQuery = () => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata["patientId"] = selectedPatient?.metadata?.patientId;
    return jsonData;
  };

  useEffect(() => {
    if (selectedPatient) {
      dispatch(getLabsList(buildQuery())).then((r) => {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      });
    }
  }, [selectedPatient]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = medicines.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(medicines);
    }
  };

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    dispatch(getLabsList(buildQuery())).then((r) => {
      setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
    });
    setPage(newpagedata);
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (e, selectrow) => {
    log.info("reportslist", e);
    if (selectrow) {
      setCheckedRows(e);
      // dispatch(selectPatientMedcine(e));
    } else {
      setCheckedRows(null);
      // dispatch(selectPatientMedcine(null));
    }
  };

  log.info("reportslist pagedata", pagedata);

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead>
              <ReportReportsHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {medicines &&
                medicines.map((row) => (
                  <ReportReportsRow
                    key={row.id}
                    row={row}
                    isRowSelected={checkedRows}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
