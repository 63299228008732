import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTableFooter } from "../styles/InvoiceStyles";

const styles = stylesTableFooter;

export const InvoiceTableFooter = ({ items }) => {
  const total = items
    .map((item) => item.qty * item.rate)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return (
    <View style={styles.row}>
      <Text style={styles.description}>TOTAL</Text>
      <Text style={styles.total}>{Number.parseFloat(total).toFixed(2)}</Text>
    </View>
  );
};
