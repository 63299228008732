import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";

import { MyButtons, MyTooltipButtons } from "../../CustomComponents/MyButtons";

import { RadReportsList } from "../../reports/RadReportsList";
//import {RadReportsInput}                         from "../../reports/RadReportsInput";
import { RadReportsOrderList } from "../../reports/RadReportsOrderList";
import { RadReportsPatOrderInput } from "../../reports/RadReportsPatOrderInput";

import log from "../../../services/log";
import { getRadOrderList } from "../../../redux/labs/labs.actions";

export function RadReportsListUSR() {
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const reps_list = useSelector((state) => state.labs.radlabsorderlist);
  const presorderlist = useSelector((state) => state.labs.presorderlist);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [selectedrows, SetSelectedrows] = useState([]);
  const [action, setAction] = useState("");
  const [checkedRows, setCheckedRows] = useState(null);

  const dispatch = useDispatch();

  const buildQuery = () => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata["patientId"] = user.metadata.accountId;
  };

  useEffect(() => {
    if (user) {
      dispatch(getRadOrderList(buildQuery())).then((r) => {
        if (r.results) {
          log.info("radilogylist", r);
          setPage({ ...pagedata, pages: r.totalPages, count: r.totalResults });
        }
      });
    }
  }, [user]);

  const handleCancel = () => {
    setAction("");
  };

  const handlePost = () => {};

  const handleRemove = () => {};

  const handleReset = () => {};

  const handleRequest = () => {
    setAction("order");
  };

  const handleRequestList = () => {
    setAction("requestList");
  };

  log.info("reportslistusr", pagedata);

  return (
    <Grid container>
      <Grid item xs>
        <div className="clear"></div>
        {action === "order" ? (
          <div>
            <RadReportsPatOrderInput
              cancel={handleCancel}
              doctor={user}
              // patient   = {patient}
              report={checkedRows}
            />
          </div>
        ) : action === "requestList" ? (
          <div>
            <div className="colfr0">
              <MyTooltipButtons
                onClick={handlePost}
                btnKey={"post"}
                btnLabel={"Post"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleReset}
                btnKey={"reset"}
                btnLabel={"Reset"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleRemove}
                btnKey={"remove"}
                btnLabel={"Remove selected"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleCancel}
                btnKey={"cancel"}
                btnLabel={"Cancel"}
                enState={false}
              />
            </div>

            <RadReportsOrderList
              handleClose={handleCancel}
              reportlist={presorderlist}
            />
          </div>
        ) : (
          <div>
            <div className="colfr0">
              <MyTooltipButtons
                onClick={handleRequestList}
                btnKey={"requestlist"}
                btnLabel={"Request list"}
                enState={false}
              />
              {/* SAJAL commented it being metauser */}
              {/*
                                &nbsp; &nbsp;

                                <MyTooltipButtons 
                                    onClick    = {handleRequest}
                                    btnKey     = {"request"}
                                    btnLabel   = {"Request"}
                                    enState    = {false} 
                                />
                                */}
            </div>

            <RadReportsList
              reportlist={reps_list}
              selectedRows={selectedrows}
              selectRows={SetSelectedrows}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
