import { ExtensionManager, MODULE_TYPES } from "./extensions";
import { ServicesManager } from "./services";
import classes, { CommandsManager, HotkeysManager } from "./classes";

import DICOMWeb from "./DICOMWeb";
import errorHandler from "./errorHandler.js";
import log from "../../../services/log.js";
import object from "./object.js";
import string from "./string.js";
import user from "./user.js";
import utils from "./utils";
import defaults from "./defaults";
import * as Types from "./types";
import metadata from "./classes/metadata/";
import reduxdicom from "./redux/";
import measurements from "./measurements";
import str2ab from "./utils/str2ab";
import DICOMSR from "./DICOMSR";
import metadataProvider from "./classes/MetadataProvider";
import studies from "./studies";

import {
  CineService,
  UIDialogService,
  UIModalService,
  UINotificationService,
  UIViewportDialogService,
  //
  DicomMetadataStore,
  DisplaySetService,
  ToolBarService,
  MeasurementService,
  ViewportGridService,
  HangingProtocolService,
  pubSubServiceInterface,
  UserAuthenticationService,
  SegmentationService,
  LoggerService,
} from "./services";

import IWebApiDataSource from "./DataSources/IWebApiDataSource";
import cornerstone from "./cornerstone";

const hotkeys = {
  ...utils.hotkeys,
  defaults: { hotkeyBindings: defaults.hotkeyBindings },
};

const OHIF = {
  MODULE_TYPES,
  //
  CommandsManager,
  ExtensionManager,
  HotkeysManager,
  ServicesManager,
  //
  defaults,
  reduxdicom,
  utils,
  hotkeys,
  classes,
  string,
  metadata,
  user,
  errorHandler,
  object,
  log,
  DICOMWeb,
  DICOMSR,
  viewer: {},
  measurements,
  metadataProvider,
  cornerstone,
  //
  CineService,
  UIDialogService,
  UIModalService,
  UINotificationService,
  UIViewportDialogService,
  DisplaySetService,
  MeasurementService,
  ToolBarService, // TODO: TYPO
  ViewportGridService,
  HangingProtocolService,
  UserAuthenticationService,
  SegmentationService,
  IWebApiDataSource,
  DicomMetadataStore,
  pubSubServiceInterface,
  LoggerService,
  studies,
};

export {
  MODULE_TYPES,
  //
  CommandsManager,
  ExtensionManager,
  HotkeysManager,
  ServicesManager,
  //
  defaults,
  utils,
  reduxdicom,
  hotkeys,
  classes,
  string,
  user,
  errorHandler,
  object,
  log,
  DICOMWeb,
  DICOMSR,
  metadata,
  measurements,
  metadataProvider,
  cornerstone,
  //
  CineService,
  UIDialogService,
  UIModalService,
  UINotificationService,
  UIViewportDialogService,
  DisplaySetService,
  MeasurementService,
  ToolBarService,
  ViewportGridService,
  HangingProtocolService,
  UserAuthenticationService,
  SegmentationService,
  IWebApiDataSource,
  DicomMetadataStore,
  pubSubServiceInterface,
  Types,
  str2ab,
  LoggerService,
  studies,
};

export { OHIF };

export default OHIF;
