import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import log from "../../../services/log";
//import LabSetting from "./LabSetting";

export default function Settings() {
  return <Grid container></Grid>;
}
