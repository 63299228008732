import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";

import { getPatientDrugs } from "../../../redux/drugs/drugs.actions";

import { MedicineList } from "../../medicine/MedicineList";
//import {MedicineInput}                           from "../../medicine/MedicineInput";
import { MedicineOrderList } from "../../medicine/MedicineOrderList";
import { MedicinePatOrderInput } from "../../medicine/MedicinePatOrderInput";

import { MyTooltipButtons } from "../../CustomComponents/MyButtons";
import log from "../../../services/log";

export function MedicineListUSR() {
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const presorderlist = useSelector((state) => state?.drugs?.presorderlist);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [action, setAction] = useState("");
  const [checkedRows, setCheckedRows] = useState(null);

  const dispatch = useDispatch();

  const handleCancel = () => {
    setAction("");
  };

  const handlePost = () => {};

  const handleRemove = () => {};

  const handleReset = () => {};

  const handleRequest = () => {
    setAction("order");
  };

  const handleRequestList = () => {
    setAction("requestList");
  };

  log.info("medicinelistusr", pagedata);

  return (
    <Grid container>
      <Grid item xs>
        <div className="clear"></div>
        {action === "order" ? (
          <div>
            <MedicinePatOrderInput
              cancel={handleCancel}
              doctor={user}
              // patient   = {patient}
              medicine={checkedRows}
            />
          </div>
        ) : action === "requestList" ? (
          <div>
            <div className="colfr0">
              <MyTooltipButtons
                onClick={handlePost}
                btnKey={"post"}
                btnLabel={"Post"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleReset}
                btnKey={"reset"}
                btnLabel={"Reset"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleRemove}
                btnKey={"remove"}
                btnLabel={"Remove selected"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleCancel}
                btnKey={"cancel"}
                btnLabel={"Cancel"}
                enState={false}
              />
            </div>

            <MedicineOrderList
              handleClose={handleCancel}
              medicinelist={presorderlist}
            />
          </div>
        ) : (
          <div>
            <div className="colfr0">
              <MyTooltipButtons
                onClick={handleRequestList}
                btnKey={"requestlist"}
                btnLabel={"Request list"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleRequest}
                btnKey={"request"}
                btnLabel={"Request"}
                enState={false}
              />
            </div>

            <MedicineList />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
