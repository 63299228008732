//metauser
import * as React from "react";
import { Grid, Box } from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { format } from "date-fns";
import { ReportChartHeader } from "./ReportChartHeader";
import { testList } from "../../tests/TestData";
import { ChartFormat } from "../charts/ChartFormat";
import log from "../../services/log";
import { datac as datatest } from "../../tests/Data_LC"; //orig data source
import { colChart, defDataPeriod } from "../common/ConstantsAndValues";

const tDate = new Date(); // Date.now(); //new Date();

const formDate = (d) => {
  //d.setMonth(d.getMonth() - defDataPeriod.minMonth);
  d.setFullYear(d.getFullYear() - defDataPeriod.minYear);
  return d;
};

const fDate = formDate(tDate);
//format(fromDate, 'yyyy-MM-dd')

function formatDate(d) {
  let v = new Date(d);
  if (dateIsValid(v) == false) {
    return d;
  }
  v = format(v, "MM-dd-yyyy");
  return v;
}

function formatDateYMD(d) {
  let v = new Date(d);
  if (dateIsValid(v) == false) {
    return d;
  }
  v = format(v, "yyyy-MM-dd");
  return v;
}
function dateIsValid(d) {
  return d instanceof Date && !isNaN(d);
}

export const ChartContainer = (props) => {
  const [markDates, dateTest, auth, eventType, page] = useOutletContext();

  //DATA  GETHERE
  let testdata = datatest.items; //becomes data
  //let streamdata = { xlabel: datatest.xlabel,  ylabel: datatest.ylabel ,  color: datatest.color, desc: datatest.desc } ;

  testdata.forEach((i) => {
    i.date = new Date(i.date);
    i.value = Number(i.value);
    i.product = i.product;
  });

  const reports = useMemo(() => testList.kdfp);

  const [reportSet, setReportSet] = useState(reports);
  const [chartType, setChartType] = useState("line");
  const [selectedChart, setSelectedChart] = useState(null);
  const [fromDate, setFromDate] = useState(formatDate(formDate(new Date())));
  const [toDate, setToDate] = useState(formatDate(new Date()));
  const [data, setData] = useState(null);

  useEffect(() => {
    if (data && toDate && fromDate) {
      const to = new Date(toDate);
      const from = new Date(fromDate);
      const fdata = data.filter((d) => d.date >= from && d.date <= to);
      setData(fdata);
    } else setData(data);
  }, [toDate, fromDate]);

  const selectReport = (report) => {
    log.info("select data stream ", report.length);

    if (report.length > 0) {
      setSelectedChart(report);
      setData(testdata);
    } else {
      setSelectedChart(null);
      setData(null);
    }
  };

  const fromDateCallback = (date) => {
    setFromDate(formatDate(date));
  };

  const toDateCallback = (date) => {
    setToDate(formatDate(date));
  };

  console.log("reports", selectedChart);

  return (
    <Grid p={0} mb={2} container>
      <Grid item xs={12}>
        <ReportChartHeader
          fromDateCallback={fromDateCallback}
          toDateCallback={toDateCallback}
          fromDate={formatDateYMD(fromDate)}
          toDate={formatDateYMD(toDate)}
          selectReport={selectReport}
          tests={reportSet}
          page={page}
        />
      </Grid>

      <Grid
        pt={1}
        pb={1}
        item
        xs={12}
        sx={{
          display: "block",
          justifyContent: "center",
          flexDirection: "column",
          maxHeight: colChart.groupHeight,
          width: "auto",
          overflowX: "hidden",
          overflowY: "scroll",
        }}
      >
        {selectedChart?.map((dchart, index) => (
          <Box
            key={"cc" + index}
            sx={{
              margin: "8px 0px",
              width: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div />
            <ChartFormat
              chartType={chartType}
              selectedChart={dchart}
              fromDate={formatDate(fromDate)}
              toDate={formatDate(toDate)}
              data={data?.filter((d) => d?.product == dchart?.value)}
              streamdata={reportSet?.labs?.filter(
                (d) => d?.link == dchart?.value
              )}
              page={page}
            />
            <div className="clear"></div>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default ChartContainer;
