import * as React from "react";
import {
  StyledTableCellHead,
  StyledTableRowHead,
} from "../../common/ConstantsAndValues";

export const patientListTable = {
  title: {
    label: "Organization",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  date: {
    label: "Associated since",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
};

export function PatientListMDHead() {
  const table = patientListTable;

  return (
    <React.Fragment>
      <StyledTableRowHead style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead>{table.title.label}</StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.date.label}
        </StyledTableCellHead>
      </StyledTableRowHead>
    </React.Fragment>
  );
}
