import React, { useEffect, useState } from "react";

const YoutubeIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})`, marginTop: "3px" }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <path
        d="M11.159 13.9782L7.09678 13.9023C5.78153 13.8758 4.46301 13.9286 3.17355 13.6546C1.21198 13.2451 1.07302 11.2372 0.927609 9.55291C0.727247 7.18507 0.804813 4.77425 1.18292 2.42618C1.39638 1.10866 2.23641 0.322489 3.53549 0.236939C7.92084 -0.0735363 12.3354 -0.0367417 16.711 0.108106C17.1731 0.121385 17.6384 0.19396 18.0941 0.276571C20.3434 0.679489 20.3982 2.95489 20.544 4.87034C20.6895 6.80557 20.6281 8.75072 20.3501 10.6728C20.1272 12.2642 19.7005 13.5987 17.9002 13.7275C15.6444 13.896 13.4404 14.0316 11.1783 13.9885C11.1784 13.9782 11.1654 13.9782 11.159 13.9782ZM8.77079 9.94924C10.4707 8.95183 12.1382 7.97104 13.8283 6.98032C12.1253 5.9829 10.461 5.00212 8.77079 4.01139V9.94924Z"
        fill="#19445E"
      />
    </svg>
  );
};
export default YoutubeIcon;
