import axios from "axios";
import { API_URL } from "./Constants";
import log from "./log";
export const axiosApiInstance = axios.create();

let requestsToRefresh = [];
let isRefreshRequesting = false;

const clearToken = () => {
  localStorage.removeItem("tokens");
};

const currentToken = async () => {
  const value = await localStorage.getItem("tokens");
  return value !== undefined;
};

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const value = localStorage.getItem("tokens");
    const keys = JSON.parse(value);
    // log.info("axios instance", keys);

    if (keys) {
      config.headers = {
        Authorization: `Bearer ${keys.access.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }
    // log.info("axios instance", config);
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const refreshAccessToken = () => {
  const value = localStorage.getItem("tokens");
  const keys = JSON.parse(value);

  // log.info("refreshing token", keys.refresh.token)

  return axios
    .post(API_URL + "auth/refresh-tokens", { refreshToken: keys.refresh.token })
    .then((response) => {
      // log.info("refreshed token ", response.data.access.token)
      if (response.data) {
        localStorage.setItem("tokens", JSON.stringify(response.data));
      }
      requestsToRefresh.forEach((cb) => {
        // log.info("callback token", cb)
        cb(response.data.access.token);
      });
      // return response.data.access.token;
    })
    .catch(() => {
      // clearToken()
      requestsToRefresh.forEach((cb) => cb(null));
    })
    .finally(() => {
      requestsToRefresh = [];
      isRefreshRequesting = false;
    });
};

axiosApiInstance.interceptors.response.use(
  (response) => {
    // log.info("axios respone");
    return response;
  },
  async function (error) {
    // log.info("axios respone", error);
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      // log.info("response 401", originalRequest)

      if (!currentToken()) {
        log.info("checking current token");
        return Promise.reject(error);
      }

      if (!isRefreshRequesting) {
        isRefreshRequesting = true;
        originalRequest._retry = true;
        refreshAccessToken();
        // const access_token = await refreshAccessToken();
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' +  access_token;
        // return axiosApiInstance(originalRequest);
      }

      // log.info("queued jobs", requestsToRefresh);
      return new Promise((resolve, reject) => {
        requestsToRefresh.push((token) => {
          if (token) {
            originalRequest.headers.Authorization = "Bearer " + token;
            resolve(axiosApiInstance(originalRequest));
          }

          // If the first request could not update the token, we
          // must return the basic request processing logic
          reject(error);
        });
      });
    }
    return Promise.reject(error);
  }
);
