import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import { getPolicies } from "../../redux/policy/policy.actions";
import { getMetaLabs } from "../../redux/labs/labs.actions";
import { pageOptions, SearchBoxStandard } from "../common/ConstantsAndValues";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import { PolicyListHead } from "./HealthPolicyListHead";
import { PolicyListRow } from "./HealthPolicyListRow";
import PolicyCompare from "./PolicyCompare";
import { PolicyEnrol } from "./PolicyEnrol";
import { MyButtons } from "../CustomComponents/MyButtons";

import log from "../../services/log";

export function HealthPolicyList(props) {
  const user = useSelector((state) => state?.auth?.user?.user);
  const org = useSelector((state) => state?.orgs?.selectorg);
  const auth = useSelector((state) => state?.auth);

  const policylist = useSelector((state) => state?.policies?.policylist);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [selectrows, setSelectrows] = useState([]);
  const [action, setAction] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData["page"] = pagedata.page;
    jsonData["limit"] = pagedata.limit;
    jsonData["sortBy"] = "updated_at";
    dispatch(getPolicies(jsonData)).then((r) => {
      // log.info("policylist", r)
      if (r.results) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  }, [dispatch]);

  useEffect(() => {}, [action]);

  useEffect(() => {
    if (policylist) {
      setRows(policylist);
    }
  }, [policylist]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = policylist.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(policylist);
    }
  };

  const handleChangePage = (event, value) => {
    // log.info("libslist", value);
    // log.info("lablist", pagedata);
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData["page"] = value + 1;
    jsonData["limit"] = pagedata.limit;
    jsonData["sortBy"] = "updated_at";
    dispatch(getMetaLabs(jsonData)).then((r) => {
      log.info("labslist", r);
      setRows(r.results);
      setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
    });
    setPage({ ...pagedata, page: parseInt(value) });
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleSelectRow = (row) => {
    const newlist = selectrows;
    let rowInlist = false;

    newlist.forEach((v) => {
      if (v.id === row.id) {
        rowInlist = true;
      }
    });

    if (rowInlist === false) {
      newlist.push(row);
      setSelectrows(newlist);
    } else {
      const flist = newlist.filter((v) => v.id !== row.id);
      setSelectrows(flist);
    }
  };

  const handleCompare = () => {
    // log.info("policylist");
    setAction("compare");
  };

  const handleBack = () => {
    // log.info("policylist");
    setAction("");
  };

  const handleBuy = () => {
    setAction("enrol");
  };
  // log.info("policylist", selectrows);
  log.info("act", action);

  return (
    <Grid container>
      <Grid item xs>
        <div
          width="100%"
          className="flex-grow-1"
          style={{
            display: "flex",
            justifyContent: "space-between",
            lineHeight: "36px",
            width: "100%",
          }}
        >
          <div>
            <div className="colfl0"></div>

            <div className="colfr0" width="100%">
              <SearchBoxStandard
                onChange={handleFilter}
                placeholder="Policy name"
                sx={{ marginRight: 1 }}
                className="text-color-primary text-color-2-border text-color-2-bg"
              />
            </div>
          </div>
          <div className="colfr">
            {action === "compare" || action === "enrol" ? (
              <MyButtons
                onClick={handleBack}
                btnKey={"back"}
                btnLabel={"Back"}
                enState={false}
              />
            ) : (
              <div>
                {auth && auth.isLoggedIn && (
                  <>
                    <MyButtons
                      onClick={handleBuy}
                      btnKey={"enrol"}
                      btnLabel={"Enrol"}
                      enState={false}
                    />
                    &nbsp; &nbsp;
                  </>
                )}
                <MyButtons
                  onClick={handleCompare}
                  btnKey={"compare"}
                  btnLabel={"Compare"}
                  enState={false}
                />
              </div>
            )}
          </div>
        </div>

        {action === "compare" ? (
          <div style={{ width: "100%" }}>
            <PolicyCompare maprows={selectrows} />
          </div>
        ) : action === "enrol" ? (
          <div>
            <PolicyEnrol policy={selectrows} />
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <div>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <PolicyListHead />
                  </TableHead>

                  <TableBody className="text-color-primary text text-md">
                    {rows &&
                      rows.map((row) => (
                        <PolicyListRow
                          key={row.id}
                          row={row}
                          selectedRows={selectrows}
                          selectRow={handleSelectRow}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div style={{ width: "100%" }}>
              <TablePaginationStandard
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                pagedata={pagedata}
              />
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
}
