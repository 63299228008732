import React from "react";
import { LocationList } from "../../location/LocationList";

export const LocationOrg = (props) => {
  const { cancel, title } = props;

  const handleCancel = () => {
    cancel();
  };

  return (
    <div className="flex-grow-1">
      <LocationList cancel={handleCancel} title={title} />
    </div>
  );
};
