import React, { useEffect, useState } from "react";

const TubeIcon = (props) => {
  const { height = "40", width = "36", size, fill = "#5FA7BD" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);
  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Menu">
        <rect
          id="Menu line"
          x="1.4375"
          y="30.1953"
          width="29.1305"
          height="3.49999"
          rx="1.74999"
        />
        <rect
          id="Menu line_2"
          x="12.7188"
          y="18.875"
          width="17.8288"
          height="3.49999"
          rx="1.74999"
          transform="rotate(120 12.7188 18.875)"
        />
        <rect
          id="Menu line_3"
          x="22.4375"
          y="17.125"
          width="17.8288"
          height="3.49999"
          rx="1.74999"
          transform="rotate(60 22.4375 17.125)"
        />
        <rect
          id="Menu line 1"
          x="5.53125"
          y="0.734375"
          width="21.2653"
          height="3.49999"
          rx="1.74999"
        />
        <rect
          id="Menu line 2"
          x="12.4453"
          y="5.47656"
          width="15.2185"
          height="3.49999"
          rx="1.74999"
          transform="rotate(90 12.4453 5.47656)"
        />
        <rect
          id="Menu line 3"
          x="23.3594"
          y="5.47656"
          width="15.2185"
          height="3.49999"
          rx="1.74999"
          transform="rotate(90 23.3594 5.47656)"
        />
        <circle id="Ellipse 12" cx="16.0547" cy="24.6484" r="2.62499" />
      </g>
    </svg>
  );
};
export default TubeIcon;
