export const drugsquery = (field = "name", value = "a", pagedata) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "drugs";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page + 1;
  jsonData.params["query"] = {};
  jsonData.params.query["match_phrase_prefix"] = {};
  jsonData.params.query.match_phrase_prefix = {};
  jsonData.params.query.match_phrase_prefix[field] = {};
  jsonData.params.query.match_phrase_prefix[field]["query"] = value;
  return jsonData;
};

export const otcdrugsquery = (field = "name", value = "a", pagedata) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "drugotc";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page + 1;
  jsonData.params["query"] = {};
  jsonData.params.query["match_phrase_prefix"] = {};
  jsonData.params.query.match_phrase_prefix = {};
  jsonData.params.query.match_phrase_prefix[field] = {};
  jsonData.params.query.match_phrase_prefix[field]["query"] = value;
  return jsonData;
};

export const drugotcpricequery = (field = "name", value = "a", pagedata) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "drugotcprice";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page + 1;
  jsonData.params["query"] = {};
  jsonData.params.query["match_phrase_prefix"] = {};
  jsonData.params.query.match_phrase_prefix = {};
  jsonData.params.query.match_phrase_prefix[field] = {};
  jsonData.params.query.match_phrase_prefix[field]["query"] = value;
  return jsonData;
};

export const drugpricequery = (field = "name", value = "a", pagedata) => {
  let jsonData = {};
  jsonData["params"] = {};
  jsonData["options"] = {};
  jsonData.options["meta"] = true;
  jsonData.params["index"] = "drugprice";
  jsonData.params["size"] = pagedata.limit;
  jsonData.params["from"] = pagedata.page + 1;
  jsonData.params["query"] = {};
  jsonData.params.query["match_phrase_prefix"] = {};
  jsonData.params.query.match_phrase_prefix = {};
  jsonData.params.query.match_phrase_prefix[field] = {};
  jsonData.params.query.match_phrase_prefix[field]["query"] = value;
  return jsonData;
};
