import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Paper,
  Typography,
  Box,
  NativeSelect,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  Collapse,
  IconButton,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import ReplyTwoToneIcon from "@mui/icons-material/ReplyTwoTone";
import ExpandCircleDownTwoToneIcon from "@mui/icons-material/ExpandCircleDownTwoTone";
import AdjustIcon from "@mui/icons-material/Adjust";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";

import { getReportData } from "../../redux/actions/reports";
import { getAlerts, getAlert } from "../../redux/actions/alerts";

import {
  pageOptions,
  tableParam,
  colWidthSign,
} from "../common/ConstantsAndValues";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../common/ConstantsAndValues";

//import ThreeDotMessageReplyForward from "../CustomComponents/ThreeDotMessageReplyForward";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";

import log from "../../services/log";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  table: {
    minWidth: colWidthSign.width,
  },
  Snackbar: {
    bottom: "104px",
  },
});

function Row(props) {
  const { row, reply } = props;
  log.info("alert", row);

  const [open, setOpen] = React.useState(false);
  const [rowdata, setRowData] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(
      getAlert({
        id: row.alertId,
      })
    ).then((r) => setRowData(r));
  }, [row]);

  const handleReply = (e) => {
    console.log(e.target.value);
  };

  const dateFormat = (v) => {
    return new Date(v).toLocaleDateString();
  };

  return (
    <React.Fragment>
      {rowdata && (
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell width="11px" align="left">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <AdjustIcon /> : <ExpandCircleDownTwoToneIcon />}
            </IconButton>
          </StyledTableCell>

          <StyledTableCell component="th" scope="row">
            <Typography variant={"body1"}>
              <span className="text-color-primary-off">
                {rowdata.sourceName}
              </span>
              -
              <span className="text-color-gray">
                {dateFormat(rowdata.updated_at)}
              </span>
            </Typography>
            <Typography variant={"body2"}>
              <b>{rowdata.alertSubject}</b> &nbsp;&nbsp;
              {row.msg}
            </Typography>
          </StyledTableCell>

          {reply && (
            <StyledTableCell>
              <IconButton
                className="text-color"
                aria-label="reply"
                size="small"
                onClick={handleReply}
              >
                <ReplyTwoToneIcon />
                Reply
              </IconButton>
            </StyledTableCell>
          )}
        </StyledTableRow>
      )}

      {rowdata && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {rowdata.alertBody.map((data, i) => (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          <Typography variant={"body2"}>{data}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

// Row.propTypes = {
//     row: PropTypes.shape({
//         calories: PropTypes.number.isRequired,
//         carbs: PropTypes.number.isRequired,
//         fat: PropTypes.number.isRequired,
//         history: PropTypes.arrayOf(
//             PropTypes.shape({
//                 amount: PropTypes.number.isRequired,
//                 customerId: PropTypes.string.isRequired,
//                 date: PropTypes.string.isRequired,
//             }),
//         ).isRequired,
//         name: PropTypes.string.isRequired,
//         price: PropTypes.number.isRequired,
//         protein: PropTypes.number.isRequired,
//     }).isRequired,
// };

export default function MetaAlertTable(props) {
  const { mrows } = props;
  const RowsPPage = tableParam.defLinesPerPage;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(RowsPPage);
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const pagedata = [{ count: mrows.length, limit: rowsPerPage, page: page }];

  return (
    <div>
      <TableContainer
        component={Paper}
        elevation={0}
        style={{ minHeight: tableParam.minHeight }}
      >
        <Table aria-label="simple table">
          <TableBody>
            {!!mrows[0] ? (
              mrows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <Row key={row.id} reply={props.reply} row={row} />
                ))
            ) : (
              <TableRow hover={true}>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <div className="text-color-error">No data found</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/*
            <TablePaginationStandard
                handleChangePage = {handleChangePage}
                handleChangeRows = {handleChangeRowsPerPage}
                pagedata = {pagedata} 
            />
            */}
      <TablePagination
        className="bg-gray border-dbt"
        rowsPerPageOptions={pageOptions}
        component="div"
        count={mrows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        backIconButtonProps={{
          "aria-label": "< Previous",
        }}
        nextIconButtonProps={{
          "aria-label": "Next >",
        }}
      />
    </div>
  );
}
