//COMMON
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { compsystemListQueries } from "../../utils/labsqueries";
import { selectMoreLabRow } from "../../../redux/labs/labs.actions";
import {
  colWidthSign,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import { MyButtons } from "../../CustomComponents/MyButtons";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import { ClinicalMoreTestListRow } from "./ClinicalMoreTestListRow";
import { ClinicalMoreTestListHead } from "./ClinicalMoreTestListHead";
import { getMoreLabsList } from "../../../redux/labs/labs.actions";
import log from "../../../services/log";

const useStyles = makeStyles({
  table: {
    minWidth: colWidthSign.width,
  },
});

export function ClinicalMoreTestList(props) {
  const { handleBack } = props;
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const ctlist = useSelector((state) => state?.labs?.labmorelist);
  const selectlab = useSelector((state) => state?.labs?.labsselectrow);
  const moreselect = useSelector((state) => state?.labs?.moreselect);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);
  const [enableMore, setEnableMore] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (moreselect) {
      const newstate =
        moreselect._source.code.startsWith("LP") === true ? false : true;
      setEnableMore(newstate);
    }
  }, [moreselect]);

  useEffect(() => {
    if (ctlist) {
      setRows(ctlist);
    }
  }, [ctlist]);

  const buildQuery = (paged) => {
    // log.info("ctlist serarch query", searchtext);
    const searchquery = compsystemListQueries(
      "root",
      selectlab ? selectlab?._source?.root : "",
      paged
    );
    return searchquery;
  };

  useEffect(() => {
    // log.info("drug serarch query", searchText);
    const resetpage = { ...pagedata, page: 0 };
    const searchquery = buildQuery(resetpage);
    log.info("ctlist more serarch query", searchquery);
    dispatch(getMoreLabsList(searchquery)).then((r) => {
      log.info("ctmorelist", r);
      if (r?.total?.value && r?.total?.value > 0) {
        setPage({
          ...resetpage,
          count: r?.total?.value,
          pages: r?.total?.value / pagedata?.limit,
        });
      }
    });
  }, [searchcontext, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    const newlist = keyword
      ? ctlist.filter((v) =>
          v._source.name.toLowerCase().startsWith(keyword.toLowerCase())
        )
      : ctlist;
    setRows(newlist);
  };

  const handleChangePage = (event, value) => {
    // log.info("ctlist", value);
    // log.info("ctlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const searchquery = buildQuery(newpagedata);
    // log.info("ctlist nextpage", searchquery);
    dispatch(getMoreLabsList(searchquery)).then((r) => {
      log.info("ctlist more", r);
      if (r.total.value && r.total.value > 0) {
        setPage(newpagedata);
      }
    });
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (row, add) => {
    // log.info("Prescribe", row);
    // log.info("Prescribe", add);

    if (row && add === true) {
      setCheckedRows(true);
      dispatch(selectMoreLabRow(row));
    } else {
      setCheckedRows(false);
      dispatch(selectMoreLabRow(null));
    }
  };

  const handleGroupTests = () => {
    // log.info("moreselect", selectlab)
    if (moreselect && moreselect._source.code.startsWith("LP") == true) {
      const resetpage = { ...pagedata, page: 0 };
      const newroot = moreselect._source.root + "." + moreselect._source.code;
      const newquery = compsystemListQueries("root", newroot, resetpage);
      // log.info("moreselect", newquery);
      dispatch(getMoreLabsList(newquery)).then((r) => {
        if (r.total.value && r.total.value > 0) {
          log.info("moreselect", r);
          setPage({
            ...resetpage,
            count: r.total.value,
            pages: r.total.value / pagedata.limit,
          });
        }
      });
    }
  };

  log.info("ctlist more", searchcontext);

  return (
    <Grid container>
      <Grid item xs>
        <div>
          <div
            className="flex-grow-1"
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "36px",
              width: "100%",
            }}
          >
            <div>
              <div className="colfl0" width="100%">
                <SearchBoxStandard
                  onChange={handleFilter}
                  placeholder="Test Name"
                  sx={{ marginRight: 1 }}
                  className="text-color-primary text-color-2-border text-color-2-bg"
                />
              </div>
            </div>
            <div>
              {/*
                            <MyButtons 
                                onClick    = {handleGroupTests}
                                btnKey     = {"info"}
                                btnLabel   = {"Lab"}
                                enState    = {enableMore} 
                            />
                            &nbsp; &nbsp;
                            */}
              <MyButtons
                onClick={handleBack}
                btnKey={"back"}
                btnLabel={"Back"}
                enState={false}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "16px" }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="collapsible table">
                <TableHead>
                  <ClinicalMoreTestListHead />
                </TableHead>

                <TableBody className="text-color-primary text text-md">
                  {rows &&
                    rows.map((row, index) => (
                      <ClinicalMoreTestListRow
                        key={row._source.code}
                        index={index}
                        row={row}
                        handleRowClick={handleRowClick}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div style={{ width: "100%" }}>
            <TablePaginationStandard
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              pagedata={pagedata}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
