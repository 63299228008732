// import node modules
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// import other modules
import {
  setAppLsmState,
  setAppSidebarOpenState,
} from "../../redux/appstate/appstate.actions";
import { tableParam, StyledButton } from "./ConstantsAndValues";
import { MyArrowButtons } from "../CustomComponents/MyButtons";

export const AdminMenu = ({ menuList }) => {
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const username = user ? user.name : "Visitor";
  return <CommonMenu menuList={menuList} username={username} />;
};

const CommonMenu = ({ username, menuList }) => {
  const lsmstate = useSelector((state) => state.appstate.lsmstate);
  const [openMenu, setOpenMenu] = useState(true);
  const [openHover, setOpenHover] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  const anImg = menuList[0].link.split("/")[1];

  // const ThisLink = "/" + { anImg }.anImg + "/do";

  const location = useLocation();

  const currentPath = location.pathname;

  const navigate = useNavigate();

  const handleChange = (event) => {
    // log.info("sidemenu change  hover", event);
    dispatch(setAppLsmState({ id: event._id }));
    handleCloseHover();
    navigate(event.link);
  };

  const handleCloseHover = (event) => {
    // log.info("sidemenu close", event)
    setAnchorEl(null);
    setOpenHover(false);
    setOpenMenu(false);
    dispatch(setAppSidebarOpenState(false));
  };

  const handleOpenHover = (event) => {
    // log.info("sidemenu hover", event)
    setAnchorEl(event.currentTarget);
    setOpenHover(true);
    setOpenMenu(false);
    dispatch(setAppSidebarOpenState(false));
  };

  const handleClick = (event) => {
    // log.info("sidemenu click", event);

    if (event.active === false) {
      return;
    }

    if (event && event.link) {
      dispatch(setAppLsmState({ id: event._id }));
      navigate(event.link);
    }
  };

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
    dispatch(dispatch(setAppSidebarOpenState(!openMenu)));
  };

  return (
    <>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
            boxShadow: "1px 0px 0px 0 rgba(0,0,0,.1)",
          },
        }}
      >
        <aside className={"sidebar h-100 " + (openMenu ? "open" : "close")}>
          <div
            className="sidebar-section-db sidebar-profile flex pt-2 pb-4 text-center bg-gradient-menu cursor-pointer"
            onClick={() => handleOpenMenu()}
          >
            <div style={{ marginTop: "13px", marginLeft: "10px" }}>
              {openMenu ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </div>
            <div className="text-14 text-center mt-3">
              {openMenu ? "Collapse Categories" : ""}
            </div>
          </div>

          <div className="sidebar-section sidebar-menu p-0">
            <ul id="menu">
              {menuList.map((tmenu) => (
                <li
                  onClick={() => handleClick(tmenu)}
                  key={tmenu._id}
                  // className={
                  //   lsmstate?.id === tmenu._id
                  //     ? "menu-text flex-fill text-color-menu-active"
                  //     : tmenu.active
                  //     ? "menu-text flex-fill text-color-menu"
                  //     : "text-color-gray"
                  // }
                >
                  <StyledButton
                    disabled={!tmenu.active}
                    key={tmenu._id}
                    style={{}}
                    onClick={handleClick}
                    className={
                      tmenu.active
                        ? "menu-text cursor-pointer"
                        : "text-color-gray"
                    }
                    startIcon={tmenu.icon}
                    sx={{
                      fontWeight: currentPath === tmenu.link && "500",
                      backgroundColor: currentPath === tmenu.link && "#eaf4f7",
                      color: currentPath === tmenu.link ? "#202020" : "#585858",
                      boxShadow: "0 !important",
                      outline: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }}
                  >
                    {openMenu ? tmenu.title : ""}
                  </StyledButton>
                </li>
              ))}
            </ul>
          </div>
        </aside>
      </Box>

      <Box
        sx={{ display: { xs: "flex", md: "none" } }}
        className="sidebar h-100"
      >
        <div
          className="icofont-navigation-menu toggleMenu cursor-arrow"
          onClick={handleOpenHover}
        >
          {!openMenu ? "" : ""}
        </div>
        <Menu
          anchorEl={anchorEl}
          id="left-menu"
          open={openHover}
          onClose={handleCloseHover}
          onClick={handleCloseHover}
          PaperProps={{
            elevation: 0,
            sx: {
              paddingLeft: 2,
              paddingRight: 2,
              minWidth: tableParam.hoverWidth,
              overflowY: "scroll",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <Box>
            <MenuItem
              onClick={handleCloseHover}
              style={{
                padding: "4px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span className="bold text-color-primary">DASHBOARD</span>
              <span className="text-color-primary">
                <MyArrowButtons
                  onClick={handleCloseHover}
                  btnKey={"w"}
                  btnLabel={"Close"}
                  enState={false}
                />
              </span>
            </MenuItem>
          </Box>

          <Divider />

          <Box>
            <div className="text-vsm text-color-gray">{username}</div>
            <MenuItem
              onClick={handleCloseHover}
              style={{ paddingLeft: "48px" }}
            ></MenuItem>
          </Box>

          {username && <Divider />}

          {menuList.map((tmenu, i) => {
            {
              /*
                  let thislink        = tmenu.link 
                  const found         = MetaDefaultPages.filter(x => { return x.href === thislink; });
                  if (found.length> 0) { thislink += found[0].default;}
                  */
            }
            return (
              <Box
                key={"bx" + i}
                className={
                  lsmstate?.id === tmenu._id
                    ? "border-active menu-text flex-fill text-color-menu-active"
                    : "menu-text flex-fill text-color-menu"
                }
              >
                <MenuItem
                  disabled={!tmenu.active}
                  key={tmenu.title}
                  value={tmenu.link}
                  onClick={(e) => handleChange(tmenu)}
                  sx={{
                    "&:focus": { outline: "none" },
                  }}
                >
                  <ListItemIcon className="text-color-menu my5">
                    {tmenu.icon}
                  </ListItemIcon>
                  <ListItemText className="text-color-menu my5">
                    <StyledButton
                      display="block"
                      sx={{
                        "&:hover": { backgroundColor: "transparent" },
                        "&:focus": { outline: "none" },
                      }}
                    >
                      {tmenu.title}
                    </StyledButton>
                  </ListItemText>
                </MenuItem>
              </Box>
            );
          })}
        </Menu>
      </Box>
    </>
  );
};
