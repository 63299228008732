import axios from "axios";
import { API_URL } from "./Constants";
import log from "./log";
import { axiosApiInstance } from "./axiosmiddleware";

const registerService = (data) => {
  console.log(data);
  return axios
    .post(API_URL + "auth/register", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

const loginService = (data) => {
  return axios.post(API_URL + "auth/login", data).then((response) => {
    let data = response.data.data;
    if (data.tokens) {
      localStorage.setItem("tokens", JSON.stringify(data.tokens));
    }
    return data;
  });
};

// const logoutService = () => {
//   localStorage.removeItem("user");
// };

const getUserService = (payload) => {
  // return axios.post(API_URL + "users/list", payload)
  // log.info("getusers", payload);
  return axiosApiInstance.post(API_URL + "users/list", payload);
};

const getProfileService = (payload) => {
  return axiosApiInstance.post(API_URL + "profile/list", payload);
};

const updateProfileService = (payload) => {
  return axiosApiInstance.post(API_URL + "profile/update", payload);
};

const setProfileService = (payload) => {
  return axiosApiInstance.post(API_URL + "profile/set", payload);
};

const sendVerificationCodeService = (payload) => {
  // console.log("verification payload", payload);
  return axiosApiInstance.post(API_URL + "auth/verifications-email", payload);
};

const updateUserService = (payload) => {
  // console.log("verification payload", payload);
  return axiosApiInstance.post(API_URL + "users/update", payload);
};

export {
  registerService,
  loginService,
  // logoutService,
  getProfileService,
  updateProfileService,
  setProfileService,
  sendVerificationCodeService,
  updateUserService,
  getUserService,
};
