import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { AppointmentInputFormMD } from "../../appointment/AppointmentInputFormMD";
import InputServiceType from "../../docs/InputServiceType";
import { AppointmentStepperMD } from "../../appointment/AppointmentStepperMD";
import { AppointmentStepper } from "../../appointment/AppointmentStepper";

export default function AddApptMD() {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: "Reason for visit",
      component: (
        <InputServiceType
        // form={appointmentform}
        // disableContinue={setDisableContinue}
        />
      ),
    },
    {
      label: "Verify and submit",
      component: (
        <AppointmentInputFormMD
        // inputform={appointmentform}
        // disableContinue={setDisableContinue}
        />
      ),
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item md={8}>
        {steps[activeStep].component}
      </Grid>

      <Grid item md={4}>
        <AppointmentStepperMD
          // dataFields={appointmentform.values}
          // setActiveNextStep={handleNext}
          // setActiveBackStep={handleBack}
          steps={steps}
          // setDisableContinue={setDisableContinue}
          // disableContinue={disableContinue}
          // handleSubmit={appointmentform.handleSubmit}
        />
      </Grid>
    </Grid>
  );
}
