import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Link,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  Button,
} from "@mui/material";

import { getMetaLabs } from "../../redux/labs/labs.actions";
import {
  getPolicies,
  getPolicySubsList,
} from "../../redux/policy/policy.actions";

import { Title } from "../common/Title";
import { pageOptions, SearchBoxStandard } from "../common/ConstantsAndValues";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";
import { MyButtons } from "../CustomComponents/MyButtons";

import { PolicyListRow } from "./PatientPolicyListRow";
import { PolicyListHead } from "./PatientPolicyListHead";

import log from "../../services/log";

export function PatientPolicyList(props) {
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const org = useSelector((state) => state.orgs.selectorg);

  const subslist = useSelector((state) => state.policies.subslist);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [actionState, setActionState] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setRows(subslist);
  }, []);

  useEffect(() => {
    if (user) {
      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["subscriberId"] =
        user.metadata && user.metadata.accountId;
      jsonData["page"] = pagedata.page;
      jsonData["limit"] = pagedata.limit;
      jsonData["sortBy"] = "updated_at";

      dispatch(getPolicySubsList(jsonData)).then((r) => {
        // log.info("policylist", r)
        if (r.results) {
          setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
        }
      });
    }
  }, [user]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = subslist.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(subslist);
    }
  };

  const handleChangePage = (event, value) => {
    log.info("patientpolicy", value);
    log.info("patientpolicy", pagedata);
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData["page"] = value + 1;
    jsonData["limit"] = pagedata.limit;
    jsonData["sortBy"] = "updated_at";
    // dispatch(getMetaLabs(jsonData)).then((r) => {
    //     log.info("labslist", r)
    //     setRows(r.results);
    //     // setPage({... pagedata,
    //     //     count: r.totalResults,
    //     //     pages: r.totalPages,
    //     // });
    // });
    setPage({ ...pagedata, page: parseInt(value) });
  };
  const handleChangeRows = (event, value) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };
  const handleEdit = () => {
    setActionState("edit");
  };
  const handleUpdate = (e) => {
    setActionState("");
    //update
    console.log("update", e);
  };
  const handleCancel = () => {
    setActionState("");
    console.log("cancel");
  };

  return (
    <Grid container>
      <div
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0"></div>

          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="policy name"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div>
          {actionState === "edit" ? (
            <>
              <MyButtons
                onClick={handleUpdate}
                btnKey={"update"}
                btnLabel={"Update"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyButtons
                onClick={handleCancel}
                btnKey={"cancel"}
                btnLabel={"Cancel"}
                enState={false}
              />
            </>
          ) : (
            <MyButtons
              onClick={handleEdit}
              btnKey={"edit"}
              btnLabel={"Edit"}
              enState={false}
            />
          )}
        </div>
      </div>

      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <PolicyListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {rows &&
                rows.map((row) => (
                  <PolicyListRow
                    key={row.labTitle}
                    row={row}
                    actionState={actionState}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
