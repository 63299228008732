import React, { useEffect, useState } from "react";

export const RefreshIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
    >
      <g clipPath="url(#clip0_1377_23935)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.591 14.0469C17.5938 14.0306 17.597 14.0145 17.6005 13.9984C17.6153 13.9297 17.6357 13.8628 17.6611 13.7983C17.8268 13.378 18.2069 13.0625 18.6663 13.0625C19.2127 13.0625 19.6623 13.5073 19.5919 14.0492C19.5771 14.1633 19.5597 14.2769 19.5398 14.39C19.5293 14.4496 19.5181 14.509 19.5062 14.5683C19.2609 15.7912 18.7188 16.9436 17.9178 17.9196C16.7941 19.2888 15.2304 20.2261 13.4931 20.5716C11.7558 20.9172 9.95248 20.6497 8.39033 19.8147C6.82817 18.9797 5.60386 17.6289 4.926 15.9924C4.24815 14.3559 4.1587 12.5351 4.67288 10.84C5.18707 9.14497 6.27308 7.68066 7.74587 6.69657C9.21867 5.71248 10.9871 5.26951 12.7499 5.44312C13.9271 5.55907 15.0532 5.94521 16.0444 6.56199L15.1069 3.75827C14.9317 3.23449 15.2144 2.6679 15.7382 2.49276C16.2619 2.31762 16.8285 2.60025 17.0037 3.12402L18.666 8.0953C18.6801 8.12651 18.6928 8.15875 18.7039 8.19195C18.879 8.71573 18.5964 9.28232 18.0726 9.45746L12.9925 11.1562C12.4688 11.3313 11.9022 11.0487 11.727 10.5249C11.5519 10.0011 11.8345 9.43453 12.3583 9.25939L15.1365 8.33039C14.3689 7.82149 13.4838 7.50373 12.5559 7.41234C11.2488 7.28359 9.93736 7.61208 8.8452 8.34183C7.75305 9.07159 6.94772 10.1575 6.56642 11.4144C6.18513 12.6714 6.25146 14.0217 6.75413 15.2352C7.25679 16.4487 8.16468 17.4504 9.3231 18.0696C10.4815 18.6888 11.8188 18.8872 13.1071 18.6309C14.3954 18.3747 15.5549 17.6796 16.3882 16.6643C17.011 15.9055 17.4227 15.0028 17.591 14.0469Z"
          fill="#404040"
        />
      </g>
      <defs>
        <clipPath id="clip0_1377_23935">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
