import React, { useEffect, useState } from "react";

export const PlusIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})`, marginTop: "3px" }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="-6.10352e-05" y="11" width="24" height="2" rx="1" fill={fill} />
      <rect
        x="10.9999"
        y="24"
        width="24"
        height="2"
        rx="1"
        transform="rotate(-90 10.9999 24)"
      />
    </svg>
  );
};
