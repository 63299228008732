import { styled } from "@mui/material/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import Divider from "@material-ui/core/Divider";
import ArrowCircleLeftTwoToneIcon from "@mui/icons-material/ArrowCircleLeftTwoTone";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import useStyles from "../CustomComponents/useStyles";
import log from "../../services/log";
import { useDispatch } from "react-redux";
import { docQueries } from "../utils/docqueries";
import { getDocDomains } from "../../redux/user/user.actions";

const styles = styled("input")({
  type: "search",
  boxShadow: "none",
  height: "26px",
  width: "180px",
  outline: "none",
  fontSize: "14px",
  borderRadius: "10px",
  padding: "3px 5px",
  marginLeft: "15px",
});

export const DocDomainSearch = (props) => {
  const classes = useStyles();
  const [searchtext, setSearchText] = useState("");

  const dispatch = useDispatch();
  const handleSearch = (event) => {
    // log.info("domainsearch", searchtext);
    dispatch(getDocDomains(docQueries("partName", "*" + searchtext + "*")));
  };

  const updateSearchText = (event) => {
    setSearchText(event.target.value);
  };

  // log.info("domainsearch",  searchtext);

  return (
    <Paper className={classes.root} elevation={1}>
      <InputBase
        className={classes.input}
        onChange={updateSearchText}
        placeholder="search medical domain"
      />
      <IconButton
        key={"searchtext"}
        className={classes.iconButton}
        onClick={handleSearch}
        aria-label="Search"
      >
        <SearchTwoToneIcon />
      </IconButton>
      <Divider className={classes.divider} />
      <IconButton
        key={"direction"}
        color="primary"
        className={classes.iconButton}
        aria-label="Directions"
      >
        <ArrowCircleLeftTwoToneIcon />
      </IconButton>
    </Paper>
  );
};

export default withStyles(styles)(DocDomainSearch);
