import React from "react";
import SignUp from "../components/sign/sign-up";

import { Gen400Container } from "../components/common/ConstantsAndValues";
import { Navigate } from "react-router-dom";

export const SignUpPage = ({ auth }) => {
  const isAuthorized =
    auth?.user?.user?.metadata?.role === "metasys" ||
    auth?.user?.user?.metadata?.role === "metauser";
  // console.log(props, "props");
  return isAuthorized ? (
    <Navigate to="/metauser/home" />
  ) : (
    <Gen400Container className="py-5 px-3 px-md-0">
      <SignUp />
    </Gen400Container>
  );
};
