import * as React from "react";

import IconButton from "@mui/material/IconButton";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../../common/ConstantsAndValues";

export const pTable = {
  title: {
    label: "Name",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  drugpack: {
    label: "Packaging",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  mrp: {
    label: "MSRP",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  hsn: {
    label: "HSN Code",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  discount: {
    label: "Discount code",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },

  completedAt: {
    label: "Recorded on",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
};

export function DrugTaxHead() {
  const table = pTable;
  return (
    <React.Fragment>
      <StyledTableRowHead style={{ fontWeight: 700 }}>
        <StyledTableCellHead>{table.title.label}</StyledTableCellHead>
        <StyledTableCellHead>{table.drugpack.label}</StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.mrp.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.hsn.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.discount.label}
        </StyledTableCellHead>
        <StyledTableCellHead />
      </StyledTableRowHead>
    </React.Fragment>
  );
}
