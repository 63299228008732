import React from "react";
import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { stylesAddress } from "../styles/CommonStyles";

import phone from "../../../assets/images/icons/phone.png";
import email from "../../../assets/images/icons/email.png";

const styles = stylesAddress;

export const CommonAddrAddr = ({ invoice, parentAddress, label1, label2 }) => (
  <View style={styles.headContainer}>
    <View style={styles.clientContainer}>
      {label1 && (
        <Text style={styles.clientLabel}>{label1 ? label1 + ":" : ""}</Text>
      )}
      {invoice.name && <Text>{invoice.name}</Text>}
      {invoice.age && invoice.gender && (
        <Text>{"Age: " + invoice.age + " Gender: " + invoice.gender}</Text>
      )}
      {invoice.company && (
        <>
          <Text>{invoice.company}</Text>
          <Text>{invoice.address}</Text>
        </>
      )}
      <Text>{invoice.phone}</Text>
      <Text>{invoice.email}</Text>
    </View>
    <View style={styles.addressContainer}>
      {label2 && (
        <View style={styles.addrContainer}>
          <Text style={styles.addrLabel}>{label2 ? label2 + ":" : ""}</Text>
        </View>
      )}
      {parentAddress.name && (
        <View style={styles.addrContainer}>
          <Text style={styles.name}>{parentAddress.name}</Text>
        </View>
      )}
      {parentAddress.designation && (
        <View style={styles.addrContainer}>
          <Text style={styles.desig}>{parentAddress.designation}</Text>
        </View>
      )}

      <View style={styles.addrContainer}>
        <Text style={styles.addr}>{parentAddress.address}</Text>
      </View>
      <View style={styles.connContainer}>
        <View style={styles.connInnerContainer}>
          <View style={styles.connDetContainer}>
            <Image style={styles.icon} src={phone} />
            <Text style={styles.connDet}>{parentAddress.phone}</Text>
          </View>
          <View style={styles.connDetContainer}>
            <Image style={styles.icon} src={email} />
            <Text style={styles.connDet}>{parentAddress.email}</Text>
          </View>
        </View>
      </View>
    </View>
  </View>
);

export const CommonInvoiceAddr = ({
  invoice,
  parentAddress,
  label1,
  label2,
}) => (
  <View style={styles.headContainer}>
    <View style={styles.clientContainer}>
      {label1 && (
        <Text style={styles.clientLabel}>{label1 ? label1 + ":" : ""}</Text>
      )}
      {invoice.name && <Text>{invoice.name}</Text>}
      {invoice.company && <Text>{invoice.company}</Text>}
      {invoice.age && invoice.gender && (
        <Text>{"Age: " + invoice.age + " Gender: " + invoice.gender}</Text>
      )}
      {invoice.company && <Text>{invoice.address}</Text>}
      <Text>{invoice.phone}</Text>
      <Text>{invoice.email}</Text>
    </View>
    <View style={styles.addressContainer}>
      {label2 && (
        <View style={styles.addrContainer}>
          <Text style={styles.addrLabel}>{label2 ? label2 + ":" : ""}</Text>
        </View>
      )}
      {parentAddress.name && (
        <View style={styles.addrContainer}>
          <Text style={styles.name}>{parentAddress.name}</Text>
        </View>
      )}
      {parentAddress.designation && (
        <View style={styles.addrContainer}>
          <Text style={styles.desig}>{parentAddress.designation}</Text>
        </View>
      )}

      <View style={styles.addrContainer}>
        <Text style={styles.addr}>{parentAddress.address}</Text>
      </View>
      <View style={styles.connContainer}>
        <View style={styles.connInnerContainer}>
          <View style={styles.connDetContainer}>
            <Image style={styles.icon} src={phone} />
            <Text style={styles.connDet}>{parentAddress.phone}</Text>
          </View>
          <View style={styles.connDetContainer}>
            <Image style={styles.icon} src={email} />
            <Text style={styles.connDet}>{parentAddress.email}</Text>
          </View>
        </View>
      </View>
    </View>
  </View>
);
