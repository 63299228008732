export const POLICY_DATA = [
  {
    id: "PP1",
    policy_no: "ITC001X20230125",
    policy_date: "",
    policy_start_date: "",
    policy_end_date: "",
    policy_holder: "P001",
    policy_holder_name: "R Ramkumar",
    provider: "ABC",
    plan: "Premium",
    members: [
      {
        id: 10,
        masterid: "PP1",
        memberid: "R001",
        name: "R RamKumar",
        relation: "self",
        age: "55",
        gender: "M",
        previous_ailment: "",
      },
      {
        id: 1,
        masterid: "PP1",
        memberid: "R002",
        name: "R K Charan",
        relation: "father",
        age: "76",
        gender: "M",
        previous_ailment: "",
      },
      {
        id: 2,
        masterid: "PP1",
        memberid: "R003",
        name: "MalatiDevi",
        relation: "mother",
        age: "70",
        gender: "F",
        previous_ailment: "",
      },
      {
        id: 3,
        masterid: "PP1",
        memberid: "R004",
        name: "Radha",
        relation: "wife",
        age: "50",
        gender: "F",
        previous_ailment: "",
      },
      {
        id: 4,
        masterid: "PP1",
        memberid: "R005",
        name: "Smita",
        relation: "aunt",
        age: "55",
        gender: "F",
        previous_ailment: "",
      },
    ],
    insurer: "CS001",
    instruction: "",
    status: "approved",
    currency: "USD",
    policy_terms: "",
    policy_tenure: "5years",
    cover: "5 lakhs",
    premium_amt: "31232.00",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "5 members CI patient",
  },

  {
    id: "PP2",
    policy_no: "WLC00112320230",
    policy_date: "",
    policy_start_date: "",
    policy_end_date: "",
    policy_holder: "P001",
    policy_holder_name: "R K Sarma",
    provider: "ABC",
    plan: "Economy",
    members: [
      {
        id: 1,
        masterid: "PP2",
        memberid: "R001",
        name: "RK SArma",
        relation: "self",
        age: "45",
        gender: "M",
        previous_ailment: "",
      },
      {
        id: 2,
        masterid: "PP2",
        memberid: "R002",
        name: "Radhu Devi",
        relation: "wife",
        age: "40",
        gender: "F",
        previous_ailment: "",
      },
      {
        id: 0,
        masterid: "PP2",
        memberid: "R003",
        name: "Vikram",
        relation: "son",
        age: "2",
        gender: "M",
        previous_ailment: "",
      },
    ],
    insurer: "CS001",
    instruction: "",
    status: "",
    currency: "USD",
    policy_terms: "",
    policy_tenure: "5years",
    cover: "10 lakhs",
    premium_amt: "15530.00",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "N/A",
  },

  {
    id: "PP3",
    policy_no: "CLC00232230123",
    policy_date: "",
    policy_start_date: "",
    policy_end_date: "",
    policy_holder: "P002",
    policy_holder_name: "R K Sarma",
    provider: "ABC",
    plan: "Economy",
    members: [
      {
        id: 1,
        masterid: "PP2",
        memberid: "R001",
        name: "RK SArma",
        relation: "self",
        age: "45",
        gender: "M",
        previous_ailment: "",
      },
      {
        id: 2,
        masterid: "PP2",
        memberid: "R002",
        name: "Radhu Devi",
        relation: "wife",
        age: "40",
        gender: "F",
        previous_ailment: "",
      },
      {
        id: 0,
        masterid: "PP2",
        memberid: "R003",
        name: "Vikram",
        relation: "son",
        age: "2",
        gender: "M",
        previous_ailment: "",
      },
    ],
    insurer: "CS001",
    instruction: "",
    status: "",
    currency: "USD",
    policy_terms: "",
    policy_tenure: "5years",
    cover: "10 lakhs",
    premium_amt: "15530.00",
    paid: false,
    paidDate: "",
    payType: "",
    payMethod: "",
    transactionNo: "",
    remarks: "N/A",
  },
];

export const policies = [
  {
    id: "pp011",
    plan: "New India Mediclaim Policy",
    provider: "The new india assurance co ltd",
    cover: "5lakhs",
    premium: "12345.00",
    claim: "88%",
    policy_tenure: "1year",
    features: [
      "20% NCB per year",
      "modern treatment",
      "loyality benefit",
      "health checkup",
      "renewal for life",
      "booster benefit",
      "cashless facility",
      "pre and post hospitalisation",
      "live healthy benefits",
      "alternative treatments",
      "OPD",
      "critical illness",
      "COVID like plans",
      "tax benefit",
    ],
    hospitals: "1234",
    policyId: "ppp001",
    status: "active",
  },
  {
    id: "pp015",
    plan: "Arogya Sanjeevani Policy",
    provider: "The new india assurance co ltd",
    cover: "10lakhs",
    premium: "22345.00",
    claim: "88%",
    policy_tenure: "5years",
    features: [
      "20% NCB per year",
      "modern treatment",
      "loyality benefit",
      "health checkup",
      "renewal for life",
      "booster benefit",
      "cashless facility",
      "pre and post hospitalisation",
      "live healthy benefits",
      "alternative treatments",
      "OPD",
      "COVID like plans",
      "tax benefit",
    ],
    hospitals: "1234",
    policyId: "ppp001",
    status: "active",
  },

  {
    id: "pp022",
    plan: "New India Premier Mediclaim Policy Plan A",
    provider: "The new india assurance co ltd",
    cover: "15lakhs",
    premium: "22345.00",
    claim: "82%",
    policy_tenure: "2years",
    features: [
      "20% NCB per year",
      "health checkup",
      "renewal for life",
      "booster benefit",
      "cashless facility",
      "pre and post hospitalisation",
      "live healthy benefits",
      "alternative treatments",
      "OPD",
      "critical illness",
      "COVID like plans",
    ],
    hospitals: "1234",
    policyId: "ppp002",
    status: "active",
  },

  {
    id: "pp033",
    plan: "New India Premier Mediclaim Policy Plan A",
    provider: "The new india assurance co ltd",
    cover: "25lakhs",
    premium: "32345.00",
    claim: "78%",
    policy_tenure: "3years",
    features: [
      "10% NCB per year",
      "modern treatment",
      "loyality benefit",
      "health checkup",
      "renewal for life",
      "booster benefit",
      "cashless facility",
      "pre and post hospitalisation",
      "live healthy benefits",
      "tax benefit",
    ],
    hospitals: "1234",
    policyId: "ppp003",
    status: "active",
  },
  {
    id: "pmppb050",
    plan: "New India Premier Mediclaim Policy Plan B",
    provider: "The new india assurance co ltd",
    cover: "50lakhs",
    premium: "42345.00",
    claim: "88%",
    policy_tenure: "5years",
    features: [
      "15% NCB per year",
      "modern treatment",
      "health checkup",
      "renewal for life",
      "booster benefit",
      "cashless facility",
      "pre and post hospitalisation",
      "live healthy benefits",
      "alternative treatments",
      "OPD",
      "critical illness",
      "COVID like plans",
      "tax benefit",
    ],
    hospitals: "1234",
    policyId: "ppp001",
    status: "active",
  },

  {
    id: "pmppb100",
    plan: "New India Premier Mediclaim Policy Plan B",
    provider: "The new india assurance co ltd",
    cover: "100lakhs",
    premium: "42345.00",
    claim: "88%",
    policy_tenure: "5years",
    features: [
      "15% NCB per year",
      "modern treatment",
      "health checkup",
      "renewal for life",
      "booster benefit",
      "cashless facility",
      "pre and post hospitalisation",
      "live healthy benefits",
      "alternative treatments",
      "OPD",
      "critical illness",
      "COVID like plans",
      "tax benefit",
    ],
    hospitals: "1234",
    policyId: "ppp001",
    status: "active",
  },
];
