export const locsActionTypes = {
  ADDRESS_DATA: "ADDRESS_DATA",
  SEARCH_REGIONS: "SEARCH_REGIONS",
  SEARCH_SUBREGIONS: "SEARCH_SUBREGIONS",
  SEARCH_REGIONDATA: "SEARCH_REGIONDATA",
  SEARCH_COUNTRIES: "SEARCH_COUNTRIES",
  SEARCH_AREAS: "SEARCH_AREAS",
  SELECTED_REGION: "SELECTED_REGION",
  SELECTED_SUBREGION: "SELECTED_SUBREGION",
  SELECTED_COUNTRY: "SELECTED_COUNTRY",
  SELECTED_AREA: "SELECTED_AREA",
  DEVICEINFO: "DEVICEINFO",
  ADDRESS_AUTO: "ADDRESS_AUTO",
  ADDRESSBYID_AUTO: "ADDRESSBYID_AUTO",
  ADDRESS_AUTOCOMPLETE: "ADDRESS_AUTOCOMPLETE",
  ADDRESS_NEW: "ADDRESS_NEW",
  ADDRESS_ANCHOR: "ADDRESS_ANCHOR",
};
