import commandsModule from "./commandsModule";

export default {
  id: "generic-viewer-commands",
  get version() {
    return window.version;
  },
  getCommandsModule({ commandsManager }) {
    return commandsModule({ commandsManager });
  },
};
