import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

//import {MDSpecialitySelect} from "../../CustomComponents/MDSpeciality";
//import {DepartmentSelect} from "../../CustomComponents/DepartmentSelect";
//import GroupInput from "../../forms/GroupInput";
//import OrgInput from "../../forms/OrgInput";
//import OrgAddPatient from "./OrgAddPatient";

import LabComponentView from "../../CustomComponents/LabComponentBySystem";

import MetaUserSearch from "../../search/MetaUserSearch";

export default function DeptManageORG() {
  return (
    <Grid container>
      {/*<OrgAddPatient/>*/}
      {/*<OrgInput/>*/}
      {/*<GroupInput/>*/}
      {/*<DepartmentSelect/>*/}
      {/*<MDSpecialitySelect/>*/}

      <LabComponentView />
      <MetaUserSearch title={"Add Guest"} />
    </Grid>
  );
}
