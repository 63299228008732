import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
export const colLoc = { width: 360, height: 100 };

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  ".MuiInputBase-root": {
    marginTop: 0,
    height: "60px",
  },
}));

export const LocMSStyles = {
  container: (styles) => ({ ...styles, width: colLoc.width }),
  option: (styles) => ({ ...styles }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: colLoc.width,
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid lightgray",
    boxShadow: "none",
  }),
};

export const AutoMSStyles = {
  container: (styles) => ({
    ...styles,
    width: colLoc.width,
    height: colLoc.height,
  }),
  option: (styles) => ({ ...styles }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: colLoc.width,
    maxHeight: colLoc.height,
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid lightgray",
    boxShadow: "none",
  }),
};

export const DialogMSStyles = {
  container: (styles) => ({ ...styles, width: "sm" }),
  option: (styles) => ({ ...styles }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "sm",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid lightgray",
    boxShadow: "none",
  }),
};
