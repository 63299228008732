import { failure, success } from "../../utils/redux";
import { FeedbackActionTypes } from "./feedback.types";

const INITIAL_STATE = {
  imageFile: null,
  isUploadingImage: false,
  isLoading: false,
};

const feedbackReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FeedbackActionTypes.UPLOAD_FEEDBACK_IMAGE:
      return {
        ...state,
        isUploadingImage: true,
      };

    case success(FeedbackActionTypes.UPLOAD_FEEDBACK_IMAGE):
      return {
        ...state,
        imageFile: action.payload,
        isUploadingImage: false,
      };

    case failure(FeedbackActionTypes.UPLOAD_FEEDBACK_IMAGE):
      return {
        ...state,
        isUploadingImage: false,
      };

    case FeedbackActionTypes.RESET_IMAGE:
      return {
        ...state,
        imageFile: null,
        isUploadingImage: false,
      };

    case FeedbackActionTypes.ADD_NEW_FEEDBACK:
      return {
        ...state,
        isLoading: true,
      };

    case success(FeedbackActionTypes.ADD_NEW_FEEDBACK):
      return {
        ...state,
        isLoading: false,
      };

    case failure(FeedbackActionTypes.ADD_NEW_FEEDBACK):
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
export default feedbackReducer;
