import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import BoldButton from "../Buttons/BoldButton";
import ItalicButton from "../Buttons/ItalicButton";
import UnderlinedButton from "../Buttons/UnderlinedButton";
import StrikethroughButton from "../Buttons/StrikethroughButton";
import CodeButton from "../Buttons/CodeButton";
import BulletedListButton from "../Buttons/BulletedListButton";
import NumberedListButton from "../Buttons/NumberedListButton";
import LinkButton from "../Buttons/LinkButton";

/**
 * Toolbar that appears on the top of the editor.
 *
 * It accepts any content as children. If no children are set it displays by default the following buttons:
 * Bold, italic, underline, strike through, code, bulleted list and numbered list
 */
export default function Toolbar({ children, className, ...props }) {
  return (
    <Box
      className="bg-standard-offwhite border-dbb sticky"
      sx={{
        borderRadius: 1,
        fontWeight: "light",
        margin: 1 / 4,
        padding: 1 / 4,
      }}
    >
      {!children && (
        <React.Fragment>
          <BoldButton />
          <ItalicButton />
          <UnderlinedButton />
          <StrikethroughButton />
          {/*<CodeButton />*/}
          <BulletedListButton />
          <NumberedListButton />
          {/*<LinkButton />*/}
        </React.Fragment>
      )}
      {children && <React.Fragment>{children}</React.Fragment>}
    </Box>
  );
}
