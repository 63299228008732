import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import QueryMedicalPolicies from "../../insurance/QueryMedicalPolicies";

export default function InsuInfoORG() {
  return (
    <Grid container>
      <QueryMedicalPolicies />
    </Grid>
  );
}
