import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTable } from "../styles/DiagnosticsStyles";

import { RTestsTableHeader } from "./RTestsTableHeader";
import { RTestsTableRows, RTestsTableRowsBlank } from "./RTestsTableRows";

const styles = stylesTable;

export const RTestsItemsTable = ({ diags, tableRowsCount }) => (
  <View style={styles.tableContainer}>
    <RTestsTableHeader />
    <RTestsTableRows items={diags} />
    {tableRowsCount !== 0 && (
      <RTestsTableRowsBlank rowsCount={tableRowsCount - diags.length} />
    )}
  </View>
);
