import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  PROFILE_DATA,
  EMAILVERIFY,
} from "../actions/types";

// const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  isLoggedIn: false,
  user: null,
  profile: null,
  verify: null,
};

export default function AuthReducer(state = initialState, action) {
  const { type, payload } = action;
  // console.log("login", payload);
  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        profile: null,
      };
    case PROFILE_DATA:
      return {
        ...state,
        profile: payload,
      };
    case EMAILVERIFY:
      return {
        ...state,
        verify: payload,
      };

    default:
      return state;
  }
}
