import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { MetaInvoiceViewer } from "../../pdfdoc/MetaInvoiceViewer";

export default function CreatePdfORG() {
  return (
    <Grid container>
      <MetaInvoiceViewer />
    </Grid>
  );
}
