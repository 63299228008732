import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTableRow } from "../styles/InsuranceStyles";

const styles = stylesTableRow;

export const InsuranceDetailTableRows = ({ items }) => {
  return (
    <>
      {items &&
        items.map((item) => (
          <View style={styles.row} key={"r" + item.id.toString()}>
            <Text style={styles.memberid}>{item.memberid}</Text>
            <Text style={styles.member}>{item.name}</Text>
            <Text style={styles.relation}>{item.relation}</Text>
            <Text style={styles.detail}>
              {item.gender} {item.age}
            </Text>
          </View>
        ))}
    </>
  );
};

export const InsuranceDetailTableRowsBlank = ({ rowsCount }) => {
  const blankRows = Array(rowsCount).fill(0);
  const rows = blankRows.map((x, i) => (
    <View style={styles.row} key={`BR${i}`}>
      <Text style={styles.memberid}>&nbsp;</Text>
      <Text style={styles.member}>&nbsp;</Text>
      <Text style={styles.relation}>&nbsp;</Text>
      <Text style={styles.detail}>&nbsp;</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};
