import * as React from "react";
import {
  StyledTableRow,
  StyledTableRowHead,
  StyledTableCellHead,
  StyledTableCell,
} from "../common/ConstantsAndValues";

export const qTable = {
  title: {
    label: "Title",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  topic: {
    label: "Topic",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  process: {
    label: "Resource Description",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  use: {
    label: "Reference",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
};

export function RListHead() {
  const table = qTable;

  return (
    <React.Fragment>
      <StyledTableRow style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead>{table.title.label}</StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.topic.label}
        </StyledTableCellHead>
      </StyledTableRow>
    </React.Fragment>
  );
}
