import React, { useEffect, useState } from "react";

export const BurgerIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})`, marginTop: "10px" }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="menu">
        <rect id="menuline" y="8" width="24" height="2" rx="1" />
        <rect id="menuline" width="24" height="2" rx="1" />
      </g>
    </svg>
  );
};
