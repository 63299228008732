import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 160,
  },
}));

export default function DatePickers(props) {
  const { toCallBack, fromCallBack, fromDate, toDate } = props;
  //const toDate = new Date();
  const prevYear = () => {
    let d = new Date();
    d.setFullYear(d.getFullYear() - 1);
    return d;
  };

  //const fromDate = prevYear()

  const setToDate = (e) => {
    //console.log('to',e.target.value)
    let val = e.target.value ? e.target.value : toDate;
    toCallBack(val);
  };

  const setFromDate = (e) => {
    //console.log('from',e.target.value)
    let val = e.target.value ? e.target.value : toDate;
    fromCallBack(val);
  };

  const classes = useStyles();

  return (
    <Grid item xs alignItems={"center"}>
      <form noValidate>
        <TextField
          id="dateFrom"
          label="From"
          defaultValue={fromDate}
          type="date"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={setFromDate}
        />

        <TextField
          id="dateTo"
          label="To"
          defaultValue={toDate}
          type="date"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={setToDate}
        />
      </form>
    </Grid>
  );
}
