import React, { useEffect, useRef } from "react";
import { useState, useMemo, useCallback } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { CnParam } from "../common/ConstantsAndValues";
import log from "../../services/log";

import {
  MaterialSlate,
  MaterialEditable,
  createMaterialEditor,
  Toolbar,
  HoveringToolbar,
  defaultHotkeys,
  defaultRenderElement,
  CommentElement,
} from "./index";

export const TextEditor = (props) => {
  const { addTextEditor, addToMsg, isProtect, isColm, isFullHeight } = props;
  let INITIAL_SLATE_VALUE = [];

  if (Array.isArray(addToMsg)) {
    INITIAL_SLATE_VALUE = addToMsg;
  } else {
    INITIAL_SLATE_VALUE = [
      {
        type: "paragraph",
        children: [{ text: addToMsg }],
      },
    ];
  }

  const [value, setValue] = useState(INITIAL_SLATE_VALUE);
  const editor = useMemo(() => createMaterialEditor(props.editorId), []);
  const indicatorColor = isProtect ? "#FDFDFE" : "white";

  const handleChangeNotes = (value) => {
    setValue(value);
    addTextEditor(value);
  };

  // log.info("texteditor", value)

  return (
    <Grid container sx={{ border: "none" }}>
      <Grid item>
        <Typography variant={"body2"}></Typography>
      </Grid>

      <Paper
        style={{
          maxHeight: isFullHeight ? CnParam.maxHeight : CnParam.minHeight,
          minHeight: CnParam.minHeight,
          height: "auto",
          width: "100%",
          overflowX: "hidden",
          background: indicatorColor,
        }}
      >
        <MaterialSlate
          editor={editor}
          value={value}
          onChange={handleChangeNotes}
          className="editor"
        >
          {!isProtect && <Toolbar />}

          <HoveringToolbar />

          <MaterialEditable
            placeholder="Type ..."
            readOnly={isProtect}
            isColm={isColm}
          />
        </MaterialSlate>
      </Paper>
    </Grid>
  );
};
