export const cardData = {
  score: 95, // in range 0 to 100 allowed
  normal: 25, //value normal range starts
  critical: 67, //value critical range starts
  uom: "",
  title: "", //default string is "<PAGE> Risk" eg "CKD Risk"
  date: "November 27, 2022",
  biomark: "Critical biomarkers",
  details: ["Method: AI, Clinical Pathway", "Other info  ...", "TBD"],

  action: [
    {
      _id: "panel1",
      title: "Clinical pathway",
      label: "Actions and referral",
      detail: "PCP actions",
    },
    {
      _id: "panel2",
      title: "Dietary",
      label: "Education and referral",
      detail: "compiled ...",
    },
    {
      _id: "panel3",
      title: "Clinical pathology",
      label: "Actions and referral",
      detail: "Listing recommeded tests and scheduling ...",
    },
    {
      _id: "panel4",
      title: "Personal data",
      label: "",
      detail: "Data collection from personal monitoring devices",
    },
  ],
};
