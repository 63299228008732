// import axios from "axios";
import { API_URL } from "./Constants";
import log from "./log";
import { axiosApiInstance } from "./axiosmiddleware";

const getTestTypes = () => {
  return axiosApiInstance.get(API_URL + "testtypes/");
};

const getAddressService = (searchTerm) => {
  return axiosApiInstance.get(API_URL + "addresses?name=" + searchTerm);
};

const setAddressService = (payload) => {
  return axiosApiInstance.post(API_URL + "addresses/set", payload);
};

const createAppointmentService = (payload) => {
  // console.log("posting", payload)
  return axiosApiInstance.post(API_URL + "privappointments/set", payload);
};

const createProviderAppointmentService = (payload) => {
  // console.log("posting", payload)
  return axiosApiInstance.post(API_URL + "appointments/set", payload);
};

const getTimeSlot = (addressId) => {
  return axiosApiInstance.post(API_URL + "appointments/timeslots/" + 4567);
};

const getAppointmentsService = (payload) => {
  return axiosApiInstance.post(API_URL + "privappointments/list", payload);
};

const getProviderAppointmentsService = (payload) => {
  return axiosApiInstance.post(API_URL + "appointments/list", payload);
};

const getAppointmentmlistService = (payload) => {
  return axiosApiInstance.post(API_URL + "privappointments/mlist", payload);
};

const getProviderAppointmentmlistService = (payload) => {
  return axiosApiInstance.post(API_URL + "appointments/mlist", payload);
};

const getAppointmentService = (payload) => {
  // log.info("fetching appoinment", payload)
  return axiosApiInstance.post(API_URL + "privappointments/get", payload);
};

const getProviderAppointmentService = (payload) => {
  // log.info("fetching appoinment", payload)
  return axiosApiInstance.post(API_URL + "appointments/get", payload);
};

const removeAppointmentService = (payload) => {
  return axiosApiInstance.post(API_URL + "privappointments/remove", payload);
};

const removeProviderAppointmentService = (payload) => {
  return axiosApiInstance.post(API_URL + "appointments/remove", payload);
};

const updateAppointmentService = (payload) => {
  return axiosApiInstance.post(API_URL + "privappointments/update", payload);
};

const updateProviderAppointmentService = (payload) => {
  return axiosApiInstance.post(API_URL + "appointments/update", payload);
};

const deleteApptGuestService = (payload) => {
  return axiosApiInstance.post(API_URL + "guests/delete", payload);
};

const addApptGuestService = (payload) => {
  return axiosApiInstance.post(API_URL + "guests/set", payload);
};
const getApptGuestsService = (payload) => {
  return axiosApiInstance.post(API_URL + "guests/list", payload);
};

const getApptDateSlotsService = (payload) => {
  return axiosApiInstance.post(API_URL + "appointments/slots", payload);
};

const getUserAppointmentsService = (payload) => {
  return axiosApiInstance.post(API_URL + "appointments/list", payload);
};

const getUserAppointmentDates = (payload) => {
  return axiosApiInstance.post(API_URL + "privappointments/slots", payload);
};
const getUserAppointmentTimings = (payload) => {
  return axiosApiInstance.post(API_URL + "appointments/slottimes", payload);
};

export {
  getTestTypes,
  getAddressService,
  setAddressService,
  createAppointmentService,
  getTimeSlot,
  getAppointmentsService,
  removeAppointmentService,
  updateAppointmentService,
  deleteApptGuestService,
  addApptGuestService,
  getApptGuestsService,
  getAppointmentService,
  getAppointmentmlistService,
  getProviderAppointmentsService,
  updateProviderAppointmentService,
  removeProviderAppointmentService,
  createProviderAppointmentService,
  getProviderAppointmentmlistService,
  getProviderAppointmentService,
  getApptDateSlotsService,
  getUserAppointmentsService,
  getUserAppointmentDates,
  getUserAppointmentTimings,
};
