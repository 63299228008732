function createData(id, code, name, typ, catg, status) {
  return { id, code, name, typ, catg, status };
}

function createGroupData(_id, label, icon, link) {
  return { _id, label, icon, link };
}

export const HealthConditionRows = [
  createData(0, "CKD", "Chronic Kidney Disease", "2", 1, 1),
  createData(1, "MS", "Multiple Sclerosis", "1", 1, 0),
  createData(2, "DM", "Diabetes Mellitus", "2", 1, 0),
];

//SOURCE: https://health.ri.gov/diseases/infectious/

export const HealthConditionRowsAll = [
  createData(1000, "AL", "Allergy", 0, 0),

  createData(2000, "MS", "Multiple Sclerosis", 1, 0),

  createData(3000, "AFM", "Acute Flaccid Myelitis", 2, 1),
  createData(3001, "ANAPLAS", "Anaplasmosis", 2, 1),
  createData(3002, "ANTRAX", "Anthrax", 2, 1),
  createData(3003, "AVIAN", "Avian Influenza", 2, 1),

  createData(3050, "BAB", "Babesiosis", 2, 1),
  createData(3051, "BOTUL", "Botulism", 2, 1),
  createData(3052, "BRUCEL", "Brucellosis", 2, 1),

  createData(3101, "CAMPY", "Campylobacteriosis", 2, 1),
  createData(3102, "CRE-CRPA", "Carbapenem-resistant Infection", 2, 1),
  createData(3103, "CHANCROID", "Chancroid", 2, 1),
  createData(3104, "CHIKUNGUNYA", "Chikungunya Virus Infection", 2, 1),
  createData(3105, "CHLAMY", "Chlamydia", 2, 1),
  createData(3106, "COLD", "Common Cold", 2, 1),
  createData(3107, "CDI", "Clostridium Difficile Infection", 2, 1),
  createData(3108, "CKD", "Chronic Kidney Disease", 2, 1),
  createData(3109, "COVID-19", "Coronavirus Disease 2019", 2, 1),
  createData(
    3110,
    "CJD",
    "Creutzfeldt-Jacob Disease, transmissible spongiform encephalopathy",
    2,
    1
  ),
  createData(3111, "CRYPTO", "Cryptosporidiosis", 2, 1),
  createData(3112, "CYC", "Cyclosporiasis", 2, 1),
  createData(3113, "CPL", "Pthiriasis (Crabs; Pubic Lice Infestation)", 2, 1),
  createData(3114, "CHICKPOX", "Varicella (Chickenpox)", 2, 1),
  createData(3115, "CHOLERA", "Vibrio cholerae (Cholera)", 2, 1),

  createData(3152, "DM", "Diabetes Mellitus", 2, 0),
  createData(3154, "DENGUE", "Dengue,1,2,3,4", 2, 1),
  createData(3155, "DIPTHERIA", "Diphtheria", 2, 1),

  createData(3200, "EPSILON", "Clostridium Perfringens", 2, 1),
  createData(3201, "EEE", "Eastern Equine Encephalitis", 2, 1),
  createData(3202, "EBOLA", "Ebola Hemorrhagic Fever", 2, 1),
  createData(3203, "EHR", "Ehrlichiosis", 2, 1),
  createData(3204, "ENCE", "Encephalitis, Arboviral or parainfectious", 2, 1),
  createData(3205, "EVI", "Enterovirus Infection , Non-Polio", 2, 1),
  createData(3206, "EV-D68", "Enterovirus Infection , D68", 2, 1),

  createData(3250, "FUNGAL", "Coccidioidomycosis fungal infection", 2, 1),
  createData(3251, "FLU", "Influenza (Flu)", 2, 1),

  createData(3275, "GIARDIASIS", "Giardiasis", 2, 1),
  createData(3276, "GLANDARS", "Glanders", 2, 1),
  createData(3277, "GONORRHEA", "Gonococcal Infection", 2, 1),
  createData(3278, "GI", "Granuloma inguinale", 2, 1),

  createData(3300, "HABS", "Ciguatera (Harmful Algae Blooms)", 2, 1),
  createData(3301, "HIB", "Haemophilus Influenza disease, Type B", 2, 1),
  createData(3302, "HPS", "Hantavirus Pulmonary Syndrome", 2, 1),
  createData(3304, "HUS", "Hemolytic Uremic Syndrome", 2, 1),
  createData(3305, "HEP-A", "Hepatitis A (Hep A)", 2, 1),
  createData(3306, "HEP-B", "Hepatitis B (Hep B)", 2, 1),
  createData(3307, "HEP-C", "Hepatitis C (HCV)", 2, 1),
  createData(3308, "HEP-D", "Hepatitis D (Hep D)", 2, 1),
  createData(3309, "HEP-E", "Hepatitis E (Hep E)", 2, 1),
  createData(3310, "HERPES", "Herpes", 2, 1),
  createData(3311, "HISTOPLASMOSIS", "Histoplasmosis infection", 2, 1),
  createData(3312, "HIV", "Human Immunodeficiency Virus, AIDS", 2, 1),
  createData(3313, "HPV", "Human Papillomavirus (HPV)", 2, 1),

  createData(3350, "IM", "Infectious mononucleosis", 2, 1),

  createData(3451, "LEAD", "Lead Poisoning", 2, 1),
  createData(3452, "LD", "Legionellosis (Legionnaires Disease)", 2, 1),
  createData(3453, "LHD", "Leprosy (Hansens Disease)", 2, 1),
  createData(3454, "LEP", "Leptospirosis", 2, 1),
  createData(3455, "LISTERIA", "Listeriosis", 2, 1),
  createData(3456, "LYME", "Lyme Disease", 2, 1),
  createData(3457, "LGV", "Lymphogranuloma Venereum infection", 2, 1),
  createData(3469, "LICE", "Pediculosis (Lice, Head and Body)", 2, 1),
  createData(3470, "LTBI", "Tuberculosis (Latent)", 2, 1),

  createData(3508, "MALARIA", "Malaria", 2, 1),
  createData(3509, "MEASLES", "Measles", 2, 1),
  createData(3510, "MELIOIDOSIS", "Melioidosis", 2, 1),
  createData(3511, "MEN-VIRAL", "Meningitis, Viral", 2, 1),
  createData(
    3512,
    "MEN-BACT",
    "Meningococcal Disease , Meningitis Bacterial",
    2,
    1
  ),
  createData(
    3513,
    "MERS-CoV",
    "Middle East Respiratory Syndrome Coronavirus",
    2,
    1
  ),
  createData(3514, "M-POX", "Monkeypox Virus", 2, 1),
  createData(
    3515,
    "MISC",
    "Multisystem Inflammatory Syndrome in Children",
    2,
    1
  ),
  createData(3516, "MUMPS", "Mumps", 2, 1),
  createData(
    3517,
    "MRSA",
    "Staphyloccal Infection , Methicillin-resistant",
    2,
    1
  ),

  createData(3550, "NORO", "Norovirus", 2, 1),

  createData(
    3600,
    "PSPC",
    "Paralytic Shellfish Poisoning (Paralytic Shellfish Poisoning, Ciguatera)",
    2,
    1
  ),
  createData(3601, "PID", "Pelvic Inflammatory Disease", 2, 1),
  createData(3602, "PLAGUE", "Plague; Bubonic, Septicemic, Pneumonic", 2, 1),
  createData(3603, "PNEUMONIA", "Pneumococcal Disease", 2, 1),
  createData(3604, "POLIO", "Poliomyelitis", 2, 1),
  createData(3605, "POW", "Powassan", 2, 1),
  createData(3606, "PARROT", "Psittacosis (Parrot Fever)", 2, 1),
  createData(
    3608,
    "POX",
    "Pustular Rash diseases (Small pox, monkeypox, cowpox)",
    2,
    1
  ),

  createData(3650, "QFEVER", "Q-Fever", 2, 1),

  createData(3660, "RABIES", "Rabies", 2, 1),
  createData(3661, "RSV", "Respiratory Syncytial Virus", 2, 1),
  createData(3662, "RICIN", "Ricin Poisoning", 2, 1),
  createData(
    3663,
    "ROCKY",
    "Rickettsiosis (Rocky Mountain Spotted Fever)",
    2,
    1
  ),
  createData(
    3664,
    "RUBELLA",
    "Rubella, Including congenital (German Measles)",
    2,
    1
  ),
  createData(3665, "RABBIT", "Tularemia (Rabbit fever)", 2, 1),

  createData(3700, "STEC", "E. coli infection, Shiga toxin-producing", 2, 1),
  createData(3705, "SALMONELLA", "Salmonellosis gastroenteritis", 2, 1),
  createData(3706, "SCABIES", "Scabies Infestation", 2, 1),
  createData(3707, "SCOMBROID", "Scombroid", 2, 1),
  createData(3708, "SEPSIS", "Septic Shock", 2, 1),
  createData(3709, "SARS", "Severe Acute Respiratory Syndrome", 2, 1),
  createData(3710, "SHIGELLA", "Shigellosis gastroenteritis", 2, 1),
  createData(3711, "S-POX", "Smallpox", 2, 1),
  createData(
    3713,
    "STAPH",
    "Staphylococcal Food Poisoning, Enterotoxin - B Poisoning (Staph Food Poisoning)",
    2,
    1
  ),
  createData(3714, "STD", "Sexually transmitted diseases", 2, 1),
  createData(
    3716,
    "STREP-AI",
    "Streptococcal Disease , Group A (invasive)",
    2,
    1
  ),
  createData(3717, "STREP-B", "Streptococcal Disease, Group B ", 2, 1),
  createData(
    3718,
    "STSS-TSS",
    "Streptococcal Toxic-Shock Syndrome, STSS, Toxic Shock",
    2,
    1
  ),
  createData(
    3719,
    "SYPHILIS",
    "Syphilis , primary, secondary, early latent, late latent, congenital",
    2,
    1
  ),

  createData(3750, "TETANUS", "Tetanus Infection, tetani Lock Jaw", 2, 1),
  createData(3751, "TRICHOMONAS", "Trichomoniasis (infection)", 2, 1),
  createData(3752, "TRICHINOSIS", "Trichonosis Infection", 2, 1),
  createData(3753, "TB", "Tuberculosis", 2, 1),
  createData(3756, "TYPHOIDD", "Typhoid Fever, Group D", 2, 1),
  createData(3757, "TYPHUS", "Typhus", 2, 1),

  createData(3800, "VZV", "Herpes Zoster, zoster VZV (Shingles)", 2, 1),
  createData(
    3804,
    "VISA",
    "Staphylococcal Infection, Vancomycin Intermediate",
    2,
    1
  ),
  createData(
    3805,
    "VRSA",
    "Staphylococcal Infection, Vancomycin Resistant",
    2,
    1
  ),
  createData(
    3809,
    "VAPING",
    "Vaping-Associated Lung Injury (e-Cigarette Associated Lung Injury)",
    2,
    1
  ),
  createData(
    3808,
    "VAGINOSIS",
    "Vaginosis , bacterial (Yeast Infection)",
    2,
    1
  ),
  createData(3812, "VIBRIO", "Vibriosis", 2, 1),
  createData(
    3813,
    "VIRALHEMOR",
    "Viral Hemorrhagic Fever (Ebola, Lassa, Marburg)",
    2,
    1
  ),

  createData(3831, "WHOOP", "Pertussis (Whooping Cough)", 2, 1),
  createData(3834, "WNILE", "West Nile Virus", 2, 1),
  createData(3866, "YELLOW", "Yellow Fever", 2, 1),
  createData(3867, "YERS", "Yersenia (Yersinia)", 2, 1),
  createData(3908, "ZIKA", "Zika Virus Infection", 2, 1),
];

{
  /* types of dis

Type	Explanation	Example
Anatomic Classification	This type refers to the affected organ or tissue	Heart disease
Topographical Classification	Further classified into types such as vascular disease, chest disease, gastrointestinal disease, and abdominal diseases. These are then handled by specializations in medicine that follow these topographical classifications	An ENT specialist (Ear-Nose-Throat)
A Gastroenterology specialist etc.
Physiological Classification	This type includes diseases that affect a process or a function (such as metabolism, digestion or respiration)	Diabetes
Pathological Classification	This type considers the nature of the disease. For instance, cancer is associated with uncontrolled cell growth, and there are variations or types in the disease.	Neoplastic diseases (uncontrolled cell growth that is characteristic of cancer)
Inflammatory diseases (autoimmunity)
Epidemiological Classification	This classification refers to the rate of occurrence, distribution and the control of the disease in a population.	Epidemic diseases such as the plague and Influenza pandemic of 1918–1919



groups
Degenerative Diseases

*/
}

export const HealthConditionGroups = [
  createGroupData(0, "Allergic Diseases", "", ""),
  createGroupData(1, "Immunologic Diseases", "", ""),
  createGroupData(2, "Infectious Diseases", "", ""),
  createGroupData(3, "Non-infectious Diseases", "", ""),
];
