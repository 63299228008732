// import node modules
import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormLabel,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ThemeTabs } from "../../tabs/ThemeTabs";
import {
  CalendarTableIcon,
  FirstPageIcon,
  LastPageIcon,
  NextPageIcon,
  PreviousPageIcon,
  SearchTableIcon,
} from "../../customIcons/TableIcons";
import moment from "moment";
import Select from "react-select";

import { rswitch } from "../../../utils/helpers";

import { ProfileSection } from "./AppointmentSections/ProfileSection";
import { ReasonSection } from "./AppointmentSections/ReasonSection";
import { ProviderSection } from "./AppointmentSections/ProviderSection";
import { DateTimeSection } from "./AppointmentSections/DateTimeSection";
import { ReviewSection } from "./AppointmentSections/ReviewSection";
import { ScopeIcon, TubeIcon } from "../../customIcons/FindCare";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { WhiteTickIcon } from "../../customIcons/WhiteTickIcon";
import { AppointmentBookedSection } from "./AppointmentSections/AppointmentBookedSection";
import { AccordionCloseIcon } from "../../customIcons/Accordion/AccordionCloseIcon";
import { AccordionOpenIcon } from "../../customIcons/Accordion/AccordionOpenIcon";
import { NotificationToast } from "../../notification/NotificationToast";
import { RoundedCrossBlack } from "../../customIcons/RoundedCrossBlack";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersAppointmentList } from "../../../redux/appointment/appointment.actions";
import { useState } from "react";
import { PlusIcon } from "../../customIcons/PlusIcon";
import useOnClickOutside from "../../../utils/use-on-click-outside";
import { customStylesFilter, formattedArray } from "../../tabs/TabsStaticData";

export const AppointmentsListBody = ({ handleChange }) => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [showFilters, setShowFilters] = useState(false);
  // calling dispatch
  const dispatch = useDispatch();
  // accordion function
  const handleAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [isLoadingUpcomingAppointments, setIsLoadingUpcomingAppointments] =
    useState(false);
  const [upcomingAppointment, setUpcomingAppointment] = useState([]);

  const [isLoadingPastAppointments, setIsLoadingPastAppointments] =
    useState(false);
  const [pastAppointment, setPastAppointment] = useState([]);
  // console.log(pastAppointment);
  const profile = useSelector((state) => state.auth.user.user);

  useEffect(() => {
    getUpcomingAppointments();
    getPastAppointments();
  }, []);

  var date = moment().utc();
  var dateFormat = date.format();

  const getUpcomingAppointments = async () => {
    setIsLoadingUpcomingAppointments(true);
    let params = {
      metadata: { userId: profile?.metadata?.accountId },
      range: { fromDate: dateFormat },
    };
    dispatch(getUsersAppointmentList(params)).then((res) => {
      if (res.code === 200) {
        setUpcomingAppointment(res.data);
        setIsLoadingUpcomingAppointments(false);
      }
    });
  };
  const getPastAppointments = async () => {
    setIsLoadingPastAppointments(true);
    let params = {
      metadata: { userId: profile?.metadata?.accountId },
      range: { toDate: dateFormat },
    };
    dispatch(getUsersAppointmentList(params)).then((res) => {
      if (res.code === 200) {
        setPastAppointment(res.data);
        setIsLoadingPastAppointments(false);
      }
    });
  };
  const dateLabelFilter = [
    {
      label: "13 October Health check-up",
      value: "13 October Health check-up",
    },
    {
      label: "15 October Tests",
      value: "15 October Tests",
    },
  ];

  const appointList = [
    {
      type: "checkup",
      title: "Health check-up",
      description: "In-person consult with Dr. Aamir Khan",
      secondDescrption:
        "165, Tambakuwala, Dr Vigas Street, Charni Road, Mumbai",
      date: "13 October, 2023",
      time: "12:00 pm - 1:00 pm",
    },
    {
      type: "lab",
      title: "Lab visit",
      description: "In-person visit at Dr. Lal PathLabs Ltd.",
      secondDescrption: "C-73/602, Vasant Vihar, Khopat, Thane, Mumbai, India",
      date: "13 October, 2023",
      time: "2:00 pm - 3:00 pm",
    },
  ];

  // outside click for profile dropdown
  const squareBoxRef = React.useRef(null);

  const clickOutsidehandler = (event) => {
    event.stopPropagation();
    setShowFilters(false);
  };
  useOnClickOutside(squareBoxRef, clickOutsidehandler);

  const doctorList = [
    {
      name: "Dr. Aamir Khan",
      address: "C-73/602, Vasant Vihar, Khopat, Thane",
      date: "12 October, 2023",
    },
  ];
  return (
    <Box>
      <Box sx={{ mx: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              color: "#404040",
              fontFamily: "DM SANS",
            }}
          >
            Manage your appointments and visits
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                mt: 1,
                mb: 2,
                mx: 1,
                display: "flex",
                cursor: "pointer",
              }}
            >
              <SearchTableIcon size={0.6} />
              <Typography
                sx={{
                  color: "#585858",
                  fontFamily: "DM SANS",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                Search Appointment
              </Typography>
            </Box>
            {/* <Box
              sx={{
                mt: 1,
                mb: 2,
                mx: 1,
                display: "flex",
                cursor: "pointer",
              }}
            >
              <PlusCircleIcon size={0.7} />
              <Typography
                sx={{
                  color: "#585858",
                  fontFamily: "DM SANS",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                Schedule new
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </Box>

      {/* <NotificationToast
        type="error"
        message="Your upcoming appointment has been cancelled"
      />
      <NotificationToast
        type="warning"
        message="You have a message from your provider that needs attention"
      />
      <NotificationToast
        type="info"
        message="You have a request to change an appointment"
      /> */}
      <Box sx={{ mx: 1 }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleAccordion("panel1")}
          style={{ boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={""}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{ padding: "0" }}
          >
            {expanded === "panel1" ? (
              <AccordionOpenIcon size={0.8} />
            ) : (
              <AccordionCloseIcon size={0.8} />
            )}
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                color: "#404040",
                fontFamily: "DM SANS",
              }}
            >
              Upcoming appointments
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container sx={{ flexGrow: 1 }}>
              {isLoadingUpcomingAppointments && <p>Loading... </p>}
              {upcomingAppointment.results &&
                upcomingAppointment.results.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    key={index}
                    sx={{ px: 1 }}
                  >
                    <Box className={"appointment_card"}>
                      <Grid container sx={{ flexGrow: 1 }}>
                        <Grid item xs={12} sm={1} md={1} lg={1}>
                          {item.type === "checkup" ? (
                            <ScopeIcon size={0.8} />
                          ) : (
                            <TubeIcon size={0.8} />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                          <Box sx={{ mx: 1 }}>
                            <Typography className="capitalize meet_type">
                              {item.eventTitle}
                            </Typography>
                            <Typography className="address_label">
                              {item.eventAddress.locId} with {item.providerName}
                            </Typography>
                            <Typography className="address_full">
                              {item.eventAddress.address}
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                              <Box className="green_label">
                                {moment(item.dateStart).format("DD MMMM, YYYY")}{" "}
                                <CalendarTableIcon size={0.55} />
                              </Box>
                              <Box className="green_label" sx={{ mt: 1 }}>
                                {moment(item.dateStart).format("HH:mm a")} -{" "}
                                {moment(item.dateEnd).format("HH:mm a")}
                                <CalendarTableIcon size={0.55} />
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              cursor: "pointer",
                            }}
                          >
                            <Typography className="view_details">
                              View details
                            </Typography>
                            <ChevronRightIcon htmlColor="#2a8aa7" />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              {upcomingAppointment.results &&
                upcomingAppointment.results.length === 0 && (
                  <Box className="empty_section mt-0">
                    <p className="mb-0">You have no upcoming appointments </p>
                  </Box>
                )}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Divider sx={{ mt: 2, mb: 2 }} />

        <Accordion
          sx={{
            "&:before": {
              display: "none",
            },
          }}
          expanded={expanded === "panel2"}
          onChange={handleAccordion("panel2")}
          style={{ boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={""}
            aria-controls="panel1bh-content"
            id="panel2bh-header"
            style={{ padding: "0" }}
          >
            {expanded === "panel2" ? (
              <AccordionOpenIcon size={0.8} />
            ) : (
              <AccordionCloseIcon size={0.8} />
            )}
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                color: "#404040",
                fontFamily: "DM SANS",
              }}
            >
              Past appointments
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {isLoadingPastAppointments && <p>Loading...</p>}
            {!isLoadingPastAppointments &&
              pastAppointment.results &&
              pastAppointment.results.length === 0 && (
                <Box className="empty_section">
                  <p className="mb-0">There are no past visits</p>
                </Box>
              )}
            {!isLoadingPastAppointments &&
              pastAppointment.results &&
              pastAppointment.results.length > 0 && (
                <>
                  <Box sx={{ mb: 2, position: "relative" }}>
                    <div
                      className="yellowlabel_right"
                      onClick={() => setShowFilters(true)}
                    >
                      <PlusIcon size={0.45} />
                      <p className="mb-0">Add a filter</p>
                    </div>
                    {showFilters && (
                      <div className="filterBox" ref={squareBoxRef}>
                        <Box>
                          <FormLabel
                            sx={{
                              fontSize: "15px",
                              marginLeft: 1,
                              fontFamily: "DM SANS",
                            }}
                          >
                            Provider Name
                          </FormLabel>
                        </Box>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          isMulti
                          className=""
                          placeholder={"Filter by name"}
                          onChange={(value) => {
                            // setSpeciality(value.value);
                          }}
                          styles={customStylesFilter}
                          isClearable={true}
                          isSearchable={true}
                          // isLoading={providerListLoading}
                          options={formattedArray(doctorList)}
                        />
                        <Box>
                          <FormLabel
                            sx={{
                              fontSize: "15px",
                              marginLeft: 1,
                              marginTop: 1,
                              fontFamily: "DM SANS",
                            }}
                          >
                            Specialty
                          </FormLabel>
                        </Box>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          isMulti
                          className=""
                          placeholder={"Filter by specialty"}
                          onChange={(value) => {
                            // setSpeciality(value.value);
                          }}
                          styles={customStylesFilter}
                          isClearable={true}
                          isSearchable={true}
                          // isLoading={providerListLoading}
                          options={formattedArray(dateLabelFilter)}
                        />
                        <button
                          className={`${"meta__button_orange"} mt-3 mb-2 w-p-100`}
                          // onClick={() => setCurrentStep(2)}
                        >
                          Apply Filter
                        </button>
                      </div>
                    )}
                  </Box>
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ fontFamily: "DM SANS", padding: "5px" }}
                          >
                            Provider
                          </TableCell>
                          <TableCell
                            sx={{ fontFamily: "DM SANS", padding: "5px" }}
                          >
                            Reason for visit
                          </TableCell>
                          <TableCell
                            sx={{ fontFamily: "DM SANS", padding: "5px" }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ fontFamily: "DM SANS", padding: "5px" }}
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pastAppointment.results &&
                          pastAppointment.results.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  fontFamily: "DM SANS",
                                  fontSize: "15px",
                                  padding: "10px 5px",
                                }}
                              >
                                {row.providerName}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "DM SANS",
                                  fontSize: "15px",
                                  padding: "10px 5px",
                                }}
                              >
                                {"reason"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "DM SANS",
                                  fontSize: "15px",
                                  padding: "10px 5px",
                                }}
                              >
                                {moment(row.dateStart).format("DD MMMM, YYYY")}
                              </TableCell>{" "}
                              <TableCell
                                align="right"
                                sx={{
                                  fontFamily: "DM SANS",
                                  fontSize: "15px",
                                  padding: "10px 5px",
                                }}
                              >
                                <Link href="" color={"#2A8AA7"}>
                                  View Details
                                </Link>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box
                    sx={{
                      paddingTop: "1.5rem",
                      // paddingBottom: "4rem",
                      borderTop: `1px solid #e9e9e9`,
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ mx: 1, cursor: "pointer" }}>
                      <FirstPageIcon />
                    </Box>
                    <Box sx={{ mx: 2, cursor: "pointer" }}>
                      <PreviousPageIcon />
                    </Box>
                    <Typography
                      sx={{
                        paddingTop: "2px",
                        fontFamily: "DM SANS",
                        fontSize: (theme) => theme.typography.button.fontSize,
                      }}
                    >
                      Page
                      <Box
                        sx={{
                          display: "inline",
                          border: "1px solid #C7C7C7",
                          px: 0.6,
                          borderRadius: "4px",
                        }}
                      >
                        1
                      </Box>
                      / 22
                    </Typography>
                    <Box sx={{ mx: 2, cursor: "pointer" }}>
                      <NextPageIcon />
                    </Box>
                    <Box sx={{ mx: 1, cursor: "pointer" }}>
                      <LastPageIcon />
                    </Box>
                  </Box>
                </>
              )}
          </AccordionDetails>
        </Accordion>

        <Divider sx={{ mt: 2, mb: 2 }} />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "18px",
              color: "#404040",
              fontFamily: "DM SANS",
            }}
          >
            Want to schedule an appointment?
          </Typography>

          <button
            className="transparent_button"
            onClick={(e) => handleChange(e, 1)}
          >
            Schedule appointment
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export const AppointmentsScheduleBody = () => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const steps = [
    { title: "Profile", description: "Select a user" },
    { title: "Reason", description: "Enter your reason" },
    { title: "Provider", description: "Select your provider" },
    { title: "Date & Time", description: "Choose an available slot" },
    { title: "Review", description: "Review & book" },
    { title: "Booked", description: "Review & book" },
  ];

  const [speciality, setSpeciality] = React.useState(null);
  const [reason, setReason] = React.useState(null);

  return (
    <Box>
      <Box sx={{ mx: 1 }}>
        {currentStep !== 5 ? (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  color: "#404040",
                  fontFamily: "DM SANS",
                }}
              >
                Schedule your next appointment or visit
              </Typography>
              <Box sx={{ display: "flex", mt: 1 }}>
                <RoundedCrossBlack size={0.8} />
                <Typography
                  sx={{
                    color: "#585858",
                    fontFamily: "DM SANS",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  Exit Scheduling
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }} className="steps_box">
              <section className="steps">
                <ul className="checkout pl-0">
                  {steps.map((data, index) => (
                    <React.Fragment key={index}>
                      {index !== 5 ? (
                        <li
                          className={`${
                            currentStep === index
                              ? "current"
                              : currentStep > index
                              ? "complete"
                              : "not_current"
                          }`}
                          key={index}
                        >
                          <span className="inner_circle"></span>
                          {currentStep > index && <WhiteTickIcon />}
                          <Typography
                            className={`${
                              currentStep === index
                                ? "title"
                                : currentStep > index
                                ? "bluetitle"
                                : "greytitle"
                            }`}
                          >
                            {data.title}
                          </Typography>
                          <Typography
                            className={`${
                              currentStep === index
                                ? "description"
                                : "greydescription"
                            }`}
                          >
                            {data.description}
                          </Typography>
                        </li>
                      ) : null}
                    </React.Fragment>
                  ))}
                </ul>
              </section>
            </Box>

            <Divider sx={{ mt: 4, mb: 4 }} />
          </>
        ) : null}
        {rswitch(currentStep, {
          0: <ProfileSection setCurrentStep={setCurrentStep} />,
          1: (
            <ReasonSection
              setCurrentStep={setCurrentStep}
              speciality={speciality}
              setSpeciality={setSpeciality}
              reason={reason}
              setReason={setReason}
            />
          ),
          2: <ProviderSection setCurrentStep={setCurrentStep} />,
          3: <DateTimeSection setCurrentStep={setCurrentStep} />,
          4: <ReviewSection setCurrentStep={setCurrentStep} />,
          5: <AppointmentBookedSection setCurrentStep={setCurrentStep} />,
          default: <></>,
        })}
      </Box>
    </Box>
  );
};

function Appointments() {
  const tabs = [
    { id: 1007, title: "All Appointments" },
    { id: 1008, title: "Schedule Appointments" },
  ];
  return (
    <>
      <ThemeTabs tabs={tabs} />
    </>
  );
}
export default Appointments;
