import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import {
  getPatientsList,
  getUserGroup,
} from "../../../redux/user/user.actions";
import {
  pageOptions,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import { MyButtons } from "../../CustomComponents/MyButtons";
import { PatientListHead } from "./PatientListHead";
import { PatientListRow } from "./PatientListRow";
import log from "../../../services/log";
import { buildDepartmentGroupUserGroupQuery } from "../../dbcq/usergroupdb";

export default function PatientListPHA() {
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const userslist = useSelector((state) => state?.user?.usergroup);
  const group = useSelector((state) => state?.drugs?.pharmagroup);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [action, setAction] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setRows(userslist);
  }, [userslist]);

  useEffect(() => {
    const resetpage = { ...pagedata, page: 0 };
    const jsonData = buildDepartmentGroupUserGroupQuery(
      resetpage,
      searchcontext,
      group
    );
    log.info("patient query: ", jsonData);
    dispatch(getUserGroup(jsonData)).then((r) => {
      log.info("patientlist", r);
      if (r.results) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  }, [dispatch, searchcontext]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("ct labs", keyword);
    if (keyword !== "") {
      const results = userslist.filter((v) =>
        v.labTitle.startsWith(keyword.toLowerCase())
      );
      setRows(results);
    } else {
      setRows(userslist);
    }
  };

  const handleChangePage = (event, value) => {
    // log.info("patientlist", value);
    // log.info("patientlist", pagedata);
    const newpage = { ...pagedata, page: parseInt(value) };
    const jsonData = buildDepartmentGroupUserGroupQuery(
      newpage,
      searchcontext,
      group
    );
    log.info("patient query: ", jsonData);
    dispatch(getUserGroup(jsonData)).then((r) => {
      log.info("patientlist", r);
      if (r.results) {
        setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
      }
    });
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleBack = () => {
    // log.info("policylist");
    setAction("");
  };

  const handleSelect = () => {
    log.info("patientlist select");
    setAction("select");
  };

  // log.info("policylist", selectrows);

  return (
    <Grid container>
      <div className="clear"></div>
      <div
        width="100%"
        className="flex-grow-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "36px",
          width: "100%",
        }}
      >
        <div>
          <div className="colfl0"></div>

          <div className="colfr0" width="100%">
            <SearchBoxStandard
              onChange={handleFilter}
              placeholder="Patient Name"
              sx={{ marginRight: 1 }}
              className="text-color-primary text-color-2-border text-color-2-bg"
            />
          </div>
        </div>
        <div className="colfr">
          {action === "select" ? (
            <MyButtons
              onClick={handleBack}
              btnKey={"back"}
              btnLabel={"Back"}
              enState={false}
            />
          ) : (
            <MyButtons
              onClick={handleSelect}
              btnKey={"select"}
              btnLabel={"Select"}
              enState={false}
            />
          )}
        </div>
      </div>

      {action === "select" ? (
        <div>{/*<PolicyEnrol policy={selectrows}/>*/}</div>
      ) : (
        <div style={{ width: "100%" }}>
          <div>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead className="text-color-primary">
                  <PatientListHead />
                </TableHead>

                <TableBody className="text-color-primary text text-md">
                  {rows &&
                    rows.map((row) => (
                      <PatientListRow key={row.id} row={row} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div style={{ width: "100%" }}>
            <TablePaginationStandard
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              pagedata={pagedata}
            />
          </div>
        </div>
      )}
    </Grid>
  );
}
