// import axios from "axios";
import { API_URL } from "./Constants";
import { axiosApiInstance } from "./axiosmiddleware";

const getPoliciesService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "policies/list", payload);
};

const setPolicyService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "policies/set", payload);
};

const updatePolicyService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "policies/update", payload);
};

const getPolicySubsService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "policysubs/list", payload);
};

const setPolicySubsService = (payload) => {
  // console.log(payload)
  return axiosApiInstance.post(API_URL + "policysubs/set", payload);
};

export {
  setPolicyService,
  updatePolicyService,
  getPoliciesService,
  getPolicySubsService,
  setPolicySubsService,
};
