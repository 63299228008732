import * as React from "react";
import {
  StyledTableRowHead,
  StyledTableCellHead,
} from "../common/ConstantsAndValues";

export const pTable = {
  title: {
    label: "Invoice",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  descrip: {
    label: "Detail",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  orderby: {
    label: "Source",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  amount: {
    label: "Amount",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },

  createdAt: {
    label: "Created on",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
};

export function PaymentDuesHead() {
  const table = pTable;
  return (
    <React.Fragment>
      <StyledTableRowHead style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead>{table.title.label}</StyledTableCellHead>
        {/*<StyledTableCellHead align="right">{table.descrip.label}</StyledTableCellHead>*/}
        <StyledTableCellHead align="right">
          {table.amount.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.orderby.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.createdAt.label}
        </StyledTableCellHead>
      </StyledTableRowHead>
    </React.Fragment>
  );
}
