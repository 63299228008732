/**
 * filter pharama group
 * @param p
 * @param s
 * @param user
 * @returns {{}}
 */
export const buildPharmaGroupsQuery = (p, s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p?.page;
  jsonData["limit"] = p?.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["providerRole"] = "metapharma";
  jsonData.metadata["providerId"] = user?.metadata?.accountId;
  return jsonData;
};

/**
 * filter lab group
 * @param p
 * @param s
 * @param user
 * @returns {{}}
 */
export const buildLabGroupsQuery = (p, s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p.page;
  jsonData["limit"] = p.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["providerRole"] = "metalab";
  jsonData.metadata["providerId"] = user?.metadata.accountId;
  return jsonData;
};

/**
 * filter doctor group
 *
 * @param p
 * @param s
 * @param user
 * @returns {{}}
 */

export const builddoctorGroupsQuery = (p, s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p.page;
  jsonData["limit"] = p.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["providerRole"] = "metamd";
  jsonData.metadata["providerId"] = user?.metadata.accountId;
  return jsonData;
};

/**
 * filter by department
 * @param p
 * @param s
 * @param user
 * @returns {{}}
 */

export const buildDepartmentGroupsQuery = (p, s, department) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p.page;
  jsonData["limit"] = p.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["providerRole"] = "metalab";
  jsonData.metadata["departmentId"] = department?.metadata.accountId;
  return jsonData;
};
