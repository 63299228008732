import {
  Box,
  IconButton,
  Typography,
  ThemeProvider,
  Tooltip,
} from "@mui/material";

import * as React from "react";
import { useMetaTheme } from "../theme/ThemeContext";
import log from "../../services/log";
import { BurgerIcon } from "../customIcons/BurgerIcon";
import { useState } from "react";

export function MetaIconButton(props) {
  const { onClick, enState, menu } = props;
  const { theme } = useMetaTheme();
  const [hoverState, setHoverState] = useState(false);

  const handleMouseEnter = () => {
    // log.info("mouser entering");
    setHoverState(true);
  };

  const handleMouseLeave = () => {
    // log.info("mouse leaving");
    setHoverState(false);
  };

  const handleClick = (event) => {
    // log.info("button: ", menu);
    onClick(menu);
  };

  // log.info("menus: ", menu);

  return (
    <ThemeProvider theme={theme}>
      <Tooltip title={menu?.title}>
        <IconButton
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          aria-label="expand row"
          size="small"
          color="primary"
          onClick={handleClick}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          sx={{
            alignItems: "center",
            padding: "0px",
            "&:hover": {
              backgroundColor: "transparent",
              svg: { fill: (theme) => theme?.palette?.primary?.main },
            },
            "&:focus": {
              outline: "none",
              border: 0,
            },
          }}
        >
          <Box
            display="flex"
            alignItems="left"
            sx={{
              padding: 0.5,
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "transparent",
                // color: theme?.palette?.primary?.light,
                svg: { fill: (theme) => theme?.palette?.primary?.light },
              },
            }}
          >
            {menu?.icon}
            <Typography
              variant="body2"
              marginLeft={1}
              sx={{
                color: menu?.isOpen
                  ? theme.palette.primary.main
                  : theme.palette.gray.main,
                fontSize: "16px",
                "&:hover": {
                  color: (theme) => theme?.palette?.primary?.light,
                },
              }}
            >
              {menu?.label}
            </Typography>
          </Box>
        </IconButton>
      </Tooltip>
    </ThemeProvider>
  );
}
