import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, createRef, useRef, useState } from "react";
import { Grid, Box } from "@mui/material";
import {
  //getAppointmentGuestList,
  //removeAppointment,
  //setApptDates,
  getProviderAppointmentByid,
  getProviderAppointmentList,
  setApptDates,
  updateProviderAppointment,
} from "../../redux/appointment/appointment.actions";

import { format } from "date-fns";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  eventColorBG,
  useWindowSize,
  TriggerCAL,
  calV,
  calInstruction,
} from "../common/ConstantsAndValues";
import { CalInputformProvder } from "./CalInputformProvder";
import { CalupdateformProvider } from "./CalupdateformProvider";
import { EventView } from "./EventView";
import log from "../../services/log";
import { selectRightDisable } from "./redux/actions"; //??
import { buildProviderAppointmentQuery } from "../dbcq/appointmentdb";

function renderEventContent(eventInfo) {
  return <span className="text-color">{eventInfo.event.title}</span>;
}

export function CalendartypeADM_APPT(props) {
  const user = useSelector((state) => state?.auth?.user?.user);
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const apptlist = useSelector((state) => state?.appointment?.clientapptlist);
  const eventmask = useSelector((state) => state?.events?.mask);
  const selectdate = useSelector((state) => state?.events?.select);
  const appt = useSelector((state) => state?.appointment?.appt);
  const newdate = useSelector((state) => state?.appointment?.dates);
  const selectorg = useSelector((state) => state?.orgs?.selectorg);

  const dispatch = useDispatch();
  const calendarRef = React.createRef();
  const size = useWindowSize();
  const calOffset =
    size.width < TriggerCAL.a
      ? 8
      : size.width < TriggerCAL.b && size.width >= TriggerCAL.a
      ? 4
      : 0;

  useEffect(() => {
    if (user) {
      const jsonData = buildProviderAppointmentQuery(searchcontext, user);
      dispatch(getProviderAppointmentList(jsonData)).then((r) => {
        log.info("events: ", r);
      });
    }
  }, [user, size]);

  //useEffect(() => {
  //    if (user) {
  //        dispatch(getProviderAppointmentList({metadata: {
  //            guestId:  user?.metadata?.accountId
  //        }}));
  //    }
  //}, [user, dispatch, size]);

  const [eventInfo, setEventInfo] = useState({});
  const [date, setDate] = useState(new Date());
  const [eventdate, setEventDate] = useState(new Date());
  const [datePast, setDatePast] = useState(false);
  const [dateFlag, setDateFlag] = useState(false);
  const [orgInfo, setOrgInfo] = useState(selectorg ? selectorg : null);
  const [alertMessage, setAlertMessage] = useState(calInstruction.admAppt);
  const [calstate, setCalState] = useState("");
  const [eventmove, setEventmove] = useState(false);

  const today = new Date(new Date().setHours(0, 0, 0, 0));

  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();

    // log.info("metacal add event", eventmask);

    if (calendarApi && apptlist && eventmask) {
      calendarApi.removeAllEvents();

      // all event setting
      const allevent = eventmask.filter((e) => e._id === "all");

      // log.info("metacal allevent", allevent)
      allevent.filter((e) => {
        /**
         * rendering all events
         */
        if (e.isChecked) {
          // all events
          apptlist.forEach((v) => {
            calendarApi.addEvent(
              {
                title: v.eventTitle,
                start: v.dateStart,
                end: v.dateEnd,
                id: v.id,
                sourceId: v.metadata.eventId,
                backgroundColor: eventColorBG.mask,
              },
              true
            );
          });
        } else {
          // fitler checked events
          const events = eventmask.filter(
            (e) => e._id !== "all" && e.isChecked == true
          );
          events.forEach((f) => {
            const filtevents = apptlist.filter(
              (e) => e.metadata.eventType === f._id
            );

            filtevents &&
              filtevents.forEach((v) => {
                calendarApi.addEvent(
                  {
                    title: v.eventTitle,
                    start: v.dateStart,
                    end: v.dateEnd,
                    id: v.id,
                    sourceId: v.metadata.eventId,
                    backgroundColor: eventColorBG.mask,
                  },
                  true
                );
              });
          });
        }
      });
    }
  }, [apptlist, eventmask]);

  useEffect(() => {
    /**
     * setting from rsm
     * @type {CalendarApi}
     */
    const calendarApi = calendarRef.current.getApi();

    if (selectdate) {
      calendarApi.gotoDate(selectdate);
      setDate(selectdate);

      {
        calendarApi.view.type === "dayGridMonth" &&
          calendarApi.changeView("timeGridDay");
      }
    }
  }, [selectdate]);

  const updateEvent = () => {
    // console.log('update event', eventInfo);

    dispatch(
      getProviderAppointmentList({
        metadata: { eventId: appt.metadata.eventId },
      })
    ).then((r) => {
      // log.info("event move", r);
      r.results &&
        r.results.forEach((e) => {
          dispatch(
            updateProviderAppointment({
              id: e.id,
              dateEnd: newdate.endStr,
              dateStart: newdate.startStr,
            })
          );
        });
    });
  };

  const removeEvent = () => {
    eventInfo.remove();
  };

  const handleDateSelect = (selectInfo) => {
    log.info("datecal", selectInfo);
    setAlertMessage("");

    //ADD Ph-1
    const calendarApi = selectInfo.view.calendar;
    //const today = new Date(new Date().setHours(0, 0, 0, 0))
    const ispast = selectInfo.start < today ? true : false;
    setDate(selectInfo.start);
    setEventDate(selectInfo);
    setDatePast(ispast);

    calendarApi.unselect(); // clear date selection

    setEventInfo(selectInfo);
    dispatch(
      setApptDates({
        startStr: selectInfo.startStr,
        endStr: selectInfo.endStr,
        allDay: selectInfo.allDay,
      })
    );
  };

  const handleCancel = () => {
    /**
     * closes form
     */
    setCalState("");
    dispatch(selectRightDisable(false));
  };

  /**
   * date clicked from calendar
   * @param event
   */
  const handleDateClick = (event) => {
    //ADD Ph-2
    // log.info("date clicked", event);

    const calendarApi = calendarRef?.current?.getApi();
    if (event?.view?.type === "dayGridMonth") {
      calendarApi.changeView("timeGridDay", event.date);
    } else {
      let alertmsg = datePast
        ? "Booking is irrelevent"
        : dateFlag
        ? "Booking closed "
        : "";
      // log.info("date clicked", alertmsg);

      if (alertmsg != "") {
        alertmsg += " for " + format(date, "yyyy-MM-dd");
        setAlertMessage(alertmsg);
      } else {
        dispatch(selectRightDisable(true));
        setAlertMessage("");
        setCalState("inputform");
      }
    }
  };

  /**
   * this trigger the event update
   * @param clickInfo
   */
  const handleEventClick = (clickInfo) => {
    // log.info("metacal event", clickInfo.event._def.publicId);
    const calendarApi = clickInfo?.view?.calendar;
    if (calendarApi?.view?.type === "dayGridMonth") {
      calendarApi.changeView("timeGridDay", clickInfo?.event.start);
    } else {
      let dt = clickInfo.event.start;
      //const today = new Date(new Date().setHours(0, 0, 0, 0))
      setDate(dt);
      setDatePast(dt < today ? true : false);
      const seldate = format(dt, "yyyy-MM-dd");

      // setDateFlag(markDates && (markDates.includes(seldate))? true: false )

      if (clickInfo.event != undefined) {
        dispatch(selectRightDisable(true));
        const jsonData = {};
        jsonData["id"] = clickInfo.event._def.publicId;
        dispatch(getProviderAppointmentByid(jsonData));
        setCalState("eventview");
      }
    }
  };

  /**
   * called on evnet darag start
   * @param removeInfo
   */
  const handleEventRemove = (removeInfo) => {
    // console.log('moving from', removeInfo)
    setEventmove(true);
  };

  /**
   * executed at the end of move
   * @param addInfo
   */
  const handleEventAdd = (addInfo) => {
    // console.log("adding movedto", addInfo)
    if (addInfo.event !== null) {
      const jsonData = {};
      jsonData["id"] = addInfo.event._def.publicId;
      dispatch(getProviderAppointmentByid(jsonData));
      if (eventmove) {
        /**
         * do update for event move
         */
        updateEvent();
        setEventmove(false);
      }
    }
  };

  const doColorPast = (event, cell) => {
    const dt = event.start;
    const seldate = format(dt, "yyyy-MM-dd");
    const past = dt < today ? true : false;
    if (past) {
      cell.css("background-color", "gray");
    }
  };

  const handleEventDropAllow = (changeInfoAllow) => {
    {
      /*Drop case*/
    }

    log.info("event dragallow", changeInfoAllow);

    //DRAGDROP Ph-0?

    const dt = changeInfoAllow.start;
    //const today = new Date(new Date().setHours(0, 0, 0, 0))
    const seldate = format(dt, "yyyy-MM-dd");
    const past = dt < today ? true : false;

    // const full = markDates && (markDates.includes(seldate))? true: false ;

    setDate(dt);
    setDatePast(past);
    // setDateFlag(full)

    dispatch(
      setApptDates({
        startStr: changeInfoAllow.startStr,
        endStr: changeInfoAllow.endStr,
        allDay: changeInfoAllow.allDay,
      })
    );

    // return ( !past && !full ) ? true: false ;
  };

  /**
   * not used
   * @param Info
   */
  const handleEventMouseEnter = (Info) => {};

  /**
   * drag-drop
   * @param changeInfo
   */
  const handleEventDrop = (changeInfo) => {
    log.info("dragdrop", changeInfo);
    //DRAGDROP Ph-1
  };

  /**
   * drag of event
   * this event does not work
   * @param changeInfo
   */
  const handleEventChange = (changeInfo) => {
    // log.info("moveevent to ", changeInfo.event);
    if (!datePast && !dateFlag) {
      dispatch(
        updateProviderAppointment({
          id: changeInfo.event.id,
          eventTitle: changeInfo.event.title,
          dateStart: changeInfo.event.startStr,
          dateEnd: changeInfo.event.endStr,
        })
      );
    }
  };

  /**
   * get called on range select
   * @param rangeInfo
   */
  const handleDates = (rangeInfo) => {
    // log.info('calendar range', rangeInfo);
    if (user) {
      dispatch(
        getProviderAppointmentList({
          metadata: {
            guestId: user.metadata && user.metadata.accountId,
          },
        })
      );
    }
  };

  const handleSize = (event) => {
    let contentApi = event.view.calendar;
    contentApi.changeView(
      window.innerWidth < 800 ? "timeGridDay" : "timeGridWeek"
    );
  };

  return (
    <Grid container width="100%" mt={6}>
      {alertMessage && (
        <div className="text-color-error App my5" style={{ width: "100%" }}>
          {alertMessage}
        </div>
      )}

      {calstate === "" ? (
        <div style={{ width: "100%" }}>
          <FullCalendar
            height={"auto"}
            aspectRatio={2}
            ref={calendarRef}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              center: "title",
              left: "prev,today,next",
              right: "dayGridMonth,timeGridWeek,timeGridDay,list",
            }}
            initialView={"timeGridDay"}
            views={{
              month: { titleFormat: calV[0 + calOffset] },
              week: { titleFormat: calV[1 + calOffset] },
              day: { titleFormat: calV[2 + calOffset] },
              list: { titleFormat: calV[3 + calOffset] },
            }}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={props.weekendsVisible}
            datesSet={handleDates}
            select={handleDateSelect}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            eventAllow={handleEventDropAllow}
            eventDrop={handleEventDrop}
            eventMouseEnter={handleEventMouseEnter}
            eventChange={handleEventChange} // called for drag-n-drop/resize
            eventContent={renderEventContent} // custom render function
            eventAdd={handleEventAdd} //Add and Update event triggers this
            eventRemove={handleEventRemove}
            windowResize={handleSize}
            // eventOverlap = { false }

            businessHours={{
              daysOfWeek: orgInfo
                ? orgInfo.workingHours.daysOfWeek
                : [0, 1, 2, 3, 4, 5, 6],
              startTime: orgInfo ? orgInfo.workingHours.startTime : "00:00",
              endTime: orgInfo ? orgInfo.workingHours.endTime : "24:00",
            }}
            // slotEventOverlap = {false}

            slotMinTime={orgInfo ? orgInfo.workingHours.startTime : "00:00"}
            slotMaxTime={orgInfo ? orgInfo.workingHours.endTime : "24:00"}
            slotDuration={
              orgInfo ? orgInfo.workingHours.slotDuration : "00:30:00"
            }

            //validRange = {{ start: Date, end: null }}
          />
        </div>
      ) : calstate === "inputform" ? (
        <CalInputformProvder closeForm={handleCancel} mode={"appointment"} />
      ) : calstate === "eventview" ? (
        <>
          <Grid
            item
            xs={12}
            mb={4}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <EventView closeForm={handleCancel} eventRemove={removeEvent} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CalupdateformProvider closeForm={handleCancel} />
          </Grid>
        </>
      ) : (
        <div />
      )}
    </Grid>
  );
}
