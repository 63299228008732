import {
  getOrgService,
  setOrgService,
  updateOrgService,
  listOrgGroupService,
  updateOrgGroupService,
  setOrgGroupService,
  listOrgDepartmentService,
  addOrgDepartmentService,
  getOrgProfileService,
  updateOrgProfileService,
  setOrgProfileService,
} from "../../services/orgService";

import { orgsActionTypes } from "./orgs.types";

export const getOrgs = (payload) => (dispatch) => {
  return getOrgService(payload).then(
    (response) => {
      dispatch({
        type: orgsActionTypes.ORGS,
        payload: response.data.results,
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const setOrg = (payload) => (dispatch) => {
  return setOrgService(payload).then(
    (response) => {
      dispatch({
        type: orgsActionTypes.ORGS,
        payload: response.data.results,
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const updateOrg = (payload) => (dispatch) => {
  return updateOrgService(payload).then(
    (response) => {
      dispatch({
        type: orgsActionTypes.ORGS,
        payload: response.data.results,
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const getOrgGroups = (payload) => (dispatch) => {
  return listOrgGroupService(payload).then(
    (response) => {
      dispatch({
        type: orgsActionTypes.ORGGROUPS,
        payload: response.data.results,
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return Promise.reject();
    }
  );
};

export const setOrgGroup = (payload) => (dispatch) => {
  return setOrgGroupService(payload).then(
    (response) => {
      dispatch({
        type: orgsActionTypes.ORGGROUPS,
        payload: response.data.results,
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return Promise.reject();
    }
  );
};

export const updateOrgGroup = (payload) => (dispatch) => {
  return updateOrgGroupService(payload).then(
    (response) => {
      dispatch({
        type: orgsActionTypes.ORGGROUPS,
        payload: response.data.results,
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return Promise.reject();
    }
  );
};

export const listOrgDepartments = (payload) => (dispatch) => {
  return listOrgDepartmentService(payload).then(
    (response) => {
      dispatch({
        type: orgsActionTypes.ORGDEPARTMENTS,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const existOrgDepartment = (payload) => (dispatch) => {
  return listOrgDepartmentService(payload).then(
    (response) => {
      return Promise.resolve(response.data.results);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const addOrgDepartments = (payload) => (dispatch) => {
  return addOrgDepartmentService(payload).then(
    (response) => {
      dispatch({
        type: orgsActionTypes.ORGDEPARTMENTADD,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const updateOrgDepartments = (payload) => (dispatch) => {
  return addOrgDepartmentService(payload).then(
    (response) => {
      dispatch({
        type: orgsActionTypes.ORGDEPARTMENTS,
        payload: response.data.hits,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const selectDepartment = (data) => (dispatch) => {
  return dispatch({
    type: orgsActionTypes.SELECTEDDEPARTMENT,
    payload: data,
  });
};

export const selectOrg = (data) => (dispatch) => {
  return dispatch({
    type: orgsActionTypes.SELECTEDORG,
    payload: data,
  });
};

export const selectOrgGroup = (data) => (dispatch) => {
  return dispatch({
    type: orgsActionTypes.SELECTEDORGGROUP,
    payload: data,
  });
};

export const setOrgProfileCache = (data) => (dispatch) => {
  return dispatch({
    type: orgsActionTypes.ORGABOUT,
    payload: data,
  });
};

export const getOrgProfile = (payload) => (dispatch) => {
  return getOrgProfileService(payload).then(
    (response) => {
      dispatch({
        type: orgsActionTypes.ORG_PROFILE,
        payload: response.data.results[0],
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const updateOrgProfile = (payload) => (dispatch) => {
  return updateOrgProfileService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const setOrgProfile = (payload) => (dispatch) => {
  return setOrgProfileService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};
