import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import log from "../../services/log";
import { getUsersList } from "../../redux/user/user.actions";
import { usersquery } from "../utils/userqueries";

export default function MetaUserSearch(props) {
  const { title, defaultValue, addsearch } = props;

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([{}]);
  const [open, setOpen] = useState(false);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });

  const userslist = useSelector((state) => state.user.userslist);

  const loading = open && options.length === 0;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open || inputValue === "") {
      setOptions([]);
    } else if (userslist) {
      const newoptions = [];
      userslist.forEach((u) =>
        newoptions.push({ label: u._source.name, value: u._source })
      );
      setOptions(newoptions);
    }
  }, [open, userslist]);

  const handleChange = (event, newValue) => {
    // log.info("autocomplete", newValue);
    addsearch(newValue);
  };

  const handleInputValue = (event, newInputValue) => {
    // log.info("autocomplete", newInputValue);
    setInputValue(newInputValue);
    dispatch(getUsersList(usersquery("email", newInputValue, pagedata)));
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      label={title}
      defaultValue={defaultValue}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
        ),
      }}
    />
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      &nbsp;
      <Autocomplete
        // sx={{ width: 300, height: 40}}
        id="useremails"
        defaultValue={defaultValue}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputValue}
        options={options}
        loading={loading}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => (option ? option.label : "")}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        renderInput={renderInput}
      />
    </Box>
  );
}
