import * as React from "react";
import {
  StyledTableRowHead,
  StyledTableCellHead,
} from "../../common/ConstantsAndValues";

export const drugsTable = {
  medicine: {
    label: "Medicine Name",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  salt: {
    label: "Salt Composition",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  use: {
    label: "Primary Use",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
};

export function DrugsListHead() {
  const table = drugsTable;

  return (
    <React.Fragment>
      <StyledTableRowHead style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead style={{ maxWidth: "40%" }}>
          {table.medicine.label}
        </StyledTableCellHead>
        <StyledTableCellHead style={{ maxWidth: "40%" }} align="left">
          {table.salt.label}
        </StyledTableCellHead>
        {/*<StyledTableCellHead style={{maxWidth:"40%"}} align="left">{table.use.label}     </StyledTableCellHead>*/}
      </StyledTableRowHead>
    </React.Fragment>
  );
}
