import { connect } from "react-redux";
import ViewerRetrieveStudyData from "./ViewerRetrieveStudyData.js";
import OHIF from "../../core";

const { clearViewportSpecificData, setStudyData } = OHIF.reduxdicom.actions;
const isActive = (a) => a.active === true;

const mapStateToProps = (state, ownProps) => {
  const activeServer = state.servers.servers.find(isActive);
  console.log("viewerretrieve", ownProps);
  // server: ownProps.server || activeServer,
  return {
    server: activeServer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setStudyData: (StudyInstanceUID, data) => {
      dispatch(setStudyData(StudyInstanceUID, data));
    },
    clearViewportSpecificData: () => {
      dispatch(clearViewportSpecificData());
    },
  };
};

const ConnectedViewerRetrieveStudyData = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewerRetrieveStudyData);

export default ConnectedViewerRetrieveStudyData;
