import * as React from "react";
import Select from "react-select";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { Form, useFormik } from "formik";
import { Grid, Box, Typography } from "@mui/material";
import { SelectOptionsAuto } from "../common/SelectOptions";
import { LocMSStyles } from "../location/LocMSStyles";
import InputFormComponent from "../CustomComponents/InputFormComponent";
import {
  MyButtons,
  MyTooltipButtons,
  MyHelpButtons,
} from "../CustomComponents/MyButtons";

import { useDispatch, useSelector } from "react-redux";
import { setDrugPrecriptionList } from "../../redux/drugs/drugs.actions";

import { drugHold, drugPackage } from "./ReportsOrders";

import log from "../../services/log";

const prescriptionSchema = yup.object({
  doctorId: yup.string(),
  doctorName: yup.string().min(2).required("doctor is required"),
  patientId: yup.string(),
  patientName: yup.string().min(2).required("patient is required"),
  medicineId: yup.string(),
  medicineName: yup.string().min(2).required("medicine is required"),
  medicineSalt: yup.string(),
  medicineQty: yup.number().required("Quantity info is required"),

  status: yup.string(),
});

export const ReportsPatOrderInput = (props) => {
  const { doctor, patient, medicine, cancel } = props;

  const oplist = useSelector((state) => state.drugs.preslist);
  const [isDrugHold, setIsDrugHold] = useState(false);
  const [drugHoldRem, setDrugHoldRem] = useState([]);
  const [drugPack, setDrugPack] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (drugHold) {
      const newoptions = [];
      drugHold.forEach((v) =>
        newoptions.push({ label: v.abbr + " - " + v.value, value: v })
      );
      setDrugHoldRem(newoptions);
    } else {
      setDrugHoldRem([]);
    }
  }, [drugHold]);

  useEffect(() => {
    if (drugPackage) {
      const newoptions = [];
      drugPackage.forEach((v) =>
        newoptions.push({ label: v.abbr + " - " + v.value, value: v })
      );
      setDrugPack(newoptions);
    } else {
      setDrugPack([]);
    }
  }, [drugPackage]);

  const medicineform = useFormik({
    initialValues:
      doctor && patient && medicine
        ? {
            doctorId: doctor ? doctor.accountId : "",
            doctorName: doctor ? doctor.name : "",
            patientId: patient ? patient.accountId : "",
            patientName: patient ? patient.name : "",
            // medicineId:   medicine? medicine._source.Id : '',
            // medicineName: medicine? medicine._source.name : '',
            // medicineSalt: medicine? medicine._source.salt : '',
            // medicineQty : medicine? medicine._source.qty : '',      //added ??
            status: "active",
            currentlist: [...oplist],
          }
        : {
            doctorId: "",
            doctorName: "",
            patientId: "",
            patientName: "",
            medicineId: "",
            medicineName: "",
            medicineSalt: "",
            medicineQty: "", //?
            status: "active",
            currentlist: [],
          },
    validationSchema: prescriptionSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("medicineinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["doctorId"] = values.doctorId;
      jsonData.metadata["patientId"] = values.patientId;
      jsonData.metadata["medicineId"] = values.medicineId;
      jsonData["doctorName"] = values.doctorName;
      jsonData["patientName"] = values.patientName;
      jsonData["medicineName"] = {};
      jsonData.medicineName["name"] = values.medicineName;
      jsonData.medicineName["salt"] = values.medicineSalt;
      jsonData.medicineName["qty"] = values.medicineQty;
      jsonData["status"] = values.status;

      values.currentlist.push(jsonData);
      // log.info("medicineinput", oplist);
      dispatch(setDrugPrecriptionList(values.currentlist));
      cancel();
      setIsDrugHold(false);
    },
    onReset: (values) => {
      values.medicineQty = 1;
    },
  });

  const addPrescription = () => {
    // dispatch(setPrecriptionList());
  };

  const cancelEvent = () => {
    cancel();
    setIsDrugHold(false);
  };

  const handleHoldClick = (e) => {
    setIsDrugHold(true);
    log.info("medicinehold", e);
  };

  const handleDrugHold = (e) => {
    log.info("medicineHoldRemarks", e);
    medicineform.setFieldValue("HoldRem", e);
  };

  const handleDrugPack = (e) => {
    log.info("medicinePackaging", e);
    medicineform.setFieldValue("DrugPack", e);
  };

  log.info("medicinephainput", medicine);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%", md: "50%", lg: "40%" },
        }}
      >
        <form>
          <Box className="colfr0 " mb={1}>
            <MyTooltipButtons
              onClick={cancelEvent}
              btnKey={"cancel"}
              btnLabel={"Cancel"}
              enState={false}
            />
          </Box>
          <Box className="clear"></Box>
          <Box width="100%" className="text-color-primary">
            <Box className="border-db p10">
              <Typography className="text-color-primary">
                Medicine: {medicineform.values.medicineName}
              </Typography>
            </Box>
            <Box className="border-db p10">
              <Typography>
                Contains: <i>{medicineform.values.medicineSalt}</i>
              </Typography>
            </Box>
            <Box className="border-db p10 mb-30">
              <Typography>
                Quantity (requested): <i>{medicineform.values.medicineQty}</i>
              </Typography>
            </Box>
            <Box>
              <InputFormComponent
                id="qty"
                name="qty"
                label="Quantity"
                errorText={medicineform.errors.medicineQty}
                defaultValue={medicineform.values.medicineQty}
                errors={medicineform.errors.medicineQty ? true : false}
                variant="standard"
                handleChange={medicineform.handleChange}
              />
              &nbsp;
              <SelectOptionsAuto
                openPage={handleDrugPack}
                data={drugPack}
                multi={false}
                title={"Drug Packaging"}
                wide={0}
              />
              <p style={{ margin: "16px 0px 8px 0px" }}>
                When it is required to hold the process, provide reason herein
              </p>
              <SelectOptionsAuto
                openPage={handleDrugHold}
                data={drugHoldRem}
                multi={false}
                title={"reason when Drug on hold"}
                wide={0}
              />
            </Box>
          </Box>
        </form>

        <Box mt={2} pt={2} className="border-dbt">
          <Box className="colfr0">
            <MyHelpButtons
              onClick={medicineform.handleSubmit}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
              btnTooltip={"Add"}
            />
            &nbsp; &nbsp;
            <MyHelpButtons
              onClick={() => handleHoldClick()}
              btnKey={"hold"}
              btnLabel={"Hold"}
              enState={false}
              btnTooltip={"Hold"}
            />
          </Box>
          <Box className="clear" />
        </Box>
        <Box className="clear" />
        <Box mt={2} className="border-dbt p10 text-color-error">
          {medicineform.errors.medicineName ? (
            <Typography>{medicineform.errors.medicineName}</Typography>
          ) : (
            <div />
          )}
          {medicineform.errors.medicineQty ? (
            <Typography>{medicineform.errors.medicineQty}</Typography>
          ) : (
            <div />
          )}
          {!isDrugHold && medicineform.errors.drugPackaging ? (
            <Typography>{medicineform.errors.drugPackaging}</Typography>
          ) : (
            <div />
          )}
          {isDrugHold && medicineform.errors.HoldRem ? (
            <Typography>{medicineform.errors.HoldRem}</Typography>
          ) : (
            <div />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

{
  /*

                        <Select
                            options       = {drugPack}
                            placeholder   = {"Select Drug Packaging"}
                            value         = {medicineform.values.drugPackaging}
                            onChange      = {handleDrugPack}
                            isSearchable  = {true}
                            styles        = {LocMSStyles}
                            menuPlacement = "auto"
                            menuPosition  = "fixed"
                            isMulti       = {false}
                            error         = {true}
                        />



                        <Select
                            options       = {drugHoldRem}
                            placeholder   = {"Select reason when Drug on hold"}
                            value         = {medicineform.values.HoldRem}
                            onChange      = {handleDrugHold}
                            isSearchable  = {true}
                            styles        = {LocMSStyles}
                            menuPlacement = "auto"
                            menuPosition  = "fixed"
                            isMulti       = {false}
                            error         = {true}
                        />


*/
}
