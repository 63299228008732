import React, { useEffect, useState } from "react";

const HealthInsuranceIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
    >
      <path
        d="M12.7188 7.65761C12.3407 6.49049 10.8547 4.15625 7.93533 4.15625C5.01593 4.15625 3.66066 6.49049 3.38121 7.65761C3.19426 8.35862 3.08495 10.6094 4.53499 12.3687C5.29571 13.2916 6.29471 14.4014 7.29869 15.508C10.7704 19.3348 12.7188 21.1382 12.7188 21.1382"
        stroke="#404040"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M12.7188 7.65761C13.0968 6.49049 14.5828 4.15625 17.5022 4.15625C20.4216 4.15625 21.7768 6.49049 22.0563 7.65761C22.2432 8.35862 22.3526 10.6094 20.9025 12.3687C20.1418 13.2916 19.1428 14.4014 18.1388 15.508C14.6671 19.3348 12.7188 21.1382 12.7188 21.1382"
        stroke="#404040"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle cx="12.7188" cy="21.1406" r="1" />
      <circle cx="12.7188" cy="7.70312" r="1" />
      <rect x="9.71875" y="12.5703" width="6" height="1.16013" rx="0.580067" />
      <rect
        x="13.2969"
        y="10.1484"
        width="6"
        height="1.16013"
        rx="0.580067"
        transform="rotate(90 13.2969 10.1484)"
      />
    </svg>
  );
};
export default HealthInsuranceIcon;
