import * as React from "react";
import { useEffect, useState } from "react"; //from "@material-ui/core";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatientDrugs,
  selectPatientMedcine,
} from "../../redux/drugs/drugs.actions";
import { getMedicine } from "../../redux/actions/reports";
import { pageOptions, SearchBoxStandard } from "../common/ConstantsAndValues";
import useStyles from "../CustomComponents/useStyles";
import { TablePaginationStandard } from "../CustomComponents/TablePaginationStandard";

import { MedicineListHead } from "./MedicineListHead";
import { MedicineListRow } from "./MedicineListRow";

import log from "../../services/log";
import {
  buildPatientMedicinesQuery,
  buildUserMedicinesQuery,
} from "../dbcq/medicinedb";

export function MedicineList(props) {
  const user = useSelector((state) => state?.auth?.user?.user);
  const medicines = useSelector((state) => state?.drugs?.patientdruglist);
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setRows(medicines);
  }, [medicines]);

  useEffect(() => {
    if (user) {
      const resetpage = { ...pagedata, page: 0 };
      const jsonData = buildUserMedicinesQuery(resetpage, searchcontext, user);

      dispatch(getPatientDrugs(jsonData)).then((r) => {
        if (r.results) {
          // log.info("patinetdruglist", r);
          setPage({ ...pagedata, pages: r.totalPages, count: r.totalResults });
        }
      });
    }
  }, [user]);

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient medlist", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    const jsonData = buildUserMedicinesQuery(newpagedata, searchcontext, user);
    dispatch(getPatientDrugs(jsonData)).then((r) => {
      if (r.results) {
        log.info("patinetdruglist", r);
        setPage({ ...pagedata, pages: r.totalPages, count: r.totalResults });
      }
    });
    setPage(newpagedata);
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (e, selectrow) => {
    log.info("medicinelist", e);
    if (selectrow) {
      setCheckedRows(e);
      dispatch(selectPatientMedcine(null));
    } else {
      setCheckedRows(null);
      dispatch(selectPatientMedcine(null));
    }
  };

  // log.info("medicinelist", medicines);

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead className="">
              <MedicineListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {rows &&
                rows?.map((row) => (
                  <MedicineListRow
                    key={row.id}
                    row={row}
                    isRowSelected={checkedRows}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
