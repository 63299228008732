import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  getAddressAutocomp,
  setAnchorLocAddress,
} from "../../redux/actions/locs";
import { AutoMSStyles, StyledAutocomplete } from "./LocMSStyles";
import log from "../../services/log";
import { useMetaTheme } from "../theme/ThemeContext";
import { ThemeProvider } from "@mui/material/styles";

export default function AnchorAutocomplete(props) {
  const { title, defaultValue } = props;
  const country = useSelector((state) => state?.locs?.country);
  const area = useSelector((state) => state?.locs?.area);
  const addresses = useSelector((state) => state?.locs?.addresssearch);

  const [value, setValue] = useState(defaultValue);
  const [inputValue, setInputValue] = useState("a");
  const [options, setOptions] = useState([{}]);
  const [open, setOpen] = useState(false);

  const { theme } = useMetaTheme();
  const { color, muitheme, muitheme1 } = theme;

  const dispatch = useDispatch();

  useEffect(() => {
    let jsonData = {};
    let valueset = area
      ? area._source.name + ", " + inputValue
      : "Delhi, " + inputValue;
    jsonData["input"] = valueset;
    jsonData["country"] = [];
    jsonData.country.push(country ? country?._source["alpha-2"] : "IN");
    log.info("address autocomplete change", jsonData);
    dispatch(getAddressAutocomp(jsonData));
    // .then((r) => {log.info("address autocomplete", r)});
  }, [inputValue, area, country, dispatch]);

  useEffect(() => {
    if (addresses) {
      const newoptions = [];
      addresses.forEach((u) =>
        newoptions.push({ label: u.description, value: u })
      );
      setOptions(newoptions);
    }
  }, [addresses]);

  const handleChange = (event, newValue) => {
    // log.info("anchor address change", newValue)
    setValue(newValue);
    dispatch(setAnchorLocAddress(newValue));
  };

  const handleInputValue = (event, newInputValue) => {
    // log.info("anchor autocomplete", newInputValue);
    setInputValue(newInputValue);
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      label={title}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
        ),
      }}
    />
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // log.info("autocomplete", value);

  return (
    <ThemeProvider theme={muitheme}>
      <Box mt={2} mb={2}>
        <StyledAutocomplete
          // style={AutoMSStyles}
          id="loincnumber"
          // defaultValue={}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={handleInputValue}
          options={options}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          getOptionLabel={(option) => (option ? option.label : "")}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          renderOption={(props, option) => <li {...props}>{option.label}</li>}
          renderInput={renderInput}
        />
      </Box>
    </ThemeProvider>
  );
}
