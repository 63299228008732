import { Box, FormLabel, Grid, Typography } from "@mui/material";
import Select, { components } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";
import {
  // customStylesField,
  customStylesFieldHover,
  formattedArray,
} from "../../../tabs/TabsStaticData";
import { SearchTableIcon } from "../../../customIcons/TableIcons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentReasonListElastic,
  getProviderAppointmentListElastic,
} from "../../../../redux/appointment/appointment.actions";
import { providerquery, reasonquery } from "../../../utils/providerqueries";
import toast from "react-hot-toast";

export const ReasonSection = ({
  setCurrentStep,
  speciality,
  setSpeciality,
  reason,
  setReason,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // getting providers
    dispatch(getProviderAppointmentListElastic(providerquery()));
  }, []);

  // getting providers
  const providerList = useSelector((state) => state?.appointment?.providerData);
  const providerListLoading = useSelector(
    (state) => state?.appointment?.isLoadingProviderData
  );
  let finalProviderList = providerList?.map((data) => ({
    label: data._source.appTypeLabel,
    value: data._source.appTypeLabel,
  }));

  // getting reasons
  const reasonList = useSelector((state) => state?.appointment?.reasonData);
  const reasonListLoading = useSelector(
    (state) => state?.appointment?.isLoadingReasonData
  );

  let finalReasonList = reasonList?.map((data) => ({
    label: data._source.conditionDescp,
    value: data._source.conditionDescp,
  }));

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchTableIcon size={0.6} />
      </components.DropdownIndicator>
    );
  };

  const filterOptions = (inputValue) => {
    dispatch(getAppointmentReasonListElastic(reasonquery(inputValue)));
    let finalReasonList = reasonList?.map((data) => ({
      label: data._source.conditionDescp,
      value: data._source.conditionDescp,
    }));
    return finalReasonList;
  };

  const routeToProvider = () => {
    if (reason !== null && speciality !== null) {
      setCurrentStep(2);
    } else {
      toast.error("Please complete the fields");
    }
  };

  return (
    <>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "18px",
          color: "#404040",
          fontFamily: "DM SANS",
        }}
      >
        Tell us more about the appointment
      </Typography>
      <Grid container sx={{ flexGrow: 1, mt: 4 }}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
            <span className="required_dot"></span>
            Provider specialty
          </FormLabel>
          <Box sx={{ width: "95%" }}>
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              className=""
              placeholder={"Select a speciality"}
              onChange={(value) => {
                setSpeciality(value.value);
              }}
              styles={customStylesFieldHover}
              isClearable={true}
              isSearchable={true}
              isLoading={providerListLoading}
              options={formattedArray(finalProviderList)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
            Details
          </FormLabel>
          <Box sx={{ position: "relative" }}>
            <CreatableSelect
              cacheOptions={false}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              styles={customStylesFieldHover}
              options={finalReasonList}
              onInputChange={(value) => {
                if (value.length > 1) {
                  filterOptions(value);
                }
              }}
              onChange={(value) => {
                setReason(value.value);
              }}
              isSearchable={true}
              placeholder={"Enter details - helpful text here"}
              isClearable={true}
              isLoading={reasonListLoading}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex" }}>
        <button
          className={`${
            speciality !== null && reason !== null
              ? "meta__button_orange"
              : "meta__button_green"
          } mt-4`}
          onClick={routeToProvider}
        >
          Next: Provider
        </button>
        <button
          className="meta__button_green_outlined mt-4 ml-3"
          onClick={() => setCurrentStep(0)}
        >
          Back: Profile
        </button>
      </Box>
    </>
  );
};
