import { TextEditor } from "../../editor/TextEditor";
import React, { useEffect, useState } from "react";
import log from "../../../services/log";
import { Title } from "../../common/Title";
import { Box } from "@material-ui/core";
import {
  setOrgProfile,
  updateOrgProfile,
  setOrgProfileCache,
  getOrgProfile,
} from "../../../redux/actions/orgs";
import { useDispatch, useSelector } from "react-redux";
import { MyTooltipButtons } from "../../CustomComponents/MyButtons";
import { useFormik } from "formik";
import { orgProfile } from "../../schema/orgInputSchema";

export const OrgEditor = (props) => {
  const { handleCancel } = props;

  const orgs = useSelector((state) => state?.orgs?.orgs);
  const profile = useSelector((state) => state?.orgs?.orgprofile);
  const [eventAbout, setEventAbout] = useState(
    profile?.about
      ? JSON.parse(profile?.about)
      : [{ type: "paragraph", children: [{ text: "" }] }]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (orgs) {
      dispatch(
        getOrgProfile({
          metadata: {
            email: orgs[0]?.metadata?.email,
          },
        })
      );
    }
  }, [orgs, dispatch]);

  const orgprofile = useFormik({
    initialValues: {
      accountId: "",
      email: null,
      name: "",
      about: "",
      id: "",
    },
    validationSchema: orgProfile,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      log.info("clinicaltestinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["accountId"] = values.accountId;
      jsonData.metadata["email"] = values.email;
      jsonData["name"] = values.name;
      jsonData["about"] = values.about;

      log.info("org profile", jsonData);
      if (values.id !== "") {
        jsonData["id"] = values.id;
        dispatch(updateOrgProfile(jsonData));
      } else {
        dispatch(setOrgProfile(jsonData));
      }
      handleCancel();
    },
    onReset: (values) => {},
  });

  useEffect(() => {
    if (orgs?.length > 0) {
      orgprofile.setFieldValue("accountId", orgs[0]?.metadata?.accountId);
      orgprofile.setFieldValue("email", orgs[0]?.metadata?.email);
      orgprofile.setFieldValue("name", orgs[0]?.orgName);
    }
  }, [orgs]);

  useEffect(() => {
    if (profile) {
      orgprofile.setFieldValue("id", profile?.id);
    }
  }, [profile]);

  const addTextEditor = (e) => {
    // log.info("noteseditor", e);
    setEventAbout(e);
  };

  const handleSaveAbout = () => {
    // log.info("orgeditor", eventAbout);
    dispatch(setOrgProfileCache(JSON.stringify(eventAbout)));
  };

  const handlePost = () => {
    // log.info("orgeditor post");
    orgprofile.setFieldValue("about", JSON.stringify(eventAbout));
    orgprofile.handleSubmit();
  };

  log.info("orgeditor", eventAbout);

  return (
    <Box mb={4}>
      <Box mb={2}>
        <div className="colfl0">
          <Title>About</Title>
        </div>
        <div className="colfr0">
          <MyTooltipButtons
            onClick={handlePost}
            btnKey={"post"}
            btnLabel={"Post"}
            enState={false}
          />
          &nbsp;
          <MyTooltipButtons
            onClick={handleSaveAbout}
            btnKey={"update"}
            btnLabel={"Save"}
            enState={false}
          />
          &nbsp;
          <MyTooltipButtons
            onClick={handleCancel}
            btnKey={"cancel"}
            btnLabel={"Cancel"}
            enState={false}
          />
        </div>
        <div className="clear"></div>
        <div className="flex-grow-1">
          <TextEditor
            editorId={"orgabout"}
            addTextEditor={addTextEditor}
            addToMsg={eventAbout}
            isProtect={false}
            isColm={true}
            isFullHeight={false}
          />
        </div>
      </Box>
    </Box>
  );
};
