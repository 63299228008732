import React, { useState, useEffect, useLayoutEffect } from "react";
import { Grid, Box } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClinicNotes, postClinicNote } from "../../../redux/actions/message";
import { ClinicalNotesView } from "../../CustomComponents/ClinicalNotesView";
import log from "../../../services/log";
import {
  buildClinicNotesQuery,
  buildUserClinicNotesQuery,
} from "../../dbcq/clinicnotesdb";

export function ClinicalNotesUSR() {
  const user = useSelector((state) => state?.auth?.user?.user);
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const patient = useSelector((state) => state.user?.selectedPatient);

  const clinicalnotes = useSelector((state) => state.messages?.clinicnotes);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const resetpage = { ...pagedata, page: 0 };
      const jsonData = buildUserClinicNotesQuery(
        resetpage,
        searchcontext,
        user
      );
      log.info("patient clinics", jsonData);
      dispatch(getClinicNotes(jsonData));
    }
  }, [user, dispatch]);

  return (
    <Grid container sx={{ width: "100%", overflow: "hidden" }} spacing={2}>
      <ClinicalNotesView
        clinicalnotes={clinicalnotes}
        patient={patient}
        title={""}
      />
    </Grid>
  );
}
