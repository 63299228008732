import { REPORT_DATA, REPORTS, LABGROUPS, MEDICINES } from "../actions/types";

const initialState = {};

export default function reportReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REPORT_DATA:
      return {
        ...state,
        reportData: payload.data,
      };

    case REPORTS:
      return {
        ...state,
        reports: payload.data,
      };
    case LABGROUPS:
      return {
        ...state,
        labgroups: payload.data,
      };
    case MEDICINES:
      return {
        ...state,
        medicines: payload,
      };
    default:
      return state;
  }
}
