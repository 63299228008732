import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import QueryMedicalPolicies from "../../insurance/QueryMedicalPolicies";
import { POLICY_DATA } from "../../../tests/POLICY_DATA";

export default function InsuInfoPHA() {
  //const patient                                               = "P001"
  //const data                                                  = POLICY_DATA.filter( itm=> itm.policy_holder==="P001" );
  const data = POLICY_DATA;

  const filterTitle = "Medical Policy";
  const err = "";

  const handleRowsDetail = (policy) => {
    console.log("Approve this ", policy);
  };

  return (
    <Grid container>
      <QueryMedicalPolicies
        handleRowsDetail={handleRowsDetail}
        data={data}
        filterTitle={filterTitle}
        err={err}
      />
    </Grid>
  );
}
