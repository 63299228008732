import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  SearchBoxStandard,
  StyledTableCell,
  StyledTableRow,
  tableParam,
} from "../../common/ConstantsAndValues";
import { MyIconButtons } from "../../CustomComponents/MyButtons";
import { labComponentQueries } from "../../utils/labsqueries";
import {
  getLabComponentInfo,
  resetLabComponentInfo,
} from "../../../redux/labs/labs.actions";
import log from "../../../services/log";

export function ClinicalMoreTestListRow(props) {
  const { row, index, handleRowClick } = props;

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [labcomp, setLabComp] = useState(null);

  const selectrow = useSelector((state) => state?.labs?.moreselect);
  const labcomponent = useSelector((state) => state?.labs?.labcomponent);

  const dispatch = useDispatch();

  useEffect(() => {
    if (labcomponent) {
      const labcomp = labcomponent[0];
      setLabComp(labcomp);
    } else {
      setLabComp(null);
    }
  }, [labcomponent]);

  useEffect(() => {
    // log.info("CTlistrow", row);
    if (selectrow) {
      const temp = selectrow && row._source.code === selectrow._source.code;
      setSelectedRow(temp);
    }
  }, [selectrow]);

  const handleClick = (row) => {
    // log.info("CTlist", row);
    if (row && row._source.code.startsWith("LP") === false) {
      // log.info("CTlist code", row._source.code);
      const newquery = labComponentQueries("loincNumber", row._source.code);
      log.info("moreselect", newquery);
      dispatch(getLabComponentInfo(newquery)).then((r) => {
        if (r.total.value && r.total.value > 0) {
          log.info("unitlab", r);
        }
      });
    } else {
      dispatch(resetLabComponentInfo(null));
    }
    setOpen(!open);
  };

  const handleFilter = () => {};

  const handleRowClickLocal = (e) => {
    // log.info("CTlist", e);
    const newstate = !selectedRow;
    setSelectedRow(newstate);
    handleRowClick(e, newstate);
  };

  // log.info("more clinical test", row._source.code.startsWith('lp'));

  return (
    <React.Fragment>
      <StyledTableRow
        key={"rr" + index}
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
        onClick={() => handleRowClickLocal(row)}
      >
        <StyledTableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <b>{row._source["code_text"]}</b>
        </StyledTableCell>
        <StyledTableCell align="right">
          <Typography variant={"body2"}>
            {row._source.code.startsWith("LP") ? "group" : "unit"}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="right">
          {row._source.instructions}
        </StyledTableCell>
      </StyledTableRow>

      <TableRow
        className="text-color-gray bg-standard-offwhite"
        key={"rrd" + index}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="text-color-primary">Test Information</div>
                <div className="">
                  <SearchBoxStandard
                    onChange={handleFilter}
                    placeholder="Property Name"
                    sx={{ marginRight: 1 }}
                    className="text-color-primary text-color-2-border text-color-2-bg"
                  />
                </div>
              </div>

              <Box
                className=" list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow className="bg-gray">
                      <TableCell>Properties</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={"store" + row._source["Id"]}>
                      <TableCell>{"Group"}</TableCell>
                      <TableCell>{row._source.groupName}</TableCell>
                    </TableRow>
                    <TableRow key={"preg" + row._source["Id"]}>
                      <TableCell>{"Turnaround"}</TableCell>
                      <TableCell>{row._source.turnaround}</TableCell>
                    </TableRow>
                    {labcomp && (
                      <>
                        <TableRow key={"name"}>
                          <TableCell>{"Name"}</TableCell>
                          <TableCell>{labcomp._source.displayName}</TableCell>
                        </TableRow>
                        <TableRow key={"system"}>
                          <TableCell>{"System"}</TableCell>
                          <TableCell>{labcomp._source.system}</TableCell>
                        </TableRow>
                        <TableRow key={"scale"}>
                          <TableCell>{"Scale"}</TableCell>
                          <TableCell>{labcomp._source.scaleType}</TableCell>
                        </TableRow>
                        <TableRow key={"unit"}>
                          <TableCell>{"Unit"}</TableCell>
                          <TableCell>{labcomp._source.unit}</TableCell>
                        </TableRow>
                        <TableRow key={"descp"}>
                          <TableCell>{"Description"}</TableCell>
                          <TableCell>{labcomp._source.descrip}</TableCell>
                        </TableRow>
                        <TableRow key={"status"}>
                          <TableCell>{"Status"}</TableCell>
                          <TableCell>{labcomp._source.status}</TableCell>
                        </TableRow>
                        <TableRow key={"code"}>
                          <TableCell>{"Code"}</TableCell>
                          <TableCell>{labcomp._source.loincNumber}</TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
