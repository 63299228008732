import React, { useRef, useState } from "react";
import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import { CancelIcon } from "../customIcons/CancelIcon";
import { Box } from "@mui/material";
import { ChevronRightFeed } from "../customIcons/ChevronRightFeed";
import { AttachmentIcon } from "../customIcons/AttachmentIcon";
import { BlackCrossIcon } from "../customIcons/BlackCrossIcon";

// import { ChevronRightFeed } from "../customIcons/ChevronRight";
import { useSelector, useDispatch } from "react-redux";
import {
  uploadNewFeedbackImage,
  sendUserFeedback,
} from "../../redux/feedback/feedback.actions";
import Spinner from "../Loader/index";
import { FeedbackActionTypes } from "../../redux/feedback/feedback.types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { GreenTickIcon } from "../customIcons/GreenTickIcon";

const useStyles = makeStyles((theme) => ({
  backDrop: {
    backdropFilter: "blur(2px)",
    backgroundColor: "rgba(0,0,0,0.2)",
    // boxShadow: "none",
  },
  paper: {
    boxShadow: "none",
  },
}));

export default function FeedbackModal({ setModalState }) {
  const classes = useStyles();

  // const [isUploadingImage, setIsUploadingImage] = useState(false);
  const fileData = useSelector((state) => state.feedback);
  const profile = useSelector((state) => state.auth.user.user);

  // redux states
  const uploadedFile = fileData.imageFile;
  const loader = fileData.isUploadingImage;
  const formLoader = fileData.isLoading;
  const [formstate, setFormState] = useState(0);

  // for file reference input
  const fileInput = useRef();

  const dispatch = useDispatch();

  const handleImage = async (e) => {
    // setIsUploadingImage(true);
    const file = e.target.files[0];
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg" ||
      file.type === "image/jpg" ||
      file.type === "image/webp" ||
      file.type === "image/svg+xml" ||
      file.type === "application/pdf"
    ) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        await dispatch(uploadNewFeedbackImage(formData));
      } catch (err) {
        console.error(err);
      }
    } else {
      // setIsUploadingImage(false);
    }
  };

  const removeImage = () => {
    dispatch({
      type: FeedbackActionTypes.RESET_IMAGE,
    });
  };

  // feedback inital values
  const feedBackValueSchema = () => {
    return {
      feedback: "",
    };
  };

  const feedBackValidateSchema = Yup.object().shape({
    feedback: Yup.string().required("Please enter a valid Feedback"),
  });

  const submitNow = async (values, { resetForm }) => {
    var params = {
      metadata: {
        providerId: "test",
        role: "metauser",
      },
      name: profile?.name !== undefined ? profile?.name : "Metalane User",
      feedback: values.feedback,
    };
    if (uploadedFile) {
      params.file = uploadedFile;
    }

    let response = await dispatch(sendUserFeedback(params));

    if (response.code === 201) {
      setFormState(1);
      dispatch({
        type: FeedbackActionTypes.RESET_IMAGE,
      });
    }
  };

  return (
    <Dialog
      open={true}
      classes={{ paper: classes.paper }}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      onClose={() => {}}
    >
      {formstate === 1 ? (
        <DialogContent className="feedbox text-center">
          <Box sx={{ display: "flex" }}>
            <Box className="cancelIcon" onClick={() => setModalState(false)}>
              <CancelIcon size={0.8} />
            </Box>
          </Box>
          <Box sx={{ py: 2 }}>
            <GreenTickIcon size={0.8} />
            <h3 className="feedhead">
              Thank you for submitting your feedback.
            </h3>
            <h4 className="feeddes">
              Our team will review your message, and get back to you if needed.
            </h4>
          </Box>
        </DialogContent>
      ) : (
        <Formik
          initialValues={feedBackValueSchema()}
          validationSchema={feedBackValidateSchema}
          onSubmit={submitNow}
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogContent className="feedbox">
                <Box sx={{ display: "flex" }}>
                  <h3 className="feedhead">We’d love to hear your feedback!</h3>
                  <Box
                    className="cancelIcon"
                    onClick={() => setModalState(false)}
                  >
                    <CancelIcon size={0.8} />
                  </Box>
                </Box>
                <Field
                  name="feedback"
                  component="textarea"
                  rows={8}
                  placeholder="Enter your feedback (200 word limit)"
                  className="feedback_textarea"
                />
                <ErrorMessage
                  name="feedback"
                  component="div"
                  className="error-msg pl-1"
                />
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ mt: 0.4 }}>
                    {uploadedFile !== null ? (
                      <Box onClick={removeImage} className="cursor-pointer">
                        <BlackCrossIcon size={0.9} />
                      </Box>
                    ) : (
                      <AttachmentIcon size={0.9} />
                    )}
                  </Box>
                  {uploadedFile !== null ? (
                    <div className="myfileupload-buttonbar">
                      <label className="myui-button">
                        <span className="attachfile_text_success">
                          Image successfully Uploaded
                        </span>
                      </label>
                    </div>
                  ) : (
                    <div className="myfileupload-buttonbar">
                      <label className="myui-button">
                        <span className="attachfile_text">
                          Accepted file types up to 1 MB (jpg, png, pdf)
                        </span>
                        <input
                          type="file"
                          name="myfile"
                          className="cursor-pointer"
                          ref={fileInput}
                          onChange={(event) => handleImage(event)}
                        />
                      </label>
                    </div>
                  )}
                  {loader && <Spinner />}
                </Box>
                <button
                  className="feed_button"
                  type="submit"
                  disabled={loader || formLoader}
                >
                  {!formLoader ? (
                    <>
                      Send Feedback
                      <ChevronRightFeed size={0.8} />
                    </>
                  ) : (
                    <Spinner />
                  )}
                </button>
              </DialogContent>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
}
