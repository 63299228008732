import { useState } from "react";
import { useSelector } from "react-redux";

import { ClinicalMoreTestList } from "./ClinicalMoreTestList"; //COMMON
import { ClinicalTestsList } from "./ClinicalTestsList"; //COMMON

import log from "../../../services/log";

export const ClinicalTestsListLAB = (props) => {
  const searchtext = useSelector((state) =>
    state.user.searchtext ? state.user.searchtext : null
  );
  let searchcontext = useSelector((state) =>
    state.user.searchtext ? state?.user?.searchcontext : null
  );
  const [actionState, setActionState] = useState("");

  // log.info("ctlist searchtext", searchtext);
  // log.info("ctlist searchcontext", searchcontext);
  searchcontext = { ...searchcontext, name: searchtext };

  const handleCancel = () => {
    setActionState("");
  };

  log.info("ctlist searchtext", actionState);

  return (
    <div>
      {actionState === "moretests" ? (
        <ClinicalMoreTestList handleBack={handleCancel} />
      ) : (
        <ClinicalTestsList
          searchText={searchtext && searchcontext}
          setActionState={setActionState}
        />
      )}
    </div>
  );
};
