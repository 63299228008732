import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTable } from "../styles/InvoiceStyles";

import { InvoiceTableHeader } from "./InvoiceTableHeader";
import { InvoiceTableRows, InvoiceTableRowsBlank } from "./InvoiceTableRows";
import { InvoiceTableFooter } from "./InvoiceTableFooter";

const styles = stylesTable;

export const InvoiceItemsTable = ({ invoice, tableRowsCount }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader />
    <InvoiceTableRows items={invoice} />
    <InvoiceTableRowsBlank rowsCount={tableRowsCount - invoice.length} />
    <InvoiceTableFooter items={invoice} />
  </View>
);
