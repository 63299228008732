import * as React from "react";
import {
  StyledTableRowHead,
  StyledTableCellHead,
} from "../../common/ConstantsAndValues";

export const drugsTable = {
  medicine: {
    label: "OTC Medicine Name",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  category: {
    label: "Medicine Class",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
};

export function DrugsListOTCHead() {
  const table = drugsTable;

  return (
    <React.Fragment>
      <StyledTableRowHead style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead>{table.medicine.label}</StyledTableCellHead>
        <StyledTableCellHead align="left">
          {table.category.label}
        </StyledTableCellHead>
      </StyledTableRowHead>
    </React.Fragment>
  );
}
