import { TableContainer } from "@mui/material";
import MyAccountSetup from "../../payments/MyAccountSetup";
import { Paper } from "@mui/material";

export function SetProfileList() {
  return (
    <TableContainer component={Paper}>
      <MyAccountSetup />
    </TableContainer>
  );
}
