import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import {
  getUserGroup,
  selectedPatient,
} from "../../../redux/user/user.actions";
import { SearchBoxStandard } from "../../common/ConstantsAndValues";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import useStyles from "../../CustomComponents/useStyles";
import { PatientListMDRow } from "./PatientListMDRow";
import { PatientListMDHead } from "./PatientListMDHead";
import { MyTooltipButtons } from "../../CustomComponents/MyButtons";
import { buildDepartmentGroupUserGroupQuery } from "../../dbcq/usergroupdb";
import log from "../../../services/log";

export function PatientListMD(props) {
  const selectgroup = useSelector((state) => state?.user?.selectmdgroup);
  const datalist = useSelector((state) => state?.user?.usergroup);
  const searchcontext = useSelector((state) => state?.user?.searchcontext);
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [rows, setRows] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setRows(datalist ?? null);
  }, [searchcontext, datalist]);

  useEffect(() => {
    // log.info("drug serarch query", searchtext);
    const resetpage = { ...pagedata, page: 0 };
    const jsonData = buildDepartmentGroupUserGroupQuery(
      resetpage,
      searchcontext,
      selectgroup
    );
    dispatch(getUserGroup(jsonData)).then((r) => {
      // log.info("datalist", r)
      setPage({
        ...pagedata,
        count: r?.totalResults,
        pages: r?.totalPages,
      });
    });
  }, [searchcontext, dispatch]);

  const handleFilter = (event) => {
    const keyword = event.target.value;
    // log.info("datalist", keyword);
    const newlist = keyword
      ? rows.filter((v) =>
          v.orgName.toLowerCase().startsWith(keyword.toLowerCase())
        )
      : datalist?.results;
    setRows(newlist);
  };

  const handleChangePage = (event, value) => {
    const newpagedata = { ...pagedata, page: parseInt(value) };
    // log.info("datalist nextpage", searchquery);
    const jsonData = buildDepartmentGroupUserGroupQuery(
      newpagedata,
      searchcontext,
      selectgroup
    );
    dispatch(getUserGroup(jsonData)).then((r) => {
      // log.info("datalist", r)
      setPage({
        ...pagedata,
        count: r?.totalResults,
        pages: r?.totalPages,
      });
    });
  };

  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };
  const handleRowClick = (row, add) => {
    // log.info("Prescribe", row);
    // log.info("Prescribe", add);

    if (row && add === true) {
      setCheckedRows(row);
      dispatch(selectedPatient(row));
    }

    if (row && add === false) {
      setCheckedRows(null);
      dispatch(selectedPatient(null));
    }
  };

  // log.info("patient serchcontext", searchcontext);
  // log.info("patient serchcontext", searchtext);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          className="flex-grow-1"
          style={{
            display: "flex",
            justifyContent: "space-between",
            lineHeight: "36px",
            width: "100%",
          }}
        >
          <div>
            <div className="colfr0" width="100%">
              <SearchBoxStandard
                onChange={handleFilter}
                placeholder="Patient Name"
                sx={{ marginRight: 1 }}
                className="text-color-primary text-color-2-border text-color-2-bg"
              />
            </div>
          </div>
          <div>
            <MyTooltipButtons
              // onClick    = {appointmentForm.handleSubmit}
              btnKey={"add"}
              btnLabel={"Add to my list"}
              enState={false}
            />
          </div>
        </div>
        <div style={{ width: "100%", marginTop: "16px" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="collapsible table">
              <TableHead>
                <PatientListMDHead />
              </TableHead>

              <TableBody className="text-color-primary text text-md">
                {rows &&
                  rows.map((row) => (
                    <PatientListMDRow
                      key={row.id}
                      row={row}
                      handleRowClick={handleRowClick}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </Grid>
    </Grid>
  );
}
