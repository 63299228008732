import React, { useEffect, useState } from "react";

export const BlackCrossIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1054 16.6966C15.4959 17.0872 16.1291 17.0872 16.5196 16.6966C16.9101 16.3061 16.9102 15.673 16.5196 15.2824L13.4481 12.2109L16.5196 9.13944C16.9101 8.74892 16.9101 8.11575 16.5196 7.72523C16.1291 7.33471 15.4959 7.33471 15.1054 7.72523L12.0339 10.7967L8.96242 7.72523C8.5719 7.33471 7.93873 7.3347 7.54821 7.72523C7.15768 8.11575 7.15768 8.74892 7.54821 9.13944L10.6197 12.2109L7.54821 15.2824C7.15768 15.673 7.15768 16.3061 7.54821 16.6966C7.93873 17.0872 8.5719 17.0872 8.96242 16.6966L12.0339 13.6252L15.1054 16.6966Z"
        fill="#585858"
      />
      <rect
        x="2.23438"
        y="2.41406"
        width="19.592"
        height="19.592"
        rx="9.79598"
        stroke="#585858"
        strokeWidth="2"
      />
    </svg>
  );
};
