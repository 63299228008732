import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Collapse,
} from "@mui/material";
import { tableParam } from "../../common/ConstantsAndValues";
import { MyIconButtons } from "../../CustomComponents/MyButtons";
import { getPatientProfile } from "../../../redux/user/user.actions";
import { dateFormat } from "../../common/dateFormat";
import log from "../../../services/log";

export function PatientListRow(props) {
  const { row, selectRow, selectedRows } = props;

  const profile = useSelector((state) => state.user.patientprofile);
  const [open, setOpen] = useState(false);
  const [maplist, setMaplist] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    log.info("patientlistrow", row);
    if (row) {
      // dispatch(getPatientProfile(
      //     {metadata:{accountId: row._source.accountId}}))
    }
  }, [row]);

  useEffect(() => {
    // set row state on reload
    if (selectedRows && row) {
      const selectrow = selectedRows.find((r) => r.id === row.id);
      if (selectrow) {
        setSelectedRow(true);
      }
    }
  }, []);

  useEffect(() => {
    if (profile) {
      const listdata = [];
      // listdata.push({id: "gender", label: "Gender", value: profile.gender})
      // listdata.push({id: "dob", label: "Bithday", value: dateFormat(profile.dob)})
      // listdata.push({id: "date", label: "Member since", value: dateFormat(profile.created_at)})
      setMaplist(listdata);
    }
  }, [profile]);

  const handleClick = () => {
    log.info("ct row", row);
    // if (row) {
    //     const jsonData = {};
    //     jsonData['metadata'] = {};
    //     jsonData.metadata['metalabId'] = row.labId;
    //     dispatch(getMetaLabMap(jsonData)).then((r) => {
    //         log.info("ct row", r);
    //         setMaplist(r)
    //     });
    // }
    setOpen(!open);
  };

  const handleFilter = () => {};

  const handelRowlocal = (row) => {
    // log.info("patientlist", row);
    setSelectedRow(!selectedRow);
    selectRow(row);
  };

  // log.info("patientrow", row);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => handelRowlocal(row)}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
      >
        <TableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </TableCell>
        <TableCell component="th" scope="row">
          <b>{row.name}</b>
        </TableCell>
        <TableCell align="right">{dateFormat(row.created_at)}</TableCell>
      </TableRow>

      <TableRow className="text-color-gray bg-standard-offwhite">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">
                  Patient information
                </div>
              </div>

              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {maplist &&
                      maplist.map((m) => (
                        <TableRow key={m.id}>
                          <TableCell>{m.label}</TableCell>
                          <TableCell>{m.value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
