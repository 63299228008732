import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Stepper, Step, StepButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import InputComponent from "../../CustomComponents/InputComponent";
import { MyButtons, MyTooltipButtons } from "../../CustomComponents/MyButtons";
import {
  getPatientProfile,
  getPatientUser,
  setUserGroup,
} from "../../../redux/user/user.actions";
import { useFormik } from "formik";
import { userRegisterSchema } from "../../schema/userSchema";
import log from "../../../services/log";

export default function PatientStepper(props) {
  const { handleDone } = props;
  const orguser = useSelector((state) => state?.auth?.user?.user);
  const puser = useSelector((state) => state?.user?.user);
  const patientprofile = useSelector((state) => state?.user?.patientprofile);
  const department = useSelector((state) => state?.orgs?.selectdepartment);
  const deptaddress = useSelector((state) => state?.addresses?.deptaddress);

  const steps = ["Lookup by registered email", "Register to organization"];
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState([]);
  const [values, setValues] = useState({
    nameError: false,
    emailError: false,
    emailerrortext: "a valid email address is required",
  });

  const defaultdob = new Date("01 January 1901 00:00 UTC");
  const dispatch = useDispatch();

  const patientform = useFormik({
    initialValues: {
      orgId: null,
      groupId: null,
      departmentId: null,
      loincNumber: null,
      providerId: "",
      providerRole: null,
      name: null,
      patientId: null,
      dob: defaultdob.toISOString(),
      status: "active",
    },

    validationSchema: userRegisterSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // log.info("clinicaltestinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData.metadata = {};
      jsonData.metadata.patientId = values.patientId;
      jsonData.metadata.orgId = values.orgId;
      jsonData.metadata.groupId = values.groupId;
      jsonData.metadata.departmentId = values.departmentId;
      jsonData.metadata.email = values.email;
      jsonData.metadata.providerId = values.providerId;
      jsonData.metadata.providerRole = values.provierRole;
      jsonData.metadata.loincNumber = values.loincNumber;
      jsonData.name = values.name;
      jsonData.dob = values.dob;
      jsonData.status = values.status;

      // log.info("patient input", jsonData);
      dispatch(setUserGroup(jsonData));
      handleDone();
    },
    onReset: (values) => {},
  });

  useEffect(() => {
    if (patientprofile) {
      patientform.setFieldValue("dob", patientprofile?.dob);
    }
  }, [patientprofile]);

  useEffect(() => {
    if (puser) {
      patientform.setFieldValue("name", puser?.name);
      patientform.setFieldValue("patientId", puser?.metadata?.accountId);
    }
  }, [puser]);

  useEffect(() => {
    patientform.setFieldValue("providerRole", orguser?.metadata?.role);
  }, []);

  useEffect(() => {
    if (department) {
      patientform.setFieldValue("orgId", department?.metadata?.orgId);
      patientform.setFieldValue("groupId", department?.metadata?.groupId);
      patientform.setFieldValue(
        "departmentId",
        department?.metadata?.accountId
      );
      patientform.setFieldValue("loincNumber", department.metadata.loincNumber);
      patientform.setFieldValue("providerId", department.metadata.accountId);
    }
  }, [department]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    // log.info("addressstepper", activeStep)

    if (activeStep == 0) {
      let jsonData = {};
      jsonData.metadata = {};
      jsonData.metadata.role = "metauser";
      jsonData.metadata.email = patientform.values.email;

      // log.info("addressstepper", jsonData)
      dispatch(getPatientUser(jsonData));

      const jsonData2 = {};
      jsonData2.metadata = {};
      jsonData2.metadata.email = patientform.values.email;
      // get user profile
      dispatch(getPatientProfile(jsonData2));
    }

    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  log.info("patient stepper", patientform.errors);

  return (
    <Box sx={{ maxWidth: "100%" }} mt={1} className="">
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div sx={{ marginTop: "10px" }} className="text-color-primary ">
        {allStepsCompleted() ? (
          <React.Fragment>
            <center>
              <Typography
                sx={{ padding: "32px 0px 16px 0px" }}
                className="text-color-primary btn-round"
              >
                All steps are complete.
              </Typography>
              <Typography className="text-color-dark">
                To proceed click on DONE
              </Typography>
              <Typography
                sx={{ padding: "0px 0px 16px 0px" }}
                className="text-color-gray"
              >
                <i>or</i> RESET <i>to repeat steps all over again</i>
              </Typography>
            </center>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                justifyContent: "flex-end",
              }}
              className="border-dbt"
            >
              <MyButtons
                onClick={handleReset}
                btnKey={"reset"}
                btnLabel={"Reset"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyButtons
                onClick={patientform.submitForm}
                btnKey={"done"}
                btnLabel={"Done"}
                enState={false}
              />
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography
              sx={{ padding: "16px 0px 8px 0px" }}
              className="text-color-primary-highlight"
            >
              Step {activeStep + 1}
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "column" },
                  justifyContent: "center",
                  alignItems: "top",
                  height: "100%",
                  width: "100%",
                  margin: "16px 16px 16px 0px",
                }}
                className="clear"
              >
                {activeStep == 0 && (
                  <Box>
                    <InputComponent
                      id="email"
                      name="email"
                      label="Email"
                      defaultValue={patientform.values.email}
                      errors={patientform.errors.email ? true : false}
                      errorText={patientform.errors.email}
                      variant="standard"
                      handleChange={patientform.handleChange}
                    />
                  </Box>
                )}
                {activeStep == 1 && (
                  <Box>
                    <InputComponent
                      id="DisplayName"
                      name="name"
                      label="Name"
                      defaultValue={patientform.values.name}
                      errors={patientform.errors.name ? true : false}
                      variant="standard"
                      handleChange={patientform.handleChange}
                    />
                    &nbsp; &nbsp;
                    <InputComponent
                      id="email"
                      name="email"
                      label="Email"
                      defaultValue={patientform.values.email}
                      errors={patientform.errors.email ? true : false}
                      errorText={patientform.errors.email}
                      variant="standard"
                      handleChange={patientform.handleChange}
                    />
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                justifyContent: "space-between",
              }}
              className=""
            >
              <Box>
                <MyTooltipButtons
                  onClick={handleDone}
                  btnKey={"cancel"}
                  btnLabel={"Cancel"}
                  enState={false}
                />
                &nbsp; &nbsp;
                <MyButtons
                  onClick={handleBack}
                  btnKey={"back"}
                  btnLabel={"Back"}
                  enState={activeStep === 0}
                />
                &nbsp; &nbsp;
              </Box>
              <Box>
                <MyButtons
                  onClick={handleNext}
                  btnKey={"next"}
                  btnLabel={"Next"}
                  enState={false}
                />
                &nbsp; &nbsp;
                {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Typography
                      variant="caption"
                      sx={{ display: "inline-block" }}
                      className="text-color-primary-highlight my10"
                    >
                      Step {activeStep + 1} Completed
                    </Typography>
                  ) : (
                    <MyButtons
                      onClick={handleComplete}
                      btnKey={
                        completedSteps() === totalSteps() - 1
                          ? "add"
                          : "completestep"
                      }
                      btnLabel={
                        completedSteps() === totalSteps() - 1
                          ? "Add"
                          : "Complete Step"
                      }
                      enState={false}
                    />
                  ))}
              </Box>
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
