import OHIF from "../../core";
import { connect } from "react-redux";
import findDisplaySetByUID from "./findDisplaySetByUID";
import { StudyBrowser } from "../../ui/components/studyBrowser";
import { servicesManager } from "../../DicomView";

const { setActiveViewportSpecificData } = OHIF.reduxdicom.actions;

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onThumbnailClick: (displaySetInstanceUID) => {
      let displaySet = findDisplaySetByUID(
        ownProps.studyMetadata,
        displaySetInstanceUID
      );

      const { LoggerService, UINotificationService } = servicesManager.services;

      console.log("studybrowser", displaySet);

      if (displaySet.isDerived) {
        const { Modality } = displaySet;
        console.log("studybrowser", Modality);

        if (Modality === "SEG" && servicesManager) {
          const onDisplaySetLoadFailureHandler = (error) => {
            const message =
              error.message.includes("orthogonal") ||
              error.message.includes("oblique")
                ? "The segmentation has been detected as not planar,\
          If you really think it is planar,\
          please adjust the tolerance in the segmentation panel settings (at your own peril!)"
                : error.message;

            LoggerService.error({ error, message });
            UINotificationService.show({
              title: "DICOM Segmentation Loader",
              message,
              type: "error",
              autoClose: false,
            });
          };

          const { referencedDisplaySet, activatedLabelmapPromise } =
            displaySet.getSourceDisplaySet(
              ownProps.studyMetadata,
              true,
              onDisplaySetLoadFailureHandler
            );
          displaySet = referencedDisplaySet;

          activatedLabelmapPromise.then((activatedLabelmapIndex) => {
            const selectionFired = new CustomEvent(
              "extensiondicomsegmentationsegselected",
              {
                detail: { activatedLabelmapIndex: activatedLabelmapIndex },
              }
            );
            document.dispatchEvent(selectionFired);
          });
        } else if (Modality !== "SR") {
          displaySet = displaySet.getSourceDisplaySet(ownProps.studyMetadata);
        }

        if (!displaySet) {
          const error = new Error(
            `Referenced series for ${Modality} dataset not present.`
          );
          const message = `Referenced series for ${Modality} dataset not present.`;
          LoggerService.error({ error, message });
          UINotificationService.show({
            autoClose: false,
            title: "Fail to load series",
            message,
            type: "error",
          });
        }
      }

      if (!displaySet) {
        const error = new Error("Source data not present");
        const message = "Source data not present";
        LoggerService.error({ error, message });
        UINotificationService.show({
          autoClose: false,
          title: "Fail to load series",
          message,
          type: "error",
        });
      }

      if (displaySet.isSOPClassUIDSupported === false) {
        const error = new Error("Modality not supported");
        const message = "Modality not supported";
        LoggerService.error({ error, message });
        UINotificationService.show({
          autoClose: false,
          title: "Fail to load series",
          message,
          type: "error",
        });
      }

      console.log("studybrowser", displaySet);
      dispatch(setActiveViewportSpecificData(displaySet));
    },
  };
};

const ConnectedStudyBrowser = connect(null, mapDispatchToProps)(StudyBrowser);

export default ConnectedStudyBrowser;
