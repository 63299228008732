import { connect } from "react-redux";
import Viewer from "./Viewer.js";
import OHIF from "../../core";

const { setTimepoints, setMeasurements } = OHIF.reduxdicom.actions;

const getActiveServer = (servers) => {
  console.log(servers);
  const isActive = (a) => a.active === true;
  return servers.servers.find(isActive);
};

const mapStateToProps = (state) => {
  const { viewports, servers } = state;
  console.log("viewers", viewports);
  return {
    viewports: viewports.viewportSpecificData,
    activeViewportIndex: viewports.activeViewportIndex,
    activeServer: getActiveServer(servers),
  };
};

const mapDispatchToProps = (dispatch) => {
  console.log("viewerdispatch");
  return {
    onTimepointsUpdated: (timepoints) => {
      console.log("viewerdispatch", timepoints);
      dispatch(setTimepoints(timepoints));
    },
    onMeasurementsUpdated: (measurements) => {
      console.log("viewerdispatch", measurements);
      dispatch(setMeasurements(measurements));
    },
  };
};

const ConnectedViewer = connect(mapStateToProps, mapDispatchToProps)(Viewer);

export default ConnectedViewer;
