import React, { useEffect, useState } from "react";

export const GreenTickIcon = (props) => {
  const { height = "50", width = "50", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
    >
      <circle cx="25.0078" cy="25.25" r="25" fill="#52A72A" />
      <path
        d="M10.815 25.9272C11.3357 25.4065 12.1799 25.4065 12.7006 25.9272L20.2402 33.4668C20.7609 33.9875 20.7609 34.8317 20.2402 35.3524C19.7195 35.8731 18.8753 35.8731 18.3546 35.3524L10.815 27.8128C10.2943 27.2921 10.2943 26.4479 10.815 25.9272Z"
        fill="white"
      />
      <rect
        x="17.4766"
        y="34.4609"
        width="29.5775"
        height="2.66667"
        rx="1.33333"
        transform="rotate(-45 17.4766 34.4609)"
        fill="white"
      />
    </svg>
  );
};
