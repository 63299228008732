import { LabsActionTypes } from "./labs.types";
import { searchService } from "../../services/location-service";

import {
  getLabsService,
  getLabsMapService,
  setLabOrderService,
  getLabOrderService,
  getRadOrderService,
  setRadOrderService,
} from "../../services/reports-service";

export const getLabsList = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        // console.log("received labs", response.data.hits);
        const labkey = new Set();
        const dlist = [];
        response?.data?.data?.hits?.forEach((d) => {
          if (labkey.has(d._source.labId) === false) {
            labkey.add(d._source.labId);
            dlist.push(d);
          }
        });

        dispatch({
          type: LabsActionTypes.LABS_LIST,
          payload: dlist,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LabsActionTypes.LABS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getPanelLabList = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        // console.log("received labs", response.data.hits);
        const labkey = new Set();
        const dlist = [];
        response?.data?.data?.hits?.forEach((d) => {
          if (labkey.has(d._source.parentId) === false) {
            labkey.add(d._source.parentId);
            dlist.push(d);
          }
        });
        dispatch({
          type: LabsActionTypes.LABPANELS_LIST,
          payload: dlist,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }

    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LabsActionTypes.LABS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getPanelTestList = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        // console.log("received labs", response.data.hits);
        dispatch({
          type: LabsActionTypes.PANELTESTS_LIST,
          payload: response?.data?.data.hits,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LabsActionTypes.LABS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getMoreLabsList = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      // console.log("received labs", response.data.hits);
      // console.log("received more list", response.data.hits);
      // const labkey = new Set();
      // const dlist = response.data.hits.filter((d) => d._source.code.startsWith('LP') === false);

      // response.data.hits.forEach((d) => {
      //     if (labkey.has(d._source.labId) === false) {
      //         labkey.add(d._source.labId);
      //         dlist.push(d)
      //     }
      // });

      if (response?.data?.code == 200) {
        dispatch({
          type: LabsActionTypes.LABSMORE_LIST,
          payload: response?.data?.data.hits,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LabsActionTypes.LABS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getMoreRadLabsList = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        dispatch({
          type: LabsActionTypes.RADLABSMORE_LIST,
          payload: response?.data?.data.hits,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LabsActionTypes.LABS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getRadlabsList = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        // console.log("received labs", response.data.hits);
        const labkey = new Set();
        const dlist = [];
        response?.data?.data?.hits.forEach((d) => {
          if (labkey.has(d._source.labId) === false) {
            labkey.add(d._source.labId);
            dlist.push(d);
          }
        });

        dispatch({
          type: LabsActionTypes.RADLABS_LIST,
          payload: dlist,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LabsActionTypes.LABS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getPanelLabComponentInfo = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        // console.log("received labs", response.data.hits);
        dispatch({
          type: LabsActionTypes.LABPANEL_COMPONENT,
          payload: response?.data?.data?.hits,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LabsActionTypes.LABS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getLabComponentInfo = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        // console.log("received labs", response.data.hits);
        dispatch({
          type: LabsActionTypes.LAB_COMPONENT,
          payload: response?.data?.data.hits,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LabsActionTypes.LABS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const selectRadLabMoreRow = (payload) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.RADLABMORE_SELECTROW,
    payload: payload,
  });
};

export const resetLabComponentInfo = (payload) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.LAB_COMPONENT,
    payload: payload,
  });
};

export const getRadLabsSearchList = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        const labkey = new Set();
        const dlist = [];
        response?.data?.data?.hits?.forEach((d) => {
          if (labkey.has(d._source.loinc_num) === false) {
            labkey.add(d._source.loinc_num);
            dlist.push(d);
          }
        });
        dispatch({
          type: LabsActionTypes.RADLABS_SEARCHLIST,
          payload: dlist,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LabsActionTypes.LABS_LIST,
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const selectRadLabsRow = (data) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.RADLABS_SELECTROW,
    payload: data,
  });
};

export const selectMoreRadLabRow = (data) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.RADLABS_SELECTROW,
    payload: data,
  });
};

export const selectLabsRow = (data) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.LABS_SELECTROW,
    payload: data,
  });
};

export const selectMoreLabRow = (data) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.LABMORE_SELECTROW,
    payload: data,
  });
};

export const selectLabPanelRow = (data) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.PANEL_SELECT,
    payload: data,
  });
};

export const setLabsOrderPresList = (data) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.LABS_ORDER,
    payload: data,
  });
};

export const selectLabsOrderListRow = (data) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.LABS_ORDERSELECT,
    payload: data,
  });
};

export const selectLabsOrderPresListRow = (data) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.LABSPRES_SELECTROW,
    payload: data,
  });
};

export const selectRadlabsPresListRow = (data) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.RADLABS_PRESSELECT,
    payload: data,
  });
};

export const setRadlabsOrderPresList = (data) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.RADLABS_PRESLIST,
    payload: data,
  });
};

export const getLabsOrderList = (payload) => (dispatch) => {
  return getLabOrderService(payload).then(
    (response) => {
      dispatch({
        type: LabsActionTypes.LABS_ORDERLIST,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const setLabsOrder = (payload) => (dispatch) => {
  return setLabOrderService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getMetaLabsSearch = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        dispatch({
          type: LabsActionTypes.LABS_SEARCHLIST,
          payload: response?.data?.data,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getMetaLabs = (payload) => (dispatch) => {
  return getLabsService(payload).then(
    (response) => {
      dispatch({
        type: LabsActionTypes.METALABS_LIST,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getMetaLabMap = (payload) => (dispatch) => {
  return getLabsMapService(payload).then(
    (response) => {
      dispatch({
        type: LabsActionTypes.LABS_MAP,
        payload: response.data.results,
      });
      return Promise.resolve(response.data.results);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const setMetlabRoot = (data) => (dispatch) => {
  return dispatch({
    type: LabsActionTypes.METALABS_ROOT,
    payload: data,
  });
};

export const getRadOrderList = (payload) => (dispatch) => {
  return getRadOrderService(payload).then(
    (response) => {
      dispatch({
        type: LabsActionTypes.RADLABS_ORDERLIST,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const setRadlabsOrder = (payload) => (dispatch) => {
  return setRadOrderService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};
