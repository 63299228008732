import React from "react";
import SignNewpass from "../components/sign/sign-newpass";

import { Gen400Container } from "../components/common/ConstantsAndValues";

export const SignNewpassPage = () => (
  <Gen400Container>
    <SignNewpass />
  </Gen400Container>
);
