// switch cases for UI
export function rswitch(param, cases) {
  if (cases[param]) {
    return cases[param];
  } else {
    return cases.default;
  }
}

export const setLocalStorage = (storageKeyName, data) => {
  if (!data) return;
  localStorage.setItem(storageKeyName, JSON.stringify(data));
};

export const getLocalStorage = (storageKeyName) => {
  return localStorage.getItem(storageKeyName);
};

export const deleteLocalStorage = (storageKeyName) => {
  return localStorage.removeItem(storageKeyName);
};

export const deleteAllLocalStorage = () => {
  return localStorage.clear();
};
