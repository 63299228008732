import { DirectoryActionTypes } from "../directory/directory.types";
import log from "../../services/log";
import { searchService } from "../../services/location-service";
import { LabsActionTypes } from "../labs/labs.types";

export const getProviderList = (payload) => (dispatch) => {
  return searchService(payload).then(
    (response) => {
      if (response?.data?.code == 200) {
        dispatch({
          type: DirectoryActionTypes.PROVIDER_LIST,
          payload: response?.data?.data,
        });
        return Promise.resolve(response?.data?.data);
      }
      else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const selectProviderListRow = (payload) => (dispatch) => {
  return dispatch({
    type: DirectoryActionTypes.PROVIDERLIST_SELECT,
    payload: payload,
  });
};
