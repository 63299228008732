import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableFooter,
} from "@mui/material";
import {
  SearchBoxStandard,
  tableParam,
  StyledTableRow,
  StyledTableCell,
} from "../../common/ConstantsAndValues";
import { MyIconButtons } from "../../CustomComponents/MyButtons";
import { labComponentQueries } from "../../utils/labsqueries";
import {
  getLabComponentInfo,
  resetLabComponentInfo,
} from "../../../redux/labs/labs.actions";
import log from "../../../services/log";

export function RadiologyTestsList4ORGRow(props) {
  const { row, index, handleRowClick } = props;

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [labcomp, setLabComp] = useState(null);

  const selectCT = useSelector((state) => state?.labs?.radlabselect);
  const labcomponent = useSelector((state) => state?.labs?.labcomponent);
  const dispatch = useDispatch();

  useEffect(() => {
    if (labcomponent) {
      const labcomp = labcomponent[0];
      setLabComp(labcomp);
    } else {
      setLabComp(null);
    }
  }, [labcomponent]);

  useEffect(() => {
    // log.info("CTlistrow", row);
    if (selectCT) {
      const selectrow = row._source.loinc_num === selectCT._source.loinc_num;
      setSelectedRow(selectrow);
    }
  }, [selectCT]);

  const handleFilter = () => {};

  const handleRowClickLocal = (e) => {
    log.info("rtlist", e);
    const newstate = !selectedRow;
    setSelectedRow(newstate);
    handleRowClick(e, newstate);
  };

  const handleClick = (row) => {
    // log.info("CTlist", row);
    const newquery = labComponentQueries("loincNumber", row._source.loinc_num);
    log.info("moreselect", newquery);
    dispatch(getLabComponentInfo(newquery)).then((r) => {
      if (r.total.value && r.total.value > 0) {
        log.info("unitlab", r);
      }
    });
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <StyledTableRow
        key={"rr" + index}
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
        onClick={() => handleRowClickLocal(row)}
      >
        <StyledTableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <b>{row._source["common_name"]}</b>
        </StyledTableCell>
        {/*<StyledTableCell align="right">*/}
        {/*    <Typography variant={"body2"}>*/}
        {/*        {row._source.specimen}*/}
        {/*    </Typography>*/}
        {/*</StyledTableCell>*/}
        {/*<StyledTableCell align="right">{row._source.instructions}</StyledTableCell>*/}
      </StyledTableRow>

      <TableRow
        className="text-color-gray bg-standard-offwhite"
        key={"rrd" + index}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">Test Information</div>
                <div className="colfl">
                  <SearchBoxStandard
                    onChange={handleFilter}
                    placeholder="Property Name"
                    sx={{ marginRight: 1 }}
                    className="text-color-primary text-color-2-border text-color-2-bg"
                  />
                </div>
              </div>

              <Box
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow className="bg-gray">
                      <TableCell>Properties</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={"store" + row._source["Id"]}>
                      <TableCell>{"Group"}</TableCell>
                      <TableCell>{row._source.groupName}</TableCell>
                    </TableRow>
                    <TableRow key={"preg" + row._source["Id"]}>
                      <TableCell>{"Turnaround"}</TableCell>
                      <TableCell>{row._source.turnaround}</TableCell>
                    </TableRow>
                    {labcomp && (
                      <>
                        <TableRow key={"name"}>
                          <TableCell>{"Name"}</TableCell>
                          <TableCell>{labcomp._source.shortName}</TableCell>
                        </TableRow>
                        <TableRow key={"system"}>
                          <TableCell>{"System"}</TableCell>
                          <TableCell>{labcomp._source.system}</TableCell>
                        </TableRow>
                        <TableRow key={"descp"}>
                          <TableCell>{"Description"}</TableCell>
                          <TableCell>{labcomp._source.descrip}</TableCell>
                        </TableRow>
                        <TableRow key={"status"}>
                          <TableCell>{"Status"}</TableCell>
                          <TableCell>{labcomp._source.status}</TableCell>
                        </TableRow>
                        <TableRow key={"code"}>
                          <TableCell>{"Code"}</TableCell>
                          <TableCell>{labcomp._source.loincNumber}</TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
