// import axios from "axios";
import { API_URL } from "./Constants";
import log from "./log";
import { axiosApiInstance } from "./axiosmiddleware";

const getUserGroupService = (payload) => {
  return axiosApiInstance.post(API_URL + "usergroup/list", payload);
};

const setUserGroupService = (payload) => {
  return axiosApiInstance.post(API_URL + "usergroup/set", payload);
};

const updateUserGroupService = (payload) => {
  return axiosApiInstance.post(API_URL + "usergroup/update", payload);
};

const setPatientListService = (payload) => {
  return axiosApiInstance.post(API_URL + "patientlist/set", payload);
};

const getPatientListService = (payload) => {
  return axiosApiInstance.post(API_URL + "patientlist/list", payload);
};

const getUsersService = (payload) => {
  return axiosApiInstance.post(API_URL + "users/list", payload);
};

const getUserProfileService = (payload) => {
  return axiosApiInstance.post(API_URL + "profile/list", payload);
};

const updateClientSessionService = (payload) => {
  return axiosApiInstance.post(API_URL + "sessions/update", payload);
};

const setClientSessionService = (payload) => {
  return axiosApiInstance.post(API_URL + "sessions/set", payload);
};

const getClientSessionService = (payload) => {
  return axiosApiInstance.post(API_URL + "sessions/list", payload);
};

const getMDGroupService = (payload) => {
  return axiosApiInstance.post(API_URL + "mdgroups/list", payload);
};

const setMDGroupService = (payload) => {
  return axiosApiInstance.post(API_URL + "mdgroups/set", payload);
};

const updateMDGroupService = (payload) => {
  return axiosApiInstance.post(API_URL + "mdgroups/update", payload);
};

const getUserAccessList = (payload) => {
  return axiosApiInstance.post(API_URL + "useraccess/list", payload);
};

const setUserAccessList = (payload) => {
  return axiosApiInstance.post(API_URL + "useraccess/set", payload);
};

export {
  getUserGroupService,
  setUserGroupService,
  updateUserGroupService,
  setPatientListService,
  getPatientListService,
  getUserProfileService,
  getUsersService,
  getClientSessionService,
  setClientSessionService,
  updateClientSessionService,
  updateMDGroupService,
  setMDGroupService,
  getMDGroupService,
  getUserAccessList,
  setUserAccessList,
};
