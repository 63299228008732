import actions from "./actions.js";
import dicomreducers from "./reducers";
import localStorage from "./localStorage.js";
import sessionStorage from "./sessionStorage.js";

const reduxdicom = {
  dicomreducers,
  actions,
  localStorage,
  sessionStorage,
};

export default reduxdicom;
