import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTableRow } from "../styles/DiagnosticsStyles";

const styles = stylesTableRow;

export const CTestsTableRows = ({ items }) => {
  return (
    <>
      {items &&
        items.map((item) => (
          <View style={styles.row} key={item.sno.toString()}>
            <Text style={styles.description}>{item.desc}</Text>
            <Text style={styles.remarks}>{item.instruction}</Text>
          </View>
        ))}
    </>
  );
};

export const CTestsTableRowsBlank = ({ rowsCount }) => {
  const blankRows = Array(rowsCount).fill(0);
  const rows = blankRows.map((x, i) => (
    <View style={styles.row} key={`BR${i}`}>
      <Text style={styles.description}>&nbsp;</Text>
      <Text style={styles.remarks}>&nbsp;</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};
