////////////////////////////////////////////////////
//                 common for metalab             //
////////////////////////////////////////////////////
import * as React from "react";

import { ContainerLAB } from "../laboratory/components/ContainerLAB";
import {
  commonSearchMenuOptions,
  labPatientSearchMenuOptions,
  rtlabMenuOptions,
} from "./ConstantsAndValues";

import InventoryTwoToneIcon from "@mui/icons-material/InventoryTwoTone";
import PersonalVideoTwoToneIcon from "@mui/icons-material/PersonalVideoTwoTone";
import FormatListBulletedTwoToneIcon from "@mui/icons-material/FormatListBulletedTwoTone";

import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import TimelineTwoToneIcon from "@mui/icons-material/TimelineTwoTone";
import AddAlertTwoToneIcon from "@mui/icons-material/AddAlertTwoTone";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import CreateNewFolderTwoToneIcon from "@mui/icons-material/CreateNewFolderTwoTone";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import UpgradeTwoToneIcon from "@mui/icons-material/UpgradeTwoTone";
import SwitchAccountTwoToneIcon from "@mui/icons-material/SwitchAccountTwoTone";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import FileOpenTwoToneIcon from "@mui/icons-material/FileOpenTwoTone";
import ViewCozyTwoToneIcon from "@mui/icons-material/ViewCozyTwoTone";
import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone";

export function ApptLAB() {
  const containerType = {
    eventType: ["event"],
    type: "appointment",
    label: "Appointments",
    menuList: [
      {
        _id: "lablist",
        label: "Calendar",
        icon: <CalendarMonthTwoToneIcon />,
        link: "/metalab/appointments/cal",
        more: false,
      },
      {
        _id: "labmonitor",
        label: "Monitor",
        icon: <PersonalVideoTwoToneIcon />,
        link: "/metalab/appointments/investigations",
        more: false,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/appointments/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/appointments/messages",
        more: true,
      },
    ],
    comboLabel: "Any more addl combo box",
    comboList: [],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function InveLAB() {
  const containerType = {
    type: "investigations",
    label: "Investigations",
    menuList: [
      {
        _id: "lablist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/investigations/list",
        more: false,
      },
      {
        _id: "finanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metalab/investigations/analytics",
        more: true,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/investigations/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/investigations/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function PatnLAB() {
  const containerType = {
    type: "patients",
    label: "Patients",
    menuList: [
      {
        _id: "lablist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/patients/list",
        more: false,
      },
      {
        _id: "labmanage",
        label: "Manage",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metalab/patients/manage",
        more: false,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/patients/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/patients/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={labPatientSearchMenuOptions}
    />
  );
}

export function DeptLAB() {
  const containerType = {
    type: "departments",
    label: "Departments",
    menuList: [
      {
        _id: "lablist",
        label: "Info",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/departments/info",
        more: false,
      },
      {
        _id: "labmanage",
        label: "Manage",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metalab/departments/manage",
        more: false,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/departments/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/departments/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ClinicalTestsLAB() {
  const containerType = {
    type: "ct",
    label: "Clinical Tests",
    menuList: [
      {
        _id: "lablist",
        label: "Pricelist",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/ct/list",
        more: false,
      },
      {
        _id: "labtax",
        label: "Tax",
        icon: <FormatListBulletedTwoToneIcon />,
        link: "/metalab/ct/tax",
        more: false,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/ct/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/ct/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function RadiologyTestsLAB() {
  const containerType = {
    type: "rt",
    label: "Radiology Tests",
    menuList: [
      {
        _id: "rlablist",
        label: "PriceList",
        icon: <ListAltTwoToneIcon />,
        link: "/metalab/rt/list",
        more: false,
      },
      {
        _id: "rlabtax",
        label: "Tax",
        icon: <FormatListBulletedTwoToneIcon />,
        link: "/metalab/rt/tax",
        more: false,
      },
      {
        _id: "rlabalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/rt/notifications",
        more: true,
      },
      {
        _id: "rlabmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/rt/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={rtlabMenuOptions}
    />
  );
}

export function InfoLAB() {
  const containerType = {
    type: "info",
    label: "Information",
    menuList: [
      {
        _id: "lablist",
        label: "Info",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/info/info",
        more: false,
      },
      {
        _id: "labmmanage",
        label: "Manage",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metalab/info/manage",
        more: false,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/info/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/info/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function MoniLAB() {
  const containerType = {
    type: "monitor",
    label: "Monitor",
    menuList: [
      {
        _id: "lablist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/monitor/list",
        more: false,
      },
      {
        _id: "labanalysis",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metalab/monitor/analytics",
        more: true,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/monitor/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/monitor/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function AccLAB() {
  const containerType = {
    type: "accounts",
    label: "Accounts",
    menuList: [
      {
        _id: "lablist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/accounts/list",
        more: false,
      },
      {
        _id: "labledger",
        label: "Ledger",
        icon: <FormatListBulletedTwoToneIcon />,
        link: "/metalab/accounts/ledger",
        more: false,
      },
      {
        _id: "labsettings",
        label: "Settings",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metalab/accounts/settings",
        more: false,
      },
      {
        _id: "labanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metalab/accounts/analytics",
        more: true,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/accounts/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/accounts/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ProfLAB() {
  const containerType = {
    type: "profile",
    label: "My Profile",
    menuList: [
      {
        _id: "lablist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/profile/list",
        more: false,
      },
      {
        _id: "labanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metalab/profile/analytics",
        more: true,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/profile/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/profile/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ProfGroupLAB() {
  const containerType = {
    type: "profilegroup",
    label: "My Group",
    menuList: [
      {
        _id: "lablist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/group/list",
        more: false,
      },
      {
        _id: "labanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metalab/group/analytics",
        more: true,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/group/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/group/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function RepoLAB() {
  const containerType = {
    type: "reports",
    label: "Reports",
    menuList: [
      {
        _id: "lablist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/reports/list",
        more: false,
      },
      {
        _id: "labuploads",
        label: "Uploads",
        icon: <UpgradeTwoToneIcon />,
        link: "/metalab/reports/uploads",
        more: false,
      },
      {
        _id: "labanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metalab/reports/analytics",
        more: true,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/reports/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/reports/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ImgyLAB() {
  //5
  const containerType = {
    type: "medical-images",
    label: "Investigational Images",
    menuList: [
      {
        _id: "labfile",
        label: "Files",
        icon: <FileOpenTwoToneIcon />,
        link: "/metalab/mimages/dicom/files",
        more: false,
      },
      {
        _id: "labview",
        label: "View",
        icon: <ViewCozyTwoToneIcon />,
        link: "/metalab/mimages/dicom/view",
        more: false,
      },
      {
        _id: "lablist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/mimages/list",
        more: false,
      },
      {
        _id: "labanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metalab/mimages/analytics",
        more: true,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/mimages/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/mimages/messages",
        more: true,
      },
    ],
    comboList: [],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function ResoLAB() {
  const containerType = {
    type: "resources",
    label: "Resources",
    menuList: [
      {
        _id: "lablist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/resources/list",
        more: false,
      },
      {
        _id: "labtodo",
        label: "To Do",
        icon: <FormatListBulletedTwoToneIcon />,
        link: "/metalab/resources/todo",
        more: false,
      },
      {
        _id: "labmonitor",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metalab/resources/analytics",
        more: true,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/resources/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/resources/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function SuppLAB() {
  const containerType = {
    type: "supplies",
    label: "Supplies",
    menuList: [
      {
        _id: "lablist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/supplies/list",
        more: false,
      },
      {
        _id: "labtodo",
        label: "To Do",
        icon: <FormatListBulletedTwoToneIcon />,
        link: "/metalab/supplies/todo",
        more: false,
      },
      {
        _id: "labmonitor",
        label: "Supplies",
        icon: <InventoryTwoToneIcon />,
        link: "/metalab/supplies/supplies",
        more: false,
      },
      {
        _id: "labanalytics",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metalab/supplies/analytics",
        more: true,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/supplies/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/supplies/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function SetLAB() {
  const containerType = {
    eventType: ["setup"],
    type: "setup",
    label: "Settings",
    menuList: [
      {
        _id: "lablist",
        label: "Accounts",
        icon: <PrivacyTipTwoToneIcon />,
        link: "/metalab/setup/privacy",
        more: false,
      },
      {
        _id: "labsettings",
        label: "Settings",
        icon: <ManageAccountsTwoToneIcon />,
        link: "/metalab/setup/settings",
        more: false,
      },
      {
        _id: "labuploads",
        label: "Uploads",
        icon: <UpgradeTwoToneIcon />,
        link: "/metalab/setup/uploads",
        more: false,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/setup/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/setup/messages",
        more: true,
      },
    ],
  };
  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function SpecLAB() {
  const containerType = {
    type: "specialists",
    label: "Specialists",
    menuList: [
      {
        _id: "lablist",
        label: "Info",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/specialists/info",
        more: false,
      },
      {
        _id: "labmonitor",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metalab/specialists/analytics",
        more: true,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/specialists/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/specialists/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function TodoLAB() {
  const containerType = {
    type: "todo",
    label: "Management",
    menuList: [
      {
        _id: "lablist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/todo/list",
        more: false,
      },
      {
        _id: "labmonitor",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metalab/todo/analytics",
        more: true,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/todo/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/todo/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}

export function MetalabDoThisNowOrig() {
  const containerType = {
    type: "do",
    label: "Broadcast Now",
    menuList: [
      {
        _id: "lablist",
        label: "List",
        icon: <TableRowsTwoToneIcon />,
        link: "/metalab/do/list",
        more: false,
      },
      {
        _id: "labmonitor",
        label: "Analytics",
        icon: <TimelineTwoToneIcon />,
        link: "/metalab/do/analytics",
        more: true,
      },
      {
        _id: "labalert",
        label: "Alerts",
        icon: <AddAlertTwoToneIcon />,
        link: "/metalab/do/notifications",
        more: true,
      },
      {
        _id: "labmsg",
        label: "Messages",
        icon: <MessageTwoToneIcon />,
        link: "/metalab/do/messages",
        more: true,
      },
    ],
  };

  return (
    <ContainerLAB
      containerType={containerType}
      searchOptions={commonSearchMenuOptions}
    />
  );
}
