import React, { useEffect, useState } from "react";

const TestResultsIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      style={{ transform: `scale(${size})` }}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
    >
      <rect x="0.398438" y="17.2578" width="16.646" height="2" rx="1" />
      <rect
        x="6.84375"
        y="10.7891"
        width="10.1879"
        height="2"
        rx="1"
        transform="rotate(120 6.84375 10.7891)"
      />
      <rect
        x="12.3984"
        y="9.78906"
        width="10.1879"
        height="2"
        rx="1"
        transform="rotate(60 12.3984 9.78906)"
      />
      <rect x="2.73438" y="0.421875" width="12.1516" height="2" rx="1" />
      <rect
        x="6.6875"
        y="3.13281"
        width="8.69629"
        height="2"
        rx="1"
        transform="rotate(90 6.6875 3.13281)"
      />
      <rect
        x="12.9219"
        y="3.13281"
        width="8.69629"
        height="2"
        rx="1"
        transform="rotate(90 12.9219 3.13281)"
      />
      <circle cx="8.75" cy="14.0859" r="1.5" />
    </svg>
  );
};
export default TestResultsIcon;
