import React, { useState, useEffect, useLayoutEffect } from "react";
import { Grid, Box, Link, Typography, Tooltip, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  MyTooltipButtons,
  MyHelpButtons,
} from "../../CustomComponents/MyButtons";
import { getUsers } from "../../../redux/user/user.actions";
import { Title } from "../../common/Title";

import { MedicinePresList } from "../../medicine/MedicinePresList";
import { ClinicalTestsPresList } from "../../clinicaltests/ClinicalTestsPresList";
import { OtherTestsPresList } from "../../othertests/OtherTestsPresList";
import log from "../../../services/log";

import {
  setLabsOrder,
  setLabsOrderPresList,
  setRadlabsOrder,
  setRadlabsOrderPresList,
} from "../../../redux/labs/labs.actions";
import {
  setDrugList,
  setDrugPrecriptionList,
  setPatientDrugs,
} from "../../../redux/drugs/drugs.actions";

export function PatientsPrescribe() {
  const user = useSelector((state) =>
    state?.auth?.user ? state.auth.user.user : null
  );
  const profile = useSelector((state) => state?.auth);
  const preslistdrug = useSelector((state) => state?.drugs?.preslist);
  const preslistlab = useSelector((state) => state?.labs?.labsorder);
  const preslistrad = useSelector((state) => state?.labs?.radlabsorder);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUsers({
        query: {
          role: "metauser",
          groupId: "metaorg",
        },
      })
    );
  }, [user]);

  const isColm = true;

  const handleRemoveM = () => {};
  const handleRemoveCT = () => {};
  const handleRemoveOMP = () => {};

  const handlePostM = () => {
    preslistdrug?.forEach((d) => {
      dispatch(setPatientDrugs(d));
    });
    // reset list after post
    dispatch(setDrugPrecriptionList([]));
  };
  const handlePostCT = () => {
    preslistlab?.forEach((d) => {
      dispatch(setLabsOrder(d));
    });
    // reset list after post
    dispatch(setLabsOrderPresList([]));
  };
  const handlePostOMP = () => {
    preslistrad?.forEach((d) => {
      dispatch(setRadlabsOrder(d));
    });
    // reset list after post
    dispatch(setRadlabsOrderPresList([]));
  };

  //----------------------------------------INIT LOCALSTORAGE PATIENT  patient = null---------------------------------------------------//
  //localStorage.setItem("patient", JSON.stringify(PatientListNullRow));

  return (
    <Grid p={2} pt={1} pb={2} container direction="column" spacing={2}>
      <Grid>
        <Box className="">
          <Box
            className="flex-grow-1"
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "36px",
              width: "100%",
            }}
          >
            <Box>
              <Title>Medicines </Title>
            </Box>

            <Box className="">
              <MyTooltipButtons
                onClick={handlePostM}
                btnKey={"post"}
                btnLabel={"Post"}
                enState={false}
              />

              <MyTooltipButtons
                onClick={handleRemoveM}
                btnKey={"remove"}
                btnLabel={"Remove"}
                enState={false}
              />
            </Box>
          </Box>
          <MedicinePresList
            // handleClose={handleCancel}
            medicinelist={preslistdrug}
          />
          &nbsp;
          <Grid className="clear"></Grid>
          <Box
            className="flex-grow-1"
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "36px",
              width: "100%",
            }}
          >
            <Box>
              <Title>Clinical Tests</Title>
            </Box>

            <Box className="">
              <MyTooltipButtons
                onClick={handlePostCT}
                btnKey={"post"}
                btnLabel={"Post"}
                enState={false}
              />

              <MyTooltipButtons
                onClick={handleRemoveCT}
                btnKey={"remove"}
                btnLabel={"Remove"}
                enState={false}
              />
            </Box>
          </Box>
          <ClinicalTestsPresList //MedicinePresList
            // handleClose={handleCancel}
            clinicaltestlist={preslistlab}
          />
          &nbsp;
          <Grid className="clear"></Grid>
          <Box
            className="flex-grow-1"
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "36px",
              width: "100%",
            }}
          >
            <Box>
              <Title>Other Procedures</Title>
            </Box>

            <Box className="">
              <MyTooltipButtons
                onClick={handlePostOMP}
                btnKey={"post"}
                btnLabel={"Post"}
                enState={false}
              />

              <MyTooltipButtons
                onClick={handleRemoveOMP}
                btnKey={"remove"}
                btnLabel={"Remove"}
                enState={false}
              />
            </Box>
          </Box>
          <OtherTestsPresList
            // handleClose={handleCancel}
            othertestlist={preslistrad}
          />
        </Box>
      </Grid>

      <Grid className="clear"></Grid>
    </Grid>
  );
}
