import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { PolicyList } from "./PolicyListView";

{
  /*
CALLED FROM
[1] src/components/doctor/components/InsuInfoMD.js 8:0-72
[2] src/components/laboratory/components/InsuInfoLAB.js 8:0-72
[3] src/components/organization/components/InsuInfoORG.js 8:0-72
[4] src/components/organization/components/PatientListManageORG.js 8:0-72
[5] src/components/pharmacy/components/InsuInfoPHA.js 8:0-72
*/
}

export default function QueryMedicalPolicies(props) {
  return (
    <Grid container>
      <PolicyList />
    </Grid>
  );
}
