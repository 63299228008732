import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTableRow } from "../styles/DiagnosticsStyles";

const styles = stylesTableRow;

export const DrugsTableRows = ({ items }) => {
  return (
    <>
      {items &&
        items.map((item) => (
          <View style={styles.row} key={item.sno.toString()}>
            <Text style={styles.drugname}>{item.name}</Text>
            <Text style={styles.drugdosedetail}>
              {item.dose} {item.doseUnit} {" - "}
              {item.doseFrequency} {", "}
              {item.doseRoute}
            </Text>
          </View>
        ))}
    </>
  );
};

export const DrugsTableRowsBlank = ({ rowsCount }) => {
  const blankRows = Array(rowsCount).fill(0);
  const rows = blankRows.map((x, i) => (
    <View style={styles.row} key={`BR${i}`}>
      <Text style={styles.drugname}>&nbsp;</Text>
      <Text style={styles.drugdosedetail}>&nbsp;</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

{
  /*
useEffect(() => {
        if (row) {
            const newmap = [];
            newmap.push({id: "name",   label: "Name",  value: row.medicineName && row.medicineName.name});
            newmap.push({id: "salt",   label: "Contains", value: row.medicineName && row.medicineName.salt});
            newmap.push({id: "dose",   label: "Dose", value: row.medicineDosage &&  row.medicineDosage.dose});
            newmap.push({id: "unit",   label: "Unit", value: row.medicineDosage &&  row.medicineDosage.doseUnit});
            newmap.push({id: "freq",   label: "Frquency", value: row.medicineDosage && row.medicineDosage.doseFrequency});
            newmap.push({id: "route",  label: "Route", value: row.medicineDosage &&  row.medicineDosage.doseRoute});
            newmap.push({id: "refill", label: "Refills", value: row.refills && row.refills.numRefill});

            setMaplist(newmap)
        }
    }, [row]);
 */
}
