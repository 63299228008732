import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { CustomizedAxisTick } from "./graph";

export default class MetaAreaChart extends React.Component {
  state = {
    data: this.props.data,
    chatType: this.props.chartType,
    testType: this.props.testType,
    title: this.props.title,
    xlable: this.props.xlable,
    ylable: this.props.ylable,
  };

  color = ["#82ca9d", "#FFCC80", "#8884d8", "#82ca9d", "orange", "pink"];

  getAreaChart = () => {
    const keysArr = Object.keys(this.state.data[0]).slice(1);
    const areaArr = [];
    keysArr.forEach((item, index) => {
      areaArr.push(
        <Area
          type="monotone"
          dataKey={item}
          stroke={this.color[index]}
          fill={this.color[index]}
          fillOpacity={0.3}
        />
      );
    });
    return areaArr;
  };

  modifyFormatter = (value, name, props) => {
    const nameJSX = (
      <span>
        <span
          style={{
            display: "inline-block",
            marginRight: "5px",
            borderRadius: "10px",
            width: "10px",
            height: "10px",
            backgroundColor: props.color,
          }}
        ></span>
        {name} : {value}
      </span>
    );
    let result = [nameJSX].reverse();
    return result;
  };

  render() {
    const {
      data,
      title,
      refAreaLeft,
      refAreaRight,
      xlable,
      ylable,
      ResponsiveContainer,
    } = this.state;

    return (
      <AreaChart
        width={600}
        height={400}
        data={data}
        margin={{ top: 10, right: 30, left: 20, bottom: 50 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <CartesianGrid
          strokeDasharray="3 3"
          horizontal={false}
          vertical={false}
        />
        <defs>
          <linearGradient id="colortsh" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colortshp" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey={Object.keys(data[0])[0]}
          offset={10}
          tick={<CustomizedAxisTick />}
        >
          <Label value={xlable} offset={20} position="left" />
        </XAxis>
        <YAxis label={{ value: ylable, angle: -90, position: "insideLeft" }} />
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        {this.getAreaChart()}
      </AreaChart>
    );
  }
}
