import React from "react";
import { Grid, Paper, Box, Typography, Container } from "@mui/material";
import { useOutletContext } from "react-router-dom";

import ReactSpeedometer from "../charts/speedo";
//import SpeedoButton                                      from "../../charts/speedo/stories/speedo-button";

import { colSpeedoChart, getSpeedoColors } from "../common/ConstantsAndValues";

export function HealthSpeedo(props) {
  const p = props.page || "";
  const v = props.data.score || 0;
  let v1 = props.data.normal || 30;
  let v2 = props.data.critical || 70;
  const w = colSpeedoChart.width;
  const h = colSpeedoChart.height;
  const x = colSpeedoChart.maxValue || 100; //maxvalue

  if (v1 > v2) {
    let v = v1;
    v1 = v2;
    v2 = v;
  }
  const clr = getSpeedoColors(v1, v2);

  //console.log('HS', p,v,w,h,x,v1,v2);

  return (
    <Box px={2}>
      <ReactSpeedometer
        value={v}
        width={w}
        height={h}
        maxValue={100}
        segments={10}
        segmentColors={clr}
      />
    </Box>
  );
}
