// import node modules
import * as React from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { PlusIcon } from "../../../customIcons/PlusIcon";
import {
  FirstPageIcon,
  LastPageIcon,
  NextPageIcon,
  PreviousPageIcon,
} from "../../../customIcons/TableIcons";
import { Link, useNavigate } from "react-router-dom";
import useOnClickOutside from "../../../../utils/use-on-click-outside";
import Select, { components } from "react-select";
import {
  customStylesFilter,
  formattedArray,
} from "../../../tabs/TabsStaticData";

export const ProviderSection = ({ setCurrentStep }) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [showFilters, setShowFilters] = React.useState(false);
  const [doctorList, setDoctorList] = React.useState([
    {
      name: "Dr. Aamir Khan",
      speciality: "General Medicine",
      address: "165, Tambakuwala, Dr Vigas Street, Charni Road, Mumbai",
      date: "12 October, 2023",
    },
    {
      name: "Dr. Amitabh Bacchan",
      speciality: "General Medicine",
      address: "C-73/602, Vasant Vihar, Khopat, Thane",
      date: "14 October, 2023",
    },
  ]);

  const providerOptions = [{ title: "Doctor" }, { title: "Laboratory" }];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // outside click for profile dropdown
  const squareBoxRef = React.useRef(null);

  const clickOutsidehandler = (event) => {
    event.stopPropagation();
    setShowFilters(false);
  };
  useOnClickOutside(squareBoxRef, clickOutsidehandler);

  const dateLabelFilter = [
    {
      label: "13 October Health check-up",
      value: "13 October Health check-up",
    },
    {
      label: "15 October Tests",
      value: "15 October Tests",
    },
  ];
  return (
    <>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "18px",
          color: "#404040",
          fontFamily: "DM SANS",
        }}
      >
        With whom would you like to schedule your appointment?
      </Typography>
      <Grid container sx={{ flexGrow: 1, mt: 4 }}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
            <span className="required_dot"></span>
            Provider category
          </FormLabel>

          <Tabs
            className="appointment_tabs mt-0"
            TabIndicatorProps={{
              style: {
                backgroundColor: "transparent",
                color: "black",
              },
            }}
            sx={{
              "& .MuiTab-root.Mui-selected": {
                background: "#EAF4F7 !important",
                // borderRight: "none",
                border: "1px solid #94C4D2",
              },
            }}
            value={value}
            onChange={handleChange}
          >
            {providerOptions.map((data, index) => (
              <Tab
                className="appointment_tab"
                label={data.title}
                key={index}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Grid>
        {value === 0 && (
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <FormLabel sx={{ fontSize: "14px", fontFamily: "DM SANS" }}>
              <span className="required_dot"></span>
              Type of visit
            </FormLabel>
            <Box sx={{ width: "95%" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="In-person"
                  control={
                    <Radio
                      size=""
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#404040",
                        },
                      }}
                    />
                  }
                  label={<span className="text-14 dm_sans">In-person</span>}
                />
                <FormControlLabel
                  value="Online/Video"
                  control={
                    <Radio
                      size=""
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#404040",
                        },
                      }}
                    />
                  }
                  label={<span className="text-14 dm_sans">Online/Video</span>}
                />
              </RadioGroup>
            </Box>
          </Grid>
        )}
      </Grid>
      {/* <Box sx={{ display: "flex", paddingBottom: "4rem" }}>
        
        <button
          className="meta__button_green mt-4"
          onClick={() => setCurrentStep(3)}
        >
          Next: Date & Time
        </button>
        <button
          className="meta__button_green_outlined mt-4 ml-3"
          onClick={() => setCurrentStep(1)}
        >
          Back: Reason
        </button>
      </Box> */}
      <Divider sx={{ mt: 4, mb: 4 }} />
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "18px",
          color: "#404040",
          fontFamily: "DM SANS",
        }}
      >
        Select your provider or
        <Box
          onClick={() => navigate("/metauser/find-care")}
          component="span"
          sx={{
            color: "#2A8AA7",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          &nbsp;find care
        </Box>
      </Typography>
      <Box sx={{ mb: 2, mt: 2, position: "relative" }}>
        <div className="yellowlabel_right" onClick={() => setShowFilters(true)}>
          <PlusIcon size={0.45} />
          <p className="mb-0">Add a filter</p>
        </div>
        {showFilters && (
          <div className="filterBox" ref={squareBoxRef}>
            <Box>
              <FormLabel
                sx={{ fontSize: "15px", marginLeft: 1, fontFamily: "DM SANS" }}
              >
                Provider Name
              </FormLabel>
            </Box>
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              isMulti
              className=""
              placeholder={"Filter by name"}
              onChange={(value) => {
                // setSpeciality(value.value);
              }}
              styles={customStylesFilter}
              isClearable={true}
              isSearchable={true}
              // isLoading={providerListLoading}
              options={formattedArray(doctorList)}
            />
            <Box>
              <FormLabel
                sx={{
                  fontSize: "15px",
                  marginLeft: 1,
                  marginTop: 1,
                  fontFamily: "DM SANS",
                }}
              >
                Specialty
              </FormLabel>
            </Box>
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              isMulti
              className=""
              placeholder={"Filter by specialty"}
              onChange={(value) => {
                // setSpeciality(value.value);
              }}
              styles={customStylesFilter}
              isClearable={true}
              isSearchable={true}
              // isLoading={providerListLoading}
              options={formattedArray(dateLabelFilter)}
            />
            <button
              className={`${"meta__button_orange"} mt-3 mb-2 w-p-100`}
              // onClick={() => setCurrentStep(2)}
            >
              Apply Filter
            </button>
          </div>
        )}
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontFamily: "DM SANS", padding: "5px" }}
              ></TableCell>
              <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                Name
              </TableCell>
              <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                Specialty
              </TableCell>
              <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                Address
              </TableCell>
              <TableCell sx={{ fontFamily: "DM SANS", padding: "5px" }}>
                First available date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {doctorList.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    fontFamily: "DM SANS",
                    fontSize: "15px",
                    padding: "10px 5px",
                  }}
                >
                  <FormControlLabel
                    // key={menu._id}
                    // value={menu.value}
                    sx={{ mt: 1 }}
                    control={<Radio size="small" />}
                    // label={menu.label}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontFamily: "DM SANS",
                    fontSize: "15px",
                    padding: "10px 5px",
                  }}
                >
                  {row.name}
                </TableCell>

                <TableCell
                  sx={{
                    fontFamily: "DM SANS",
                    fontSize: "15px",
                    padding: "10px 5px",
                  }}
                >
                  {row.speciality}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: "DM SANS",
                    fontSize: "15px",
                    padding: "10px 5px",
                  }}
                >
                  {row.address}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: "DM SANS",
                    fontSize: "15px",
                    padding: "10px 5px",
                  }}
                >
                  {row.date}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          paddingTop: "1.5rem",
          // paddingBottom: "4rem",
          borderTop: `1px solid #e9e9e9`,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box sx={{ mx: 1, cursor: "pointer" }}>
          <FirstPageIcon />
        </Box>
        <Box sx={{ mx: 2, cursor: "pointer" }}>
          <PreviousPageIcon />
        </Box>
        <Typography
          sx={{
            paddingTop: "2px",
            fontFamily: "DM SANS",
            fontSize: (theme) => theme.typography.button.fontSize,
          }}
        >
          Page
          <Box
            sx={{
              display: "inline",
              border: "1px solid #C7C7C7",
              px: 0.6,
              borderRadius: "4px",
            }}
          >
            1
          </Box>
          / 22
        </Typography>
        <Box sx={{ mx: 2, cursor: "pointer" }}>
          <NextPageIcon />
        </Box>
        <Box sx={{ mx: 1, cursor: "pointer" }}>
          <LastPageIcon />
        </Box>
      </Box>
      <Box sx={{ display: "flex", paddingBottom: "4rem" }}>
        <button
          className="meta__button_green mt-4"
          onClick={() => setCurrentStep(3)}
        >
          Next: Date & Time
        </button>
        <button
          className="meta__button_green_outlined mt-4 ml-3"
          onClick={() => setCurrentStep(1)}
        >
          Back: Reason
        </button>
      </Box>
    </>
  );
};
