// import node modules
import * as React from "react";
import { Box, Typography, Grid } from "@mui/material";
import Calendar from "react-calendar";
import moment from "moment";
import { useDispatch } from "react-redux";
import { selectEventDate } from "../../../appointment/redux/actions";
import { format } from "date-fns";
import { addDays, differenceInCalendarDays } from "date-fns";

import { myDateFormat } from "../../../common/ConstantsAndValues";
import {
  getUsersAppointmentDates,
  getUsersAppointmentSlots,
} from "../../../../redux/appointment/appointment.actions";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

export const DateTimeSection = ({ setCurrentStep }) => {
  const dispatch = useDispatch();
  const [slotSelected, setSlotSelected] = React.useState(null);
  const allSlots = [
    { time: "12:00pm" },
    { time: "12:30pm" },
    { time: "1:00pm" },
    { time: "1:30pm" },
    { time: "2:00pm" },
    { time: "2:30pm" },
    { time: "3:00pm" },
    { time: "3:30pm" },
  ];
  let Flag = false;

  useEffect(() => {
    getUpcomingDates();
    getUpcomingSlots();
  }, []);

  var localdate = moment().utc();
  var dateFormat = localdate.format();

  const getUpcomingDates = async () => {
    let params = {
      metadata: { providerId: "b8ac5cc6-f720-46b6-9345-1ab2ecf58aad" },
      range: { fromDate: dateFormat, toDate: "2023-11-30T04:00:00Z" },
    };
    dispatch(getUsersAppointmentDates(params)).then((res) => {
      if (res.code === 200) {
        console.log(res);
        // setUpcomingAppointment(res.data);
      }
    });
  };

  const getUpcomingSlots = async () => {
    let params = {
      metadata: { providerId: "b8ac5cc6-f720-46b6-9345-1ab2ecf58aad" },
      range: { fromDate: dateFormat },
    };
    dispatch(getUsersAppointmentSlots(params)).then((res) => {
      if (res.code === 200) {
        console.log(res);
        // setUpcomingAppointment(res.data);
      }
    });
  };
  const [date, setDate] = React.useState(null);
  const onDateChange = (newDate) => {
    // log.info("datepick", Flag);
    // log.info("datepick", newDate);

    setDate(newDate);
    // dispatch(selectEventDate({ select: newDate }));
  };

  const handleClickDay = (e) => {
    // log.info("datepick", Flag);
    // log.info("datepick", e);

    const seldate = format(e, myDateFormat);
    // if (markDates.includes(seldate)) {
    //   Flag = true;
    //   setNote(seldate + " - This date is occupied");
    // } else {
    //   Flag = false; //needed for resetting clicks
    //   setNote("");
    // }
  };

  const setTime = () => {
    if (slotSelected !== null && date !== null) {
      setCurrentStep(4);
    } else {
      toast.error("Please select slot first");
    }
  };

  return (
    <>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "18px",
          color: "#404040",
          fontFamily: "DM SANS",
        }}
      >
        Select a date and book a time slot for your appointment
      </Typography>
      <Box className="appointment_calendar">
        <Grid container className="">
          <Grid item xs={12} sm={6} md={6} lg={4} sx={{ px: 1 }}>
            <Calendar
              className="react_calendar"
              onChange={onDateChange}
              value={date}
              showNeighboringMonth={true}
              locale={"en-US"}
              onClickDay={handleClickDay}
              minDate={new Date()}
              // tileDisabled={({ activeStartDate, date, view }) =>{
              // console.log(activeStartDate,date,view)
              // return  'non_highlight_date'
              // }}
              //   showDateDisabled &&
              //   markDates.find((x) => x === moment(date).format("YYYY-MM-DD"))

              // tileContent={({ date, view }) => {
              //   const currentDate = new Date();
              //   let a = currentDate.getDate();
              //   let b = date.getDate();
              //   console.log(a, b);
              //   if(a===b){

              //   }
              //   //   if (markDates.find((x) => x === moment(date).format("YYYY-MM-DD"))) {
              //   //     return markDate ? "x" : "";
              //   //   }
              // }}

              tileClassName={({ date, view }) => {
                // const now = new Date();
                // const tomorrow = addDays(now, 1);
                // const in3Days = addDays(now, 3);
                // const in5Days = addDays(now, 5);

                // const highlightedDates = [tomorrow, in3Days, in5Days];

                // function isSameDay(a, b) {
                //   return differenceInCalendarDays(a, b) === 0;
                // }
                // if (
                //   view === "month" &&
                //   highlightedDates.find((dDate) => isSameDay(dDate, date))
                // ) {
                //   return "highlight_date";
                // }
                const currentDate = new Date();
                let a = date.getDate();
                let b = currentDate.getDate();
                if (a > b) {
                  return "highlight_date";
                }
                // console.log(date,view)
                //   if (markDates.find((x) => x === moment(date).format("YYYY-MM-DD"))) {
                //     return markDate ? "text-color-gray bg-gray" : "";
                //   }
              }}

              // onDisabledDayError={handleDisabledSelect}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={8} sx={{ px: 1 }}>
            <Box sx={{ border: "1px solid #c7c7c7", borderRadius: "4px" }}>
              <Box
                sx={{
                  textAlign: "center",
                  py: 1,
                  fontSize: "16px",
                  borderBottom: "1px solid #c7c7c7",
                }}
              >
                Wednesday – 13 October, 2023
              </Box>
              <Box>
                <Box
                  sx={{
                    // display: "flex",
                    borderBottom: "1px solid #E9E9E9",
                    mx: 2,
                    py: 2,
                  }}
                >
                  <Grid container className="">
                    <Grid item xs={12} sm={6} md={6} lg={2} sx={{ px: 1 }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "14px",
                          marginTop: "10px",
                          color: "#404040",
                          fontFamily: "DM SANS",
                        }}
                      >
                        Afternoon
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={10} sx={{ px: 1 }}>
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {allSlots.map((data) => (
                          <Box
                            className={`${
                              data.time === slotSelected
                                ? "green_label_appointment_selected"
                                : "green_label_appointment"
                            }`}
                            onClick={() => setSlotSelected(data.time)}
                          >
                            {data.time}
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {/* <Box sx={{ mx: 2, py: 2 }}>
                  <Grid container className="">
                    <Grid item xs={12} sm={6} md={6} lg={2} sx={{ px: 1 }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#404040",
                          fontFamily: "DM SANS",
                        }}
                      >
                        Evening
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={10} sx={{ px: 1 }}>
                      <Box sx={{ display: "flex" }}>
                        <Box className="green_label_appointment">7:30 pm</Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex" }}>
        <button
          className={`${
            date !== null && slotSelected !== null
              ? "meta__button_orange"
              : "meta__button_green"
          } mt-4`}
          onClick={setTime}
        >
          Next: Review
        </button>
        <button
          className="meta__button_green_outlined mt-4 ml-3"
          onClick={() => setCurrentStep(2)}
        >
          Back: Provider
        </button>
      </Box>
    </>
  );
};
