import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Paper,
  Typography,
  Button,
  Fade,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import { ListItemIcon } from "@mui/material";
import InputBase from "@mui/material/InputBase";

import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import FilterAltTwoToneIcon from "@mui/icons-material/FilterAltTwoTone";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import SearchIcon from "@mui/icons-material/Search";

import {
  searchUserText,
  searchUserContext,
} from "../../redux/user/user.actions";

import log from "../../services/log";

export const SearchBoxMenuBar = (props) => {
  const { searchOptions, placeholder } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [searchtext, setSetSearchtext] = useState("");
  const [searchoption, setSearchoption] = useState(null);

  const dispatch = useDispatch();

  const handleSearchMenu = (event) => {
    log.info("SearchOpt", event);

    //updateText(event);   //error ?
    //handleSearch(event);  //to be enabled once all calls rectified
  };

  const handleMenuClose = (event) => {
    // log.info("searchmenutag", event)
    setAnchorEl(null);
    setSearchoption(event);
  };

  const handleSearchMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearchtext = (event) => {
    // log.info("menusearchbar", event.target.value);
    setSetSearchtext(event.target.value);
  };

  const handleSearch = () => {
    log.info("menusearchbar", searchtext);
    dispatch(searchUserContext(searchoption));
    dispatch(searchUserText(searchtext));
  };

  // log.info("menusearchbar", searchOptions);

  return (
    <Paper
      component="form"
      sx={{
        padding: "0px 10px 1px 16px",
        display: "flex",
        alignItems: "center",
        boxShadow: "none",
        height: "46px",
        width: { xs: "180px", sm: "200px", md: "240px" },
        fontSize: { xs: "12px", sm: "16px" },
        borderRadius: "20px",
        margin: "0px 0px 0px 0px",
      }}
    >
      <IconButton
        id="menu-search"
        size="small"
        aria-controls={open ? "menu-search" : undefined}
        aria-haspopup="true"
        onClick={handleSearchMenuOpen}
        color="inherit"
        sx={{
          boxShadow: "0 !important",
          outline: "none",
          "&:focus": {
            border: "0 !important",
            outline: "none",
            boxShadow: "none",
            backgroundColor: "transparent",
          },
        }}
      >
        <TuneTwoToneIcon />
      </IconButton>
      {searchOptions && (
        <Menu
          anchorEl={anchorEl}
          mr={0}
          ml={0}
          id="menu-search"
          MenuListProps={{
            "aria-labelledby": "menu-search",
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          open={open}
          onClose={handleMenuClose}
        >
          {searchOptions &&
            searchOptions.map((m, index) => (
              <Box key={"icb" + m._id + index}>
                <MenuItem
                  key={m._id + index}
                  onClick={() => handleMenuClose(m.value)}
                  style={{ padding: "0px 10px" }}
                >
                  <ListItemIcon>{m.icon}</ListItemIcon>
                  <Typography variant="body2" className="text-color-primary">
                    {<span className="">{m.title}</span>}
                  </Typography>
                </MenuItem>
              </Box>
            ))}
        </Menu>
      )}
      <InputBase
        onChange={handleSearchtext}
        onClick={handleSearch}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
      />

      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={handleSearch}
        sx={{
          boxShadow: "0 !important",
          outline: "none",
          "&:focus": {
            border: "0 !important",
            outline: "none",
            boxShadow: "none",
            backgroundColor: "transparent",
          },
        }}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
