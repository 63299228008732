import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MyTooltipButtons } from "../../CustomComponents/MyButtons";
import { ClinicalTestsPresList } from "../../clinicaltests/ClinicalTestsPresList";

import {
  setLabsOrder,
  setLabsOrderPresList,
} from "../../../redux/labs/labs.actions";

export const ClinicalTestsPresListView = (props) => {
  const { handleCancel } = props;

  const preslist = useSelector((state) => state?.labs?.labsorder);
  const selectedrow = useSelector((state) => state?.labs?.labsorderselect);

  const [action, setAction] = useState("");
  const dispatch = useDispatch();

  const handlePost = () => {
    // log.info("labslist post");
    preslist.forEach((d) => {
      dispatch(setLabsOrder(d));
    });
    // reset list after post
    dispatch(setLabsOrderPresList([]));
  };

  const handleRemove = () => {
    // log.info("prescribelist remove");
    const newlist = preslist.filter(
      (p) => p.metadata.loincNumber !== selectedrow.metadata.loincNumber
    );
    dispatch(setLabsOrderPresList(newlist));
  };

  const handleReset = () => {
    // log.info("prescribelist reset");
    dispatch(setLabsOrderPresList([]));
  };

  const handleCancelLocal = () => {
    setAction("");
    handleCancel();
  };

  return (
    <div>
      <div className="colfr0">
        <MyTooltipButtons
          onClick={handlePost}
          btnKey={"post"}
          btnLabel={"Post"}
          enState={false}
        />
        &nbsp; &nbsp;
        <MyTooltipButtons
          onClick={handleReset}
          btnKey={"reset"}
          btnLabel={"Reset"}
          enState={false}
        />
        &nbsp; &nbsp;
        <MyTooltipButtons
          onClick={handleRemove}
          btnKey={"remove"}
          btnLabel={"Remove selected"}
          enState={false}
        />
        &nbsp; &nbsp;
        <MyTooltipButtons
          onClick={handleCancelLocal}
          btnKey={"cancel"}
          btnLabel={"Cancel"}
          enState={false}
        />
      </div>

      <ClinicalTestsPresList
        handleClose={handleCancel}
        clinicaltestlist={preslist}
      />
    </div>
  );
};
