import React from "react";
import { Page, Text, Document } from "@react-pdf/renderer";
import { CommonHeader } from "./CommonHeader";
import { PDFViewer } from "@react-pdf/renderer";

//import {CommonAddrAddr} from './CommonAddrAddr'
//import {CommonSerialNo} from './CommonSerialNo'
//import {DrugsItemsTable} from './DrugsItemsTable'
//import {CTestsItemsTable} from './CTestsItemsTable'
//import {RTestsItemsTable} from './RTestsItemsTable'
import { InsuranceDetailTable } from "./InsuranceDetailTable";
import { InsuranceTitle } from "./InsuranceTitle";

//import {PrescriptionSign} from './PrescriptionSign'
//import {PrescriptionDeclaration} from './PrescriptionDeclaration'

import { stylesLogo } from "../styles/CommonStyles";

import { tableParam } from "../../common/ConstantsAndValues";
import logo from "../../../assets/images/logo.png";
import logoSm from "../../../assets/images/logoSm.png";

const styles = stylesLogo;
const countTR = tableParam.tableRowsCount ? tableParam.tableRowsCount : 15;

const onRenderDocument = ({ blob, filename }) => {
  var blobUrl = URL.createObjectURL(blob);
  //saveDocument(blobUrl, filename);
};

const Insurance = ({ title, content, eventsmask, getCN }) => (
  <Document
    title="InsurancePolicy"
    author="Metalane"
    subject="Online generated document"
    keywords="Metalane online pdf document"
    creator="Metalane  webapp"
    file="InsurancePolicy"
  >
    <Page size="A4" style={styles.page} wrap>
      <CommonHeader logo={logo} invoice={content.parent[0]} />
      <InsuranceTitle title={title} />

      {(eventsmask?.filter((item) => item.includes("presAll")).length !== 0 ||
        eventsmask?.filter((item) => item.includes("presIN")).length !== 0) && (
        <>
          <InsuranceDetailTable tableRowsCount={0} diags={content.policy} />
        </>
      )}

      <Text
        style={styles.pageNos}
        render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default Insurance;
