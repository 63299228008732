import * as React from "react";
import Select from "react-select";
import { Box, FormControl, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SelectRegion, SelectSubregion } from "../../redux/actions/locs";
import { LocMSStyles } from "./LocMSStyles";

import log from "../../services/log";

export function SubregionSelectLoc(props) {
  const { title } = props;

  const dispatch = useDispatch();

  const regiondata = useSelector((state) => state.locs.regiondata);
  const region = useSelector((state) => state.locs.region);
  const subregion = useSelector((state) => state.locs.subregion);

  const [selOption, setSelOption] = useState(
    subregion
      ? { label: subregion._source["sub-region"], value: subregion }
      : null
  );
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (regiondata && region) {
      const noptions = [];
      const subregids = [];

      const subfilter = (id) => {
        if (subregids.includes(id) === false) {
          subregids.push(id);
          return true;
        }
        return false;
      };

      const sublist = regiondata.filter(
        (r) => r._source["region-code"] === region._source["region-code"]
      );

      const ssubreglist = sublist.filter((r) =>
        subfilter(r._source["sub-region-code"])
      );
      // log.info("subregion", ssubreglist);
      ssubreglist.forEach((a) => {
        noptions.push({ label: a._source["sub-region"], value: a });
      });

      setOptions(noptions);
    }
  }, [regiondata, region]);

  const handleSelect = (event) => {
    // console.log('selectedregion',event);
    setSelOption(event);
    dispatch(SelectSubregion(event.value));
  };

  // log.info("subregion", subregion);

  return (
    <FormControl
      fullWidth
      className="text-color-primary"
      variant="outlined"
      size="small"
    >
      &nbsp;
      <InputLabel
        sx={{ minWidth: 20 }}
        variant="standard"
        htmlFor="uncontrolled-native"
      >
        {title}
      </InputLabel>
      <Box className="text-color">
        <Select
          isDisabled={region ? false : true}
          options={options}
          value={selOption}
          placeholder={"Select " + title}
          onChange={handleSelect}
          isSearchable={true}
          styles={LocMSStyles}
          menuPlacement="auto"
          menuPosition="fixed"
          isMulti={false}
        />
      </Box>
    </FormControl>
  );
}
