import * as React from "react";
import { Tooltip, IconButton } from "@mui/material";
import AddchartTwoToneIcon from "@mui/icons-material/AddchartTwoTone";
import ArrowBackIosNewTwoToneIcon from "@mui/icons-material/ArrowBackIosNewTwoTone";
import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";
import AddLocationTwoToneIcon from "@mui/icons-material/AddLocationTwoTone";
import ApprovalTwoToneIcon from "@mui/icons-material/ApprovalTwoTone";
import AssignmentIndTwoToneIcon from "@mui/icons-material/AssignmentIndTwoTone";
import CompareTwoToneIcon from "@mui/icons-material/CompareTwoTone";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";
import DoneIcon from "@mui/icons-material/Done";
import DifferenceTwoToneIcon from "@mui/icons-material/DifferenceTwoTone";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import LibraryAddCheckTwoToneIcon from "@mui/icons-material/LibraryAddCheckTwoTone";
import LocalPharmacyTwoToneIcon from "@mui/icons-material/LocalPharmacyTwoTone";
import MedicationTwoToneIcon from "@mui/icons-material/MedicationTwoTone";
import NotificationsActiveTwoToneIcon from "@mui/icons-material/NotificationsActiveTwoTone";
import PaymentTwoToneIcon from "@mui/icons-material/PaymentTwoTone";
import PanToolTwoToneIcon from "@mui/icons-material/PanToolTwoTone";
import PostAddTwoToneIcon from "@mui/icons-material/PostAddTwoTone";
import PreviewTwoToneIcon from "@mui/icons-material/PreviewTwoTone";
import QuestionMarkTwoToneIcon from "@mui/icons-material/QuestionMarkTwoTone";
import RestartAltTwoToneIcon from "@mui/icons-material/RestartAltTwoTone";
import RemoveCircleOutlineTwoToneIcon from "@mui/icons-material/RemoveCircleOutlineTwoTone";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import SubscriptionsTwoToneIcon from "@mui/icons-material/SubscriptionsTwoTone";
import TaskTwoToneIcon from "@mui/icons-material/TaskTwoTone";
import UploadFileTwoToneIcon from "@mui/icons-material/UploadFileTwoTone";
import UpdateTwoToneIcon from "@mui/icons-material/UpdateTwoTone";

import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { StyledButton, StyledButtonColor } from "../common/ConstantsAndValues";
import log from "../../services/log";

const menuArrows = [
  { id: 110, key: "e", icon: <ArrowForwardTwoToneIcon /> },
  { id: 210, key: "w", icon: <ArrowBackTwoToneIcon /> },
  { id: 310, key: "n", icon: <ArrowUpwardTwoToneIcon /> },
  { id: 410, key: "s", icon: <ArrowDownwardTwoToneIcon /> },
  { id: 510, key: "kup", icon: <KeyboardArrowUpIcon /> },
  { id: 610, key: "kdn", icon: <KeyboardArrowDownIcon /> },
];

const menuButtns = [
  { id: 1501, key: "add", icon: <DoneIcon /> },
  { id: 2701, key: "addloc", icon: <AddLocationTwoToneIcon /> },
  { id: 2301, key: "aging", icon: <DateRangeTwoToneIcon /> },
  { id: 2401, key: "approve", icon: <ApprovalTwoToneIcon /> },
  { id: 3701, key: "assign", icon: <AssignmentIndTwoToneIcon /> },
  { id: 401, key: "back", icon: <ArrowBackIosNewTwoToneIcon /> },
  { id: 801, key: "buy", icon: <ShoppingCartTwoToneIcon /> },
  { id: 101, key: "cancel", icon: <ClearTwoToneIcon /> },
  { id: 3801, key: "chart", icon: <AddchartTwoToneIcon /> },
  { id: 3601, key: "clear", icon: <ClearIcon /> },
  { id: 3701, key: "close", icon: <ClearTwoToneIcon /> },
  { id: 501, key: "compare", icon: <CompareTwoToneIcon /> },
  { id: 2801, key: "completestep", icon: <LibraryAddCheckTwoToneIcon /> },
  { id: 601, key: "differ", icon: <DifferenceTwoToneIcon /> },
  { id: 201, key: "edit", icon: <EditTwoToneIcon /> },
  { id: 901, key: "enrol", icon: <SubscriptionsTwoToneIcon /> },
  { id: 1601, key: "hold", icon: <PanToolTwoToneIcon /> },
  { id: 1701, key: "info", icon: <InfoTwoToneIcon /> },
  { id: 402, key: "next", icon: <ArrowForwardIosTwoToneIcon /> },
  { id: 3001, key: "new", icon: <DoneIcon /> },
  { id: 701, key: "pay", icon: <PaymentTwoToneIcon /> },
  { id: 1001, key: "post", icon: <PostAddTwoToneIcon /> },
  { id: 1801, key: "prescribe", icon: <LocalPharmacyTwoToneIcon /> },
  { id: 1802, key: "prescribedrug", icon: <LocalPharmacyTwoToneIcon /> },
  { id: 3101, key: "prescriberad", icon: <MedicationTwoToneIcon /> },
  { id: 3201, key: "prescribelab", icon: <MedicationTwoToneIcon /> },

  { id: 2001, key: "process", icon: <LocalPharmacyTwoToneIcon /> },

  { id: 2101, key: "proceed", icon: <PostAddTwoToneIcon /> },
  { id: 10, key: "question", icon: <QuestionMarkTwoToneIcon /> },
  { id: 3301, key: "raisebill", icon: <PaymentTwoToneIcon /> }, //this
  { id: 1901, key: "receipt", icon: <ReceiptTwoToneIcon /> },
  { id: 2201, key: "remind", icon: <NotificationsActiveTwoToneIcon /> },
  { id: 1101, key: "reset", icon: <RestartAltTwoToneIcon /> },
  { id: 1201, key: "remove", icon: <RemoveCircleOutlineTwoToneIcon /> },
  { id: 1301, key: "request", icon: <LocalPharmacyTwoToneIcon /> },
  { id: 1401, key: "requestlist", icon: <PreviewTwoToneIcon /> },
  { id: 3401, key: "save", icon: <UpdateTwoToneIcon /> }, //this
  { id: 2501, key: "select", icon: <CheckCircleOutlineTwoToneIcon /> },
  { id: 2901, key: "step", icon: <TaskTwoToneIcon /> },
  { id: 301, key: "update", icon: <UpdateTwoToneIcon /> },
  { id: 3501, key: "upload", icon: <UploadFileTwoToneIcon /> },
  { id: 2601, key: "viewlist", icon: <PreviewTwoToneIcon /> },
];

export function MyButtons(props) {
  const { onClick, btnKey, btnLabel, enState } = props;

  let found = menuButtns.filter((x) => {
    return x.key === btnKey;
  });
  if (!found) {
    found = menuButtns[0];
  }
  const thy = enState === true ? true : false;

  return (
    <StyledButton
      disabled={thy}
      key={btnKey + "0"}
      style={{ marginRight: "0px" }}
      onClick={onClick}
      className="btn-sm border-db btn-icon btn-round text-color cursor-pointer"
      sx={{
        boxShadow: "0 !important",
        outline: "none",
        "&:hover": { backgroundColor: "transparent", border: "none" },
        "&:focus": {
          outline: "none",
          backgroundColor: "transparent",
          border: "none",
        },
      }}
    >
      &nbsp;
      {found[0] && found[0].icon} &nbsp;
      {btnLabel} &nbsp;
    </StyledButton>
  );
}

export function MyButtonsColor(props) {
  const { onClick, btnKey, btnLabel, enState } = props;

  let found = menuButtns.filter((x) => {
    return x.key === btnKey;
  });
  if (!found) {
    found = menuButtns[0];
  }
  const thy = enState === true ? true : false;

  return (
    <StyledButtonColor
      disabled={thy}
      key={btnKey + "0"}
      style={{ marginRight: "0px" }}
      onClick={onClick}
      className="btn-sm border-db btn-icon btn-round text-color cursor-pointer"
      sx={{
        boxShadow: "0 !important",
        outline: "none",
        "&:hover": { backgroundColor: "transparent", border: "none" },
        "&:focus": {
          outline: "none",
          backgroundColor: "transparent",
          border: "none",
        },
      }}
    >
      &nbsp;
      {found[0] && found[0].icon} &nbsp;
      {btnLabel} &nbsp;
    </StyledButtonColor>
  );
}

export function MyFullButtons(props) {
  const { onClick, btnKey, btnLabel, enState } = props;

  let found = menuButtns.filter((x) => {
    return x.key === btnKey;
  });
  if (!found) {
    found = menuButtns[0];
  }
  const thy = enState === true ? true : false;

  return (
    <StyledButton
      disabled={thy}
      key={btnKey + "0"}
      style={{ marginRight: "0px", width: "100%", minWidth: "100%" }}
      onClick={onClick}
      className="btn-sm border-db btn-icon btn-round text-color cursor-pointer"
      sx={{
        boxShadow: "0 !important",
        outline: "none",
        "&:hover": { backgroundColor: "transparent", border: "none" },
        "&:focus": {
          outline: "none",
          backgroundColor: "transparent",
          border: "none",
        },
      }}
    >
      &nbsp;
      {found[0] && found[0].icon} &nbsp;
      {btnLabel} &nbsp;
    </StyledButton>
  );
}

export function MyFullButtonsColor(props) {
  const { onClick, btnKey, btnLabel, enState } = props;

  let found = menuButtns.filter((x) => {
    return x.key === btnKey;
  });
  if (!found) {
    found = menuButtns[0];
  }
  const thy = enState === true ? true : false;

  return (
    <StyledButtonColor
      disabled={thy}
      type="submit"
      key={btnKey + "0"}
      style={{ marginRight: "0px", width: "100%", minWidth: "100%" }}
      onClick={onClick}
      className="btn btn-main-2-inv btn-icon btn-round text-color-white"
      sx={{
        boxShadow: "0 !important",
        outline: "none",
        "&:hover": { backgroundColor: "transparent", border: "none" },
        "&:focus": {
          outline: "none",
          backgroundColor: "transparent",
          border: "none",
        },
      }}
    >
      &nbsp;
      {found[0] && found[0].icon} &nbsp;
      {btnLabel} &nbsp;
    </StyledButtonColor>
  );
}

export function MyTooltipButtons(props) {
  const { onClick, btnKey, btnLabel, enState } = props;

  let found = menuButtns.filter((x) => {
    return x.key === btnKey;
  });
  if (!found) {
    found = menuButtns[0];
  }
  const thy = enState === true ? true : false;

  return (
    <Tooltip title={btnLabel}>
      <StyledButton
        disabled={thy}
        key={btnKey + "0"}
        style={{ marginRight: "0px" }}
        onClick={onClick}
        className="btn-sm border-db btn-icon btn-round text-color cursor-pointer"
        sx={{
          boxShadow: "0 !important",
          outline: "none",
          "&:hover": { backgroundColor: "transparent", border: "none" },
          "&:focus": {
            outline: "none",
            backgroundColor: "transparent",
            border: "none",
          },
        }}
      >
        {found[0] && found[0].icon}
      </StyledButton>
    </Tooltip>
  );
}

export function MyTooltipSpan(props) {
  const { onClick, btnKey, btnLabel, enState } = props;

  let found = menuButtns.filter((x) => {
    return x.key === btnKey;
  });
  if (!found) {
    found = menuButtns[0];
  }
  const thy = enState === true ? true : false;

  return (
    <Tooltip title={btnLabel}>
      <span
        disabled={thy}
        key={btnKey + "0"}
        style={{ marginRight: "0px" }}
        onClick={onClick}
        className="btn-sm border-db btn-icon btn-round text-color cursor-pointer"
        sx={{
          boxShadow: "0 !important",
          outline: "none",
          "&:hover": { backgroundColor: "transparent", border: "none" },
          "&:focus": {
            outline: "none",
            backgroundColor: "transparent",
            border: "none",
          },
        }}
      >
        {found[0] && found[0].icon}
      </span>
    </Tooltip>
  );
}

export function MyHelpButtons(props) {
  const { onClick, btnKey, btnLabel, enState, btnTooltip } = props;

  let found = menuButtns.filter((x) => {
    return x.key === btnKey;
  });
  if (!found) {
    let found = menuButtns[0];
  }
  const thy = enState === true ? true : false;

  return (
    <Tooltip title={btnTooltip}>
      <StyledButton
        disabled={thy}
        key={btnKey + "0"}
        style={{ marginRight: "0px" }}
        onClick={onClick}
        className="btn-sm border-db btn-icon btn-round text-color cursor-arrow"
        sx={{
          boxShadow: "0 !important",
          outline: "none",
          "&:hover": { backgroundColor: "transparent", border: "none" },
          "&:focus": {
            outline: "none",
            backgroundColor: "transparent",
            border: "none",
          },
        }}
      >
        &nbsp;
        {found[0] && found[0].icon} &nbsp;
        {btnLabel} &nbsp;
      </StyledButton>
    </Tooltip>
  );
}

export function MyIconButtons(props) {
  const { onClick, enState } = props;
  const thystate = enState === true ? true : false;

  return (
    <Tooltip title={thystate ? "Collapse" : "Open"}>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={onClick}
        sx={{
          boxShadow: "0 !important",
          outline: "none",
          "&:focus": {
            border: "0 !important",
            outline: "none",
            boxShadow: "none",
            backgroundColor: "transparent",
          },
        }}
      >
        {thystate ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </Tooltip>
  );
}

export function MyArrowButtons(props) {
  const { onClick, btnKey, btnLabel, enState } = props;
  let found = menuArrows.filter((x) => {
    return x.key === btnKey;
  });
  if (!found) {
    found = menuArrows[0];
  }
  const thy = enState === true ? true : false;

  return (
    <Tooltip title={btnLabel}>
      <span
        disabled={thy}
        key={btnKey + "x0"}
        style={{ marginRight: "0px" }}
        onClick={onClick}
        className="btn-sm border-db btn-icon btn-round text-color cursor-pointer"
        sx={{
          boxShadow: "0 !important",
          outline: "none",
          "&:hover": { backgroundColor: "transparent", border: "none" },
          "&:focus": {
            outline: "none",
            backgroundColor: "transparent",
            border: "none",
          },
        }}
      >
        {found[0] && found[0].icon}
      </span>
    </Tooltip>
  );
}
