import { PcareActionTypes } from "./pcare.types";

const INITIAL_STATE = {
  pcarelist: null,
};

const pcareReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PcareActionTypes.PCAREREG:
      return {
        ...state,
        pcarelist: action.payload,
      };
    default:
      return state;
  }
};

export default pcareReducer;
