export const orgsActionTypes = {
  ORGS: "ORGS",
  ORGGROUPS: "ORGGROUPS",
  ORGDEPARTMENTS: "ORGDEPARTMENTS",
  ORGDEPARTMENTADD: "ORGDEPARTMENTADD",
  SELECTEDDEPARTMENT: "SELECTEDDEPARTMENT",
  SELECTEDORG: "SELECTEDORG",
  SELECTEDORGGROUP: "SELECTEDORGGROUP",
  ORGABOUT: "ORGABOUT",
  ORG_PROFILE: "ORG_PROFILE",
};
