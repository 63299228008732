import React, { useEffect, useState } from "react";

export const ArrowRightIcon = (props) => {
  const { height = "24", width = "24", size, fill = "#404040" } = props;
  const [viewbox, setViewbox] = useState("0 0 24 24");

  useEffect(() => {
    const newviewbox = "0 0" + " " + width + " " + height;
    setViewbox(newviewbox);
  }, [height, width]);

  return (
    <svg
      width={width}
      height={height}
      style={{ transform: `scale(${size})` }}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="8.41422"
        y="2.80762"
        width="13.0016"
        height="2"
        rx="1"
        transform="rotate(45 8.41422 2.80762)"
      />
      <rect
        x="7"
        y="19.7783"
        width="13"
        height="2"
        rx="1"
        transform="rotate(-45 7 19.7783)"
      />
    </svg>
  );
};
