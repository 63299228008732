import { searchUserText } from "../../redux/user/user.actions";
import log from "../../services/log";

export const postSearchText = (text, container) => {
  const stext = {};
  const keyValue = text ? text.split(":") : "";
  if (text && keyValue) {
    stext[container.type] = {};
    stext[container.type][keyValue[0].trim()] = keyValue[1].trim();
    log.info("searchtext", stext);
  }
  return stext;
};
