import React from "react";

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function validateIFSCode(str) {
  let regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  return str && str.toUpperCase().trim().match(regex) != null;
}

export function validatePAN(str) {
  let regex = /^[A-Z]{3}[PCFTGHLABJ]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;
  return str && str.toUpperCase().trim().match(regex) != null;
}

export function validateAADHAR(str) {
  let regex = /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/;
  return str && str.toUpperCase().trim().match(regex) != null;
}

export const validateUserAccess = (props) => {
  const { user, roleaccess } = props;
  if (user) {
    return user.role === roleaccess;
  }
  return false;
};
