import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { Link } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";

import ManageAccountsSharpIcon from "@mui/icons-material/ManageAccountsSharp";
import ToggleOnSharpIcon from "@mui/icons-material/ToggleOnSharp";

import { Title } from "./Title";
import SecurityList, { SecurityAccInfo } from "./SecurityAccInfo";
import { SelectButton } from "./SelectButton";

import { BlockAccList } from "./ConstantsAndValues";
import { UploadFile } from "./UploadFile";

import { addWallet } from "../utils/web3account";
import { loadWallet } from "../../redux/user/user.actions";

import ThreedotMenu from "./ThreedotMenu";
import log from "../../services/log";

export const BlockAccManager = (props) => {
  const { getVal } = props;
  const dispatch = useDispatch();

  const wallet = useSelector((state) => state?.user?.wallet);

  const [actionState, setActionState] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const [privateKey, setPrivateKey] = useState("");

  const btnHandleChange = (e) => {
    console.log("btn eventBlockAccManager.js bef", e);
    setActionState(e);
    getVal(e);
  };

  const handlePrivateKey = (e) => {
    setPrivateKey(e.target.value);
  };

  const handlePassphrase = (e) => {
    setPassphrase(e.target.value);
  };

  const handleSubmit = () => {
    const wallet = privateKey ? addWallet(privateKey) : "";
    dispatch(loadWallet(wallet));
    setActionState("");
  };

  const handleSubmitCreate = () => {
    passphrase && dispatch();
    setActionState("");
  };

  const handleSubmitRetrieve = () => {
    passphrase && dispatch();
    setActionState("");
  };

  const handleCancel = () => {
    setActionState("");
  };

  const containerType = {
    id: "blockaccInfo",
    label: "",
    type: "Block Accounts",
    menuList: [],
    comboLabel: "Security Account",
    comboList: BlockAccList,
  };

  // log.info("blockmanager", passphrase);

  return (
    <Grid container>
      <Grid item display="none">
        <Divider />
        <SecurityList setAction={setActionState} />
        <br /> <Divider /> <br />
        <Box mt={2} ml={2} mr={4} width="100%">
          {actionState === "create" && (
            <Box>
              <TextField
                id="bam01"
                label="Phasphrase"
                style={{ width: "100%" }}
                variant="standard"
                value={passphrase}
                onChange={handlePassphrase}
              />
              <br /> <Divider /> <br />
              <Box mr={0} key={"bsubmint1"} className="right">
                <Link
                  key={"lcancel1"}
                  onClick={handleCancel}
                  className="btn btn-transparent btn-icon btn-round text-color cursor-pointer"
                >
                  Cancel
                </Link>
                &nbsp;
                <Link
                  key={"lsubmint1"}
                  onClick={handleSubmitCreate}
                  className="btn btn-transparent btn-icon btn-round text-color cursor-pointer"
                >
                  Submit
                </Link>
              </Box>
            </Box>
          )}

          {actionState === "add" && (
            <Box>
              <TextField
                id="bam11"
                label="Private Key"
                style={{ width: "100%" }}
                variant="standard"
                value={privateKey}
                onChange={handlePrivateKey}
              ></TextField>

              <UploadFile addPrivateKey={setPrivateKey} />

              <br />

              <Box mr={0} key={"bcancel2"} className="right">
                <Link
                  key={"lcancel2"}
                  onClick={handleCancel}
                  className="btn btn-transparent btn-icon btn-round text-color cursor-pointer"
                >
                  Cancel
                </Link>
                &nbsp;
                <Link
                  key={"lsubmit2"}
                  onClick={handleSubmit}
                  className="btn btn-transparent btn-icon btn-round text-color cursor-pointer"
                >
                  Submit
                </Link>
              </Box>
            </Box>
          )}

          {actionState === "retrieve" && (
            <Box>
              <TextField
                id="bam01"
                label="Phasphrase"
                style={{ width: "100%" }}
                variant="standard"
                value={passphrase}
                onChange={handlePassphrase}
              />
              <br /> <Divider /> <br />
              <Box mr={0} key={"bsubmit3"} className="right">
                <Link
                  key={"lcancel3"}
                  onClick={handleCancel}
                  className="btn btn-transparent btn-icon btn-round text-color cursor-pointer"
                >
                  Cancel
                </Link>
                &nbsp;
                <Link
                  key={"lsubmit3"}
                  onClick={handleSubmitRetrieve}
                  className="btn btn-transparent btn-icon btn-round text-color cursor-pointer"
                >
                  Submit
                </Link>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default BlockAccManager;
