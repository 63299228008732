import * as React from "react";
import { Box, Paper, TableContainer } from "@mui/material";
import { useEffect, useState } from "react";
import { getAddress } from "../../../redux/actions/locs";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from "../../../redux/actions/auth";

import ProfileUser from "../../profile/components/ProfileUserPHA";
import ProfileListData from "../../profile/components/ProfileListDataPHA";
import ThreeDotProfile from "../../profile/components/ThreedotProfile";
import { AddressInputPart } from "../../forms/AddressInputPartPHA";
import Registration from "./RegistrationPHA";

import log from "../../../services/log";
import { buildProfileQuery } from "../../dbcq/profiledb";
import { buildAddressQuery } from "../../dbcq/addressdb";

export function ProfileListPHA(props) {
  const user = useSelector((state) => state?.auth?.user?.user);
  const searchcontext = useSelector((state) => state?.user.searchcontext);
  const profile = useSelector((state) => state?.auth?.profile);
  const address = useSelector((state) => state?.locs?.address);

  const dispatch = useDispatch();
  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });

  const [openState, setOpenState] = useState("");
  // const [openUserProfile, setUserProfile]      = useState(false);
  const [openUserAddress, setUserAddress] = useState(false);

  useEffect(() => {
    if (user) {
      const resetpage = { ...pagedata, page: 0 };
      const jsonData = buildAddressQuery(resetpage, searchcontext, user);
      // log.info("profilelist query", jsonData);
      dispatch(getAddress(jsonData));
    }
  }, [user, dispatch]);

  // fetch profile
  useEffect(() => {
    if (user) {
      const resetpage = { ...pagedata, page: 0 };
      const jsonData = buildProfileQuery(resetpage, searchcontext, user);
      dispatch(getProfile(jsonData)).then((r) => {
        log.info("md profile", r);
        if (r.results) {
          setPage({ ...pagedata, count: r.totalResults, pages: r.totalPages });
        }
      });
    }
  }, [user, dispatch]);

  const handleAddressUpdate = () => {
    setUserAddress(true);
  };

  const handleClose = () => {
    setOpenState("");
  };

  // log.info("ProfileList", profile);

  return (
    <Box>
      <Box className={"colfr"}>
        <ThreeDotProfile openState={setOpenState} />
      </Box>
      &nbsp;
      <Box>
        {openState === "updateAddress" ? (
          <AddressInputPart cancel={handleClose} />
        ) : openState === "updateProfile" ? (
          <Registration handleClose={handleClose} />
        ) : (
          <Box>
            <TableContainer component={Paper}>
              {profile && <ProfileUser privacy={false} label={"Personal"} />}
              {address && (
                <ProfileListData
                  privacy={false}
                  label={"Address"}
                  updateCallback={handleAddressUpdate}
                />
              )}
            </TableContainer>
          </Box>
        )}
      </Box>
    </Box>
  );
}
