import { useState } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import log from "../../services/log";
import {
  SearchBoxStandard,
  StyledTableCell,
  StyledTableRow,
  tableParam,
} from "../common/ConstantsAndValues";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import {
  Medicine_TaxPCList,
  Medicine_PriceList,
} from "../../tests/Medicine_PriceList";

export function MedProcListRow(props) {
  const { row, handleRowClick, rowno, handleInputChange } = props;
  const selectmedrows = useSelector((state) => state.drugs.selectmedrows);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [maplist, setMaplist] = useState(false);
  const dispatch = useDispatch();

  const priceList = Medicine_PriceList;
  const tax = Medicine_TaxPCList;

  useEffect(() => {
    //log.info("medicinelistrow", selectmedrows);
    if (selectmedrows) {
      let isSelected = false;
      selectmedrows.forEach((r) => {
        // log.info("medicinlistrow", r)
        if (r.metadata.medicineId === row.metadata.medicineId) {
          isSelected = true;
        }
      });
      setSelectedRow(isSelected);
    }
  }, [selectmedrows]);

  useEffect(() => {
    if (row) {
      const newmap = [];
      newmap.push({
        id: "name",
        label: "Name",
        value: row?.medicineName?.name,
      });
      newmap.push({
        id: "salt",
        label: "Contains",
        value: row?.medicineName?.salt,
      });
      newmap.push({
        id: "dose",
        label: "Dose",
        value: row?.medicineDosage?.dose,
      });
      newmap.push({
        id: "unit",
        label: "Unit",
        value: row?.medicineDosage?.doseUnit,
      });
      newmap.push({
        id: "freq",
        label: "Frquency",
        value: row?.medicineDosage?.doseFrequency,
      });
      newmap.push({
        id: "route",
        label: "Route",
        value: row?.medicineDosage?.doseRoute,
      });
      newmap.push({
        id: "refill",
        label: "Refills",
        value: row?.refills?.numRefill,
      });
      setMaplist(newmap);
    }
  }, [row]);

  const handleClick = () => {
    log.info("medicinelist");
    setOpen(!open);
  };

  const handleRowClickLocal = (e) => {
    log.info("druglist", e);
    const newstate = !selectedRow;
    setSelectedRow(newstate);
    handleRowClick(e, newstate);
  };

  const getRate = (itemId, index) => {
    let itemRate = "";
    let taxRate = "";
    let itemGroup = "";
    let itemUOM = "";

    const founditem = priceList.filter((itm) => itm.drugid === itemId);

    itemRate = founditem.length > 0 ? founditem[0].rate : "";
    itemUOM = founditem.length > 0 ? founditem[0].uom : "";
    itemGroup = founditem.length > 0 ? founditem[0].druggrpid : "";

    taxRate = tax.filter((itm) => itm.grpid === itemGroup)[0].taxpc;

    //console.log('rates=', itemId, itemRate, taxRate, itemUOM);

    const list = []; // [...rows];
    list[index]["rate"] = itemRate;
    list[index]["uom"] = itemUOM;
    list[index]["taxpc"] = taxRate;

    return [itemRate, taxRate, itemUOM];
  };

  // log.info("medicinelist row", row);

  return (
    <React.Fragment>
      <StyledTableRow key={"row" + rowno} className="text-color lh-35">
        <StyledTableCell
          className={row.qty <= 0 ? "text-color-gray" : "text-color"}
        >
          <b>{row.masterid}</b>.{row.id}
        </StyledTableCell>
        <StyledTableCell
          className={row.qty <= 0 ? "text-color-gray" : "text-color"}
        >
          {row.drug}
        </StyledTableCell>
        <StyledTableCell align="right" className="border-db">
          <input
            key={row.id + rowno + "r"}
            style={{ maxWidth: "70px", textAlign: "right" }}
            defaultValue={getRate(row.drugid, rowno)[0]}
            name="rate"
            onChange={(e) => handleInputChange(e, rowno)}
          />
        </StyledTableCell>
        <StyledTableCell style={{ width: "50px" }}>
          <input
            key={row.id + rowno + "q"}
            style={{ maxWidth: "50px", textAlign: "center" }}
            defaultValue={row.qty}
            name="qty"
            onChange={(e) => handleInputChange(e, rowno)}
          />
        </StyledTableCell>
        <StyledTableCell>
          {getRate(row.drugid, rowno)[1]} {/*packageType*/}
        </StyledTableCell>
        <StyledTableCell align="right" className="border-db">
          {parseFloat(row.amt).toFixed(2)}
        </StyledTableCell>
        <StyledTableCell align="right" className="border-db">
          {getRate(row.drugid, rowno)[2]} {/*tax pc*/}
        </StyledTableCell>
        <StyledTableCell align="right" className="border-db">
          {parseFloat(row.amtwtax).toFixed(2)}
        </StyledTableCell>
        <StyledTableCell>
          <input
            key={row.id + rowno + "re"}
            defaultValue={row.comment}
            name="comment"
            onChange={(e) => handleInputChange(e, rowno)}
          />
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}
