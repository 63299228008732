import {
  getTestTypes,
  getTimeSlot,
  createAppointmentService,
  removeAppointmentService,
  updateAppointmentService,
  deleteApptGuestService,
  addApptGuestService,
  getApptGuestsService,
  getAppointmentService,
  getAppointmentmlistService,
  getAppointmentsService,
  getProviderAppointmentsService,
  createProviderAppointmentService,
  updateProviderAppointmentService,
  removeProviderAppointmentService,
  getProviderAppointmentService,
  getProviderAppointmentmlistService,
  getApptDateSlotsService,
  getUserAppointmentsService,
  getUserAppointmentDates,
  getUserAppointmentTimings,
} from "../../services/appointment-service";
import { searchService } from "../../services/location-service";
import log from "../../services/log";
import { failure, success } from "../../utils/redux";

import { AppointmentActionTypes } from "../appointment/appointment.types";

export const allTests = () => (dispatch) => {
  return getTestTypes().then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.GET_TEST_TYPES,
        payload: response,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const getTimeSlotByAddress = (addressId) => (dispatch) => {
  return getTimeSlot(addressId).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const getAppointmentList = (inputValue) => (dispatch) => {
  return getAppointmentsService(inputValue).then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.APPT_LIST,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getAppointmentGuestList = (inputValue) => (dispatch) => {
  return getAppointmentsService(inputValue).then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.APPT_GLIST,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const getAppointmentRangeList = (inputValue) => (dispatch) => {
  return getAppointmentmlistService(inputValue).then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.APPT_LIST,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const getAppointmentByid = (payload) => (dispatch) => {
  return getAppointmentService(payload).then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.APPT_GET,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getProviderAppointmentByid = (payload) => (dispatch) => {
  return getProviderAppointmentService(payload).then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.APPT_GET,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const removeAppointment = (payload) => (dispatch) => {
  return removeAppointmentService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const removeProviderAppointment = (payload) => (dispatch) => {
  return removeProviderAppointmentService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const updateAppointment = (payload) => (dispatch) => {
  return updateAppointmentService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const updateProviderAppointment = (payload) => (dispatch) => {
  return updateProviderAppointmentService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const createAppointment = (payload) => (dispatch) => {
  return createAppointmentService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const createProviderAppointment = (payload) => (dispatch) => {
  return createProviderAppointmentService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const setAppServiceType = (data) => (dispatch) => {
  dispatch({
    type: AppointmentActionTypes.APPTSTYPE,
    payload: data,
  });
};

export const setAppServiceSetting = (data) => (dispatch) => {
  dispatch({
    type: AppointmentActionTypes.APPTDOCSETTING,
    payload: data,
  });
};

export const setAppServices = (data) => (dispatch) => {
  dispatch({
    type: AppointmentActionTypes.APPTSERVS,
    payload: data,
  });
};

export const initProviderClientList = (data) => (dispatch) => {
  dispatch({
    type: AppointmentActionTypes.CLIENTAPPT_LIST,
    payload: data,
  });
};

export const setApptDates = (data) => (dispatch) => {
  dispatch({
    type: AppointmentActionTypes.EVENT_DATES,
    payload: data,
  });
};

export const setEventFilters = (data) => (dispatch) => {
  dispatch({
    type: AppointmentActionTypes.EVENT_FILTERS,
    payload: data,
  });
};

export const addApptGuest = (payload) => (dispatch) => {
  return addApptGuestService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const deleteApptGuest = (payload) => (dispatch) => {
  return deleteApptGuestService(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const getApptGuests = (payload) => (dispatch) => {
  return getApptGuestsService(payload).then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.GUESTS_LIST,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const getProviderAppointmentList = (inputValue) => (dispatch) => {
  return getProviderAppointmentsService(inputValue).then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.CLIENTAPPT_LIST,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  );
};

export const getProviderAppointmentRangeList = (inputValue) => (dispatch) => {
  return getProviderAppointmentmlistService(inputValue).then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.CLIENTAPPT_LIST,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const getAppointmentDateSlots = (payload) => (dispatch) => {
  return getApptDateSlotsService(payload).then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.DATE_SLOTS,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return Promise.reject();
    }
  );
};

export const getProviderAppointmentListElastic = (payload) => (dispatch) => {
  dispatch({
    type: AppointmentActionTypes.PROVIDER_LIST,
  });
  return searchService(payload).then(
    (response) => {
      // console.log("received labs", response.data.hits);
      if (response?.data?.code == 200) {
        dispatch({
          type: success(AppointmentActionTypes.PROVIDER_LIST),
          payload: response?.data?.data?.hits,
        });
        return Promise.resolve(response?.data?.data);
      } else {
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: failure(AppointmentActionTypes.PROVIDER_LIST),
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getAppointmentReasonListElastic = (payload) => (dispatch) => {
  dispatch({
    type: AppointmentActionTypes.REASON_LIST,
  });
  return searchService(payload).then(
    (response) => {
      // console.log("received labs", response.data.hits);
      if (response?.data?.code == 200) {
        dispatch({
          type: success(AppointmentActionTypes.REASON_LIST),
          payload: response?.data?.data?.hits,
        });
        return Promise.resolve(response?.data?.data);
      } else return Promise.reject();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: failure(AppointmentActionTypes.REASON_LIST),
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getUsersAppointmentList = (params) => (dispatch) => {
  dispatch({
    type: AppointmentActionTypes.APPOINTMENT_LIST,
  });
  return getUserAppointmentsService(params).then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.APPOINTMENT_LIST,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: failure(AppointmentActionTypes.APPOINTMENT_LIST),
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getUsersAppointmentDates = (params) => (dispatch) => {
  dispatch({
    type: AppointmentActionTypes.APPOINTMENT_DATES,
  });
  return getUserAppointmentDates(params).then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.APPOINTMENT_DATES,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: failure(AppointmentActionTypes.APPOINTMENT_DATES),
        payload: null,
      });
      return Promise.reject();
    }
  );
};

export const getUsersAppointmentSlots = (params) => (dispatch) => {
  dispatch({
    type: AppointmentActionTypes.APPOINTMENT_SLOTS,
  });
  return getUserAppointmentTimings(params).then(
    (response) => {
      dispatch({
        type: AppointmentActionTypes.APPOINTMENT_SLOTS,
        payload: response.data.results,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: failure(AppointmentActionTypes.APPOINTMENT_SLOTS),
        payload: null,
      });
      return Promise.reject();
    }
  );
};
