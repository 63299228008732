import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import {
  MyButtons,
  MyTooltipButtons,
  MyHelpButtons,
} from "../../CustomComponents/MyButtons";
import { ReportsList } from "../../reports/ReportsList";
import { ReportsOrderList } from "../../reports/ReportsOrderList";
import { ReportsPatOrderInput } from "../../reports/ReportsPatOrderInput";
import { ChartContainer } from "../../reports/ChartContainer";
import { getLabsList } from "../../../redux/labs/labs.actions";
import log from "../../../services/log";

export function ReportsListUSR() {
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const reps_list = useSelector((state) => state?.labs?.labslist);
  const presorderlist = useSelector((state) => state?.drugs?.presorderlist);

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [selectedrows, SetSelectedrows] = useState([]);
  const [action, setAction] = useState("");
  const [checkedRows, setCheckedRows] = useState(null);

  const dispatch = useDispatch();

  const buildQuery = () => {
    const jsonData = {};
    jsonData["metadata"] = {};
    jsonData.metadata["patientId"] = user?.metadata.accountId;
  };

  useEffect(() => {
    if (user) {
      dispatch(getLabsList(buildQuery())).then((r) => {
        if (r.results) {
          log.info("labslist", r);
          setPage({ ...pagedata, pages: r.totalPages, count: r.totalResults });
        }
      });
    }
  }, [user, dispatch]);

  const handleCancel = () => {
    setAction("");
  };

  const handlePost = () => {};

  const handleRemove = () => {};

  const handleReset = () => {};

  const handleRequest = () => {
    setAction("order");
  };

  const handleChart = () => {
    setAction("chart");
  };

  const handleRequestList = () => {
    setAction("requestList");
  };

  log.info("reportslistusr", pagedata);

  return (
    <Grid container>
      <Grid item xs>
        <div className="clear"></div>
        {action === "order" ? (
          <div>
            <ReportsPatOrderInput
              cancel={handleCancel}
              doctor={user}
              // patient   = {patient}
              report={checkedRows}
            />
          </div>
        ) : action === "requestList" ? (
          <div>
            <div className="colfr0">
              <MyTooltipButtons
                onClick={handlePost}
                btnKey={"post"}
                btnLabel={"Post"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleReset}
                btnKey={"reset"}
                btnLabel={"Reset"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleRemove}
                btnKey={"remove"}
                btnLabel={"Remove selected"}
                enState={false}
              />
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleCancel}
                btnKey={"cancel"}
                btnLabel={"Cancel"}
                enState={false}
              />
            </div>

            <ReportsOrderList
              handleClose={handleCancel}
              reportlist={presorderlist}
            />
          </div>
        ) : action === "chart" ? (
          <div>
            <div className="colfr0">
              &nbsp; &nbsp;
              <MyTooltipButtons
                onClick={handleCancel}
                btnKey={"cancel"}
                btnLabel={"Cancel"}
                enState={false}
              />
            </div>

            <ChartContainer />
          </div>
        ) : (
          <div>
            <div className="colfr0">
              <MyTooltipButtons
                onClick={handleChart}
                btnKey={"chart"}
                btnLabel={"Charts"}
                enState={false}
              />
              {/*
                                    &nbsp; &nbsp;
                                    <MyTooltipButtons 
                                        onClick    = {handleRequestList}
                                        btnKey     = {"requestlist"}
                                        btnLabel   = {"Request list"}
                                        enState    = {false} 
                                    />
                                    &nbsp; &nbsp;
                                    <MyTooltipButtons 
                                        onClick    = {handleRequest}
                                        btnKey     = {"request"}
                                        btnLabel   = {"Request"}
                                        enState    = {false} 
                                    />
                                    */}
            </div>

            <ReportsList
              reportlist={reps_list}
              selectedRows={selectedrows}
              selectRows={SetSelectedrows}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
