import { Box, FormLabel, Typography } from "@mui/material";
import locationIcon from "../../assets/images/homepage/location.svg";
import mailIcon from "../../assets/images/homepage/mail.svg";
import phoneIcon from "../../assets/images/homepage/phone.svg";
import { useDispatch } from "react-redux";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { sendUserFeedbackLanding } from "../../redux/feedback/feedback.actions";
import { useState } from "react";
import { FeedbackActionTypes } from "../../redux/feedback/feedback.types";

export default function ContactUs() {
  const dispatch = useDispatch();
  const [formstate, setFormState] = useState(0);

  // feedback inital values
  const feedBackValueSchema = () => {
    return {
      feedback: "",
      email: "",
    };
  };

  const feedBackValidateSchema = Yup.object().shape({
    feedback: Yup.string().required("Please enter a valid Feedback"),
    email: Yup.string().email().required("Please enter a valid email"),
  });

  const submitNow = async (values, { resetForm }) => {
    var params = {
      metadata: {
        email: values.email,
      },
      comment: values.feedback,
    };

    let response = await dispatch(sendUserFeedbackLanding(params));
    if (response.code === 201) {
      setFormState(1);
      resetForm(feedBackValueSchema);
      setTimeout(() => {
        setFormState(0);
      }, 2000);
    }
  };

  return (
    <Box
      sx={{ mx: { xs: 4, md: 8 }, py: { xs: 3, md: 6, lg: 12 } }}
      id="sectionFour"
    >
      <Typography
        sx={{ fontSize: "28px", color: "#404040", fontWeight: "600" }}
      >
        Contact us
      </Typography>
      <Box sx={{ display: { xs: "block", md: "flex" }, mt: 4 }}>
        <Box>
          <Box sx={{ display: "flex", mt: 3 }}>
            <img src={locationIcon} alt="locationIcon" width={28} height={28} />
            <Typography ml={1} sx={{ fontSize: "17px" }}>
              <span style={{ color: "#FF6D0D" }}>Metalane, Inc.</span>
              <br /> 335 Bryant Street
              <br /> Palo Alto, California, 94301
              <br /> USA
            </Typography>{" "}
          </Box>{" "}
          <Box sx={{ display: "flex", mt: 6 }}>
            {" "}
            <img src={mailIcon} alt="mailIcon" width={25} height={25} />
            <Typography ml={1} sx={{ fontSize: "17px" }}>
              contact@metalane.ai
            </Typography>
          </Box>
          <Box sx={{ display: "flex", mt: 6 }}>
            {" "}
            <img src={phoneIcon} alt="phoneIcon" width={25} height={25} />
            <Typography ml={1} sx={{ fontSize: "17px" }}>
              +1 415-275-0617
            </Typography>
          </Box>
        </Box>
        <Box
          mt={{ xs: 4, md: 0 }}
          ml={{ xs: 0, md: 14 }}
          sx={{ width: { sx: "80%", md: "100%" } }}
        >
          <Formik
            initialValues={feedBackValueSchema()}
            validationSchema={feedBackValidateSchema}
            onSubmit={submitNow}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box>
                  <FormLabel
                    sx={{
                      fontSize: "15px",
                      marginLeft: 1,
                      fontFamily: "DM SANS",
                      display: "block",
                    }}
                  >
                    Email
                  </FormLabel>
                  <Field
                    name="email"
                    placeholder="Enter your email"
                    className="meta__input w-400"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-msg pl-1"
                  />
                </Box>
                <Box mt={4} className="w-full">
                  <FormLabel
                    sx={{
                      fontSize: "15px",
                      marginLeft: 1,
                      fontFamily: "DM SANS",
                      display: "block",
                    }}
                  >
                    Your Message
                  </FormLabel>
                  <Field
                    name="feedback"
                    component="textarea"
                    rows={4}
                    placeholder="Type your message"
                    className="meta__textarea  w-full-textarea"
                  />
                  <ErrorMessage
                    name="feedback"
                    component="div"
                    className="error-msg pl-1"
                  />
                  {/* <textarea
                    className="meta__textarea  w-full"
                    placeholder="Type your message"
                    rows={4}
                  ></textarea> */}
                </Box>
                {formstate === 1 && (
                  <p className="mb-0">Feedback sent successfully</p>
                )}
                <button
                  type="submit"
                  className={`${"meta__button_orange"} mt-3 mb-2 `}
                  // onClick={() => setCurrentStep(2)}
                >
                  Send message
                </button>
              </Form>
            )}
          </Formik>
          <Box sx={{ py: 2, display: { sx: "block", md: "none" } }}></Box>
        </Box>
      </Box>
    </Box>
  );
}
