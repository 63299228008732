export const EventsActionTypes = {
  DELETE_EVENT: "DELETE_EVENT",
  EVENT_FILTERS: "EVENT_FILTERS",
  RECEIVE_EVENTS: "RECEIVE_EVENTS",
  UPDATE_EVENT: "UPDATE_EVENT",
  CREATE_EVENT: "CREATE_EVENT",
  TOGGLE_WEEKENDS: "TOGGLE_WEEKENDS",
  SELECT_DATE: "SELECT_DATE",
  SELECT_DISABLEDATE: "SELECT_DISABLEDATE",
  DATES_AVAILABLE: "DATES_AVAILABLE",
  SETAPPT_DATE: "SETAPPT_DATE",
};
