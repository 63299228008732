export default {
  COMMANDS: "commandsModule",
  DATA_SOURCE: "dataSourcesModule",
  PANEL: "panelModule",
  SOP_CLASS_HANDLER: "sopClassHandlerModule",
  TOOLBAR: "toolbarModule",
  VIEWPORT: "viewportModule",
  CONTEXT: "contextModule",
  LAYOUT_TEMPLATE: "layoutTemplateModule",
  HANGING_PROTOCOL: "hangingProtocolModule",
  UTILITY: "utilityModule",
};
