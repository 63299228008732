import React, { useState } from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import logo from "../../assets/images/logo.png";

import { useSelector, useDispatch } from "react-redux";
import { login } from "../../redux/actions/auth";
import Password from "../CustomComponents/Password";
import InputComponent from "../CustomComponents/InputComponent";
import { MyFullButtonsColor } from "../CustomComponents/MyButtons";
import { PatientListNullRow } from "../CustomComponents/TableList";
import { StyledLink } from "../common/ConstantsAndValues";
import { useFormik } from "formik";
import { userSigninSchema } from "../schema/userSchema";
import log from "../../services/log";
import { setAppDashUrl } from "../../redux/appstate/appstate.actions";
// import toast from "react-hot-toast";
import { setLocalStorage } from "../../utils/helpers";
import { useMetaTheme } from "../theme/ThemeContext";

function LoginPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  // const auth = useSelector((store) => store.auth);

  // get theme
  const { theme } = useMetaTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function getNewUrl(e) {
    //const found = MetaDefaultPages.filter(x => {
    //    return x.href === e;
    //});
    //return ((found.length > 0) ? (found[0].href + found[0].default) : e)
    return e;
  }

  const signupform = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: userSigninSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      setIsLoading(true);
      // log.info("medicineinput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData["metadata"] = {};
      jsonData.metadata["email"] = values.email;
      jsonData["password"] = values.password;

      // log.info("signin", jsonData);

      dispatch(login(jsonData)).then(
        (r) => {
          log.info("signin response", r);
          if (r && r?.user && r?.user?.isEmailVerified === false) {
            setIsLoading(false);
            navigate("/signcode");
          } else if (
            r &&
            r?.user &&
            (r?.user?.metadata?.role === "metauser" ||
              r?.user?.metadata?.role === "metaorg" ||
              r?.user?.metadata?.role === "metamd" ||
              r?.user?.metadata?.role === "metalab" ||
              r?.user?.metadata?.role === "metapharma" ||
              r?.user?.metadata?.role === "metafin" ||
              r?.user?.metadata?.role === "metasrv" ||
              r?.user?.metadata?.role === "metasys")
          ) {
            setIsLoading(false);
            setLocalStorage("patient", JSON.stringify(PatientListNullRow));
            // toast.success("Logged In Successfully");
            const newurl = getNewUrl("/" + r.user.metadata.role);
            // log.info("dashboard url", newurl);
            dispatch(setAppDashUrl(newurl));
            // console.log(newurl);
            if (r?.user?.metadata?.role === "metauser") {
              navigate(getNewUrl(newurl + "/home"));
            } else {
              navigate(getNewUrl(newurl));
            }
          } else {
            setIsLoading(false);
            setLocalStorage("patient", JSON.stringify(PatientListNullRow));
            navigate("/");
          }
        },
        (response) => {
          setIsLoading(false);
          alert("Failed to login! Please check your ID and Password!!!");
        }
      );
    },
    onReset: (values) => {},
  });

  const handleClickShowPassword = () => {
    signupform.setFieldValue("showpassword", !signupform.values.showpassword);
  };

  // log.info ("signup form", signupform.values);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box display={{ xs: "block", md: "none" }} className=" px-3 px-md-0">
          <form className="" onSubmit={signupform.handleSubmit}>
            <Box
              // onClick={() => handleChange("/")}
              className="enable-link"
              id="imgLogoMain"
              component="img"
              sx={{
                width: "100%",
                height: "50px",
                padding: "0 5rem",
                // marginBottom: "4rem",
                // padding: (theme) => theme?.dashboardlogoSizes?.padding,
                maxHeight: (theme) => theme?.dashboardlogoSizes?.maxHeight,
                maxWidth: (theme) => theme?.dashboardlogoSizes?.maxWidth,
              }}
              alt="logo"
              src={logo}
            />
            <Box
              sx={{
                background: "#F2FDFF",
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                margin: "0.5rem auto",
              }}
            ></Box>
            <label className="meta__input__label">
              Registered email address
            </label>
            <input
              placeholder="Enter your email address"
              className="meta__input__mobile"
              id="email"
              name="email"
              label="Email"
              defaultValue={signupform.values.email}
              errors={signupform.errors.email ? true : false}
              variant="standard"
              handleChange={signupform.handleChange}
            />
            <label className="meta__input__label">Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              className="meta__input__mobile"
              id="password"
              name="password"
              label="Password"
              values={signupform.values.password}
              showPassword={signupform.values.showpassword}
              handleChange={signupform.handleChange}
              handleClickShowPassword={handleClickShowPassword}
              errors={signupform.errors.password ? true : false}
            />
            <button className="meta__button_green w-full mt-4" type="submit">
              {isLoading ? "Loading..." : "Sign in"}
            </button>
            <button className="meta__button_green_outlined w-full mt-3">
              Sign in with mobile number
            </button>
            <Typography className="fs-13 text-center mt-3" color="#8F8F8F">
              Do not have an account?{" "}
            </Typography>
            <Link
              color="#2A8AA7"
              href="/signup"
              underline="always"
              className="d-block fs-13 text-center text-decoration-underline"
            >
              Register now
            </Link>
          </form>
        </Box>
      </ThemeProvider>
      <Box display={{ xs: "none", md: "block" }} className="px-3 px-md-0">
        <form className="" onSubmit={signupform.handleSubmit}>
          <Box
            className="clear py-md-5"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <center>
              <h3 className="text-color-primary">Sign in</h3>
            </center>
            <InputComponent
              id="email"
              name="email"
              label="Email"
              defaultValue={signupform.values.email}
              errors={signupform.errors.email ? true : false}
              variant="standard"
              handleChange={signupform.handleChange}
            />

            <Box className="clear">&nbsp; </Box>

            <Password
              id="password"
              name="password"
              label="Password"
              values={signupform.values.password}
              showPassword={signupform.values.showpassword}
              handleChange={signupform.handleChange}
              handleClickShowPassword={handleClickShowPassword}
              errors={signupform.errors.password ? true : false}
            />

            <Box className="clear">&nbsp; </Box>

            <MyFullButtonsColor
              // onClick={signupform.handleSubmit}
              btnKey={"x"}
              btnLabel={isLoading ? "Loading..." : "Login"}
              enState={false}
              isLoading={isLoading}
            />

            <Box className="clear">&nbsp; </Box>

            <Box
              className="clear border-db text-color-read"
              sx={{
                padding: "16px",
                margin: "16px 0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Box className="clear">&nbsp; </Box>
              <center>
                Forgot Password?
                <Link href="/reset" underline="always" className="text-color">
                  Request
                </Link>
              </center>
              <Box className="clear">&nbsp; </Box>
              <center>
                Need an account?
                <Link href="/signup" underline="always" className="text-color">
                  Register
                </Link>
              </center>
              <Box className="clear">&nbsp; </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
}

export default LoginPage;
