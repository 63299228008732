import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setDrugPrecriptionList,
  setPatientDrugs,
} from "../../../redux/drugs/drugs.actions";
import { MyTooltipButtons } from "../../CustomComponents/MyButtons";
import { MedicinePresList } from "../../medicine/MedicinePresList";
import log from "../../../services/log";

export const DrugPresListView = (props) => {
  const { handleCancel } = props;

  const preslist = useSelector((state) => state?.drugs?.preslist);
  const selectedrow = useSelector((state) => state?.drugs?.drugorderselect);
  const [checkedRows, setCheckedRows] = useState(null);

  const dispatch = useDispatch();

  const handleReset = () => {
    // log.info("prescribelist reset");
    dispatch(setDrugPrecriptionList([]));
  };

  const handleRemove = () => {
    // log.info("prescribelist remove");
    const newlist = preslist.filter(
      (p) => p.metadata.medicineId !== selectedrow.metadata.medicineId
    );
    dispatch(setDrugPrecriptionList(newlist));
  };

  const handlePost = () => {
    // log.info("prescribelist post");
    preslist.forEach((d) => {
      dispatch(setPatientDrugs(d));
    });
    // reset list after post
    dispatch(setDrugPrecriptionList([]));
  };

  const handleSelectRows = (row) => {
    // log.info("drugslist md", row);
    setCheckedRows(row);
  };

  return (
    <div>
      <div className="colfr0">
        <MyTooltipButtons
          onClick={handlePost}
          btnKey={"post"}
          btnLabel={"Post"}
          enState={false}
        />
        &nbsp; &nbsp;
        <MyTooltipButtons
          onClick={handleReset}
          btnKey={"reset"}
          btnLabel={"Reset"}
          enState={false}
        />
        &nbsp; &nbsp;
        <MyTooltipButtons
          onClick={handleRemove}
          btnKey={"remove"}
          btnLabel={"Remove selected"}
          enState={false}
        />
        &nbsp; &nbsp;
        <MyTooltipButtons
          onClick={handleCancel}
          btnKey={"cancel"}
          btnLabel={"Cancel"}
          enState={false}
        />
      </div>

      <MedicinePresList handleClose={handleCancel} medicinelist={preslist} />
    </div>
  );
};
