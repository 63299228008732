import { LabsActionTypes } from "./labs.types";

const INITIAL_STATE = {
  labslist: null,
  labmorelist: null,
  searchlist: null,
  labsmap: null,
  metalabs: null,
  metaroot: null,
  labsorder: null,
  radlabs: null,
  radsearchlist: null,
  selectmedrows: null,
  moreselect: null,
  radlabsorderlist: null,
  labcomponent: null,
  radlabselect: null,
  labpanels: null,
  paneltests: null,
  labpanelcomponent: null,
  radlablist: null,
  radmoreselect: null,
  radmorelist: null,
  radlabpreslist: null,
  labpresselectrow: null,
};

const labsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LabsActionTypes.LABS_LIST:
      return {
        ...state,
        labslist: action.payload,
      };
    case LabsActionTypes.LABSMORE_LIST:
      return {
        ...state,
        labmorelist: action.payload,
      };
    case LabsActionTypes.LABS_SEARCHLIST:
      return {
        ...state,
        searchlist: action.payload,
      };
    case LabsActionTypes.LABS_MAP:
      return {
        ...state,
        labsmap: action.payload,
      };
    case LabsActionTypes.METALABS_LIST:
      return {
        ...state,
        metalabs: action.payload,
      };
    case LabsActionTypes.METALABS_ROOT:
      return {
        ...state,
        metaroot: action.payload,
      };
    case LabsActionTypes.LABS_ORDER:
      return {
        ...state,
        labsorder: action.payload,
      };
    case LabsActionTypes.LABS_ORDERSELECT:
      return {
        ...state,
        labsorderselect: action.payload,
      };
    case LabsActionTypes.RADLABS_SEARCHLIST:
      return {
        ...state,
        radsearchlist: action.payload,
      };
    case LabsActionTypes.LABS_ORDERLIST:
      return {
        ...state,
        labsorderlist: action.payload,
      };
    case LabsActionTypes.RADLABS_ORDER:
      return {
        ...state,
        radlabs: action.payload,
      };
    case LabsActionTypes.RADLABS_ORDERLIST:
      return {
        ...state,
        radlabsorderlist: action.payload,
      };
    case LabsActionTypes.LABS_SELECTROW:
      return {
        ...state,
        labsselectrow: action.payload,
      };
    case LabsActionTypes.LABMORE_SELECTROW:
      return {
        ...state,
        moreselect: action.payload,
      };
    case LabsActionTypes.LAB_COMPONENT:
      return {
        ...state,
        labcomponent: action.payload,
      };
    case LabsActionTypes.LABPANEL_COMPONENT:
      return {
        ...state,
        labpanelcomponent: action.payload,
      };
    case LabsActionTypes.RADLABS_SELECTROW:
      return {
        ...state,
        radlabselect: action.payload,
      };
    case LabsActionTypes.LABPANELS_LIST:
      return {
        ...state,
        labpanels: action.payload,
      };
    case LabsActionTypes.PANELTESTS_LIST:
      return {
        ...state,
        paneltests: action.payload,
      };
    case LabsActionTypes.PANEL_SELECT:
      return {
        ...state,
        panelselect: action.payload,
      };
    case LabsActionTypes.RADLABMORE_SELECTROW:
      return {
        ...state,
        radmoreselect: action.payload,
      };
    case LabsActionTypes.RADLABSMORE_LIST:
      return {
        ...state,
        radmorelist: action.payload,
      };

    case LabsActionTypes.RADLABS_PRESLIST:
      return {
        ...state,
        radlabpreslist: action.payload,
      };
    case LabsActionTypes.RADLABS_PRESSELECT:
      return {
        ...state,
        radlabpresselect: action.payload,
      };

    case LabsActionTypes.LABSPRES_SELECTROW:
      return {
        ...state,
        labpresselectrow: action.payload,
      };

    default:
      return state;
  }
};

export default labsReducer;
