import React from "react";
import init from "./init.js";
import commandsModule from "./commandsModule";
import toolbarModule from "./toolbarModule";
import CornerstoneViewportDownloadForm from "./CornerstoneViewportDownloadForm";

const Component = React.lazy(() => {
  return import("./OHIFCornerstoneViewport");
});

const OHIFCornerstoneViewport = (props) => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </React.Suspense>
  );
};

export default {
  id: "cornerstone",
  version: "2.12.15",

  /**
   *
   * @param {object} [configuration={}]
   * @param {object|array} [configuration.csToolsConfig] - Passed directly to `initCornerstoneTools`
   */

  preRegistration({ servicesManager, configuration = {} }) {
    init({ servicesManager, configuration });
  },

  getViewportModule({ commandsManager, appConfig }) {
    const ExtendedOHIFCornerstoneViewport = (props) => {
      /**
       * TODO: This appears to be used to set the redux parameters for
       * the viewport when new images are loaded. It's very ugly
       * and we should remove it.
       */
      const onNewImageHandler = (jumpData) => {
        /** Do not trigger all viewports to render unnecessarily */
        jumpData.refreshViewports = false;
        commandsManager.runCommand("jumpToImage", jumpData);
      };

      const { studyPrefetcher } = appConfig;
      const isStackPrefetchEnabled =
        studyPrefetcher && !studyPrefetcher.enabled;

      return (
        <OHIFCornerstoneViewport
          {...props}
          onNewImage={onNewImageHandler}
          isStackPrefetchEnabled={isStackPrefetchEnabled}
        />
      );
    };

    return ExtendedOHIFCornerstoneViewport;
  },
  getToolbarModule() {
    return toolbarModule;
  },
  getCommandsModule({ servicesManager }) {
    return commandsModule({ servicesManager });
  },
};

export { CornerstoneViewportDownloadForm };
