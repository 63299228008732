import * as React from "react";
import {
  StyledTableRowHead,
  StyledTableCellHead,
} from "../common/ConstantsAndValues";

export const policyTable = {
  subscriber: {
    label: "Subscriber",
    isShown: true,
    minWidth: 120,
    align: "left",
    format: (value) => value.toString(),
  },
  name: {
    label: "Policy",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  provider: {
    label: "Provider",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  hii: {
    label: "Coverage",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  premium: {
    label: "Premium",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.toString(),
  },
  start: {
    label: "Start Date",
    isFilter: false,
    minWidth: 120,
    align: "right",
    format: (value) => value.format("YYYY-MM-DD"),
  },
  end: {
    label: "End Date",
    isShown: true,
    minWidth: 120,
    align: "right",
    format: (value) => value.format("YYYY-MM-DD"),
  },
  status: {
    label: "Status",
    isShown: true,
    minWidth: 120,
    align: "left",
    format: (value) => value.toString(),
  },
};

export function ApprovePolicyListHead() {
  const table = policyTable;

  return (
    <React.Fragment>
      <StyledTableRowHead style={{ fontWeight: 700 }}>
        <StyledTableCellHead />
        <StyledTableCellHead>{table.subscriber.label} </StyledTableCellHead>
        <StyledTableCellHead>{table.name.label} </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.provider.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.hii.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.premium.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.start.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.end.label}
        </StyledTableCellHead>
        <StyledTableCellHead align="right">
          {table.status.label}
        </StyledTableCellHead>
      </StyledTableRowHead>
    </React.Fragment>
  );
}
