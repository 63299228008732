import React, { useEffect, useRef, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { setOrg } from "../../redux/actions/orgs";

import InputComponent from "../CustomComponents/InputComponent";
import { colChart } from "../common/ConstantsAndValues";
import { timeOptions } from "../common/timeslots";
import { useFormik } from "formik";
import { orgInputSchema } from "../schema/orgInputSchema";
import { SelectOptionsAuto } from "../common/SelectOptions";
import { Title } from "../common/Title";

import log from "../../services/log";
import { MyTooltipButtons, MyHelpButtons } from "../CustomComponents/MyButtons";

const OrgInput = (props) => {
  const { cancelCallback, title } = props;
  const [optionsST, setOptionsST] = useState([]);
  const [optionsET, setOptionsET] = useState([]);
  const [optionsWD, setOptionsWD] = useState([]);

  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    nameError: false,
    descError: false,
  });

  const inputformref = useRef();
  const orginputform = useFormik({
    innerRef: { inputformref },
    initialValues: {
      id: "",
      userId: null,
      email: null,
      orgName: null,
      orgDescp: null,
      mobile: "",
      secureId: false,
      slotDuration: "00:30:00",
      startTime: null,
      endTime: null,
      daysOfWeek: "",
      status: "active",
    },
    validationSchema: orgInputSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      log.info("orginput", JSON.stringify(values, null, 2));

      const jsonData = {};
      jsonData.metadata = {};
      jsonData.metadata.email = values.email;
      jsonData.metadata.phone = values.mobile;
      jsonData.metadata.userId = values.userId;
      jsonData.orgName = values.orgName;
      jsonData.orgDescp = values.orgDescp;
      jsonData.status = values.status;
      jsonData.secureId = values.secureId;
      jsonData.mobile = values.mobile;
      jsonData.workingHours = {};
      jsonData.workingHours.slotDuration = values.slotDuration;
      jsonData.workingHours.daysOfWeek = values.workingDays;
      jsonData.workingHours.startTime = values.startTime;
      jsonData.workingHours.endTime = values.endTime;

      // log.info("orginput", jsonData);

      dispatch(setOrg(jsonData));
      // .then((r) => {
      //         log.info("orggroup", r);
      //     },
      //     (error) => {
      //         log.info("orgroup creation error ", error);
      //     });
      cancelCallback();
    },
    onReset: (values) => {},
  });

  useEffect(() => {
    log.info("orgInput", user);

    if (user) {
      orginputform.setFieldValue("userId", user.metadata.accountId);
      orginputform.setFieldValue("userName", user.name);
      orginputform.setFieldValue("email", user.metadata.email);
    }
  }, [user]);

  useEffect(() => {
    if (timeOptions.timeSlots) {
      let newoptionsST = [];
      timeOptions.timeSlots &&
        timeOptions.timeSlots.forEach((d) =>
          newoptionsST.push({ label: d.value, value: d })
        );
      setOptionsST(newoptionsST);
    }
  }, [timeOptions.timeSlots]);

  useEffect(() => {
    if (timeOptions.timeSlots) {
      let newoptionsET = [];
      timeOptions.timeSlots &&
        timeOptions.timeSlots.forEach((d) =>
          newoptionsET.push({ label: d.value, value: d })
        );
      setOptionsET(newoptionsET);
    }
  }, [timeOptions.timeSlots]);

  useEffect(() => {
    if (timeOptions.daysOfWeek) {
      let newoptionsWD = [];
      timeOptions.daysOfWeek &&
        timeOptions.daysOfWeek.forEach((d) =>
          newoptionsWD.push({ label: d.value, value: d })
        );
      setOptionsWD(newoptionsWD);
    }
  }, [timeOptions.daysOfWeek]);

  const handleCancel = (event) => {
    cancelCallback();
  };

  const handleEndtime = (event) => {
    if (event.target.value <= values.startTime) {
      setValues({
        ...values,
        endTimeError: true,
      });
    } else {
      setValues({
        ...values,
        endTime: event.target.value,
        endTimeError: false,
      });
    }
  };

  const handleStarttime = (event) => {
    setValues({
      ...values,
      startTime: event.target.value,
    });
  };

  const handleSelectEndtime = (time) => {
    orginputform.setFieldValue("endTime", time.value.value);
  };

  const handleSelectStarttime = (time) => {
    orginputform.setFieldValue("startTime", time.value.value);
  };

  const handleSelectDaysOfWeek = (days) => {
    // log.info("orgInput", days)
    const daysofweek = [];
    days.forEach((d) => daysofweek.push(d.value.value));
    orginputform.setFieldValue("daysOfWeek", daysofweek);
  };

  // log.info("orginput errors", orginputform.errors);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%", md: "60%", lg: "50%" },
        }}
      >
        <Box mb={1}>
          <div className="colfl0">
            <Title>{title ? title : ""} </Title>
          </div>
          <div className="colfr0">
            <MyTooltipButtons
              onClick={handleCancel}
              btnKey={"cancel"}
              btnLabel={"Cancel"}
              enState={false}
            />
          </div>
        </Box>
        <Box className="clear text-color-error"></Box>
        {orginputform.errors ? (
          orginputform.errors.userId ? (
            <div className="text-color-error my5" style={{ width: "100%" }}>
              {orginputform.errors.userId}
            </div>
          ) : orginputform.errors.startTime ? (
            <div className="text-color-error my5" style={{ width: "100%" }}>
              {orginputform.errors.startTime}
            </div>
          ) : orginputform.errors.endTime ? (
            <div className="text-color-error my5" style={{ width: "100%" }}>
              {orginputform.errors.endTime}
            </div>
          ) : orginputform.errors.daysOfWeek ? (
            <div className="text-color-error my5" style={{ width: "100%" }}>
              {orginputform.errors.daysOfWeek}
            </div>
          ) : (
            <div />
          )
        ) : (
          <div />
        )}
        <InputComponent
          id="orgName"
          name="orgName"
          label="Name"
          errors={orginputform.errors.orgName ? true : false}
          variant="standard"
          disabled={false}
          defaultValue={orginputform.values.orgName}
          handleChange={orginputform.handleChange}
        />
        <InputComponent
          id="orgDescp"
          name="orgDescp"
          label="Desription"
          errors={orginputform.errors.orgDescp ? true : false}
          defaultValue={orginputform.values.orgDescp}
          variant="standard"
          handleChange={orginputform.handleChange}
        />
        &nbsp;
        <InputComponent
          id="mobile"
          name="mobile"
          label="Mobile"
          errors={orginputform.errors.mobile ? true : false}
          defaultValue={orginputform.values.mobile}
          variant="standard"
          handleChange={orginputform.handleChange}
        />
        &nbsp;
        <SelectOptionsAuto
          openPage={handleSelectStarttime} //orginputform.values.startTime
          data={optionsST}
          multi={false}
          title={"start time"}
          wide={0}
        />
        &nbsp;
        <SelectOptionsAuto
          openPage={handleSelectEndtime} //orginputform.values.endTime
          data={optionsET}
          multi={false}
          title={"end time"}
          wide={0}
        />
        &nbsp;
        <SelectOptionsAuto
          openPage={handleSelectDaysOfWeek} //orginputform.values.daysOfWeek
          data={optionsWD}
          multi={true}
          title={"working days"}
          wide={0}
        />
        <Box className="clear" />
        <Box mt={2} pt={2} className="border-dbt">
          <Box className="colfr0">
            <MyHelpButtons
              onClick={orginputform.handleSubmit}
              btnKey={"add"}
              btnLabel={"Add"}
              enState={false}
              btnTooltip={"Add"}
            />
          </Box>
          <Box className="clear" />
        </Box>
        <Box className="clear" />
      </Grid>
    </Grid>
  );
};

export default OrgInput;
