import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTableRow } from "../styles/InvoiceStyles";

const styles = stylesTableRow;

export const InvoiceTableRows = ({ items }) => {
  return (
    <>
      {items &&
        items.map((item) => (
          <View style={styles.row} key={item.sno.toString()}>
            <Text style={styles.description}>{item.desc}</Text>
            <Text style={styles.qty}>{item.qty}</Text>
            <Text style={styles.rate}>{item.rate}</Text>
            <Text style={styles.amount}>
              {(item.qty * item.rate).toFixed(2)}
            </Text>
          </View>
        ))}
    </>
  );
};

export const InvoiceTableRowsBlank = ({ rowsCount }) => {
  const blankRows = Array(rowsCount).fill(0);
  const rows = blankRows.map((x, i) => (
    <View style={styles.row} key={`BR${i}`}>
      <Text style={styles.description}>&nbsp;</Text>
      <Text style={styles.qty}>&nbsp;</Text>
      <Text style={styles.rate}>&nbsp;</Text>
      <Text style={styles.amount}>&nbsp;</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};
