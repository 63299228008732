import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Collapse,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  SearchBoxStandard,
  StyledTableCell,
  StyledTableRow,
  tableParam,
} from "../common/ConstantsAndValues";
import { MyIconButtons } from "../CustomComponents/MyButtons";
import log from "../../services/log";

export function PolicyListRow(props) {
  const { row, handleSelectRow, selectedrow } = props;
  const [open, setOpen] = useState(false);
  const [maplist, setMaplist] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // log.info("policylist", selectedrow)
    if (row && selectedrow) {
      setSelectedRow(row.id === selectedrow.id);
    }
  }, [selectedrow]);

  useEffect(() => {
    if (row) {
      const listdata = [];
      row.opd
        ? listdata.push({
            id: "opd",
            label: "OPD Coverage",
            value: row.opd.coverage,
          })
        : listdata.push({ id: "opd", label: "OPD Coverage", value: "None" });
      row.hiiCoverage
        ? listdata.push({
            id: "hii",
            label: "Hospitalizaiton Coverage",
            value: row.hiiCoverage.coverage,
          })
        : listdata.push({
            id: "hii",
            label: "Hospitalizaiton Coverage",
            value: "None",
          });
      row.dentalCoverage
        ? listdata.push({
            id: "dental",
            label: "Dental Coverage",
            value: row.dentalCoverage.coverage,
          })
        : listdata.push({
            id: "dental",
            label: "Dental Coverage",
            value: "None",
          });
      row.visionCoverage
        ? listdata.push({
            id: "vision",
            label: "Hospitalizaiton Coverage",
            value: row.visionCoverage.coverage,
          })
        : listdata.push({
            id: "vision",
            label: "Vision Coverage",
            value: "None",
          });
      row.discount
        ? listdata.push({
            id: "discount",
            label: "Discount",
            value: row.discount,
          })
        : listdata.push({ id: "discount", label: "Discount", value: "None" });
      row.ncb
        ? listdata.push({ id: "ncb", label: "No Claim bonus", value: row.ncb })
        : listdata.push({ id: "ncb", label: "No Claim bonus", value: "None" });
      row.familyDiscount
        ? listdata.push({
            id: "familydiscount",
            label: "Family discount",
            value: row.familyDiscount,
          })
        : listdata.push({
            id: "familydiscount",
            label: "Family discount",
            value: "None",
          });
      row.policyPremium
        ? listdata.push({
            id: "premium",
            label: "Premium",
            value: JSON.stringify(row.policyPremium.premium),
          })
        : listdata.push({ id: "premium", label: "Premium", value: "None" });
      row.policyPremium
        ? listdata.push({
            id: "premiumperiod",
            label: "Premium period",
            value: row.policyPremium.period,
          })
        : listdata.push({
            id: "premiumperiod",
            label: "Premium period",
            value: "None",
          });

      // log.info("ct row", r);
      setMaplist(listdata);
    }
  }, []);

  const handleClick = () => {
    log.info("ct row", row);
    // if (row) {
    //     const jsonData = {};
    //     jsonData['metadata'] = {};
    //     jsonData.metadata['metalabId'] = row.labId;
    //     dispatch(getMetaLabMap(jsonData)).then((r) => {
    //         log.info("ct row", r);
    //         setMaplist(r)
    //     });
    // }
    setOpen(!open);
  };

  const handleFilter = () => {};

  const sumCoverage = (row) => {
    log.info("policyrow", row);
    let total = 0;
    total = total + row.hiiCoverage.coverage ? row.hiiCoverage.coverage : 0;
    // total = total + row.optCoverage.coverage? row.opdCoverage.coverage : 0;
    // total = total + row.dentalCoverage.coverage? row.dentalCoverage.coverage : 0;
    // total = total + row.visionCoverage.coverage? row.visionCoverage.coverage : 0;

    return total;
  };

  const handelRowlocal = (row) => {
    log.info("healthpolicylist", row);
    setSelectedRow(!selectedRow);
    if (!selectedRow) {
      handleSelectRow(row);
    } else {
      handleSelectRow(null);
    }
  };

  return (
    <React.Fragment>
      <StyledTableRow
        key={"a" + row.id}
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => handelRowlocal(row)}
        className={
          selectedRow ? "text-color-primary bg-selected" : "text-color-primary"
        }
      >
        <StyledTableCell width="24px">
          <MyIconButtons onClick={() => handleClick(row)} enState={open} />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <b>{row.policyName}</b>
        </StyledTableCell>
        <StyledTableCell align="right">
          <Typography variant={"body2"}>{row.providerName}</Typography>
        </StyledTableCell>
        <StyledTableCell align="right">Rs. {sumCoverage(row)}</StyledTableCell>
        <StyledTableCell align="right">
          {row.hiiCoverage.period}
        </StyledTableCell>
      </StyledTableRow>

      <TableRow
        key={"b" + row.id}
        className="text-color-gray bg-standard-offwhite"
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pb={2} pl={7}>
              <div>
                <div className="colfl text-color-primary">
                  Policy information
                </div>
              </div>

              <Box
                key={row.id}
                className="colfl list border-db"
                pt={0}
                ml={2}
                mr={0}
                mb={1}
                height={tableParam.minHeight / 2}
                width="90%"
              >
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {maplist &&
                      maplist.map((m, index) => (
                        <TableRow key={m.id}>
                          <TableCell>{m.label}</TableCell>
                          <TableCell>{m.value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
