import { scaleOrdinal, schemeSet1 } from "d3";

export const marginChartsXXXXX = { top: 40, right: 80, bottom: 60, left: 80 };

export const dimensions = {
  width: 600,
  height: 400,
  margin: {
    top: 30,
    right: 30,
    bottom: 70,
    left: 60,
  },
};

export const color = scaleOrdinal().range(schemeSet1.slice(2));
