import React, { useState, useEffect, useLayoutEffect } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, Container, Stack } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Title } from "../common/Title";

//import {PatientListPath, HeadUrl}                          from "../common/ConstantsAndValues";
import { colWidthAccordion } from "../common/ConstantsAndValues";

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "var(--fc-neutral-bg-color)",
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    flexGrow: 12,
    "&$expanded": {
      flexGrow: 1,
      margin: "12px 0",
    },
  },
  expanded: {},
  expandIcon: {
    order: 0,
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function DeptList(props) {
  const { userType, data } = props;

  const navigate = useNavigate();
  const [markDates, dateTest, auth, eventType, page] = useOutletContext();
  const [expanded, setExpanded] = React.useState("panel0");

  const w = colWidthAccordion.colm2;

  //const saved = localStorage.getItem("patient");
  //const patient = JSON.parse(saved);

  //localStorage.setItem("prevURL", JSON.stringify(HeadUrl));

  useEffect(() => {
    //if (userType ==="MD") {
    //    if (patient){
    //        if (patient.id === null){
    //           navigate(PatientListPath);
    //        }
    //    }else {
    //        navigate(PatientListPath);
    //    }
    //}
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {!!data[0] ? (
        data.map((aco, i) => (
          <Accordion
            key={i}
            square
            expanded={expanded === "panel" + i}
            onChange={handleChange("panel" + i)}
          >
            <AccordionSummary
              className="border-db"
              aria-controls={"panel" + i + "d-content"}
              expandIcon={<ExpandMoreIcon />}
              id={"panel" + i + "d-header"}
            >
              <Stack
                sx={{
                  pl: 0,
                  //width:          'auto',
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  //justifyContent: "space-between",
                }}
              >
                <Box component="div" width={w} key={aco._id + "asl"}>
                  <Typography>{aco.alias}</Typography>
                </Box>
                <Box component="div" width={"auto"} key={aco._id + "assl"}>
                  <Typography className="text-color ">
                    <b>{aco.label}</b>
                  </Typography>
                </Box>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Box component="div" width={w} key={aco._id + "adl"}>
                <Typography>&nbsp; &nbsp; &nbsp; </Typography>
              </Box>
              <Box
                component="div"
                width={"auto"}
                key={aco._id + "adsl"}
                className=""
              >
                <Stack
                  className="text-color-primary "
                  sx={{
                    pl: 0,
                    //width:          'auto',
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className="border-db" p={5}>
                    {aco.specialist}
                  </Typography>
                  <Typography className="border-db" p={5}>
                    {aco.associate}
                  </Typography>
                  <Typography className="border-db" p={5}>
                    {aco.assistant}
                  </Typography>
                </Stack>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <div className="text-color-primary">No Data</div>
      )}
    </div>
  );
}
