/**
 *  filter by patients
 * @param p
 * @param s
 * @param patient
 * @returns {{}}
 */

export const buildClinicNotesQuery = (p, s, patient) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p?.page;
  jsonData["limit"] = p?.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["patientId"] = patient?.metadata?.patinetId;
  return jsonData;
};

export const buildUserClinicNotesQuery = (p, s, user) => {
  const jsonData = {};
  jsonData["metadata"] = {};
  jsonData["page"] = p?.page;
  jsonData["limit"] = p?.limit;
  jsonData["sortBy"] = "updated_at";
  jsonData.metadata["patientId"] = user?.metadata?.accountId;
  return jsonData;
};
