export const LabsActionTypes = {
  LABS_SELECTROW: "LABS_SELECTROW",
  LABS_LIST: "LABS_LIST",
  LABPANELS_LIST: "LABPANELS_LIST",
  PANELTESTS_LIST: "PANELTESTS_LIST",
  PANEL_SELECT: "PANEL_SELECT",
  LABPANEL_COMPONENT: "LABPANEL_COMPONENT",
  LAB_COMPONENT: "LAB_COMPONENT",
  LABSMORE_LIST: "LABSMORE_LIST",
  LABMORE_SELECTROW: "LABMORE_SELECTROW",
  LABS_ORDERLIST: "LABS_ORDERLIST",
  LABS_ORDER: "LABS_ORDER",
  LABS_ORDERSELECT: "LABS_ORDERSELECT",
  LABS_SEARCHLIST: "LABS_SEARCHLIST",
  LABSPRES_LIST: "LABSPRES_LIST",
  LABSPRES_SELECTROW: "LABSPRES_SELECTROW",
  LABS_MAP: "LABS_MAP",
  METALABS_LIST: "METALABS_LIST",
  METALABS_ROOT: "METALABS_ROOT",
  RADLABS_SEARCHLIST: "RADLABS_SEARCHLIST",
  RADLABS_LIST: "RADLABS_LIST",
  RADLABS_PRESLIST: "RADLABS_PRESLIST",
  RADLABS_PRESSELECT: "RADLABS_PRESSELECT",
  RADLAB_MORESELECTROW: "RADLAB_MORESELECTROW",
  RADLABS_ORDER: "RADLABS_ORDER",
  RADLABS_ORDERLIST: "RADLABS_ORDERLIST",
  RADLABS_SELECTROW: "RADLABS_SELECTROW",
  RADLABSMORE_LIST: "RADLABSMORE_LIST",
  RADLABMORE_SELECTROW: "RADLABMORE_SELECTROW",
};
