import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { stylesTableHead } from "../styles/DiagnosticsStyles";

const styles = stylesTableHead;

export const DrugsTableHeader = () => (
  <View style={styles.container} fixed>
    <Text style={styles.drugname}>Drug</Text>
    <Text style={styles.drugdosedetail}>Dose details</Text>
  </View>
);
