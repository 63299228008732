import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";

import log from "../../services/log";
import { labsystemquery } from "../utils/elasticqueries";
import {
  getLabComponents,
  getLabComponentRoot,
} from "../../redux/loinc/loinc.actions";
import { LabTreeNode } from "../common/LabTreeNode";

export default function LabComponentView(props) {
  const labcomponentroot = useSelector(
    (state) => state?.loinc?.labcomponentroot
  );
  const labcomponents = useSelector((state) => state?.loinc?.labcomponents);

  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [comptree, setCompTree] = useState(
    new LabTreeNode({ _source: { code: "LP29693-6" } })
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getLabComponents(labsystemquery("root", "LP432695-7.LP29693-6"))
    ).then((r) => {
      const newtree = comptree.addChildren(r.hits, "LP29693-6");
      setCompTree(newtree);
    });
  }, []);

  const renderTree = (children) => {
    if (children == undefined) return <div />;

    // log.info("labcoponent", children)
    return children.map((child) => {
      const childrenNodes =
        child.children.length > 0 ? (
          renderTree(child.children)
        ) : child._source.code.includes("LP") ? (
          <div />
        ) : null;

      return (
        <TreeItem
          nodeId={child._source["code"]}
          label={child._source["code_text"]}
        >
          {childrenNodes}
        </TreeItem>
      );
    });
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    log.info("selected", nodeIds);
    setSelected(nodeIds);
  };

  const handleExpandClick = (nodeId) => {
    log.info("tree expand", expanded);
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? [] : []));
  };

  const handleSelectClick = (nodeId) => {
    log.info("tree select", nodeId);
    setSelected((oldSelected) => (oldSelected.length === 0 ? [] : []));
  };

  const handleOpenClick = () => {
    selected.forEach((nodeId) => {
      const node = comptree.search(nodeId);
      const newroot = node._source["root"] + "." + nodeId;
      // log.info("tree get", node);
      // log.info("tree get", newroot);

      dispatch(getLabComponents(labsystemquery("root", newroot))).then((r) => {
        const newtree = comptree.addChildren(r.hits, nodeId);
        setCompTree(newtree);
      });
    });
  };

  const handleAddClick = () => {
    selected.forEach((nodeId) => {
      const node = comptree.search(nodeId);
    });
  };

  // log.info("tree root", comptree);

  return (
    <Box sx={{ height: 270, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}>
      <Box sx={{ mb: 1 }}>
        <Button onClick={handleExpandClick}>
          {expanded.length === 0 ? "Expand" : "Collapse"}
        </Button>
        <Button onClick={handleSelectClick}>
          {selected.length === 0 ? "Select" : "Unselect"}
        </Button>
        <Button onClick={handleOpenClick}>
          {selected.length === 0 ? "" : "Show labs"}
        </Button>
        <Button onClick={handleAddClick}>
          {selected.length === 0 ? "" : "Add labs"}
        </Button>
      </Box>
      <TreeView
        aria-label="controlled"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        multiSelect
      >
        {renderTree(comptree.children)}
      </TreeView>
    </Box>
  );
}
