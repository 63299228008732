import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import useStyles from "../../CustomComponents/useStyles";
import { TablePaginationStandard } from "../../CustomComponents/TablePaginationStandard";
import { Title } from "../../common/Title";
import {
  pageOptions,
  SearchBoxStandard,
} from "../../common/ConstantsAndValues";
import { ClinicalTestsPresListHead } from "./ClinicalTestsPresListHead";
import { ClinicalTestsPresListRow } from "./ClinicalTestsPresListRow";
import log from "../../../services/log";

export function ClinicalTestsPresList(props) {
  const { clinicaltestlist } = props;

  const [pagedata, setPage] = useState({
    limit: 10,
    page: 0,
    pages: 0,
    count: 0,
  });
  const [query, setQuery] = useState({ name: "a" });
  const [rows, setRows] = useState(null);
  const [clinicaltests, setClinicaltests] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    // log.info("clinicaltestlist", clinicaltestlist.results)
    if (clinicaltestlist) {
      setClinicaltests(clinicaltestlist);
    }
  }, [clinicaltestlist]);

  const handleChangePage = (event, value) => {
    // log.info("druglist", value);
    log.info("patient CT list", pagedata);
    const newpagedata = { ...pagedata, page: parseInt(value) };
    //log.info("patient druglist", drugsquery("name", query.name, newpagedata));
    //dispatch(getDrugs(drugsquery("name", query.name, newpagedata))).then((r) => {
    // log.info("drugslist", r)
    // if (r.total.value && r.total.value > 0) {
    //     setPage({... pagedata,
    //         count: r.total.value,
    //         pages: r.total.value/pagedata.limit,
    //     });
    // }
    //});
    setPage(newpagedata);
  };
  const handleChangeRows = (event) => {
    setPage({ ...pagedata, limit: parseInt(event.target.value) });
  };

  const handleRowClick = (e, selectrow) => {
    log.info("clinicaltestlist", e);
    if (selectrow) {
      setCheckedRows(e);
      // dispatch(selectPatientMedcine(e));
    } else {
      setCheckedRows(null);
      // dispatch(selectPatientMedcine(null));
    }
  };

  // log.info("clinicaltestlist", clinicaltests);

  return (
    <Grid container>
      <div style={{ width: "100%", marginTop: "16px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead className="bg-standard-lightest text-md">
              <ClinicalTestsPresListHead />
            </TableHead>

            <TableBody className="text-color-primary text text-md">
              {/*{clinicaltests && clinicaltests.map((row) => (*/}
              {/*    <ClinicalTestsPresListRow*/}
              {/*        key            = {row.metadata.medicineId}*/}
              {/*        row            = {row}*/}
              {/*        isRowSelected  = {checkedRows}*/}
              {/*        handleRowClick = {handleRowClick}*/}
              {/*    />*/}
              {/*))}*/}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ width: "100%" }}>
        <TablePaginationStandard
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          pagedata={pagedata}
        />
      </div>
    </Grid>
  );
}
